<template>
  <div class="hls-player">
    <video-player
      ref="videoPlayer"
      class="video-player-box"
      :options="playerOptions"
      :playsinline="true"
      custom-event-name="customstatechangedeventname"
      @ready="playerReadied"
    />
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      // player: null,
      playerOptions: {
        autoplay: true,
        muted: true,
        height: '150',
        width: '250',
        sources: [{
          withCredentials: false,
          type: 'application/x-mpegURL',
          // src: 'https://logos-channel.scaleengine.net/logos-channel/live/biblescreen-ad-free/playlist.m3u8'
          src: this.url
        }],
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
          volumePanel: false
        },
        flash: { hls: { withCredentials: false } },
        html5: { hls: { withCredentials: false } }
      }
    }
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    }
  },
  methods: {
    playerReadied(player) {
      console.log('the player is readied', player)
    }
  }
}
</script>

<style lang="scss">
.hls-player {
  position: relative;
  padding: 4px;
  border-radius: var(--border-radius);
  overflow: hidden;
  max-width: calc(100% - 8px);
  min-height: 50px;
  width: calc(100% - 8px);

  .video-js {
    max-width: 100%;
  }
}
</style>
