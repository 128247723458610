<template>
  <reports />
</template>

<script>
import reports from '@/components/reports/reports'

export default {
  components: { reports }
}
</script>
