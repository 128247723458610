import vue from 'vue'

const state = {
  datasources: {},
  dsTree: [],
  loading: true,
  changed: false,
  activeDs: '',
  dsExportItem: null,
  update: false
}

const mutations = {
  SET_DATASOURCE(state, data) {
    if (!data) return
    data.forEach(l => {
      state.datasources[l.id] = l
      if (l.children && l.children.length) {
        l.children.forEach(ch => {
          state.datasources[ch.id] = ch
        })
      }
    })
    state.dsTree = data
    // state.isReadyToRender = true
  },
  SET_DS_MANAGER_ACTIVE_DS(state, object) {
    vue.set(state, 'activeDs', object)
  },
  SET_DS_EXPORT_ITEM(state, object) {
    vue.set(state, 'dsExportItem', object)
  },
  SET_UPDATE_DS_TREE(state, value) {
    vue.set(state, 'update', value)
  }
}

export default {
  state,
  mutations
}
