export const toggleBodyTheme = newTheme => {
  newTheme = newTheme || 'dark-theme'
  const oldClass = newTheme === 'dark-theme' ? 'light-theme' : 'dark-theme'

  document.body.classList.remove(oldClass)
  document.body.classList.add(newTheme)
}

// return all css variables, main rule - has '--'
const isSameDomain = (styleSheet) => {
  if (!styleSheet.href) return true

  return styleSheet.href.indexOf(window.location.origin) === 0
}

const isStyleRule = (rule) => rule.type === 1

export const getCSSCustomPropIndex = () => {
  const variables = {}

  const variablesArr = [...document.styleSheets].filter(isSameDomain).reduce(
    (finalArr, sheet) =>
      finalArr.concat(
        [...sheet.cssRules].filter(isStyleRule).reduce((propValArr, rule) => {
          const props = [...rule.style]
            .map((propName) => [
              propName.trim(),
              rule.style.getPropertyValue(propName).trim()
            ])
            .filter(([propName]) => propName.indexOf('--') === 0)

          return [...propValArr, ...props]
        }, [])
      ),
    []
  )
  variablesArr.forEach(e => {
    variables[e[0]] = e[1]
  })

  return variables
}
