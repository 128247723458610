<template>
  <r-simple-card title="Размер">
    <div class="map-slider-picker">
      <div
        v-if="!field"
        class="map-slider-picker__simple"
      >
        <r-slider
          title="Толщина"
          :number="layerStyle.paint[property]"
          :min="1"
          :max="20"
          :step="1"
          @change="changeProperty('paint', property, $event)"
        />
      </div>
      <div
        v-else
        class="map-slider-picker__range"
      >
        <div class="map-slider-picker__range-slider">
          <el-slider
            v-model="range"
            class="r-slider dark square"
            range
            :min="min"
            :max="max"
            :step="1"
            @input="rangeChange"
          />
        </div>
        <div class="map-slider-picker__range-inputs">
          <el-input-number
            v-model="firstValue"
            :controls="false"
            class="r-input dark"
            size="mini"
            type="number"
            @change="rangeInputChange(0, $event)"
          />
          <el-input-number
            v-model="secondValue"
            :controls="false"
            class="r-input dark"
            size="mini"
            type="number"
            @change="rangeInputChange(1, $event)"
          />
        </div>
      </div>
      <div class="map-slider-picker__more-block">
        <div class="map-slider-picker__attributes">
          <r-text
            type="caption"
            style="margin-bottom: 4px"
          >
            Зависит от атрибута
          </r-text>
          <source-attributes
            :single="true"
            :active-fields="field"
            :tree-data="getAttributes(objectFields, false, false)"
            @handleNodeClick="changeField($event)"
          />
        </div>
      </div>
    </div>
  </r-simple-card>
</template>

<script>
import SourceAttributes from '@/components/map/components/source-attributes/source-attributes'
import { getAttributes, updateObjectFieldsConfigs } from '@/utils'

export default {
  components: {
    SourceAttributes
  },
  props: {
    id: {
      type: String,
      required: true
    },
    layerStyle: {
      type: Object,
      required: true
    },
    controllers: {
      type: Object,
      required: true
    },
    property: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      moreShown: false,
      field:
        this.$store.state.map.styleConfig[this.id].classesStyle[this.property]
          .field || '',
      objectFields: this.$clientStore.getDataFields(
        this.$store.state.profiles.sourceIdById[this.id]
      ),
      min: 1,
      max: 20,
      fieldMin:
        this.$store.state.map.styleConfig[this.id].classesStyle[this.property]
          .fieldMin || 0,
      fieldMax:
        this.$store.state.map.styleConfig[this.id].classesStyle[this.property]
          .fieldMax || 0,
      range: this.$store.state.map.styleConfig[this.id].classesStyle[
        this.property
      ].range || [1, 10],
      firstValue: 1,
      secondValue: 10
    }
  },
  watch: {
    id: function() {
      const config = this.$store.state.map.styleConfig[this.id].classesStyle
      const current = config[this.property]
      const path = current.field || ''
      this.range = current.range || [1, 10]
      this.firstValue = current.range[0] || 1
      this.secondValue = current.range[1] || 10
      this.fieldMin = current.fieldMin || 0
      this.fieldMax = current.fieldMax || 0
      this.objectFields = this.$clientStore.getDataFields(
        this.$store.state.profiles.sourceIdById[this.id]
      )

      if (path) this.changeField({ path })
      else {
        this.field = ''
        this.moreShown = false
        this.changeField({ path })
      }
    }
  },
  methods: {
    changeProperty(type, field, value) {
      if (value) this.layerStyle[type][field] = value
      this.$emit('change-property', { type, field })
    },
    async changeField({ path, config }) {
      if (path === this.field) {
        this.field = ''
        this.fieldMin = 0
        this.fieldMax = 0
        this.$set(this, 'range', [1, 10])
        this.changeProperty('paint', this.property)
        this.$store.commit('SET_FIELD_CLASSES_STYLE', {
          id: this.id,
          field: this.property,
          config: {
            field: this.field,
            fieldMax: this.fieldMax,
            fieldMin: this.fieldMin,
            range: this.range
          }
        })
      } else {
        this.field = path
        updateObjectFieldsConfigs(
          this.controllers.layers.map,
          this.id,
          path,
          config
        )
        await this.controllers.layers.updateLayerData(this.id, [this.field])
        this.setMinMaxValues()
        this.applyRange()
      }
    },
    setMinMaxValues() {
      const field = this.field
      const attributes = this.$clientStore.getDataFields(
        this.$store.state.profiles.sourceIdById[this.id]
      )
      const attribute = attributes.find(f => f.title === field)
      if (!attribute) return
      const { min, max } = attribute
      this.fieldMin = min
      this.fieldMax = max
    },
    rangeInputChange(input, value) {
      if (input === 0) this.$set(this, 'range', [value, this.range[1]])
      else this.$set(this, 'range', [this.range[0], value])
      if (this.field && this.fieldMin && this.fieldMax) this.applyRange()
    },
    rangeChange() {
      this.firstValue = this.range[0]
      this.secondValue = this.range[1]
      if (this.field && this.fieldMax) this.applyRange()
    },
    applyRange() {
      this.$store.commit('SET_FIELD_CLASSES_STYLE', {
        id: this.id,
        field: this.property,
        config: {
          field: this.field,
          fieldMax: this.fieldMax,
          fieldMin: this.fieldMin,
          range: this.range
        }
      })
      this.$emit('change-range', { field: this.property })
    },
    getAttributes
  }
}
</script>

<style lang="scss">
.map-slider-picker {
  &__range {
    margin-top: 8px;

    &-inputs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 4px;

      .r-input {
        width: 25%;
        .el-input__inner {
          height: 24px;
          line-height: 24px;
        }
      }
    }
  }

  &__attributes {
    margin-top: 15px;
  }
}
</style>
