<template>
  <div
    :class="['r-icon', `${colorType}`, { loading }]"
    :style="`${arrowDown ? 'grid-gap: 0' : ''}`"
  >
    <svg
      v-if="name"
      v-svg
      :symbol="name"
      role="presentation"
      class="r-icon__svg"
      :style="getStyle()"
    />
    <span
      v-if="loading"
      class="r-icon__spinner"
    />
    <div
      v-if="arrowDown"
      class="r-icon-arrow-down"
      :style="getArrowStyle"
    />
  </div>
</template>

<script>
const colorTypes = [
  'accent-error',
  'error',
  'accent-primary',
  'primary',
  'accent-warning',
  'white',
  'success',
  ''
]
export default {
  props: {
    name: {
      type: String,
      default: null
    },
    size: {
      type: Number,
      default: 32
    },
    color: {
      type: String,
      default: ''
    },
    arrowDown: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    colorType: {
      validator: type => colorTypes.includes(type),
      default: ''
    },
    label: {
      type: String,
      default: null
    }
  },
  computed: {
    getArrowStyle() {
      const styles = {}
      if (this.color) styles.background = `${this.color} !important`
      return styles
    }
  },
  methods: {
    getStyle() {
      const styles = {}

      if (this.color) styles.fill = `${this.color} !important`
      if (this.size) {
        styles.width = `${this.size}px`
        styles.height = `${this.size}px`
      }

      return styles
    }
  }
}
</script>

<style lang="scss">
.r-icon {
  fill: var(--icons_high_contrast) !important;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: auto;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  &__svg {
    width: 100%;
    height: 100%;
    transition: all 0.15s ease;
  }

  &.loading {
    .r-icon__svg {
      opacity: 0;
    }
  }

  &__spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: ' ';
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      border: 2px solid;
      border-color: var(--icons_low_contrast) transparent
        var(--icons_low_contrast) transparent;
      animation: spin 0.9s infinite cubic-bezier(0.5, 0, 0.5, 1);
    }
  }

  .r-title {
    margin-left: 2px;
  }

  &-arrow-down {
    margin-left: 4px;
    width: 8px;
    height: 4px;
    clip-path: polygon(0 0, 50% 100%, 100% 0);
    background-color: var(--icons_high_contrast) !important;
  }

  &.error {
    .r-icon__svg {
      fill: var(--accent_error) !important;
    }

    .r-title.subhead {
      color: var(--accent_error) !important;
    }

    .r-icon-arrow-down {
      background-color: var(--accent_error) !important;
    }
  }

  &.accent-primary {
    .r-icon__svg {
      fill: var(--accent_primary) !important;
    }

    .r-title.subhead {
      color: var(--accent_primary) !important;
    }

    .r-icon-arrow-down {
      background-color: var(--accent_primary) !important;
    }
  }

  &.primary {
    .r-icon__svg {
      fill: var(--text_primary) !important;
    }

    .r-title.subhead {
      color: var(--text_primary) !important;
    }

    .r-icon-arrow-down {
      background-color: var(--text_primary) !important;
    }
  }

  &.accent-error {
    .r-icon__svg {
      fill: var(--accent_error) !important;
    }

    .r-title.subhead {
      color: var(--accent_error) !important;
    }

    .r-icon-arrow-down {
      background-color: var(--accent_error) !important;
    }
  }

  &.success {
    .r-icon__svg {
      fill: var(--accent_success) !important;
    }

    .r-title.subhead {
      color: var(--accent_success) !important;
    }

    .r-icon-arrow-down {
      background-color: var(--accent_success) !important;
    }
  }

  &.accent-warning {
    .r-icon__svg {
      fill: var(--accent_warning) !important;
    }

    .r-title.subhead {
      color: var(--accent_warning) !important;
    }

    .r-icon-arrow-down {
      background-color: var(--accent_warning) !important;
    }
  }

  &.white {
    .r-icon__svg {
      fill: var(--white_white) !important;
    }

    .r-title.subhead {
      color: var(--white_white) !important;
    }

    .r-icon-arrow-down {
      background-color: var(--white_white) !important;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1079deg);
  }
}
</style>
