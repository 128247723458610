export const tableFields = {
  no: '№',
  created_at: 'Дата создания',
  datetime: 'Дата фиксации',
  elimination_date: 'Срок устранения',
  subproject_id: 'Подпроект',
  detection_employee_id: 'ФИО выявившего нарушение',
  guilty_name: 'ФИО нарушителя',
  contractor_id: 'Подрядчик',
  subcontractor_id: 'Субподрядчик',
  subsubcontractor_id: 'Субсубподрядчик',
  type_id: 'Вид нарушения',
  eliminated: 'Устранено'
}

export const columns = [
  'no',
  'created_at',
  'datetime',
  'elimination_date',
  'subproject_id',
  'detection_employee_id',
  'guilty_name',
  'contractor_id',
  'subcontractor_id',
  'subsubcontractor_id',
  'type_id',
  'eliminated'
]

export const exportConfig = {
  column_order: [
    'no',
    'created_at',
    'datetime',
    'elimination_date',
    'subproject.name',
    'detection_employee.name',
    'guilty_name',
    'contractor.name',
    'subcontractor.name',
    'subsubcontractor.name',
    'type.name',
    'eliminated'
  ],
  column_labels: [
    '№',
    'Дата создания',
    'Дата фиксации',
    'Срок устранения',
    'Подпроект',
    'ФИО выявившего нарушение',
    'ФИО нарушителя',
    'Подрядчик',
    'Субподрядчик',
    'Субсубподрядчик',
    'Вид нарушения',
    'Устранено'
  ],
  only: [
    'no',
    'created_at',
    'datetime',
    'elimination_date',
    'guilty_name',
    'eliminated'
  ],
  include: {
    subproject: {
      only: ['name']
    },
    detection_employee: {
      only: ['name']
    },
    contractor: {
      only: ['name']
    },
    subcontractor: {
      only: ['name']
    },
    subsubcontractor: {
      only: ['name']
    },
    type: {
      only: ['name']
    }
  }
}
