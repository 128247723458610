export const filters = () => [
  {
    id: 'created_at',
    initType: 'date-filter',
    type: 'day-filter',
    title: 'Дата создания',
    prop: { interval: { from: '', to: '' } },
    active: false
  },
  {
    id: 'contractor_id',
    type: 'checkbox-filter',
    title: 'Подрядчик',
    prop: [],
    active: false
  },
  {
    id: 'type_id',
    type: 'checkbox-filter',
    title: 'Тип',
    prop: [],
    active: false
  },
  {
    id: 'group_id',
    type: 'checkbox-filter',
    title: 'Группа',
    prop: [],
    active: false
  },
  {
    id: 'subgroup_id',
    type: 'checkbox-filter',
    title: 'Подгруппа',
    prop: [],
    active: false
  },
  {
    id: 'date_until',
    initType: 'date-filter',
    type: 'day-filter',
    title: 'Срок выполнения',
    prop: { interval: { from: '', to: '' } },
    active: false
  }
]
