<template>
  <div class="rp-sidebar-roles">
    <r-button
      type="primary"
      style="margin: 0 0 8px"
      @click="createNewRole"
    >
      Создать новую роль
    </r-button>
    <div class="rp-sidebar-roles-header">
      <r-search-input
        :filter-text="filterText"
        @change="e => (filterText = e)"
      />
    </div>
    <rp-sidebar-roles-list
      v-if="allRoles"
      :roles="allRoles"
    />
  </div>
</template>

<script>
import rpSidebarRolesList from './rp-sidebar-roles-list.vue'

export default {
  components: {
    rpSidebarRolesList
  },
  data() {
    return {
      filterText: ''
    }
  },
  computed: {
    allRoles() {
      const allRoles = this.$store.state.rolePermission.allRoles || []

      if (!this.filterText.trim()) return allRoles

      return (
        allRoles?.filter(e => {
          return e.name
            ?.toLowerCase()
            ?.trim()
            ?.includes(this.filterText?.trim()?.toLowerCase())
        }) || []
      )
    }
  },
  methods: {
    createNewRole() {
      this.$store.commit('ROLE_PERM_SET_CREATE_NEW_ROLE')
    }
  }
}
</script>

<style lang="scss">
.rp-sidebar-roles {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;

  &-header {
    display: flex;
    margin-bottom: 8px;
  }

  .icon-button {
    margin-left: 8px;
    cursor: initial;
    &.icon-button {
      padding: 6px !important;
    }
  }
}
</style>
