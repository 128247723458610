<template>
  <div class="r-tooltip">
    <span
      class="r-tooltip__text"
      :class="place"
    > {{ text }}</span>
    <slot />
  </div>
</template>

<script>
const places = ['top', 'bottom']
export default {
  props: {
    delay: {
      type: Number,
      default: 500
    },
    text: {
      type: String,
      required: true
    },
    place: {
      type: String,
      validator: place => places.includes(place),
      default: 'top'
    }
  }
}
</script>

<style lang="scss">
$transparent: transparent;
$text: var(--text_primary);
$bg: var(--field_droplist_bg);

.r-tooltip {
  position: relative;

  &__text {
    visibility: hidden;
    background-color: $bg;
    color: $text;
    position: absolute;
    min-width: 100px;
    max-width: 300px;
    width: 100%;
    border-radius: 4px;
    text-align: justify;
    padding: 0.5rem;
    z-index: 2000;
    line-height: 1.4;
    font-size: 12px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.33s;

    &::after {
      content: ' ';
      position: absolute;
      border-width: 5px;
      border-style: solid;
    }

    &.top {
      bottom: calc(100% + 8px);
      left: 50%;
      transform: translateX(-50%);

      &:after {
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-color: $bg $transparent $transparent $transparent;
      }
    }

    &.bottom {
      top: calc(100% + 8px);
      left: 50%;
      transform: translateX(-50%);

      &:after {
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-color: $transparent $transparent $bg $transparent;
      }
    }
  }

  &:hover {
    .r-tooltip__text {
      visibility: visible;
      opacity: 1;
    }
  }
}
</style>
