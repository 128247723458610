<template>
  <div
    v-loading="loading"
    class="pt-router-build"
  >
    <div class="pt-router-build__body">
      <div class="pt-router-build__departure">
        <el-time-picker
          ref="time"
          v-model="departureTime"
          format="HH:mm"
          popper-class="router-build__time-picker"
          prefix-icon="none"
          clear-icon="none"
          @change="checkForLoad"
        />
        <button class="pt-router-build__departure-picker">
          <r-text color="caption">
            {{ `Время отправления ${dateTo24hTime(departureTime)}` }}
          </r-text>
        </button>
      </div>
      <div class="pt-router-build__selects">
        <el-select
          v-model="valueFrom"
          placeholder="Откуда"
          popper-class="pt-router-build-popper"
          :no-data-text="emptyText"
          :no-match-text="emptyText"
          filterable
          @change="checkForLoad"
        >
          <el-option
            v-for="item in fromOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            <stop-option :label="item.label" />
          </el-option>
        </el-select>
        <el-select
          v-model="valueTo"
          placeholder="Куда"
          popper-class="pt-router-build-popper"
          :no-match-text="emptyText"
          :no-data-text="emptyText"
          filterable
          @change="checkForLoad"
        >
          <el-option
            v-for="item in toOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            <stop-option :label="item.label" />
          </el-option>
        </el-select>
      </div>
      <button
        class="pt-router-build__reverse-btn"
        @click="reverseValues"
      >
        <r-icon
          name="sort-unselected"
          :size="24"
        />
      </button>
      <button
        class="pt-router-build__reset-button"
        :style="valueTo && valueFrom ? '' : 'opacity: 0.4;'"
        @click="resetRouting()"
      >
        Сбросить выбор
      </button>
    </div>
    <div
      v-if="error"
      class="pt-router-build__error"
    >
      <r-icon
        name="alert"
        color-type="accent-error"
      />
      <r-text>
        {{ error }}
      </r-text>
    </div>
    <div
      v-else
      class="pt-router-build__footer"
    >
      <ul
        v-if="readyToBuild"
        class="pt-router-build__route-list route-list"
      >
        <r-title type="subtitle-2">
          Предложенные маршруты
        </r-title>
        <li
          v-for="(route, i) in routes"
          :key="i"
          class="route-list__item"
          @click="showRoute(route)"
        >
          <r-title
            type="subtitle-1"
            class="route-list__item-title"
          >
            {{ formatMinutes(Math.round(route.options.time / 60000)) }}
          </r-title>

          <r-text
            type="caption"
            class="route-list__item-caption"
          >
            {{ ` ${(route.options.distance / 1000).toFixed(1)} км` }}
          </r-text>

          <r-icon
            class="route-list__item-button"
            name="arrow"
            :size="22"
            color="var(--text_subhead)"
          />
          <ul class="route-list__legs">
            <li
              v-for="(leg, index) in route.options.legs"
              :key="index"
              class="route-list__legs-item"
            >
              <r-icon
                class="route-list__legs-item-icon"
                :name="leg.type === 'walk' ? 'walking' : 'bus'"
                :size="16"
                color="var(--text_subhead)"
              />
              <r-text type="caption">
                {{
                  leg.type === 'walk'
                    ? `${(leg.distance / 1000).toFixed(1)} км`
                    : `${formatMinutes(Math.round(leg.travel_time / 60000))}`
                }}
              </r-text>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import StopOption from './router-build-option.vue'
import './time-picker.scss'
import { pointsToGeojson } from '@/utils/json'
import { formatMinutes, dateTo24hTime } from '@/utils'

export default {
  components: { StopOption },
  data() {
    return {
      valueFrom: '',
      valueTo: '',
      loading: false,
      routes: null,
      error: null,
      testURL: null,
      formatMinutes,
      dateTo24hTime,
      departureTime: new Date(),
      identicalStops: 'Выберите разные остановки',
      emptyText: 'Нет результатов',
      appointment: ''
    }
  },
  computed: {
    fromOptions() {
      return this.options?.filter(stop => stop.value !== this.valueTo)
    },
    toOptions() {
      return this.options?.filter(stop => stop.value !== this.valueFrom)
    },
    options() {
      return this.$store.state.publicTransport.lists.stop_points.map(s => {
        return {
          value: s.id,
          label: s.name || `Остановка № ${s.no}`
        }
      })
    },
    popup() {
      const { from, to } = this.$store.state.publicTransport.popup
      return from + to
    },
    selectedStop() {
      return this.$store.state.publicTransportselectedStop
    },
    hasChanges() {
      return this.valueFrom + this.valueTo
    },
    readyToBuild() {
      return this.valueFrom && this.valueTo
    }
  },
  watch: {
    departureTime() {
      if (this.departureTime === null) {
        this.departureTime = new Date()
      }
    },
    selectedStop() {
      this.setSelectedStop()
    },
    popup() {
      this.checkSelected()
    },
    hasChanges() {
      const { valueTo, valueFrom } = this

      if (valueFrom && valueTo && valueFrom !== valueTo) {
        this.error = null
        this.checkForLoad()
      }
    }
  },
  mounted() {
    this.checkSelected()
  },
  beforeDestroy() {
    this.resetRouting()
  },
  methods: {
    checkSelected() {
      const { from, to } = this.$store.state.publicTransport.popup
      if (from) {
        this.valueFrom = from
      }
      if (to) {
        this.valueTo = to
      }
    },
    checkForLoad() {
      if (this.readyToBuild) {
        this.loadRoutes()
      }
      this.routes = null
    },
    resetRouting() {
      this.error = null
      const entities = ['activeRoute', 'userRoute', 'routeBbox', 'flyToGeom']
      entities.forEach(e => {
        this.$store.commit('SET_PT_FIELD', { field: e, value: null })
      })
      this.$store.commit('SET_PT_FIELD', {
        field: 'popup',
        value: { from: null, to: null }
      })
      this.departureTime = new Date()
      this.valueFrom = this.valueTo = ''
    },
    reverseValues() {
      const tempValue = this.valueFrom
      this.valueFrom = this.valueTo
      this.valueTo = tempValue
      this.checkForLoad()
    },
    async loadRoutes() {
      this.loading = true
      this.$store.commit('SET_PT_FIELD', { field: 'routeStops', value: null })

      const date = this.departureTime?.toISOString()

      const a = this.loadCoordinates(this.valueFrom)
      const b = this.loadCoordinates(this.valueTo)
      try {
        const { data } = await this.$store.dispatch('GET_MOB_REQUEST', {
          url:
          this.testURL ||
          `route_pt?point_a=${a}&point_b=${b}&departure_time=${date}`
        })
        this.routes = data.map(route => ({
          type: 'FeatureCollection',
          options: route,
          features: route.legs.map(item => ({
            type: 'Feature',
            properties: {
              ...item,
              ...route,
              color: item.type === 'walk' ? '#5D9E4E' : '#FF93fe',
              width: 4,
              dash: item.type === 'walk' ? [1, 2] : [1]
            },
            geometry: {
              coordinates:
              item.type === 'walk'
                ? [...item.geometry.coordinates]
                : [...item.match_points.coordinates],
              type: 'LineString'
            }
          }))
        }))

        this.error = null
      } catch (e) {
        this.error = 'Ошибка загрузки'
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    chooseOnMap(appointment) {
      this.appointment = appointment
      appointment === 'to' ? (this.valueTo = null) : (this.valueFrom = null)

      const entities = ['activeRoute', 'userRoute', 'routeBbox']
      entities.forEach(e => {
        this.$store.commit('SET_PT_FIELD', { field: e, value: null })
      })
      //   this.$store.commit('SET_PT_FIELD', { field: 'stopSelectionProcess', value: true })
    },
    setSelectedStop() {
      if (this.selectedStop) {
        if (this.appointment === 'from') {
          if (this.selectedStop !== this.valueTo) {
            this.valueFrom = this.selectedStop
          } else {
            this.routes = null
            this.error = this.identicalStops
          }
        } else if (this.appointment === 'to') {
          if (this.selectedStop !== this.valueFrom) {
            this.valueTo = this.selectedStop
          } else {
            this.routes = null
            this.error = this.identicalStops
          }
        }
      }

      this.$store.commit('SET_PT_FIELD', { field: 'selectedStop', value: null })
      // this.$store.commit('SET_PT_FIELD', { field: 'stopSelectionProcess', value: false })
    },
    showRoute(route) {
      this.loading = true
      if (route.features[2]?.properties.type === 'pt') {
        route.features[2].properties.color = '#C55151'
        route.features[2].properties.width = 3
      }
      // AB Points
      const coords = route.options.points.coordinates
      const extremePoints = [
        {
          name: 'A',
          coordinates: coords[0]
        },
        {
          name: 'B',
          coordinates: coords[coords.length - 1]
        }
      ]
      this.$store.commit('SET_PT_FIELD', {
        field: 'extremePoints',
        value: pointsToGeojson(extremePoints)
      })

      this.$store.commit('SET_PT_FIELD', {
        field: 'flyToGeom',
        value: route
      })

      this.$store.commit('SET_PT_FIELD', { field: 'userRoute', value: route })
      if (route.options.legs.length > 1) {
        const routeStops = route.options.legs
          .filter(i => i.type === 'pt')
          .map(i => i.stops)[0]
          .map(s => ({
            stop_point_id: s.stop_id,
            ...s
          }))

        if (routeStops) {
          this.$store.commit('SET_PT_FIELD', {
            field: 'lineRouteItems',
            value: routeStops
          })
        } else {
          this.$store.commit('SET_PT_FIELD', {
            field: 'lineRouteItems',
            value: null
          })
        }
      }

      this.loading = false
    },
    loadCoordinates(id) {
      return this.$store.state.publicTransport.lists.stop_points
        .find(s => s.id === id)
        .geom.coordinates.sort()
        .reverse()
        .toString()
        .replace(',', '%2C')
    }
  }
}
</script>

<style lang="scss">
.pt-router-build {
  display: grid;
  grid-auto-flow: row;
  align-content: start;
  text-align: center;
  justify-content: stretch;
  grid-gap: 0.5rem;
  padding: 0 0.5rem;
  position: relative;

  &__error {
    border-radius: var(--border-radius);
    border: 1px solid var(--accent_error);
    padding: 0.25rem;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
  }

  &__body {
    display: grid;
    grid-template-areas: 'departure departure' 'selects reverse' 'resetButton resetButton';
    grid-template-columns: auto 1.75rem;
    width: 100%;
    grid-gap: 0.5rem 0;
    justify-content: stretch;
    justify-self: center;
    max-width: 300px;
  }

  &__reset-button {
    grid-area: resetButton;
    display: flex;
    padding: 0 0.5rem;
    justify-content: flex-end;
    align-items: center;
    border: none;
    background-color: transparent;
    color: var(--accent_primary);
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
  }

  &__footer {
    display: grid;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
    overflow: auto;
    position: relative;
  }

  &__button {
    height: 2.25rem;
    border: 0;
    border-radius: var(--border-radius);
    padding: 0.5rem 1rem;
    background-color: var(--button_primary_bg);
    cursor: pointer;

    &:disabled {
      opacity: 40%;
    }
  }

  &__departure {
    display: grid;
    grid-area: departure;
    position: relative;

    .el-date-editor {
      width: 100% !important;
      .el-input__inner {
        cursor: pointer;
        position: absolute;
        opacity: 0;
        height: 30px;
        width: 100% !important;
        padding: 0 !important;
        z-index: 999;
      }
    }
    &-picker {
      border: 0;
      background: transparent;
      display: grid;
      width: fit-content;
      .r-text {
        border-bottom: 1px dashed var(--dividers_low_contrast);
      }
    }
  }

  &__selects {
    grid-area: selects;
    width: 100%;
    display: grid;
    position: relative;
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr;
    grid-gap: 0.75rem 0;
    max-width: 400px;
    & > div {
      width: 100% !important;
    }
  }
  .route-list {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.5rem;
    width: 100%;
    margin-top: 0.5rem;

    &__item {
      padding: 0.5rem 0 0.5rem 0;
      display: grid;
      background-color: var(--bg_containers);
      border-radius: var(--border-radius);
      grid-gap: 0.5rem 0.25rem;
      grid-template-areas: 'title caption button' 'roadmap roadmap button';
      grid-template-columns: auto 1fr auto;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      &:active {
        background-color: var(--accent_selected);
      }

      &-title {
        grid-area: title;
        padding-left: 0.5rem;
      }

      &-button {
        grid-area: button;
        display: grid;
        grid-auto-flow: column;
        grid-gap: 0.5rem;
        align-items: center;
        border: 0;
        border-radius: var(--border-radius);
        padding: 0.25rem 0;
        background-color: transparent;
        cursor: pointer;
      }

      &-caption {
        grid-area: caption;
      }
    }

    &__legs {
      grid-area: roadmap;
      display: flex;
      padding-left: 0.5rem;

      &-item {
        display: flex;
        align-items: center;
        .caption {
          line-height: 1;
          text-align: center;
        }

        &::after {
          content: '';
          border: 5px solid transparent;
          border-left: 5px solid var(--button_secondary_bg);
          margin-left: 5px;
        }
        &:last-child::after {
          display: none;
        }
        &-icon {
          padding: 0 0.1rem 0 0.05rem;
        }
      }
    }
  }
  &__reverse-btn {
    box-shadow: none;
    display: block;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
  }
  .caption {
    text-align: left;
  }
}
.pt-router-build-popper {
  .el-select-dropdown {
    &__item {
      height: 30px !important;
      padding: 0 10px !important;
    }
    &__list {
      max-width: 240px;
      line-height: 1 !important;
    }
  }
}
</style>
