<template>
  <div class="ed-view">
    <r-button
      type="primary"
      class="ed-view__add-button"
      @click="$emit('mode', 'create')"
    >
      Создать новую рассылку
    </r-button>
    <r-search-input
      placeholder="Поиск по темам"
      :filter-text="filterText"
      @change="e => filterChange(e)"
    />
    <div
      v-if="completedDistributions.length && !loading"
      class="ed-view__list"
    >
      <email-item
        v-for="(email, i) in filtered"
        :key="i"
        :email="email"
        :groups="groups"
      />
    </div>
    <r-text
      v-else
      type="caption"
    >
      Завершённые рассылки отсутствуют
    </r-text>
  </div>
</template>

<script>
import emailItem from './email-item'
import { hashToArray } from '@/utils'

export default {
  components: { emailItem },
  data() {
    return {
      filterText: '',
      loading: false,
      completedDistributions: [],
      source_id: 'c047c21d-bb28-4c83-8a26-479a1f55c8cb',
      groups: {}
    }
  },
  computed: {
    filtered() {
      return this.completedDistributions.filter(({ subject }) =>
        subject
          .toLowerCase()
          .trim()
          .includes(this.filterText.toLowerCase().trim())
      )
    }
  },
  mounted() {
    this.loadDistributions()
    this.getGroups()
  },
  methods: {
    filterChange(e) {
      this.filterText = e
    },
    async getGroups() {
      this.loading = true

      const config = {
        include: { subscribers: { only: ['id', 'email'] } }
      }
      const source_id = '08772af2-f329-48fa-b5a0-087a8cb5ed78'
      const url = `objectInfo/${source_id}?config=${JSON.stringify(config)}`

      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        this.groups = data
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async loadDistributions() {
      this.loading = true
      const config = {
        include: {
          created_user: { only: ['id', 'name'] }
        },
        order: ['created_at']
      }

      const url = `objectInfo/${this.source_id}?config=${JSON.stringify(
        config
      )}`

      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        this.completedDistributions = hashToArray(data).reverse()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.ed-view {
  display: grid;
  grid-gap: 1rem;
  align-items: start;
  align-content: start;
  height: 100%;
  overflow: hidden;
  max-height: 70vh;

  &__add-button {
    font-weight: 400 !important;
    justify-self: start;
  }

  .el-radio-group.ed-view__relevance-toggler {
    display: flex;
    z-index: 2;
    height: 36px;
    padding-right: 0.75rem;

    .el-radio-button {
      flex: 1;

      .el-radio-button__inner {
        height: 36px !important;
        display: grid;
        align-items: center;
        justify-content: center;
        grid-auto-flow: column;
        grid-gap: 0.5rem;
        padding: 0;
        background-color: var(--bg_containers);
        border: 0;
        box-shadow: none !important;
        font-size: 14px;
        color: var(--text_primary);
        font-weight: 400;
      }

      &.is-active {
        .el-radio-button__inner {
          background-color: var(--accent_active) !important;
        }
      }
    }
  }

  &__list {
    padding: 0 1rem;
    border-radius: var(--border-radius);
    display: grid;
    grid-gap: 1rem;
    height: 100%;
    overflow-y: auto;
    align-content: start;
  }
}
</style>
