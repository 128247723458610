const dependenciesConfig = {
  type: 'line',
  layout: {
    'line-join': 'round',
    'line-cap': 'round'
  },
  paint: {
    'line-color': '#e56566',
    'line-width': 1,
    'line-dasharray': [3, 6]
  }
}
const dependenciesLabelsConfig = {
  type: 'symbol',
  layout: {
    'symbol-placement': 'line-center',
    'text-field': '{type}',
    'text-size': 12,
    'text-letter-spacing': 0.05
  },
  paint: {
    'text-color': '#e56566',
    'text-halo-color': '#fff',
    'text-halo-width': 1
  }
}

export const getDependenciesData = (editor, objects, coords) => {
  objects.forEach(f => {
    const { id, geom, type } = f
    editor.dependenciesData[id] = {
      id,
      geometry: {
        type: 'LineString',
        coordinates: [coords, geom.coordinates[1]]
      },
      properties: {
        type
      }
    }
  })
}
export const createDependenciesHelpers = (map, objects) => {
  const data = {
    type: 'FeatureCollection',
    features: Object.values(objects)
  }

  if (!map.getSource('dependencies-helpers')) {
    map.addSource('dependencies-helpers', {
      type: 'geojson',
      data
    })
  } else map.getSource('dependencies-helpers').setData(data)

  if (!map.getLayer('dependencies-helpers')) {
    map.addLayer({
      id: 'dependencies-helpers',
      source: 'dependencies-helpers',
      ...dependenciesConfig
    })
    map.addLayer({
      id: 'dependencies-labels-helpers',
      source: 'dependencies-helpers',
      ...dependenciesLabelsConfig
    })
  }
}
export const removeDependenciesHelpers = map => {
  if (map.getLayer('dependencies-helpers')) { map.removeLayer('dependencies-helpers') }
  if (map.getLayer('dependencies-labels-helpers')) { map.removeLayer('dependencies-labels-helpers') }
  if (map.getSource('dependencies-helpers')) { map.removeSource('dependencies-helpers') }
}

// deleted dependencies helpers
const depTypes = ['links', 'connectors']
const deletedLinesConfig = {
  type: 'symbol',
  layout: {
    'symbol-placement': 'line',
    'text-field': '╳',
    'text-size': 12,
    'symbol-spacing': ['interpolate', ['linear'], ['zoom'], 12, 40, 22, 60],
    'text-keep-upright': false
  },
  paint: {
    'text-color': '#e56566',
    'text-halo-color': '#fff',
    'text-halo-width': 0.5
  }
}

export const createDeletedDepHelpers = (map, layers, ids) => {
  const depLayers = layers.filter(l => depTypes.indexOf(l.datatype) > -1)

  depLayers.forEach(l => {
    const { id, datatype } = l
    const layerId = `${datatype}_deleted`
    const filter = ['in', 'id', ...ids]

    if (map.getSource(id)) {
      // set deleted dependencies helpers - crosses
      if (!map.getLayer(layerId)) {
        map.addLayer({
          id: layerId,
          source: id,
          ...deletedLinesConfig,
          filter
        })
      } else {
        map.setFilter(layerId, filter)
      }
    }
  })
}

export const removeDeletedDepHelpers = (map, layers) => {
  const depLayers = layers.filter(l => depTypes.indexOf(l.datatype) > -1)

  depLayers.forEach(l => {
    const { datatype } = l
    const id = `${datatype}_deleted`

    if (map.getLayer(id)) map.removeLayer(id)
  })
}
