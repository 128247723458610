import vue from 'vue'
import cloneDeep from 'lodash.clonedeep'

const state = {
  activeObjectsInfo: {
    book: {},
    map: {}
  },
  activeCards: {
    book: [],
    map: []
  },
  activeCard: {
    book: null,
    map: null
  },
  needToCallCard: {
    id: null,
    sourcename: null
  }
}

const mutations = {
  SET_ACTIVE_CARD(state, { card, module }) {
    state.activeCard[module] = card
  },
  ADD_ACTIVE_CARD(state, info) {
    info.id = String(info.id)
    const found = state.activeCards[info.module].find(c => c.id === info.id)
    const lastTab =
      state.activeCards[info.module][state.activeCards[info.module].length - 1]
    info.tab_number = lastTab ? lastTab.tab_number + 1 : 1
    if (!found) {
      state.activeCards[info.module].push(info)
    } else {
      state.activeCard[info.module] = info.id
    }
  },
  CHANGE_ACTIVE_CARD(state, info) {
    info.id = String(info.id)
    const found = state.activeCards[info.module].find(c => c.id === info.id)
    const lastTab =
      state.activeCards[info.module][state.activeCards[info.module].length - 1]
    info.tab_number = lastTab ? lastTab.tab_number + 1 : 1
    if (!found) {
      const index = state.activeCards[info.module].findIndex(
        e => e.id === state.activeCard[info.module]
      )
      if (index > -1) {
        info.tab_number = state.activeCards[info.module][index].tab_number
        state.activeCards[info.module][index] = info
        state.activeCard[info.module] = info.id
      } else {
        state.activeCards[info.module].push(info)
      }
    } else {
      state.activeCard[info.module] = info.id
    }
  },
  REMOVE_ACTIVE_CARD(state, { id, module }) {
    state.activeCards[module].splice(
      state.activeCards[module].findIndex(c => String(c.id) === String(id)),
      1
    )
    if (state.activeCards[module].length === 1) { state.activeCards[module][0].tab_number = 1 }
  },
  UPDATE_ACTIVE_OBJECT_INFO(state, { data }) {
    // update active object info (card) by socket
    const moduleList = ['map', 'book']

    Object.keys(data).forEach(id => {
      moduleList.forEach(e => {
        if (state.activeObjectsInfo[e][id]) {
          Object.keys(data[id]).forEach(prop => {
            const current = state.activeObjectsInfo[e][id]
            const initial = current.initialObjectInfo.find(
              f => f.title === prop
            )
            const active = current.activeObjectInfo.find(f => f.title === prop)

            if (initial && active) {
              initial.value = data[id][prop]
              active.value = data[id][prop]
            }
            current.updated = true
          })
        }
      })
    })
  },
  ADD_ACTIVE_OBJECT_INFO(state, { data, sourcename, id, module }) {
    // open active object info (object card)
    const activeObjectInfo = []
    for (const title in data) {
      if (title === 'geom') continue // hack
      if (title === 'center_geom') continue // hack
      if (title === 'permission_objects') continue // hack
      const field = vue.$clientStore
        .getDataFields(sourcename)
        .find(item => item.title === title)
      if (!field) continue
      const { type, source_id, system_field, read_only, alias } = field
      activeObjectInfo.push({
        alias,
        title,
        type,
        value: data[title],
        source_id,
        system_field,
        read_only
      })
    }

    const activeObject = {
      activeObjectInfo: activeObjectInfo,
      initialObjectInfo: cloneDeep(activeObjectInfo),
      activeObjectInfoState: {
        id: id,
        source_id: sourcename
      },
      updated: false
    }
    vue.set(state.activeObjectsInfo[module], id, activeObject)
  },
  // REMOVE_ACTIVE_OBJECT_INFO(state, { module, id }) {
  //   // remove active object info
  //   if (state.activeObjectsInfo[module][id]) {
  //     delete state.activeObjectsInfo[module][id];
  //   }
  // },
  NEED_TO_CALL_CARD(state, { id = null, sourcename = null }) {
    vue.set(state.needToCallCard, 'id', id)
    vue.set(state.needToCallCard, 'sourcename', sourcename)
  }
}

export default {
  state,
  mutations
}
