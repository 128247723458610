<template>
  <div class="r-modal-card__input-field doc-item-info">
    <r-text color-type="secondary">
      {{ name }}
    </r-text>
    <r-text v-if="!isEditing">
      {{
        (parent_source
          ? source[parent_source][attr_name]
          : source[attr_name]) || '—'
      }}
    </r-text>
    <el-input
      v-if="isEditing && !parent_source"
      v-model="source[attr_name]"
      clearable
      class="r-input"
    />
    <el-input
      v-if="isEditing && parent_source"
      v-model="source[parent_source][attr_name]"
      clearable
      class="r-input"
    />
  </div>
</template>

<script>
export default {
  props: {
    source: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    name() {
      return this.item.name
    },
    parent_source() {
      return this.item.parent_source
    },
    attr_name() {
      return this.item.attr_name
    }
  }
}
</script>
