<template>
  <div class="reports">
    <reports-list />
    <reports-modal
      v-if="activeItem"
      :item="activeItem"
    />
  </div>
</template>

<script>
import reportsList from './components/reports-list/reports-list'
import reportsModal from './components/reports-modal/reports-modal'

import { testReportsList } from './configs'

export default {
  components: {
    reportsList,
    reportsModal
  },
  data() {
    return {
      testReportsList
    }
  },
  computed: {
    activeItem() {
      const activeId = this.$store.state.reports.activeId

      return this.testReportsList.find(e => e.id === activeId) || null
    }
  }
}
</script>

<style lang="scss">
.reports {
  position: relative;
  height: 100%;
  padding: 16px;
  width: calc(100% - 32px);
  overflow: hidden;
  max-height: 100%;
}
</style>
