export const usersTabsList = [
  {
    id: 0,
    name: 'Личная информация',
    value: 'personal'
  },
  {
    id: 1,
    name: 'Модули и компоненты',
    value: 'modules'
  },
  {
    id: 2,
    name: 'Источники данных',
    value: 'datasources'
  }
]
