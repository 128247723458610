import mapboxgl from 'mapbox-gl'
import { reportOptions } from '../configs'
import { jsonToGeojson } from '@/utils'
import { layersConfig } from '../components/an-map/configs'

export class PassingReportForPeriodAll {
  constructor(state) {
    this.$store = state.$store
    this.state = state
    this.mapgl = state.mapgl

    this.options = reportOptions.passing_report_for_period_all
  }

  getTableData(data) {
    const report = { ' ': 'Количество' }
    data[0].result.forEach(e => {
      report[e.name] = e.value
    })
    return [report]
  }

  getChartData(_, tableData) {
    const chartData = {
      data: [],
      fields: {
        data: ['1'],
        title: 'Объект'
      }
    }
    const itemData = tableData?.[0] || null
    if (itemData) {
      Object.keys(itemData).forEach(k => {
        if (k?.trim()) {
          chartData.data.push({
            title: k,
            data: [itemData[k]]
          })
        }
      })
    }

    return chartData
  }

  async getMapData() {
    try {
      const { data } = await this.$store.dispatch('GET_REQUEST', {
        url: 'objectInfo/15064f3c-2218-4276-94ce-c0b775895387'
      })
      const filteredData = Object.values(data).filter(e => e.cam_type === 'kpp_cam' || e.cam_type === 'astra')
      const geojson = jsonToGeojson(filteredData)

      return geojson
    } catch (e) {
      console.log(e)
      return null
    }
  }

  async addMapData(data) {
    this.mapgl.addSource('passing-statistic', {
      type: 'geojson',
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 36,
      data
    })

    // add clusters
    this.mapgl.addLayer({
      id: 'passing-statistic-clusters',
      source: 'passing-statistic',
      ...layersConfig.passing_statistic_clusters
    })
    this.mapgl.addLayer({
      id: 'passing-statistic-cluster-count',
      source: 'passing-statistic',
      ...layersConfig.passing_statistic_clusters_count
    })

    this.mapgl.addLayer({
      id: 'passing-statistic',
      source: 'passing-statistic',
      ...layersConfig.passing_statistic
    })

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: 15,
      anchor: 'left',
      className: 'info-layer__custom-popup'
    })

    this.mapgl.on('mouseenter', 'passing-statistic', (e) => {
      this.mapgl.getCanvas().style.cursor = 'pointer'

      const coordinates = e.features[0].geometry.coordinates.slice()
      const name = e.features[0].properties?.name

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
      }

      if (name) {
        popup
          .setLngLat(e.lngLat)
          .setHTML(
          `<dl>
              <dd>${name}</dd>
          </dl>`
          )
          .addTo(this.mapgl)
      }
    })

    this.mapgl.on('mouseleave', 'passing-statistic', () => {
      this.mapgl.getCanvas().style.cursor = ''
      popup.remove()
    })
  }
}
