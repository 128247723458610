<template>
  <div
    v-if="user"
    class="user-info"
  >
    <div class="user-info__title">
      <r-title type="subtitle-1">
        {{ $t('user-info:avatar') }}
      </r-title>
    </div>
    <avatar-upload-block
      :save-user="saveUser"
      :module="module"
    />
    <div class="user-info__title">
      <r-title type="subtitle-1">
        {{ $t('user-info:settings') }}
      </r-title>
    </div>
    <div class="user-info__block">
      <div
        v-for="field in filteredUserFields"
        :key="field.id"
        class="user-info__block-row"
      >
        <input-rendered
          :user="user"
          :field="field"
          :module="module"
        />
      </div>
      <div
        v-if="module === `account`"
        class="user-info__block-row__controls"
      >
        <el-tooltip
          :open-delay="1500"
          :content="`${$t('user-info:button:cancel')}`"
          placement="top"
        >
          <r-button
            :disabled="!isChanged.settings"
            simple
            @click="beforeCancelChanges('settings')"
          >
            {{ $t('user-info:button:cancel') }}
          </r-button>
        </el-tooltip>
        <el-tooltip
          :open-delay="1500"
          :content="`${$t('user-info:button:save')}`"
          placement="top"
        >
          <r-button
            :disabled="!isChanged.settings"
            type="primary"
            @click="beforeSaveUser"
          >
            {{ $t('user-info:button:save') }}
          </r-button>
        </el-tooltip>
      </div>
    </div>
    <div class="user-info__title">
      <r-title type="subtitle-1">
        {{ $t('user-info:information') }}
      </r-title>
    </div>
    <div class="user-info__block">
      <div
        v-for="field in userFieldsInfo"
        :key="field.id"
        class="user-info__block-row"
      >
        <input-rendered
          :user="user"
          :field="field"
        />
      </div>
      <div
        v-if="module === `account`"
        class="user-info__block-row__controls"
      >
        <el-tooltip
          :open-delay="1500"
          :content="`${$t('user-info:button:cancel')}`"
          placement="top"
        >
          <r-button
            :disabled="!isChanged.personal"
            simple
            @click="beforeCancelChanges('personal')"
          >
            {{ $t('user-info:button:cancel') }}
          </r-button>
        </el-tooltip>
        <el-tooltip
          :open-delay="1500"
          :content="`${$t('user-info:button:save')}`"
          placement="top"
        >
          <r-button
            :disabled="!isChanged.personal"
            type="primary"
            @click="beforeSaveUser"
          >
            {{ $t('user-info:button:save') }}
          </r-button>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import avatarUploadBlock from './components/user-info-avatar-upload'
import inputRendered from './renderers/user-info-input-renderer'

import { userFields, userFieldsInfo } from './configs/index'
import { notifyFactory, errorParser } from '@/utils'

export default {
  components: { avatarUploadBlock, inputRendered },
  props: {
    showPassword: {
      type: Boolean,
      default: false
    },
    module: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isChanged: {
        personal: false,
        settings: false
      },

      userFields,
      userFieldsInfo
    }
  },
  computed: {
    isCreateUser() {
      return this.$store.state.userRoles.isCreateUser
    },
    user() {
      const user =
        this.module === 'account'
          ? this.$store.state.userRoles.currentUser
          : this.$store.state.userRoles.activeUser

      return user
    },
    userModules() {
      return this.$store.state.userRoles.activeUserModules
    },
    userDS() {
      return this.$store.state.userRoles.activeUserDS
    },
    initialUser() {
      const initialUser =
        this.module === 'account'
          ? this.$store.state.userRoles.initialCurrentUser
          : this.$store.state.userRoles.initialUser

      return initialUser
    },
    isAdmin() {
      return this.$store.state.admin
    },
    filteredUserFields() {
      return this.userFields.filter(e => {
        if (e.value === 'password' && !this.showPassword) return false
        if (e.value === 'admin' && (!this.isAdmin || !this.showPassword)) {
          return false
        }
        return true
      })
    },
    isAllFieldsFilled() {
      const { name, email, first_name, last_name, company } = this.user
      return name && email && first_name && last_name && company
    }
  },
  watch: {
    user: {
      handler: function() {
        this.isChangedInfo()
      },
      deep: true,
      immediate: true
    },
    initialUser: {
      handler: function() {
        this.isChangedInfo()
      },
      deep: true,
      immediate: true
    },
    isChanged: {
      handler: function(value) {
        const hasChanges = !!(value.personal || value.settings)

        this.$store.commit('SET_ACTIVE_USER_CHANGES', {
          module: 'user',
          value: hasChanges
        })
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    isChangedInfo() {
      this.isChanged.settings = !!this.filteredUserFields.find(e => {
        if (!this.initialUser[e.value] && !this.user[e.value]) return false
        if (this.initialUser[e.value] !== this.user[e.value]) return true
      })
      this.isChanged.personal = !!this.userFieldsInfo.find(e => {
        if (!this.initialUser[e.value] && !this.user[e.value]) return false
        if (this.initialUser[e.value] !== this.user[e.value]) return true
      })
    },
    beforeCancelChanges(block) {
      const title = this.$t('before:title')
      const message = this.$t('before-cancel:text')
      const confirmButtonText = this.$t('button-confirm')
      const cancelButtonText = this.$t('button-cancel')
      this.$confirm(message, title, {
        customClass: 'r-message-box',
        closeOnPressEscape: true,
        closeOnClickModal: false,
        type: 'warning',
        confirmButtonText,
        cancelButtonText
      })
        .then(() => {
          this.cancelChanges(block)
        })
        .catch(() => {})
    },
    cancelChanges(block) {
      switch (block) {
        case 'settings':
          this.filteredUserFields.find(e => {
            this.user[e.value] = this.initialUser[e.value]
          })
          break
        case 'personal':
          this.userFieldsInfo.find(e => {
            this.user[e.value] = this.initialUser[e.value]
          })
          break
      }
    },
    updateUser() {
      if (this.module === 'account') {
        this.$store.commit('SET_INITIAL_USER_INFO', this.user)
      } else {
        this.$store.commit('SET_INITIAL_USER_ROLES_USER', {
          user: this.user,
          modules: this.userModules,
          ds: this.userDS
        })
      }
    },
    beforeSaveUser() {
      if (!this.isAllFieldsFilled) {
        const title = this.$t('user-info:save-notify:title')
        const message = this.$t('user-info:save-notify:text')
        this.$notify(notifyFactory('error', title, message))
        return
      }

      const title = this.$t('before:title')
      const message = this.$t('before-save:text')
      const confirmButtonText = this.$t('button-confirm')
      const cancelButtonText = this.$t('button-cancel')
      this.$confirm(message, title, {
        customClass: 'r-message-box',
        closeOnPressEscape: true,
        closeOnClickModal: false,
        type: 'warning',
        confirmButtonText,
        cancelButtonText
      })
        .then(() => {
          this.saveUser()
        })
        .catch(() => {})
    },
    saveUser() {
      switch (this.module) {
        case 'account':
          this.saveUserData()
          break
        case 'user-roles':
          this.editUserData()
          break
      }
    },
    async editUserData() {
      const mainConfig = { main_config: {} }

      mainConfig.user = this.user

      mainConfig.main_config.datasources = this.userDS.map(item => ({
        ...item,
        cruds: [item.create, item.read, item.update, item.delete, item.share]
      }))
      mainConfig.user.valid_until = mainConfig.user.valid_until
        ? this.$rDate.format(mainConfig.user.valid_until, 'iso')
        : null
      delete mainConfig.user.unfilled_fields
      if (!mainConfig.user.password) delete mainConfig.user.password
      const data = {
        ...mainConfig.user,
        permissions: {
          modules: this.userModules,
          datasources: mainConfig.main_config.datasources
        }
      }

      if (!this.isCreateUser) {
        try {
          await this.$store.dispatch('SAVE_USER_PERMS', data)
          const title = this.$t('user-info:edit-notify:title')
          const message = this.$t('user-info:edit-notify:text')
          this.$notify(notifyFactory('success', title, message))
          this.updateUser()
        } catch (error) {
          const title = this.$t('user-info:edit-notify:error-title')
          const message = this.$t('user-info:edit-notify:error-text')
          errorParser.call(this, error, message, title)
        }
      } else {
        try {
          this.$store.commit('SET_USER_ROLES_LOADING', true)
          await this.$store.dispatch('CREATE_USER', data)
          const title = this.$t('user-info:create-notify:title')
          const message = this.$t('user-info:create-notify:text')
          this.$notify(notifyFactory('success', title, message))
          this.updateUser()
          this.$store.commit('SET_USER_ROLES_USERS_UPDATE', true)
          this.$store.commit('SET_USER_ROLES_CHANGE_BY_NAME', data.name)
          this.$store.commit('SET_USER_ROLES_LOADING', false)
        } catch (error) {
          errorParser.call(this, error)
          this.$store.commit('SET_USER_ROLES_LOADING', false)
        }
      }
    },
    async saveUserData() {
      const dataUserObj = {
        email: this.user.email,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        middle_name: this.user.middle_name,
        id: this.user.id,
        avatar: this.user.avatar,
        company: this.user.company,
        position: this.user.position,
        name: this.user.name
      }
      if (this.user.role_name) dataUserObj.role_name = this.user.role_name
      if (this.user.password) dataUserObj.password = this.resultObj.password

      try {
        await this.$store.dispatch('PUT_REQUEST', {
          url: 'user',
          data: dataUserObj
        })
        this.$store.commit('UPDATE_USER_DATA', dataUserObj)
        this.$store.commit('SET_USER_DATA_BY_TOKEN', { user: dataUserObj })
        const title = this.$t('user-info:edit-notify:title')
        const message = this.$t('user-info:edit-notify:text')
        this.$notify(notifyFactory('success', title, message))
        this.userDataObj = Object.assign({}, this.resultObj)
        this.updateUser()
      } catch (error) {
        const title = this.$t('user-info:edit-notify:error-title')
        const message = this.$t('user-info:edit-notify:error-text')
        errorParser.call(this, error, message, title)
      }
    }
  }
}
</script>

<style lang="scss">
.user-info {
  width: 520px;
  &__title {
    margin-bottom: 12px;
  }
  &__block {
    margin-bottom: 28px;
    width: 100%;
    border: 1px solid;
    border-radius: var(--border-radius);
    padding: 24px;
    border-color: var(--dividers_low_contrast) !important;
    &.avatar-block {
      display: flex;
      align-items: center;
    }
    &-row {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
      &__controls {
        justify-content: end;
        display: grid;
        grid-gap: 0.5rem;
        grid-auto-flow: column;
      }
    }
  }
  &__avatar {
    height: 116px;
    width: 116px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    margin-right: 16px;
    .avatar-uploader {
      width: 100%;
      height: 100%;
      .el-upload {
        width: 100%;
        height: 100%;
      }
    }
    &-wrapper {
      height: 100%;
      width: 100%;
      background-color: var(--bg_containers) !important;
    }
    &-image {
      height: 100%;
      width: 100%;
      top: 50%;
      left: 50%;
      box-sizing: border-box;
    }
    &-info {
      width: 338px;
      &__row {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &__button-upload {
        margin-right: 8px;
      }
    }
    &-edit {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 20px;
      color: var(--white_white);
      opacity: 0;
      transition: all 0.15s ease;
      &:hover {
        color: var(--accent_primary);
      }
    }
    &-actions {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      height: 0;
      justify-content: center;
      background-color: var(--black_bg);
      transition: all 0.15s ease;
      .r-button {
        color: var(--accent_primary) !important;
      }
    }
    &:hover {
      .user-info__avatar {
        &-edit {
          opacity: 1;
        }
        &-actions {
          height: 32px;
        }
      }
    }
  }
  .r-date-picker {
    width: 100% !important;
  }
}
</style>

<i18n>
{
  "ru": {
    "user-info:avatar": "Аватар",
    "user-info:settings": "Настройки аккаунта",
    "user-info:information": "Персональные данные",

    "user-info:button:save": "Сохранить изменения",
    "user-info:button:cancel": "Отмена",

    "user-info:save-notify:title": "Необходимо заполнить все обязательные поля",
    "user-info:save-notify:text": "Заполните ФИО, данные об электронной почте и Вашей компании",

    "user-info:edit-notify:title": "Редактирование пользователя",
    "user-info:edit-notify:text": "Редактирование пользователя выполнено успешно",
    "user-info:edit-notify:error-title": "Ошибка",
    "user-info:edit-notify:error-text": "Редактирование пользователя не выполнено",

    "user-info:create-notify:title": "Создание пользователя",
    "user-info:create-notify:text": "Создание пользователя выполнено успешно",
    "user-info:create-notify:error-title": "Ошибка",
    "user-info:create-notify:error-text": "Создание пользователя не выполнено",

    "before:title": "Предупреждение",
    "before-save:text": "Все изменения для текущего пользователя будут сохранены. Вы уверены?",
    "before-cancel:text": "Все изменения для текущего пользователя будут отменены. Вы уверены?",
    "button-confirm": "Да",
    "button-cancel": "Отмена"
  },
  "en": {
    "user-info:avatar": "Avatar",
    "user-info:settings": "Account settings",
    "user-info:information": "Personal data",

    "user-info:button:save": "Save changes",
    "user-info:button:cancel": "Cancel",

    "user-info:save-notify:title": "All required fields must be filled",
    "user-info:save-notify:text": "Fill in the full name, data about e-mail and your company",

    "user-info:edit-notify:title": "User editing",
    "user-info:edit-notify:text": "User editing completed successfully",
    "user-info:edit-notify:error-title": "Error",
    "user-info:edit-notify:error-text": "User editing failed",

    "user-info:create-notify:title": "User creating",
    "user-info:create-notify:text": "User creating completed successfully",
    "user-info:create-notify:error-title": "Error",
    "user-info:create-notify:error-text": "User creating failed",

    "before:title": "Warning",
    "before-save:text": "All changes for the current user will be saved. Are you sure?",
    "before-cancel:text": "All changes for the current user will be discarded. Are you sure?",
    "button-confirm": "Yes",
    "button-cancel": "Cancel"
  }
}
</i18n>
