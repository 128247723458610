<template>
  <div class="map-editor-help">
    <div
      v-if="editorHelpShow"
      class="map-editor-help__container"
    >
      <div class="map-editor-help__subcontainer">
        <div class="map-editor-help__subtitle">
          Создание
        </div>
        <div class="map-editor-help__rows">
          <div class="map-editor-help__block">
            <strong>ЛКМ</strong> - создание объектов / вершин
          </div>
          <div class="map-editor-help__block">
            <strong>ДЛКМ</strong> - закончить создание геометрии (поставить последнюю
            вершину)
          </div>
          <div class="map-editor-help__block">
            <strong>Escape</strong> - отмена создания объекта
          </div>
        </div>
      </div>
      <div class="map-editor-help__subcontainer">
        <div class="map-editor-help__subtitle">
          Редактирование
        </div>
        <div class="map-editor-help__rows">
          <div class="map-editor-help__block">
            <strong>ЛКМ</strong> - выбор объекта / вершины
          </div>
          <div class="map-editor-help__block">
            <strong>Shift + ЛКМ</strong> - выбор нескольких объектов
          </div>
          <div class="map-editor-help__block">
            <strong>Перетаскивание вершин / объекта</strong> - редактирование геометрии
          </div>
          <div class="map-editor-help__block">
            <strong>Backspace</strong> - удаление объекта
          </div>
        </div>
      </div>
      <div class="map-editor-help__subcontainer">
        <div class="map-editor-help__subtitle">
          Разделение линка
        </div>
        <div class="map-editor-help__rows">
          <div class="map-editor-help__block">
            <strong>ЛКМ</strong> - добавление точки разделения линка
          </div>
        </div>
      </div>
    </div>
    <div
      class="map-editor-help__title"
      @click="editorHelpShow = !editorHelpShow"
    >
      <span
        :class="editorHelpShow ? 'el-icon-caret-top' : 'el-icon-caret-right'"
      />
      Помощь
    </div>
  </div>
</template>

<script>
export default {
  props: {
    state: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      editorHelpShow: true
    }
  }
}
</script>

<style lang="scss">
.map-editor-help {
  margin-top: auto;
  color: var(--text_secondary) !important;

  &__title {
    font-size: 12px;
    cursor: pointer;
    margin: 8px;
    margin-top: 4px;
  }
  &__subtitle {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
    padding: 4px;
    padding-left: 10px;
    border-radius: var(--border-radius);
    text-transform: uppercase;
    color: var(--text_primary) !important;

    i {
      margin-right: 2px;
    }
  }
  &__subcontainer {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
  &__block {
    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      margin-left: 5px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: var(--bg_containers) !important;
    }
    &:not(:first-child) {
      margin-top: 4px;
    }
  }
  &__container {
    font-size: 12px;
    padding: 8px;
    margin-bottom: 8px;
    border-top: 1px solid transparent;
    border-color: var(--dividers_low_contrast) !important;
    background-color: var(--bg_panel_primary) !important;
  }

  &__rows {
    padding: 8px;
    padding-top: 0;
  }
}
</style>
