<template>
  <div class="r-simple-card">
    <div
      :class="['r-simple-card__title-block', { noToggler }]"
      @click="toggleHandler()"
    >
      <r-icon
        v-if="iconName"
        :name="iconName"
        :size="20"
        style="margin-right:8px"
      />
      <div
        class="r-simple-card__title"
        style="margin-right:4px"
      >
        <r-title type="subtitle-2">
          {{ title || $t('title') }}
        </r-title>
        <r-text
          v-if="subtitle"
          type="caption"
          style="margin-top:2px"
        >
          {{ subtitle }}
        </r-text>
      </div>
      <div
        v-if="!noToggler"
        class="r-simple-card__title-block__toggler"
        style="margin-left:auto"
      >
        <r-icon
          :name="isOpen ? 'chevron-up' : 'chevron-down'"
          :size="20"
        />
      </div>
    </div>
    <transition
      name="fadeHeight"
      mode="out-in"
    >
      <div
        v-if="isOpen"
        class="r-simple-card__content"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    noToggler: {
      type: Boolean,
      default: false
    },
    defaultClosed: {
      type: Boolean,
      default: false
    },
    iconName: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isOpen: this.noToggler || !this.defaultClosed
    }
  },
  methods: {
    toggleHandler() {
      if (this.noToggler) return

      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style lang="scss">
.r-simple-card {
  position: relative;
  padding: 8px;
  background-color: var(--bg_containers) !important;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: auto;

  & + .r-simple-card {
    margin-top: 0.5rem;
  }
  &__title {
    width: 100%;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__title-block {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    .r-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 24px);
      display: block !important;
    }
    &.noToggler {
      cursor: default;
    }
  }

  &__content {
    margin-top: 8px;
    width: 100%;
  }
}
</style>

<i18n>
{
  "ru": {
    "title": "Название блока"
  },
  "en": {
    "title": "Title"
  }
}
</i18n>
