<template>
  <div :class="[`si-center__layout ${$mq}`]">
    <div class="si-center__layout-bg">
      <slot name="bg" />
    </div>
    <div :class="['si-center__layout-left-block', { hide: !isShowLeft }]">
      <div
        v-if="hasLeft"
        class="si-center__layout-toggler left"
        @click="toggleBlock('left')"
      >
        <el-tooltip
          :open-delay="500"
          :content="`${$t(getToggleTooltip('left'))}`"
          placement="bottom"
        >
          <r-icon
            :name="isShowLeft ? 'chevron-left' : 'chevron-right'"
            :size="24"
          />
        </el-tooltip>
      </div>
      <slot name="left-block" />
    </div>
    <div :class="['si-center__layout-bottom-block', { hide: !isShowBottom }]">
      <div
        v-if="hasBottom"
        class="si-center__layout-toggler bottom"
        @click="toggleBlock('bottom')"
      >
        <el-tooltip
          :open-delay="500"
          :content="`${$t(getToggleTooltip('bottom'))}`"
          placement="top"
        >
          <r-icon
            :name="isShowBottom ? 'chevron-down' : 'chevron-up'"
            :size="24"
          />
        </el-tooltip>
      </div>
      <div class="si-center__layout-bottom-block__wrapper">
        <slot name="bottom-block" />
      </div>
    </div>
    <div class="si-center__layout-right-block">
      <slot name="right-block" />
    </div>
    <div class="si-center__layout-top">
      <slot name="top" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hasLeft: {
      type: Boolean,
      default: false
    },
    hasBottom: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isShowLeft() {
      return this.$store.state.situationCenter.layout.isShow.left
    },
    isShowBottom() {
      return this.$store.state.situationCenter.layout.isShow.bottom
    }
  },
  methods: {
    toggleBlock(block) {
      this.$store.commit('TOGGLE_SI_CENTER_LAYOUT', block)
    },
    getToggleTooltip(k) {
      switch (k) {
        case 'left':
          return this.isShowLeft ? 'hide' : 'show'
        case 'bottom':
          return this.isShowBottom ? 'hide' : 'show'
      }
    }
  }
}
</script>

<style lang="scss">
.si-center__layout {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &-left-block {
    position: absolute;
    width: 260px;
    z-index: 1;
    top: 16px;
    left: 16px;
    & > div {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &.hide {
      overflow: hidden;
      width: 36px !important;
      & > div {
        opacity: 0;
      }
      .si-center__layout-toggler {
        opacity: 1;
        position: relative;
        right: 0;
      }
    }
  }
  &-bottom-block {
    position: absolute;
    height: 276px;
    z-index: 1;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-wrap: nowrap;
    max-width: 100vw;
    padding: 0 0 4px;
    overflow-x: auto;
    &__wrapper {
      height: 100%;
      display: flex;
      flex-wrap: nowrap;
      padding: 0 16px;
      & > div {
        margin-right: 16px;
        flex: 0 0 auto;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &.hide {
      overflow: hidden;
      height: 36px !important;
      overflow-x: hidden;
      & > div {
        opacity: 0;
      }
      .si-center__layout-toggler {
        opacity: 1;
      }
    }
  }
  &-right-block {
    position: absolute;
    z-index: 1111;
    top: 16px;
    right: 16px;
    & > div {
      align-items: flex-start;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &-top {
    position: absolute;
    width: auto;
    height: auto;
    z-index: 1;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
  }
  &-toggler {
    position: absolute;
    top: 0;
    z-index: 10;
    width: 24px;
    height: 35px;
    border-radius: var(--border-radius);
    background-color: var(--modal_bg) !important;
    box-shadow: 2px 0 4px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    &:hover {
      opacity: 0.9 !important;
    }
    &.left {
      right: -30px;
    }
    &.bottom {
      width: 35px;
      height: 24px;
      position: sticky;
      left: 4px;
      margin-right: -5px !important;
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "hide": "Свернуть",
    "show": "Развернуть"
  },
  "en": {
    "hide": "Hide",
    "show": "Show"
  }
}
</i18n>
