<template>
  <ul class="mt-filters">
    <filters-item
      :filter="statusFilter"
      @toggle-show="toggleShow"
      @change-handler="statusChangeHandler"
    />
    <filters-item
      v-for="filter in filledFilters"
      :key="filter.id"
      :filter="filter"
      @change-handler="changeHandler"
      @toggle-show="toggleShow"
    />
  </ul>
</template>

<script>
import { getVehiclesRequestConfig } from '../map/helpers'
import FiltersItem from '@/components/commons/monitoring/filter-item'

const filters = [
  {
    type: 'project',
    title: 'Проекты',
    format: 'count_by_project',
    items: [],
    show: false
  },
  {
    type: 'main_contractor_id',
    title: 'Подрядчики',
    format: 'count_by_contractor',
    items: [],
    show: false
  },
  {
    type: 'type_group_id',
    title: 'Тип ТС',
    format: 'count_by_type',
    items: [],
    show: false
  },
  {
    type: 'colour_id',
    title: 'Цвет',
    format: 'count_by_color',
    items: [],
    show: false
  }
]

export default {
  components: {
    FiltersItem
  },
  data() {
    return {
      filters
    }
  },
  computed: {
    filledFilters() {
      return this.filters.filter(f => f.items.length > 0)
    },
    statusFilter() {
      return this.$store.state.monitoring.statusFilter
    },
    filterCategories() {
      return this.$store.state.monitoring.filterCategories
    }
  },
  created() {
    this.loadFilters(true)
  },
  methods: {
    loadFilters(isInitial) {
      this.filters.forEach(async filter => {
        const whereConfig = getVehiclesRequestConfig(this.$store, true, this.filters)
        const config = {}

        if (whereConfig.where.length) {
          config.where = whereConfig.where
        }

        const uri = `vehicles_map?format=${filter.format}&config=${JSON.stringify(config)}`
        const { data } = await this.$store.dispatch('GET_REQUEST', { url: uri })
        const currentItems = filter.items
        const items = Object.values(data).map(v => ({
          ...v,
          active:
            isInitial || !!currentItems.find(item => item.id === v.id)?.active
        }))
        items.unshift({
          active:
            isInitial || !!currentItems.find(item => item.id === null)?.active,
          id: null,
          name: '<Не указано>'
        })
        this.$set(filter, 'items', items)
      })

      this.$store.commit('SET_MT_FIELD', {
        field: 'filters',
        value: this.filters
      })
    },
    statusChangeHandler() {
      this.$store.commit('SET_MT_FIELD', {
        field: 'statusFilterUpdated',
        value: true
      })

      this.loadFilters()
    },
    changeHandler({ type, items }) {
      const { filterCategories } = this.$store.state.monitoring
      filterCategories[type] = items.reduce((a, { id, active }) => {
        if (active) a.push(id)
        return a
      }, [])

      this.loadFilters(false)
    },
    toggleShow(filter) {
      const value = !filter.show

      this.filters.forEach(item => {
        this.$set(item, 'show', false)
      })
      this.$set(this.statusFilter, 'show', false)

      this.$set(filter, 'show', value)
    }
  }
}
</script>
