<template>
  <div class="restriction-modal">
    <div class="restriction-modal__controls">
      <r-button
        type="primary"
        class="restriction-modal__edit-button"
        icon="edit"
        @click="$emit('mode', 'edit')"
      >
        Редактировать
      </r-button>
      <r-delete-button
        mini
        @delete="deleteRestrictionEvent"
      />
    </div>

    <div class="restriction-modal__attribute-list">
      <div
        class="
          restriction-modal__attribute restriction-modal__attribute--created
        "
      >
        <r-text color-type="secondary">
          Дата создания
        </r-text>
        <r-text>
          {{ $rDate.format(event.created_at) }}
        </r-text>
      </div>

      <div class="restriction-modal__attribute">
        <r-text color-type="secondary">
          Название
        </r-text>
        <r-text>
          {{ event.name || '—' }}
        </r-text>
      </div>

      <div class="restriction-modal__attribute">
        <r-text color-type="secondary">
          Тип
        </r-text>
        <r-text>
          {{ event.type }}
        </r-text>
      </div>

      <div class="restriction-modal__attribute">
        <r-text color-type="secondary">
          Актуальность
        </r-text>
        <r-text>
          {{ event.relevance }}
        </r-text>
      </div>

      <div class="restriction-modal__attribute">
        <r-text color-type="secondary">
          Перекрытие
        </r-text>
        <r-text>
          {{ event.closed_line_count ? event.closed_line_count : 'Нет' }}
        </r-text>
      </div>

      <div class="restriction-modal__attribute">
        <r-text color-type="secondary">
          Адрес
        </r-text>
        <r-text>
          {{ event.address || 'Нет данных' }}
        </r-text>
      </div>

      <div class="restriction-modal__attribute">
        <r-text color-type="secondary">
          Заявка
        </r-text>
        <r-text>
          {{ event.requested ? 'Да' : 'Нет' }}
        </r-text>
      </div>

      <div class="restriction-modal__attribute">
        <r-text color-type="secondary">
          Начало
        </r-text>
        <r-text>
          {{ $rDate.format(event.start_time) }}
        </r-text>
      </div>

      <div class="restriction-modal__attribute">
        <r-text color-type="secondary">
          Окончание
        </r-text>
        <r-text>
          {{ $rDate.format(event.end_time) }}
        </r-text>
      </div>
    </div>

    <files-comments
      :id="event.id"
      :source_id="source_id"
      class="restriction-modal__row"
      container
      column
    />
  </div>
</template>

<script>
import filesComments from '@/components/files-comments/files-comments'

export default {
  components: { filesComments },
  props: {
    source_id: {
      type: String,
      required: true
    },
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      hasChanges: false
    }
  },
  methods: {
    async deleteRestrictionEvent() {
      try {
        await this.$store.dispatch('DELETE_REQUEST', {
          url: `objectInfo/${this.source_id}?id=${this.event.id}`
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.$store.commit('BDD_SET_FIELD', {
          field: 'updateRestrictions',
          value: true
        })

        this.$emit('mode', 'view')
        this.$store.commit('CLOSE_MODAL_WINDOW')
      }
    }
  }
}
</script>

<style lang="scss">
.restriction-modal {
  &__controls {
    display: grid;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: start;
    grid-auto-flow: column;
  }

  &__attribute {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.25rem;

    &-list {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr;
    }

    &--created {
      width: 100%;
      place-self: stretch;
      grid-column-start: 1;
      grid-column-end: 3;
    }

    .el-date-editor {
      &.el-input {
        width: 100%;
      }
    }
  }
}
</style>
