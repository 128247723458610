<template>
  <div class="mt-map">
    <div id="mt-map" />

    <div
      v-if="hasModule('Layers Top Panel') && mapReady"
      class="mt-map__layers"
    >
      <r-info-layers
        ref="infoLayers"
        :mapgl="mapgl"
        :enabled-layers="['graph', 'geozones', 'signs', 'events', 'tilelayers']"
        module="monitoringTmc"
      />
    </div>

    <div class="mt-map__actions">
      <r-map-tools
        v-if="mapgl"
        :tools="['baselayers', '3d', 'reset', 'screenshot']"
        :map="mapgl"
        :map-bearing="mapBearing"
        :is3d="is3d"
        :baselayer-id="baselayerId"
        @change-prop="changeProp"
        @toggle-base-layer="toggleBaselayer"
      />
      <mt-legend />
    </div>
    <popup-list :settings="popupListSettings" />
  </div>
</template>

<script>
import throttle from 'lodash.throttle'
import extent from 'turf-extent'
import { initMap } from './core'
import { loadIcons } from './helpers'
import MtLegend from './components/legend'
import { getMapConfigs } from '@/utils'
import popupList from './components/popup-list'

export default {
  components: {
    MtLegend,
    popupList
  },
  data() {
    return {
      mapgl: null,
      mapBearing: null,
      is3d: false,
      controllers: {},
      timer: null,
      mapReady: false,
      popupListSettings: {
        display: 'none',
        top: 0,
        left: 0,
        values: []
      }
    }
  },
  computed: {
    vehiclesLoading: {
      get() {
        return this.$store.state.monitoringTmc.vehiclesLoading
      },
      set(value) {
        this.$store.commit('MT_TMC_SET_FIELD', {
          field: 'vehiclesLoading',
          value
        })
      }
    },
    trackColors() {
      return this.$store.state.monitoringTmc.trackColors
    },
    baselayerId() {
      return this.$store.state.mapConfigs?.baselayers?.monitoringTmc || 7
    }
  },
  watch: {
    '$store.state.monitoringTmc.cardId'(id) {
      this.mapgl.resize()
      this.popupListSettings.display = 'none'

      this.controllers.vehicle.toggleActiveStyling(id)
    },
    '$store.state.monitoringTmc.isVehicleListOpened'() {
      this.mapgl.resize()
    },
    '$store.state.monitoringTmc.mainTrackGeom'(value) {
      this.controllers.track.toggleTrack(value, 'main')
    },
    '$store.state.monitoringTmc.secondTrackGeom'(value) {
      this.controllers.track.toggleTrack(
        this.$store.state.monitoringTmc.mainTrackGeom,
        'main'
      )
      this.controllers.track.toggleTrack(value, 'second')
    },
    '$store.state.monitoringTmc.fcTrackAgpz'(value) {
      this.controllers.fcTrack.toggleTrack(value, 'agpz')
    },
    '$store.state.monitoringTmc.fcTrackAghk'(value) {
      this.controllers.fcTrack.toggleTrack(value, 'aghk')
    },
    '$store.state.monitoringTmc.flyToGeom': function(geom) {
      if (!geom) return
      this.flyTo(geom)
      this.$store.commit('MT_TMC_SET_FIELD', {
        field: 'flyToGeom',
        value: null
      })
    },
    '$store.state.monitoringTmc.filterCategories': {
      handler: function(v) {
        this.controllers.vehicle.updateVehicles()
      },
      deep: true
    },
    '$store.state.charts.list.mt_tmc_track_chart.datazoom': {
      handler: throttle(function() {
        this.controllers.track.updateTracks()
      }, 500),
      deep: true
    },
    '$store.state.charts.list.mt_tmc_track_chart.clickHandler': {
      handler: function(value) {
        this.controllers.track.showPointOnMap(value)
      },
      deep: true
    }
  },
  mounted() {
    this.$socket.emit('enter_module', 'monitoring')
    initMap(this)

    this.mapgl.once('load', () => {
      this.mapReady = true
    })
  },
  beforeDestroy() {
    this.$socket.emit('enter_module', 'monitoring_exit')
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    hasModule(name) {
      return this.$store.getters.hasModule(name)
    },
    async getVehicleCategory() {
      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: 'objectInfo/telemetry.categories'
        })
        const { id } = Object.values(data).find(e => e.name === 'ТС с ТМЦ')

        this.$store.commit('MT_TMC_SET_FIELD', {
          field: 'categoryId',
          value: id
        })
      } catch (e) {
        console.log(e)
      }
    },
    flyTo(geom) {
      if (!geom) return
      const bounds = extent(geom)

      this.mapgl.fitBounds(bounds, {
        padding: 20
      })
    },
    changeProp({ field, value }) {
      this[field] = value
    },
    toggleBaselayer({ id }) {
      this.$store.commit('MAP_CONFIGS_SET_CONFIG', {
        field: 'baselayers',
        module: 'monitoringTmc',
        value: id
      })
      const { baselayer } = getMapConfigs(this, 'monitoringTmc')

      this.mapgl.setStyle(baselayer)
      this.mapgl.once('style.load', async() => {
        await loadIcons(this)
        await this.$refs.infoLayers.rerenderComponent()
        this.controllers.vehicle.addVehicles()
      })
    }
  }
}
</script>

<style lang="scss">
.mt-map {
  width: 100%;
  position: relative;
  flex: 1;

  &__layers {
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__actions {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
  }

  #mt-map {
    width: 100%;
    height: 100%;
  }

  .mapboxgl-canvas {
    &:focus {
      outline: none;
    }
  }
  .mapboxgl-ctrl-top-right {
    display: none;
  }
}
</style>
