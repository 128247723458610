<template>
  <component :is="componentName" />
</template>

<script>
export default {
  components: {
    createModal: () => import('./create'),
    cardModal: () => import('./card')
  },
  computed: {
    componentName() {
      const active = this.$store.state.bdd.act

      return active ? 'card-modal' : 'create-modal'
    }
  }
}
</script>
