<template>
  <div class="tasks-modal">
    <div class="tasks-modal-list-wrap">
      <r-title type="subtitle-2">
        {{ $t('tasks:title-active-tasks') }}
      </r-title>
      <div
        v-if="activeUserTasks.length"
        class="tasks-modal__active-tasks__wrapper"
      >
        <tasks-modal-list :tasks="activeUserTasks" />
      </div>
      <r-text
        v-else
        type="caption"
      >
        {{ $t('tasks.no-active-tasks') }}
      </r-text>
    </div>
    <div class="tasks-modal-list-wrap">
      <r-title type="subtitle-2">
        {{ $t('tasks:title-finished-tasks') }}
      </r-title>
      <div
        v-if="finishedUserTasks.length"
        class="tasks-modal__active-tasks__wrapper"
      >
        <tasks-modal-list :tasks="finishedUserTasks" />
      </div>
      <r-text
        v-else
        type="caption"
      >
        {{ $t('tasks.no-finished-tasks') }}
      </r-text>
    </div>
  </div>
</template>

<script>
import tasksModalList from './tasks-modal-list'

export default {
  components: { tasksModalList },
  computed: {
    allUserTasks() {
      return this.$store.state.userTasks.list || []
    },
    activeUserTasks() {
      return this.allUserTasks
        .filter(e => !e.done)
        .sort((a, b) => {
          const aS = this.$rDate.format(a.start_time, 'x')
          const bS = this.$rDate.format(b.start_time, 'x')
          return aS > bS ? -1 : aS < bS ? 1 : 0
        })
    },
    finishedUserTasks() {
      return this.allUserTasks
        .filter(e => e.done)
        .sort((a, b) => {
          const aS = this.$rDate.format(a.start_time, 'x')
          const bS = this.$rDate.format(b.start_time, 'x')
          return aS > bS ? -1 : aS < bS ? 1 : 0
        })
    }
  }
}
</script>

<style lang="scss">
.tasks-modal {
  overflow: auto;
  max-height: 600px;
  width: 500px;
  &-list-wrap {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
    .r-title {
      margin-bottom: 4px;
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "tasks:title-active-tasks": "Активные задачи",
    "tasks:title-finished-tasks": "Завершенные задачи",
    "tasks.no-active-tasks": "Активные задачи отсутствуют",
    "tasks.no-finished-tasks": "Завершенные задачи отсутствуют"
  },
  "en": {
    "tasks:title-active-tasks": "Active tasks",
    "tasks:title-finished-tasks": "Finished tasks",
    "tasks.no-active-tasks": "No active tasks",
    "tasks.no-finished-tasks": "No finished tasks"
  }
}
</i18n>
