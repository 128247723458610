<template>
  <div class="account-menu__info">
    <div class="account-menu__avatar">
      <img
        v-if="user.avatar"
        :src="`${baseURL}/${downloadURL}/${user.avatar}`"
        :alt="$t('user-avatar')"
      >
      <r-icon
        v-else
        name="user-avatar"
        :size="64"
      />
    </div>
    <div class="account-menu__user-info">
      <div class="account-menu__name">
        <r-title>
          {{ getName() }}
        </r-title>
      </div>
      <div class="account-menu__email">
        <r-text>{{ user.email || '' }}</r-text>
      </div>
    </div>
  </div>
</template>

<script>
import constants from '@/constants/url'

export default {
  props: {
    user: {
      type: Object,
      default: () => null,
      required: true
    }
  },
  data() {
    return {
      baseURL: constants.URL,
      downloadURL: 'download_share_resource'
    }
  },
  methods: {
    getName() {
      const name = `${this.user.first_name || ''} ${this.user.last_name ||
        ''}`.trim()

      return name || this.user.name || 'не авторизован'
    }
  }
}
</script>

<style lang="scss" scoped>
.account-menu {
  &__info {
    display: grid;
    grid-template-columns: 4rem auto;
    align-items: center;
    border-bottom: 1px solid;
    padding: 0.5rem 0 1rem 0.5rem;
    border-color: var(--dividers_low_contrast) !important;
    overflow: hidden;
    position: relative;
  }

  &__avatar {
    height: 4rem;
    width: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--dividers_low_contrast);
    overflow: hidden;
    border-radius: 50%;

    img {
      object-fit: cover;
      width: 4rem;
      height: 4rem;
    }
  }
  &__user-info {
    margin-left: 14px;
    position: relative;
    overflow: hidden;
    width: 100%;
  }
  &__name,
  &__email {
    .r-text,
    .r-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "user-avatar": "Аватар пользователя"
  },
  "en": {
    "user-avatar": "User avatar"
  }
}
</i18n>
