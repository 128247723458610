<template>
  <r-button
    class="map-toggler"
    type="secondary"
    :icon="mapVisible ? 'hide-map' : 'ksodd-map'"
    :active="mapVisible"
    @click="$emit('toggle')"
  >
    {{ $t(`map-toggler:${mapVisible ? 'hide' : 'show'}`) }}
  </r-button>
</template>

<script>
export default {
  props: {
    mapVisible: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss">
.map-toggler {
  display: flex;
  border-radius: var(--border-radius);
  border: none;
  height: 2.25rem;
  align-items: center;
  color: var(--text_subhead);
  font-weight: 600;
  background-color: transparent;
  cursor: pointer;
  font-size: 14px;
  transition: 0.15s;

  &:hover {
    color: var(--button_primary_bg);
    background-color: var(--accent_hover);

    svg {
      fill: var(--button_primary_bg);
    }
  }

  &.active {
    background-color: var(--accent_selected);
    color: var(--button_primary_bg);

    svg {
      fill: var(--button_primary_bg);
    }

    &:hover {
      color: var(--text_subhead);
      background-color: transparent;

      svg {
        fill: var(--text_subhead);
      }
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "map-toggler:hide": "Скрыть карту",
    "map-toggler:show": "Показать карту"
  },
  "en": {
    "map-toggler:hide": "Hide map",
    "map-toggler:show": "Show map"
  }
}
</i18n>
