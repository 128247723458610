<template>
  <div class="an-select-modal">
    <component :is="modalTab" />
  </div>
</template>

<script>
import selection from './an-select-modal-report-selection.vue'
import options from './an-select-modal-report-options.vue'

export default {
  components: {
    selection,
    options
  },
  computed: {
    modalTab() {
      return this.$store.state.analytics.modalTab || 'selection'
    }
  }
}
</script>

<style lang="scss">
.an-select-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--modal_overlay);
  z-index: 1000;
}
</style>
