import mapboxgl from 'mapbox-gl'
import { mapToken } from '@/constants/tokens'
import mapboxStyles from '@/constants/mapbox_styles'

export const initMap = component => {
  mapboxgl.accessToken = mapToken

  component.mapgl = new mapboxgl.Map({
    container: 'pot-1-map',
    center: { lat: 51.521, lng: 128.1828 },
    zoom: 12,
    style: mapboxStyles.bright
  })
  component.mapgl.on('load', () => {
    component.addMapLayers()
  })
}
