import mapboxgl from 'mapbox-gl'
import { mapToken } from '@/constants/tokens'
import { getFirstSymbolId, mapIcons } from '../configs/'
import { widgetStyleConfig } from '../../../configs'

import { MapConfigEvents, getMapConfigs } from '@/utils'
import mapboxStyles from '@/constants/mapbox_styles'

export const initMap = component => {
  let { center, zoom } = getMapConfigs(component, 'situation_center_display')
  const theme = component.theme
  const style = component.isSatelliteActive
    ? mapboxStyles['satellite-streets']
    : theme === 'dark-theme'
      ? mapboxStyles.maptiler_dark
      : mapboxStyles.maptiler_basic

  mapboxgl.accessToken = mapToken

  center = center || component.mapConfig.center
  zoom = zoom || component.mapConfig.zoom
  const maxBounds = component.mapConfig.maxBounds

  component.mapgl = new mapboxgl.Map({
    container: 'situation-center-map',
    preserveDrawingBuffer: true,
    maxBounds,
    center,
    zoom,
    style
  })
  component.mapgl.dragRotate.disable()

  component.mapgl.on('mousemove', e => {
    const mapLayers = component.eventAvaliableLayers.filter(l =>
      component.mapgl.getLayer(l)
    )
    if (!mapLayers.length) return

    const { x, y } = e.point
    const bbox = [
      [x - 5, y - 5],
      [x + 5, y + 5]
    ]
    const features = component.mapgl.queryRenderedFeatures(bbox, {
      layers: mapLayers
    })

    if (!features.length) {
      component.mapgl.getCanvas().style.cursor = ''
      component.popupSettings.display = 'none'
      component.popupSettings.values = []
      return
    }

    component.mapgl.getCanvas().style.cursor = 'pointer'

    component.popupSettings.top = y - 38
    component.popupSettings.left = x + 10
    component.popupSettings.display = 'grid'

    const values = []

    features.forEach(f => {
      const value = {}
      const { properties, layer } = f
      const layerId = layer.id.includes('_sc_map')
        ? layer.id.split('_sc_map')[0]
        : layer.id
      const { icons, colors, fields } = widgetStyleConfig[layerId]
      value.colors = colors
      value.icons = icons
      value.layer_id = layerId

      if (Object.keys(fields).length) {
        value.type = properties[fields.type]
        value.values = Object.keys(fields).map(key => ({
          key,
          value:
            properties[fields[key]] === 'null' ? null : properties[fields[key]]
        }))
      }
      values.push(value)
    })
    component.popupSettings.values = values
  })

  component.mapgl.on('mouseout', () => {
    component.popupSettings.display = 'none'
    component.popupSettings.values = []
  })

  component.mapgl.on('movestart', () => {
    component.popupSettings.display = 'none'
    component.popupSettings.values = []
  })

  component.mapgl.on('click', e => {
    const mapLayers = component.eventAvaliableLayers.filter(l =>
      component.mapgl.getLayer(l)
    )
    if (!mapLayers.length) return

    const { x, y } = e.point
    const bbox = [
      [x - 5, y - 5],
      [x + 5, y + 5]
    ]
    const features = component.mapgl.queryRenderedFeatures(bbox, {
      layers: mapLayers
    })

    if (!features.length) return
    const feature = features[0]
    const { properties, layer } = feature
    const layerId = layer.id.includes('_sc_map')
      ? layer.id.split('_sc_map')[0]
      : layer.id

    component.targetClickHandler(feature, properties.id, layerId)
  })

  // controllers
  component.controllers.events = new MapConfigEvents(
    component,
    'situation_center_display'
  )

  component.mapgl.on('load', async() => {
    Promise.all(
      mapIcons.map(
        ({ name, icon }) =>
          new Promise(resolve => {
            component.mapgl.loadImage(component[icon], function(_error, res) {
              component.mapgl.addImage(name, res)
              resolve()
            })
          })
      )
    ).then(() => {
      component.isReady = true
      component.updateActiveLayers()
      component.updateActiveTileLayers()
    })

    if (component.tileLayers && component.tileLayers.length) {
      component.tileLayers.forEach(e => {
        if (!component.mapgl.getLayer(e.id)) {
          component.mapgl.addLayer(e, getFirstSymbolId(component.mapgl) || '')
          component.mapgl.setLayoutProperty(e.id, 'visibility', 'none')
        }
      })
    }

    component.controllers.events.addEventHandler()
  })
}
