<template>
  <div class="rp-users">
    <rp-users-wellcome v-if="!activeUserId" />
    <rp-users-content v-else />
  </div>
</template>

<script>
import rpUsersWellcome from './rp-users-wellcome'
import rpUsersContent from './rp-users-content'

export default {
  components: {
    rpUsersWellcome,
    rpUsersContent
  },
  computed: {
    activeUserId() {
      return this.$store.state.rolePermission.activeUserId || null
    }
  }
}
</script>

<style lang="scss">
.rp-users {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
