<template>
  <analytics />
</template>

<script>
import analytics from '@/components/analytics/analytics'

export default {
  components: { analytics }
}
</script>
