export const encodeUrl = url => {
  const withTZ = addTimezone(url)
  return withTZ.replaceAll('+', '%2b')
}

export const addTimezone = url => {
  const sep = url.includes('?') ? '&' : '?'
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return `${url}${sep}tz=${timezone}`
}

export const getHeaders = () => {
  const headers = {}

  if (getGroupId()) {
    headers.groupid = getGroupId()
  }

  return headers
}

export const getGroupId = () => {
  return JSON.parse(localStorage.getItem('group_id'))
}

export const getUsername = () => {
  return JSON.parse(localStorage.getItem('username'))
}

export const blobToBase64 = blob => {
  const reader = new FileReader()

  reader.readAsDataURL(blob)

  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result)
    }
  })
}
