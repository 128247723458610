<template>
  <r-simple-card :title="title">
    <r-color-picker
      :color="config[colorField]"
      :color-opacity="config[opacityField]"
      @change="changeProperty"
    />
  </r-simple-card>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: 'Цвет'
    },
    config: {
      type: Object,
      required: true
    },
    colorField: {
      type: String,
      required: true
    },
    opacityField: {
      type: String,
      required: true
    }
  },
  methods: {
    changeProperty({ color, opacity }) {
      this.config[this.colorField] = color
      this.config[this.opacityField] = opacity

      this.$cControllers.styles.changePaintProperty(this.id, this.colorField)
      this.$cControllers.styles.changePaintProperty(this.id, this.opacityField)
    }
  }
}
</script>

<style lang="scss"></style>
