import vue from 'vue'

const state = {
  applicationsSourceId: 'inspections.applications',
  applicationsStatusListSourceId: 'inspections.applications_status',
  applicationsHistoryUrl: 'inspections.applications_history',
  checklistsUrl: 'inspections.checklists',
  inspectorsUrl: 'inspections.inspectors',
  checklistAttrsUrl: 'inspections.attrs',
  checklistAttrsGroupUrl: 'inspections.attrs_groups',
  checklistAttrsValuesUrl: 'inspections.attrs_checklists',
  actsUrl: 'public.acts',
  applications: null,
  applicationsRelated: null,
  statuses: null,
  applicationsHistory: null,
  checklists: null,
  inspectors: null,
  contractors: null,
  vehicleTypes: null,
  activeApplication: null,
  openAfterUpdate: null,
  modalName: 'bddModalView',
  applicationListFilters: null,
  selectModalShow: false,
  selectModalAttrId: null,
  selectModalAttrName: null,
  loadAfterUpdate: null,
  confirmModalShow: false,
  updateAlbum: false,
  albumResources: null,
  rrflyToGeom: null,
  restriction: null,
  preventive: null,
  violation: null,
  act: null,
  actRelated: null,
  actObjectFields: null,
  pass: null,
  updateApplications: false,
  applicationModalMode: null,
  updateRestrictions: false,
  updatePreventive: false,
  updateViolations: false,
  updateActs: false,
  updatePass: false,
  passBasedOnAppId: null,

  filters: {
    violations: null,
    restrictions: null,
    preventive: null,
    pass: null,
    applications: null,
    acts: null
  }
}

const mutations = {
  BDD_SET_FIELD(state, { field, value }) {
    if (field.includes('.')) {
      const splited = field.split('.')

      vue.set(state[splited[0]], splited[1], value)
    } else {
      vue.set(state, field, value)
    }
  }
}

export default {
  state,
  mutations
}
