export const getDatasourceById = async(component, id, include = {}) => {
  const config = {
    where: [
      {
        field: 'id',
        op: '=',
        value: id,
        type: 'AND'
      }
    ],
    include
  }

  const { data } = await component.$store.dispatch('GET_REQUEST', {
    url: `permission_object?config=${JSON.stringify(config)}`
  })
  component.$store.commit('SET_DATASOURCE_FROM_TREE', {
    id,
    data: data[id]
  })

  return data[id]
}

export const getDatasourcesByDatatype = async(
  component,
  datatype
) => {
  const { data } = await component.$store.dispatch('GET_REQUEST', {
    url: `permission_object?format=by_datatype&datatype=${datatype}`
  })
  const datasources = Object.values(data)

  datasources.forEach(data =>
    component.$store.commit('SET_DATASOURCE_FROM_TREE', {
      id: data.id,
      data
    })
  )

  return datasources || []
}

export const getModelChildrenIds = children =>
  children.reduce((prev, ch) => {
    prev[ch.datatype] = ch.id

    return prev
  }, {})

export const hashToArray = function(data) {
  const arr = []

  for (const item in data) {
    arr.push(data[item])
  }

  return arr
}

export const hashToCleanArray = data => {
  const systemFields = [
    'created_at',
    'id',
    'created_by',
    'disabled',
    'geom',
    'updated_at',
    'updated_by'
  ]
  const arr = []

  const getValueByItem = item => {
    return Array.isArray(item)
      ? item.map(({ name }) => name).join('  |  ')
      : item instanceof Object
        ? item.name
        : typeof item === 'boolean'
          ? item
            ? 'Да'
            : 'Нет'
          : item || null
  }

  for (const item in data) {
    if (systemFields.indexOf(item) < 0) {
      arr.push({
        name: item,
        value: getValueByItem(data[item])
      })
    }
  }

  return arr
}

export const iconByItem = ({ geom_type, datatype, children }) => {
  if (children?.length && datatype !== 'model') return 'folder'
  switch (geom_type) {
    case 'line_string':
    case 'multi_line_string':
      return 'line-layer'
    case 'polygon':
    case 'multi_polygon':
      return 'polygon-layer'
    case 'point':
    case 'multi_point':
      return 'node-layer'
    default: {
      switch (datatype) {
        case 'model':
          return 'model-layer'
        case 'veh_journey_items':
        case 'line_route_items':
          return 'node-layer'
        case 'lines':
        case 'veh_journeys':
        case 'event_links':
          return 'line-layer'
        case 'event_classes':
          return 'work'
        case 'tile_layer':
          return 'image'
        case 'osm_buildings':
          return 'administration'
        case 'events':
        case 'time_profiles':
          return 'date-calendar'
        case 'matrices':
        case 'dod_matrices':
        case 'skim_matrices':
          return 'matrix-layer'
        case 'turns':
        case 'directions':
        case 'turn_tdas':
          return 'turns'
        case 'calculations':
          return 'modelling'
        default: {
          return 'folder-layer'
        }
      }
    }
  }
}
