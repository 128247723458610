<template>
  <div class="pt-route-card">
    <el-tabs
      v-model="activeTab"
      class="r-tabs"
    >
      <el-tab-pane
        v-for="item in tabs"
        :key="item.id"
        :name="item.id"
      >
        <r-text
          slot="label"
          class="r-tabs__label"
        >
          {{ item.title }}
        </r-text>
        <component :is="item.id" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import RouteInfo from './route-card/route-info'
import RouteStops from './route-card/route-stops'
import RouteTransport from './route-card/route-transport'

const tabs = [
  {
    id: 'route-info',
    title: '1. Информация о маршруте'
  },
  {
    id: 'route-stops',
    title: '2. Остановки'
  },
  {
    id: 'route-transport',
    title: '3. Транспорт'
  }
]

export default {
  components: {
    RouteInfo,
    RouteStops,
    RouteTransport
  },
  data() {
    return {
      tabs
    }
  },
  computed: {
    activeTab: {
      get() {
        return this.$store.state.publicTransport.editorState.routeActiveTab
      },
      set(value) {
        this.$store.commit('SET_PT_EDITOR_PROP', {
          field: 'routeActiveTab',
          value
        })
      }
    }
  }
}
</script>

<style lang="scss">
.pt-route-card {
  position: absolute;
  left: 16px;
  right: 16px;
  top: calc(100vh - 334px - 16px - 46px);
  height: 334px;
  background-color: var(--modal_bg);
  border-radius: var(--border-radius);
  z-index: 100;

  .el-tabs {
    &__nav {
      display: flex;
      width: 100%;

      &-wrap {
        margin-bottom: 0;
      }
    }

    &__item {
      flex: 1;
      text-align: center;
    }

    &__header {
      margin-bottom: 0px;
      overflow: visible !important;
    }

    &__content {
      padding: 16px;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }
  }
}
.el-tab-pane {
  display: flex;
  height: 100%;
  width: 100%;
}
.r-tabs {
  display: flex;
  flex-direction: column;
}
</style>
