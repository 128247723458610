<template>
  <div
    :style="
      `display: ${settings.display};top: ${settings.top}px; left: ${settings.left}px`
    "
    class="pt-router-popup"
  >
    <button
      class="pt-router-popup__button"
      @click="clickHandler('from')"
    >
      Отсюда
    </button>
    <button
      class="pt-router-popup__button"
      @click="clickHandler('to')"
    >
      Сюда
    </button>
  </div>
</template>

<script>
export default {
  props: {
    settings: {
      type: Object,
      default: () => null
    }
  },
  methods: {
    clickHandler(value) {
      this.$store.commit('SET_PT_FIELD', {
        field: 'popup',
        value:
          value === 'from'
            ? { from: this.settings.id }
            : { to: this.settings.id }
      })
      this.settings.display = 'none'
    }
  }
}
</script>

<style lang="scss">
.pt-router-popup {
  position: absolute;
  border: none;
  background-color: transparent;
  font-size: 12px;
  grid-gap: 0.5rem;
  display: grid;
  grid-auto-flow: column;
  z-index: 10;

  &__button {
    border: none;
    width: 70px;
    border-radius: var(--border-radius);
    padding: 0.5rem 0;
    cursor: pointer;
    color: var(--text_primary);
    background-color: var(--modal_bg);
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
    transition: 0.1s ease;
    &:hover {
      background-color: var(--accent_selected_noopacity);
    }
  }
}
</style>
