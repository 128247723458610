import cloneDeep from 'lodash.clonedeep'

export const setChartStyle = (state, variables) => {
  const colors = {}

  colors.bg = variables['--bg_panel_primary']
  colors.text = variables['--text_secondary']

  state.chartOption.textStyle = { color: colors.text }
  if (state.chartOption.legend) { state.chartOption.legend.textStyle = { color: colors.text } }
  if (state.chartOption.series[0].itemStyle) {
    state.chartOption.series[0].itemStyle = {
      borderWidth: 3,
      borderColor: colors.bg
    }
  }
}

export const buildChart = state => {
  let newData

  switch (state.type) {
    case 'type-1':
      newData = cloneDeep(state.pie)
      break
    case 'type-2':
      newData = cloneDeep(state.doughnut)
      break
    case 'type-3':
      newData = cloneDeep(state.pie)
      break
    default:
      newData = cloneDeep(state.pie)
      break
  }

  if (newData.series[0]) {
    newData.series[0].name = state.title
    newData.series[0].data = state.chartData
  }
  if (state.colors) newData.color = state.colors
  state.chartOption = cloneDeep(newData)

  state.setColorTheme()
}
