<template>
  <r-tabs v-model="active">
    <r-tab
      v-for="tab in tabs"
      :id="tab.label"
      :key="tab.label"
      :name="tab.title"
    />
  </r-tabs>
</template>

<script>
export default {
  props: {
    viewTab: {
      type: String,
      required: true
    },
    tabs: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      active: this.viewTab
    }
  },
  watch: {
    active(tab) {
      this.$emit('changeTab', tab)
    }
  }
}
</script>
