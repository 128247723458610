<template>
  <div class="journal-settings">
    <r-text color-type="secondary">
      Выберите типы уведомлений
    </r-text>

    <types-list
      v-if="currentTypes.length"
      :current-types="currentTypes"
      @hasChanges="hasChanges = true"
    />

    <div class="journal-settings__car-select">
      <r-text color-type="secondary">
        Выберите конкретные транспортные средства
      </r-text>

      <el-select
        v-model="currentCars"
        class="r-select"
        multiple
        filterable
        remote
        reserve-keyword
        placeholder="Поиск по ГРЗ"
        :remote-method="vehicleRemoteSearch"
        :loading="vehicleLoading"
        @change="hasChanges = true"
      >
        <el-option
          v-for="item in foundCars"
          :key="item.id"
          :label="item.reg_number"
          :value="item.id"
        />
      </el-select>
    </div>

    <div class="journal-settings__footer">
      <r-button
        type="primary"
        :loading="loading"
        :disabled="!hasChanges"
        @click="saveSettings"
      >
        Сохранить
      </r-button>
    </div>
  </div>
</template>

<script>
import typesList from './types-list'
import { getEventColor } from '../../../journal/helpers/data-formatting'

export default {
  components: {
    typesList
  },
  data() {
    return {
      loading: false,
      hasChanges: false,
      vehicleLoading: false,
      currentTypes: [],
      currentCars: [],
      foundCars: []
    }
  },
  computed: {
    userId() {
      return this.$store.state.auth?.user?.id
    },
    initialTypes() {
      return this.$store.state.journal.userNoticesTypes
    },
    initialCars() {
      return this.$store.state.journal.userNoticesCars
    }
  },
  methods: {
    async vehicleRemoteSearch(query) {
      if (!query) {
        this.foundCars = []
        return
      }

      try {
        this.vehicleLoading = true

        const sourceId = 'f09e59fb-ee58-482c-8b0f-9ac520cf2099'
        const config = {
          only: ['id', 'reg_number'],
          where: [
            {
              field: 'reg_number',
              value: query,
              op: 'like'
            }
          ]
        }
        const url = `objectInfo/${sourceId}?config=${JSON.stringify(config)}`
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        this.foundCars = Object.values(data)
      } catch (error) {
        console.log(error)
      } finally {
        this.vehicleLoading = false
      }
    },
    async getData() {
      this.$emit('toggle-loading', true)

      try {
        this.currentCars = this.initialCars.map(car => car.vehicle_id)
        this.foundCars = this.initialCars.map(car => ({
          id: car.vehicle_id,
          reg_number: car.vehicle?.reg_number
        }))

        // get all types
        const dataUrl = 'objectInfo/f365f523-f7d1-4823-a236-426c5ea75105'
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: dataUrl
        })

        this.currentTypes = Object.values(data).map(item => {
          const initial = this.initialTypes.find(
            object => object.notice_type_id === item.id
          )

          return {
            ...item,
            color: getEventColor(item.slug),
            active: !!initial,
            connectionId: initial ? initial.id : undefined
          }
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.$emit('toggle-loading', false)
      }
    },
    getTypesData() {
      const newItems = this.currentTypes
        .filter(item => !!item.active)
        .map(item => ({
          notice_type_id: item.id,
          id: item.connectionId || undefined
        }))
      const removedItems = this.currentTypes
        .filter(
          item =>
            !item.active &&
            this.initialTypes.findIndex(
              object => object.notice_type_id === item.id
            ) !== -1
        )
        .map(item => ({
          id: item.connectionId,
          notice_type_id: item.id,
          disabled: true
        }))

      return [...newItems, ...removedItems]
    },
    getCarsData() {
      const newItems = this.currentCars
        .filter(id => !this.initialCars.find(c => c.vehicle_id === id))
        .map(vehicle_id => ({ vehicle_id }))
      const removedItems = this.initialCars
        .filter(c => !this.currentCars.includes(c.vehicle_id))
        .map(item => ({ id: item.id, disabled: true }))

      return [...newItems, ...removedItems]
    },
    async saveSettings() {
      try {
        this.loading = true

        const data = {
          id: this.userId,
          user_notices_types: this.getTypesData(),
          user_notices_cars: this.getCarsData()
        }

        const { data: user } = await this.$store.dispatch('POST_REQUEST', {
          url: 'user',
          data
        })

        this.$store.commit('SET_JOURNAL_FIELD', [
          'userNoticesTypes',
          user.user_notices_types.filter(
            item => !!item.notice_type_id && !item.disabled
          )
        ])
        this.$store.commit('SET_JOURNAL_FIELD', [
          'userNoticesCars',
          user.user_notices_cars.filter(item => !item.disabled)
        ])

        await this.getData()

        this.$emit('update-settings')
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.journal-settings {
  display: grid;
  grid-gap: 0.5rem;
  height: 100%;
  max-height: 88vh;
  overflow: auto;

  &__footer .r-button {
    width: 100%;
  }

  &__car-select {
    display: grid;
    grid-gap: 0.5rem;
  }
}
</style>
