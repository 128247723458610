<template>
  <div class="map-legend-diagram">
    <div v-if="item.diagram.type === 'simple'">
      <div class="map-legend-table">
        <div class="map-legend-table-header">
          эпюры по <strong>{{ item.diagram.field }}</strong>
        </div>
      </div>
      <div class="map-legend-row">
        <div
          class="map-legend-color"
          :style="`background-color: ${item.diagram.color}`"
        />
      </div>
    </div>
    <div v-else>
      <div class="map-legend-table">
        <div class="map-legend-table-header">
          эпюры по
          <strong>{{ item.diagram.first }}</strong> (вес), по
          <strong>{{ item.diagram.second }}</strong> (цвет)
        </div>
        <div class="map-legend-table-block">
          <div
            v-for="field in item.diagram.conditions"
            :key="field.id"
            class="map-legend-row"
          >
            <div
              class="map-legend-color"
              :style="`background-color: ${field.color}`"
            />
            <div class="map-legend-text">
              {{ field.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style></style>
