<template>
  <div class="pt-map">
    <div id="pt-map" />

    <div
      v-if="hasModule('Layers Top Panel') && mapReady"
      class="pt-map__layers"
    >
      <r-info-layers
        ref="infoLayers"
        :mapgl="mapgl"
        module="traffic_counts"
        :locked-layers="['graph']"
        :vehicle-category-id="vehicleCategoryId"
      />
    </div>

    <div class="pt-map__actions">
      <r-map-tools
        v-if="mapgl"
        :tools="['baselayers', '3d', 'reset', 'screenshot']"
        :map="mapgl"
        :map-bearing="mapBearing"
        :is3d="is3d"
        :baselayer-id="baselayerId"
        @change-prop="changeProp"
        @toggle-base-layer="toggleBaselayer"
      />

      <pt-legend v-if="$route.name === 'pt-view'" />
    </div>
    <r-map-loader v-if="loadingLayers.length > 0" />
    <pt-popup-list
      :settings="popupSettings"
      @close="closePopup"
    />
    <pt-router-popup
      v-if="$route.name === 'pt-router'"
      :settings="routerPopupSettings"
      @close="closePopup"
    />
  </div>
</template>

<script>
import extent from 'turf-extent'
import { mapColors } from '@/config/colors'
import { initMap, loadIcons } from './core'
import { layersConfig } from './configs'
import PtLegend from './components/legend'
import PtPopupList from './components/popup-list'
import PtRouterPopup from './components/router-popup'
import { getMapConfigs } from '@/utils'

export default {
  components: {
    PtLegend,
    PtPopupList,
    PtRouterPopup
  },
  data() {
    return {
      mapgl: null,
      draw: null,
      mapBearing: null,
      is3d: false,
      timer: null,
      controllers: {},
      modes: {},
      vehicleCategoryId: 'c357883a-d73b-444d-8a32-71e25151bfcd',
      popupSettings: {
        display: 'none',
        top: 0,
        left: 0,
        values: []
      },
      routerPopupSettings: {
        display: 'none',
        top: 0,
        left: 0,
        id: null
      },
      mapReady: false
    }
  },
  computed: {
    loadingLayers() {
      return this.$store.state.publicTransport.loadingLayers
    },
    cardType() {
      return this.$store.state.publicTransport.cardType
    },
    baselayerId() {
      return this.$store.state.mapConfigs?.baselayers?.traffic_counts || 5
    },
    requiresUpdating() {
      return this.$store.state.publicTransport.needToUpdateLists
    },
    userRoute() {
      return this.$store.state.publicTransport.userRoute
    },
    ids() {
      return this.$store.state.publicTransport.model.ids
    },
    routeName() {
      return this.$route.name
    }
  },
  watch: {
    // TODO: don't work
    // '$store.state.publicTransport.editorState.lriToDelete'(index) {
    //   if (index) {
    //     const { features } = this.draw.getAll()
    //     const [feature] = features

    //     if (!feature) return

    //     const { line_route_items } = feature.properties

    //     if (this.draw.get(feature.id)) {
    //       this.draw.setFeatureProperty(
    //         feature.id,
    //         'line_route_items',
    //         line_route_items.filter(item => item.index !== index)
    //       )
    //     }

    //     this.$store.commit('SET_PT_EDITOR_PROP', {
    //       field: 'lriToDelete',
    //       value: null
    //     })
    //   }
    // },
    requiresUpdating(val) {
      if (val) {
        this.updateLists()
      }
    },
    userRoute(route) {
      if (route) {
        this.controllers.router.addUserRoute()
      } else {
        this.controllers.router.clearUserRoute()
      }
    },
    routeName(val, prev) {
      this.initPage(val, prev)
    },
    '$store.state.publicTransport.cardId'(val, prev) {
      this.setInitialStyling()

      const { model } = this.$store.state.publicTransport
      const { ids } = model

      if (val) {
        const layerId = ids[this.cardType]
        const value = [
          'case',
          ['==', ['get', 'id'], val],
          mapColors.activeColor,
          mapColors.ptRouteColor
        ]

        if (this.cardType === 'stop_points') {
          this.mapgl.setPaintProperty(
            `${layerId}_points`,
            'circle-color',
            value
          )
        } else {
          this.mapgl.setPaintProperty(layerId, 'line-color', value)
        }
      }

      if (!!val && !!prev) {
        return
      }

      this.mapgl.resize({ noRequest: true })
    },
    '$store.state.publicTransport.flyToGeom': function(geom) {
      if (!geom) return
      if (this.$route.name === 'pt-router') {
        this.mapgl.setLayoutProperty(this.ids.lines, 'visibility', 'none')
      }

      const bounds = extent(geom)

      this.mapgl.fitBounds(bounds, {
        padding: 64,
        maxZoom: 17
      })
      this.$store.commit('SET_PT_FIELD', {
        field: 'flyToGeom',
        value: null
      })
    },
    '$store.state.publicTransport.cardGeom'(geometry) {
      const source = this.mapgl.getSource('pt-active-card')
      const noData = {
        type: 'FeatureCollection',
        features: []
      }

      if (!geometry) {
        source.setData(noData)
      } else {
        if (geometry.type === 'LineString' && source) {
          source.setData({
            type: 'FeatureCollection',
            features: [
              {
                geometry
              }
            ]
          })
        } else if (source) {
          source.setData(noData)
        }
      }
    },
    '$store.state.publicTransport.lineRouteItems'(items) {
      const { ids } = this.$store.state.publicTransport.model

      // set styling to stops
      if (items) {
        const spIds = items
          .filter(item => !!item.stop_point_id)
          .map(item => item.stop_point_id)
        const uniqueIds = spIds.filter((id, i) => spIds.indexOf(id) === i)
        const modifiedIds = uniqueIds.reduce((prev, el) => {
          return [...prev, el, mapColors.activeColor]
        }, [])
        const spValue = [
          'match',
          ['get', 'id'],
          ...modifiedIds,
          mapColors.ptRouteColor
        ]

        this.mapgl.setPaintProperty(
          `${ids.stop_points}_points`,
          'circle-color',
          spValue
        )
      } else {
        this.setInitialStyling()
      }
    }
  },
  mounted() {
    initMap(this)

    this.mapgl.once('load', () => {
      this.mapReady = true
    })
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.saveMapParams)
    this.saveMapParams()
  },
  methods: {
    hasModule(name) {
      return this.$store.getters.hasModule(name)
    },
    async updateLists() {
      await this.controllers.base.updateLayers()
      await this.controllers.model.loadLists()
      this.$store.commit('SET_PT_FIELD', {
        field: 'needToUpdateLists',
        value: false
      })
      this.mapgl.resize()
    },
    initPage(val, prev) {
      if (val === 'pt-view') {
        this.controllers.base.updateLayers()
        this.controllers.model.loadLists()
      }

      if (prev === 'pt-editor') {
        this.controllers.editor.disableEditor()
      }

      if (val === 'pt-router') {
        this.controllers.base.updateLayers()
        this.controllers.model.loadLists()
        this.controllers.router.initRouter()
      } else {
        this.controllers.router.clearUserRoute()
      }

      if (val === 'pt-editor') {
        const { mode } = this.$route.params
        if (mode === 'create') {
          this.controllers.editor.enableCreateMode()
        } else {
          this.controllers.editor.enableEditMode()
        }
      }

      this.mapgl.resize()
    },
    saveMapParams() {
      const zoom = this.mapgl.getZoom()
      const center = this.mapgl.getCenter()

      localStorage.setItem('ptMapCenter', JSON.stringify(center))
      localStorage.setItem('ptMapZoom', JSON.stringify(zoom))
    },
    changeProp({ field, value }) {
      this[field] = value
    },
    setInitialStyling() {
      const { ids } = this.$store.state.publicTransport.model
      const types = ['stop_points', 'lines']

      types.forEach(type => {
        const prop = type === 'stop_points' ? 'circle-color' : 'line-color'
        const layerId =
          type === 'stop_points' ? `${ids[type]}_points` : ids[type]

        this.mapgl.setPaintProperty(
          layerId,
          prop,
          layersConfig[type].paint[prop]
        )
      })
    },
    getObjectsLimit() {
      return 10000
    },
    toggleBaselayer({ id }) {
      this.$store.commit('MAP_CONFIGS_SET_CONFIG', {
        field: 'baselayers',
        module: 'traffic_counts',
        value: id
      })
      const { baselayer } = getMapConfigs(this, 'traffic_counts')

      this.mapgl.setStyle(baselayer)
      this.mapgl.once('style.load', async() => {
        await loadIcons(this)
        await this.$refs.infoLayers.rerenderComponent()
        this.controllers.base.addBaseLayers()
      })
    },
    closePopup() {
      this.popupSettings.display = 'none'
      this.popupSettings.values = []
    }
  }
}
</script>

<style lang="scss">
.pt-map {
  height: 100%;
  width: 100%;
  position: relative;
  flex: 1;

  &__layers {
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__actions {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
  }

  &__info-card {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1000;
  }

  #pt-map {
    width: 100%;
    height: 100%;
  }

  .mapboxgl-canvas {
    &:focus {
      outline: none;
    }
  }
  .mapboxgl-ctrl-top-right {
    display: none;
  }
}
</style>
