<template>
  <div class="mt-track-compare">
    <div class="mt-track-compare__item">
      <el-checkbox-button
        v-model="show"
        @change="toggle"
      >
        <r-icon
          name="chart-line"
          :size="18"
        />
        <span>
          {{ show ? 'Убрать сравнение' : 'Сравнить трек с другим ТС' }}
        </span>
      </el-checkbox-button>
    </div>
    <div
      v-if="show"
      class="mt-track-compare__item"
    >
      <el-select
        v-model="selectedTrackId"
        filterable
        @change="changeSecondTrackId"
      >
        <el-option
          v-for="item in filteredData"
          :key="item.id"
          :value="item.id"
          :label="item.number"
        >
          {{ item.number }}
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    activeId: {
      type: String,
      required: true
    },
    isEtran: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      selectedTrackId: null
    }
  },
  computed: {
    filteredData() {
      return this.data.filter(e => e.type_name !== 'ЖД' && e.id !== this.activeId)
    }
  },
  methods: {
    changeSecondTrackId(id) {
      if (!id) return

      this.$emit('change', id)
    },
    toggle() {
      this.selectedTrackId = null
      this.$emit('change', null)
    },
    clearCompare() {
      this.show = false
      this.selectedTrackId = null
    }
  }
}
</script>

<style lang="scss">
.mt-track-compare {
  display: flex;

  &__item {
    &:not(:first-child) {
      margin-left: 8px;
    }

    .el-checkbox-button {
      width: 208px;

      &__inner {
        display: flex;
        align-content: center;
        justify-content: center;
        width: 100%;
        height: 38px;
        line-height: 36px;
        border-radius: 4px !important;
        padding: 0 !important;
        background: transparent !important;
        border: none !important;
        color: var(--text_secondary) !important;

        .r-icon svg {
          fill: var(--text_secondary) !important;
        }

        span {
          font-size: 14px;
          margin-left: 8px;
        }
      }

      &.is-checked {
        .el-checkbox-button__inner {
          background: var(--accent_hover) !important;
          color: var(--accent_primary) !important;

          .r-icon svg {
            fill: var(--accent_primary) !important;
          }
        }
      }
    }

    .el-select {
      width: 200px;
    }
  }
}
</style>
