export const getVehiclesRequestConfig = (store) => {
  const filters = store.state.monitoringTmc.filters || []
  const { filterCategories } = store.state.monitoringTmc
  const include = {
    categories: {},
    vehicle_type: {
      include: {
        vehicle_type_group: {}
      }
    }
  }

  const where = []

  Object.keys(filterCategories).forEach(field => {
    const initFilter = filters?.find(e => e.type === field)
    const filterIds = initFilter?.items?.map(e => e.id)
    const activeValues = filterCategories[field]
    const allValues = activeValues?.length === filterIds?.length
    const op = 'in'
    const value = allValues ? [] : activeValues

    if (value.length) {
      let filterField

      switch (field) {
        case 'type_group_id':
          filterField = 'type'
          break
        default:
          filterField = field
          break
      }

      if (value.includes(null) || value.includes('')) {
        where.push({
          op,
          field: filterField,
          value: field === 'project' ? value.filter(v => !!v || v === '') : value.filter(v => !!v || v !== '')
        })
      } else {
        where.push({
          op,
          field: filterField,
          value: value.filter(v => !!v)
        })
      }
    }
  })

  return {
    where,
    include
  }
}

// load icons
const iconsBank = [
  'aghk-bus-default',
  'aghk-bus-disabled',
  'aghk-bus-selected',
  'aghk-car-default',
  'aghk-car-disabled',
  'aghk-car-selected',
  'aghk-train-default',
  'aghk-train-disabled',
  'aghk-train-selected',
  'aghk-truck-default',
  'aghk-truck-disabled',
  'aghk-truck-selected',
  'agpz-bus-default',
  'agpz-bus-disabled',
  'agpz-bus-selected',
  'agpz-car-default',
  'agpz-car-disabled',
  'agpz-car-selected',
  'agpz-train-default',
  'agpz-train-disabled',
  'agpz-train-selected',
  'agpz-truck-default',
  'agpz-truck-disabled',
  'agpz-truck-selected'
]
const icons = iconsBank.map(e => ({
  name: e,
  imageUrl: require(`@/assets/images/monitoring/${e}.svg`)
}))
const MAP_ICON_SIZE = 64

export const loadIcons = component => {
  return Promise.all(
    icons.map(
      ({ name, imageUrl }) =>
        new Promise(resolve => {
          const image = new Image(MAP_ICON_SIZE, MAP_ICON_SIZE)
          image.crossOrigin = 'Anonymous'
          image.style.backgroundPosition = '50%, 50%'
          image.style.backgroundSize = '100%'
          image.addEventListener('load', () => {
            component.mapgl.addImage(name, image)
            resolve()
          })
          image.src = imageUrl
        })
    )
  ).then(() => console.log('mt tmc icons loaded'))
}
