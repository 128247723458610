<template>
  <div
    v-if="isAdmin"
    class="r-modal-card__controls"
  >
    <div
      v-if="isEditing"
      class="r-modal-card__controls r-modal-card__controls--edit-mode"
    >
      <r-button
        type="success"
        @click="$emit('clickHandler', 'save')"
      >
        Сохранить
      </r-button>
      <r-button
        simple
        @click="$emit('clickHandler', 'discard')"
      >
        Отменить все изменения
      </r-button>
    </div>
    <div
      v-else
      class="r-modal-card__controls r-modal-card__controls--view-mode"
    >
      <r-button
        type="primary"
        @click="$emit('clickHandler', 'edit')"
      >
        Редактировать
      </r-button>
      <r-delete-button
        mini
        @delete="$emit('clickHandler', 'delete')"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isEditing: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isAdmin() {
      return !!this.$store.state.pot1.isAdmin
    }
  }
}
</script>
