<template>
  <div class="rp-roles-header">
    <div class="rp-roles-header__title">
      <r-text color-type="subhead">
        Название роли
      </r-text>
    </div>
    <div class="rp-roles-header__control">
      <el-input
        v-model="roleName"
        class="r-input"
        type="text"
        :disabled="isIbAdminRole || isAdminRole"
        placeholder="Диспетчер ЖД"
        clearable
      />
      <r-button
        v-if="!isIbAdminRole && !isAdminRole"
        type="success"
        :disabled="isDisabled"
        @click="saveHandler"
      >
        {{ isCreateNewRole ? 'Создать новую роль' : 'Сохранить изменения' }}
      </r-button>
      <r-button
        v-if="!isCreateNewRole && !isIbAdminRole && !isAdminRole"
        type="danger"
        style="margin-left: auto"
        @click="deleteRole"
      >
        Удалить роль
      </r-button>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import { notifyFactory } from '@/utils'

export default {
  data() {
    return {
      roleName: ''
    }
  },
  computed: {
    activeRoleId() {
      return this.$store.state.rolePermission.activeRoleId
    },
    isIbAdminRole() {
      return this.activeRoleId === 'ib_admin'
    },
    isAdminRole() {
      return this.activeRoleId === 'admin'
    },
    activeRole() {
      if (!this.activeRoleId) return null
      const allRoles = this.$store.state.rolePermission.allRoles

      return allRoles?.find(e => e.id === this.activeRoleId)
    },
    isCreateNewRole() {
      return this.$store.state.rolePermission.createNewRole
    },
    isDisabled() {
      const nameAvailable =
        !!this.roleName && this.roleName !== this.activeRole?.name
      const modulesChanged = !isEqual(this.roleModules, this.initialRoleModules)
      const datasourcesChanged = !isEqual(
        this.roleDatasources,
        this.initialRoleDatasources
      )

      return !nameAvailable && !modulesChanged && !datasourcesChanged
    },
    roleModules() {
      return this.$store.state.rolePermission.rolePerms?.modules || []
    },
    initialRoleModules() {
      return this.$store.state.rolePermission.initialRolePerms?.modules || []
    },
    roleDatasources() {
      return this.$store.state.rolePermission.rolePerms?.datasources || []
    },
    initialRoleDatasources() {
      return (
        this.$store.state.rolePermission.initialRolePerms?.datasources || []
      )
    }
  },
  watch: {
    activeRole: {
      handler: function(val) {
        this.setRoleName(val?.name)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    saveHandler() {
      if (this.isCreateNewRole) {
        this.createNewRole()
      } else {
        this.saveRole()
      }
    },
    async createNewRole() {
      this.$store.commit('ROLE_PERM_SET_IS_LOADING', true)

      try {
        const { data } = await this.$store.dispatch('POST_REQUEST', {
          url: 'objectInfo/auth.roles?',
          data: {
            name: this.roleName
          }
        })
        const newRoleId = data[0]?.id

        if (newRoleId) {
          await this.$store.dispatch('POST_REQUEST', {
            url: `role?role_id=${newRoleId}`,
            data: {
              permissions: {
                modules: this.roleModules.filter(e => e._action),
                datasources: this.roleDatasources.filter(e => e._action)
              }
            }
          })
          this.$store.commit('ROLE_PERM_SET_ACTIVE_ROLE', newRoleId)
          this.loadRolePermission(newRoleId)
        }
        const title = 'Редактирование роли'
        const message = 'Редактирование роли выполнено успешно'

        this.$notify(notifyFactory('success', title, message))
      } catch (e) {
        throw new Error(e)
      } finally {
        this.$store.commit('ROLE_PERM_SET_IS_LOADING', false)
      }

      this.$store.commit('ROLE_PERM_SET_FIELD', {
        field: 'updateRoles',
        value: true
      })
    },
    async loadRolePermission(roleId) {
      this.$store.commit('ROLE_PERM_SET_IS_LOADING', true)

      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `role?role_id=${roleId}&permissions=true`
        })

        this.$store.commit('ROLE_PERM_SET_ACTIVE_ROLE_DATA', data?.permissions)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.$store.commit('ROLE_PERM_SET_IS_LOADING', false)
      }
    },
    async saveRole() {
      this.$store.commit('ROLE_PERM_SET_IS_LOADING', true)
      if (!this.activeRoleId) return

      try {
        await this.$store.dispatch('POST_REQUEST', {
          url: `role?role_id=${this.activeRoleId}`,
          data: {
            name: this.roleName,
            permissions: {
              modules: this.roleModules.filter(e => e._action),
              datasources: this.roleDatasources.filter(e => e._action)
            }
          }
        })
        this.loadRolePermission(this.activeRoleId)
        const title = 'Редактирование роли'
        const message = 'Редактирование роли выполнено успешно'

        this.$notify(notifyFactory('success', title, message))
      } catch (e) {
        throw new Error(e)
      } finally {
        this.$store.commit('ROLE_PERM_SET_IS_LOADING', false)
      }
      this.$store.commit('ROLE_PERM_SET_FIELD', {
        field: 'updateRoles',
        value: true
      })
    },
    async deleteRole() {
      this.$store.commit('ROLE_PERM_SET_IS_LOADING', true)
      if (!this.activeRoleId) return

      try {
        await this.$store.dispatch('POST_REQUEST', {
          url: `role?role_id=${this.activeRoleId}`,
          data: {
            disabled: true
          }
        })
        const title = 'Удаление роли'
        const message = 'Удаление роли выполнено успешно'

        this.$notify(notifyFactory('warning', title, message))

        this.$store.commit('ROLE_PERM_SET_FIELD_AFTER_DELETE')
      } catch (e) {
        throw new Error(e)
      } finally {
        this.$store.commit('ROLE_PERM_SET_IS_LOADING', false)
      }
    },
    setRoleName(name) {
      this.roleName = cloneDeep(name || '')
    }
  }
}
</script>

<style lang="scss">
.rp-roles-header {
  position: relative;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--dividers_low_contrast);

  &__title {
    margin-bottom: 8px;
  }

  &__control {
    display: flex;
    align-items: center;

    .r-input {
      width: 300px;
      margin-right: 16px;
    }
  }
}
</style>
