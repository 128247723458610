<template>
  <div class="pt-editor-panel">
    <r-panel-header
      with-back-button
      :title="title"
      @back="back"
    />
    <div class="pt-editor-panel__controls">
      <editor-controls @back="back" />
    </div>

    <stoppoint-attributes v-if="type === 'stop_points'" />
    <route-editor-help v-else-if="mode === 'create'" />
  </div>
</template>

<script>
import EditorControls from '@/components/public-transport/editor/editor-controls'
import StoppointAttributes from '@/components/public-transport/editor/stoppoint-attributes'
import RouteEditorHelp from './route-editor-help.vue'

export default {
  components: {
    EditorControls,
    StoppointAttributes,
    RouteEditorHelp
  },
  computed: {
    type() {
      return this.$store.state.publicTransport.editorState.type
    },
    mode() {
      return this.$route.params.mode
    },
    title() {
      const typeText = this.type === 'stop_points' ? 'остановки' : 'маршрута'
      const modeText = this.mode === 'create' ? 'Создание' : 'Редактирование'

      return `${modeText} ${typeText}`
    }
  },
  methods: {
    back() {
      this.$store.commit('SET_PT_FIELD', {
        field: 'lineRouteId',
        value: null
      })
      this.$store.commit('SET_PT_EDITOR_PROP', {
        field: 'geometry',
        value: null
      })

      this.$router.push('/app/public-transport/view')
    }
  }
}
</script>

<style lang="scss">
.pt-editor-panel {
  position: absolute;
  top: 16px;
  left: 16px;
  padding: 8px;
  background-color: var(--modal_bg);
  width: 244px;
  border-radius: var(--border-radius);

  &__controls {
    margin-top: 8px;
  }
}
</style>
