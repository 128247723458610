<template>
  <nav class="navbar">
    <div class="navbar__wrapper">
      <div class="navbar__logo">
        <div class="navbar__logo-image">
          <r-logo :size="32" />
        </div>
        <profiles-menu />
      </div>
      <navbar-router />
      <navbar-actions />
    </div>
  </nav>
</template>

<script>
import navbarRouter from './components/navbar-router/navbar-router'
import navbarActions from './components/navbar-actions/navbar-actions'
import profilesMenu from '@/components/profiles/profiles-menu'

export default {
  components: {
    navbarRouter,
    navbarActions,
    profilesMenu
  }
}
</script>

<style lang="scss">
.navbar {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  border: none;
  z-index: 100;
  position: relative;
  border-bottom: 1px solid var(--dividers_low_contrast);
  height: 48px;
  overflow: hidden;
  background-color: var(--bg_panel_primary) !important;

  &__wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__logo {
    display: grid;
    width: 261px;
    border-right: 1px solid;
    align-items: center;
    position: relative;
    border-color: var(--dividers_low_contrast) !important;
    grid-gap: 1rem;
    grid-auto-flow: column;
    justify-content: start;
    padding: 0 0.75rem;
  }

  &__logo-image {
    width: 32px;
    height: 32px;
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
}
</style>
