<template>
  <div :class="['r-simple-list', { grid }]">
    <div
      v-for="item in list"
      :key="item.id"
      class="r-simple-list__item-wrapper"
      @click="clickHandler(item)"
    >
      <r-simple-list-item
        :icon-name="item.icon"
        :title="item.title"
        :subtitle="item.subtitle"
        :with-bg="withBg"
        :no-icon="noIcon"
        :simple-icon="simpleIcon"
        :size="size"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => null,
      required: true
    },
    withBg: {
      type: Boolean,
      default: false
    },
    noIcon: {
      type: Boolean,
      default: false
    },
    simpleIcon: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: null
    },
    grid: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickHandler(item) {
      this.$emit('click-handler', item)
    }
  }
}
</script>

<style lang="scss">
.r-simple-list {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  height: 0px;

  &__item-wrapper {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.grid {
    display: grid;
    height: auto;
    grid-gap: 4px;

    .r-simple-list__item-wrapper {
      margin: 0;
    }
  }
}
</style>
