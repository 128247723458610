export const formatValue = function(item, val) {
  if (val === null || val === undefined) return val

  const date = new Date(val)

  switch (item.type) {
    case 'datetime':
    case 'date':
    case 'time':
      return date?.toLocaleString('ru-RU') || date
    case 'number':
    case 'integer':
    case 'numeric':
    case 'float':
    case 'decimal':
      return val?.toLocaleString('ru-RU') || val
    default:
      return val
  }
}
