export const getPotItemById = (id, source_id) => {
  const config = {
    where: [
      {
        field: 'id',
        op: '=',
        value: id
      }
    ],
    only: [],
    include: {
      cargoes: {
        include: {
          cargoes_assigment: {},
          mols: {},
          cargo_mols: {}
        }
      },
      vehicle_type: {
        only: ['name', 'id']
      },
      vehicle_categories: {}
    }
  }
  return `objectInfo/${source_id}?config=${JSON.stringify(config)}`
}
