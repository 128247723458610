<template>
  <div class="pt-route-editor-help">
    <div class="pt-route-editor-help__header">
      <r-title type="subtitle-2">
        Горячие клавиши
      </r-title>
    </div>
    <div class="pt-route-editor-help__content">
      <div class="pt-route-editor-help__row">
        <r-text>
          CTRL + Z
        </r-text>
        <r-text type="caption">
          Отменить последнюю точку
        </r-text>
      </div>
      <div class="pt-route-editor-help__row">
        <r-text>
          Escape
        </r-text>
        <r-text type="caption">
          Отменить создание всего маршрута
        </r-text>
      </div>
      <div class="pt-route-editor-help__row">
        <r-text>
          Backspace
        </r-text>
        <r-text type="caption">
          Удалить всю геометрию
        </r-text>
      </div>
      <div class="pt-route-editor-help__row">
        <r-text>
          Двойное нажатие ЛКМ
        </r-text>
        <r-text type="caption">
          Закончить отрисовку маршрута
        </r-text>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.pt-route-editor-help {
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid transparent;
  border-color: var(--dividers_low_contrast) !important;

  &__content {
    margin-top: 8px;
  }

  &__row {
    &:not(:first-child) {
      margin-top: 8px;
    }
  }
}
</style>
