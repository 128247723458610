<template>
  <div class="rp-roles-datasources">
    <rp-roles-datasources-multi-edit />
    <div class="rp-roles-datasources-row">
      <div class="rp-roles-datasources-col">
        <rp-roles-datasources-list
          :list="allDatasources"
          :selected-items="selectedFromAll"
        />
      </div>
      <div class="rp-roles-datasources-control">
        <r-button
          :class="{ active: selectedFromAll.length }"
          icon="chevron-right"
          @click="addDatasources"
        />
        <r-button
          :class="{ active: selectedFromRole.length }"
          icon="chevron-left"
          @click="removeDatasources"
        />
      </div>
      <div class="rp-roles-datasources-col">
        <rp-roles-datasources-list-role
          :list="roleDatasources"
          :selected-items="selectedFromRole"
        />
      </div>
    </div>
  </div>
</template>

<script>
import rpRolesDatasourcesMultiEdit from './rp-roles-datasources-multi-edit.vue'
import rpRolesDatasourcesList from './rp-roles-datasources-list'
import rpRolesDatasourcesListRole from './rp-roles-datasources-list-role'
import { crudsTypes } from './configs'

export default {
  components: {
    rpRolesDatasourcesMultiEdit,
    rpRolesDatasourcesList,
    rpRolesDatasourcesListRole
  },
  data() {
    return {
      selectedFromAll: [],
      selectedFromRole: [],
      crudsTypes
    }
  },
  computed: {
    allDatasources() {
      const datasources =
        this.$store.state.rolePermission.allPerms?.datasources || []

      return datasources
        .filter(e => {
          return !this.roleDatasources.find(
            d => d.id === e.id && d._action !== 'deleted'
          )
        })
        .sort((a, b) => {
          if (a.name > b.name) return 1
          if (a.name < b.name) return -1

          return 0
        })
    },
    roleDatasources() {
      const roleDatasources =
        this.$store.state.rolePermission.rolePerms?.datasources || []

      return roleDatasources
        .map(e => e)
        .sort((a, b) => {
          if (a.name > b.name) return 1
          if (a.name < b.name) return -1

          return 0
        })
    },
    initialRoleDatasources() {
      return (
        this.$store.state.rolePermission.initialRolePerms?.datasources || []
      )
    }
  },
  watch: {
    '$store.state.rolePermission.activeRoleId': function() {
      this.selectedFromAll = []
      this.selectedFromRole = []
    }
  },
  methods: {
    addDatasources() {
      if (!this.selectedFromAll?.length) return

      const datasources = this.selectedFromAll.map(e => {
        e.cruds = []

        this.crudsTypes.forEach(c => {
          e.cruds.push(c.value)
        })

        if (e.children?.length) {
          e.children.forEach(c => {
            c.cruds = e.cruds
          })
        }

        return e
      })
      const initial = this.roleDatasources.map(e => e)
      datasources.forEach(ds => {
        const index = initial.findIndex(e => {
          return e.id === ds.id
        })

        if (index > -1) {
          initial[index]._action = ds._action
        } else {
          initial.push(ds)
        }
      })

      this.$store.commit('ROLE_PERM_SET_ROLE_DATASOURCES', initial)
      this.selectedFromAll = []
      this.$forceUpdate()
    },
    removeDatasources() {
      if (!this.selectedFromRole?.length) return

      const dsToRemove = this.selectedFromRole.filter(e => {
        return !this.initialRoleDatasources.find(ds => ds.id === e.id)
      })
      const dsToUpdate = this.selectedFromRole.filter(e => {
        return this.initialRoleDatasources.find(ds => ds.id === e.id)
      })
      dsToUpdate.forEach(e => {
        const index = this.roleDatasources.findIndex(d => d.id === e.id)
        if (index > -1) {
          this.roleDatasources[index]._action = 'deleted'
        }
      })
      const datasources = this.roleDatasources.filter(e => {
        return !dsToRemove.find(d => d.id === e.id)
      })

      this.$store.commit('ROLE_PERM_SET_ROLE_DATASOURCES', datasources)
      this.selectedFromRole = []
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss">
.rp-roles-datasources {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;

  &-row {
    position: relative;
    display: flex;
    width: 100%;
    min-width: 1020px;
    height: 0;
    flex: 1;
  }

  &-control {
    padding: 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .r-button {
      padding: 6px !important;
      margin-bottom: 20px;
      cursor: initial;

      &:last-child {
        margin-bottom: 0;
        margin-left: 0;
      }

      &.r-button {
        &.active {
          background-color: var(--accent_active) !important;
          cursor: pointer;
        }

        &:hover {
          background-color: var(--accent_hover) !important;
        }
      }
    }
  }

  &-col {
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--bg_containers);
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
</style>
