<template>
  <r-toggle-card
    title="Информация"
    :is-open="layerStyle.popup.enabled"
    @toggle="toggleHandler"
  >
    <r-text
      style="margin-bottom: 4px"
      type="caption"
    >
      Отображаемые атрибуты
    </r-text>
    <source-attributes
      :single="false"
      :active-fields="layerStyle.popup.fields"
      :tree-data="getAttributes(objectFields, true, false)"
      @handleNodeClick="changeField($event)"
    />
  </r-toggle-card>
</template>

<script>
import SourceAttributes from '@/components/map/components/source-attributes/source-attributes'
import { getAttributes, updateObjectFieldsConfigs } from '@/utils'

export default {
  components: {
    SourceAttributes
  },
  props: {
    id: {
      type: String,
      required: true
    },
    layerStyle: {
      type: Object,
      required: true
    },
    controllers: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      moreShown: false,
      objectFields: this.$clientStore.getDataFields(
        this.$store.state.profiles.sourceIdById[this.id]
      )
    }
  },
  watch: {
    id: function() {
      this.objectFields = this.$clientStore.getDataFields(
        this.$store.state.profiles.sourceIdById[this.id]
      )
    }
  },
  methods: {
    toggleHandler(value) {
      this.layerStyle.popup.enabled = value
    },
    changeField({ path, config }) {
      const { fields } = this.layerStyle.popup
      const index = fields.indexOf(path)

      if (index > -1) fields.splice(index, 1)
      else fields.push(path)

      updateObjectFieldsConfigs(
        this.controllers.layers.map,
        this.id,
        path,
        config
      )
      this.controllers.layers.updateLayerData(this.id, fields)
    },
    getAttributes
  }
}
</script>

<style lang="scss">
.popup-fields-picker {
  &__container {
    padding-left: 10px;
  }

  &__attributes {
    margin-top: 15px;
  }
}
</style>
