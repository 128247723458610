<template>
  <div class="pt-router-build-option">
    <r-icon
      :size="20"
      name="bus-station"
    />
    <r-text> {{ label }} </r-text>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.pt-router-build-option {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  grid-gap: 0.5rem;
}
</style>
