import { getDiagramGeojson, createClassesValue } from '../helpers'

export class DiagramController {
  constructor(parent) {
    this.parent = parent
    this.$store = parent.$store
    this.mapgl = parent.mapgl
  }

  toggleDiagram(id) {
    const { stylesConfigs } = this.$store.state.modelling
    const { diagram } = stylesConfigs[id]
    const { enabled } = diagram

    if (enabled) {
      this.addDiagramLayer(id)
    } else {
      this.removeDiagramLayer(id)
    }
  }

  addDiagramLayer(id) {
    const { stylesConfigs } = this.$store.state.modelling
    const { diagram } = stylesConfigs[id]
    const { color, opacity, conditions, secondField } = diagram
    const layerId = `${id}_diagram`
    const borderId = `${id}_diagram_border`
    const layerSource = this.mapgl.getSource(id)
    const layerFeatures = layerSource._data.features
    const diagramSource = this.mapgl.getSource(layerId)
    const diagramGeojson = getDiagramGeojson(
      layerFeatures,
      diagram,
      id,
      this.$store
    )

    const fillColor = secondField && conditions.length
      ? createClassesValue(secondField, conditions, this.$store, id)
      : color

    if (diagramSource) {
      diagramSource.setData(diagramGeojson)
    } else {
      this.mapgl.addSource(layerId, {
        type: 'geojson',
        data: diagramGeojson
      })
      this.mapgl.addLayer({
        source: layerId,
        id: layerId,
        type: 'fill',
        paint: {
          'fill-opacity': opacity,
          'fill-color': fillColor
        }
      })
      this.mapgl.addLayer({
        source: layerId,
        id: borderId,
        type: 'line',
        paint: {
          'line-color': fillColor,
          'line-width': 1
        },
        layout: {
          'line-cap': 'round',
          'line-join': 'round'
        }
      })
    }
  }

  removeDiagramLayer(id) {
    const layerId = `${id}_diagram`
    const borderId = `${id}_diagram_border`

    if (this.mapgl.getLayer(layerId)) {
      this.mapgl.removeLayer(layerId)
    }
    if (this.mapgl.getLayer(borderId)) {
      this.mapgl.removeLayer(borderId)
    }
    if (this.mapgl.getSource(layerId)) {
      this.mapgl.removeSource(layerId)
    }
  }

  changeDiagramProperty(id, field) {
    const layerId = `${id}_diagram`
    const borderId = `${id}_diagram_border`

    if (!this.mapgl.getLayer(layerId)) return
    if (!this.mapgl.getLayer(borderId)) return

    const { stylesConfigs } = this.$store.state.modelling
    const { diagram } = stylesConfigs[id]
    const value = diagram[field]

    this.mapgl.setPaintProperty(layerId, `fill-${field}`, value)

    if (field !== 'opacity') {
      this.mapgl.setPaintProperty(borderId, `line-${field}`, value)
    }
  }

  changeDiagramConditions(id) {
    const layerId = `${id}_diagram`
    const borderId = `${id}_diagram_border`

    if (!this.mapgl.getLayer(layerId)) return
    if (!this.mapgl.getLayer(borderId)) return

    const { stylesConfigs } = this.$store.state.modelling
    const { diagram } = stylesConfigs[id]
    const { conditions, secondField } = diagram
    const value = createClassesValue(secondField, conditions, this.$store, id)

    this.mapgl.setPaintProperty(layerId, 'fill-color', value)
    this.mapgl.setPaintProperty(borderId, 'line-color', value)
  }
}
