<template>
  <div
    class="r-circle-block"
    :style="getStyle()"
  >
    <div class="r-circle-block__header">
      <slot name="header" />
    </div>
    <div class="r-circle-block__main">
      <slot />
    </div>
    <div class="r-circle-block__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
const types = [
  'primary',
  'success',
  'warning',
  'inactive',
  'strongWarning',
  'danger',
  'mtaxi',
  'stroimekhanizatsiya',
  'stroytech',
  'ural_syberian_company',
  'stroi_servis'
]

export default {
  props: {
    type: {
      type: String,
      validator: value => types.indexOf(value) !== -1,
      default: 'primary'
    },
    color: {
      type: String,
      default: null
    },
    size: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      colors: {
        primary: '#2e93fe',
        success: '#59D64E',
        warning: '#ffac00',
        inactive: '#777',
        strongWarning: '#FF3E3E',
        mtaxi: '#B33EA9',
        danger: '#FF3E3E',
        stroimekhanizatsiya: '#e41a1c',
        stroytech: '#377eb8',
        ural_syberian_company: '#4daf4a',
        stroi_servis: '#a65628'
      }
    }
  },
  methods: {
    getStyle() {
      const styles = {}

      if (this.type) {
        styles['background-color'] = this.colors[this.type] + ' !important'
      }

      if (this.color) {
        styles['background-color'] = this.color + ' !important'
      }

      if (this.size) {
        styles.width = this.size + 'px'
        styles.height = this.size + 'px'
      }

      return styles
    }
  }
}
</script>

<style lang="scss">
.r-circle-block {
  height: 24px;
  width: 24px;
  font-size: 12px;
  border-radius: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 1;
  color: var(--white_white) !important;
  div {
    margin-bottom: 0 !important;
  }
  &__header {
    font-size: 18px;
  }
  &__footer {
    font-size: 10px;
  }
}
</style>
