<template>
  <div class="sc-basic-widget__list">
    <div class="sc-basic-widget__list__header">
      <r-title
        type="subtitle-1"
        color-type="secondary"
      >
        {{ getLabel() }}
      </r-title>
      <r-title type="subtitle-1">
        {{ total }}
      </r-title>
    </div>
    <ul class="sc-basic-widget__list-wrapper">
      <li
        v-for="(item, i) in itemList"
        :key="item + i"
      >
        <div class="sc-basic-widget__list-item">
          <r-cblock
            v-if="!noIcons"
            :size="32"
            :color="colors[item.type] || colors.default"
            :border-color="colors[item.type] || 'transparent'"
          >
            <r-icon
              :name="icons[item.type] || icons.default"
              :size="20"
              :color="colors[item.type] || colors.default"
            />
          </r-cblock>
          <r-title
            type="subtitle-1"
            color-type="subhead"
          >
            {{ item.type }}
          </r-title>
          <r-title type="subtitle-1">
            {{ item.value }}
          </r-title>
        </div>
        <template v-if="item.childrens">
          <r-block
            v-for="children in item.childrens"
            :key="children.id"
            space-between
            no-padding
            col
          >
            <r-title
              type="subtitle-1"
              color-type="subhead"
            >
              {{ children.type }}
            </r-title>
            <r-title type="subtitle-1">
              {{ children.value }}
            </r-title>
          </r-block>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    itemList: {
      type: Array,
      required: true,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    },
    colors: {
      type: Object,
      default: () => null
    },
    icons: {
      type: Object,
      default: () => null
    },
    noIcons: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getLabel() {
      const type = this.$parent.title.split('format=widget&type=')[1]

      if (!!type && (type.includes('categories') || type.includes('project'))) {
        return 'Уникальных объектов'
      }

      return 'Всего объектов'
    }
  }
}
</script>

<style lang="scss">
.sc-basic-widget__list {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  text-align: center;
  padding: 8px;
  flex: 1;
  overflow: auto;

  li {
    display: grid;
    grid-gap: 0.5rem;

    > * {
      &:not(:first-child) {
        margin-left: 1rem;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &-wrapper {
    display: grid;
    grid-gap: 0.5rem;
  }

  &-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    overflow: hidden;

    .r-title {
      text-align: start;
      max-width: calc(100% - 5rem);
    }

    > * {
      margin-right: 8px;

      &:first-child {
        margin-right: 8px;
        flex-shrink: 0;
      }

      &:last-child {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }
}
</style>
