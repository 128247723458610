<template>
  <div class="pt-routes-list">
    <ul
      v-if="routes.length"
      class="pt-routes-list__items"
    >
      <routes-item
        v-for="item in routes"
        :key="item.id"
        :item="item"
      />
    </ul>
    <div
      v-else
      class="pt-routes-list__nodata"
    >
      <r-text type="caption">
        Список пуст
      </r-text>
    </div>
  </div>
</template>

<script>
import RoutesItem from './routes-item'

export default {
  components: {
    RoutesItem
  },
  props: {
    routes: {
      type: Array,
      required: true
    }
  }
  // data () {
  //   return {
  //     filter: ''
  //   }
  // },
  // computed: {
  //   filteredList () {
  //     return this.routes.filter(item =>
  //       item?.name.toUpperCase().includes(this.filter.toUpperCase())
  //     )
  //   }
  // }
}
</script>

<style lang="scss">
.pt-routes-list {
  height: 100%;
  overflow: auto;
}
</style>
