<template>
  <div
    :style="
      `display: ${settings.display};top: ${settings.top}px; left: ${settings.left}px`
    "
    class="pt-popup-list"
  >
    <div class="pt-popup-list__title">
      <r-text style="display: inline">
        Доступные объекты
      </r-text>
      <r-text type="caption">
        {{ `(кол-во: ${settings.values.length})` }}
      </r-text>
    </div>
    <ul class="pt-popup-list__list">
      <li
        v-for="item in settings.values"
        :key="item.properties.id"
        :class="[
          'pt-popup-list__item',
          { active: isActive(item.properties.id) }
        ]"
        @click="e => clickHandler(item)"
      >
        <div class="pt-popup-list__layer-name">
          <r-text type="caption">
            {{ getLayerName(item.layer.id) }}
          </r-text>
        </div>
        <div class="pt-popup-list__item-id">
          <r-text>
            {{ item.properties.name || item.properties.no }}
          </r-text>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    settings: {
      type: Object,
      default: () => null
    }
  },
  methods: {
    isActive(id) {
      return this.$store.state.publicTransport.cardId === id
    },
    clickHandler({ properties, layer }) {
      const { ids } = this.$store.state.publicTransport.model

      this.$store.commit('SET_PT_FIELD', {
        field: 'cardId',
        value: properties.id
      })
      this.$store.commit('SET_PT_FIELD', {
        field: 'cardType',
        value: ids.stop_points === layer.id ? 'stop_points' : 'lines'
      })

      this.$emit('close')
    },
    getLayerName(id) {
      const { ids } = this.$store.state.publicTransport.model

      return `${ids.stop_points}_points` === id ? 'Остановка' : 'Маршрут'
    }
  }
}
</script>

<style lang="scss">
.pt-popup-list {
  position: absolute;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  background-color: var(--bg_surface) !important;
  border-color: var(--dividers_low_contrast) !important;
  min-width: 200px;
  padding: 8px;
  font-size: 12px;
  z-index: 10;
  &__title {
    margin-bottom: 8px;
  }
  &__list {
    max-height: 40vh;
    overflow: auto;
    max-width: 300px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 4px 8px;
    border-bottom: 1px solid;
    border-radius: var(--border-radius);
    border-color: var(--dividers_low_contrast) !important;
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
    &.active {
      background-color: var(--accent_hover) !important;
      * {
        color: var(--accent_primary) !important;
      }
    }
    &:hover {
      background-color: var(--accent_hover) !important;
    }
    &:active {
      background-color: var(--accent_active) !important;
    }
  }
  &__key {
    margin-right: 16px;
  }
  &__value {
    font-weight: bold;
  }
}
</style>
