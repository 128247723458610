<template>
  <div
    v-if="checklist"
    v-loading="loading"
    class="doc-list"
  >
    <div
      v-for="item in checklist"
      :key="item.id"
      class="doc-list__item"
    >
      <doc-item-info
        :source="source"
        :item="item"
        :docs="docs"
      />
      <doc-item-files
        :source="source"
        :source_id="source_id"
        :docs="docs"
        :label="item.label"
        :is-editing="isEditing"
        :attached="getAttached(item)"
        :url="URL"
        @update="checkAttached"
        @remove="removeItem"
      />
    </div>
  </div>
</template>

<script>
import constants from '@/constants/url'

import docItemInfo from './doc-item-info'
import docItemFiles from './doc-item-files'

export default {
  components: {
    docItemInfo,
    docItemFiles
  },
  props: {
    source: {
      type: Object,
      required: true
    },
    checklist: {
      type: Array,
      default: () => {}
    },
    docs: {
      type: Array,
      default: () => []
    },
    source_id: {
      type: String,
      required: true
    },
    isEditing: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      URL: constants.URL,
      attached: [],
      dialogVisible: false,
      loading: false
    }
  },
  computed: {
    postFileData() {
      return {
        source_id: this.source_id,
        obj_id: this.source.id,
        attr_name: this.attr_name
      }
    }
  },
  mounted() {
    this.checkAttached()
  },
  methods: {
    getAttached({ label }) {
      const { attr_name } = this.docs.find(({ title }) => title === label)
      return this.attached.filter(d => d.attr_name === attr_name)
    },
    async removeItem(id) {
      try {
        this.loading = true
        await this.$store.dispatch('DELETE_REQUEST', {
          url: `objectResources/${this.source_id}/${id}`
        })
      } catch (e) {
        console.log(e)
      } finally {
        await this.checkAttached()
        this.loading = false
      }
    },
    async checkAttached() {
      const url = `objectResources/${this.source_id}/${this.source.id}`
      const { data } = await this.$store.dispatch('GET_REQUEST', {
        url
      })
      this.attached = data.filter(d => d.attr_name)
    }
  }
}
</script>

<style lang="scss">
.doc-list {
  &__item {
    display: grid;
    grid-gap: 0.5rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--dividers_low_contrast);

    &:last-child {
      border: none;
    }
  }
}
</style>
