export const timeFiltersList = [
  {
    id: 3,
    value: 60,
    title: '60 мин'
  },
  {
    id: 4,
    value: 60 * 24,
    title: 'Сутки'
  },
  {
    id: 5,
    value: 60 * 24 * 7,
    title: 'Неделя'
  },
  {
    id: 6,
    value: 60 * 24 * 30,
    title: 'Месяц'
  }
]

export const passingTypes = [
  {
    id: 1,
    title: 'Высокая',
    colorType: 'high',
    color: '#D53E4F',
    value: '2500'
  },
  {
    id: 2,
    title: 'Повышенная',
    colorType: 'increased',
    color: '#EE8D63',
    value: '1000'
  },
  {
    id: 3,
    title: 'Нормальная',
    colorType: 'normal',
    color: '#F5CE43',
    value: '500'
  },
  {
    id: 4,
    title: 'Низкая',
    colorType: 'low',
    color: '#3EB52A'
  }
]
