<template>
  <div id="app-main">
    <router-view />
  </div>
</template>

<script>
import { toggleBodyTheme, getCSSCustomPropIndex } from '@/utils'

export default {
  created() {
    toggleBodyTheme(this.$store.state.theme)
    this.$store.commit('SET_THEME_COLORS', getCSSCustomPropIndex())
  }
}
</script>
