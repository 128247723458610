import {
  // default
  createDrawPointMode,
  createDrawLineStringMode,
  createDrawPolygonMode,
  createDrawNodesMode,
  createDrawLinksMode,
  createDrawZonesMode,
  createDrawConnectorsMode,
  createSimpleSelectMode,
  createLinkSpliceMode,
  createDirectSelectMode,
  // event editor
  createEESimpleSelectMode,
  createEEDirectSelectMode,
  createEEDrawPointsMode,
  createEEDrawLinesMode,
  createEEDrawPolygonsMode,
  // modelling
  createMSimpleSelectMode,
  createMDrawPointsMode,
  // public transport
  createPTSimpleSelectMode,
  createPTDirectSelectMode,
  createDrawStopPointsMode,
  createDrawRoutesMode,
  // odd
  createODDSimpleSelectMode,
  createODDDirectSelectMode,
  createODDDrawSignsMode,
  createODDDrawPointsMode,
  createODDDrawLinesMode
} from './creators'

export class ModesController {
  constructor(map, MapboxDraw, type) {
    this.map = map
    this.MapboxDraw = MapboxDraw
    this.mapgl = map.map
    this.state = map[type]
  }

  // common select modes
  createSimpleSelectMode() {
    return createSimpleSelectMode(this.MapboxDraw, this.state, this.map)
  }

  createLinkSpliceMode() {
    return createLinkSpliceMode(this.MapboxDraw, this.state, this.map)
  }

  createDirectSelectMode() {
    return createDirectSelectMode(this.MapboxDraw, this.state, this.map)
  }

  // common draw modes
  createDrawPointMode() {
    return createDrawPointMode(this.MapboxDraw, this.state, this.map)
  }

  createDrawLineStringMode() {
    return createDrawLineStringMode(this.MapboxDraw, this.state, this.map)
  }

  createDrawPolygonMode() {
    return createDrawPolygonMode(this.MapboxDraw, this.state, this.map)
  }

  // model draw modes
  createDrawNodesMode() {
    return createDrawNodesMode(this.MapboxDraw, this.state, this.map)
  }

  createDrawLinksMode() {
    return createDrawLinksMode(this.MapboxDraw, this.state, this.map)
  }

  createDrawZonesMode() {
    return createDrawZonesMode(this.MapboxDraw, this.state, this.map)
  }

  createDrawConnectorsMode() {
    return createDrawConnectorsMode(this.MapboxDraw, this.state, this.map)
  }

  // event editor modes
  createEESimpleSelectMode() {
    return createEESimpleSelectMode(this.MapboxDraw, this.state, this.map)
  }

  createEEDirectSelectMode() {
    return createEEDirectSelectMode(this.MapboxDraw, this.state, this.map)
  }

  createEEDrawPointsMode() {
    return createEEDrawPointsMode(this.MapboxDraw, this.state, this.map)
  }

  createEEDrawLinesMode() {
    return createEEDrawLinesMode(this.MapboxDraw, this.state, this.map)
  }

  createEEDrawPolygonsMode() {
    return createEEDrawPolygonsMode(this.MapboxDraw, this.state, this.map)
  }

  // modelling
  createMSimpleSelectMode() {
    return createMSimpleSelectMode(this.MapboxDraw, this.map)
  }

  createMDrawPointsMode() {
    return createMDrawPointsMode(this.MapboxDraw, this.map)
  }

  // public transpot modes
  createPTSimpleSelectMode() {
    return createPTSimpleSelectMode(this.MapboxDraw, this.map)
  }

  createPTDirectSelectMode() {
    return createPTDirectSelectMode(this.MapboxDraw, this.map)
  }

  createDrawStopPointsMode() {
    return createDrawStopPointsMode(this.MapboxDraw, this.map)
  }

  createDrawRoutesMode() {
    return createDrawRoutesMode(this.MapboxDraw, this.map)
  }

  // event editor modes
  createODDSimpleSelectMode() {
    return createODDSimpleSelectMode(this.MapboxDraw, this.map)
  }

  createODDDirectSelectMode() {
    return createODDDirectSelectMode(this.MapboxDraw, this.map)
  }

  createODDDrawSignsMode() {
    return createODDDrawSignsMode(this.MapboxDraw, this.map)
  }

  createODDDrawPointsMode() {
    return createODDDrawPointsMode(this.MapboxDraw, this.map)
  }

  createODDDrawLinesMode() {
    return createODDDrawLinesMode(this.MapboxDraw, this.map)
  }
}
