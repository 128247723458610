<template>
  <role-permission />
</template>

<script>
import rolePermission from '@/components/role-permission/role-permission'

export default {
  components: { rolePermission }
}
</script>

<style></style>
