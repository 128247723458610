import debounce from 'lodash.debounce'
import cloneDeep from 'lodash.clonedeep'
import {
  getModellingRequestConfig,
  jsonToGeojson,
  parseObjectFields
} from '@/utils'
import { getBranchParam, getCalcParams } from '../helpers'
import { layerStyles, baseLayersStyles, calcFields } from '../config'

export class LayerController {
  constructor(parent) {
    this.parent = parent
    this.$store = parent.$store
    this.mapgl = parent.mapgl
    this.handlers = {}
    this.asyncHandlers = {}
    this.data = {}
  }

  async toggleLayerOn(id, byUser) {
    const {
      model,
      requestedFields,
      stylesConfigs,
      objectFields
    } = this.$store.state.modelling
    const { datatype } = model.children.find(l => l.id === id)

    const loadModelLayer = async e => {
      if (e && e.noRequest) return
      // const zoom = this.mapgl.getZoom()
      const routeName = this.parent.$route.name
      const { scenarioId } = this.parent.$route.params
      const config = getModellingRequestConfig(this.parent, id, datatype)

      if (routeName !== 'm-scenario') {
        config.only = ['id', 'geom']
      }

      try {
        this.$store.commit('ADD_CALC_LAYER_BY_TYPE', { type: 'loading', id })
        let url = `objectInfo/${id}?config=${JSON.stringify(config)}`

        const withCalcField = config.only.some(item => item.agg_op === 'avg')

        if (
          routeName === 'm-scenario' &&
          datatype === 'links' &&
          withCalcField
        ) {
          const { currentTime } = this.$store.state.modelling

          url += `&${getCalcParams(scenarioId, currentTime)}`
        } else if (datatype === 'events') {
          url += `&${getBranchParam(scenarioId)}`
        }

        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })
        // modify and save data
        let json = Object.keys(data).map(id => data[id])
        if (datatype === 'events') {
          json = json.map(e => ({
            ...e,
            event_class_name: e.event_class?.name
          }))
        }
        const geojson = jsonToGeojson(json)

        this.data[datatype] = geojson

        // add layers
        if (!this.mapgl.getLayer(id)) {
          const styles =
            routeName === 'm-scenario'
              ? stylesConfigs[id].style
              : baseLayersStyles[datatype]
          const options = {
            id,
            source: {
              type: 'geojson',
              data: geojson
            },
            ...styles
          }

          if (this.mapgl.getLayer('gl-draw-polygon-fill-inactive.cold')) {
            this.mapgl.addLayer(options, 'gl-draw-polygon-fill-inactive.cold')
          } else {
            this.mapgl.addLayer(options)
          }
        } else {
          this.mapgl.getSource(id).setData(geojson)
        }

        if (routeName === 'm-scenario') {
          if (datatype === 'links') {
            // get initial calc data
            await this.updateCalcData()

            // set initial diagram styling
            // load aggregation for CURSPEED
            await this.updateFieldData(id, 'curspeed')
            await this.updateFieldData(id, 'vehonlink')
            // set to diagram config (WIDTH)
            const fields = objectFields[id]
            const vehonlinkMeta = fields.find(
              f => f.title === 'vehonlink' && f.type === 'float'
            )
            const { diagram } = stylesConfigs[id]

            this.parent.$set(diagram, 'minValue', vehonlinkMeta.min)
            this.parent.$set(diagram, 'maxValue', vehonlinkMeta.max)
            // set to diagram config (COLOR)
            const { max, min } = fields.find(
              f => f.title === 'curspeed' && f.type === 'float'
            )
            const { currentPalette } = diagram
            const step = Number(((max - min) / 5).toFixed(1))
            const colors = currentPalette.value
            const conditions = colors.map((color, i) => {
              const id = Date.now() + i
              const index = i === 0 ? colors.length - i - 1 : colors.length - i
              const value = Number(((index + 1) * step).toFixed(1))
              const style = {
                color
              }
              const initial = i === 0 ? true : undefined

              return { id, value, style, initial }
            })

            this.parent.$set(diagram, 'conditions', conditions)
          }

          this.addAdditionalLayers(id, datatype)
        }

        this.$store.commit('ADD_CALC_LAYER_BY_TYPE', { type: 'active', id })
        this.$store.commit('REMOVE_CALC_LAYER_BY_TYPE', {
          type: 'loading',
          id
        })
      } catch (error) {
        console.log(error)
        this.$store.commit('REMOVE_CALC_LAYER_BY_TYPE', {
          type: 'loading',
          id
        })
      }
    }

    this.$store.commit('ADD_CALC_LAYER_BY_TYPE', { type: 'loading', id })

    // set initial configs
    const fields = await this.getObjectFields(id, datatype)
    this.$store.commit('SET_OBJECT_FIELDS', { id, fields })

    if (!requestedFields[id]) {
      const fields = ['id', 'geom']

      this.$store.commit('SET_REQUESTED_FIELDS', { id, fields })
    }

    if (!stylesConfigs[id]) {
      const config = cloneDeep(layerStyles[datatype])

      this.$store.commit('SET_STYLE_CONFIG', { id, config })
    }

    this.$store.commit('REMOVE_CALC_LAYER_BY_TYPE', {
      type: 'loading',
      id
    })

    this.handlers[id] = debounce(loadModelLayer, 200)
    this.asyncHandlers[id] = loadModelLayer
    if (byUser) this.handlers[id]()
    // this.mapgl.on('moveend', this.handlers[id])
  }

  toggleLayerOff(id) {
    if (this.mapgl.getLayer(id)) {
      this.mapgl.removeLayer(id)
    }
    if (this.mapgl.getLayer(`${id}_border`)) {
      this.mapgl.removeLayer(`${id}_border`)
    }
    if (this.mapgl.getLayer(`${id}_diagram`)) {
      this.mapgl.removeLayer(`${id}_diagram`)
    }
    if (this.mapgl.getLayer(`${id}_diagram_border`)) {
      this.mapgl.removeLayer(`${id}_diagram_border`)
    }
    if (this.mapgl.getSource(id)) {
      this.mapgl.removeSource(id)
    }
    if (this.mapgl.getSource(`${id}_diagram`)) {
      this.mapgl.removeSource(`${id}_diagram`)
    }
    this.mapgl.off('moveend', this.handlers[id])
    delete this.handlers[id]
    this.$store.commit('REMOVE_CALC_LAYER_BY_TYPE', { type: 'active', id })

    if (this.$store.state.modelling.settingsId === id) {
      this.$store.commit('SET_CALC_FIELD', {
        field: 'settingsId',
        value: null
      })
    }
  }

  // additional layers
  addAdditionalLayers(id, datatype) {
    const { stylesConfigs } = this.$store.state.modelling

    switch (datatype) {
      case 'zones': {
        if (!this.mapgl.getLayer(`${id}_border`)) {
          this.mapgl.addLayer({
            source: id,
            id: `${id}_border`,
            type: 'line',
            ...stylesConfigs[id].borderStyle
          })
        }
        return
      }
      case 'links': {
        const { diagram } = stylesConfigs[id]

        if (diagram.enabled) {
          this.parent.controllers.diagram.addDiagramLayer(id)
        }
      }
    }
  }

  // update layers
  async updateCalcData(byTimeline) {
    const { currentTime, model } = this.$store.state.modelling
    const { ids } = model
    const { scenarioId } = this.parent.$route.params

    try {
      this.$store.commit('ADD_CALC_LAYER_BY_TYPE', {
        type: 'loading',
        id: ids.links
      })

      const config = {
        only: ['id', 'link_id', ...calcFields],
        where: [
          {
            field: 'calculation_id',
            op: '=',
            value: scenarioId
          },
          {
            field: 'time_value',
            op: '=',
            value: currentTime
          }
        ]
      }
      const url = `objectInfo/${
        ids.link_calculation_results
      }?config=${JSON.stringify(config)}&${getBranchParam(scenarioId)}`
      const { data } = await this.$store.dispatch('GET_REQUEST', { url })
      const calcData = Object.values(data)

      // merge with links data
      const source = this.mapgl.getSource(ids.links)
      const geojson = this.data.links

      geojson.features = geojson.features.map(f => {
        const calcObject =
          calcData.find(object => object.link_id === f.properties.id) || {}

        delete calcObject.id

        const dataToMerge = {
          ...calcObject,
          link_id: undefined
        }

        return {
          ...f,
          properties: {
            ...f.properties,
            ...dataToMerge
          }
        }
      })

      // set data to links layer
      source.setData(geojson)
      // update diagram
      if (byTimeline) {
        this.parent.controllers.diagram.addDiagramLayer(ids.links)
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.$store.commit('REMOVE_CALC_LAYER_BY_TYPE', {
        type: 'loading',
        id: ids.links
      })
    }
  }

  // set only base layers
  setBaseLayers() {
    const { activeLayers, model } = this.$store.state.modelling
    const { ids } = model
    const baseLayersIds = ['links'].map(type => ids[type])
    const layers = [...activeLayers]

    layers.forEach(async id => {
      if (baseLayersIds.includes(id)) {
        this.toggleLayerOff(id)
        await this.toggleLayerOn(id)

        if (this.handlers[id]) this.handlers[id]()
      } else {
        this.toggleLayerOff(id)
      }
    })
  }

  addLinksLayer() {
    const { model } = this.$store.state.modelling
    const { ids } = model
    const baseLayersIds = ['links'].map(type => ids[type])

    baseLayersIds.forEach(async id => {
      this.toggleLayerOff(id)
      await this.toggleLayerOn(id)

      if (this.handlers[id]) this.handlers[id]()
    })
  }

  removeLinksLayer() {
    const { model } = this.$store.state.modelling
    const { ids } = model
    const baseLayersIds = ['links'].map(type => ids[type])

    baseLayersIds.forEach(async id => {
      this.toggleLayerOff(id)
    })
  }

  // object fields request
  async getObjectFields(id, datatype) {
    const { currentScenarioId } = this.$store.state.modelling

    const availableTypes = ['decimal', 'float', 'integer']

    try {
      const url = `objectFields/${id}?${getBranchParam(currentScenarioId)}`
      const data = await this.$store.dispatch('GET_REQUEST', { url })
      const parsedFields = parseObjectFields(data, false)
      const fields = parsedFields.filter(f => availableTypes.includes(f.type))

      if (datatype === 'links' && this.parent.$route.name === 'm-scenario') {
        const { ids } = this.$store.state.modelling.model
        const calcUrl = `objectFields/${
          ids.link_calculation_results
        }?${getBranchParam(currentScenarioId)}`

        const calcFieldsData = await this.$store.dispatch('GET_REQUEST', {
          url: calcUrl
        })
        const parsedCalcFields = parseObjectFields(calcFieldsData, false)
        const calcFields = parsedCalcFields
          .filter(f => availableTypes.includes(f.type))
          .map(f => ({
            ...f,
            calc_field: true
          }))

        console.log('calcf fields from objectFields --- ', calcFields)

        return [...fields, ...calcFields]
      }

      return fields
    } catch (error) {
      console.log(error)

      return []
    }
  }

  async updateFieldData(id, field) {
    const {
      objectFields,
      requestedFields,
      currentScenarioId,
      model
      // currentTime
    } = this.$store.state.modelling

    const fields = objectFields[id]
    const fieldConfig = fields.find(
      item => item.title === field && item.type === 'float'
    )

    const { calc_field } = fieldConfig

    // create config
    const only = [field]
    const config = { only }

    if (calc_field) {
      config.where = [
        {
          field: 'calculation_id',
          op: '=',
          value: currentScenarioId
        }
        // {
        //   field: 'time_value',
        //   op: '=',
        //   value: currentTime
        // }
      ]
    }

    // load field data
    let sourceId
    let url

    if (calc_field) {
      sourceId = model.ids.link_calculation_results
      url = `objectInfo/${sourceId}?format=due_result_aggregation&attr_name=${field}`
    } else {
      sourceId = id
      url = `aggregation/${sourceId}?config=${JSON.stringify(config)}`
    }

    const { data } = await this.$store.dispatch('GET_REQUEST', {
      url: `${url}&${getBranchParam(currentScenarioId)}`
    })

    // set field data to store
    const fieldMeta = calc_field ? data : data[field]

    const index = fields.findIndex(f => f.title === field && f.type === 'float')
    const newValue = {
      ...fields[index],
      ...fieldMeta
    }

    fields[index] = newValue

    if (id === model.ids.links) {
      if (!calc_field) {
        await this.asyncHandlers[id]()
      }
    } else {
      this.$store.commit('SET_REQUESTED_FIELDS', {
        id,
        fields: [...requestedFields[id], field]
      })

      await this.asyncHandlers[id]()
    }
  }
}
