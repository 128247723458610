import vue from 'vue'

const state = {
  source_id: ''
}

const mutations = {
  SET_OBJECT_CREATE_SOURCE_ID(state, source_id) {
    vue.set(state, 'source_id', source_id)
  }
}

export default {
  state,
  mutations
}
