<template>
  <div class="m-layers-list">
    <r-list
      :list="layers"
      :tools-list="tools"
      :active-list="activeLayers"
      :loading-list="loadingLayers"
      @node-click-handler="e => toggleLayer(e)"
      @tool-click-handler="e => toggleSettings(e)"
    />
  </div>
</template>

<script>
const layersToRenderDatatypes = ['links', 'zones', 'events']
const layersName = {
  nodes: 'Узлы (nodes)',
  links: 'Отрезки (links)',
  zones: 'Районы (zones)',
  events: 'События (events)'
}
const layersIcons = {
  nodes: 'node-layer',
  zones: 'polygon-layer',
  links: 'line-layer',
  events: 'car-accident'
}

export default {
  data() {
    return {
      tools: [
        {
          id: 1,
          title: 'Настройки',
          icon: 'settings'
        }
      ]
    }
  },
  computed: {
    layers() {
      return this.$store.state.modelling.model.children
        .filter(l => layersToRenderDatatypes.includes(l.datatype))
        .map(l => ({
          ...l,
          title: layersName[l.datatype],
          icon: layersIcons[l.datatype]
        }))
    },
    activeLayers() {
      return this.$store.state.modelling.activeLayers
    },
    loadingLayers() {
      return this.$store.state.modelling.loadingLayers
    }
  },
  methods: {
    isActive(id) {
      return this.activeLayers.includes(id)
    },
    isLoading(id) {
      return this.loadingLayers.includes(id)
    },
    toggleLayer({ id }) {
      if (this.isLoading(id)) return

      if (this.isActive(id)) {
        this.$cControllers.layers.toggleLayerOff(id)
      } else {
        this.$cControllers.layers.toggleLayerOn(id, true)
      }
    },
    toggleSettings({ item }) {
      const { id } = item

      if (!this.isActive(id)) return

      const { settingsId } = this.$store.state.modelling

      const value = id === settingsId ? null : id

      this.$store.commit('SET_CALC_FIELD', {
        field: 'settingsId',
        value
      })
    }
  }
}
</script>

<style lang="scss">
.m-layers-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px;
}
</style>
