<template>
  <div class="sc-chart-builder__wrapper">
    <v-chart
      :option="chartOption"
      :autoresize="true"
      class="sc-chart-builder"
    />
    <div class="sc-chart-builder__controllers">
      <el-dropdown
        class="r-dropdown"
        :hide-on-click="false"
        placement="bottom-start"
        @command="changeChartType"
      >
        <span class="el-dropdown-link">
          <r-icon
            name="settings"
            :size="14"
          />
        </span>
        <el-dropdown-menu
          slot="dropdown"
          class="r-dropdown-menu"
        >
          <el-dropdown-item
            v-for="item in chartsTypes"
            :key="item"
            :command="item"
          >
            <span
              v-if="item === type"
              class="el-icon-check"
            />
            <r-text>{{ $t(`chart-type:${item}`) }}</r-text>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import 'echarts'
import VChart from 'vue-echarts'

import { basic, bar, barX, pie } from './configs'
import { setChartStyle, setChartOptions } from './helpers'

const chartsTypes = ['type-1', 'type-2', 'type-3']

export default {
  components: { VChart },
  props: {
    chartData: {
      type: Object,
      required: true,
      default: () => null
    },
    type: {
      type: String,
      validator: val => chartsTypes.indexOf(val) !== -1,
      default: 'type-1'
    },
    title: {
      type: String,
      default: ''
    },
    colors: {
      type: Array,
      default: () => null
    },
    changeChartType: {
      type: Function,
      default: () => null
    }
  },
  data() {
    return {
      isLoading: false,
      chartOption: null,
      preparedChartData: null,

      chartsTypes,
      basic,
      bar,
      barX,
      pie
    }
  },
  computed: {
    theme() {
      return this.$store.state.theme
    }
  },
  watch: {
    theme() {
      this.setChart()
    },
    type() {
      this.setChart()
    },
    chartData() {
      this.setChart()
    },
    themeColors() {
      return this.$store.state.colors
    }
  },
  created() {
    this.setChart()
  },
  methods: {
    setChart() {
      this.setChartOptions(this)
      this.setColorTheme()
    },
    setColorTheme() {
      setChartStyle(this, this.themeColors)
    },
    setChartOptions
  }
}
</script>

<style lang="scss">
.sc-chart-builder__wrapper {
  min-width: 604px;
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  .echarts {
    height: calc(100%);
    width: calc(100%);
    margin-top: -80px;
    margin-left: -8px;
    top: 0;
    left: 0;
  }
  .sc-chart-builder {
    &__legend {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: space-between;
      width: 100%;
      overflow: auto;
      height: 44px;
      overflow-y: hidden;
      &-item {
        text-align: center;
        min-width: 70px;
        overflow: hidden;
        padding: 0 4px;
        .r-text,
        .r-text.caption-2 {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    &__controllers {
      position: absolute;
      top: -12px;
      left: -8px;
      .r-dropdown {
        cursor: pointer;
      }
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "false": "Ошибка",
    "true": "Работает",
    "stroimekhanizatsiya": "Строймеханизация",
    "stroytech": "Стройтех",
    "ural_syberian_company": "Урало-Сибирская Компания",
    "stroi_servis": "Строй-Сервис",

    "chart-type:type-1": "Linear",
    "chart-type:type-2": "Bar",
    "chart-type:type-3": "Bar-X",
    "chart-type:type-4": "Pie"
  },
  "en": {
    "false": "Error",
    "true": "Work",
    "stroimekhanizatsiya": "Stroimekhanizatsiya",
    "stroytech": "Stroytech",
    "ural_syberian_company": "Ural Syberian Company",
    "stroi_servis": "Stroi-Servis",

    "chart-type:type-1": "Linear",
    "chart-type:type-2": "Bar",
    "chart-type:type-3": "Bar-X",
    "chart-type:type-4": "Pie"
  }
}
</i18n>
