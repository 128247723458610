export const fields = {
  no: '№',
  event_time: 'Время события',
  event_type: 'Тип события',
  reg_number: 'Гос. номер',
  model: 'Модель',
  vehicle_type: 'Вид транспорта',
  vehicle_type_group: 'Тип транспорта',
  contractor: 'Подрядчик',
  sub_contractor: 'Субподрядчик',
  speed_limit: 'Разрешённая скорость',
  description: 'Детали',
  speed: 'Фактическая скорость, км/ч',
  status: 'Статус',
  number: 'Гос. номер',
  recognizer_name: 'Камера',
  time_leave: 'Время проезда',
  tLicenseNumber: 'Гос. номер',
  nDatetime: 'Время проезда',
  nOverSpeed: 'Тип нарушения',
  nWrongDirection: 'Тип нарушения',
  display_speed: 'Скорость',
  direction: 'Направление',
  dt: 'Время события',
  no_kpp: '№ КПП',
  permit_number: '№ пропуска',
  error_message: 'Текст ошибки',
  subprojects: 'Подпроект',
  project_label: 'Проект',
  over_speed: 'Превышение, км/ч'
}

export const columns = {
  car_event: [
    'no',
    'event_time',
    'event_type',
    'reg_number',
    'model',
    'vehicle_type',
    'vehicle_type_group',
    'contractor',
    'sub_contractor',
    'speed_limit',
    'speed',
    'description'
  ],
  system_event: [
    'no',
    'event_time',
    'event_type',
    'status',
    'error_message'
  ],
  gazprom: [
    'no',
    'reg_number',
    'vehicle_type_group',
    'contractor',
    'sub_contractor',
    'recognizer_name',
    'no_kpp',
    'event_time'
  ],
  videophoto: [
    'no',
    'reg_number',
    'model',
    'vehicle_type_group',
    'contractor',
    'sub_contractor',
    'subprojects',
    'project_label',
    'permit_number',
    'event_type',
    'recognizer_name',
    'event_time',
    'speed',
    'over_speed',
    'direction'
  ],
  videoanalytics: [
    'no',
    'event_type',
    'reg_number',
    'vehicle_type',
    'vehicle_type_group',
    'contractor',
    'sub_contractor',
    'event_time'
  ],
  nipigaz: [
    'no',
    'reg_number',
    'vehicle_type_group',
    'contractor',
    'sub_contractor',
    'recognizer_name',
    'event_time'
  ],
  nipigaz_pot: [
    'no',
    'reg_number',
    'vehicle_type_group',
    'contractor',
    'sub_contractor',
    'recognizer_name',
    'event_time'
  ]
}
