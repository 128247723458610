<template>
  <div
    class="r-info-panel"
    :style="getStyle"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 480
    }
  },
  computed: {
    getStyle() {
      return {
        width: `${this.width}px`
      }
    }
  }
}
</script>

<style lang="scss">
.r-info-panel {
  height: 100%;
  z-index: 10;
  flex-shrink: 0;
  box-shadow: -2px 0 10px 0px rgba(0, 0, 0, 0.1);
  background-color: var(--bg_panel_secondary) !important;
}
</style>
