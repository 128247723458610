<template>
  <div class="navbar-dropdown-title">
    <r-icon
      :name="icon"
      :size="24"
    />
    <r-text>
      {{ title }}
    </r-text>
    <r-icon
      name="drop-down"
      :size="24"
      class="navbar-dropdown-arrow"
    />
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'alert'
    },
    title: {
      type: String,
      default: 'title'
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar-dropdown-title {
  display: flex;
  align-items: center;
  padding: 6px 4px;

  .r-text {
    width: 0;
    opacity: 0;
    white-space: nowrap;
    max-width: 13vw;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.15s ease;
    color: var(--accent_primary) !important;
  }

  .navbar-dropdown-arrow {
    margin-left: -4px;
  }
}
</style>
