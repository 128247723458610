<template>
  <r-toggle-card
    title="Тепловая карта 2D"
    :is-open="layerStyle.heatmap.enabled"
    @toggle="toggleHeatmapLayer"
  >
    <div
      v-if="layerStyle.heatmap.loading"
      class="map-heatmaps__loader"
    >
      <r-text type="caption">
        <span class="el-icon-loading" />
        Загрузка данных
      </r-text>
    </div>
    <div
      v-else
      class="map-heatmaps__container"
    >
      <div class="map-heatmaps__slider">
        <r-slider
          title="Прозрачность"
          :number="layerStyle.heatmap.opacity"
          :min="0.1"
          :max="1"
          :step="0.1"
          @change="changeProperty('opacity', $event)"
        />
      </div>
      <div class="map-heatmaps__color">
        <r-text style="margin-bottom: 4px">
          Палитра
        </r-text>
        <div class="map-heatmaps__color-palettes">
          <el-select
            v-model="currentPalette"
            class="r-select dark palette"
            placeholder=""
            size="mini"
            :popper-append-to-body="false"
            popper-class="map-color-picker__palette-popper"
            value-key="id"
            @change="changePalette"
          >
            <template slot="prefix">
              <palette-colors
                :colors="currentPalette.value"
                :is-current="true"
              />
            </template>
            <el-option
              v-for="item in palettes"
              :key="item.id"
              :label="item.name"
              :value="item"
            >
              <palette-colors :colors="item.value" />
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
  </r-toggle-card>
</template>

<script>
import PaletteColors from '../input-sources/palette-colors'
import { palettes } from '../config'

export default {
  components: {
    PaletteColors
  },
  props: {
    id: {
      type: String,
      required: true
    },
    layerStyle: {
      type: Object,
      required: true
    },
    controllers: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      conditions: this.layerStyle.conditions,
      currentPalette: this.layerStyle.currentPalette || palettes[0],
      palettes
    }
  },
  watch: {
    id: function() {
      const config = this.$store.state.map.styleConfig[this.id]
      this.conditions = config.heatmap.conditions
      this.currentPalette = config.heatmap.currentPalette || this.palettes[0]
    }
  },
  methods: {
    changeProperty(property, value) {
      if (value) this.layerStyle.heatmap[property] = value

      this.controllers.heatmap.changeHeatmapPropery(this.id, property)
    },
    toggleHeatmapLayer(value) {
      this.layerStyle.heatmap.enabled = value

      this.changePalette()
      this.controllers.layers.toggleHeatmapLayer(this.id)
    },
    changePalette() {
      this.conditions = this.currentPalette.value.map((v, i) => ({
        value: i * 0.2,
        color: v
      }))
      this.setStoreValue()
      this.changeProperty('color')
    },
    setStoreValue() {
      this.$store.commit('SET_HEATMAP_STYLE', {
        id: this.id,
        config: {
          conditions: this.conditions,
          currentPalette: this.currentPalette
        },
        field: 'heatmap'
      })
    }
  }
}
</script>

<style></style>
