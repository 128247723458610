<template>
  <div
    v-loading="isLoading"
    class="situation-center"
  >
    <sc-layout
      :has-bottom="!!bottomLayout && !!bottomLayout.length"
      :has-left="!!layout.left_block && !!layout.left_block.length"
    >
      <sc-map
        v-if="isReady"
        slot="bg"
        :layers="mapRequestList"
      />
      <component
        :is="`sc-${widget.name}`"
        v-for="(widget, i) in layout.left_block"
        :key="widget.name + i"
        slot="left-block"
        :options="widget.props"
      />
      <component
        :is="`sc-${widget.name}`"
        v-for="(widget, i) in bottomLayout"
        :key="widget.name + i"
        slot="bottom-block"
        :options="widget.props"
      />
      <sc-object-cards slot="right-block" />
    </sc-layout>
  </div>
</template>

<script>
import scLayout from './components/layout/layout'
import scMap from './components/map/map'
import scTitleBarWidget from './components/widgets/title-bar/title-bar'
import scTrafficWidget from './components/widgets/traffic/traffic'
import scCategoryWidget from './components/widgets/category-widget/category-widget'
import scStatusWidget from './components/widgets/status-widget/status-widget'
import scChartWidget from './components/widgets/chart-widget/chart-widget'
import scObjectCards from './components/widgets/object-card/object-cards'
import scEventsLog from './components/widgets/events-log/events-log'
import { instanceConfigs, getRequestList, dataRequest } from './configs'
import { setMainModelId, setSiCenterEventsInfo, modifyInstanceConfig } from './helpers'
import cloneDeep from 'lodash.clonedeep'

export default {
  components: {
    scLayout,
    scMap,
    scTitleBarWidget,
    scTrafficWidget,
    scCategoryWidget,
    scStatusWidget,
    scChartWidget,
    scObjectCards,
    scEventsLog
  },
  data() {
    return {
      token: this.$store.state.auth.token,
      isLoading: false,
      isReady: false,
      refreshData: {}
    }
  },
  computed: {
    instance() {
      return this.$store.state.instance
    },
    instanceConfigs() {
      return this.$store.state.situationCenter.config
    },
    mainModelId() {
      return this.$store.state.mainModelId || null
    },
    layout() {
      return this.instanceConfigs.layout
    },
    bottomLayout() {
      return this.layout.bottom_block
        ? this.layout.bottom_block.filter(e => e.props.request.url || e.props.request.plug)
        : []
    },
    requestList() {
      return getRequestList(this.layout)
    },
    mapRequestList() {
      return this.instanceConfigs.mapLayers
        ? this.instanceConfigs.mapLayers.filter(e => e.request.url)
        : []
    },
    mapPlugList() {
      return this.instanceConfigs.mapLayers
        ? this.instanceConfigs.mapLayers.filter(e => e.request.plug)
        : []
    }
  },
  async created() {
    try {
      await setMainModelId(this)
      this.setInstanceConfig()
    } catch (e) {
      console.log(e)
    }
  },
  beforeDestroy() {
    this.deleteRequestInterval()
  },
  methods: {
    async setInstanceConfig() {
      this.isLoading = true
      const config =
        cloneDeep(instanceConfigs[this.instance]) ||
        cloneDeep(instanceConfigs.default)

      try {
        if (config.mapTileLayers && config.mapTileLayers.length) {
          config.mapTileLayers = await this.prepareTileLayers(
            config.mapTileLayers
          )
        }
        this.$store.commit('SET_SI_CENTER_CONFIG', config)
        await setSiCenterEventsInfo(this)
        modifyInstanceConfig(this)

        this.startDataRequest()

        this.isLoading = false
        this.isReady = true
      } catch (e) {
        console.log(e)
        this.isLoading = false
        this.isReady = true
      }
    },
    async prepareTileLayers(mapTileLayers) {
      const layersList = mapTileLayers
      const index = layersList.findIndex(e => e.url)
      if (index > -1) {
        try {
          const { data } = await this.$store.dispatch('GET_REQUEST', {
            url: layersList[index].url
          })
          if (data && data.layers && data.layers.layer) {
            const layers = data.layers.layer.map(e => e.name)
            layersList[index] = {
              id: layersList[index].id,
              source: {
                type: 'raster',
                tiles: [
                  `http://ritm.smeu-gai.ru/geoserver/ritm/wms?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&transparent=true&layers=${layers.join(
                    ','
                  )}`
                ],
                tileSize: 256
              },
              type: 'raster'
            }
          } else {
            layersList.splice(index, 1)
          }
        } catch (e) {
          console.log(e)
        }
      }

      return layersList
    },
    startDataRequest() {
      this.requestList.forEach(e => {
        this.dataRequest(this, e, 'widget')
        if (this.refreshData[e.url]) return
        this.refreshData[e.url] = setInterval(
          this.doRequest,
          120000,
          e,
          'widget'
        )
      })
      this.mapRequestList.forEach(e => {
        this.dataRequest(this, e.request, 'map', e.event_layer)
        if (this.refreshData[e.request.url]) return
        this.refreshData[e.request.url] = setInterval(
          this.doRequest,
          120000,
          e.request,
          'map',
          e.event_layer
        )
      })
      this.mapPlugList.forEach(e => {
        this.$store.commit('ADD_SI_CENTER_MAP_PLUG_ITEM', e.layer_id)
      })
    },
    doRequest(item, comp, event_layer = false) {
      this.dataRequest(this, item, comp, event_layer)
    },
    deleteRequestInterval() {
      this.requestList.forEach(e => {
        clearInterval(this.refreshData[e.url])
      })
      this.mapRequestList.forEach(e => {
        clearInterval(this.refreshData[e.request.url])
      })
      this.refreshData = cloneDeep({})
    },
    dataRequest
  }
}
</script>

<style lang="scss">
.situation-center {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex: 1;
  background-color: var(--bg_panel_primary) !important;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2) inset;
}
</style>
