<template>
  <odd-router />
</template>

<script>
import oddRouter from '@/components/odd/panels/router'

export default {
  components: { oddRouter }
}
</script>
