<template>
  <el-dialog
    title="Экспортировать дэшборд"
    :visible.sync="visible"
    width="350px"
    append-to-body
    :before-close="close"
    class="r-modal-window"
  >
    <div class="an-export-modal">
      <r-text color-type="secondary">
        Выберите тип файла
      </r-text>
      <div class="an-export-modal__content">
        <el-select
          v-model="fileType"
          class="r-select"
          placeholder="Выбрать типы"
        >
          <el-option
            v-for="item in fileTypes"
            :key="item.id"
            :value="item.value"
            :label="`.${item.value}`"
          />
        </el-select>
        <r-button
          type="primary"
          :disabled="!fileType"
          @click="exportData"
        >
          Загрузить
        </r-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { fileTypes } from './configs'
import { reportOptions } from '../../configs'
import { saveAs } from 'file-saver'

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      fileType: '',
      fileTypes
    }
  },
  computed: {
    reportUrl() {
      return this.$store.state.analytics.reportUrl || null
    },
    reportName() {
      return this.$store.state.analytics.reportName || 'analytic-report'
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async exportData() {
      try {
        const reportOption = reportOptions[this.reportName] || {}
        const columnOrder = reportOption?.columnsOrder?.map(e => e.id)

        let url = `${this.reportUrl}&as_file=true&format=${this.fileType}`
        if (columnOrder) {
          url = url + `&column_order=${JSON.stringify(columnOrder)}`
        }
        const { columnOrderNew, columnLabels } = reportOption
        if (columnOrderNew) {
          url = url + `&column_order=${JSON.stringify(columnOrderNew)}`
        }
        if (columnLabels) {
          url = url + `&column_labels=${JSON.stringify(columnLabels)}`
        }
        const fields = reportOption?.tableFields
        if (fields) {
          const tableFields = fields?.map(f => f.value)
          url = url + `&column_order=${JSON.stringify(tableFields)}`
        }
        const { data } = await this.$store.dispatch('GET_BLOB_REQUEST', {
          url
        })

        saveAs(data, this.reportName)
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>

<style lang="scss">
.an-export-modal {
  position: relative;

  &__content {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
