import cloneDeep from 'lodash.clonedeep'
import merge from 'lodash.mergewith'

export const parseObjectFields = (initial, ds, nameFromProp = false) => {
  // parse object fields
  const fields = []
  for (const prop in initial.data) {
    if (!ds && prop === 'geom') continue // hack
    if (!ds && prop === 'geom_extent') continue // hack
    if (!ds && prop === 'center_geom') continue // hack
    if (!ds && prop === 'track_data') continue // hack
    if (!ds && prop === 'car_events') continue // hack
    if (prop === 'metadata') continue
    if (prop === 'default_time_field_attribute') continue
    const item = initial.data[prop]
    const source_id =
      item.reflection && !item.type ? item.reflection.source_id : null
    const type =
      item.reflection && !ds && source_id ? item.reflection.type : item.type
    const source_key = !!(item.reflection && !source_id)
    const source_name = item.reflection && !nameFromProp ? item.reflection.name : prop
    const foreign_key = item.reflection ? item.reflection.foreign_key : null
    const primary_key = item.reflection ? item.reflection.primary_key : null
    const system_field = item.system_field ? item.system_field : false
    const read_only = item.read_only ? item.read_only : false
    const { min, max, time_field, alias } = item
    const field = {
      title: prop,
      reflection: item.reflection,
      datatype: item.datatype,
      type,
      source_id,
      source_name,
      foreign_key,
      primary_key,
      source_key,
      min,
      max,
      time_field,
      system_field,
      read_only,
      alias
    }
    if (ds) {
      field.default = item.default ? item.default : null
      field.time_field = item.time_field
    }
    fields.push(field)
  }
  return fields
}

export const getEditableObjectFields = data =>
  Object.keys(data)
    .filter(name => data[name])
    .map(name => ({
      type: data[name].type,
      reflection: data[name].reflection,
      system_field: data[name].system_field,
      read_only: data[name].read_only,
      name
    }))
    .filter(
      ({ name, reflection, system_field, type, read_only }) =>
        !reflection &&
        !system_field &&
        read_only !== true &&
        type !== 'geometry' &&
        type !== 'datetime' &&
        type !== 'uuid' &&
        name !== 'geom'
    )

export const functionTypes = ['concat', 'count', 'min', 'max', 'avg', 'sum']

export const getAttributePath = node => {
  // get attribute path from tree node (datagrid - options)
  const pathArray = []
  const recursive = node => {
    const title = node.data.title
    pathArray.push(title)
    if (node.parent.parent) {
      const { fn_type } = node.parent.data
      pathArray.push(fn_type)
      recursive(node.parent)
    }
  }
  recursive(node)
  const path = pathArray.reverse().join('/')
  const pathStr = path.replace(/\//g, '_')
  let pathLabel = path;
  ['edit', ...functionTypes].forEach(type => {
    pathLabel = pathLabel.replace(new RegExp(`/${type}`, 'g'), `:${type}`)
  })
  return { path: pathStr, label: pathLabel }
}

export const getAttributeConfig = (node, that) => {
  // get attribute config from tree node (datagrid - options)
  let config = {}
  let firstTime = true
  let alias
  const recursive = node => {
    if (node.parent.parent) {
      if (firstTime) { alias = getAttributePath(node).path }
      // alias = `${node.parent.data.title}_${node.data.title}_${
      //   node.parent.data.fn_type
      // }`;
      config = {
        [node.parent.data.source_name]: {
          only: [
            {
              field: firstTime ? node.data.title : alias,
              agg_op: node.parent.data.fn_type,
              alias
            }
          ],
          include: firstTime ? null : config
        }
      }
      if (node.parent.data.fn_type === 'time_fn') {
        const tmState = that.$store.state.map.timemachine

        config[node.parent.data.source_name].where = [
          {
            field: tmState.time_field,
            op: '=',
            value: tmState.times[tmState.current]
          }
        ]
      }
      firstTime = false
      recursive(node.parent)
    }
  }
  recursive(node)
  return {
    config: {
      only: ['id'],
      include: config
    },
    alias
  }
}

export const getAttributes = (attributes, all, withDatetime, withString) => {
  if (all) return cloneDeep(attributes)
  return cloneDeep(attributes)
    .filter(
      f =>
        (withString && f.type === 'string') ||
        f.type === 'integer' ||
        f.type === 'decimal' ||
        f.type === 'float' ||
        f.type === 'numeric' ||
        (withDatetime && f.type === 'datetime') ||
        f.source_id
    )
    .filter(f => f.max !== null && f.min !== null && f.max !== 0)
}

export const numberTypes = [
  'integer',
  'decimal',
  'float',
  'numeric',
  'datetime'
]

export const getFieldsAggregation = async(map, only, id) => {
  const source_id = map.$store.state.profiles.sourceIdById[id]
  const simpleFields = only.filter(f => {
    const field = map.objectFields[id].find(field => field.title === f)
    if (!field) return false
    return numberTypes.indexOf(field.type) > -1
  })
  const includeFields = only.filter(
    f => map.objectFieldsConfigs[id] && map.objectFieldsConfigs[id][f]
  )
  const customizer = (objValue, srcValue) => {
    if (Array.isArray(objValue)) {
      const result = objValue.concat(srcValue)
      return result.filter((el, i) => result.indexOf(el) === i)
    }
  }
  const include = includeFields.reduce(
    (prev, f) =>
      merge(prev, map.objectFieldsConfigs[id][f].include, customizer),
    {}
  )
  const fieldsConfig = {
    only: simpleFields,
    include: includeFields.length ? include : {}
  }

  const fieldsUrl = `aggregation/${source_id}?config=${JSON.stringify(
    fieldsConfig
  )}`
  const fieldsAggregation = await map.$store.dispatch('GET_REQUEST', {
    url: fieldsUrl
  })

  Object.keys(fieldsAggregation.data).forEach(f => {
    const field = fieldsAggregation.data[f]
    const index = map.objectFields[id].findIndex(item => item.title === f)
    if (index > -1) {
      map.objectFields[id][index] = {
        ...map.objectFields[id][index],
        ...field
      }
    } else if (f !== 'metadata') {
      map.objectFields[id].push({
        title: f,
        ...fieldsAggregation.data[f]
      })
    }
  })

  const allCount = fieldsAggregation.data.metadata.count
  map.objectsCounts[id] = allCount

  map.$store.commit('SET_OBJECT_FIELDS', {
    source_id,
    fields: map.objectFields[id]
  })
}

export const updateObjectFieldsConfigs = (map, id, path, config) => {
  if (config.alias) {
    if (!map.objectFieldsConfigs[id]) map.objectFieldsConfigs[id] = {}
    map.objectFieldsConfigs[id][path] = config.config
  }
}

export const getConditions = (id, palette, field, that) => {
  const attributes = that.controllers.layers.map.objectFields[id]
  const attribute = attributes.find(f => f.title === field)
  if (!attribute) return []
  const { min, max } = attribute
  if (!min && !max) return []
  const step = Number(((max - min) / 5).toFixed(1))
  const colors = [...palette.value].reverse()

  return colors.map((color, i) => {
    const id = Date.now() + i
    const index = i === 0 ? colors.length - i - 1 : colors.length - i
    const value = Number(((index + 1) * step).toFixed(1))
    const style = {
      color: color
    }
    const initial = i === 0 ? true : undefined

    return { id, value, style, initial }
  })
}
