// import { ArcLayer } from '@deck.gl/layers'
// import { MapboxLayer } from '@deck.gl/mapbox'
import { getDatasourceById, rDate } from '@/utils'

const zonesConfig = {
  only: ['id', 'geom', 'center_geom', 'no']
}

const getArcsConfig = limit => ({
  order: [
    {
      value: 'desc'
    }
  ],
  limit
})

function getRGBValues(str) {
  const values = str.substring(str.indexOf('(') + 1, str.length - 1).split(',')

  return values.map((v, i) =>
    i !== values.length - 1 ? Number(v) : Math.floor(255 * v)
  )
}

export class MatricesController {
  constructor(map) {
    this.map = map
    this.mapgl = map.mapgl
    this.$store = map.$store
    this.styleConfig = map.styleConfig

    // this.zoneClickHandler = this.zoneClickHandler.bind(this);
  }

  // get data helpers
  async getMatrices(config, modelId, id) {
    if (!config.matricesSourceId) {
      const model = await getDatasourceById(this.map, modelId, {
        children: {}
      })
      const matricesSource = model.children.find(c => c.datatype === 'matrices')

      this.map.$set(config, 'matricesSourceId', matricesSource.id)
    }

    // let matrices

    // if (this.$store.state.map.matricesList[id]) {
    //   matrices = this.$store.state.map.matricesList[id]
    // } else {
    const requestConfig = {
      where: [
        {
          field: 'date',
          op: '>',
          value: rDate
            .calc(-2, 'days', new Date())
            .format()
        }
      ],
      order: { no: 'asc' }
    }
    const matricesUrl = `objectInfo/${
        config.matricesSourceId
      }?config=${JSON.stringify(requestConfig)}`
    const { data } = await this.$store.dispatch('GET_REQUEST', {
      url: matricesUrl
    })

    const matrices = Object.values(data)

    this.$store.commit('SET_MATRICES_LIST', {
      layerId: id,
      list: matrices
    })

    const activeMatrix = matrices[0].id

    this.map.$set(config, 'matrixId', activeMatrix)

    return matrices
  }

  async getZones(id) {
    let zones

    if (this.$store.state.map.zonesList[id]) {
      zones = this.$store.state.map.zonesList[id]
    } else {
      const sourceId = this.$store.state.profiles.sourceIdById[id]
      const zonesUrl = `objectInfo/${sourceId}?config=${JSON.stringify(
        zonesConfig
      )}`
      const { data } = await this.$store.dispatch('GET_REQUEST', {
        url: zonesUrl
      })

      zones = Object.values(data)

      this.$store.commit('SET_ZONES_LIST', {
        layerId: id,
        list: zones
      })
    }

    return zones
  }

  // get arcs data
  async getMatricesData(modelId, id, config) {
    try {
      // get matrices if matrices list is empty
      if (!this.$store.state.map.matricesList[id]) {
        await this.getMatrices(config, modelId, id)
      }

      // get zones
      await this.getZones(id)

      // get arcs data
      let arcsUrl = `network_matrix/${modelId}?matrix_id=${config.matrixId}&config=${JSON.stringify(
        getArcsConfig(config.count)
      )}`
      if (config.zoneId) arcsUrl += `&fromzone_id=${config.zoneId}`

      const { data } = await this.$store.dispatch('GET_REQUEST', {
        url: arcsUrl
      })
      const arcs = data

      if (!arcs) return null

      // get min and max
      let min = arcs[0].value
      let max = arcs[0].value

      arcs.forEach(({ value }) => {
        if (value < min) min = value
        if (value > max) max = value
      })

      this.map.$set(config, 'min', min)
      this.map.$set(config, 'max', max)

      return arcs
    } catch (error) {
      console.log(error)

      return null
    }
  }

  getLabelsData(data) {
    if (!data) return null

    return {
      type: 'FeatureCollection',
      features: data.map(({ from_geom, to_geom, value }) => ({
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [from_geom.coordinates, to_geom.coordinates]
        },
        properties: {
          value: Number(value).toFixed(1)
        }
      }))
    }
  }

  getLayerOptions(id) {
    const config = this.styleConfig[id]
    const { matrices } = config
    const { strokeWidth, sourceColor, targetColor, min, max } = matrices

    return {
      getSourcePosition: d => d.from_geom.coordinates,
      getTargetPosition: d => d.to_geom.coordinates,
      getSourceColor: getRGBValues(sourceColor),
      getTargetColor: getRGBValues(targetColor),
      widthUnits: 'pixels',
      widthScale: 1,
      getWidth: d => {
        const { value } = d
        const part = (value - min) / (max - min)

        return part * strokeWidth
      }
    }
  }

  async toggleMatrices(id) {
    const config = this.styleConfig[id]
    const { matrices } = config
    const { enabled, modelId } = matrices
    const mapLayerId = `${id}_matrices`
    const labelsLayerId = `${id}_matrices_labels`
    const circlesLayerId = `${id}_matrices_circles`
    const mapLayer = this.mapgl.getSource(mapLayerId)

    if (enabled && !mapLayer) {
      const data = await this.getMatricesData(modelId, id, matrices)
      const labelsData = this.getLabelsData(data)
      // const options = this.getLayerOptions(id)
      // const layer = new MapboxLayer({
      //   id: mapLayerId,
      //   type: ArcLayer,
      //   data,
      //   ...options
      // })

      if (labelsData) {
        this.mapgl.addSource(labelsLayerId, {
          type: 'geojson',
          data: labelsData
        })
        this.mapgl.addLayer({
          id: labelsLayerId,
          source: labelsLayerId,
          type: 'symbol',
          paint: {
            'text-color': '#eee',
            'text-halo-color': '#202',
            'text-halo-width': 1
          },
          layout: {
            'text-field': ['get', 'value'],
            'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
            'text-size': 7,
            'text-letter-spacing': 0.05,
            'text-pitch-alignment': 'map',
            'text-rotation-alignment': 'map',
            'symbol-placement': 'line-center'
          }
        })
      }

      // TODO: FIX
      const { max, strokeWidth } = matrices

      this.mapgl.addSource(mapLayerId, {
        type: 'geojson',
        data: this.getLineData(data)
      })
      this.mapgl.addLayer({
        id: mapLayerId,
        source: mapLayerId,
        type: 'line',
        paint: {
          'line-color': 'rgb(255, 68, 140)',
          'line-opacity': 0.3,
          'line-width': [
            'interpolate',
            ['linear'],
            ['get', 'value'],
            0,
            1,
            max || 1,
            strokeWidth
          ]
        }
      })

      // this.mapgl.addLayer(layer)
    } else {
      if (this.mapgl.getLayer(mapLayerId)) this.mapgl.removeLayer(mapLayerId)
      if (this.mapgl.getLayer(labelsLayerId)) {
        this.mapgl.removeLayer(labelsLayerId)
      }
      if (this.mapgl.getLayer(circlesLayerId)) {
        this.mapgl.removeLayer(circlesLayerId)
      }
      if (this.mapgl.getSource(labelsLayerId)) {
        this.mapgl.removeSource(labelsLayerId)
      }
      if (this.mapgl.getSource(mapLayerId)) {
        this.mapgl.removeSource(mapLayerId)
      }
      this.map.$set(matrices, 'enabled', false)
    }
  }

  getLineData(data) {
    return {
      type: 'FeatureCollection',
      features: data
        ? data.map(({ value, from_geom, to_geom }) => ({
          type: 'Feature',
          properties: {
            value
          },
          geometry: {
            type: 'LineString',
            coordinates: [from_geom.coordinates, to_geom.coordinates]
          }
        }))
        : []
    }
  }

  async changeData(id) {
    const config = this.styleConfig[id]
    const { matrices } = config
    const { modelId } = matrices
    const mapLayerId = `${id}_matrices`
    const labelsLayerId = `${id}_matrices_labels`
    const mapLayer = this.mapgl.getLayer(mapLayerId)

    if (!mapLayer) return

    this.map.$set(matrices, 'loading', true)

    const data = await this.getMatricesData(modelId, id, matrices)
    const labelsData = this.getLabelsData(data)
    // const options = this.getLayerOptions(id)

    // mapLayer.implementation.setProps({
    //   data,
    //   ...options
    // })

    const labelsSource = this.mapgl.getSource(labelsLayerId)

    this.map.$set(matrices, 'loading', false)

    if (!labelsSource) return

    labelsSource.setData(labelsData)

    // TODO: FIX
    const lineSource = this.mapgl.getSource(mapLayerId)

    if (!lineSource) return

    lineSource.setData(this.getLineData(data))
  }

  changeProperty(id) {
    const mapLayerId = `${id}_matrices`
    const mapLayer = this.mapgl.getLayer(mapLayerId)

    if (!mapLayer) return

    // const options = this.getLayerOptions(id)
    // const { implementation } = mapLayer
    // const { data } = implementation.props

    // this.mapgl.removeLayer(mapLayerId)

    // const layer = new MapboxLayer({
    //   id: mapLayerId,
    //   type: ArcLayer,
    //   data,
    //   ...options
    // })

    // this.mapgl.addLayer(layer)

    const config = this.styleConfig[id]
    const { matrices } = config
    const { max, strokeWidth } = matrices

    this.mapgl.setPaintProperty(mapLayerId, 'line-width', [
      'interpolate',
      ['linear'],
      ['get', 'value'],
      0,
      1,
      max || 1,
      strokeWidth
    ])
  }
}
