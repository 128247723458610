<template>
  <r-simple-card title="Обводка">
    <r-color-picker
      :color="layerStyle.paint['circle-stroke-color']"
      :color-opacity="layerStyle.paint['circle-stroke-opacity']"
      @change="changeColorProperty"
    />
    <r-slider
      style="margin-top: 8px;"
      title="Ширина"
      :number="layerStyle.paint['circle-stroke-width']"
      :min="1"
      :max="10"
      :step="1"
      @change="changeWidthProperty"
    />
  </r-simple-card>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    layerStyle: {
      type: Object,
      required: true
    },
    controllers: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    changeColorProperty({ color, opacity }) {
      this.layerStyle.paint['circle-stroke-color'] = color
      this.layerStyle.paint['circle-stroke-opacity'] = opacity

      this.$emit('change-property', {
        type: 'paint',
        field: 'circle-stroke-color'
      })
      this.$emit('change-property', {
        type: 'paint',
        field: 'circle-stroke-opacity'
      })
    },
    changeWidthProperty(width) {
      this.layerStyle.paint['circle-stroke-width'] = width

      this.$emit('change-property', {
        type: 'paint',
        field: 'circle-stroke-width'
      })
    }
  }
}
</script>

<style></style>
