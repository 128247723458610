<template>
  <div class="rp-users-personal-wrapper">
    <rp-users-tip
      v-if="isIbAdmin"
      :tip="isIbAdminTip"
    />
    <rp-users-tip
      v-else-if="isAdmin"
      :tip="isAdminTip"
    />
    <div class="rp-users-personal">
      <div class="rp-users-personal-row">
        <rp-users-personal-photo
          v-if="activeUser"
          :user="activeUser"
        />
      </div>
      <div class="rp-users-personal-row">
        <r-text color-type="subhead">
          Логин
        </r-text>
        <el-input
          v-model="activeUser.name"
          class="r-input"
          type="text"
          placeholder="Диспетчер ЖД"
          clearable
          disabled
        />
      </div>
      <div class="rp-users-personal-row">
        <r-text color-type="subhead">
          Электронная почта
        </r-text>
        <el-input
          v-model="activeUser.email"
          class="r-input"
          type="text"
          placeholder="Диспетчер ЖД"
          clearable
          disabled
        />
      </div>
    </div>
  </div>
</template>

<script>
import rpUsersPersonalPhoto from './rp-users-personal-photo'
import rpUsersTip from '../rp-users-tip'

export default {
  components: {
    rpUsersPersonalPhoto,
    rpUsersTip
  },
  data() {
    return {
      isIbAdminTip:
        'Роль администратора имеет полные права для работы с модулями, компонентами и источникам данных, поэтому вы не можете изменять эти настройки.',
      isAdminTip:
        'Роль менеджера имеет полные права для работы с модулями, компонентами и источникам данных, поэтому вы не можете изменять эти настройки.'
    }
  },
  computed: {
    activeUserId() {
      return this.$store.state.rolePermission.activeUserId || null
    },
    activeUser() {
      const users = this.$store.state.rolePermission.allUsers || []

      return users?.find(u => u.id === this.activeUserId)
    },
    isIbAdmin() {
      return this.activeUser?.ib_admin || false
    },
    isAdmin() {
      return this.activeUser?.admin || false
    }
  }
}
</script>

<style lang="scss">
.rp-users-personal {
  position: relative;
  height: fit-content;
  width: 512px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  background-color: var(--bg_containers);

  &-row {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .r-text {
    margin-bottom: 8px;
  }
}
</style>
