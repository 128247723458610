<template>
  <div class="objects-item">
    <div :class="routeClass">
      <div
        class="objects-item__icon"
        @click="opened = !opened"
      >
        <r-icon
          :name="opened ? 'chevron-down' : 'chevron-right'"
          :size="18"
        />
      </div>
      <div
        class="objects-item__info"
        @click="clickHandler"
      >
        <div class="objects-item__name">
          <r-text>
            {{ item.name }}
          </r-text>
        </div>
        <div class="objects-item__count">
          <r-text type="caption">
            Остановки: {{ item.stops ? item.stops.length : 0 }}
          </r-text>
        </div>
      </div>
    </div>
    <div
      v-if="opened"
      class="objects-item__children"
    >
      <stops-list :stops="stops || []" />
    </div>
  </div>
</template>

<script>
import StopsList from './stops-list'

export default {
  components: {
    StopsList
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      opened: false
    }
  },
  computed: {
    cardId() {
      return this.$store.state.publicTransport.cardId
    },
    routeClass() {
      return this.item.id === this.cardId
        ? 'objects-item__container objects-item__container--active'
        : 'objects-item__container'
    },
    stops() {
      return this.item.stops
    }
  },
  methods: {
    clickHandler() {
      this.$store.commit('SET_PT_FIELD', {
        field: 'cardId',
        value: this.item.id
      })
      this.$store.commit('SET_PT_FIELD', {
        field: 'cardType',
        value: 'lines'
      })
    }
  }
}
</script>

<style lang="scss">
.objects-item {
  &:not(:first-child) {
    margin-top: 8px;
  }

  &__info {
    flex: 1;
  }

  &__container {
    padding: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: var(--border-radius);

    &.objects-item__container--active {
      background-color: var(--accent_active) !important;
    }

    &:hover {
      background-color: var(--accent_hover) !important;
    }
  }

  &__icon {
    margin-right: 8px;
  }

  &__children {
    margin-left: 16px;
    margin-top: 0.25rem;
  }
}
</style>
