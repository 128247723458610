export const layersConfig = {
  vehicles_clusters: {
    type: 'circle',
    source: 'vehicles',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'step',
        ['get', 'point_count'],
        'rgba(153,213,148, 1)',
        10,
        'rgba(254,224,139, 1)',
        50,
        'rgba(252,141,89, 1)',
        100,
        'rgba(213,62,79, 1)'
      ],
      'circle-radius': [
        'step',
        ['get', 'point_count'],
        10,
        20,
        14,
        50,
        18
      ]
    }
  },
  vehicles_clusters_count: {
    type: 'symbol',
    source: 'vehicles',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium'],
      'text-size': 11,
      'text-allow-overlap': true,
      'icon-allow-overlap': true
    }
  },
  'track-player-car': {
    type: 'symbol',
    layout: {
      'icon-image': 'car-sdf',
      'icon-pitch-alignment': 'map',
      'icon-rotate': ['get', 'course'],
      'icon-rotation-alignment': 'map',
      'icon-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        0.25,
        14,
        0.45,
        22,
        0.75
      ],
      'icon-ignore-placement': false,
      'text-allow-overlap': false,
      'icon-allow-overlap': false
    },
    paint: {}
  },
  'track-player-car-point': {
    type: 'circle',
    layout: {},
    paint: {
      'circle-radius': [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        12,
        14,
        18,
        22,
        25
      ],
      'circle-color': 'rgba(120, 120, 120, 0.4)',
      'circle-pitch-alignment': 'map',
      'circle-stroke-color': '#ccc',
      'circle-stroke-width': 1
    }
  },
  vehicles: {
    type: 'symbol',
    filter: ['!', ['has', 'point_count']],
    layout: {
      'icon-image': [
        'case',
        ['==', ['get', 'retranslation_status'], 'online'], 'monitoring-car-green',
        ['==', ['get', 'retranslation_status'], 'without_coords_halfline'], 'monitoring-car-yellow',
        ['==', ['get', 'retranslation_status'], 'with_coords_halfline'], 'monitoring-car-yellow',
        ['==', ['get', 'retranslation_status'], 'with_coords_offline'], 'monitoring-car-yellow',
        ['==', ['get', 'retranslation_status'], 'without_coords_offline'], 'monitoring-car-blue',
        ['==', ['get', 'retranslation_status'], 'nodata'], 'monitoring-car-red',
        'monitoring-car-red'
      ],
      'icon-pitch-alignment': 'map',
      'icon-rotate': ['get', 'course'],
      'icon-rotation-alignment': 'map',
      'icon-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        0.25,
        14,
        0.5,
        22,
        0.8
      ],
      'icon-ignore-placement': true,
      'text-allow-overlap': true,
      'icon-allow-overlap': true
    },
    paint: {
      'icon-opacity': [
        'case',
        ['==', ['get', 'retranslation_status'], 'without_coords_halfline'], 0.5,
        ['==', ['get', 'retranslation_status'], 'nodata'], 0.5,
        ['==', ['get', 'retranslation_status'], null], 0.5,
        1
      ]
    }
  },
  events: {
    type: 'symbol',
    layout: {
      'icon-image': [
        'case',
        ['==', ['get', 'event_type'], 'move'], 'move',
        ['==', ['get', 'event_type'], 'stop'], 'stop',
        ['==', ['get', 'event_type'], 'over_speed'], 'over_speed',
        ['==', ['get', 'event_type'], 'status_changed'], 'status_changed',
        ['==', ['get', 'event_type'], 'cross_geozone_in'], 'cross_geozone_in',
        ['==', ['get', 'event_type'], 'cross_geozone_out'], 'cross_geozone_out',
        ['==', ['get', 'event_type'], 'deviation_from_route'], 'deviation_from_route',
        ['==', ['get', 'event_type'], 'return_to_route'], 'monitoring-car-red',
        ['==', ['get', 'event_type'], 'geozone_over_speed'], 'over_speed',
        'track-event'
      ],
      'icon-pitch-alignment': 'map',
      'icon-rotation-alignment': 'map',
      'icon-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        0.2,
        14,
        0.30,
        22,
        0.45
      ],
      'icon-ignore-placement': true,
      'text-allow-overlap': true,
      'icon-allow-overlap': true
    },
    paint: {
      'icon-color': [
        'case',
        ['==', ['get', 'event_type'], 'move'], '#40a228',
        ['==', ['get', 'event_type'], 'stop'], '#6e7480',
        ['==', ['get', 'event_type'], 'over_speed'], '#e56566',
        ['==', ['get', 'event_type'], 'status_changed'], '#d69338',
        ['==', ['get', 'event_type'], 'cross_geozone_in'], '#2e93fe',
        ['==', ['get', 'event_type'], 'cross_geozone_out'], '#2e93fe',
        ['==', ['get', 'event_type'], 'deviation_from_route'], '#f06529',
        ['==', ['get', 'event_type'], 'return_to_route'], '#40a228',
        ['==', ['get', 'event_type'], 'geozone_over_speed'], '#e56566',
        '#ccc'
      ]
    }
  }
}

export const eventsTranslate = {
  move: 'Движение',
  stop: 'Остановка',
  over_speed: 'Превышение скорости',
  status_changed: 'Смена статуса',
  cross_geozone_in: 'Въезд в геозону',
  cross_geozone_out: 'Выезд из геозоны',
  deviation_from_route: 'Отклонение от маршрута',
  return_to_route: 'Возвращение к маршруту',
  geozone_over_speed: 'Превышение скорости в геозоне'
}
