<template>
  <div class="event-type-select">
    <div class="event-type-select-main">
      <r-text
        type="caption"
        style="margin-bottom: 4px;"
      >
        Выбор типа создаваемого события
      </r-text>
      <el-select
        v-model="eventType"
        class="r-select"
        @change="changeType"
      >
        <el-option
          v-for="item in eventClasses"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
    </div>
    <div class="event-type-select-additional">
      <geometry-select
        v-if="state.mode === 'create'"
        :state="state"
        @toggle-geometry-mode="toggleGeometryMode"
      />
    </div>
  </div>
</template>

<script>
import GeometrySelect from './geometry-select'

export default {
  components: {
    GeometrySelect
  },
  props: {
    state: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    eventClasses() {
      return this.$store.state.eventEditor.eventClasses
    },
    eventType: {
      get() {
        return this.$store.state.eventEditor.eventType
      },
      set(value) {
        this.$store.commit('SET_EE_STATE_PROP', {
          name: 'eventType',
          value
        })
      }
    }
  },
  created() {
    this.getBookInfo()
  },
  methods: {
    changeType(value) {
      const index = this.eventClasses.findIndex(c => c.id === value)
      let mode

      if (index === 0) {
        mode = 'points'
      } else if (index === 1) {
        mode = 'lines'
      } else {
        mode = 'polygons'
      }

      this.$store.commit('SET_EE_STATE_PROP', {
        name: 'geometryMode',
        value: mode
      })

      this.toggleGeometryMode(mode)
    },
    toggleGeometryMode(mode) {
      this.$emit('toggle-geometry-mode', mode)
    },
    async getBookInfo() {
      try {
        this.loading = true
        const { ids } = this.$store.state.eventEditor.model
        const eventTypesId = ids.event_classes
        const url = `objectInfo/${eventTypesId}`
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })
        const value = Object.values(data)

        this.$store.commit('SET_EE_STATE_PROP', {
          name: 'eventClasses',
          value
        })
        this.eventType = value[0].id
        this.changeType(this.eventType)
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.event-type-select {
  margin-top: 16px;

  &-main {
    .r-select {
      width: 100%;
    }
  }
}
</style>
