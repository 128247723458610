import {
  CommonSelectors,
  Constants,
  doubleClickZoom,
  mouseEventPoint,
  createSupplementaryPoints
} from '@/libs/map-draw/lib-utils'
import {
  clearAllHelpers,
  undraggableTypes,
  createLineGeoJson,
  getLineRouteItemsCoordinates,
  clearAllRouteGeometry
} from '@/libs/map-draw/helpers'

export const createEESimpleSelectMode = (MapboxDraw, editor, component) => {
  const SimpleSelect = {}
  const customState = {
    hoveredLinkId: ''
  }

  SimpleSelect.onSetup = function(opts) {
    // turn the opts into state.
    const state = {
      dragMoveLocation: null,
      boxSelectStartLocation: null,
      boxSelectElement: undefined,
      boxSelecting: false,
      canBoxSelect: false,
      dragMoveing: false,
      canDragMove: false,
      initiallySelectedFeatureIds: opts.featureIds || []
    }

    this.setSelected(
      state.initiallySelectedFeatureIds.filter(id => {
        return this.getFeature(id) !== undefined
      })
    )
    this.fireActionable()

    this.setActionableState({
      combineFeatures: true,
      uncombineFeatures: true,
      trash: true
    })

    clearAllRouteGeometry(this.map)

    editor.simpleSelectMode = this

    return state
  }

  SimpleSelect.startOnActiveFeature = function(state, e) {
    // Stop any already-underway extended interactions
    this.stopExtendedInteractions(state)

    // Disable map.dragPan immediately so it can't start
    this.map.dragPan.disable()

    // Re-render it and enable drag move
    this.doRender(e.featureTarget.properties.id)

    // Set up the state for drag moving
    state.canDragMove = undraggableTypes.indexOf(editor.geom_type) === -1
    state.dragMoveLocation = e.lngLat
  }

  SimpleSelect.onMouseMove = function(state) {
    if (editor) return this.stopExtendedInteractions(state)
  }

  SimpleSelect.clickOnFeature = function(state, e) {
    // Stop everything
    doubleClickZoom.disable(this)
    this.stopExtendedInteractions(state)

    const isShiftClick = CommonSelectors.isShiftDown(e)
    const selectedFeatureIds = this.getSelectedIds()
    const featureId =
      customState.hoveredLinkId || e.featureTarget.properties.id
    const isFeatureSelected = this.isSelected(featureId)

    clearAllHelpers(this.map)
    customState.hoveredLinkId = ''

    // Click (without shift) on any selected feature but a point
    if (
      !isShiftClick &&
      isFeatureSelected &&
      this.getFeature(featureId).type !== Constants.geojsonTypes.POINT
    ) {
      // Enter direct select mode
      return this.changeMode(Constants.modes.DIRECT_SELECT, {
        featureId: featureId
      })
    }

    // Shift-click on a selected feature
    if (isFeatureSelected && isShiftClick) {
      // Deselect it
      this.deselect(featureId)
      this.updateUIClasses({ mouse: Constants.cursors.POINTER })
      if (selectedFeatureIds.length === 1) {
        doubleClickZoom.enable(this)
      }
      // Shift-click on an unselected feature
    } else if (!isFeatureSelected && isShiftClick) {
      // Add it to the selection
      this.select(featureId)
      this.updateUIClasses({ mouse: Constants.cursors.MOVE })
      // Click (without shift) on an unselected feature
    } else if (!isFeatureSelected && !isShiftClick) {
      // Make it the only selected feature
      selectedFeatureIds.forEach(id => this.doRender(id))
      this.setSelected(featureId)
      this.updateUIClasses({ mouse: Constants.cursors.MOVE })
    }

    editor.activeObject = this.getFeature(featureId)
    editor.editingCallback = point => {
      this.map.fire(Constants.events.UPDATE, {
        action: 'change_properties',
        features: [point.toGeoJSON()]
      })
      component.draw.changeMode('simple_select', {
        featureIds: []
      })
    }
    component.$store.commit('SET_EE_STATE_PROP', {
      name: 'activeCard',
      value: true
    })
    component.$store.commit('SET_EE_STATE_PROP', {
      name: 'activeCardId',
      value: featureId
    })

    // No matter what, re-render the clicked feature
    this.doRender(featureId)
  }

  SimpleSelect.onTap = SimpleSelect.onClick = function(state, e) {
    // Click (with or without shift) on no feature
    if (customState.hoveredLinkId) return this.clickOnFeature(state, e)
    if (CommonSelectors.noTarget(e)) return this.clickAnywhere(state, e) // also tap
    if (CommonSelectors.isOfMetaType(Constants.meta.VERTEX)(e)) { return this.clickOnVertex(state, e) } // tap
    if (CommonSelectors.isFeature(e)) return this.clickOnFeature(state, e)
  }

  SimpleSelect.fireUpdate = function() {
    this.map.fire(Constants.events.UPDATE, {
      action: Constants.updateActions.MOVE,
      features: this.getSelected().map(f => f.toGeoJSON())
    })
  }

  SimpleSelect.onMouseOut = function(state) {
    // As soon as you mouse leaves the canvas, update the feature
    if (state.dragMoving) return this.fireUpdate()
    clearAllHelpers(this.map)
  }

  SimpleSelect.onMouseUp = function(state, e) {
    // End any extended interactions
    if (state.dragMoving) {
      this.fireUpdate()
    } else if (state.boxSelecting) {
      const bbox = [
        state.boxSelectStartLocation,
        mouseEventPoint(e.originalEvent, this.map.getContainer())
      ]
      const featuresInBox = this.featuresAt(null, bbox, 'click')
      const idsToSelect = this.getUniqueIds(featuresInBox).filter(
        id =>
          !this.isSelected(id) && !this.getFeature(id).properties.is_center_geom
      )

      if (idsToSelect.length) {
        this.select(idsToSelect)
        idsToSelect.forEach(id => this.doRender(id))
        this.updateUIClasses({ mouse: Constants.cursors.MOVE })
      }
    }
    this.stopExtendedInteractions(state)
  }

  SimpleSelect.onTrash = function() {
    const selectedIds = this.getSelectedIds()

    component.$store.commit('SET_EE_STATE_PROP', {
      name: 'activeCard',
      value: false
    })
    component.$store.commit('SET_EE_STATE_PROP', {
      name: 'activeCardId',
      value: null
    })

    this.deleteFeature(selectedIds)
    this.fireActionable()
  }

  SimpleSelect.clickAnywhere = function(state) {
    // Clear the re-render selection
    const wasSelected = this.getSelectedIds()
    if (wasSelected.length) {
      this.clearSelectedFeatures()
      wasSelected.forEach(id => this.doRender(id))
    }
    doubleClickZoom.enable(this)
    this.stopExtendedInteractions(state)

    component.$store.commit('SET_EE_STATE_PROP', {
      name: 'activeCard',
      value: false
    })
    component.$store.commit('SET_EE_STATE_PROP', {
      name: 'activeCardId',
      value: null
    })
  }

  SimpleSelect.onDrag = function(state, e) {
    if (state.canDragMove) {
      // draw dependencies map geometry
      this.dragMove(state, e)

      return
    }
    if (this.drawConfig.boxSelect && state.canBoxSelect) { return this.whileBoxSelect(state, e) }
  }

  SimpleSelect.onMouseUp = function(state, e) {
    // End any extended interactions
    if (state.dragMoving) {
      this.fireUpdate()
    } else if (state.boxSelecting) {
      const bbox = [
        state.boxSelectStartLocation,
        mouseEventPoint(e.originalEvent, this.map.getContainer())
      ]
      const featuresInBox = this.featuresAt(null, bbox, 'click')
      const idsToSelect = this.getUniqueIds(featuresInBox).filter(
        id => !this.isSelected(id)
      )

      if (idsToSelect.length) {
        this.select(idsToSelect)
        idsToSelect.forEach(id => this.doRender(id))
        this.updateUIClasses({ mouse: Constants.cursors.MOVE })
      }
    }
    this.stopExtendedInteractions(state)
  }

  SimpleSelect.toDisplayFeatures = function(state, geojson, display) {
    geojson.properties.active = this.isSelected(geojson.properties.id)
      ? Constants.activeStates.ACTIVE
      : Constants.activeStates.INACTIVE
    display(geojson)
    this.fireActionable()
    if (
      geojson.properties.active !== Constants.activeStates.ACTIVE ||
      geojson.geometry.type === Constants.geojsonTypes.POINT
    ) { return }
    if (editor.geom_type === 'line_routes') {
      const lineRouteItems = geojson.properties.user_line_route_items
      const coordinates = getLineRouteItemsCoordinates(
        lineRouteItems.filter(item => item.node_id || item.stop_point_id)
      )
      const pointsGeojson = createLineGeoJson(coordinates)
      pointsGeojson.properties = geojson.properties
      createSupplementaryPoints(pointsGeojson).forEach(display)
    } else createSupplementaryPoints(geojson).forEach(display)
  }

  return { ...MapboxDraw.modes.simple_select, ...SimpleSelect }
}
