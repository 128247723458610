export const setWindowOptions = function(state, name) {
  state.noTitle = false
  if (name) {
    switch (name) {
      case 'public-notifications':
      case 'email-distributions':
      case 'telematics-modal':
      case 'attributes-modal':
        state.window.width = '640px'
        state.window.top = '50px'
        state.noTitle = true
        state.modalWindowType = 'default'
        break
      case 'bdd-restriction-modal':
      case 'bdd-preventive-modal':
      case 'bdd-violations-modal':
      case 'bdd-pass-register-modal':
      case 'bdd-applications-modal':
      case 'pot-modal-card':
        state.window.top = '50px'
        state.modalWindowType = 'default'
        state.noTitle = true
        state.window.width = '640px'
        break
      case 'odd-modal-card':
        state.window.top = '50px'
        state.modalWindowType = 'default'
        state.noTitle = true
        state.window.width = '800px'
        break
      case 'bdd-violations-create':
        state.window.top = '50px'
        state.modalWindowType = 'default'
        state.noTitle = true
        state.window.width = '420px'
        break
      case 'bdd-acts-modal':
        state.window.top = '50px'
        state.modalWindowType = 'default'
        state.noTitle = true
        state.window.width = 'max-content'
        break
      case 'account':
      case 'user-tasks':
        state.window.width = 'max-content'
        state.window.top = '50px'
        state.modalWindowType = 'account-window'
        break
      case 'settings-reset':
      case 'modelling-settings':
        state.window.width = '400px'
        state.window.top = '20vh'
        state.modalWindowType = 'small'
        break
      case 'rp-user-create-role':
        state.window.width = '464px'
        state.window.top = '10vh'
        state.modalWindowType = 'auto'
        break
      case 'book-filter':
      case 'create-object':
        state.window.width = '400px'
        state.window.top = '15vh'
        state.modalWindowType = 'max-height'
        break
      case 'about':
      case 'ds-manager':
      case 'ds-export':
      case 'profiles':
        state.window.width = 'max-content'
        state.window.top = '15vh'
        state.modalWindowType = 'auto'
        state.noTitle = true
        break
      case 'updates':
      case 'sc-modal-window':
        state.window.width = 'max-content'
        state.window.top = '15vh'
        state.modalWindowType = 'max-height'
        state.noTitle = true
        break
      case 'rp-attr-filter':
        state.window.width = '80%'
        state.window.top = '50px'
        state.modalWindowType = 'default'
        break
      default:
        state.window.width = '70%'
        state.window.top = '50px'
        state.modalWindowType = 'default'
        break
    }
  }
}
