<template>
  <div class="odd">
    <div class="odd__panels">
      <r-main-panel>
        <odd-router v-if="routerMode" />
        <odd-objects
          v-else
          :loading="loading"
        />
      </r-main-panel>
      <odd-card v-if="cardId" />
    </div>
    <div class="odd__main">
      <odd-map
        @map-ready="setReady"
        @setLoading="setLoading"
      />
    </div>
  </div>
</template>

<script>
import OddCard from './card/card'
import OddMap from './map/map'
import OddObjects from './panels/objects'
import OddRouter from './panels/router'

export default {
  components: {
    OddCard,
    OddMap,
    OddObjects,
    OddRouter
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    cardId() {
      return this.$store.state.odd.cardId
    },
    routerMode() {
      return this.$route.name.includes('router')
    }
  },
  created() {
    if (this.$route.name !== 'odd-objects') {
      this.$router.push('/app/odd/objects')
    }
  },
  methods: {
    setReady() {
      this.openTransferedEvent()
    },
    openTransferedEvent() {
      const { id } = this.$route.query

      if (!id) return

      this.$store.commit('SET_ODD_FIELD', {
        field: 'cardType',
        value: 'events'
      })
      this.$store.commit('SET_ODD_FIELD', {
        field: 'cardId',
        value: id
      })

      setTimeout(() => {
        const geom = this.$store.state.odd.lists?.events?.find(e => e.id === id)?.geom

        if (!geom) return

        this.$store.commit('SET_ODD_FIELD', {
          field: 'flyToGeom',
          value: geom
        })
      }, 500)
    },
    setLoading(value) {
      this.loading = value
    }
  }
}
</script>

<style lang="scss">
.odd {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  background-color: var(--bg_surface) !important;

  &__panels {
    display: flex;
    z-index: 10;
    box-shadow: 2px 0 10px 0px rgba(0, 0, 0, 0.1);
  }

  &__main {
    height: 100%;
    flex: 1;
  }
}
</style>
