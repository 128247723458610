<template>
  <div :class="`map-legend ${mapMode}-legend`">
    <div class="map-legend__toggle">
      <div
        :class="[isLegendOpen ? 'active' : '', 'map-legend__button legend']"
        @click="toggleLegend"
      >
        <r-icon
          name="help"
          :size="20"
          :active="isLegendOpen"
        />
      </div>
    </div>
    <div
      v-if="isLegendOpen"
      class="map-legend-content"
    >
      <div class="map-legend-header">
        Легенда
      </div>
      <template v-if="mapMode === 'vizualization'">
        <ul
          v-if="legendState.length"
          class="map-legend-list"
        >
          <li
            v-for="item in legendState"
            :key="item.id"
            class="map-legend-item"
          >
            <basic-layers
              v-if="item.type === 'basic' || item.type === 'icon'"
              :get-legend-color="getLegendColor"
              :item="item"
            />
            <classes-layers
              v-else-if="item.type === 'classes'"
              :get-legend-color="getLegendColor"
              :item="item"
            />
            <heatmap-layers
              v-else-if="item.type === 'heatmap'"
              :item="item"
            />
            <diagram-layers
              v-if="item.diagram"
              :item="item"
            />
          </li>
        </ul>
        <div
          v-else
          class="map-legend-nolayers"
        >
          Активные слои отсутствуют
        </div>
      </template>
      <editor-layers
        v-else-if="mapMode === 'editor' || mapMode === 'pt-registry'"
        :editor-state="editorState"
        :map-mode="mapMode"
      />
    </div>
  </div>
</template>

<script>
import BasicLayers from './basic-layers'
import ClassesLayers from './classes-layers'
import HeatmapLayers from './heatmap-layers'
import DiagramLayers from './diagram-layers'
import EditorLayers from './editor-layers'
import { geomTypes } from '@/config/geom-types'
import { layerTypesConfig } from '@/components/map/configs'

export default {
  components: {
    BasicLayers,
    ClassesLayers,
    HeatmapLayers,
    DiagramLayers,
    EditorLayers
  },
  props: {
    map: {
      type: Object,
      required: true
    },
    mapMode: {
      type: String,
      required: true
    },
    objectsCounts: {
      type: Object,
      required: true
    },
    featuresInBboxCounts: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      editorState: this.$parent.editorState,
      isLegendOpen: false
    }
  },
  computed: {
    activeLayers() {
      return this.$store.state.map.activeLayers || []
    },
    heatmapLayers() {
      return this.$store.state.map.heatmapLayers || []
    },
    legendState() {
      return this.activeLayers.map(id => {
        const source_id = this.$store.state.profiles.sourceIdById[id]
        const layer = this.$store.state.datasources[source_id]
        const { name, geom_type, datatype } = layer

        if (datatype === 'tile_layer') {
          return {
            type: 'basic',
            color: 'transparent',
            borderColor: 'transparent',
            count: 1,
            allCount: 1,
            flag: this.isUpdate,
            geom_type: 'Tilelayer',
            name,
            id
          }
        }

        if (!this.map) return {}

        const config = this.$store.state.map.styleConfig[id]
        const { icon, classesStyle, stringsStyle, style, diagram } = config
        const count = this.featuresInBboxCounts[id]
        const field = this.getFieldName(geom_type)
        const allCount = this.objectsCounts[id]
        const parsedGeomType = geomTypes[geom_type]
        const item = {
          geom_type: parsedGeomType,
          flag: this.isUpdate,
          borderColor: this.getBorderColor(config, parsedGeomType),
          type: 'basic',
          id,
          name,
          count,
          allCount
        }

        // layers with icons
        if (icon && icon.enabled) {
          const color = style.paint[field] || 'rgba(255,255,255,0.4)'

          return {
            ...item,
            type: 'icon',
            icon: icon.name,
            color
          }
        }

        if (diagram && diagram.field) {
          if (!diagram.conditions.length) {
            item.diagram = {
              type: 'simple',
              field: diagram.field,
              color: diagram.color
            }
          } else {
            item.diagram = {
              type: 'complex',
              first: diagram.field,
              second: diagram.secondField,
              conditions: diagram.conditions.map((obj, i, arr) => {
                let text

                if (obj.initial) text = `> ${obj.value}`
                else if (i === arr.length - 1) text = `<= ${obj.value}`
                else text = `${obj.value} - ${arr[i + 1].value}`

                return {
                  ...obj,
                  color: obj.style.color,
                  text
                }
              })
            }
          }
        }

        if (
          classesStyle[field].field &&
          classesStyle[field].conditions.length
        ) {
          return {
            ...item,
            type: 'classes',
            field: classesStyle[field].field,
            conditions: classesStyle[field].conditions.map((obj, i, arr) => {
              let text
              let color

              if (obj.initial) text = `> ${obj.value}`
              else if (i === arr.length - 1) text = `<= ${obj.value}`
              else text = `${obj.value} - ${arr[i + 1].value}`

              if (datatype === 'arc_layer') {
                color = {
                  getSourceColor: obj.style.getLineColor,
                  getTargetColor: obj.style.getFillColor
                }
              } else {
                color = obj.style.color
              }

              return {
                ...obj,
                color,
                text
              }
            })
          }
        } else if (stringsStyle[field].field) {
          const { defaultValue, values } = stringsStyle[field]

          return {
            ...item,
            type: 'classes',
            field: stringsStyle[field].field,
            conditions: [
              ...values.map(v => ({ text: v.value, color: v.color })),
              {
                text: 'Другие',
                color: defaultValue
              }
            ]
          }
        } else {
          const color = style.paint[field] || 'rgba(255,255,255,0.4)'
          return {
            ...item,
            type: 'basic',
            color
          }
        }
      })
    }
  },
  watch: {
    mapMode(value) {
      if (value === 'editor') this.isLegendOpen = true
    }
  },
  methods: {
    toggleLegend() {
      this.isLegendOpen = !this.isLegendOpen
    },
    getFieldName(geom_type) {
      return `${layerTypesConfig[geom_type]}-color`
    },
    getLegendColor(item) {
      const type = typeof item.color

      if (type === 'string') {
        return `background-color: ${item.color}; border-color: ${item.borderColor}`
      } else {
        return `background: linear-gradient(to right, ${item.color.getSourceColor} 0%, ${item.color.getTargetColor} 100%); border-color: ${item.borderColor}`
      }
    },
    getBorderColor(config, geom_type) {
      switch (geom_type) {
        case 'Polygon':
        case 'MultiPolygon':
          return config.borderStyle.paint['line-color']
        case 'Point':
          return config.style.paint['circle-stroke-color']
        case 'LineString':
        case 'MultiLineString':
          return 'transparent'
        default:
          return 'transparent'
      }
    }
  }
}
</script>

<style lang="scss">
.map-legend {
  position: absolute;
  right: 16px;
  top: 176px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  &__toggle {
    color: var(--text_primary) !important;
    background-color: var(--modal_bg) !important;
    border-radius: var(--border-radius);
  }

  &__button {
    border-radius: var(--border-radius);
    width: 36px;
    height: 36px;
    cursor: pointer;

    &.active {
      background-color: var(--accent_hover) !important;
    }
  }

  &-content {
    border-radius: var(--border-radius);
    border: 1px solid transparent;
    width: 240px;
    z-index: 1000;
    font-size: 12px;
    color: var(--white_white);
    user-select: none;
    margin-top: 8px;

    background-color: var(--modal_bg) !important;
    border-color: var(--dividers_low_contrast) !important;
  }

  &.pt-registry-legend .map-legend-content {
    top: 123px;
  }

  &-header {
    padding: 8px;
    font-weight: bold;
    border-radius: var(--border-radius);
    text-transform: uppercase;
    color: var(--text_primary) !important;
    background-color: var(--modal_bg) !important;
  }

  &-nolayers {
    padding: 10px;
    color: var(--text_secondary) !important;
  }

  &-list {
    padding: 8px;
    overflow: auto;
    max-height: calc(100vh - 293px);
  }

  &-item {
    border-radius: var(--border-radius);
    padding: 8px;
    background-color: var(--bg_containers) !important;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &-tc-item {
    display: flex;

    &.wrap {
      flex-wrap: wrap;

      .map-legend-tc-value {
        margin-top: 2px;
      }
    }
  }

  &-layer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-title {
    font-weight: bold;
    margin-bottom: 4px;
    color: var(--text_primary) !important;
  }
  &-count {
    margin-left: 8px;
    color: var(--text_secondary) !important;
  }
  &-subtitle {
    margin-top: 2px;
    color: var(--text_secondary) !important;
  }

  &-row {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 5px;
  }
  &-color {
    height: 12px;
    width: 24px;
    border: 2px solid transparent;
    border-radius: var(--border-radius);
    flex-shrink: 0;
  }
  &-icon {
    width: 20px;
    height: 20px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-table {
    margin-top: 5px;
  }

  &-text {
    color: var(--text_primary) !important;
    margin-left: 20px;
  }

  &-table-header {
    margin-bottom: 8px;
    color: var(--text_secondary) !important;

    b {
      max-width: 100%;
      text-overflow: ellipsis;
      display: inline-block;
      overflow: hidden;
      line-height: 1.5;
    }
  }
}
</style>
