export const getApplicationUrlById = (id, source_id) => {
  const config = {
    where: [
      {
        field: 'id',
        op: '=',
        value: id
      }
    ],
    include: {
      contractor: { only: ['name'] },
      applications_employees: {},
      vehicle: {
        only: [
          'driver_full_name',
          'main_contractor_id',
          'vehicle_type_id',
          'driver_phone',
          'reg_number',
          'model_id',
          'vrc_sn',
          'mileage',
          'vin',
          'year',
          'passport'
        ],
        include: {
          vehicle_type: {
            include: {
              vehicle_type_group: {
                only: ['name']
              }
            }
          }
        }
      },
      status: {
        only: ['name']
      }
    }
  }
  return `objectInfo/${source_id}?config=${JSON.stringify(config)}`
}
