<template>
  <div
    v-if="settings && settings.values.length"
    :style="getStyle()"
    class="sc-map-popup-list"
  >
    <sc-map-popup-item
      v-for="(item, i) in settings.values"
      :key="i"
      :settings="item"
    />
  </div>
</template>

<script>
import scMapPopupItem from './sc-map-popup-item'

export default {
  components: {
    scMapPopupItem
  },
  props: {
    settings: {
      type: Object,
      required: true,
      default: () => null
    }
  },
  methods: {
    getStyle() {
      const display = this.settings.display
      const top = this.settings.top
      const left = this.settings.left

      return `display:${display};top:${top}px;left:${left}px`
    }
  }
}
</script>

<style lang="scss">
.sc-map-popup-list {
  max-width: 300px;
  position: absolute;
  border-radius: var(--border-radius);
  background-color: var(--bg_panel_primary) !important;
  color: var(--text_primary) !important;
  min-width: 120px;
  padding: 0.5rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
  display: grid;
  grid-gap: 1rem;
  z-index: 2222;

  > * {
    &:not(:last-child) {
      border-bottom: 1px solid;
      border-color: var(--dividers_low_contrast);
    }
  }
}
.r_medium {
  .sc-map-popup-list {
    max-width: 240px;
    min-width: 120px;
  }
}
</style>

<i18n>
{
  "ru": {
    "true": "Работает",
    "false": "Не работает",
    "location": "Адрес",
    "type": "Тип",
    "code": "Код",
    "phase": "Фаза",
    "status": "Статус",
    "mode": "Мод",
    "report": "Отчет",
    "voltage": "Вольтаж",
    "reg_number": "Рег.номер",
    "organization": "Организация",
    "event_class": "Тип события",
    "ДТП": "ДТП",
    "Мероприятия": "Мероприятие",
    "Ремонтные работы": "Дорожные работы",
    "contractor": "Контрактор",
    "contact": "Контакт",
    "contract": "Контракт",
    "period": "Период",
    "obj_id": "ИД объекта",
    "track_layer": "Трек",
    "avg_speed": "Средняя скорость",
    "distance": "Расстояние",
    "km/h": "км/ч",
    "m": "км",
    "to_time": "Время, до",
    "from_time": "Время, с"
  },
  "en": {
    "true": "Work",
    "false": "Error",
    "location": "Address",
    "type": "Type",
    "code": "Code",
    "phase": "Phase",
    "status": "Status",
    "mode": "Mode",
    "report": "Report",
    "voltage": "Voltage",
    "reg_number": "Reg.number",
    "organization": "Organization",
    "event_class": "Event type",
    "ДТП": "Accident",
    "Мероприятия": "Event",
    "Ремонтные работы": "Road work",
    "contractor": "Contractor",
    "contact": "Contact",
    "contract": "Contract",
    "period": "Period",
    "obj_id": "Object ID",
    "track_layer": "Track",
    "avg_speed": "Average speed",
    "distance": "Distance",
    "km/h": "km/h",
    "m": "km",
    "to_time": "To",
    "from_time": "From"
  }
}
</i18n>
