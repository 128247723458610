<template>
  <div>
    <el-popover
      v-model="isVisible"
      placement="bottom-end"
      popper-class="account-menu-popover r-popover"
      width="320"
      trigger="click"
    >
      <account-menu-avatar
        slot="reference"
        :user="user"
      />
      <div class="account-menu">
        <account-menu-user-info :user="user" />
        <account-menu-list
          :list="actionList"
          @hide-popover="hidePopover"
        />
      </div>
    </el-popover>
  </div>
</template>

<script>
import { accountActionList } from '../../config'

import accountMenuAvatar from './components/account-menu-avatar'
import accountMenuUserInfo from './components/account-menu-user-info'
import accountMenuList from './components/account-menu-list'

export default {
  components: {
    accountMenuAvatar,
    accountMenuUserInfo,
    accountMenuList
  },
  data() {
    return {
      isVisible: false,
      accountActionList
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
    actionList() {
      return this.accountActionList.default
    }
  },
  methods: {
    hidePopover() {
      this.isVisible = false
    }
  }
}
</script>

<style lang="scss">
.account-menu-popover {
  top: 50px !important;

  &.el-popper[x-placement^='bottom'] {
    margin-top: 0px;
  }
  padding: 0 !important;

  .popper__arrow {
    display: none !important;
  }
}
</style>
