<template>
  <div class="rp-users-content">
    <rp-users-header />
    <rp-users-control />
  </div>
</template>

<script>
import rpUsersHeader from './rp-users-header'
import rpUsersControl from './rp-users-control'

export default {
  components: {
    rpUsersHeader,
    rpUsersControl
  }
}
</script>

<style lang="scss">
.rp-users-content {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 16px;
  display: flex;
  flex-direction: column;
}
</style>
