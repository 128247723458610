import {
  Constants,
  doubleClickZoom,
  isEventAtCoordinates,
  createVertex
} from '@/libs/map-draw/lib-utils'

function checkAttributes(state, editor, component) {
  editor.creatingCallback = () => {
    editor.isDrawing = false
    this.changeMode('simple_select', { featureIds: [state.polygon.id] })
  }
  editor.creatingObject = state.polygon
  component.toggleCreateModal(true)
}

export const createDrawZonesMode = (MapboxDraw, editor, component) => {
  const DrawZone = {}

  function finishDrawing(state) {
    const { withAttributes } = editor

    if (withAttributes) {
      checkAttributes.call(this, state, editor, component)
    } else {
      editor.isDrawing = false
      return this.changeMode('simple_select', {
        featureIds: [state.polygon.id]
      })
    }
  }

  DrawZone.onSetup = function() {
    const polygon = this.newFeature({
      type: Constants.geojsonTypes.FEATURE,
      properties: {},
      geometry: {
        type: Constants.geojsonTypes.POLYGON,
        coordinates: [[]]
      }
    })

    this.addFeature(polygon)

    this.clearSelectedFeatures()
    doubleClickZoom.disable(this)
    this.updateUIClasses({ mouse: Constants.cursors.ADD })
    this.activateUIButton(Constants.types.POLYGON)
    this.setActionableState({
      trash: true
    })

    return {
      polygon,
      currentVertexPosition: 0,
      center_geom: null
    }
  }

  DrawZone.clickOnVertex = function(state) {
    editor.isDrawing = false
    this.deleteFeature([state.center_geom.id])
    finishDrawing.call(this, state)
  }

  DrawZone.clickAnywhere = function(state, e) {
    const { lng, lat } = e.lngLat

    if (!state.center_geom) {
      editor.isDrawing = true
      state.center_geom = this.newFeature({
        type: Constants.geojsonTypes.FEATURE,
        properties: {},
        geometry: {
          type: Constants.geojsonTypes.POINT,
          coordinates: [lng, lat]
        }
      })
      return
    }

    if (
      state.currentVertexPosition > 0 &&
      isEventAtCoordinates(
        e,
        state.polygon.coordinates[0][state.currentVertexPosition - 1]
      )
    ) {
      finishDrawing.call(this, state)
      return
    }

    this.updateUIClasses({ mouse: Constants.cursors.ADD })
    state.polygon.updateCoordinate(
      `0.${state.currentVertexPosition}`,
      lng,
      lat
    )
    state.currentVertexPosition++
    state.polygon.updateCoordinate(
      `0.${state.currentVertexPosition}`,
      lng,
      lat
    )
  }

  DrawZone.onStop = function(state) {
    this.updateUIClasses({ mouse: Constants.cursors.NONE })
    doubleClickZoom.enable(this)
    this.activateUIButton()

    // check to see if we've deleted this feature
    if (this.getFeature(state.polygon.id) === undefined) return

    // remove last added coordinate
    state.polygon.removeCoordinate(`0.${state.currentVertexPosition}`)
    if (state.polygon.isValid()) {
      const { type, coordinates } = state.center_geom
      state.polygon.setProperty('center_geom', { type, coordinates })
      editor.isDrawing = false
      this.map.fire(Constants.events.CREATE, {
        features: [state.polygon.toGeoJSON()]
      })
    } else {
      this.deleteFeature([state.polygon.id], { silent: true })
      this.changeMode(Constants.modes.SIMPLE_SELECT, {}, { silent: true })
    }
  }

  DrawZone.toDisplayFeatures = function(state, geojson, display) {
    if (state.center_geom && editor.isDrawing) {
      display(
        createVertex(
          state.center_geom.id,
          state.center_geom.coordinates,
          'center_geom',
          false
        )
      )
    }
    const isActivePolygon = geojson.properties.id === state.polygon.id
    geojson.properties.active = isActivePolygon
      ? Constants.activeStates.ACTIVE
      : Constants.activeStates.INACTIVE
    if (!isActivePolygon) return display(geojson)

    // Don't render a polygon until it has two positions
    // (and a 3rd which is just the first repeated)
    if (geojson.geometry.coordinates.length === 0) return

    const coordinateCount = geojson.geometry.coordinates[0].length
    // 2 coordinates after selecting a draw type
    // 3 after creating the first point
    if (coordinateCount < 3) {
      return
    }
    geojson.properties.meta = Constants.meta.FEATURE
    display(
      createVertex(
        state.polygon.id,
        geojson.geometry.coordinates[0][0],
        '0.0',
        false
      )
    )
    if (coordinateCount > 3) {
      // Add a start position marker to the map, clicking on this will finish the feature
      // This should only be shown when we're in a valid spot
      const endPos = geojson.geometry.coordinates[0].length - 3
      display(
        createVertex(
          state.polygon.id,
          geojson.geometry.coordinates[0][endPos],
          `0.${endPos}`,
          false
        )
      )
    }
    if (coordinateCount <= 4) {
      // If we've only drawn two positions (plus the closer),
      // make a LineString instead of a Polygon
      const lineCoordinates = [
        [
          geojson.geometry.coordinates[0][0][0],
          geojson.geometry.coordinates[0][0][1]
        ],
        [
          geojson.geometry.coordinates[0][1][0],
          geojson.geometry.coordinates[0][1][1]
        ]
      ]
      // create an initial vertex so that we can track the first point on mobile devices
      display({
        type: Constants.geojsonTypes.FEATURE,
        properties: geojson.properties,
        geometry: {
          coordinates: lineCoordinates,
          type: Constants.geojsonTypes.LINE_STRING
        }
      })
      if (coordinateCount === 3) {
        return
      }
    }

    // render the Polygon
    return display(geojson)
  }

  return { ...MapboxDraw.modes.draw_polygon, ...DrawZone }
}
