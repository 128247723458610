<template>
  <r-simple-card :title="title">
    <r-slider
      title="Ширина"
      :number="config[field]"
      :min="1"
      :max="20"
      :step="1"
      @change="changeProperty"
    />
  </r-simple-card>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: 'Ширина'
    },
    config: {
      type: Object,
      required: true
    },
    field: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      min: 1,
      max: 20
    }
  },
  methods: {
    changeProperty(value) {
      this.config[this.field] = value

      this.$cControllers.styles.changePaintProperty(this.id, this.field)
    }
  }
}
</script>

<style lang="scss"></style>
