import {
  CommonSelectors,
  Constants,
  isEventAtCoordinates
} from '@/libs/map-draw/lib-utils'
import {
  getPointFeatures,
  createHoveredNodeHelper,
  clearAllHelpers
} from '@/libs/map-draw/helpers'

export const createDrawLinksMode = (MapboxDraw, editor, component) => {
  const DrawLinks = {}
  const customState = {
    nodeNo: null,
    nodeGeometry: null,
    firstPointId: null
  }

  DrawLinks.onKeyUp = function(state, e) {
    if (CommonSelectors.isEscapeKey(e)) {
      this.deleteFeature([state.line.id], { silent: true })
      editor.isDrawing = false
      return this.changeMode(Constants.modes.SIMPLE_SELECT, {
        featureIds: [state.line.id]
      })
    }
  }

  DrawLinks.onMouseOut = function() {
    clearAllHelpers(this.map)
  }

  DrawLinks.clickOnVertex = function(state) {
    return state
  }

  DrawLinks.clickAnywhere = function(state, e) {
    const nodesFeatures = getPointFeatures(this.map, editor, e, 'nodes')

    if (state.currentVertexPosition === 0) {
      if (!nodesFeatures.length) return
      else {
        const node = nodesFeatures[0]
        const nodeCoordinates = node.geometry.coordinates
        state.currentVertexPosition++
        state.line.addCoordinate(0, nodeCoordinates[0], nodeCoordinates[1])
        state.line.setProperty('from_node_id', node.properties.id)
        editor.isDrawing = true
        customState.firstPointId = node.properties.id
        return
      }
    }

    if (nodesFeatures.length) {
      const node = nodesFeatures[0]
      const { properties, geometry } = node

      if (properties.id !== customState.firstPointId) {
        const nodeCoordinates = geometry.coordinates
        state.currentVertexPosition++
        state.line.addCoordinate(
          state.currentVertexPosition,
          nodeCoordinates[0],
          nodeCoordinates[1]
        )
        state.line.setProperty('to_node_id', properties.id)

        // attributes editing helper
        const { withAttributes } = editor

        if (withAttributes) {
          editor.creatingCallback = () => {
            editor.isDrawing = false
            this.changeMode(Constants.modes.SIMPLE_SELECT, {
              featureIds: [state.line.id]
            })
            this.onSetup()
          }
          editor.creatingObject = state.line
          component.toggleCreateModal(true)

          return
        }

        editor.isDrawing = false
        return this.changeMode(Constants.modes.SIMPLE_SELECT, {
          featureIds: [state.line.id]
        })
      }
    }

    if (
      (state.currentVertexPosition > 0 &&
        isEventAtCoordinates(
          e,
          state.line.coordinates[state.currentVertexPosition - 1]
        )) ||
      (state.direction === 'backwards' &&
        isEventAtCoordinates(
          e,
          state.line.coordinates[state.currentVertexPosition + 1]
        ) &&
        nodesFeatures.length)
    ) {
      return this.changeMode(Constants.modes.SIMPLE_SELECT, {
        featureIds: [state.line.id]
      })
    }

    this.updateUIClasses({ mouse: 'add' })
    state.line.updateCoordinate(
      state.currentVertexPosition,
      e.lngLat.lng,
      e.lngLat.lat
    )
    if (state.direction === 'forward') {
      state.currentVertexPosition++
      state.line.updateCoordinate(
        state.currentVertexPosition,
        e.lngLat.lng,
        e.lngLat.lat
      )
    } else {
      state.line.addCoordinate(0, e.lngLat.lng, e.lngLat.lat)
    }
  }

  DrawLinks.onMouseMove = function(state, e) {
    const { lng, lat } = e.lngLat
    const nodeFeatures = getPointFeatures(this.map, editor, e, 'nodes')

    if (nodeFeatures.length) {
      const feature = nodeFeatures[0]
      const { coordinates } = feature.geometry

      if (!customState.nodeNo || customState.nodeNo !== feature.properties.id) {
        createHoveredNodeHelper(this.map, coordinates)
        customState.nodeNo = feature.properties.no
        state.line.updateCoordinate(
          state.currentVertexPosition,
          coordinates[0],
          coordinates[1]
        )
      }
    } else {
      clearAllHelpers(this.map)
      customState.nodeNo = null
      state.line.updateCoordinate(state.currentVertexPosition, lng, lat)
    }

    if (CommonSelectors.isVertex(e)) {
      this.updateUIClasses({ mouse: Constants.cursors.POINTER })
    }
  }

  return { ...MapboxDraw.modes.draw_line_string, ...DrawLinks }
}
