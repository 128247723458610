const model = {
  id: null,
  name: null,
  ids: {},
  children: []
}

const state = {
  view: 'model-list',
  geometryMode: 'points',
  eventType: '',
  eventClasses: null,
  eventTypes: null,
  activeCard: false,
  activeCardId: null,
  overlay: false,
  loadingLayers: [],
  model
}

const mutations = {
  SET_EE_MODEL(state, { id, name, ids, children }) {
    state.model.id = id
    state.model.name = name
    state.model.ids = ids
    state.model.children = children
  },
  CLEAR_EE_MODEL(state) {
    state.model = {
      ...model
    }
  },
  SET_EE_STATE_PROP(state, { name, value }) {
    state[name] = value
  },
  ADD_EE_LOADING_LAYER(state, id) {
    if (state.loadingLayers.indexOf(id) === -1) state.loadingLayers.push(id)
  },
  REMOVE_EE_LOADING_LAYER(state, id) {
    state.loadingLayers.splice(state.loadingLayers.indexOf(id), 1)
  }
}

export default {
  state,
  mutations
}
