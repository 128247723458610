<template>
  <r-toggle-card
    title="Высота"
    :is-open="layerStyle.fillExtrusion.enabled"
    @toggle="toggleFillExtrusion"
  >
    <div class="fill-extrusion-picker">
      <div class="fill-extrusion-picker__simple">
        <r-slider
          title="Размер"
          :number="layerStyle.fillExtrusion.paint['fill-extrusion-height']"
          :min="50"
          :max="10000"
          :step="10"
          @change="changeProperty('fill-extrusion-height', $event)"
        />
      </div>
      <div class="fill-extrusion-picker__more-block">
        <div class="fill-extrusion-picker__attributes">
          <r-text
            type="caption"
            style="margin-bottom: 4px;"
          >
            Зависит от атрибута
          </r-text>
          <source-attributes
            :single="true"
            :active-fields="field"
            :tree-data="getAttributes(objectFields, false, false)"
            @handleNodeClick="changeField($event)"
          />
        </div>
      </div>
    </div>
  </r-toggle-card>
</template>

<script>
import SourceAttributes from '@/components/map/components/source-attributes/source-attributes'
import { getAttributes } from '@/utils'

export default {
  components: {
    SourceAttributes
  },
  props: {
    id: {
      type: String,
      required: true
    },
    layerStyle: {
      type: Object,
      required: true
    },
    controllers: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      moreShown: false,
      objectFields: this.$clientStore.getDataFields(
        this.$store.state.profiles.sourceIdById[this.id]
      ),
      field: this.getConfigValue('field') || '',
      fieldMin: this.getConfigValue('fieldMin') || 0,
      fieldMax: this.getConfigValue('fieldMax') || 0
    }
  },
  watch: {
    id: function() {
      const path = this.getConfigValue('field')
      this.fieldMin = this.getConfigValue('fieldMin')
      this.fieldMax = this.getConfigValue('fieldMax')
      this.objectFields = this.$clientStore.getDataFields(
        this.$store.state.profiles.sourceIdById[this.id]
      )

      if (path) this.changeField({ path })
      else {
        this.field = ''
        this.changeField({ path })
      }
    }
  },
  methods: {
    getConfigValue(field) {
      return this.$store.state.map.styleConfig[this.id].fillExtrusion[field]
    },
    toggleFillExtrusion(value) {
      this.layerStyle.fillExtrusion.enabled = value

      this.controllers.style.toggleFillExtrusion(this.id)
    },
    changeProperty(property, value) {
      if (value) this.layerStyle.fillExtrusion.paint[property] = value

      if (!this.field) { this.controllers.style.changeFillExtrusionProperty(this.id, property) } else this.applyRange()
    },
    async changeField({ path }) {
      if (path === this.field) {
        this.field = ''
        this.fieldMin = 0
        this.fieldMax = 0
        this.changeProperty('fill-extrusion-height')
        this.$store.commit('SET_FILL_EXTRUSION_STYLE', {
          id: this.id,
          config: {
            field: this.field,
            fieldMax: this.fieldMax,
            fieldMin: this.fieldMin,
            enabled: this.getConfigValue('enabled')
          }
        })
      } else {
        this.field = path
        await this.controllers.layers.updateLayerData(this.id, [this.field])
        this.setMinMaxValues()
        this.$store.commit('SET_FILL_EXTRUSION_STYLE', {
          id: this.id,
          config: {
            field: this.field,
            fieldMax: this.fieldMax,
            fieldMin: this.fieldMin,
            enabled: this.getConfigValue('enabled')
          }
        })
        this.applyRange()
      }
    },
    setMinMaxValues() {
      const field = this.field
      const attributes = this.$clientStore.getDataFields(
        this.$store.state.profiles.sourceIdById[this.id]
      )
      const attribute = attributes.find(f => f.title === field)
      if (!attribute) return
      const { min, max } = attribute
      this.fieldMin = min
      this.fieldMax = max
    },
    applyRange() {
      this.controllers.style.changeFillExtrusionRange(
        this.id,
        'fill-extrusion-height'
      )
    },
    getAttributes
  }
}
</script>

<style lang="scss">
.fill-extrusion-picker {
  &__attributes {
    margin-top: 16px;
  }
}
</style>
