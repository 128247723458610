class ClientStore {
  constructor() {
    this._datasources = {}
  }

  // check and get DS data (all and by id)
  isDataInStore(source_id) {
    return (
      (!!this._datasources[source_id] &&
        !!this._datasources[source_id]._data) ||
      (!!this._datasources[source_id] && !!this._datasources[source_id]._fields)
    )
  }

  getData(source_id) {
    const data = this._datasources[source_id]
      ? this._datasources[source_id]._data
      : []
    if (data) return Object.keys(data).map(key => ({ ...data[key] }))
    else return []
  }

  getDataById(source_id, id) {
    const sourcedata = this._datasources[source_id]
    if (sourcedata && sourcedata._data) return sourcedata._data[id]
  }

  // get/set DS object fields
  isFieldsInStore(source_id) {
    return (
      !!this._datasources[source_id] && !!this._datasources[source_id]._fields
    )
  }

  getDataFields(source_id) {
    if (this._datasources[source_id]) { return this._datasources[source_id]._fields }
    return []
  }

  setDataFields(source_id, fields) {
    this._datasources[source_id] = {
      ...this._datasources[source_id],
      _fields: fields
    }
  }

  // socket update actions (insertData - for setting at first time too)
  insertData(source_id, data) {
    const sourcedata = this._datasources[source_id]
    if (!sourcedata) this._datasources[source_id] = { _data: data }
    else {
      if (!sourcedata._data) sourcedata._data = {}
      for (const id in data) {
        sourcedata._data[id] = {
          ...sourcedata._data[id],
          ...data[id]
        }
      }
    }
  }

  deleteData(source_id, ids) {
    const sourcedata = this._datasources[source_id]
    if (sourcedata && sourcedata._data) { ids.forEach(id => delete sourcedata._data[id]) }
  }

  updateData(source_id, data) {
    const sourcedata = this._datasources[source_id]
    if (sourcedata) {
      Object.keys(data).forEach(id => {
        const datasource = sourcedata._data
        if (!datasource) return
        const initial = datasource[id]
        if (initial) {
          const current = data[id]
          for (const prop in current) {
            initial[prop] = current[prop]
          }
        }
      })
    }
  }

  // logging for debug
  printStoreData() {
    console.log('Client Store State --- ', this._datasources)
  }
}

export default {
  install(Vue) {
    const clientStore = new ClientStore()
    Vue.prototype.$clientStore = clientStore
    Vue.$clientStore = clientStore
  }
}
