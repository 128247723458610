import {
  CommonSelectors,
  isEventAtCoordinates
} from '@/libs/map-draw/lib-utils'

function checkAttributes(state, editor, component) {
  editor.creatingCallback = () => {
    editor.isDrawing = false
    this.changeMode('simple_select', { featureIds: [state.polygon.id] })
  }
  editor.creatingObject = state.polygon
  component.toggleCreateModal(true)
}

export const createDrawPolygonMode = (MapboxDraw, editor, component) => {
  const DrawPolygon = MapboxDraw.modes.draw_polygon

  function finishDrawing(state) {
    const { withAttributes } = editor

    if (withAttributes) {
      checkAttributes.call(this, state, editor, component)
    } else {
      editor.isDrawing = false
      return this.changeMode('simple_select', {
        featureIds: [state.polygon.id]
      })
    }
  }

  DrawPolygon.clickAnywhere = function(state, e) {
    if (
      state.currentVertexPosition > 0 &&
      isEventAtCoordinates(
        e,
        state.polygon.coordinates[0][state.currentVertexPosition - 1]
      )
    ) {
      finishDrawing.call(this, state)
      return
    }

    this.updateUIClasses({ mouse: 'add' })
    state.polygon.updateCoordinate(
      `0.${state.currentVertexPosition}`,
      e.lngLat.lng,
      e.lngLat.lat
    )

    if (state.currentVertexPosition === 0) editor.isDrawing = true
    state.currentVertexPosition++
    state.polygon.updateCoordinate(
      `0.${state.currentVertexPosition}`,
      e.lngLat.lng,
      e.lngLat.lat
    )
  }

  DrawPolygon.clickOnVertex = function(state) {
    finishDrawing.call(this, state)
  }

  DrawPolygon.onKeyUp = function(state, e) {
    if (CommonSelectors.isEscapeKey(e)) {
      this.deleteFeature([state.polygon.id], { silent: true })
      this.changeMode('simple_select')
      editor.isDrawing = false
    } else if (CommonSelectors.isEnterKey(e)) {
      finishDrawing.call(this, state)
    }
  }

  return DrawPolygon
}
