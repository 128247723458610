<template>
  <map-component />
</template>

<script>
import MapComponent from '@/components/map/map'

export default {
  components: {
    MapComponent
  }
}
</script>

<style></style>
