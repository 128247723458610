<template>
  <div class="an-select-modal-report-selection">
    <r-title>
      Аналитика: выбор отчёта
    </r-title>
    <ritm-tree
      :tree-data="treeItems"
      no-animations
      no-sort
      no-icon
      :node-click="handleClick"
    />
  </div>
</template>

<script>
import { treeItems } from '../../configs'

export default {
  components: {
    ritmTree: () => import('@/components/ritm-tree')
  },
  data() {
    return {
      treeItems
    }
  },
  methods: {
    handleClick(data) {
      this.$store.commit('ANALYTICS_SET_FIELD', {
        field: 'modalTab',
        value: 'options'
      })
      this.$store.commit('ANALYTICS_SET_FIELD', {
        field: 'report',
        value: data
      })
      this.$store.commit('ANALYTICS_SET_FIELD', {
        field: 'servicesData',
        value: null
      })
    }
  }
}
</script>

<style lang="scss">
.an-select-modal-report-selection {
  position: absolute;
  top: 24px;
  left: 50%;
  transform: translate(-50%);
  border-radius: var(--border-radius);
  background-color: var(--modal_bg);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.08);
  max-height: 60vh;
  width: 368px;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  overflow: auto;

  > * {
    margin-bottom: 8px !important;
  }
}
</style>
