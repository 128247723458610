export const layersConfig = {
  active_object_LineString: {
    type: 'line',
    layout: {},
    paint: {
      'line-width': 5,
      'line-color': 'rgb(255, 68, 140)'
    }
  },
  active_object_Point: {
    type: 'circle',
    layout: {},
    paint: {
      'circle-radius': {
        base: 1.75,
        stops: [[12, 4], [18, 12]]
      },
      'circle-color': 'rgba(255, 68, 140, 0.1)',
      'circle-pitch-alignment': 'map',
      'circle-stroke-color': 'rgb(255, 68, 140)',
      'circle-stroke-width': 1
    }
  },
  vehicles_clusters: {
    type: 'circle',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'step',
        ['get', 'point_count'],
        'rgba(153,213,148, 1)',
        10,
        'rgba(254,224,139, 1)',
        50,
        'rgba(252,141,89, 1)',
        100,
        'rgba(213,62,79, 1)'
      ],
      'circle-radius': [
        'step',
        ['get', 'point_count'],
        10,
        20,
        14,
        50,
        18
      ]
    }
  },
  vehicles_clusters_count: {
    type: 'symbol',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium'],
      'text-size': 11,
      'text-allow-overlap': true,
      'icon-allow-overlap': true
    }
  },
  vehicles: {
    type: 'symbol',
    filter: ['!', ['has', 'point_count']],
    layout: {
      'icon-image': 'aghk-car-default',
      'icon-pitch-alignment': 'map',
      'icon-rotate': ['get', 'course'],
      'icon-rotation-alignment': 'map',
      'icon-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        0.25,
        14,
        0.5,
        22,
        0.8
      ],
      'icon-ignore-placement': true,
      'text-allow-overlap': true,
      'icon-allow-overlap': true
    },
    paint: {
      'icon-opacity': [
        'match',
        ['to-string', ['get', 'online']],
        'true',
        1,
        0.5
      ]
    }
  },
  passing_statistic_clusters: {
    type: 'circle',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'step',
        ['get', 'point_count'],
        'rgba(153,213,148, 1)',
        10,
        'rgba(254,224,139, 1)',
        50,
        'rgba(252,141,89, 1)',
        100,
        'rgba(213,62,79, 1)'
      ],
      'circle-radius': [
        'step',
        ['get', 'point_count'],
        10,
        20,
        14,
        50,
        18
      ]
    }
  },
  passing_statistic_clusters_count: {
    type: 'symbol',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium'],
      'text-size': 11,
      'text-allow-overlap': true,
      'icon-allow-overlap': true
    }
  },
  passing_statistic: {
    type: 'circle',
    layout: {},
    paint: {
      'circle-radius': {
        base: 1.75,
        stops: [[12, 8], [18, 80]]
      },
      'circle-color': 'rgba(89, 214, 78, 0.1)',
      'circle-pitch-alignment': 'map',
      'circle-stroke-color': 'rgb(89, 214, 78)',
      'circle-stroke-width': 1
    }
  },
  parking_movement_line: {
    type: 'line',
    filter: ['==', '$type', 'LineString'],
    layout: {},
    paint: {
      'line-width': 1.5,
      'line-color': '#3288bd'
    }
  },
  crossings_geozones_line: {
    type: 'line',
    filter: ['==', '$type', 'LineString'],
    layout: {},
    paint: {
      'line-width': 1.5,
      'line-color': '#3288bd'
    }
  },
  parking_movement_point: {
    type: 'circle',
    layout: {},
    filter: ['==', '$type', 'Point'],
    paint: {
      'circle-radius': {
        base: 1.75,
        stops: [[12, 4], [18, 12]]
      },
      'circle-color': [
        'match',
        ['to-string', ['get', 'type']],
        'parking',
        'rgba(254,224,139, 0.1)',
        'rgba(213,62,79, 0.1)'
      ],
      'circle-pitch-alignment': 'map',
      'circle-stroke-color': [
        'match',
        ['to-string', ['get', 'type']],
        'parking',
        'rgb(254,224,139)',
        'rgb(213,62,79)'
      ],
      'circle-stroke-width': 1
    }
  },
  parking_movement_arrows: {
    type: 'symbol',
    layout: {
      'symbol-placement': 'line',
      'text-field': '▶',
      'text-size': ['interpolate', ['linear'], ['zoom'], 12, 10, 22, 12],
      'symbol-spacing': [
        'interpolate',
        ['linear'],
        ['zoom'],
        12,
        32,
        22,
        45
      ],
      'text-keep-upright': false,
      'text-allow-overlap': true,
      'icon-allow-overlap': true
    },
    paint: {
      'text-color': '#3288bd',
      'text-halo-color': '#ffffff',
      'text-halo-width': 0.8
    }
  }
}
