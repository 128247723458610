<template>
  <div class="sc-events-log__list-item type-2">
    <div class="sc-events-log__list-item__block">
      <div class="sc-events-log__list-item__block__icon">
        <r-cblock
          :size="32"
          :color="getColor(event.event_class_id)"
          :border-color="getColor(event.event_class_id)"
        >
          <r-icon
            :name="getIconName(event.event_class_id)"
            :size="18"
            :color="getColor(event.event_class_id)"
          />
        </r-cblock>
      </div>
      <div class="sc-events-log__list-item__block__info">
        <r-title type="subtitle-1">
          {{ event.event_class_id }}
        </r-title>
        <r-text type="caption">
          {{ event.address || '' }}
        </r-text>
        <r-text type="caption">
          {{ getDateTime(event.start_time) }}
        </r-text>
        <r-status
          :width="76"
          :color="colors[getStatus()]"
        >
          {{ $t(getStatus()) }}
        </r-status>
      </div>
      <div
        v-if="descriptionFields.length"
        class="sc-events-log__list-item__block__control"
        @click="showMore = !showMore"
      >
        <r-button
          simple
          type="primary"
        >
          {{ !showMore ? $t('show-more') : $t('hide') }}
        </r-button>
      </div>
    </div>
    <div
      v-if="showMore"
      class="sc-events-log__list-item__block"
    >
      <ul class="sc-events-log__list-item__block__description-list">
        <li
          v-for="(field, i) in descriptionFields"
          :key="field + i"
          class="sc-events-log__list-item__block__description-list-item"
        >
          <r-text
            type="caption"
            style="margin: 0 8px 0 0 !important"
          >
            {{ $t(field) + ':' }}
          </r-text>
          <r-text>{{ event[field] }}</r-text>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { widgetStyleConfig } from '../../../../configs'

const availableFields = [
  'customer',
  'performer',
  'phone_number',
  'closed_line_count'
]
const colors = {
  inactive: '#777',
  finished: '#59D64E',
  actual: '#FF3E3E'
}

export default {
  props: {
    event: {
      type: Object,
      required: true,
      default: () => null
    }
  },
  data() {
    return {
      showMore: false,
      availableFields,
      widgetStyleConfig,
      colors
    }
  },
  computed: {
    locale() {
      return this.$store.state.locale
    },
    descriptionFields() {
      return this.availableFields.filter(e => this.event[e])
    }
  },
  methods: {
    getIconName(id) {
      return (
        this.widgetStyleConfig.events_log.icons[id] ||
        this.widgetStyleConfig.events_log.icons.default
      )
    },
    getColor(id) {
      return (
        this.widgetStyleConfig.events_log.colors[id] ||
        this.widgetStyleConfig.events_log.colors.default
      )
    },
    getDateTime(dateTime) {
      return this.$rDate.format(dateTime, 'DD MMMM, h:mm')
    },
    getStatus() {
      const dateTime = new Date()
      let status = ''
      if (new Date(this.event.end_time) < new Date(dateTime)) {
        status = 'finished'
      } else if (new Date(this.event.start_time) > new Date(dateTime)) {
        status = 'inactive'
      } else {
        status = 'actual'
      }
      return status
    }
  }
}
</script>

<style lang="scss">
.sc-events-log__list-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: 12px;
  &.type-2 {
    .sc-events-log__list-item__block {
      display: flex;
      width: calc(100% - 8px);
      position: relative;
      align-items: center;
      padding-right: 8px;
      &__icon {
        margin-right: 12px;
      }
      &__info {
        max-width: calc(100% - 164px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .r-text.caption-2 {
          margin-bottom: 4px !important;
          &:last-child {
            margin-bottom: 0 !important;
          }
        }
        > div {
          width: 25%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      &__control {
        margin-left: auto;
        display: flex;
        align-items: center;
      }
      &__description-list {
        margin-top: 8px;
        margin-left: 48px;
        padding: 8px;
        background-color: var(--accent_hover);
        border-radius: var(--border-radius);
        width: calc(100% - 76px);
        &-item {
          overflow: hidden;
          display: flex;
          align-items: center;
          margin-bottom: 2px;
          > div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "show-more": "Подробнее",
    "hide": "Скрыть",
    "address": "Адрес",
    "customer": "Заказчик",
    "performer": "Подрядчик",
    "phone_number": "Телефон",
    "closed_line_count": "Кол-во перекрытых полос",
    "actual": "В процессе",
    "finished": "Завершено",
    "inactive": "Неактивно"
  },
  "en": {
    "show-more": "Show more",
    "hide": "Hide",
    "address": "Address",
    "customer": "Customer",
    "performer": "Performer",
    "phone_number": "Phone number",
    "closed_line_count": "Closed line count",
    "actual": "Actual",
    "finished": "Finished",
    "inactive": "Inactive"
  }
}
</i18n>
