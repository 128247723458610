<template>
  <div
    v-if="potVehicleList && potVehicleList.length"
    class="pot-arrivals-list"
  >
    <r-search-input
      v-if="showAll"
      placeholder="Искать в данном списке"
      :filter-text="filterText"
      @change="searchChange"
    />
    <ul
      v-if="initialData && initialData.length"
      class="pot-arrivals-list__items"
    >
      <list-item
        v-for="item in initialData"
        :key="item.id"
        :target-status-name="targetStatusName"
        :item="item"
        :additional-action="activeModule === 'vehicles'"
      />
    </ul>
    <r-title
      v-else
      type="subtitle-1"
      color-type="secondary"
    >
      Нет транспортных средств соответствующих фильтру
    </r-title>
    <div
      v-if="extensionLength !== 'full'"
      class="pot-arrivals-list__toggle"
    >
      <el-pagination
        v-if="hasPagination"
        class="r-pagination"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        layout="prev, pager, next"
        :total="totalObject"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
      <button @click="toggleList">
        {{ showAll ? 'Скрыть' : `Показать все (+${extensionLength})` }}
      </button>
    </div>
  </div>
  <div
    v-else
    class="pot-arrivals-list"
  >
    <r-title
      type="subtitle-1"
      color-type="secondary"
    >
      {{ `Здесь будут появляться ТС, со статусом "${targetStatusName}"` }}
    </r-title>
  </div>
</template>

<script>
import listItem from './list-item'

const statusNameHash = {
  waitingVehicle: 'Ожидает разрешение',
  arrivedVehicle: 'Прибыл на ПОТ',
  arrivedRailvay: 'Прибыл на станцию назначения'
}

export default {
  components: {
    listItem
  },
  props: {
    activeModule: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showAll: false,
      filterText: '',
      statusNameHash,
      currentPage: 1,
      pageSize: 10
    }
  },
  computed: {
    targetStatusName() {
      const hash = this.statusNameHash
      return this.activeModule === 'vehicles'
        ? this.isAdmin
          ? hash.arrivedVehicle
          : hash.waitingVehicle
        : hash.arrivedRailvay
    },
    isAdmin() {
      return !!this.$store.state.pot1.isAdmin
    },
    statusList() {
      return this.$store.state.pot1.statusList || []
    },
    excludeStatusId() {
      return this.statusList.find(e => {
        switch (this.activeModule) {
          case 'vehicles':
            return this.isAdmin
              ? e.name === 'Прибыл на ПОТ'
              : e.name === 'Ожидает разрешение'

          case 'railway':
            return e.name === 'Прибыл на станцию назначения'
        }
      })?.id
    },
    potVehicleList() {
      return (
        this.excludeStatusId
          ? this.$store.state.pot1.potVehicleList?.filter(
            e => e.pot_status_id === this.excludeStatusId
          ) || [] : []
      )
    },
    totalObject() {
      return this.filteredList?.length || 0
    },
    hasPagination() {
      if (!this.showAll) return false
      return this.potVehicleList?.length > 10
    },
    initialData() {
      if (!this.hasPagination) {
        return this.filteredList
      } else {
        const start =
          this.currentPage === 1 ? 0 : (this.currentPage - 1) * this.pageSize
        const end =
          this.currentPage === 1
            ? this.totalObject < this.pageSize
              ? this.totalObject
              : this.currentPage * this.pageSize
            : this.currentPage * this.pageSize > this.totalObject
              ? this.totalObject
              : this.currentPage * this.pageSize

        return this.filteredList?.slice(start, end) || []
      }
    },
    filteredList() {
      const searchFields = this.activeModule === 'vehicles' ? [
        'reg_number',
        'forecast_delivery',
        'driver_full_name',
        'driver_phone'
      ] : [
        'carriage_no'
      ]

      if (this.showAll) {
        const query = this.filterText?.trim()?.toLowerCase()
        if (!query) return this.potVehicleList || []

        return this.potVehicleList.filter(e => {
          const filtered = searchFields
            .map(f => e[f])
            .join('')
            ?.trim()
            ?.toLowerCase()
          return filtered.includes(query)
        })
      } else {
        return this.potVehicleList.slice(0, 3)
      }
    },
    extensionLength() {
      const fullLength = this.potVehicleList.length

      if (fullLength <= 3) {
        return 'full'
      } else {
        return this.potVehicleList.length - 3
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    searchChange(e) {
      this.filterText = e
    },
    toggleList() {
      this.showAll = !this.showAll
      this.filterText = ''
    }
  }
}
</script>

<style lang="scss">
.pot-arrivals-list {
  display: grid;
  grid-gap: 1rem;
  padding: 1rem;
  justify-content: stretch;
  align-items: center;

  .r-title {
    justify-self: center;
  }

  &__items {
    display: grid;
    grid-gap: 0.5rem;
    max-height: 33vh;
    overflow: auto;
  }

  &__toggle {
    display: grid;
    justify-content: center;
    grid-gap: 0.5rem;

    button {
      cursor: pointer;
      background: none;
      border: none;
      color: var(--accent_primary);
    }
  }
}
</style>
