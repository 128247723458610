<template>
  <div :class="[`si-center__datetime ${$mq}`]">
    <div class="si-center__date">
      <r-text>{{ currentDate }}</r-text>
    </div>
    <div class="si-center__time">
      <r-text :size="24">
        {{ currentTime }}
      </r-text>
    </div>
  </div>
</template>

<script>
import { getDatetime } from '../../../configs/'
import { capitalizeFirstLetter } from '@/utils/'

export default {
  props: {
    timeZone: {
      type: String,
      default: '+3'
    }
  },
  data() {
    return {
      refreshData: null,
      date: '',
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  },
  computed: {
    currentDate() {
      return this.date
    },
    currentTime() {
      return `${this.hours}:${this.minutes}:${this.seconds}`
    },
    locale() {
      return this.$store.state.locale
    }
  },
  created() {
    this.setDatetime()

    this.refreshData = setInterval(this.setDatetime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.refreshData)
  },
  methods: {
    setDatetime() {
      const dateTime = getDatetime(this.timeZone)
      const dateOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'short'
      }

      this.date = capitalizeFirstLetter(
        dateTime.toLocaleString(this.locale, dateOptions).toLowerCase()
      )
      this.hours = ('0' + dateTime.getHours()).slice(-2)
      this.minutes = ('0' + dateTime.getMinutes()).slice(-2)
      this.seconds = ('0' + dateTime.getSeconds()).slice(-2)
    }
  }
}
</script>

<style lang="scss">
.si-center__datetime {
  padding: 8px 0;
}
</style>
