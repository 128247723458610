<template>
  <r-button
    icon="focus-zone"
    @click="clickHandler"
  >
    <div v-if="!justIcon">
      Найти на карте
    </div>
  </r-button>
</template>

<script>
export default {
  props: {
    withBg: {
      type: Boolean,
      default: false
    },
    justIcon: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickHandler() {
      this.$emit('fly')
    }
  }
}
</script>

<style lang="scss">
.r-fly-button {
  width: 100%;
  display: grid;
  grid-gap: 0.5rem;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  cursor: pointer;
  height: 36px;
  line-height: 36px;
  border-radius: var(--border-radius);

  &--background {
    background-color: var(--button_secondary_bg) !important;
    color: var(--white_white) !important;
  }
  &:hover {
    background-color: var(--accent_selected) !important;
  }
}
</style>
