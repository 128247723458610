import { createSupplementaryPoints } from '@/libs/map-draw/lib-utils'
import { undraggableTypes } from '@/libs/map-draw/helpers'

export const createDirectSelectMode = (MapboxDraw, editor, component) => {
  const DirectSelect = {}

  DirectSelect.startDragging = function(state, e) {
    this.map.dragPan.disable()
    const { meta } = e.featureTarget.properties
    state.canDragMove =
      (meta === 'vertex' && !editor.disableVertexDragging) ||
      undraggableTypes.indexOf(editor.geom_type) === -1
    state.dragMoveLocation = e.lngLat
    state.draggingPointIndex = Number(e.featureTarget.properties.coord_path)
  }

  DirectSelect.toDisplayFeatures = function(state, geojson, push) {
    if (state.featureId === geojson.properties.id) {
      geojson.properties.active = 'true'
      push(geojson)
      let supplementaryPoints

      supplementaryPoints = createSupplementaryPoints(geojson, {
        map: this.map,
        midpoints: true,
        selectedPaths: state.selectedCoordPaths
      })

      if (editor.geom_type === 'links') {
        supplementaryPoints = supplementaryPoints.slice(
          1,
          supplementaryPoints.length - 1
        )
      }
      if (editor.geom_type === 'connectors') supplementaryPoints = []
      supplementaryPoints.forEach(push)
    } else {
      geojson.properties.active = 'false'
      push(geojson)
    }
    this.fireActionable(state)
  }

  return { ...MapboxDraw.modes.direct_select, ...DirectSelect }
}
