import {
  CommonSelectors,
  isEventAtCoordinates
} from '@/libs/map-draw/lib-utils'

export const createEEDrawPolygonsMode = (MapboxDraw, editor, component) => {
  const DrawPolygon = MapboxDraw.modes.draw_polygon

  function finishDrawing(state) {
    editor.creatingCallback = () => {
      editor.isDrawing = false
      this.changeMode('simple_select', { featureIds: [state.polygon.id] })
    }

    editor.activeObject = state.polygon
    component.$store.commit('SET_EE_STATE_PROP', {
      name: 'activeCard',
      value: true
    })
    component.$store.commit('SET_EE_STATE_PROP', {
      name: 'overlay',
      value: true
    })
  }

  DrawPolygon.clickAnywhere = function(state, e) {
    if (
      state.currentVertexPosition > 0 &&
      isEventAtCoordinates(
        e,
        state.polygon.coordinates[0][state.currentVertexPosition - 1]
      )
    ) {
      finishDrawing.call(this, state)
      return
    }

    this.updateUIClasses({ mouse: 'add' })
    state.polygon.updateCoordinate(
      `0.${state.currentVertexPosition}`,
      e.lngLat.lng,
      e.lngLat.lat
    )

    if (state.currentVertexPosition === 0) editor.isDrawing = true
    state.currentVertexPosition++
    state.polygon.updateCoordinate(
      `0.${state.currentVertexPosition}`,
      e.lngLat.lng,
      e.lngLat.lat
    )
  }

  DrawPolygon.clickOnVertex = function(state) {
    finishDrawing.call(this, state)
  }

  DrawPolygon.onKeyUp = function(state, e) {
    if (CommonSelectors.isEscapeKey(e)) {
      this.deleteFeature([state.polygon.id], { silent: true })
      this.changeMode('simple_select')
      editor.isDrawing = false
    } else if (CommonSelectors.isEnterKey(e)) {
      finishDrawing.call(this, state)
    }
  }

  return DrawPolygon
}
