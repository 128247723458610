<template>
  <div class="mt-track">
    <div class="mt-track__filters">
      <div class="mt-track__filter">
        <r-text type="caption">
          Начало
        </r-text>
        <el-date-picker
          v-model="from"
          class="r-date-picker"
          type="datetime"
          size="mini"
          name="datetime"
          format="dd.MM.yyyy HH:mm"
          :clearable="false"
          :picker-options="{ firstDayOfWeek: 1 }"
          @change="loadTrack"
        />
      </div>
      <div class="mt-track__filter">
        <r-text type="caption">
          Окончание
        </r-text>
        <el-date-picker
          v-model="to"
          class="r-date-picker"
          type="datetime"
          size="mini"
          name="datetime"
          format="dd.MM.yyyy HH:mm"
          :clearable="false"
          :picker-options="{ firstDayOfWeek: 1 }"
          @change="loadTrack"
        />
      </div>
      <div class="mt-track__filter">
        <r-button
          type="primary"
          :loading="loadingTrack"
          @click="toggleTrack"
        >
          {{ trackButtonText }}
        </r-button>
      </div>
    </div>
    <div
      v-if="showTrack && chartData"
      class="mt-track__chart"
    >
      <charts
        no-control
        :data="chartData"
      />
    </div>
  </div>
</template>

<script>
import charts from '@/components/charts/charts'
import { notifyFactory } from '@/utils'

export default {
  components: {
    charts
  },
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      from: Date.now() - 3600 * 6 * 1000,
      to: Date.now(),
      loadingTrack: false,
      showTrack: false,
      chartData: null
    }
  },
  computed: {
    activeCardId() {
      return this.$store.state.situationCenter.objectCard?.data?.properties?.id
    },
    trackButtonText() {
      if (this.loadingTrack) {
        return 'Загрузка трека...'
      }

      if (this.showTrack) {
        return 'Скрыть трек'
      }

      return 'Показать трек'
    }
  },
  watch: {
    cardId() {
      this.removeTrack()
    }
  },
  methods: {
    toggleTrack() {
      if (this.showTrack) {
        this.removeTrack()
      } else {
        this.loadTrack()
      }
    },
    async loadTrack() {
      try {
        this.showTrack = false
        this.loadingTrack = true

        const from = this.$rDate.format(this.from, 'DD.MM.YYYY HH:mm:ss')
        const to = this.$rDate.format(this.to, 'DD.MM.YYYY HH:mm:ss')
        const url = `track?object_field=uid&id=${
          this.activeCardId
        }&time_field=gps_time&from=${from}&to=${to}&limit=9999999`

        const responseData = await this.$store.dispatch('GET_REQUEST', { url })

        if (responseData.data) {
          const data = responseData.data.reverse()

          const geojson = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: data
                    .filter(p => !!p.to_point)
                    .map(p => p.to_point.coordinates)
                }
              }
            ]
          }

          this.$store.commit('SET_SI_CENTER_FIELD', {
            field: 'cardTrack',
            value: geojson
          })

          this.showTrack = true
        } else {
          const title = 'Данные по треку отсутсвуют'
          const message =
            'Информация о перемещении ТС за указанный период отсутствует'
          this.$notify(notifyFactory('info', title, message))
        }

        this.loadingTrack = false
      } catch (error) {
        console.log(error)

        this.loadingTrack = false
      }
    },
    removeTrack() {
      this.showTrack = false
      this.chartData = null

      this.$store.commit('SET_SI_CENTER_FIELD', {
        field: 'cardTrack',
        value: null
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.mt-track {
  &__filters {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  &__filter {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    .r-text {
      margin-bottom: 4px;
    }
  }

  &__actions {
    margin-top: 16px;
  }
}
</style>
