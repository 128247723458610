import mapboxgl from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'

import { mapToken } from '@/constants/tokens'
import { MapConfigEvents, getMapConfigs } from '@/utils'
import { ModesController } from '@/libs/map-draw/modes/modes-controller'
import { ptEditorStyles } from '@/libs/map-draw/config'
import { BaseController, EditorController, ModelController, RouterController } from '../controllers'

// load icons
const MAP_ICON_SIZE = 64
const icons = [
  {
    name: 'stop-point',
    imageUrl: require('@/assets/images/public-transport/stop-point.svg')
  },
  {
    name: 'fixed-stop-point',
    imageUrl: require('@/assets/images/public-transport/fixed-stop-point.svg')
  }
]
export const loadIcons = component => {
  return Promise.all(
    icons.map(
      ({ name, imageUrl }) =>
        new Promise(resolve => {
          const image = new Image(MAP_ICON_SIZE, MAP_ICON_SIZE)
          image.crossOrigin = 'Anonymous'
          image.style.backgroundPosition = '50%, 50%'
          image.style.backgroundSize = '100%'
          image.addEventListener('load', () => {
            component.mapgl.addImage(name, image)
            resolve()
          })
          image.src = imageUrl
        })
    )
  ).then(() => console.log('public transport icons loaded!'))
}

export const initMap = component => {
  // map creating
  const { center, zoom, baselayer } = getMapConfigs(component, 'traffic_counts')

  mapboxgl.accessToken = mapToken

  component.mapgl = new mapboxgl.Map({
    container: 'pt-map',
    preserveDrawingBuffer: true,
    style: baselayer,
    center,
    zoom
  })

  const { controllers, mapgl } = component

  // view handlers
  mapgl.on('pitch', e => {
    component.is3d = e.target.getPitch() !== 0
  })
  mapgl.on('rotate', e => {
    component.mapBearing = e.target.getBearing()
  })

  // controllers
  controllers.events = new MapConfigEvents(component, 'traffic_counts')
  controllers.base = new BaseController(component)
  controllers.model = new ModelController(component)
  controllers.router = new RouterController(component)

  // map init
  mapgl.on('load', async() => {
    await loadIcons(component)
    // await loadPtIcons(component)
    await controllers.model.loadModel()
    controllers.events.addEventHandler()
    controllers.base.addBaseLayers()
    if (component.$route.name === 'pt-router') {
      controllers.router.initRouter()
    }

    // map draw init
    const mc = new ModesController(component, MapboxDraw, 'editorState')
    component.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        line_string: true,
        point: true,
        trash: true
      },
      styles: ptEditorStyles,
      userProperties: true,
      modes: Object.assign(MapboxDraw.modes, {
        simple_select: mc.createPTSimpleSelectMode(),
        direct_select: mc.createPTDirectSelectMode(),
        draw_stop_points: mc.createDrawStopPointsMode(),
        draw_lines: mc.createDrawRoutesMode()
      })
    })

    controllers.editor = new EditorController(component)

    // handler for popup
    mapgl.on('click', e => {
      const { ids } = component.$store.state.publicTransport.model
      const { x, y } = e.point
      const bbox = [
        [x - 5, y - 5],
        [x + 5, y + 5]
      ]
      const features = component.mapgl.queryRenderedFeatures(bbox, {
        layers: [ids.lines, `${ids.stop_points}_points`]
      })

      if (!features.length) {
        component.popupSettings.display = 'none'
        component.popupSettings.values = []
      }
    })
  })

  window.addEventListener('beforeunload', component.saveMapParams)
}
