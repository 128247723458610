<template>
  <div class="map-editor-mode">
    <r-text
      type="caption"
      style="margin-bottom: 4px;"
    >
      Выбор режима
    </r-text>
    <div class="map-editor-mode__buttons">
      <r-radio-group
        :button-list="modes"
        :active="mode"
        @change="toggleEditorMode"
      />
    </div>
  </div>
</template>

<script>
const modes = [
  { id: 'create', label: 'Создание' },
  { id: 'edit', label: 'Редактирование' }
]

export default {
  props: {
    state: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modes
    }
  },
  computed: {
    mode() {
      return this.state.mode
    }
  },
  methods: {
    toggleEditorMode(mode) {
      this.$emit('toggle-editor-mode', mode)
    }
  }
}
</script>

<style lang="scss"></style>
