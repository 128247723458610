<template>
  <div class="map">
    <div class="map-layout">
      <map-panels
        ref="mapPanels"
        :map="mapgl"
        :draw="draw"
        :style-config="styleConfig"
        :state="editorState"
        :controllers="controllers"
        :map-mode="mapMode"
      />

      <div
        id="map-container"
        class="map-container"
      >
        <div class="map-actions">
          <r-map-tools
            :tools="['baselayers', '3d', 'reset', 'screenshot']"
            :map="mapgl"
            :map-bearing="mapBearing"
            :is3d="is3d"
            :baselayer-id="baselayerId"
            @change-prop="changeProp"
            @toggle-base-layer="toggleBaselayer"
          />

          <map-legend
            :map="mapgl"
            :map-mode="mapMode"
            :objects-counts="objectsCounts"
            :features-in-bbox-counts="featuresInBboxCounts"
            :is-update="isUpdate"
          />
        </div>

        <div
          v-if="hasModule('Layers Top Panel')"
          class="map-info-layers"
        >
          <r-info-layers
            v-if="mapReady"
            ref="infoLayers"
            :mapgl="mapgl"
            module="map"
          />
        </div>

        <r-map-loader v-if="loadingLayers.length > 0" />
        <map-popup :settings="popupSettings" />
        <map-popup-list
          :settings="popupListSettings"
          :style-config="styleConfig"
        />
      </div>

      <r-info-panel v-if="activeCards.length">
        <active-cards module="map" />
      </r-info-panel>
    </div>

    <create-modal
      v-if="createModal"
      :visible="createModal"
      :state="editorState"
      :draw="draw"
      @toggle-create-modal="toggleCreateModal"
    />
    <delete-modal
      :visible="deleteModal"
      :state="editorState"
      :draw="draw"
      @toggle-delete-modal="toggleDeleteModal"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import extent from 'turf-extent'

import constants from '@/constants/url'
import MapPanels from './components/map-panels/map-panels'
import MapPopup from './components/map-popup/map-popup'
import MapPopupList from './components/map-popup-list/map-popup-list'
import MapLegend from './components/map-legend/map-legend'
import ActiveCards from '@/components/object-info/active-cards'
import DeleteModal from './components/map-editor/delete-modal'
import CreateModal from './components/map-editor/create-modal'

import { initMap, initControllers } from './core'
import { initialEditorState } from './configs'
import { toggleActiveObject } from './helpers'

import directionIconPath from '@/assets/images/map/direction-arrow.png'
import mapboxStyles from '@/constants/mapbox_styles'

export default {
  components: {
    MapPanels,
    MapPopup,
    MapPopupList,
    MapLegend,
    ActiveCards,
    DeleteModal,
    CreateModal
  },
  data() {
    return {
      mapgl: {},
      draw: {},
      controllers: {},
      mapMode: 'vizualization',
      mapBearing: 0,
      is3d: false,
      baselayerId: this.$store.state.user_config.baselayerId || 5,
      popupSettings: {
        display: 'none',
        top: 0,
        left: 0,
        values: []
      },
      popupListSettings: {
        display: 'none',
        top: 0,
        left: 0,
        values: []
      },
      requestedFields: this.$store.state.map.requestedFields || {},
      objectFieldsConfigs: this.$store.state.map.objectFieldsConfigs || {},
      styleConfig: this.$store.state.map.styleConfig || {},
      objectFields: {},
      objectsCounts: {},
      featuresInBboxCounts: {},
      editorState: cloneDeep(initialEditorState),
      directionIconPath,
      createModal: false,
      deleteModal: false,
      isUpdate: false,
      baseURL: constants.URL,
      downloadURL: 'download_share_resource',
      mapReady: false
    }
  },
  computed: {
    loadingLayers() {
      return this.$store.state.map.loadingLayers
    },
    activeCards() {
      return this.$store.state.commonData.activeCards.map || []
    },
    activeCard() {
      return this.$store.state.commonData.activeCard.map
    },
    activeCardFeature() {
      return this.$store.state.map.activeCardFeature
    }
  },
  watch: {
    '$store.state.map.historyFeatures': function(value) {
      this.controllers.history.updateHistoryLayer(value)
    },
    '$store.state.map.flyToGeom': function(geom) {
      if (!geom) return
      const bounds = extent(geom)

      this.mapgl.fitBounds(bounds, {
        padding: 20
      })
      this.$store.commit('SET_FLY_TO_GEOM', null)
    },
    activeCards() {
      this.$nextTick(() => this.mapgl.resize())
    },
    activeCard: function(val) {
      if (!val) this.$store.commit('SET_MAP_ACTIVE_CARD_FEATURE', null)
    },
    activeCardFeature(val) {
      toggleActiveObject(this, val)
    }
  },
  async mounted() {
    await initMap(this)
    initControllers(this)

    this.mapgl.once('load', () => {
      this.mapReady = true
    })
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.saveMapParams)
    this.saveMapParams()
    const { activeLayers } = this.$store.state.map

    this.$store.commit('SET_INITIAL_ACTIVE_LAYERS', [...activeLayers])
    this.$store.commit('CLEAR_ACTIVE_LAYERS')
  },
  methods: {
    hasModule(name) {
      return this.$store.getters.hasModule(name)
    },
    saveMapParams() {
      const zoom = this.mapgl.getZoom()
      const center = this.mapgl.getCenter()

      localStorage.setItem('mapGISCenter', JSON.stringify(center))
      localStorage.setItem('mapGISZoom', JSON.stringify(zoom))
    },
    changeProp({ field, value }) {
      this[field] = value
    },
    async toggleMode(mode) {
      this.mapMode = mode

      await this.$refs.infoLayers.rerenderComponent()
    },
    toggleBaselayer({ id, name }) {
      const style = mapboxStyles[name]
      this.$store.commit('SET_BASELAYER_ID', id)
      this.baselayerId = id

      const activeLayers = [...this.$store.state.map.activeLayers]
      this.mapgl.setStyle(style)
      this.$store.commit('CLEAR_ACTIVE_LAYERS')
      this.mapgl.once('style.load', async() => {
        await this.$refs.infoLayers.rerenderComponent()

        activeLayers.forEach(id => {
          this.mapgl.off('moveend', this.controllers.layers.handlers[id])
          this.controllers.layers.toggleLayer({ id })
        })
      })
    },
    getObjectsLimit() {
      return 10000
    },
    // map-editor - delete objects helpers
    toggleDeleteModal(value) {
      this.deleteModal = value
    },
    // map-editor - create object helpers
    toggleCreateModal(value) {
      this.createModal = value
    }
  }
}
</script>

<style lang="scss" src="./map.scss"></style>

<i18n>
{
  "ru": {
    "map": "Карта",
    "vizualization": "Визуализация",
    "editor": "Редактор геометрии"
  },
  "en": {
    "map": "Map",
    "vizualization": "Vizualization",
    "editor": "Geometry Editor"
  }
}
</i18n>
