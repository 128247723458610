<template>
  <div
    v-if="state.history.length"
    class="map-editor-history"
  >
    <div class="map-editor-history__header">
      <div
        class="map-editor-history__title"
        @click="editorHistoryShow = !editorHistoryShow"
      >
        История изменений
        <span
          :class="
            editorHistoryShow ? 'el-icon-caret-bottom' : 'el-icon-caret-right'
          "
        />
      </div>
      <div
        v-if="editorHistoryShow"
        class="map-editor-history__buttons"
      >
        <el-tooltip
          :open-delay="1000"
          effect="dark"
          content="Назад"
          placement="top"
        >
          <r-button
            :disabled="state.historyIndex === 0"
            icon="chevron-left"
            type="primary"
            @click="changeHistoryStep('dec')"
          />
        </el-tooltip>
        <el-tooltip
          :open-delay="1000"
          effect="dark"
          content="Вперед"
          placement="top"
        >
          <r-button
            :disabled="state.historyIndex === state.history.length - 1"
            icon="chevron-right"
            type="primary"
            @click="changeHistoryStep('inc')"
          />
        </el-tooltip>
        <el-tooltip
          :open-delay="1000"
          effect="dark"
          content="Очистить изменения"
          placement="top"
        >
          <r-delete-button
            simple
            mini
            @delete="clearEditingState"
          />
        </el-tooltip>
      </div>
    </div>
    <div
      v-if="editorHistoryShow"
      class="map-editor-history__container"
    >
      <div
        v-for="(item, i) in state.history"
        :key="item.id"
        :class="[
          'map-editor-history__item',
          i === state.historyIndex ? 'active' : ''
        ]"
        @click="changeHistoryStep(i)"
      >
        {{ i + 1 }} - {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    state: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      editorHistoryShow: true
    }
  },
  methods: {
    changeHistoryStep(i) {
      this.$emit('change-history-step', i)
    },
    clearEditingState() {
      this.$emit('clear-editing-state')
    }
  }
}
</script>

<style lang="scss">
.map-editor-history {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
  &__title {
    line-height: 28px;
    cursor: pointer;
    font-size: 12px;
    color: var(--text_secondary) !important;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;

    .r-button.icon-button.is-circle {
      padding: 4px !important;
    }
  }

  &__container {
    padding: 5px;
    border-radius: var(--border-radius);
    border: 1px solid transparent;
    border-color: var(--dividers_low_contrast) !important;
    background-color: var(--bg_panel_primary) !important;
  }
  &__item {
    font-size: 11px;
    cursor: pointer;
    color: var(--text_secondary) !important;

    &.active {
      font-weight: bold;
      font-size: 12px;
    }
    &:not(:first-child) {
      margin-top: 5px;
    }
  }
}
</style>
