<template>
  <div class="navbar-dropdown-item">
    <r-icon
      :name="icon"
      :size="24"
    />
    <r-text>
      {{ title }}
    </r-text>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'alert'
    },
    title: {
      type: String,
      default: 'title'
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar-dropdown-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .r-icon {
    margin-right: 8px;
    width: auto;
  }
}
</style>
