export const filters = () => [
  {
    id: 'created_at',
    initType: 'date-filter',
    type: 'day-filter',
    title: 'Дата создания',
    prop: { interval: { from: '', to: '' } },
    active: false
  },
  {
    id: 'event_class_id',
    type: 'checkbox-filter',
    title: 'Тип',
    prop: [],
    active: false
  },
  {
    id: 'closed_line_count',
    type: 'radio-filter',
    title: 'Перекрытие',
    prop: [
      { id: 'all', label: 'Все' },
      { id: 'yes', label: 'Есть' },
      { id: 'no', label: 'Нет' }
    ],
    active: false
  },
  {
    id: 'requested',
    type: 'radio-filter',
    title: 'Заявка',
    prop: [
      { id: 'all', label: 'Все' },
      { id: 'yes', label: 'Да' },
      { id: 'no', label: 'Нет' }
    ],
    active: false
  },
  {
    id: 'relevance',
    type: 'radio-filter',
    title: 'Актуальность',
    prop: [
      { value: true, label: 'Все', id: 'all' },
      { value: false, label: 'В процессе', id: 'ongoing' },
      { value: false, label: 'Завершено', id: 'completed' },
      { value: false, label: 'Предстоящее', id: 'upcoming' }
    ],
    active: false
  }
]
