<template>
  <sc-card
    v-if="activeTab !== 'chart'"
    v-loading="options.request.plug ? false : !initialRequest"
    :title="options.layer_id"
    :footer="options.type !== 'list'"
    :size="options.size"
    controls
  >
    <div
      :class="[`sc-basic-widget ${options.type} ${options.size} ${$mq}`]"
      :style="getWidth()"
    >
      <default-list
        v-if="options.type === 'default'"
        :item-list="widgetData.items"
        :colors="colors"
        :icons="icons"
      />
      <simple-list
        v-if="options.type === 'simple'"
        :item-list="widgetData.items"
        :colors="colors"
      />
      <list
        v-if="options.type === 'list'"
        :item-list="widgetData.items"
        :colors="colors"
        :icons="icons"
        :total="widgetData.total || 0"
        :no-icons="options.noIcons"
      />
      <div
        v-if="options.event_layer"
        class="sc-widget__events-control"
      >
        <router-link :to="eventEditorLink">
          <r-button
            simple
            type="primary"
          >
            {{ $t('sc:to-event-editor') }}
          </r-button>
        </router-link>
      </div>
    </div>
    <category-footer
      slot="footer"
      :open-modal-window="openModalWindow"
      :options="options"
      :widget-data="widgetData"
    />
    <card-toggler
      v-if="options.hasChart"
      slot="controls"
      :active="activeTab"
      :toggle-chart="toggleChart"
    />
  </sc-card>
  <sc-card
    v-else
    :title="options.layer_id"
    controls
  >
    <div
      :class="[`sc-basic-widget ${options.type} sc-chart`]"
      :style="getWidth()"
    >
      <sc-chart
        :chart-data="chartData"
        :colors="chartColors"
        :title="$t(options.layer_id)"
        :type="chartType"
        :change-chart-type="changeChartType"
      />
    </div>
    <card-toggler
      v-if="options.hasChart"
      slot="controls"
      :active="activeTab"
      :toggle-chart="toggleChart"
    />
  </sc-card>
</template>

<script>
import scCard from '../../layout/card'
import defaultList from './renderer/default-list'
import simpleList from './renderer/simple-list'
import categoryFooter from './renderer/footer'
import scChart from '../../modules/charts/charts'
import cardToggler from '../card-toggler/card-toggler'
import list from './renderer/list'

import {
  getPlugData,
  getPlugDataStyleType,
  dataMapping,
  getWidgetStyleConfig
} from '../../../configs'

export default {
  components: {
    scCard,
    defaultList,
    simpleList,
    list,
    categoryFooter,
    scChart,
    cardToggler
  },
  props: {
    options: {
      type: Object,
      required: true,
      default: () => ({
        layer_id: '',
        type: 'default',
        size: 'default',
        hasChart: false,
        hasModal: false
      })
    }
  },
  data() {
    return {
      chartType: 'type-1',
      activeTab: 'info'
    }
  },
  computed: {
    layerId() {
      return this.options.event_layer
        ? this.options.request.url
        : this.options.layer_id
    },
    widgetData() {
      if (this.options.request.plug) {
        const { data, layer_id, type } = getPlugData(this.options, 'category')
        return dataMapping(data, layer_id, type)
      }
      const data =
        this.$store.state.situationCenter.layersData.widget[this.layerId]

      return data
        ? dataMapping(
          data,
          this.options.event_layer ? 'event_stat' : this.options.layer_id,
          'basic_widget'
        )
        : {}
    },
    chartData() {
      return this.widgetData.items.map(e => ({
        value: e.value,
        name: this.$t(e.type)
      }))
    },
    colors() {
      const id = !this.options.request.plug
        ? this.options.layer_id
        : getPlugDataStyleType(this.options, 'category')
      const layerStyle = getWidgetStyleConfig(id)

      return layerStyle ? layerStyle.colors : {}
    },
    icons() {
      const id = !this.options.request.plug
        ? this.options.layer_id
        : getPlugDataStyleType(this.options, 'category')
      const layerStyle = getWidgetStyleConfig(id)

      return layerStyle ? layerStyle.icons : {}
    },
    chartColors() {
      return Object.keys(this.colors).map(e => this.colors[e])
    },
    eventEditorLink() {
      return this.$store.state.situationCenter.events.event_editor_link
    },
    initialRequest() {
      return (
        this.$store.state.situationCenter.completedRequests.indexOf(
          this.layerId
        ) !== -1
      )
    }
  },
  methods: {
    getWidth() {
      const styles = {}

      switch (this.options.size) {
        case 'small':
          styles.width = '264px'
          break
        case 'large':
          styles.width = '580px'
          break
        default:
          styles.width = '440px'
          break
      }

      return styles
    },
    changeChartType(e) {
      this.chartType = e
    },
    openModalWindow() {
      if (!this.options.hasModal) return
      this.$store.commit('SET_SI_CENTER_MODAL_WINDOW', {
        layer_id: this.options.layer_id
      })
      this.$store.commit('OPEN_MODAL_WINDOW', 'sc-modal-window')
    },
    toggleChart(e) {
      this.activeTab = e
    }
  }
}
</script>

<style lang="scss">
.sc-basic-widget {
  position: relative;
  height: 100%;
  display: flex;
  &.default {
    margin-bottom: -20px;
    &.sc-chart {
      margin-bottom: 0;
    }
  }
  &.simple {
    max-width: 300px;
  }
  .sc-widget__events-control {
    position: absolute;
    bottom: 8px;
    right: 16px;
  }
  &.list {
    align-self: flex-start;
    height: auto;
    max-height: 216px;
    overflow: hidden;
    width: 320px !important;

    &.mid {
      width: 620px !important;
    }
  }
}
</style>
