<template>
  <div class="m-event-attributes">
    <div class="m-event-attributes__card">
      <r-panel-header title="Параметры события" />
      <div class="m-event-attributes__controls">
        <r-button
          type="success"
          :disabled="isSaveDisabled"
          @click="save"
        >
          {{ activeEventId === 'new' ? 'Создать событие' : 'Обновить событие' }}
        </r-button>
      </div>
      <div class="m-event-attributes__container">
        <ul class="m-event-attributes__list">
          <li
            v-for="config in attributes"
            :key="config.prop"
            class="m-event-attributes__item"
          >
            <component
              :is="`card-${config.type}`"
              :feature="feature"
              :config="config"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {
  clearAllHelpers,
  removeStopPointsHelpers,
  clearAllRouteGeometry
} from '@/libs/map-draw/helpers'
import { getPrimaryAttributes } from '@/components/event-editor/components/active-card/config'
import CardDatetime from '@/components/event-editor/components/active-card/controls/card-datetime'
import CardTime from '@/components/event-editor/components/active-card/controls/card-time'
import CardInput from '@/components/event-editor/components/active-card/controls/card-input'
import CardInputNumber from '@/components/event-editor/components/active-card/controls/card-input-number'
import CardSelect from '@/components/event-editor/components/active-card/controls/card-select'

export default {
  components: {
    CardDatetime,
    CardTime,
    CardInput,
    CardInputNumber,
    CardSelect
  },
  data() {
    return {
      draw: this.$cControllers.editor.parent.draw,
      attributes: getPrimaryAttributes(this.$store.state.modelling.model.ids),
      feature: {
        id: null,
        event_class_id: null,
        start_time: null,
        end_time: null,
        closed_line_count: 0
      },
      requiredProps: [
        'closed_line_count',
        'event_class_id',
        'start_time',
        'end_time'
      ]
    }
  },
  computed: {
    activeEventId() {
      return this.$store.state.modelling.activeEventId
    },
    state() {
      return this.$store.state.modelling.editorState
    },
    ids() {
      return this.$store.state.modelling.model.ids
    },
    isSaveDisabled() {
      return this.requiredProps.some(p => !this.feature[p])
    }
  },
  watch: {
    activeEventId() {
      this.setInitialValues()
    }
  },
  created() {
    this.setInitialValues()

    window.addEventListener('keydown', this.escapeHandler)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.escapeHandler)
  },
  methods: {
    closePanel() {
      this.$cControllers.drawContext.deselect(this.activeEventId)

      this.$store.commit('SET_CALC_FIELD', {
        field: 'activeEventId',
        value: null
      })
      this.$store.commit('SET_CALC_FIELD', {
        field: 'overlay',
        value: false
      })
    },
    cancel() {
      const { activeObject, mode } = this.state

      if (mode === 'create') {
        this.draw.delete(activeObject.id)
        this.state.isDrawing = false
      }
      if (mode === 'edit') this.draw.changeMode('simple_select')

      clearAllHelpers(this.mapgl)
      removeStopPointsHelpers(this.mapgl)
      clearAllRouteGeometry(this.mapgl)
      this.closePanel()

      this.state.activeObject = null
    },
    save() {
      const { activeObject, editingCallback, creatingCallback, mode } =
        this.state
      const callback = mode === 'create' ? creatingCallback : editingCallback

      this.attributes.forEach(({ prop }) => {
        activeObject.setProperty(prop, this.feature[prop])
      })

      callback(activeObject)
      this.closePanel()

      this.$store.commit('SET_CALC_FIELD', {
        field: 'isEdited',
        value: true
      })
    },
    escapeHandler(e) {
      const { key } = e

      if (key === 'Escape') this.closePanel()
    },
    setInitialValues() {
      const { activeObject } = this.state

      Object.keys(this.feature).forEach(key =>
        this.$set(this.feature, key, activeObject.properties[key])
      )
    }
  }
}
</script>

<style lang="scss">
.m-event-attributes {
  position: relative;
  height: calc(100vh - 48px);

  &__card {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__controls {
    padding: 8px;
    border-bottom: 1px solid transparent;
    border-color: var(--dividers_low_contrast) !important;

    .r-button {
      width: 100%;
    }
  }

  &__container {
    flex: 1;
    padding: 8px;
  }

  &__item {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }
}
</style>
