import { activeObjectsConfig } from '../configs/layer-styles'

export const toggleActiveObject = function(state, layer) {
  const sourcename = 'active_object_layer'
  const sourcenameBorder = 'active_object_layer_border'

  removeMapLayer(state, sourcename)
  removeMapLayerBorder(state, sourcenameBorder)

  if (layer) {
    const sourceData = state.mapgl.getSource(layer.source)._data
    const layerData = sourceData.features.find(e =>
      e.properties.id === layer.properties.id
    )
    layer.geometry = layerData.geometry
    addMapLayer(state, layer, sourcename)
    const type = getFeatureType(layer.geometry.type)
    if (type === 'polygon') {
      addMapLayerBorder(state, layer, sourcenameBorder, 'polygon_border')
    }
  }
}

const removeMapLayer = function(state, sourcename) {
  const isActive = state.mapgl.getSource(sourcename)

  if (!isActive) return

  if (state.mapgl.getLayer(sourcename)) state.mapgl.removeLayer(sourcename)

  state.mapgl.removeSource(sourcename)
}

const removeMapLayerBorder = function(state, sourcename) {
  const isActive = state.mapgl.getSource(sourcename)

  if (!isActive) return

  if (state.mapgl.getLayer(sourcename)) state.mapgl.removeLayer(sourcename)

  state.mapgl.removeSource(sourcename)
}

const addMapLayer = function(state, layer, sourcename) {
  if (!layer) return
  const type = getFeatureType(layer.geometry.type)
  const styleConfig = activeObjectsConfig[type]

  state.mapgl.addSource(sourcename, {
    type: 'geojson',
    data: layer.geometry
  })
  state.mapgl.addLayer({
    id: sourcename,
    source: sourcename,
    ...styleConfig
  })
}

const addMapLayerBorder = function(state, layer, sourcename, type) {
  if (!layer) return
  const styleConfig = activeObjectsConfig[type]

  state.mapgl.addSource(sourcename, {
    type: 'geojson',
    data: layer.geometry
  })
  state.mapgl.addLayer({
    id: sourcename,
    source: sourcename,
    ...styleConfig
  })
}

const getFeatureType = function(type) {
  switch (type) {
    case 'Polygon':
    case 'MultiPolygon':
      return 'polygon'
    case 'Point':
    case 'MultiPoint':
      return 'point'
    case 'LineString':
    case 'MultiLineString':
      return 'line_string'
    default:
      return 'point'
  }
}
