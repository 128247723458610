<template>
  <div class="telematics-modal">
    <r-title>Техническая информация</r-title>

    <div class="telematics-modal__info">
      <div
        v-for="(value, key, i) in rowData"
        :key="i"
        class="telematics-modal__info-item"
      >
        <r-text :weight="600">
          {{ `${key}: ` }}
        </r-text>

        <r-text>
          {{ value }}
        </r-text>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    rowData() {
      const data = this.$store.state.monitoring.telematicsModal
      data.json_attrs = data.geom = '—'
      return data
    }
  }
}
</script>

<style lang="scss">
.telematics-modal {
  &__info {
    padding: 1rem 0.5rem;
    display: grid;
    grid-auto-flow: row;
    justify-content: start;
    grid-gap: 0.5rem;

    &-item {
      display: grid;
      grid-auto-flow: column;
      justify-content: start;
      grid-gap: 0.5rem;
    }
  }
}
</style>
