import debounce from 'lodash.debounce'
import {
  clearAllHelpers,
  removeStopPointsHelpers
} from '@/libs/map-draw/helpers'
import { getEditorRequestConfig, jsonToGeojson } from '@/utils'

import { BaseEditor } from '@/libs/map-draw/controllers'

export class EditorController extends BaseEditor {
  constructor(parent) {
    super(parent)
    this.parent = parent
    this.mapgl = parent.mapgl
    this.$store = parent.$store
    this.state = parent.editorState
  }

  // toggling editor
  toggleEditorOn() {
    const { children, id } = this.$store.state.eventEditor.model
    const layers = ['events', 'nodes', 'links']

    this.state.modelId = id
    this.state.modelLayers = children
      .filter(l => layers.includes(l.datatype))
      .map(l => ({
        id: l.id,
        datatype: l.datatype,
        geom_type: l.geom_type,
        name: l.name,
        visible: l.datatype === 'nodes' || l.datatype === 'links',
        loading: false
      }))

    // toggle editing events layer
    this.toggleLayerMode('events')
  }

  toggleLayerMode(mode) {
    const { ids } = this.$store.state.eventEditor.model
    this.toggleEditingModelLayer(ids[mode])
  }

  toggleEditorOff() {
    this.clearEditorData()
    this.draw.deleteAll()

    if (this.loadLayerData) this.mapgl.off('moveend', this.loadLayerData)
    this.state.id = ''
    this.state.modelId = ''
    this.state.modelLayers = []
    this.draw.changeMode('simple_select')
    this.$store.commit('SET_EE_STATE_PROP', {
      name: 'activeCardId',
      value: null
    })
  }

  // toggle editing layer
  toggleEditingModelLayer(layerId) {
    const { id, modelLayers } = this.state
    if (layerId === id) return

    const layer = modelLayers.find(l => l.id === layerId)
    const { visible, geom_type, datatype } = layer

    if (visible) this.toggleVisibleModelLayer(layerId)

    const setNewEditingLayer = () => {
      this.state.id = layerId
      this.state.geom_type = datatype || geom_type
      this.state.geometry = geom_type
      clearAllHelpers(this.mapgl)
      this.setEditorHandler()
      if (id) this.toggleVisibleModelLayer(id)
    }
    const successCb = async() => {
      await this.requestForSaving()
      setNewEditingLayer()
    }
    const errorCb = action => {
      if (action === 'cancel') setNewEditingLayer()
    }

    if (this.state.history.length) {
      this.showConfirmModal(successCb, errorCb)
    } else setNewEditingLayer()
  }

  async setEditorHandler() {
    const loadLayerData = async e => {
      if (e && e.noRequest) return
      const selected = this.draw.getSelectedIds()
      const { id, geom_type, isDrawing } = this.state
      if (isDrawing) return
      if (!id) return

      const zoom = this.mapgl.getZoom()
      const config = getEditorRequestConfig(this.map, geom_type)

      try {
        this.$store.commit('ADD_EE_LOADING_LAYER', id)
        const url = `objectInfo/${id}?config=${JSON.stringify(
          config
        )}&zoom=${zoom}&simplify=off`
        const { data } = await this.map.$store.dispatch('GET_REQUEST', {
          url
        })
        const geojson = jsonToGeojson(Object.keys(data).map(id => data[id]))
        const editedFeatures = [...this.created, ...this.updated]

        geojson.features = geojson.features
          .filter(f => {
            return (
              !this.deleted.find(
                item => item.properties.id === f.properties.id
              ) &&
              !this.updated.find(item => item.properties.id === f.properties.id)
            )
          })
          .map(f => ({ ...f, id: f.properties.id }))

        const features = [...editedFeatures, ...geojson.features]

        this.draw.set({
          type: 'FeatureCollection',
          features
        })

        if (this.state.mode === 'create') this.changeModeToCreate()
        else this.draw.changeMode('simple_select', { featureIds: selected })

        this.$store.commit('REMOVE_EE_LOADING_LAYER', id)
      } catch (error) {
        console.log(error)
        this.$store.commit('REMOVE_EE_LOADING_LAYER', id)
      }
    }

    await this.getLayerObjectFields()
    if (this.loadLayerData) this.mapgl.off('moveend', this.loadLayerData)
    this.loadLayerData = debounce(loadLayerData, 400)
    this.clearEditorData()
    this.loadLayerData()
    this.mapgl.on('moveend', this.loadLayerData)
  }

  toggleEditorMode(newMode) {
    const { mode } = this.state
    const { geometryMode } = this.$store.state.eventEditor

    if (newMode === mode) return

    if (newMode === 'create') {
      const mode = `draw_${geometryMode}`
      this.draw.changeMode(mode)
      this.state.mode = 'create'
    } else {
      this.draw.changeMode('simple_select')
      this.state.mode = 'edit'
    }
  }

  changeGeometryMode(mode) {
    removeStopPointsHelpers(this.mapgl)
    clearAllHelpers(this.mapgl)
    this.draw.changeMode(mode)
  }

  changeModeToCreate() {
    const { geometryMode } = this.$store.state.eventEditor
    const mode = `draw_${geometryMode}`

    this.draw.changeMode(mode)
    this.state.mode = 'create'
  }
}
