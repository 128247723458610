<template>
  <div class="bdd-application-list-tools">
    <r-filter-set
      :filters="filters"
      @reset-all="resetFilters"
      @load="updateFilters"
    />
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Array,
      required: true
    }
  },
  methods: {
    async resetFilters() {
      this.$store.commit('BDD_SET_FIELD', {
        field: 'filters.applications',
        value: this.filters?.filter(f => f.active)
      })

      this.$store.commit('BDD_SET_FIELD', {
        field: 'applicationListFilters',
        value: this.filters
      })

      this.$store.commit('BDD_SET_FIELD', {
        field: 'updateApplications',
        value: true
      })

      try {
        await this.$store.dispatch('SAVE_MAIN_USER_CONFIG')
      } catch (e) {
        throw new Error(e)
      }
    },
    async updateFilters(filter) {
      const target = this.filters.find(f => f.id === filter.id)
      const item = { ...target, ...filter }

      this.filters[this.filters.indexOf(target)] = item

      this.$store.commit('BDD_SET_FIELD', {
        field: 'filters.applications',
        value: this.filters?.filter(f => f.active)
      })

      this.$store.commit('BDD_SET_FIELD', {
        field: 'applicationListFilters',
        value: this.filters
      })

      this.$store.commit('BDD_SET_FIELD', {
        field: 'updateApplications',
        value: true
      })

      try {
        await this.$store.dispatch('SAVE_MAIN_USER_CONFIG')
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>
