const iconSizeValue = [
  'interpolate',
  ['linear'],
  ['zoom'],
  0,
  0.2,
  14,
  0.3,
  18,
  0.6
]

const eventIconImage = [
  'case',
  ['==', ['get', 'type'], 'Недостатки'],
  'event-icon',
  ['==', ['get', 'type'], 'Ремонтные работы'],
  'work-icon',
  ['==', ['get', 'type'], 'ДТП'],
  'accident-icon',
  'event-icon'
]

export const layerConfig = {
  events_lines: {
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#C55151',
      'line-width': 4
    }
  },
  events_lines_icons: {
    type: 'symbol',
    layout: {
      'icon-image': eventIconImage,
      'symbol-placement': 'line-center',
      'icon-pitch-alignment': 'map',
      'icon-rotation-alignment': 'map',
      'icon-size': iconSizeValue,
      'icon-ignore-placement': true,
      'text-allow-overlap': true,
      'icon-allow-overlap': true
    }
  },
  events_icons: {
    type: 'symbol',
    layout: {
      'icon-image': eventIconImage,
      'icon-pitch-alignment': 'map',
      'icon-rotation-alignment': 'map',
      'icon-size': iconSizeValue,
      'icon-ignore-placement': true,
      'text-allow-overlap': true,
      'icon-allow-overlap': true
    }
  }
}
