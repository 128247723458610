import { attrConfigs, reflectionKeys } from '../configs'

export const getFields = async(state, source_id) => {
  try {
    const { data } = await state.$store.dispatch('GET_REQUEST', {
      url: `objectFields/${source_id}`
    })

    const attrConfig = attrConfigs[source_id] || null

    if (!attrConfig) return []

    return Object.entries(attrConfig).map(([k, val]) => {
      if (typeof (val) === 'object') {
        const field = {
          ...val,
          title: val.value
        }
        return field
      } else if (typeof (val) === 'string') {
        const field = data[k]

        field.title = k
        field.value = k
        field.name = val

        return field
      }
    })?.filter(f => !!f)
  } catch (e) {
    throw new Error(e)
  }
}

export const getConfig = (fields, filters, isIncluded, itemsConfig) => {
  const config = {}
  const where = filters && filters?.length ? filters.map(e => e) : []

  if (itemsConfig?.value?.length) {
    if (isIncluded) {
      where.push(itemsConfig)
    } else {
      where.push({
        ...itemsConfig,
        op: '!in',
        type: 'AND'
      })
    }
  }

  config.where = where

  fields.forEach(f => {
    if (f.deepReflection) {
      config.only.push(`${f.title}.${f.key}`)
    } else if (f.reflection) {
      config.only.push(`${f.title}.${f.reflection.default_show_attribute}`)
    } else {
      if (!config.only) config.only = []
      config.only.push(f.title)
    }
  })
  if (!config.only.includes('id')) {
    config.only.push('id')
  }

  if (!isIncluded && !config?.where?.length) {
    config.where = [{
      field: 'id',
      op: '=',
      type: 'AND',
      value: 'no-items'
    }]
  }

  return config
}

export const parseData = (data, url) => {
  return Object.values(data).map(o => {
    const item = { ...o }

    for (const key in o) {
      if (Array.isArray(o[key])) {
        const refKey = reflectionKeys[key] || reflectionKeys.default
        item[key] = o[key].map(e => e[refKey] || e.id).join(', ')
      }
      if (o[key]?.name) {
        item[key] = o[key]?.name
      }
    }

    return item
  })
}

export const makeFilter = (filter, url) => {
  const availableAttr = filter.filter(f => {
    return !f.checked
  })
  const includedFilter = []
  const excludedFilter = []

  if (availableAttr?.length) {
    filter.forEach(a => {
      if (!a.checked) {
        const excluded = []
        const included = []

        a.childNodes.forEach(c => {
          if (c.data?.id) {
            if (!c.checked) {
              excluded.push(c.data?.id.includes('null') ? null : c.data?.id)
            } else {
              included.push(c.data?.id.includes('null') ? null : c.data?.id)
            }
          }
        })

        if (a.label === 'Дата доставки, Факт') {
          const receiving = included.length === 1 && included.includes('true')
          const notReceiving = included.length === 1 && included.includes('false')

          if (receiving) {
            excludedFilter.push({
              field: 'receiving_date_fact',
              op: '=',
              type: 'AND',
              value: null
            })

            includedFilter.push({
              field: 'receiving_date_fact',
              op: '!null',
              type: 'OR',
              value: ''
            })
          } else if (notReceiving) {
            excludedFilter.push(
              {
                field: 'receiving_date_fact',
                op: '!null',
                type: 'AND',
                value: ''
              })

            includedFilter.push({
              field: 'receiving_date_fact',
              op: '=',
              type: 'OR',
              value: null
            })
          }
        } else {
          const fieldName = `${a.data?.value}.id`

          excludedFilter.push({
            field: fieldName,
            op: 'in',
            type: 'OR',
            value: excluded
          })

          if (included?.length) {
            includedFilter.push({
              field: fieldName,
              op: 'in',
              type: 'OR',
              value: included
            })
          }
        }
      }
    })
  }

  return {
    includedFilter,
    excludedFilter
  }
}

export const setItemsConfig = (state) => {
  const config = state.roleAttrFilterItemsConfig || null
  const selectedObjects = state.selectedObjects
  const newConfig = {
    field: 'id',
    op: 'in',
    type: 'OR',
    value: []
  }

  if (state.roleAttrFilterActiveTab === 'included') {
    newConfig.value = selectedObjects?.map(so => so.id)
  } else {
    if (!config) {
      newConfig.value = selectedObjects?.map(so => so.id)
    } else {
      newConfig.value = config.value?.map(v => v) || []

      selectedObjects.forEach(so => {
        if (!newConfig.value.includes(so.id)) {
          newConfig.value.push(so.id)
        }
      })
    }
  }

  state.$store.commit('ROLE_PERM_SET_FIELD', {
    field: 'roleAttrFilterItemsConfig',
    value: newConfig
  })
}
