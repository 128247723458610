import { jsonToGeojson } from '@/utils'

export class FcTrackController {
  constructor(map) {
    this.map = map
    this.$store = map.$store
    this.mapgl = map.mapgl
  }

  toggleTrack(data, type) {
    if (data) {
      this.addTrack(type, data)
    } else {
      this.removeTrack(type)
    }
  }

  addTrack(type, data) {
    const trackSourceId = `mt-fc-track-${type}`
    const color = this.map.trackColors?.[type] || '#ccc'
    const source = this.mapgl.getSource(trackSourceId)

    if (source) {
      source.setData(this.getData(data, type))
      this.mapgl.setPaintProperty(trackSourceId, 'line-color', [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#55b77e',
        color
      ])
    } else {
      this.mapgl.addSource(trackSourceId, {
        type: 'geojson',
        data: this.getData(data, type)
      })

      this.mapgl.addLayer(
        {
          id: trackSourceId,
          source: trackSourceId,
          type: 'line',
          layout: {
            'line-cap': 'round',
            'line-join': 'round'
          },
          paint: {
            'line-color': color,
            'line-width': 3,
            'line-opacity': 0.6
          }
        }
      )
    }
  }

  removeTrack(type) {
    const trackSourceId = `mt-fc-track-${type}`

    if (this.mapgl.getLayer(trackSourceId)) {
      this.mapgl.removeLayer(trackSourceId)
    }
    if (this.mapgl.getSource(trackSourceId)) {
      this.mapgl.removeSource(trackSourceId)
    }
  }

  getData(geom, type) {
    const data = {
      [type]: {
        id: type,
        geom
      }
    }
    return jsonToGeojson(Object.values(data))
  }
}
