import { Constants, CommonSelectors } from '@/libs/map-draw/lib-utils'
import {
  getFeaturesByLayerId,
  createHoveredSPHelper,
  getStopPointsData,
  createStopPointsHelpers,
  removeStopPointsHelpers,
  removeHoveredSPHelpers,
  nearestPointOnLine,
  isPointInLinkArea,
  getNearestFeature
} from '@/libs/map-draw/helpers'
import { rDate } from '@/utils'

export const createODDDrawPointsMode = (MapboxDraw, map) => {
  const DrawPoints = {}
  const customState = {
    hoveredFeatureId: '',
    no: '',
    fromnodeno: '',
    type: '',
    currentPoint: null,
    projectionPoint: null
  }

  const { model, books } = map.$store.state.odd
  const { ids } = model
  const { eventClasses } = books

  DrawPoints.onTap = DrawPoints.onClick = function(state) {
    const {
      hoveredFeatureId,
      currentPoint,
      projectionPoint,
      type
    } = customState

    if (hoveredFeatureId && currentPoint) {
      const { coordinates } = projectionPoint
      this.updateUIClasses({ mouse: Constants.cursors.MOVE })
      state.point.updateCoordinate('', coordinates[0], coordinates[1])

      if (type === 'link') {
        state.point.setProperty('link_id', hoveredFeatureId)
        // state.point.setProperty("fromnodeno", fromnodeno);
      } else {
        state.point.setProperty('node_id', hoveredFeatureId)
      }

      // set properties
      state.point.setProperty(
        'name',
        `Новое перекрытие ${rDate.format(new Date(), 'DD.MM.YY')}`
      )
      state.point.setProperty('event_class_id', eventClasses[0].id)
      state.point.setProperty('closed_line_count', 1)
      state.point.setProperty('address', null)
      state.point.setProperty('requested', false)
      state.point.setProperty('start_time', null)
      state.point.setProperty('end_time', null)

      removeStopPointsHelpers(this.map)
      this.map.fire(Constants.events.CREATE, {
        features: [state.point.toGeoJSON()]
      })

      // change mode
      this.changeMode(Constants.modes.SIMPLE_SELECT, {
        featureIds: [state.point.id]
      })
      map.$store.commit('SET_ODD_EDITOR_PROP', {
        field: 'mode',
        value: 'select'
      })
    }
  }

  DrawPoints.onMouseMove = function(state, e) {
    const { lng, lat } = e.lngLat
    const nodesFeatures = getFeaturesByLayerId(this.map, e, ids.nodes)
    const linksFeatures = getFeaturesByLayerId(this.map, e, ids.links)

    if (nodesFeatures.length) {
      const feature = getNearestFeature(nodesFeatures, [lng, lat])
      const { coordinates } = feature.geometry

      if (customState.hoveredFeatureId !== feature.properties.id) {
        removeStopPointsHelpers(this.map)
        customState.hoveredFeatureId = ''

        createHoveredSPHelper(this.map, coordinates)
        const { id, no } = feature.properties
        customState.hoveredFeatureId = id
        customState.currentPoint = {
          type: 'Point',
          coordinates
        }
        customState.projectionPoint = {
          type: 'Point',
          coordinates
        }
        customState.type = 'node'
        customState.no = no
      }
    } else if (linksFeatures.length) {
      const link = linksFeatures.find(l =>
        isPointInLinkArea([lng, lat], l.geometry.coordinates, 100)
      )

      if (link) {
        removeHoveredSPHelpers(this.map)
        const nearest = nearestPointOnLine(
          [lng, lat],
          link.geometry.coordinates
        )
        const current = {
          geometry: {
            type: 'Point',
            coordinates: [lng, lat]
          },
          properties: {
            projection: 'true'
          }
        }
        const connection = {
          geometry: {
            type: 'LineString',
            coordinates: [nearest.geometry.coordinates, [lng, lat]]
          }
        }

        const { id, no, fromnodeno } = link.properties
        customState.hoveredFeatureId = id
        customState.type = 'link'
        customState.no = no
        customState.fromnodeno = fromnodeno
        customState.currentPoint = current.geometry
        customState.projectionPoint = nearest.geometry

        const data = getStopPointsData(nearest, current, connection)
        createStopPointsHelpers(this.map, data)
      } else {
        removeStopPointsHelpers(this.map)
        customState.hoveredFeatureId = ''
        customState.type = ''
        customState.currentPoint = null
        customState.projectionPoint = null
      }
    } else {
      removeStopPointsHelpers(this.map)
      customState.hoveredFeatureId = ''
      customState.type = ''
      customState.currentPoint = null
      customState.projectionPoint = null
    }
  }

  DrawPoints.onKeyUp = function(state, e) {
    if (CommonSelectors.isEscapeKey(e)) {
      this.stopDrawingAndRemove(state, e)

      this.changeMode('draw_points')
    }
  }

  return { ...MapboxDraw.modes.draw_point, ...DrawPoints }
}
