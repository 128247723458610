<template>
  <div class="map-editor-layer-header">
    <r-icon
      :name="state.modelLayers.length ? 'model-layer' : getGeomIcon()"
      :size="14"
    />
    <r-text>
      {{ state.name }}
    </r-text>
  </div>
</template>

<script>
import { geomTypesIcon } from '@/config/geom-types'

export default {
  props: {
    state: {
      type: Object,
      required: true
    }
  },
  methods: {
    getGeomIcon() {
      return `${geomTypesIcon[this.state.geom_type]}`
    }
  }
}
</script>

<style lang="scss">
.map-editor-layer-header {
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid transparent;
  border-color: var(--dividers_low_contrast) !important;

  .r-icon {
    margin-right: 8px;
    width: auto;
  }
}
</style>
