import mapboxgl from 'mapbox-gl'
import { mapToken } from '@/constants/tokens'
import { MapConfigEvents, getMapConfigs } from '@/utils'
import { VehicleController, TrackController } from '../controllers'

// load icons
const MAP_ICON_SIZE = 64
const icons = [
  {
    name: 'monitoring-car-track',
    imageUrl: require('@/assets/images/monitoring/monitoring-car-track.svg')
  },
  {
    name: 'monitoring-car-green',
    imageUrl: require('@/assets/images/monitoring/monitoring-car-green.svg')
  },
  {
    name: 'monitoring-car-blue',
    imageUrl: require('@/assets/images/monitoring/monitoring-car-blue.svg')
  },
  {
    name: 'monitoring-car-yellow',
    imageUrl: require('@/assets/images/monitoring/monitoring-car-yellow.svg')
  },
  {
    name: 'monitoring-car-red',
    imageUrl: require('@/assets/images/monitoring/monitoring-car-red.svg')
  },
  {
    name: 'vehicle-selected',
    imageUrl: require('@/assets/images/monitoring/monitoring-car-selected.svg')
  },
  {
    name: 'car-sdf',
    imageUrl: require('@/assets/icons/mono/transport/truck-filled.svg'),
    sdf: true
  },
  {
    name: 'cross_geozone_out',
    imageUrl: require('@/assets/images/monitoring/arrow-right-from-bracket.svg'),
    sdf: true
  },
  {
    name: 'cross_geozone_in',
    imageUrl: require('@/assets/images/monitoring/arrow-right-to-bracket.svg'),
    sdf: true
  },
  {
    name: 'deviation_from_route',
    imageUrl: require('@/assets/images/monitoring/arrows-rotate.svg'),
    sdf: true
  },
  {
    name: 'deviation_from_route',
    imageUrl: require('@/assets/images/monitoring/arrows-split-up-and-left.svg'),
    sdf: true
  },
  {
    name: 'status_changed',
    imageUrl: require('@/assets/images/monitoring/arrows-turn-to-dots.svg'),
    sdf: true
  },
  {
    name: 'stop',
    imageUrl: require('@/assets/images/monitoring/circle-stop.svg'),
    sdf: true
  },
  {
    name: 'move',
    imageUrl: require('@/assets/images/monitoring/circle-up.svg'),
    sdf: true
  },
  {
    name: 'over_speed',
    imageUrl: require('@/assets/images/monitoring/gauge-high.svg'),
    sdf: true
  },
  {
    name: 'track-event',
    imageUrl: require('@/assets/icons/colored/track-event.svg')
  }
]

export const loadIcons = component => {
  return Promise.all(
    icons.map(
      ({ name, imageUrl, sdf }) =>
        new Promise(resolve => {
          const image = new Image(MAP_ICON_SIZE, MAP_ICON_SIZE)
          image.crossOrigin = 'Anonymous'
          image.style.backgroundPosition = '50%, 50%'
          image.style.backgroundSize = '100%'
          image.addEventListener('load', () => {
            component.mapgl.addImage(name, image, { sdf })
            resolve()
          })
          image.src = imageUrl
        })
    )
  ).then(() => console.log('monitoring icons loaded!'))
}

export const initMap = component => {
  // map creating
  const { center, zoom, baselayer } = getMapConfigs(component, 'monitoring')

  mapboxgl.accessToken = mapToken

  component.mapgl = new mapboxgl.Map({
    container: 'mt-map',
    preserveDrawingBuffer: true,
    style: baselayer,
    center,
    zoom
  })

  // view handlers
  component.mapgl.on('pitch', e => {
    component.is3d = e.target.getPitch() !== 0
  })
  component.mapgl.on('rotate', e => {
    component.mapBearing = e.target.getBearing()
  })

  // controllers
  component.controllers.events = new MapConfigEvents(component, 'monitoring')
  component.controllers.vehicle = new VehicleController(component)
  component.controllers.track = new TrackController(component)

  component.mapgl.on('load', async() => {
    // load icons to map
    await loadIcons(component)
    // load vehicle books
    await component.getVehicleCategory()

    // load and add vehicles
    component.controllers.events.addEventHandler()
    component.controllers.vehicle.addVehicles()

    // component.timer = setInterval(() => {
    //   // console.log('vehicles updated')
    //   component.controllers.vehicle.updateVehicles()
    // }, 30000)
  })

  window.addEventListener('beforeunload', component.saveMapParams)
}
