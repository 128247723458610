<template>
  <div class="rp-users-datasources-list-role">
    <r-search-input
      :filter-text="filterText"
      placeholder="Поиск по названию"
      @change="e => (filterText = e)"
    />
    <ul
      v-if="filteredList && filteredList.length > 0"
      class="rp-users-datasources-list-role-wrapper"
    >
      <li
        v-for="item in filteredList"
        :key="item.id"
        :class="[
          'rp-users-datasources-list-role__item',
          { active: isItemActive(item) },
          { 'is-disabled': isItemDisabled(item) }
        ]"
        @click="selectItem(item)"
      >
        <div class="rp-users-datasources-list-role__item-row">
          <r-icon
            :name="getIconName(item)"
            :size="24"
          />
          <div class="rp-users-datasources-list-role__item__title">
            <r-text>{{ item.name }}</r-text>
          </div>
        </div>
        <div class="rp-users-datasources-list-role__item-row">
          <rp-users-datasources-list-cruds :item="item" />
        </div>
      </li>
    </ul>
    <div
      v-else
      class="rp-users-datasources-list-role__no-data"
    >
      <r-text>Нет данных</r-text>
    </div>
  </div>
</template>

<script>
import rpUsersDatasourcesListCruds from './rp-users-datasources-list-cruds.vue'

export default {
  components: {
    rpUsersDatasourcesListCruds
  },
  props: {
    list: {
      type: Array,
      required: true
    },
    selectedItems: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      filterText: ''
    }
  },
  computed: {
    filteredList() {
      const list = this.list.filter(e => e._action !== 'deleted')
      if (!this.filterText?.trim()) return list || []

      return (
        list?.filter(e => {
          return e.name
            ?.trim()
            ?.toLowerCase()
            ?.includes(this.filterText?.trim()?.toLowerCase())
        }) || []
      )
    },
    roleDatasources() {
      return this.$store.state.rolePermission.rolePerms?.datasources || []
    }
  },
  methods: {
    getIconName(item) {
      if (item.datatype === 'model') return 'model-layer'

      switch (item.geom_type) {
        case 'line_string':
        case 'multi-line-string':
          return 'line-layer'
        case 'polygon':
        case 'multi_polygon':
          return 'polygon-layer'
        case 'point':
        case 'multi_point':
          return 'node-layer'
        default:
          return 'folder-layer'
      }
    },
    selectItem(item) {
      if (this.isItemDisabled(item)) return
      if (!this.selectedItems) return

      const existIndex = this.selectedItems.findIndex(e => e.id === item.id)

      if (existIndex > -1) {
        this.selectedItems.splice(existIndex, 1)
      } else {
        this.selectedItems.push(item)
      }
    },
    isItemActive(item) {
      return !!this.selectedItems?.find(e => e.id === item.id)
    },
    isItemDisabled(item) {
      return !!this.roleDatasources.find(e => e.id === item.id)
    }
  }
}
</script>

<style lang="scss">
.rp-users-datasources-list-role {
  padding: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0px;
  overflow: hidden;

  &-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 0;
    overflow: auto;
    margin-top: 8px;
  }

  &__item {
    position: relative;
    padding: 8px;
    border-radius: var(--border-radius);
    margin-bottom: 4px;
    border: 1px solid transparent;
    cursor: pointer;

    &__title {
      width: calc(100% - 144px) !important;
    }

    &-row {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex: 1;
      width: 100%;
      overflow: hidden;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      > * {
        flex-shrink: 0;
      }

      .r-icon {
        width: 24px;
      }

      .r-title {
        margin: 0 2px;
      }

      .r-button {
        margin-left: auto;
        padding: 4px !important;

        span {
          grid-gap: 0;
        }

        .r-icon {
          margin: 0;
        }
      }
    }

    &::after {
      content: '';
      height: 1px;
      width: 100%;
      background-color: var(--dividers_low_contrast);
      position: absolute;
      display: block;
      bottom: -2px;
      left: 0;
    }

    &:last-child {
      margin-bottom: 0;

      &::after {
        content: none;
      }
    }

    .r-icon {
      margin-right: 4px;
    }

    &:hover {
      border: 1px solid var(--accent_hover);
    }

    &.active {
      background-color: var(--accent_selected);
    }
    &.is-disabled {
      cursor: initial;
    }
  }

  &__no-data {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 80px;
  }
}
</style>
