<template>
  <div class="m-timeline">
    <div class="m-timeline__top">
      <el-tooltip
        :open-delay="500"
        effect="dark"
        content="На шаг назад"
        placement="top"
      >
        <r-button
          mini
          :disabled="current === 0"
          class="m-timeline__controls-btn previous"
          icon="chevron-left"
          type="primary"
          @click="goToStep('previous')"
        />
      </el-tooltip>
      <div class="m-timeline__current">
        <r-title
          type="title-1"
          style
        >
          {{ formatTooltip(current) }}
        </r-title>
      </div>
      <el-tooltip
        :open-delay="500"
        effect="dark"
        content="На шаг вперед"
        placement="top"
      >
        <r-button
          mini
          class="m-timeline__controls-btn previous"
          :disabled="current === times.length - 1"
          icon="chevron-right"
          type="primary"
          @click="goToStep('next')"
        />
      </el-tooltip>
    </div>
    <div class="m-timeline__bottom">
      <!-- <div class="m-timeline__left">
        <el-tooltip
          :open-delay="500"
          effect="dark"
          :content="timemachinePlaying ? 'Остановить' : 'Запустить'"
          placement="top"
        >
          <r-button
            class="m-timeline__controls-btn play"
            type="primary"
            @click="playClickHandler"
            :icon="timemachinePlaying ? 'pause' : 'play'"
          >

          </r-button>
        </el-tooltip>
      </div> -->
      <div class="m-timeline__center">
        <div class="m-timeline__slider">
          <el-slider
            v-model="current"
            class="r-slider dark square"
            :min="0"
            :max="times.length - 1"
            :step="1"
            :show-tooltip="false"
            :format-tooltip="formatTooltip"
            @change="changeTimeValue"
          />
        </div>
        <div class="m-timeline__time-labels">
          <r-text
            type="caption"
            style="margin-bottom: 0; margin-right: 16px"
          >
            {{ formatTooltip(0) }}
          </r-text>
          <r-text
            type="caption"
            style="margin-bottom: 0; margin-left: 16px"
          >
            {{ formatTooltip(times.length - 1) }}
          </r-text>
        </div>
      </div>
      <!-- <div class="m-timeline__right">
        <el-tooltip
          :open-delay="500"
          effect="dark"
          content="Запустить с начала"
          placement="top"
        >
          <r-button
            class="m-timeline__controls-btn refresh"
            type="primary"
            @click="refreshPlaying"
            icon="replay"
          >
          </r-button>
        </el-tooltip>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: 0,
      interval: null,
      playingStep: 1
    }
  },
  computed: {
    times() {
      return this.$store.state.modelling.times
    },
    timemachinePlaying() {
      return this.$store.state.modelling.playing
    }
  },
  created() {
    const { currentTime } = this.$store.state.modelling

    this.current = this.times.findIndex(t => t === currentTime)
  },
  methods: {
    formatTooltip(value) {
      return this.$rDate.secondsToTime(this.times[value])
    },
    changeTimeValue(index) {
      this.$store.commit('SET_CALC_FIELD', {
        field: 'currentTime',
        value: this.times[index]
      })

      this.$cControllers.layers.updateCalcData(true)
    },
    playClickHandler() {
      if (this.timemachinePlaying) this.stop()
      else this.play()
    },
    play() {
      this.$store.commit('SET_CALC_FIELD', {
        field: 'playing',
        value: true
      })

      this.interval = setInterval(() => {
        const nextStep = this.current + this.playingStep

        if (nextStep <= this.times.length - 1) {
          this.current += this.playingStep
        } else {
          this.current = this.times.length - 1
          this.stop()
        }

        this.changeTimeValue(this.current)
        this.$forceUpdate()
      }, 300)
    },
    refreshPlaying() {
      this.current = 0

      if (this.timemachinePlaying) this.stop()
      this.changeTimeValue(this.current)
      this.play()
    },
    stop() {
      clearInterval(this.interval)
      this.$store.commit('SET_CALC_FIELD', {
        field: 'playing',
        value: false
      })
      this.interval = null
    },
    goToStep(type) {
      switch (type) {
        case 'next': {
          const nextStep = this.current + 1

          if (nextStep <= this.times.length - 1) {
            this.current += 1
          } else {
            this.current = this.times.length - 1
          }
          break
        }
        case 'previous': {
          const previousStep = this.current - 1

          if (previousStep <= this.times.length - 1) {
            this.current -= 1
          } else {
            this.current = this.times.length - 1
          }
        }
      }

      this.changeTimeValue(this.current)
    }
  }
}
</script>

<style lang="scss">
.m-timeline {
  position: absolute;
  right: 16px;
  bottom: 32px;
  padding: 16px;
  z-index: 10;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  background-color: var(--bg_panel_secondary) !important;
  border-color: var(--dividers_low_contrast) !important;
  color: var(--text_secondary) !important;
  width: 50vh;

  &__top {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__current {
    padding: 4px 12px;
    border-radius: var(--border-radius);
    margin: 0 16px;
    background-color: var(--field_bg) !important;

    .r-title {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 2px;
    }
  }

  &__bottom {
    display: flex;
    margin-top: 8px;
  }

  &__center {
    flex: 1;
    margin: 0 16px;
  }

  &__controls-btn.r-button.icon-button.is-circle {
    padding: 4px !important;
  }

  &__time-labels {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__slider {
    display: flex;

    .r-slider {
      flex: 1;

      .el-slider {
        &__runway {
          height: 16px !important;
          margin: 8px !important;
        }

        &__bar {
          height: 16px !important;
          background-color: var(--accent_active) !important;
        }

        &__button {
          height: 16px !important;
          width: 8px !important;
          border-radius: 2px !important;

          &-wrapper {
            top: -10px !important;
          }
        }
      }
    }
  }
}
</style>
