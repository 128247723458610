export const getVehiclesRequestConfig = (store, inFilters, filters) => {
  const { filterCategories, statusFilter } = store.state.monitoring
  const nestedFields = { type_group_id: 'vehicle_type' }
  const where = [
    {
      op: 'in',
      field: 'categories.id',
      value: '4ab630f5-90d1-4691-86c1-fb75ce63967b'
    }
  ]

  Object.keys(filterCategories).forEach(field => {
    const initFilter = filters?.find(e => e.type === field)
    const filterIds = initFilter?.items?.map(e => e.id)
    const activeValues = filterCategories[field]
    const allValues = activeValues?.length === filterIds?.length
    const op = 'in'
    const value = allValues ? [] : activeValues
    const parent = nestedFields[field]

    if (value.length) {
      where.push({
        op,
        field: parent ? `${parent}.${field}` : field,
        value
      })
    }
  })

  if (inFilters) {
    const { items } = statusFilter
    const statusValues = items
      .filter(item => !!item.active)
      .reduce((prev, current) => [...prev, ...current.values], [])

    if (statusValues.length !== items.reduce((prev, current) => [...prev, ...current.values], []).length) {
      where.push({
        op: 'in',
        field: 'retranslation_status',
        value: statusValues
      })
    }
  }

  return {
    where
  }
}
