<template>
  <div
    v-if="data"
    class="pot-1-print-sheet-info"
  >
    <div class="pot-1-print-sheet-info__row">
      <pot-1-print-sheet-info-block
        :title="infoConfig.main.title"
        :info="infoConfig.main"
        :data="data"
      />
      <pot-1-print-sheet-info-block
        :title="infoConfig.delivery.title"
        :info="infoConfig.delivery"
        :data="data"
      />
    </div>
    <div class="pot-1-print-sheet-info__row">
      <pot-1-print-sheet-info-block
        :title="infoConfig.cargo.title"
        :info="infoConfig.cargo"
        :data="data.cargoes || {}"
      />
    </div>
  </div>
</template>

<script>
import pot1PrintSheetInfoBlock from './pot-1-print-sheet-info-block.vue'
import { infoConfig } from '../../configs'

export default {
  components: { pot1PrintSheetInfoBlock },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      infoConfig
    }
  }
}
</script>

<style lang="scss">
.pot-1-print-sheet-info {
  position: relative;
  width: 100%;

  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    > * {
      width: 100%;
      margin-bottom: 12px;
      margin-left: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
</style>
