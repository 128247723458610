export const getModelItemWidth = item => {
  let width
  if (typeof item.width === 'number' && item.width !== 0) {
    width = item?.width
  } else {
    switch (item.width) {
      case 'half':
        width = 6
        break
      case 'fourth':
        width = 3
        break
      case 'third':
        width = 4
        break
      case 'full':
      default:
        width = 12
    }
  }
  const value = `span ${width}`
  return `grid-column: ${value};`
}
