import vue from 'vue'

const state = {
  beforeClose: null
}

const mutations = {
  NAVBAR_SET_FIELD(state, { field, value }) {
    vue.set(state, field, value)
  }
}

export default {
  state,
  mutations
}
