export const MAP_ICON_SIZE = 64

export const icons = [
  { label: 'Знак 1.19(РФ)', name: '1.19_Russian_road_sign.svg' },
  { label: 'Tram Green', name: 'tram-green.svg' },
  { label: 'Знак 1.6(РФ)', name: '1.6_Russian_road_sign.svg' },
  { label: 'Flag Blue', name: 'flag-blue.svg' },
  { label: 'Bicycle Blue', name: 'bicycle-blue.svg' },
  { label: 'Знак 1.23(РФ)', name: '1.23_Russian_road_sign.svg' },
  { label: 'Bicycle Green', name: 'bicycle-green.svg' },
  { label: 'Marker Red', name: 'marker-red.svg' },
  { label: 'Знак 3.3(РФ)', name: '3.3_Russian_road_sign.svg' },
  { label: 'Car Green', name: 'car-green.svg' },
  { label: 'Motorcycle Green', name: 'motorcycle-green.svg' },
  { label: 'Marker Blue', name: 'marker-blue.svg' },
  { label: 'Знак 1.2(РФ)', name: '1.20.1_Russian_road_sign.svg' },
  { label: 'Знак 4.5(РФ)', name: '4.5.1_Russian_road_sign.svg' },
  { label: 'Знак 3.3(РФ)', name: '3.30_Russian_road_sign.svg' },
  { label: 'Rhombus Orange', name: 'rhombus-orange.svg' },
  { label: 'Знак 1.11(РФ)', name: '1.11.1_Russian_road_sign.svg' },
  { label: 'Знак 1.21(РФ)', name: '1.21_Russian_road_sign.svg' },
  { label: 'Circle Red', name: 'circle-red.svg' },
  { label: 'Bus Blue', name: 'bus-blue.svg' },
  { label: 'Знак 3.1(РФ)', name: '3.1_Russian_road_sign.svg' },
  { label: 'Знак 2.2(РФ)', name: '2.2_Russian_road_sign.svg' },
  { label: 'Rhombus Green', name: 'rhombus-green.svg' },
  { label: 'Знак 1.29(РФ)', name: '1.29_Russian_road_sign.svg' },
  { label: 'Flag Red', name: 'flag-red.svg' },
  { label: 'Flag Green', name: 'flag-green.svg' },
  { label: 'Знак 1.13(РФ)', name: '1.13_Russian_road_sign.svg' },
  { label: 'Знак 1.15(РФ)', name: '1.15_Russian_road_sign.svg' },
  { label: 'Знак 1.2(РФ)', name: '1.2_Russian_road_sign.svg' },
  { label: 'Знак 1.27(РФ)', name: '1.27_Russian_road_sign.svg' },
  { label: 'Знак 2.4(РФ)', name: '2.4_Russian_road_sign.svg' },
  { label: 'Знак 1.31(РФ)', name: '1.31_Russian_road_sign.svg' },
  { label: 'Rhombus Purple', name: 'rhombus-purple.svg' },
  { label: 'Знак 1.8(РФ)', name: '1.8_Russian_road_sign.svg' },
  { label: 'Знак 1.17(РФ)', name: '1.17_Russian_road_sign.svg' },
  { label: 'Знак 1.12(РФ)', name: '1.12.1_Russian_road_sign.svg' },
  { label: 'Знак 1.25(РФ)', name: '1.25_Russian_road_sign.svg' },
  { label: 'Знак 2.6(РФ)', name: '2.6_Russian_road_sign.svg' },
  { label: 'Знак 1.33(РФ)', name: '1.33_Russian_road_sign.svg' },
  { label: 'Rhombus Red', name: 'rhombus-red.svg' },
  { label: 'Знак 3.5(РФ)', name: '3.5_Russian_road_sign.svg' },
  { label: 'Truck Red', name: 'truck-red.svg' },
  { label: 'Знак 3.28(РФ)', name: '3.28_Russian_road_sign.svg' },
  { label: 'Знак 3.31(РФ)', name: '3.31_Russian_road_sign.svg' },
  { label: 'Знак 1.1(РФ)', name: '1.10_Russian_road_sign.svg' },
  { label: 'Tram Blue', name: 'tram-blue.svg' },
  { label: 'Знак 3.27(РФ)', name: '3.27_Russian_road_sign.svg' },
  { label: 'Знак 4.6(РФ)', name: '4.6_Russian_road_sign.svg' },
  { label: 'Знак 1.7(РФ)', name: '1.7_Russian_road_sign.svg' },
  { label: 'Square Blue', name: 'square-blue.svg' },
  { label: 'Знак 1.18(РФ)', name: '1.18_Russian_road_sign.svg' },
  { label: 'Bus Green', name: 'bus-green.svg' },
  { label: 'Знак 3.2(РФ)', name: '3.2_Russian_road_sign.svg' },
  { label: 'Знак 2.1(РФ)', name: '2.1_Russian_road_sign.svg' },
  { label: 'Square Red', name: 'square-red.svg' },
  { label: 'Знак 1.22(РФ)', name: '1.22_Russian_road_sign.svg' },
  { label: 'Motorcycle Purple', name: 'motorcycle-purple.svg' },
  { label: 'Square Purple', name: 'square-purple.svg' },
  { label: 'Bus Orange', name: 'bus-orange.svg' },
  { label: 'Marker Orange', name: 'marker-orange.svg' },
  { label: 'Bicycle Orange', name: 'bicycle-orange.svg' },
  { label: 'Знак 1.28(РФ)', name: '1.28_Russian_road_sign.svg' },
  { label: 'Rhombus Blue', name: 'rhombus-blue.svg' },
  { label: 'Truck Purple', name: 'truck-purple.svg' },
  { label: 'Circle Purple', name: 'circle-purple.svg' },
  { label: 'Bicycle Red', name: 'bicycle-red.svg' },
  { label: 'Знак 1.2(РФ)', name: '1.20.2_Russian_road_sign.svg' },
  { label: 'Car Red', name: 'car-red.svg' },
  { label: 'Flag Purple', name: 'flag-purple.svg' },
  { label: 'Car Purple', name: 'car-purple.svg' },
  { label: 'Motorcycle Red', name: 'motorcycle-red.svg' },
  { label: 'Знак 1.5(РФ)', name: '1.5_Russian_road_sign.svg' },
  { label: 'Tram Orange', name: 'tram-orange.svg' },
  { label: 'Truck Green', name: 'truck-green.svg' },
  { label: 'Tram Purple', name: 'tram-purple.svg' },
  { label: 'Car Blue', name: 'car-blue.svg' },
  { label: 'Truck Blue', name: 'truck-blue.svg' },
  { label: 'Знак 2.5(РФ)', name: '2.5_Russian_road_sign.svg' },
  { label: 'Знак 1.3(РФ)', name: '1.30_Russian_road_sign.svg' },
  { label: 'Square Green', name: 'square-green.svg' },
  { label: 'Знак 3.6(РФ)', name: '3.6_Russian_road_sign.svg' },
  { label: 'Flag Orange', name: 'flag-orange.svg' },
  { label: 'Motorcycle Blue', name: 'motorcycle-blue.svg' },
  { label: 'Car Orange', name: 'car-orange.svg' },
  { label: 'Знак 1.14(РФ)', name: '1.14_Russian_road_sign.svg' },
  { label: 'Circle Orange', name: 'circle-orange.svg' },
  { label: 'Truck Orange', name: 'truck-orange.svg' },
  { label: 'Marker Green', name: 'marker-green.svg' },
  { label: 'Marker Purple', name: 'marker-purple.svg' },
  { label: 'Bicycle Purple', name: 'bicycle-purple.svg' },
  { label: 'Bus Purple', name: 'bus-purple.svg' },
  { label: 'Знак 2.7(РФ)', name: '2.7_Russian_road_sign.svg' },
  { label: 'Знак 1.32(РФ)', name: '1.32_Russian_road_sign.svg' },
  { label: 'Square Orange', name: 'square-orange.svg' },
  { label: 'Знак 3.4(РФ)', name: '3.4_Russian_road_sign.svg' },
  { label: 'Motorcycle Orange', name: 'motorcycle-orange.svg' },
  { label: 'Знак 1.24(РФ)', name: '1.24_Russian_road_sign.svg' },
  { label: 'Bus Red', name: 'bus-red.svg' },
  { label: 'Знак 1.1(РФ)', name: '1.1_Russian_road_sign.svg' },
  { label: 'Знак 3.29(РФ)', name: '3.29_Russian_road_sign.svg' },
  { label: 'Tram Red', name: 'tram-red.svg' },
  { label: 'Circle Blue', name: 'circle-blue.svg' },
  { label: 'Circle Green', name: 'circle-green.svg' },
  { label: 'Знак 1.16(РФ)', name: '1.16_Russian_road_sign.svg' },
  { label: 'Знак 1.9(РФ)', name: '1.9_Russian_road_sign.svg' },
  { label: 'Sensor Purple', name: 'sensor-purple.svg' },
  { label: 'Sensor Orange', name: 'sensor-orange.svg' },
  { label: 'Sensor Green', name: 'sensor-green.svg' },
  { label: 'Sensor Blue', name: 'sensor-blue.svg' },
  { label: 'Sensor Red', name: 'sensor-red.svg' },
  { label: 'Bus Station Red', name: 'bus-station-red.svg' },
  { label: 'Bus Station Blue', name: 'bus-station-blue.svg' },
  { label: 'Bus Station Green', name: 'bus-station-green.svg' },
  { label: 'Bus Station Purple', name: 'bus-station-purple.svg' },
  { label: 'Bus Station Orange', name: 'bus-station-orange.svg' }
]
