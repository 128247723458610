<template>
  <div class="restriction-modal">
    <r-title>{{ title }}</r-title>
    <component
      :is="`restriction-modal-${mode}`"
      :source_id="event.source_id"
      :event="event"
      @mode="changeMode"
    />
  </div>
</template>

<script>
import restrictionModalView from './restriction-modal-view'
import restrictionModalEdit from './restriction-modal-edit'

export default {
  components: { restrictionModalEdit, restrictionModalView },
  data() {
    return {
      mode: 'view',
      initHeight: 'calc(90vh - 50px)'
    }
  },
  computed: {
    title() {
      switch (this.mode) {
        case 'view':
          return 'Карточка перекрытия'
        default:
          return 'Редактирование перекрытия'
      }
    },
    event() {
      return this.$store.state.bdd.restriction
    }
  },
  watch: {
    mode(mode) {
      this.setHeight(mode === 'view' ? this.initHeight : 'auto')
    }
  },
  mounted() {
    this.setHeight(this.initHeight)
  },
  methods: {
    setHeight(height) {
      document.querySelector('[aria-label="dialog"]').style.height = height
    },
    changeMode(prop) {
      this.mode = prop
    }
  }
}
</script>

<style lang="scss">
.restriction-modal {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;

  .files-comments {
    grid-gap: 1rem;
  }
}
</style>
