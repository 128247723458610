<template>
  <div class="export-button">
    <r-button
      :mini="mini"
      type="primary"
      @click="visible = true"
    >
      Экспортировать
    </r-button>
    <export-modal
      :visible="visible"
      :uri="uri"
      :name="name"
      :book-export="bookExport"
      :hard-uri="hardUri"
      @close="close"
    />
  </div>
</template>

<script>
import exportModal from './modal'

export default {
  components: {
    exportModal
  },
  props: {
    uri: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    bookExport: {
      type: Boolean,
      default: () => false
    },
    mini: {
      type: Boolean,
      default: () => false
    },
    hardUri: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    close() {
      this.visible = false
    }
  }
}
</script>
