<template>
  <ul
    v-if="users && users.length"
    class="rp-sidebar-users-list"
  >
    <li
      v-for="user in users"
      :key="user.id"
      :class="[
        'rp-sidebar-users-list__item',
        { active: user.id === activeUserId }
      ]"
      @click="editUser(user)"
    >
      <div class="icon-col">
        <r-icon
          name="user"
          :size="24"
        />
      </div>
      <div class="info-col">
        <r-text
          type="caption"
          color-type="primary"
          style="text-transform: capitalize;"
        >
          {{ getName(user) }}
        </r-text>
        <r-text
          type="caption"
          color-type="secondary"
        >
          {{ getRoleName(user) }}
        </r-text>
      </div>
    </li>
  </ul>
  <div
    v-else
    class="rp-sidebar-users-list__no-data"
  >
    <r-text>Нет данных</r-text>
  </div>
</template>

<script>
export default {
  props: {
    users: {
      type: Array,
      required: true
    }
  },
  computed: {
    activeUserId() {
      return this.$store.state.rolePermission.activeUserId || null
    },
    allRoles() {
      return this.$store.state.rolePermission.allRoles || null
    }
  },
  watch: {
    users: {
      handler: function() {
        this.resetRolePerms()
      },
      deep: true
    }
  },
  methods: {
    getName(user) {
      if (!user.last_name) return user.name

      let name = user.last_name

      if (user.first_name) {
        name = `${name} ${user.first_name[0]}.`
      }

      if (user.first_name && user.middle_name) {
        name = `${name} ${user.middle_name[0]}.`
      }

      return name || user.name
    },
    getRoleName(user) {
      if (user.ib_admin) return 'Администратор'
      if (user.admin) return 'Менеджер'

      const roleId = user.user_roles[0]?.role_id

      if (!roleId) return 'Нет роли'

      return this.allRoles?.find(e => e.id === roleId)?.name || 'Нет роли'
    },
    editUser(user) {
      this.$store.commit('ROLE_PERM_SET_FIELD', {
        field: 'activeUserId',
        value: user.id
      })
      this.loadUserPermission(user)
    },
    async loadUserPermission(user) {
      try {
        this.$store.commit('ROLE_PERM_SET_IS_LOADING', true)
        const perms = {
          modules: [],
          datasources: []
        }
        const modules = await this.$store.dispatch('POST_REQUEST', {
          url: `user/modules?user_id=${user.id}`
        })
        perms.modules = modules.data

        const datasources = await this.$store.dispatch('POST_REQUEST', {
          url: `user/datasources?user_id=${user.id}`
        })
        const datasourcesUniqId = {}
        datasources.data.forEach(e => {
          if (!datasourcesUniqId[e.id]) {
            perms.datasources.push(e)
            datasourcesUniqId[e.id] = {}
          }
        })
        this.$store.commit('ROLE_PERM_SET_ACTIVE_USER_DATA', perms)
        this.loadRolePermission(user)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.$store.commit('ROLE_PERM_SET_IS_LOADING', false)
      }
    },
    async loadRolePermission(user) {
      if (!user?.user_roles?.length) return

      try {
        this.$store.commit('ROLE_PERM_SET_IS_LOADING', true)
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `role?role_id=${user.user_roles[0].role_id}&permissions=true`
        })

        this.$store.commit('ROLE_PERM_SET_ACTIVE_ROLE_DATA', data?.permissions)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.$store.commit('ROLE_PERM_SET_IS_LOADING', false)
      }
    },
    resetRolePerms() {
      const activeUser = this.users?.find(e => e.id === this.activeUserId)
      if (!activeUser) return

      this.loadRolePermission(activeUser)
    }
  }
}
</script>

<style lang="scss">
.rp-sidebar-users-list {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;
  overflow: auto;

  &__item {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    cursor: pointer;
    border-radius: var(--border-radius);
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }

    .icon-col {
      margin-right: 4px;
    }

    .info-col {
      > * {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &:hover {
      background-color: var(--accent_hover);
    }

    &:active {
      background-color: var(--accent_active);
    }

    &.active {
      background-color: var(--accent_selected);
      cursor: initial;
    }
  }

  &__no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
  }
}
</style>
