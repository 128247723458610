<template>
  <div class="map-heatmaps">
    <heatmap2d
      :id="id"
      :layer-style="layerStyle"
      :controllers="controllers"
    />
  </div>
</template>

<script>
import Heatmap2d from './heatmap-2d'

export default {
  components: {
    Heatmap2d
  },
  props: {
    id: {
      type: String,
      required: true
    },
    layerStyle: {
      type: Object,
      required: true
    },
    controllers: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.map-heatmaps {
  &__loader {
    padding-left: 15px;

    .el-icon-loading {
      font-size: 16px;
      margin-right: 5px;
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
    }
  }

  &__slider {
    &:not(:first-child) {
      margin-top: 8px;
    }
  }

  &__color {
    margin-top: 8px;
  }

  .el-select-dropdown__item {
    height: 36px !important;
    line-height: 36px !important;
  }
}
</style>
