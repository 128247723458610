<template>
  <r-main-panel>
    <div class="pt-view">
      <div class="pt-view__list">
        <r-tabs>
          <r-tab
            id="1"
            name="Маршруты"
            :active="panelActiveTab === 'routes'"
          >
            <div class="pt-view__container">
              <r-button
                icon="add-plus"
                type="primary"
                @click="createNewObject('lines')"
              >
                Новый маршрут
              </r-button>
              <r-search-input
                :filter-text="routesFilter"
                @change="value => changeHandler(value, 'routesFilter')"
              />
              <routes-list :routes="filteredRoutes" />
            </div>
          </r-tab>
          <r-tab
            id="2"
            name="Остановки"
          >
            <div class="pt-view__container">
              <r-button
                icon="add-plus"
                type="primary"
                @click="createNewObject('stop_points')"
              >
                Новая остановка
              </r-button>
              <r-search-input
                :filter-text="stopsFilter"
                @change="value => changeHandler(value, 'stopsFilter')"
              />
              <stops-list :stops="filteredStops" />
            </div>
          </r-tab>
        </r-tabs>
      </div>
    </div>
  </r-main-panel>
</template>

<script>
import StopsList from '@/components/public-transport/lists/stops-list'
import RoutesList from '@/components/public-transport/lists/routes-list'

export default {
  components: {
    StopsList,
    RoutesList
  },
  data() {
    return {
      stopsFilter: '',
      routesFilter: ''
    }
  },
  computed: {
    panelActiveTab: {
      get() {
        return this.$store.state.publicTransport.panelActiveTab
      },
      set(value) {
        this.$store.commit('SET_PT_FIELD', {
          field: 'panelActiveTab',
          value
        })
      }
    },
    stops() {
      const { stop_points } = this.$store.state.publicTransport.lists
      return stop_points.sort((a, b) => {
        const nameA = a.name || String(a.no)
        const nameB = b.name || String(b.no)
        return nameA.localeCompare(nameB, 'ru', {
          numeric: true
        })
      })
    },
    filteredRoutes() {
      return this.routes.filter(r =>
        r.name.toUpperCase().includes(this.routesFilter.trim().toUpperCase())
      )
    },
    filteredStops() {
      return this.stops.filter(s => {
        const name = s.name || String(s.no)

        return name
          .toUpperCase()
          .includes(this.stopsFilter.trim().toUpperCase())
      })
    },
    routes() {
      const { lines } = this.$store.state.publicTransport.lists

      return lines
        .reduce((a, c) => {
          a.push({
            id: c.id,
            name: c.name,
            stops: c.line_route_items.reduce((aa, cc) => {
              if (cc.stop_point) {
                aa.push({
                  id: cc.stop_point.id,
                  no: cc.stop_point?.no,
                  name: cc.stop_point.name
                })
              }
              return aa
            }, [])
          })
          return a
        }, [])
        .sort((a, b) =>
          a.name.localeCompare(b.name, 'ru', {
            numeric: true
          })
        )
    }
  },
  methods: {
    changeHandler(value, field) {
      this[field] = value
    },
    getProperties(type) {
      if (type === 'stop_points') {
        return {
          no: '',
          name: '',
          address: '',
          is_active: true,
          on_demand: false
        }
      } else if (type === 'lines') {
        return {
          name: '',
          color: '#B532F2',
          description: '',
          active: true,
          contractor_id: '',
          valid_from: '',
          valid_until: ''
        }
      }
    },
    createNewObject(type) {
      this.$store.commit('CLEAR_PT_CARD_INFO')
      const { ids } = this.$store.state.publicTransport.model
      const editorState = {
        id: null,
        layerId: ids[type],
        geometry: null,
        properties: {
          ...this.getProperties(type)
        },
        type
      }

      this.$store.commit('SET_PT_EDITOR_STATE', editorState)
      this.$router.push('/app/public-transport/editor/create')

      this.$store.commit('SET_PT_FIELD', {
        field: 'lineRouteId',
        value: null
      })
    }
  }
}
</script>

<style lang="scss">
.pt-view {
  height: 100%;
  overflow: hidden;
  padding: 0.5rem;
  display: grid;
  align-content: start;

  &__list {
    height: 100%;
    overflow: hidden;
  }

  &__container {
    display: grid;
    grid-gap: 0.5rem;
    height: 100%;
    overflow: hidden;
  }
}
</style>
