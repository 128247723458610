<template>
  <r-simple-card title="Построение эпюр">
    <div class="diagram-settings">
      <div class="diagram-settings__attributes">
        <r-text
          v-if="!layerStyle.diagram.field"
          type="caption"
          style="margin-bottom: 4px"
        >
          Зависит от атрибута
        </r-text>
        <source-attributes
          :single="true"
          :active-fields="layerStyle.diagram.field"
          :tree-data="getAttributes(objectFields, false, false)"
          @handleNodeClick="changeField($event)"
        />
      </div>
      <div
        v-if="layerStyle.diagram.field"
        class="diagram-settings__block"
      >
        <div
          v-if="layerStyle.diagram.timeAggregation"
          class="diagram-settings__timemachine"
        >
          <r-button
            simple
            @click="toggleTimeMachine"
          >
            Машина времени
          </r-button>
        </div>
        <div class="diagram-settings__slider">
          <r-slider
            title="Размер"
            :number="layerStyle.diagram.width"
            :min="50"
            :max="8000"
            :step="10"
            @change="changeProperty('width', $event)"
          />
        </div>
        <div class="diagram-settings__color">
          <r-color-picker
            v-if="!secondField"
            :color="layerStyle.diagram.color"
            :color-opacity="layerStyle.diagram.opacity"
            @change="changeProperty('color', $event)"
          />
          <div
            v-else
            class="diagram-settings__color-palettes"
          >
            <el-select
              v-model="currentPalette"
              class="r-select dark palette"
              placeholder=""
              size="mini"
              :popper-append-to-body="false"
              popper-class="map-color-picker__palette-popper"
              value-key="id"
              @change="changePalette"
            >
              <template slot="prefix">
                <palette-colors
                  :colors="currentPalette.value"
                  :is-current="true"
                />
              </template>
              <el-option
                v-for="item in palettes"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
                <palette-colors :colors="item.value" />
              </el-option>
            </el-select>
          </div>
          <div
            v-if="layerStyle.diagram.field"
            class="diagram-settings__attributes second"
          >
            <r-text
              type="caption"
              style="margin-bottom: 4px"
            >
              Цвет зависит от атрибута
            </r-text>
            <source-attributes
              :single="true"
              :active-fields="secondField"
              :tree-data="getAttributes(objectFields, false, false)"
              @handleNodeClick="changeSecondField($event)"
            />
          </div>
          <div class="diagram-settings__classes">
            <classifications
              :conditions="conditions"
              @apply-classes="applyClasses"
              @change-conditions="changeConditions"
            />
          </div>
        </div>
        <div class="diagram-settings__switch">
          <r-text type="caption">
            Показать эпюры в 3D
          </r-text>
          <el-switch
            v-model="layerStyle.diagram.extruded"
            :width="32"
            class="r-switch dark"
            @change="toggleDiagramExtrusion"
          />
        </div>
      </div>
    </div>
  </r-simple-card>
</template>

<script>
import SourceAttributes from '@/components/map/components/source-attributes/source-attributes'
import Classifications from '../input-sources/classifications'
import PaletteColors from '../input-sources/palette-colors'
import {
  getAttributes,
  getConditions,
  updateObjectFieldsConfigs
} from '@/utils'
import { palettes } from '../config'

export default {
  components: {
    SourceAttributes,
    Classifications,
    PaletteColors
  },
  props: {
    id: {
      type: String,
      required: true
    },
    layerStyle: {
      type: Object,
      required: true
    },
    controllers: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      objectFields: this.$clientStore.getDataFields(
        this.$store.state.profiles.sourceIdById[this.id]
      ),
      conditions: this.layerStyle.diagram.conditions,
      secondField: this.layerStyle.diagram.secondField,
      currentPalette: this.layerStyle.diagram.currentPalette,
      timeAggregation: this.layerStyle.diagram.timeAggregation,
      palettes
    }
  },
  watch: {
    id: function() {
      this.conditions = this.layerStyle.diagram.conditions
      this.secondField = this.layerStyle.diagram.secondField
      this.currentPalette = this.layerStyle.diagram.currentPalette
    }
  },
  methods: {
    getAttributes,
    async changeField({ path, config, object }) {
      if (this.layerStyle.diagram.field === path) {
        this.secondField = ''
        this.currentPalette = {}
        this.conditions = []
        this.timeAggregation = null
        this.setStoreClassesValue()
      }
      this.controllers.diagram.toggleDiagram(this.id, { path, config })

      if (object) {
        const { timeAggregation } = object

        if (timeAggregation) {
          this.timeAggregation = timeAggregation
          this.$set(this.layerStyle.diagram, 'timeAggregation', timeAggregation)
          this.toggleTimeMachine()
        }
      }
    },
    toggleTimeMachine() {
      const { map } = this.controllers.layers
      const { enabled } = map.timemachineState

      if (!enabled) {
        const { timeAggregation } = this.layerStyle.diagram
        const { min, max, sourceId, sourceName, field } = timeAggregation

        map.changeTimemachineState({
          id: Date.now(),
          layerId: this.id,
          allCount: map.objectsCounts[this.id],
          range: [min, max],
          chunks: [],
          field,
          min,
          max,
          sourceName,
          sourceId
        })
      } else {
        map.closeTimemachine()
      }
    },
    async changeSecondField({ path, config }) {
      if (path === this.secondField) {
        this.secondField = ''
        this.conditions = []
        this.currentPalette = {}
        this.changeProperty('color')
        this.setStoreClassesValue()
      } else {
        this.secondField = path
        updateObjectFieldsConfigs(
          this.controllers.layers.map,
          this.id,
          path,
          config
        )
        await this.controllers.layers.updateLayerData(this.id, [
          this.secondField
        ])
        if (!this.currentPalette.id) this.currentPalette = palettes[0]
        this.setClassesPalette(this.id, this.currentPalette)
        this.applyClasses()
      }
    },
    toggleDiagramExtrusion() {
      this.controllers.diagram.toggleDiagramExtrusion(this.id)
    },
    changeProperty(property, value) {
      if (property === 'width') {
        this.layerStyle.diagram[property] = value

        this.controllers.diagram.changeDiagramProperty(this.id, 'width')
      } else {
        if (value) {
          const { color, opacity } = value

          this.layerStyle.diagram.color = color
          this.layerStyle.diagram.opacity = opacity
        }

        this.controllers.diagram.changeDiagramProperty(this.id, 'color')
        this.controllers.diagram.changeDiagramProperty(this.id, 'opacity')
      }
    },
    changePalette() {
      this.setClassesPalette()
      this.applyClasses()
    },
    setClassesPalette() {
      this.conditions = getConditions(
        this.id,
        this.currentPalette,
        this.secondField,
        this
      )
    },
    setStoreClassesValue() {
      this.$store.commit('SET_DIAGRAM_CLASSES', {
        id: this.id,
        config: {
          secondField: this.secondField,
          conditions: this.conditions,
          currentPalette: this.currentPalette,
          timeAggregation: this.timeAggregation
        }
      })
    },
    applyClasses() {
      this.setStoreClassesValue()
      this.controllers.diagram.changeDiagramClasses(this.id)
    },
    changeConditions(conditions) {
      this.$set(this, 'conditions', conditions)
    }
  }
}
</script>

<style lang="scss">
.diagram-settings {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 5px;
    line-height: 24px;
  }

  &__container {
    padding-left: 10px;
  }

  &__attributes {
    &.second {
      margin-top: 8px;

      .diagram-settings__attributes-title {
        font-size: 11px;
        color: var(--text_secondary) !important;
      }
    }
  }

  &__timemachine {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;

    .r-button {
      padding: 0;
      font-size: 11px;
    }
  }

  &__block {
    margin-top: 8px;
  }

  &__slider {
    margin-top: 16px;
  }

  &__switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
  }

  &__color {
    margin-top: 16px;
  }

  &__color-palettes {
    .el-select-dropdown__item {
      height: 36px !important;
      line-height: 36px !important;
    }
  }
}
</style>
