<template>
  <div class="files__upload">
    <el-upload
      multiple
      drag
      :data="postFileData"
      :on-success="onSuccessUpload"
      :action="`${url}/upload_resource`"
      :show-file-list="false"
      :headers="headers"
      :before-upload="beforeUpload"
    >
      <div class="el-upload__wrapper">
        <r-icon
          :size="30"
          name="upload"
          color="var(--accent_primary)"
        />
        <r-text
          color-type="accent-primary"
          class="el-upload__link"
        >
          {{ $t('files:to-attach') }}
        </r-text>
        <r-text
          type="caption"
          class="el-upload__caption"
        >
          {{ $t('files:upload-desc') }}
        </r-text>
      </div>
    </el-upload>
  </div>
</template>

<script>
import constants from '@/constants/url'
import { getHeaders } from '@/utils'

export default {
  props: {
    source_id: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      url: constants.URL
    }
  },
  computed: {
    headers() {
      return getHeaders()
    },
    postFileData() {
      return {
        source_id: this.source_id,
        obj_id: this.id
      }
    }
  },
  methods: {
    beforeUpload() {
      this.$emit('loading', ['files', true])
      return true
    },
    onSuccessUpload() {
      this.$emit('load')
    }
  }
}
</script>

<style lang="scss">
.files-comments {
  &__files {
    &__upload {
      height: 56px;
      width: 100%;
      display: flex;

      & > div {
        flex: 1;
        padding-right: 0.5rem;
      }
    }

    .el-upload {
      width: 100%;

      &-dragger {
        background-color: transparent !important;
        border: 1px dashed var(--dividers_low_contrast) !important;
        border-radius: 4px !important;
        height: 48px !important;
        width: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: border 0.15s ease;

        &:hover {
          border: 1px dashed var(--accent_primary) !important;
        }
      }

      &-list {
        max-height: 100px;
        max-width: 220px;
        overflow-y: auto !important;
        overflow-x: hidden !important;
      }

      &__caption {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        grid-area: caption;
        padding-right: 0.25rem;
      }

      &__link {
        text-align: left;
        grid-area: default;
      }

      &__wrapper {
        display: grid;
        grid-template-areas: 'icon link' 'icon caption';
        grid-template-columns: 30px auto;
        grid-column-gap: 0.5rem;
        padding: 0.5rem;
        justify-content: start;
        align-items: center;

        .r-icon {
          grid-area: icon;
          width: 30px;
        }
      }
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "files:to-attach": "Прикрепить файл",
    "files:upload-desc": "Перетяните файл в это поле или выберите вручную"
  },
  "en": {
    "files:to-attach": "Attach file",
    "files:upload-desc": "Drag the file to this field or select manually"
  }
}
</i18n>
