import { Constants } from '@/libs/map-draw/lib-utils'

export const createDrawNodesMode = (MapboxDraw, editor, component) => {
  const DrawNodes = {}

  DrawNodes.onTap = DrawNodes.onClick = function(state, e) {
    this.updateUIClasses({ mouse: Constants.cursors.MOVE })
    state.point.updateCoordinate('', e.lngLat.lng, e.lngLat.lat)

    // attributes editing helper
    const { withAttributes } = editor
    if (withAttributes) {
      editor.creatingCallback = () => {
        this.map.fire(Constants.events.CREATE, {
          features: [state.point.toGeoJSON()]
        })
        this.changeMode(Constants.modes.SIMPLE_SELECT, {
          featureIds: [state.point.id]
        })
      }
      editor.creatingObject = state.point
      component.toggleCreateModal(true)

      return
    }

    this.map.fire(Constants.events.CREATE, {
      features: [state.point.toGeoJSON()]
    })
    this.changeMode(Constants.modes.SIMPLE_SELECT, {
      featureIds: [state.point.id]
    })
  }

  return { ...MapboxDraw.modes.draw_point, ...DrawNodes }
}
