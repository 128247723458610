import { reportOptions } from '../configs'
import { getTableData as GTB } from '../helpers'

export class BddReport {
  constructor(state) {
    this.$store = state.$store
    this.state = state

    this.options = reportOptions.bdd_report
  }

  getTableData(data) {
    return GTB(data, this.options)
  }
}
