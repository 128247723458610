<template>
  <div class="rp-attr-filter-content">
    <rp-attr-filter-content-filter />
    <rp-attr-filter-content-tabs />
  </div>
</template>

<script>
import rpAttrFilterContentFilter from './rp-attr-filter-content-filter'
import rpAttrFilterContentTabs from './rp-attr-filter-content-tabs'

export default {
  components: {
    rpAttrFilterContentFilter,
    rpAttrFilterContentTabs
  }
}
</script>

<style lang="scss">
.rp-attr-filter-content {
  position: relative;
  width: 100%;
  display: flex;
  overflow: hidden;
  flex: 1;
  margin-top: 16px;
}
</style>
