import { rDate, notifyFactory } from '@/utils'

export function errorParser(e, message = null, title = null) {
  let errorCode
  if (e.response) errorCode = e.response.data.no || 500

  console.log('errorParser:', 'e', e.response.data, 'message', message, 'errorCode', errorCode)

  const errorCodeText = `${this.$t('error:title')}№${errorCode}`
  let isKnownError

  if (errorCode >= 1000 && errorCode <= 1084) isKnownError = errorCode
  let errorMessage
  let errorTitle

  errorTitle = title || errorCodeText

  if (message && errorCode) {
    errorMessage = `${message} ${errorCodeText}`
  } else if (isKnownError && errorCode) {
    switch (isKnownError) {
      case 1009:
        errorMessage = e.response.data.body
          ? `${this.$t('error:' + errorCode)}${e.response.data.body.source_id}`
          : `${this.$t('error:' + errorCode)}`
        break
      case 1052:
        if (e.response.data.body) {
          const expirationTime = rDate.format(e.response.data.body.time)
          errorMessage = `${this.$t('error:' + errorCode)}${expirationTime}`
        } else {
          errorMessage = `${this.$t('error:' + errorCode)}`
        }
        break
      case 1073:
        errorMessage = e.response.data.body
          ? `${this.$t('error:' + errorCode)}${e.response.data.body.source_id}`
          : `${this.$t('error:' + errorCode)}`
        break
      case 1074:
      case 1075:
        errorMessage = e.response.data.body
          ? `${e.response.data.body.msg}`
          : `${this.$t('error:default')}`
        break
      default:
        errorMessage = `${this.$t('error:' + errorCode)}`
        break
    }
  } else {
    errorTitle = errorCodeText
    errorMessage = e.response.data.error || `${this.$t('error:default')}`
  }

  this.$notify(notifyFactory('error', errorTitle, errorMessage))
}
