export const etranAttributesConfig = [
  {
    id: 'carriage_no',
    prop: 'carriage_no',
    title: '№ вагона '
  },
  {
    id: 'contractor',
    prop: 'contractor',
    nestedProp: 'cargoes',
    title: 'Поставщик'
  },
  {
    id: 'current_station',
    prop: 'current_station',
    title: 'Местоположение'
  },
  {
    id: 'receiving_date',
    prop: 'delivery_forecast',
    type: 'date',
    title: 'Прогнозная дата прибытия '
  }
]

export const vehicleAttributesConfig = [
  {
    id: 'reg_number',
    prop: 'reg_number',
    title: '№ ТС'
  },
  {
    id: 'contractor',
    prop: 'contractor',
    nestedProp: 'cargoes',
    title: 'Поставщик'
  },
  {
    id: 'nearest_settlement',
    prop: 'nearest_settlement',
    title: 'Местоположение'
  },
  {
    id: 'receiving_date',
    prop: 'delivery_forecast',
    type: 'date',
    title: 'Прогнозная дата прибытия '
  }
]

export const bargesAttributesConfig = [
  {
    id: 'number',
    prop: 'number',
    title: 'Номер баржи'
  },
  {
    id: 'contractor',
    prop: 'contractor',
    nestedProp: 'cargoes',
    title: 'Поставщик'
  },
  {
    id: 'receiving_date',
    prop: 'delivery_forecast',
    type: 'date',
    title: 'Прогнозная дата прибытия '
  }
]
