<template>
  <div class="map-editor">
    <div class="map-editor__container">
      <editor-layer-header :state="state" />
      <div class="map-editor__subcontainer">
        <div class="map-editor__controls">
          <r-button
            class="map-editor__controls-button"
            type="primary"
            @click="closeButtonHandler(null)"
          >
            {{
              state.history.length ? 'Сохранить и выйти' : 'Выйти из редактора'
            }}
          </r-button>
          <r-button
            v-if="state.history.length"
            class="map-editor__controls-button"
            :loading="state.loading"
            type="success"
            @click="saveData"
          >
            Сохранить изменения
          </r-button>
          <r-button
            v-if="state.history.length"
            class="map-editor__controls-button"
            size="mini"
            @click="clearEditingState"
          >
            Отменить изменения
          </r-button>
        </div>
        <editor-mode-switcher
          :state="state"
          @toggle-editor-mode="toggleEditorMode"
        />
        <editor-model-controls
          :state="state"
          @toggle-editing-model-layer="toggleEditingModelLayer"
          @toggle-visible-model-layer="toggleVisibleModelLayer"
          @toggle-editing-link-separation="toggleEditingLinkSeparation"
        />

        <editor-history
          :state="state"
          @change-history-step="changeHistoryStep"
          @clear-editing-state="clearEditingState"
        />
      </div>
    </div>
    <editor-help :state="state" />
  </div>
</template>

<script>
import EditorLayerHeader from './editor-layer-header'
import EditorModeSwitcher from './editor-mode-switcher'
import EditorModelControls from './editor-model-controls'
import EditorHistory from './editor-history'
import EditorHelp from './editor-help'

export default {
  components: {
    EditorLayerHeader,
    EditorModeSwitcher,
    EditorModelControls,
    EditorHistory,
    EditorHelp
  },
  props: {
    map: {
      type: Object,
      required: true
    },
    draw: {
      type: Object,
      required: true
    },
    state: {
      type: Object,
      required: true
    },
    controllers: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      deleteModal: false
    }
  },
  methods: {
    // editor helpers
    closeButtonHandler(data) {
      this.controllers.editor.closeButtonHandler(data)
    },
    toggleEditorMode(mode) {
      this.controllers.editor.toggleEditorMode(mode)
    },
    changeHistoryStep(type) {
      this.controllers.editor.changeHistoryStep(type)
    },
    clearEditingState() {
      this.controllers.editor.clearEditingState()
    },
    saveData() {
      this.controllers.editor.saveData()
    },
    // editor model handlers
    toggleEditingModelLayer(id) {
      this.controllers.editor.toggleEditingModelLayer(id)
    },
    toggleEditingLinkSeparation(val) {
      this.controllers.editor.toggleEditingLinkSeparation(val)
    },
    toggleVisibleModelLayer(id) {
      this.controllers.editor.toggleVisibleModelLayer(id)
    }
  }
}
</script>

<style lang="scss">
.map-editor {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__subcontainer {
    padding: 8px;
  }

  &__controls {
    margin-bottom: 16px;

    &-button {
      display: block;
      width: 100%;
      margin: 0 !important;

      &:not(:first-child) {
        margin-top: 10px !important;
      }
    }
  }
}
</style>
