import { rDate } from '@/utils'
import cloneDeep from 'lodash.clonedeep'

const colors = {
  true: '#59D64E',
  false: '#FF3E3E',
  all: '#2E93FE',
  stroimekhanizatsiya: '#e41a1c',
  stroytech: '#377eb8',
  ural_syberian_company: '#4daf4a',
  stroi_servis: '#a65628',
  1: '#377eb8',
  2: '#59D64E',
  3: '#ffac00',
  4: '#B33EA9'
}

export const setChartStyle = (state, variables) => {
  const colors = {}

  colors.bg = variables['--bg_panel_primary']
  colors.text = variables['--text_secondary']

  state.chartOption.textStyle = { color: colors.text }
  if (state.chartOption.legend) {
    state.chartOption.legend.textStyle = {
      color: colors.text,
      fontSize: 10
    }
  }

  if (state.chartOption.series[0].itemStyle && state.type === 'type-4') {
    state.chartOption.series[0].itemStyle = {
      borderWidth: 3,
      borderColor: colors.bg
    }
  }
}

export const buildChart = state => {
  let newData

  switch (state.chartType) {
    case 'type-1':
      newData = cloneDeep(state.basic)
      break
    case 'type-2':
      newData = cloneDeep(state.bar)
      break
    case 'type-3':
      newData = cloneDeep(state.barX)
      break
    case 'type-4':
      newData = cloneDeep(state.pie)
      break
    default:
      newData = cloneDeep(state.basic)
      break
  }

  if (newData.series[0]) {
    newData.series[0].name = state.title
    newData.series[0].data = state.chartData
  }
  if (state.colors) newData.color = state.colors
  state.chartOption = cloneDeep(newData)

  state.setColorTheme()
}

export const prepareChartData = (initialData, type) => {
  const cloneData = cloneDeep(initialData).reverse()
  const dataOptions = dataTypes[type]
  if (!dataOptions) return
  const data = []
  const xAxis = { data: [] }
  for (let i = 0; i < cloneData.length; i++) {
    if (cloneData[i]) {
      xAxis.data.unshift(
        reformatTime(
          rDate
            .zone(-5)
            .format(cloneData[i].to, 'MM.DD.YY HH:mm')
        )
      )
    }
  }
  dataOptions.forEach(e => {
    const values = []
    for (let i = 0; i < cloneData.length; i++) {
      if (cloneData[i]) {
        if (cloneData[i][e]) {
          values.unshift(cloneData[i][e])
        } else {
          values.unshift(0)
        }
      }
    }
    data.push({ dataTitle: e, color: colors[e], data: values })
  })

  return { data, xAxis }
}

const dataTypes = {
  default: ['false', 'true']
}

const reformatTime = time => {
  const parseTime = time.split(':')
  const delta = parseTime[1] % 15
  const minutes = parseTime[1] - delta

  return `${parseTime[0]}:${minutes >= 10 ? minutes : `0${minutes}`}`
}

export const historyRequest = async(state, request) => {
  const { url } = request

  try {
    const config = getHistoryConfig(state)
    const { data } = await state.$store.dispatch('GET_REQUEST', {
      url: `${url}${config}`
    })

    state.isLoading = false
    return data
  } catch (e) {
    console.log(e)
  }
}

const getHistoryConfig = state => {
  const from = state.currentHistoryConfig.from
  const to = state.currentHistoryConfig.to
  const step = state.currentHistoryConfig.step

  return `&from=${from}&to=${to}&step=${step}`
}

export const setChartOptions = state => {
  switch (state.type) {
    case 'type-1':
      setBasicChart(state)
      break
    case 'type-2':
      setBarChart(state)
      break
    case 'type-3':
      setBarXChart(state)
      break
    case 'type-4':
      setPieChart(state)
      break
  }
}

const setBasicChart = state => {
  if (state.chartData && state.chartData.data) {
    const chartLegend = state.chartData.data.map(e => state.$t(e.dataTitle))
    const chartData = state.chartData.data.map(e => {
      return {
        name: state.$t(e.dataTitle),
        type: 'line',
        stack: 'one',
        areaStyle: {
          color: e.color,
          opacity: 0.6
        },
        lineStyle: {
          width: 1,
          color: e.color
        },
        itemStyle: {
          color: e.color,
          borderColor: e.color
        },
        smooth: true,
        data: e.data,
        symbol: 'none',
        showSymbol: false,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: e.color
            }
          }
        }
      }
    })

    const chartOptions = cloneDeep(state.basic)

    chartOptions.legend.data = chartLegend
    chartOptions.series = chartData
    chartOptions.xAxis[0].data = state.chartData.xAxis.data
    state.chartOption = cloneDeep(chartOptions)
  } else {
    state.chartOption = cloneDeep(state.basic)
  }
}

const setBarChart = state => {
  if (state.chartData && state.chartData.data) {
    const chartLegend = state.chartData.data.map(e => state.$t(e.dataTitle))
    const chartData = state.chartData.data.map(e => {
      return {
        name: state.$t(e.dataTitle),
        type: 'bar',
        stack: 'first',
        data: e.data,
        color: e.color
      }
    })

    const chartOptions = cloneDeep(state.bar)

    chartOptions.legend.data = chartLegend
    chartOptions.series = chartData
    chartOptions.xAxis.data = state.chartData.xAxis.data
    state.chartOption = cloneDeep(chartOptions)
  } else {
    state.chartOption = cloneDeep(state.bar)
  }
}

const setBarXChart = state => {
  if (state.chartData && state.chartData.data) {
    const chartLegend = state.chartData.data.map(e => state.$t(e.dataTitle))
    const chartData = state.chartData.data.map(e => {
      return {
        name: state.$t(e.dataTitle),
        type: 'bar',
        stack: 'first',
        data: e.data,
        color: e.color
      }
    })

    const chartOptions = cloneDeep(state.barX)

    chartOptions.legend.data = chartLegend
    chartOptions.series = chartData
    chartOptions.yAxis.data = state.chartData.xAxis.data
    state.chartOption = cloneDeep(chartOptions)
  } else {
    state.chartOption = cloneDeep(state.barX)
  }
}

const setPieChart = state => {
  if (state.chartData && state.chartData.data) {
    const chartLegend = state.chartData.data.map(e => state.$t(e.dataTitle))
    const chartData = state.chartData.data.map(e => {
      return {
        name: state.$t(e.dataTitle),
        value: e.data.reduce(function(sum, current) {
          return sum + current
        }, 0)
      }
    })

    const chartOptions = cloneDeep(state.pie)

    chartOptions.legend.data = chartLegend
    chartOptions.series[0].data = chartData
    state.chartOption = cloneDeep(chartOptions)
  } else {
    state.chartOption = cloneDeep(state.pie)
  }
}
