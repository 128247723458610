<template>
  <r-toggle-card
    title="Иконка"
    :is-open="layerStyle.icon.enabled"
    @toggle="toggleIcon"
  >
    <div class="point-icon-picker">
      <div class="point-icon-picker__image-select">
        <el-select
          v-model="layerStyle.icon.name"
          class="r-select dark"
          size="default"
          :popper-append-to-body="false"
          popper-class="point-icon-picker__popper"
          @change="changeIconImage"
        >
          <template slot="prefix">
            <img
              class="point-icon-picker__popper-image"
              :src="iconsContext[layerStyle.icon.name]"
              alt="no-data"
            >
          </template>
          <el-option
            v-for="item in sortedIcons"
            :key="item.name"
            :label="item.label"
            :value="item.name"
          >
            <img
              class="point-icon-picker__popper-image"
              :src="iconsContext[item.name]"
              alt="no-data"
            >
          </el-option>
        </el-select>
      </div>
      <div class="point-icon-picker__size">
        <r-slider
          title="Размер"
          :number="layerStyle.icon.size"
          :min="8"
          :max="64"
          :step="2"
          @change="changeIconSize"
        />
      </div>
    </div>
  </r-toggle-card>
</template>

<script>
import constants from '@/constants/url'
import { icons } from '@/config/icons'

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    layerStyle: {
      type: Object,
      required: true
    },
    controllers: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      baseURL: constants.URL,
      downloadURL: 'download_share_resource',
      iconsContext: this.$store.state.map.iconsContext,
      icons
    }
  },
  computed: {
    sortedIcons() {
      const sortedIcons = this.icons

      sortedIcons.sort((a, b) => {
        const first = a.name.toLowerCase()
        const second = b.name.toLowerCase()

        if (first > second) return 1
        if (first < second) return -1

        return 0
      })

      return sortedIcons
    }
  },
  created() {
    if (!this.layerStyle.icon) {
      this.$set(this.layerStyle, 'icon', {
        enabled: false,
        name: '1.5_Russian_road_sign.svg',
        size: 16
      })
    }
  },
  methods: {
    toggleIcon(value) {
      this.layerStyle.icon.enabled = value

      this.controllers.layers.toggleLayerIcon(this.id)
    },
    changeIconSize(value) {
      this.layerStyle.icon.size = value

      this.controllers.layers.changeIconSize(this.id)
    },
    changeIconImage() {
      this.controllers.layers.changeIconImage(this.id)
    }
  }
}
</script>

<style lang="scss">
.point-icon-picker {
  &__image-select {
    margin-bottom: 12px;

    .el-select {
      width: 100%;

      .el-input {
        &__inner {
          font-size: 12px;
          padding-left: 40px !important;
        }

        &__prefix {
          width: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  &__popper {
    left: 0 !important;

    &-image {
      width: 24px;
      height: 24px;
    }

    .el-select-dropdown {
      &__list {
        display: flex;
        flex-wrap: wrap;
        padding: 8px;
      }

      &__item {
        flex-basis: 0 0 calc(100% / 5);
        width: 20%;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 38px;
        height: 38px;
        border-radius: var(--border-radius);
      }
    }
  }
}
</style>
