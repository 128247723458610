<template>
  <div class="create-object">
    <div
      v-if="!attributes.length"
      class="map-editor-create-modal__nodata"
    >
      {{ $t('create-object:no-attributes') }}
    </div>
    <div v-else>
      <div class="create-object__head">
        <r-text>
          {{ $t('create-object:title') }}
        </r-text>
      </div>
      <ul class="create-object__list">
        <li
          v-for="(e, i) in attributes"
          :key="i"
          class="create-object__item"
        >
          <r-text>{{ e.name }}</r-text>
          <el-input
            v-if="e.type === 'string'"
            v-model="e.value"
            class="r-input"
            size="mini"
            placeholder="-"
          />
          <el-input-number
            v-else
            v-model="e.value"
            class="r-input number"
            :controls="false"
            size="mini"
            type="number"
          />
        </li>
      </ul>
      <div class="create-object__controls">
        <r-button
          simple
          @click="closeWindow"
        >
          {{ $t('create-object:cancel') }}
        </r-button>
        <r-button
          type="primary"
          @click="createObject"
        >
          {{ $t('create-object:apply') }}
        </r-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getEditableObjectFields, errorParser, notifyFactory } from '@/utils'

export default {
  data() {
    return {
      attributes: []
    }
  },
  computed: {
    sourceId() {
      return this.$store.state.createObject.source_id
    },
    isValid() {
      return !!this.attributes.find(e => e.value)
    }
  },
  watch: {
    isValid(val) {
      this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', val)
    }
  },
  created() {
    this.getAttributes()
  },
  beforeDestroy() {
    this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', false)
  },
  methods: {
    async getAttributes() {
      const { data } = await this.$store.dispatch('GET_REQUEST', {
        url: `objectFields/${this.sourceId}?`
      })

      this.attributes = getEditableObjectFields(data).map(({ type, name }) => ({
        type,
        name,
        value: null
      }))
    },
    createObject() {
      const objectData = {}

      this.attributes.forEach(e => (objectData[e.name] = e.value))
      this.$store
        .dispatch('POST_REQUEST', {
          url: `objectInfo/${this.sourceId}`,
          data: objectData
        })
        .then(() => {
          const title = this.$t('create-object:notify-success')
          const message = this.$t('create-object:notify-success-text')
          this.$notify(notifyFactory('success', title, message))
          this.$store.commit('UPDATE_ACTIVE_TABLE')
          this.$store.commit('CLOSE_MODAL_WINDOW', '')
        })
        .catch(e => {
          const title = this.$t('create-object:notify-error')
          const message = this.$t('create-object:notify-error-text')
          errorParser.call(this, e, message, title)
        })
    },
    closeWindow() {
      this.$store.commit('CLOSE_MODAL_WINDOW', '')
    }
  }
}
</script>

<style lang="scss">
.create-object {
  max-height: calc(50vh - 100px);
  min-height: 160px;
  position: relative;
  display: flex;
  flex-direction: column;
  &__head {
    padding-bottom: 16px;
  }
  &__list {
    max-height: calc(50vh - 188px);
    min-height: 68px;
    position: relative;
    overflow: hidden;
    overflow-y: auto;
  }
  &__item {
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    .r-input,
    .r-text {
      width: 50%;
    }
    input {
      text-align: center !important;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
  &__controls {
    padding-top: 16px;
    text-align: right;
  }
}
</style>

<i18n>
{
  "ru": {
    "create-object:title": "Введите значение атрибутов",
    "create-object:no-attributes": "У данного источника данных отсутствуют атрибуты для заполнения",

    "create-object:cancel": "Отменить",
    "create-object:apply": "Добавить",

    "create-object:notify-success": "Создание выполнено",
    "create-object:notify-error": "Ошибка",
    "create-object:notify-success-text": "Создание объекта выполнено успешно",
    "create-object:notify-error-text": "Создание объекта не выполнено. Повторите попытку"
  },
  "en": {
    "create-object:title": "Enter attributes value",
    "create-object:no-attributes": "This data source has no attributes to filling.",

    "create-object:cancel": "Cancel",
    "create-object:apply": "Apply",

    "create-object:notify-success": "Done",
    "create-object:notify-error": "Error",
    "create-object:notify-success-text": "Object creation successful",
    "create-object:notify-error-text": "Object creation failed. Try again"
  }
}
</i18n>
