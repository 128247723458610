<template>
  <div class="r-table-search">
    <el-dropdown
      class="r-dropdown"
      :hide-on-click="false"
      placement="bottom-start"
      trigger="click"
      @command="clickHandler"
    >
      <r-button
        simple
        :disabled="!list || !list.length"
        dropdown
        uppercase
        bold
      >
        {{ fieldTitle }}
      </r-button>
      <el-dropdown-menu
        slot="dropdown"
        class="r-dropdown-menu"
      >
        <el-dropdown-item
          v-for="item in list"
          :key="item.id"
          :command="item.value"
        >
          <div class="r-dropdown-menu__item-wrapper">
            <el-radio
              class="r-radio"
              :value="field"
              :label="item.value"
            >
              <r-text>{{ item.alias }}</r-text>
            </el-radio>
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div class="r-table-search__input-block">
      <el-input
        v-model="value"
        :disabled="!list || !list.length"
        class="r-input"
        :placeholder="placeholderValue"
        @blur="handleEvent('blur')"
        @focus="handleEvent('focus')"
      />
      <r-icon
        v-if="value"
        name="clear-input"
        :size="20"
        @click.native="clearSearch"
      />
      <r-button
        v-if="value"
        icon="search"
        @click="startSearch"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filterText: {
      type: String,
      required: true
    },
    filterField: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: null
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      field: '',
      value: ''
    }
  },
  computed: {
    placeholderValue() {
      return this.placeholder || 'Поиск'
    },
    fieldTitle() {
      const active = this.list?.find(e => e.value === this.field)

      if (!active) return 'выбрать'

      return active.alias || active.title || 'ГРЗ'
    }
  },
  created() {
    this.field = this.filterField || this.list[0]?.value || 'ГРЗ'
    this.value = this.filterText || ''
  },
  methods: {
    handleEvent(event) {
      this.$emit('handle-event', event)
    },
    clickHandler(val) {
      this.field = val
    },
    startSearch() {
      const active = this.list?.find(e => e.value === this.field)
      let searchField = this.field

      if (active?.reflection) {
        searchField = `${this.field}.${active.key || active.reflection?.default_show_attribute}`
      }

      this.$emit('start-search', {
        filterField: searchField,
        filterText: this.value
      })
    },
    clearSearch() {
      this.value = ''
      this.$emit('start-search', {
        filterField: null,
        filterText: null
      })
    }
  }
}
</script>

<style lang="scss">
.r-table-search {
  position: relative;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  border-bottom: 1px solid;
  border-color: var(--dividers_low_contrast) !important;
  grid-template-columns: 1fr auto;

  &__input-block {
    width: 268px;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    align-items: center;
  }

  .r-input {
    position: relative;
    width: auto !important;

    .el-input__inner {
      background-color: transparent !important;
      border: none !important;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 4px;
      width: 1px;
      height: calc(100% - 8px);
      background-color: var(--dividers_low_contrast);
    }
  }
}

.el-dropdown-menu {
  .el-radio {
    display: flex;
    align-items: center;
  }
}
</style>
