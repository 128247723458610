<template>
  <div class="rp-attr-filter">
    <rp-attr-filter-header
      :item="roleDatasource"
      :ds-length="dsLength"
    />
    <rp-attr-filter-content />
  </div>
</template>

<script>
import rpAttrFilterHeader from './rp-attr-filter-header'
import rpAttrFilterContent from './rp-attr-filter-content'

export default {
  components: {
    rpAttrFilterHeader,
    rpAttrFilterContent
  },
  data() {
    return {
      isLoading: false,
      dsLength: 0
    }
  },
  computed: {
    roleAttrFilterId() {
      return this.$store.state.rolePermission.roleAttrFilterId || null
    },
    roleDatasource() {
      const datasources = this.$store.state.rolePermission.rolePerms?.datasources || null

      if (!datasources?.length) return

      return datasources.find(e => e.id === this.roleAttrFilterId) || null
    }
  },
  created() {
    this.getDsLength()
    this.$store.commit('ROLE_PERM_SET_FIELD', {
      field: 'selectedObjectChanged',
      value: false
    })
  },
  methods: {
    async getDsLength() {
      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `aggregation/${this.roleAttrFilterId}`
        })

        this.dsLength = data?.metadata?.count
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>

<style lang="scss">
.rp-attr-filter {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  height: calc(100vh - 240px);
}
</style>
