import { HexagonLayer } from '@deck.gl/aggregation-layers'
import { MapboxLayer } from '@deck.gl/mapbox'

import { createHeatmapColorValue } from '../helpers'

export const convertRGBtoArray = rgb => {
  return rgb
    .replace(/[^\d,.]/g, '')
    .split(',')
    .map(c => parseFloat(c))
    .slice(0, 3)
}

export class HeatmapController {
  constructor(map) {
    this.map = map
    this.mapgl = map.mapgl
    this.styleConfig = map.styleConfig
  }

  changeHeatmapPropery(id, property) {
    const layerId = `${id}_heatmap`
    const config = this.styleConfig[id].heatmap
    const { opacity, conditions } = config
    const mapLayer = this.mapgl.getLayer(layerId)
    if (!mapLayer) return

    switch (property) {
      case 'opacity':
        this.mapgl.setPaintProperty(layerId, 'heatmap-opacity', opacity)
        break
      case 'color':
        this.mapgl.setPaintProperty(
          layerId,
          'heatmap-color',
          createHeatmapColorValue('heatmap-density', conditions)
        )
    }
  }

  changeHeatmap3dProperty(id, property) {
    const layerId = `${id}_hexagon`
    const config = this.styleConfig[id].hexagon
    const { conditions } = config
    const mapLayer = this.mapgl.getLayer(layerId)
    if (!mapLayer) return

    switch (property) {
      case 'color':
        mapLayer.implementation.setProps({
          colorRange: conditions.map(c => convertRGBtoArray(c.color))
        })
        break
      case 'maxElevation':
        mapLayer.implementation.setProps({
          elevationRange: [0, config[property]]
        })
        break
      default:
        mapLayer.implementation.setProps({
          [property]: config[property]
        })
    }
  }

  getHexagonLayer(id, data) {
    const layerId = `${id}_hexagon`
    const config = this.styleConfig[id].hexagon
    const {
      maxElevation,
      conditions,
      opacity,
      coverage,
      radius,
      elevationScale,
      upperPercentile
    } = config
    const lightSettings = {
      lightsPosition: [-0.144528, 49.739968, 8000, -3.807751, 54.104682, 8000],
      ambientRatio: 0.4,
      diffuseRatio: 0.6,
      specularRatio: 0.2,
      lightsStrength: [0.6, 0.0, 0.6, 0.0],
      numberOfLights: 2
    }

    return new MapboxLayer({
      id: layerId,
      data,
      type: HexagonLayer,
      elevationRange: [0, maxElevation],
      getPosition: d => d,
      lightSettings,
      extruded: true,
      colorRange: conditions.map(c => convertRGBtoArray(c.color)),
      elevationScale,
      opacity,
      radius,
      coverage,
      upperPercentile
    })
  }
}
