<template>
  <div class="r-modal-card__controls">
    <div
      v-if="isEditing"
      class="r-modal-card__controls r-modal-card__controls--edit-mode"
    >
      <r-button
        type="success"
        @click="$emit('clickHandler', 'handle-application')"
      >
        Сохранить
      </r-button>
      <r-button
        simple
        @click="$emit('clickHandler', 'discard-changes')"
      >
        Отменить все изменения
      </r-button>
    </div>
    <div
      v-else
      class="r-modal-card__controls r-modal-card__controls--view-mode"
    >
      <r-button
        type="primary"
        @click="$emit('clickHandler', 'handle-application')"
      >
        Редактировать
      </r-button>
      <r-delete-button
        mini
        @delete="$emit('clickHandler', 'delete')"
      />
      <r-button
        v-if="archive"
        icon="send-archive"
        @click="$emit('clickHandler', 'archive')"
      >
        Отправить в архив
      </r-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    canCreatePass: {
      type: Boolean,
      required: true
    },
    isEditing: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    archive: {
      type: Boolean,
      default: false
    }
  }
}
</script>
