<template>
  <div class="rp-sidebar-content">
    <el-tabs
      v-model="activeTab"
      class="r-tabs"
      stretch
    >
      <el-tab-pane
        v-for="tab in sidebarTabsList"
        :key="tab.id"
        :name="tab.value"
      >
        <r-text
          slot="label"
          class="r-tabs__label"
        >
          {{ tab.name }}
        </r-text>
      </el-tab-pane>
    </el-tabs>
    <component
      :is="`rp-sidebar-${activeTab}`"
      v-if="activeTab"
    />
  </div>
</template>

<script>
import rpSidebarUsers from './rp-sidebar-users/rp-sidebar-users.vue'
import rpSidebarRoles from './rp-sidebar-roles/rp-sidebar-roles.vue'
import { sidebarTabsList } from '../../configs'

export default {
  components: {
    rpSidebarUsers,
    rpSidebarRoles
  },
  data() {
    return {
      activeTab: '',
      activeModule: this.$store.state.rolePermission.activeModule,
      sidebarTabsList
    }
  },
  watch: {
    activeTab: {
      handler: function(value) {
        this.$store.commit('ROLE_PERM_SET_FIELD', {
          field: 'activeModule',
          value
        })
      }
    }
  },
  created() {
    this.activeTab = this.activeModule || this.sidebarTabsList[0]?.value
  }
}
</script>

<style lang="scss">
.rp-sidebar-content {
  position: relative;
  padding: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;

  .r-tabs {
    height: auto !important;
  }

  .el-tabs__header {
    margin-bottom: 8px;
  }
}
</style>
