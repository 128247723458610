export const palettes = [
  {
    id: 1,
    value: [
      'rgba(255,255,178, 1)',
      'rgba(254,217,118, 1)',
      'rgba(254,178,76, 1)',
      'rgba(253,141,60, 1)',
      'rgba(240,59,32, 1)',
      'rgba(189,0,38, 1)'
    ]
  },
  {
    id: 2,
    value: [
      'rgba(255,255,204, 1)',
      'rgba(199,233,180, 1)',
      'rgba(127,205,187, 1)',
      'rgba(65,182,196, 1)',
      'rgba(44,127,184, 1)',
      'rgba(37,52,148, 1)'
    ]
  },
  {
    id: 3,
    value: [
      'rgba(255,255,204, 1)',
      'rgba(217,240,163, 1)',
      'rgba(173,221,142, 1)',
      'rgba(120,198,121, 1)',
      'rgba(49,163,84, 1)',
      'rgba(0,104,55, 1)'
    ]
  },
  {
    id: 4,
    value: [
      'rgba(237,248,251, 1)',
      'rgba(191,211,230, 1)',
      'rgba(158,188,218, 1)',
      'rgba(140,150,198, 1)',
      'rgba(136,86,167, 1)',
      'rgba(129,15,124, 1)'
    ]
  },
  {
    id: 5,
    value: [
      'rgba(50,136,189, 1)',
      'rgba(153,213,148, 1)',
      'rgba(230,245,152, 1)',
      'rgba(254,224,139, 1)',
      'rgba(252,141,89, 1)',
      'rgba(213,62,79, 1)'
    ]
  },
  {
    id: 6,
    value: [
      'rgba(27,120,55, 1)',
      'rgba(127,191,123, 1)',
      'rgba(217,240,211, 1)',
      'rgba(231,212,232, 1)',
      'rgba(175,141,195, 1)',
      'rgba(118,42,131, 1)'
    ]
  },
  {
    id: 7,
    value: [
      'rgba(77,146,33, 1)',
      'rgba(161,215,106, 1)',
      'rgba(230,245,208, 1)',
      'rgba(253,224,239, 1)',
      'rgba(233,163,201, 1)',
      'rgba(197,27,125, 1)'
    ]
  },
  {
    id: 8,
    value: [
      'rgba(255,242,174, 1)',
      'rgba(230,245,201, 1)',
      'rgba(244,202,228, 1)',
      'rgba(203,213,232, 1)',
      'rgba(253,205,172, 1)',
      'rgba(179,226,205, 1)'
    ]
  },
  {
    id: 9,
    value: [
      'rgba(141,211,199, 1)',
      'rgba(255,255,179, 1)',
      'rgba(190,186,218, 1)',
      'rgba(251,128,114, 1)',
      'rgba(128,177,211, 1)',
      'rgba(253,180,98, 1)'
    ]
  },
  {
    id: 10,
    value: [
      'rgba(255,255,51, 1)',
      'rgba(255,127,0, 1)',
      'rgba(152,78,163, 1)',
      'rgba(77,175,74, 1)',
      'rgba(55,126,184, 1)',
      'rgba(228,26,28, 1)'
    ]
  },
  {
    id: 11,
    value: [
      'rgba(127,201,127, 1)',
      'rgba(190,174,212, 1)',
      'rgba(253,192,134, 1)',
      'rgba(255,255,153, 1)',
      'rgba(56,108,176, 1)',
      'rgba(240,2,127, 1)'
    ]
  },
  {
    id: 12,
    value: [
      'rgba(255,255,204, 1)',
      'rgba(254,217,166, 1)',
      'rgba(222,203,228, 1)',
      'rgba(204,235,197, 1)',
      'rgba(179,205,227, 1)',
      'rgba(251,180,174, 1)'
    ]
  },
  {
    id: 13,
    value: [
      'rgba(26,152,80, 1)',
      'rgba(145,207,96, 1)',
      'rgba(217,239,139, 1)',
      'rgba(254,224,139, 1)',
      'rgba(252,141,89, 1)',
      'rgba(215,48,39, 1)'
    ]
  }
]
