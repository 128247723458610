import vue from 'vue'

const state = {
  selectedObjects: {}
}

const mutations = {
  TABLE_PRIME_SIMPLE_SET_FIELD(state, { tableId, field, value }) {
    vue.set(state[field], tableId, value)
  }
}

export default {
  state,
  mutations
}
