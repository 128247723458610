<template>
  <div class="pot-1-terminal-modal">
    <r-title
      :size="40"
      color-type="subhead"
    >
      {{ $t('title') }}
    </r-title>
    <div class="pot-1-terminal-modal-content">
      <div class="pot-1-terminal-modal-content-row">
        <r-title
          :size="32"
          color-type="secondary"
        >
          Водитель
        </r-title>
        <r-title
          :size="32"
          color-type="subhead"
        >
          {{ info.driver_full_name || 'нет данных' }}
        </r-title>
      </div>
      <div class="pot-1-terminal-modal-content-row">
        <r-title
          :size="32"
          color-type="secondary"
        >
          Гос.номер
        </r-title>
        <r-title
          :size="32"
          color-type="subhead"
        >
          {{ info.reg_number || 'нет данных' }}
        </r-title>
      </div>
    </div>
    <div class="pot-1-terminal-modal-control">
      <r-button
        type="primary"
        :loading="isLoading"
        @click="clickHandler('confirm')"
      >
        <r-text
          :size="24"
          :weight="600"
          color="#ffffff"
        >
          {{ $t('confirm') }}
        </r-text>
      </r-button>

      <r-button @click="clickHandler('cancel')">
        <r-text
          :size="24"
          :weight="600"
        >
          {{ $t('cancel') }}
        </r-text>
      </r-button>
    </div>
  </div>
</template>

<script>
import { notifyFactory } from '@/utils'

export default {
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    statusList() {
      return this.$store.state.pot1.statusList || null
    },
    sixSatusId() {
      if (!this.statusList) return ''
      const sixSatus =
        this.statusList.find(e => e.name === 'Распечатал схему') || {}

      return sixSatus.id || ''
    }
  },
  methods: {
    clickHandler(action) {
      switch (action) {
        case 'cancel':
          this.$emit('cancel')
          break
        case 'confirm':
          this.isLoading = true
          this.closeTimeout()
          window.open(`/print?m=pot-1&id=${this.info.id}`)
          break
      }
    },
    closeTimeout() {
      this.changeStatus()
      this.$emit('cancel')
    },
    async changeStatus() {
      if (!this.sixSatusId || !this.info.id) return
      this.isLoading = true
      const data = {
        id: this.info.id,
        pot_status_id: this.sixSatusId
      }
      try {
        await this.$store.dispatch('PUT_REQUEST', {
          url: 'vehicle',
          data
        })
        const title = this.$t('edit-notify:title')
        const message = this.$t('edit-notify:text')

        this.$notify(notifyFactory('success', title, message))
        this.$store.commit('SET_POT_FIELD', {
          field: 'updateData',
          value: true
        })
        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss">
.pot-1-terminal-modal {
  position: relative;
  > * {
    margin-bottom: 48px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .r-title {
    word-break: normal;
    line-height: 54px;
  }
  &-content {
    padding: 16px;
    width: calc(100% - 32px);
    background-color: var(--bg_containers);
    border-radius: 8px;
    &-row {
      display: flex;
      > * {
        &:nth-child(1) {
          width: 160px;
          margin-right: 24px;
        }
      }
    }
  }
  &-control {
    display: flex;
    justify-content: space-between;
  }
}
</style>

<i18n>
{
  "ru": {
    "title": "Распечатать схему проезда на площадку разгрузки для:",
    "confirm": "Подтвердить и распечатать",
    "cancel": "Это не я. Отменить"
  }
}
</i18n>

<i18n>
{
  "ru": {
    "edit-notify:title": "Редактирование",
    "edit-notify:text": "Статус успешно изменен"
  },
  "en": {
    "edit-notify:title": "Editing",
    "edit-notify:text": "Editing completed successfully"
  }
}
</i18n>
