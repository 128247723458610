import Vue from 'vue'
import upperFirst from 'lodash.upperfirst'
import camelCase from 'lodash.camelcase'

const requireComponent = require.context('.', false, /r-[\w-]+\.vue$/)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(
    camelCase(
      fileName
        // .replace(/^\.\/_/, "")
        .replace(/\.\w+$/, '')
    )
  )

  Vue.component(componentName, componentConfig.default || componentConfig)
})
