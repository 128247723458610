<template>
  <div
    class="r-status"
    :style="getStyle()"
  >
    <div
      class="r-status__bg"
      :style="getBgColor()"
    />
    <span class="r-status__content">
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#000000'
    },
    width: {
      type: Number,
      default: 80
    }
  },
  data() {
    return {}
  },
  methods: {
    getStyle() {
      const styles = {}

      if (this.color) {
        styles['border-color'] = `${this.color} !important`
        styles.color = `${this.color} !important`
      }

      if (this.width) {
        styles.width = `${this.width}px !important`
      }

      return styles
    },
    getBgColor() {
      const styles = {}

      if (this.color) {
        styles['background-color'] = `${this.color} !important`
        styles.opacity = '0.1'
      }

      return styles
    }
  }
}
</script>

<style lang="scss">
.r-status {
  position: relative;
  font-size: 12px;
  border-radius: 28px;
  padding: 4px 16px;
  border: 1px solid;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 1;
  &__bg {
    margin: 0 !important;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &__content {
    margin-top: -2px;
  }
}
</style>
