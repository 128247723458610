<template>
  <div class="odd-router-loader">
    <span class="el-icon-loading" />
    Выполняется построение маршрута...
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.odd-router-loader {
  position: absolute;
  top: 76px;
  left: calc(50%);
  transform: translateX(-50%);
  font-size: 12px;
  line-height: 18px;
  padding: 10px 20px;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  border-color: var(--dividers_low_contrast) !important;
  background-color: var(--bg_panel_primary) !important;
  display: flex;
  align-items: center;
  color: var(--accent_primary) !important;

  span {
    font-size: 18px;
    line-height: 18px;
    margin-right: 5px;
  }
}
</style>
