<template>
  <div class="m-links-settings">
    <r-panel-header
      :with-back-button="!!path"
      :title="getPanelTitle"
      @back="changePath(null)"
    />
    <div class="m-links-settings__container">
      <template v-if="!path">
        <r-simple-list
          :list="tabs"
          :with-bg="true"
          grid
          @click-handler="e => changePath(e.id)"
        />
      </template>
      <template v-else-if="path === 'settings'">
        <color-control
          :id="id"
          :config="stylesConfigs.style.paint"
          color-field="line-color"
          opacity-field="line-opacity"
        />
        <width-control
          :id="id"
          :config="stylesConfigs.style.paint"
          field="line-width"
        />
        <!-- <popup-control :id="id" /> -->
      </template>
      <template v-else-if="path === 'diagram'">
        <diagram-control :id="id" />
      </template>
    </div>
  </div>
</template>

<script>
import ColorControl from '../controls/color'
import WidthControl from '../controls/width'
// import PopupControl from '../controls/popup'
import DiagramControl from '../controls/diagram'

const tabs = [
  {
    id: 'settings',
    title: 'Общие настройки',
    subtitle: 'Настройка цвета, обводки, размеров',
    icon: 'stylize'
  },
  {
    id: 'diagram',
    title: 'Эпюры',
    subtitle: 'Отображение и настройка эпюр',
    icon: 'epure-layer'
  }
]

export default {
  components: {
    ColorControl,
    WidthControl,
    // PopupControl,
    DiagramControl
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      path: null,
      tabs
    }
  },
  computed: {
    stylesConfigs() {
      return this.$store.state.modelling.stylesConfigs[this.id]
    },
    getPanelTitle() {
      switch (this.path) {
        case 'settings':
          return 'Общие настройки'
        case 'diagram':
          return 'Настройки эпюр'
        default:
          return 'Параметры слоя'
      }
    }
  },
  methods: {
    changePath(path) {
      this.path = path
    }
  }
}
</script>

<style lang="scss">
.m-links-settings {
  display: grid;
  overflow: auto;
  align-content: start;

  &__container {
    display: grid;
    overflow: auto;
    padding: 8px;
  }
}
</style>
