<template>
  <div :class="['mt-legend', { show }]">
    <r-block
      col
      no-padding
      space-between
    >
      <div
        v-if="show"
        class="mt-legend__header"
      >
        Легенда
      </div>
      <r-button
        :icon="show ? 'close-delete' : 'info'"
        simple
        @click="show = !show"
      />
    </r-block>
    <div
      v-if="show"
      class="mt-legend__content"
    >
      <ul class="mt-legend__list">
        <li class="mt-legend__item">
          <div class="mt-legend__item-icon mt-legend__item-icon--online" />
          <div class="mt-legend__item-label">
            <r-text type="caption">
              Авто
            </r-text>
          </div>
        </li>
        <li class="mt-legend__item">
          <div class="mt-legend__item-icon train mt-legend__item-icon--online" />
          <div class="mt-legend__item-label">
            <r-text type="caption">
              Ж/Д
            </r-text>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false
    }
  }
}
</script>

<style lang="scss" scoped>
.mt-legend {
  position: absolute;
  right: 16px;
  top: 180px;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  width: auto;
  z-index: 1000;
  font-size: 12px;
  color: var(--white_white);
  background-color: var(--modal_bg) !important;
  border-color: var(--dividers_low_contrast) !important;

  &.show {
    width: 200px;
  }

  &__header {
    padding: 8px;
    font-weight: bold;
    border-radius: var(--border-radius);
    text-transform: uppercase;
    color: var(--text_primary) !important;
    background-color: var(--modal_bg) !important;
  }

  &__content {
    padding: 8px;
  }

  &__list {
    margin-top: 0;
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-top: 8px;
    }

    &-icon {
      margin-right: 8px;
      width: 32px;
      height: 32px;
      background-size: cover;
      background-image: url('../../../../../assets/images/monitoring/aghk-car-default.svg');

      &--offline {
        opacity: 0.5;
      }
      &.train {
        background-image: url('../../../../../assets/images/monitoring/aghk-train-default.svg');
      }
    }
  }
}
</style>
