<template>
  <div class="r-color-picker">
    <div
      v-if="title"
      class="r-color-picker__header"
    >
      <r-text color-type="subhead">
        {{ title }}
      </r-text>
    </div>
    <div class="r-color-picker__row">
      <r-text type="caption">
        {{ $t('color') }}
      </r-text>
      <r-text type="caption">
        {{ $t('opacity') }}
      </r-text>
    </div>
    <div class="r-color-picker__row">
      <el-color-picker
        v-model="value"
        class="r-color-picker__picker"
        size="mini"
        popper-class="r-color-picker-dropdown"
        color-format="rgb"
        @active-change="e => changeColor(e)"
      />
      <r-slider
        :min="0"
        :max="1"
        :step="0.1"
        :number="opacity"
        @change="e => changeOpacity(e)"
      />
    </div>
  </div>
</template>

<script>
import throttle from 'lodash.throttle'

export default {
  props: {
    title: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    },
    colorOpacity: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      value: this.color,
      opacity: this.colorOpacity
    }
  },
  watch: {
    value() {
      this.updateInput()
    },
    color(val) {
      this.value = val
    },
    opacity() {
      this.updateInput()
    },
    colorOpacity(val) {
      this.opacity = val
    }
  },
  methods: {
    updateInput: throttle(function() {
      this.$emit('change', {
        color: this.value,
        opacity: this.opacity
      })
    }, 100),
    changeColor: throttle(function(e) {
      this.$emit('change', {
        color: e,
        opacity: this.opacity
      })
    }, 30),
    changeOpacity(val) {
      this.opacity = val
    },
    getPureValue(val) {
      return this.hexToRgb(val)
    },
    hexToRgb(hex) {
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
      const newHex = hex.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b
      })
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(newHex)

      return result
        ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
            result[3],
            16
          )}`
        : hex.split('(')[1].split(')')[0]
    }
  }
}
</script>

<style lang="scss">
.r-color-picker {
  position: relative;
  width: 100%;
  border-radius: var(--border-radius);
  &__header {
    margin-bottom: 8px;
  }
  &__row {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
    > div {
      &:first-child {
        width: 52px;
      }
      &:last-child {
        width: calc(100% - 52px);
      }
    }
  }
  &__picker {
    .el-color-picker {
      &__trigger {
        border: none;
        height: 28px;
        width: 28px;
        padding: 0;
      }
      &__icon {
        display: none;
      }
      &__color {
        border: none;
        border-radius: var(--border-radius);
        overflow: hidden;
      }
    }
  }
  &__input {
    width: 56px !important;
    .el-input__inner {
      box-sizing: border-box;
      height: 36px !important;
      padding: 8px !important;
      font-size: 14px;
    }
  }
}

.el-color-picker__panel {
  background-color: var(--modal_bg) !important;
  border: none !important;

  .el-input__inner {
    color: var(--field_primary_text) !important;
    border-color: var(--field_border) !important;
    background-color: var(--field_bg) !important;
  }

  .el-color-dropdown__link-btn {
    display: none;
  }

  .el-color-dropdown__btn {
    background: var(--accent_primary) !important;
    color: var(--white_white) !important;
    color: var(--text_primary) !important;
    background-color: var(--accent_primary) !important;
    border-color: var(--accent_primary) !important;
  }
}
</style>

<i18n>
{
  "ru": {
    "color": "Цвет",
    "opacity": "Прозрачность"
  },
  "en": {
    "color": "Color",
    "opacity": "Opacity"
  }
}
</i18n>
