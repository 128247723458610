import { jsonToGeojson } from '@/utils'
import { layersConfig } from '../configs'
import extent from 'turf-extent'

const SOURCE_NAME = 'active_object'

export class ActiveObject {
  constructor(state) {
    this.$store = state.$store
    this.state = state
    this.mapgl = state.mapgl

    this.flyToOptions = state.report.flyToOptions
  }

  addLayer(object) {
    if (!object.geom && !this.flyToOptions) return

    if (object.geom) {
      const type = object.geom.type
      const geojson = jsonToGeojson([object])

      if (!geojson) return

      this.addMapLayer(type, geojson)
      this.flyTo(object.geom)
    } else if (this.flyToOptions) {
      const { key, query } = this.flyToOptions
      const features = this.state.mapData?.features?.filter(f => {
        return f.properties[query] === object[key]
      }) || []

      if (!features.length) return

      this.flyTo(features[0].geometry)
    }
  }

  removeLayer() {
    if (this.mapgl.getLayer(SOURCE_NAME)) {
      this.mapgl.removeLayer(SOURCE_NAME)
    }
    if (this.mapgl.getSource(SOURCE_NAME)) {
      this.mapgl.removeSource(SOURCE_NAME)
    }
  }

  addMapLayer(type, features) {
    const configName = `active_object_${type}`
    const layerConfig = layersConfig?.[configName] || null

    if (!layerConfig) return

    if (this.mapgl.getLayer(SOURCE_NAME)) {
      this.mapgl.removeLayer(SOURCE_NAME)
    }
    const source = this.mapgl.getSource(SOURCE_NAME)

    if (source) {
      source.setData(features)
    } else {
      this.mapgl.addSource(SOURCE_NAME, {
        type: 'geojson',
        data: features
      })
    }

    this.mapgl.addLayer({
      id: SOURCE_NAME,
      source: SOURCE_NAME,
      ...layerConfig
    })
  }

  flyTo(geom) {
    if (!geom) return

    const bounds = extent(geom)

    this.mapgl.fitBounds(bounds, {
      padding: 20,
      maxZoom: 16
    })
    this.$store.commit('ANALYTICS_SET_FIELD', {
      field: 'flyToGeom',
      value: null
    })
  }
}
