<template>
  <div class="sc-object-card__video-archive__item">
    <div class="sc-object-card__video-archive__item__title">
      <r-text>
        {{ getFrom() }}
      </r-text>
      <r-text>
        {{ getTo() }}
      </r-text>
      <r-text type="caption">
        {{ `Статус: ${getStatus()}` }}
      </r-text>
      <el-progress
        :percentage="getProgress()"
        :color="customColors"
      />
      <r-text
        v-if="hasTimer"
        type="caption"
      >
        {{ getTimerStatus() }}
      </r-text>
    </div>

    <div class="sc-object-card__video-archive__item__controls">
      <el-tooltip
        v-if="info.state === 'Finished'"
        :open-delay="1200"
        content="Скачать"
        placement="top"
      >
        <r-button
          icon="download"
          mini
          @click="downloadVideo"
        />
      </el-tooltip>
      <!-- <el-tooltip
        :open-delay="1200"
        content="Удалить"
        placement="top"
      >
        <r-button
          icon="trash"
          mini
          @click="deleteVideo"
        />
      </el-tooltip> -->
    </div>
  </div>
</template>

<script>
import constants from '@/constants/url'
// import { downloadFile } from '@/utils/helpers'

export default {
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      customColors: [
        { color: '#f56c6c', percentage: 10 },
        { color: '#e6a23c', percentage: 30 },
        { color: '#1989fa', percentage: 60 },
        { color: '#5cb87a', percentage: 100 }
      ],
      refreshData: null,
      timerValue: null
    }
  },
  computed: {
    hasTimer() {
      return this.info?.total_progress === 100
    }
  },
  created() {
    this.setTimerValue()
    this.refreshData = setInterval(this.setTimerValue, 60000)
  },
  beforeDestroy() {
    clearInterval(this.refreshData)
  },
  methods: {
    setTimerValue() {
      if (!this.hasTimer) return
      const dateNow = this.$rDate.format(new Date(), 'x')
      const timerEnd = this.$rDate
        .calc(1, 'days', this.info?.create_time)
        .format('x')
      const timerValue = timerEnd - dateNow

      if (timerValue <= 0) {
        this.timerValue = '0 ч. 0 м.'
      } else {
        const hours = Math.floor(timerValue / (1000 * 60 * 60))
        const minutes = Math.floor(
          (timerValue - hours * 1000 * 60 * 60) / (1000 * 60)
        )

        this.timerValue = `${hours} ч. ${minutes} м.`
      }
    },
    getTimerStatus() {
      return `Скачивание доступно ещё ${this.timerValue}`
    },
    getName() {
      const from =
        this.$rDate.format(this.info?.from, 'DD-MM-YYYY HH:mm') || '-'
      const to = this.$rDate.format(this.info?.to, 'DD-MM-YYYY HH:mm') || '-'

      return `${from} / ${to}`
    },
    getFrom() {
      return (
        'От: ' + this.$rDate.format(this.info?.from, 'DD-MM-YYYY HH:mm') || '-'
      )
    },
    getTo() {
      return (
        'До: ' + this.$rDate.format(this.info?.to, 'DD-MM-YYYY HH:mm') || '-'
      )
    },
    getStatus() {
      const { state } = this.info

      switch (state) {
        case 'Registered':
          return 'Зарегистрирован'
        case 'Started':
          return 'В работе'
        case 'Finished':
          return 'Завершен'
        default:
          return 'ошибка'
      }
    },
    getProgress() {
      return this.info?.total_progress || 0
    },
    downloadVideo() {
      if (!this.info?.url) return

      console.log('prepare to download', this.info)

      window.open(`${constants.BASE_URL}${this.info.url}`)
      // const file = {
      //   name: this.getName,
      //   href: `${constants.URL}${this.info.url}`
      // }

      // downloadFile(file)
    },
    deleteVideo() {
      console.log('deleteVideo')
    }
  }
}
</script>

<style lang="scss">
.sc-object-card__video-archive__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 4px 8px;
  border-radius: var(--border-radius);
  background-color: var(--bg_containers);

  &__controls {
    display: flex;

    > * {
      &:not(:first-child) {
        margin-left: 8px;
      }
    }
  }

  .el-progress {
    border-radius: 24px !important;
    &-bar__outer {
      background-color: var(--bg_containers);
    }
    &__text {
      color: var(--text_primary);
      font-size: 12px !important;
    }
  }
}
</style>
