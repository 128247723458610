export const getCarsList = (value = 15) => {
  const list = []

  for (let i = 0; i < value; i++) {
    list.push({
      id: String(i + 100),
      number: String(randomInt(77777777, 99999999)),
      status_id: '',
      cargo: cargoTypes[randomInt(0, 2)],
      project: projectList[randomInt(0, 1)],
      subproject: subprojectList[randomInt(0, 2)],
      way_no: ''
    })
  }

  return list
}

const randomInt = (min, max) => {
  return min + Math.floor((max - min) * Math.random())
}

const cargoTypes = [
  'Насыпной материал',
  'Арматура',
  'Металлоконструкции'
]

const projectList = [
]

const subprojectList = [
  'p1',
  'p2',
  'p3'
]

export const getStatusNameById = function(state, status_id) {
  if (!status_id) return 'не определен'
  const statusList = state.$store.state.railwayControl.statusList || null
  if (!statusList) return 'не определен'
  const status = statusList[status_id]?.name || 'не определен'

  return status
}

export const statusDecryption = {
  'На сортировке': {
    id: 1,
    color: ''
  },
  'Прибыл на завод': {
    id: 2,
    color: 'accent-primary'
  },
  'На разгрузке': {
    id: 3,
    color: 'accent-warning'
  },
  Разгружен: {
    id: 4,
    color: 'accent-success'
  },
  default: {
    id: 0,
    color: ''
  }
}
