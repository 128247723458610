<template>
  <div class="r-second-panel">
    <slot />
    <div class="r-second-panel__close">
      <r-button
        icon="close-delete"
        simple
        mini
        @click="close"
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.r-second-panel {
  position: relative;
  height: 100%;
  width: 260px;
  border-left: 1px solid transparent;
  background-color: var(--bg_panel_secondary) !important;

  &__close {
    border-color: var(--dividers_low_contrast);
    border-width: 0 1px 1px 0;
    border-style: solid;
    position: absolute;
    left: 100%;
    top: 0;
    z-index: 1100;
    border-bottom-right-radius: 4px;
    display: flex;
    height: 36px;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--dividers_low_contrast);
    border-right: 1px solid var(--dividers_low_contrast);
    padding: 0.25rem;
    background-color: var(--bg_panel_secondary) !important;
    box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.1);

    .r-button {
      width: 26px;
    }
  }
}
</style>
