<template>
  <div
    v-loading="isLoading"
    class="rp-attr-filter-content-filter"
  >
    <div class="rp-attr-filter-content-filter__header">
      <r-button
        :disabled="isDisabled"
        type="primary"
        @click="applyFilter"
      >
        Применить фильтры
      </r-button>
      <r-button
        :disabled="resetDisabled"
        @click="resetFilters"
      >
        Сбросить фильтры
      </r-button>
    </div>
    <el-tree
      ref="rpAttrFilterTree"
      class="r-tree"
      :data="data"
      node-key="treeId"
      :expand-on-click-node="false"
      :render-content="renderContent"
      @node-click="clickNode"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import { makeFilter, setItemsConfig } from './helpers'
import { projectFilter, reflectionKeys, receivingDateFilter } from './configs'

export default {
  data() {
    return {
      isLoading: false,
      data: null,
      includedFilter: null
    }
  },
  computed: {
    roleAttrFilterId() {
      return this.$store.state.rolePermission.roleAttrFilterId || null
    },
    roleAttrFilterFields() {
      return (
        this.$store.state.rolePermission.roleAttrFilterFields?.filter(
          e => e.reflection
        ) || null
      )
    },
    initialFilter() {
      return this.$store.state.rolePermission.roleAttrFilter || null
    },
    selectedObjects() {
      return this.$store.state.tablePrimeSimple.selectedObjects?.rpAttrFilter || null
    },
    roleAttrFilterInitialData() {
      return this.$store.state.rolePermission.roleAttrFilterInitialData || null
    },
    roleAttrFilterActiveTab() {
      return this.$store.state.rolePermission.roleAttrFilterActiveTab || null
    },
    roleAttrFilterItems() {
      return this.$store.state.rolePermission.roleAttrFilterItems || null
    },
    roleAttrFilterExcluded() {
      return this.$store.state.rolePermission.roleAttrFilterExcluded || null
    },
    roleAttrFilterExcludedItems() {
      return this.$store.state.rolePermission.roleAttrFilterExcludedItems || null
    },
    roleAttrFilterItemsConfig() {
      return this.$store.state.rolePermission.roleAttrFilterItemsConfig || null
    },
    selectedObjectChanged() {
      return this.$store.state.rolePermission.selectedObjectChanged || false
    },
    isDisabled() {
      return isEqual(this.includedFilter, this.initialFilter) &&
        isEqual(this.excludedFilter, this.roleAttrFilterExcluded) &&
        !this.selectedObjectChanged
    },
    resetDisabled() {
      return !this.initialFilter?.length && !this.roleAttrFilterItemsConfig?.value?.length
    }
  },
  watch: {
    roleAttrFilterFields: {
      handler: function() {
        this.getFieldsInfo()
      },
      deep: true
    },
    initialFilter: {
      handler: function(val) {
        this.includedFilter = cloneDeep(val)
      },
      deep: true
    },
    selectedObjects(val) {
      this.setFilteredItems(val, this.roleAttrFilterInitialData)
    }
  },
  created() {
    this.getFieldsInfo()
  },
  methods: {
    setFilteredItems(selected, initial) {
      if (this.roleAttrFilterActiveTab === 'included') {
        const excludedItems = initial.filter(e => {
          return !selected.find(l => l.id === e.id)
        })
        this.$store.commit('ROLE_PERM_SET_FIELD', {
          field: 'roleAttrFilterExcludedItems',
          value: excludedItems
        })
      } else {
        this.$store.commit('ROLE_PERM_SET_FIELD', {
          field: 'roleAttrFilterItems',
          value: selected
        })
      }
    },
    async getFieldsInfo() {
      if (!this.roleAttrFilterFields?.length) return
      this.includedFilter = cloneDeep(this.initialFilter)
      this.isLoading = true

      try {
        const fields = []

        await Promise.all(
          this.roleAttrFilterFields.map(async(e, i) => {
            const key = e.deepReflection
              ? e.key
              : reflectionKeys[e.title] || reflectionKeys.default
            const config = { only: ['id', key] }
            const url = `objectInfo/${
              e?.reflection.source_id
            }?config=${JSON.stringify(config)}`

            const { data } = await this.$store.dispatch('GET_REQUEST', { url })

            fields.push({
              id: i,
              treeId: i,
              label: e.name || e.title,
              value: e.title,
              children: Object.values(data).map(c => {
                c.treeId = i + c.id
                c.label = c[key] || c.id
                return c
              })
            })
          })
        )
          .then(() => {
            this.data = fields
              .sort((a, b) => {
                if (a.id > b.id) return 1
                if (a.id < b.id) return -1
                return 0
              })
              .map(e => {
                e.children.push({
                  id: `${e.id}-null`,
                  treeId: `${e.id}-null`,
                  label: 'Не указано',
                  name: 'null'
                })
                return e
              })

            if (this.roleAttrFilterId === 'f09e59fb-ee58-482c-8b0f-9ac520cf2099') {
              this.data.push(projectFilter)
            }

            if (this.roleAttrFilterId === 'd1709d93-ca52-4453-a83b-ff7ab253a464') {
              this.data.push(receivingDateFilter)
            }
          })
          .then(() => {
            this.checkNodes(this.data)
          })
      } catch (e) {
        throw new Error(e)
      } finally {
        this.isLoading = false
      }
    },
    checkNodes(data) {
      const filterArray =
        this.includedFilter && this.includedFilter?.length
          ? this.includedFilter
          : []
      let checkedNodes = []

      data.forEach(e => {
        if (e.children?.length) {
          e.children.forEach(c => {
            c.parent = e.value
            checkedNodes.push(c)
          })
        }
      })

      if (filterArray?.length) {
        const agpzFilter = filterArray?.find(e => e.field === 'agpz')
        const aghkFilter = filterArray?.find(e => e.field === 'aghk')

        checkedNodes = checkedNodes.filter(
          n => {
            if (n.parent === 'projects') {
              const all = n.id === 'all'
              const isAgpz = false
              const isAghk = false

              if (all) {
                if (agpzFilter?.value ?? aghkFilter?.value) return false
                return true
              } else if (isAgpz) {
                if (!agpzFilter && !aghkFilter) return true
                if (!agpzFilter) return false
                if (agpzFilter.value === true) return true
                if (agpzFilter.value === null) return false
                return true
              } else if (isAghk) {
                if (!agpzFilter && !aghkFilter) return true
                if (!aghkFilter) return false
                if (aghkFilter.value === true) return true
                if (aghkFilter.value === null) return false
                return true
              }
              return true
            } else if (n.parent === 'receiving_date_fact') {
              const receiving = n.id === 'true'
              const notReceiving = n.id === 'false'
              const filterValue = filterArray.find(f => f.field === n.parent)?.op

              if (receiving) {
                return !filterValue || filterValue === '!null'
              } else if (notReceiving) {
                return !filterValue || filterValue === '='
              }
              return true
            } else if (n.parent.includes('.')) {
              return !filterArray?.find(e => {
                const field = e.field

                if (field === n.parent + '.id') {
                  return e.value.findIndex(v => n.id.includes(String(v))) === -1
                }

                return false
              })
            } else {
              return !filterArray?.find(e => {
                const field = e.field?.includes('.') ? e.field.split('.')?.[0] : e.field

                if (field === n.parent) {
                  return e.value.findIndex(v => n.id.includes(String(v))) === -1
                }

                return false
              })
            }
          }
        )
      }

      this.$nextTick(() =>
        this.$refs.rpAttrFilterTree.setCheckedNodes(checkedNodes)
      )
      this.$nextTick(() => {
        const node = this.$refs.rpAttrFilterTree.getNode(0)
        const { excludedFilter } = makeFilter(node.store?.root?.childNodes, this.roleAttrFilterId)
        this.excludedFilter = excludedFilter
        this.$store.commit('ROLE_PERM_SET_FIELD', {
          field: 'roleAttrFilterExcluded',
          value: cloneDeep(this.excludedFilter)
        })
      })
    },
    applyFilter() {
      this.$store.commit('ROLE_PERM_SET_FIELD', {
        field: 'roleAttrFilter',
        value: cloneDeep(this.includedFilter)
      })
      this.$store.commit('ROLE_PERM_SET_FIELD', {
        field: 'roleAttrFilterExcluded',
        value: cloneDeep(this.excludedFilter)
      })

      if (this.selectedObjectChanged) {
        setItemsConfig(this)
      }

      this.$store.commit('ROLE_PERM_SET_FIELD', {
        field: 'updateAttrFilter',
        value: true
      })
      this.$store.commit('ROLE_PERM_SET_FIELD', {
        field: 'selectedObjectChanged',
        value: false
      })
      this.$store.commit('ROLE_PERM_SET_FIELD', {
        field: 'roleAttrFilterHasChanges',
        value: true
      })
      this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', true)
    },
    resetFilters() {
      this.$store.commit('ROLE_PERM_SET_FIELD', {
        field: 'roleAttrFilter',
        value: null
      })
      this.$store.commit('ROLE_PERM_SET_FIELD', {
        field: 'roleAttrFilterExcluded',
        value: null
      })
      this.$store.commit('ROLE_PERM_SET_FIELD', {
        field: 'roleAttrFilterItemsConfig',
        value: null
      })

      this.$store.commit('ROLE_PERM_SET_FIELD', {
        field: 'updateAttrFilter',
        value: true
      })
      this.$store.commit('ROLE_PERM_SET_FIELD', {
        field: 'selectedObjectChanged',
        value: false
      })
      this.$store.commit('ROLE_PERM_SET_FIELD', {
        field: 'roleAttrFilterHasChanges',
        value: true
      })
    },
    renderContent(h, { node, data }) {
      if (data?.children) {
        return (
          <span class="custom-tree-node">
            <div class="custom-tree-node__title">
              <r-text>{node.label}</r-text>
              <r-text color-type="secondary">
                Категорий: {data?.children?.length}
              </r-text>
            </div>
            <el-checkbox
              class="r-checkbox"
              indeterminate={node.indeterminate}
              disabled
              size="mini"
              value={node.checked}
            ></el-checkbox>
          </span>
        )
      } else {
        return (
          <span class="custom-tree-node">
            <div class="custom-tree-node__title">
              <r-text>{node.label}</r-text>
            </div>
            <el-checkbox
              class="r-checkbox"
              disabled
              size="mini"
              v-model={node.checked}
            ></el-checkbox>
          </span>
        )
      }
    },
    clickNode(_, node) {
      if (node.level === 1) {
        if (node.checked) {
          node.checked = false
          node.store.currentNode.childNodes.forEach(n => {
            n.checked = false
          })
          node.indeterminate = false
        } else {
          node.checked = true
          node.store.currentNode.childNodes.forEach(n => {
            n.checked = true
          })
          node.indeterminate = false
        }
      } else {
        node.checked = !node.checked
        const { parent } = node

        const hasChecked = parent.childNodes.some(n => n.checked)

        if (hasChecked) {
          const everyChecked = parent.childNodes.every(n => n.checked)

          parent.checked = everyChecked
          parent.indeterminate = !everyChecked
        } else {
          parent.checked = false
          parent.indeterminate = false
        }
      }
      const { includedFilter, excludedFilter } = makeFilter(
        node.store?.root?.childNodes,
        this.roleAttrFilterId
      )

      this.includedFilter = includedFilter
      this.excludedFilter = excludedFilter
    }
  }
}
</script>

<style lang="scss">
.rp-attr-filter-content-filter {
  position: relative;
  width: 244px;
  border-radius: var(--border-radius);
  background-color: var(--bg_containers);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-right: 16px;
  padding: 8px;
  flex-shrink: 0;

  &__header {
    margin-bottom: 9px;
    width: 100%;
    position: relative;
    display: grid;
    grid-gap: 0.5rem;

    .r-button {
      width: 100%;
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 1px;
      left: 0;
      bottom: -4px;
      background-color: var(--dividers_low_contrast);
    }
  }

  .r-tree {
    max-height: calc(100vh - 460px);
    overflow: auto;

    .el-tree-node {
      &__content {
        background-color: initial !important;

        &:hover {
          background-color: var(--accent_hover) !important;
        }

        &:active {
          background-color: var(--accent_active) !important;
        }
      }
    }
  }

  .custom-tree-node {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    flex: 1;
    width: 100%;

    &__title {
      max-width: calc(100% - 20px);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .el-checkbox__input.is-disabled .el-checkbox__inner {
      cursor: pointer;

      &:after {
        cursor: pointer;
        border-color: #fff !important;
        border-width: 2px !important;
      }
    }
  }
}
</style>
