<template>
  <div class="map-baselayers">
    <div class="map-baselayers__title">
      Подложки
    </div>
    <ul class="map-baselayers__list">
      <li
        v-for="layer in baselayers"
        :key="layer.id"
        :class="[
          baselayerId === layer.id ? 'active' : '',
          'map-baselayers__item'
        ]"
        @click.stop="clickHandler(layer)"
      >
        {{ layer.label }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    baselayerId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      baselayers: this.$store.state.map.baselayers
    }
  },
  methods: {
    clickHandler(layer) {
      this.$emit('select', layer)
    }
  }
}
</script>

<style lang="scss">
.map-baselayers {
  display: none;
  position: absolute;
  top: 0;
  right: calc(100% + 8px);
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  padding: 5px 0;
  width: 175px;
  z-index: 1100;
  border-color: var(--dividers_low_contrast) !important;
  background-color: var(--modal_bg) !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -12px;
    width: 12px;
    height: 80px;
    display: block;
  }

  &__title {
    color: var(--text_primary) !important;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 8px;
    margin-left: 8px;
  }

  &__item {
    cursor: pointer;
    padding: 8px 16px;
    font-size: 12px;
    color: var(--text_primary) !important;

    &:hover {
      background-color: var(--accent_hover) !important;
    }

    &.active {
      background-color: var(--accent_selected) !important;
      color: var(--white_white) !important;
      font-weight: bold;
    }
  }
}
</style>
