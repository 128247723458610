export const modifyLines = data =>
  data
    .filter(f => !!f.line_routes.length)
    .map(f => ({
      id: f.id,
      name: f.name,
      color: f.color,
      geom: f.line_routes[0].geom,
      line_route_items: f.line_routes[0].line_route_items
    }))

export const getSPFeatures = features =>
  features.reduce((prev, current) => {
    const items = []
    const props = {
      ...current,
      geom: undefined,
      stop_geom: undefined
    }

    if (current.stop_geom) {
      items.push({
        type: 'Feature',
        geometry: current.stop_geom,
        properties: {
          id: current.id,
          type: 'symbols',
          ...props
        }
      })
      items.push({
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [current.stop_geom.coordinates, current.geom.coordinates]
        },
        properties: {
          id: current.id,
          ...props
        }
      })
    } else {
      items.push({
        type: 'Feature',
        geometry: current.geom,
        properties: {
          id: current.id,
          type: 'symbols',
          ...props
        }
      })
    }

    items.push({
      type: 'Feature',
      geometry: current.geom,
      properties: {
        id: current.id,
        type: 'points',
        ...props
      }
    })

    return [...prev, ...items]
  }, [])
