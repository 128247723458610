<template>
  <div
    class="mt-forecast"
  >
    <div class="mt-forecast__title">
      <r-title type="title-3">
        Ожидаемое время прибытия груза
      </r-title>
    </div>
    <div class="mt-forecast__subtitle">
      <r-text color-type="secondary">
        {{ `Последнее обновление статуса: ${statusUpdateTime}` }}
      </r-text>
    </div>
    <template v-if="isEtran">
      <div
        v-if="etranForecast"
        class="mt-forecast__item"
      >
        <r-icon
          name="cargo"
          :size="32"
        />
        <div class="long-arrow">
          <div class="right" />
        </div>
        <r-title>
          {{ etranForecast }}
        </r-title>
      </div>
    </template>
    <template v-else>
      <div
        v-if="timeToAGHK"
        class="mt-forecast__item"
      >
        <r-icon
          name="cargo"
          :size="32"
        />
        <div class="long-arrow">
          <div class="right" />
        </div>
        <r-title>
          {{ `${timeToAGHK}` }}
        </r-title>
      </div>
      <div
        v-if="timeToAGPZ"
        class="mt-forecast__item"
      >
        <r-icon
          name="cargo"
          :size="32"
        />
        <div class="long-arrow">
          <div class="right" />
        </div>
        <r-title>
          {{ `${timeToAGPZ}` }}
        </r-title>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    info: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      statusUpdateTime: '-',
      timeToAGPZ: null,
      timeToAGHK: null
    }
  },
  computed: {
    cardId() {
      return this.$store.state.monitoringTmc.cardId
    },
    vehiclesData() {
      return this.$store.state.monitoringTmc.vehicles
    },
    isEtran() {
      const feature = this.vehiclesData.find(e => e.id === this.cardId)

      return feature.type_name === 'ЖД'
    },
    etranForecast() {
      if (!this.isEtran) return null

      const { arrival_forecast } = this.info

      if (!arrival_forecast) return null

      const postfix = ''

      return `${this.$rDate.format(arrival_forecast, 'DD.MM.YYYY')} ${postfix}`
    }
  },
  watch: {
    info() {
      this.setForecast()
    }
  },
  created() {
    this.setForecast()
  },
  methods: {
    setForecast() {
      const { arrival_time_agpz, arrival_time_aghk, updated_at } = this.info

      this.timeToAGPZ = arrival_time_agpz ? this.$rDate.format(arrival_time_agpz) : null
      this.timeToAGHK = arrival_time_aghk ? this.$rDate.format(arrival_time_aghk) : null
      this.statusUpdateTime = updated_at ? this.$rDate.format(updated_at) : null
    }
  }
}
</script>

<style lang="scss" scoped>
.mt-forecast {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    margin-bottom: 8px;
  }

  &__subtitle {
    margin-bottom: 28px;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    .r-icon {
      margin-right: 16px;
    }

    .long-arrow {
      margin-right: 16px;
    }

    .r-button {
      margin-left: 8px;
    }
  }
}

.long-arrow {
  cursor: pointer;
  position: relative;
  width: 120px;
  height: 12px;
}
.long-arrow .right {
  position: absolute;
  display: block;
  width: calc(100% - 10px);
  height: 2px;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  background-color: var(--dividers_high_contrast);
}
.long-arrow .right::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translate(0, -50%);
  clip-path: polygon(50% 50%, 0 0, 0 100%);
  width: 10px;
  height: 10px;
  background-color: var(--dividers_high_contrast);
}
</style>
