export const getFirstLayerId = (mapgl, layersControllerItems) => {
  const layers = mapgl.getStyle().layers

  const firstLayer = layers.find(e => {
    return !!layersControllerItems.find(n => {
      if (
        `${n.name}_sc_map` === e.id ||
        `${n.name}_sc_map_circle` === e.id ||
        `${n.name}_sc_map_line` === e.id ||
        `${n.name}_sc_map_fill` === e.id
      ) {
        return true
      }
    })
  })

  return firstLayer ? firstLayer.id : layers[layers.length - 1].id
}

export const getFirstSymbolId = (mapgl, type = 'symbol') => {
  const { layers } = mapgl.getStyle()

  return layers.find(e => e?.type === type)?.id
}

export const preparedMapData = (layer_id, data) => {
  switch (layer_id) {
    default:
      return data
  }
}

export const getSourceIdByActiveCardId = function(activeCardId) {
  return activeCardId.split('?id:')[0]
}

export const getLayerIdByActiveCardId = function(activeCardId) {
  return activeCardId.split('?id:')[1]
}
