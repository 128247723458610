export default {
  locale: 'ru',
  theme: 'light-theme',
  colors: '',
  defaultPath: '/app/map',
  isReadyToRender: false,
  datasources: {},
  user_config: {
    datasources: {},
    objectInfoFields: {},
    baselayerId: 5,
    timeFormat: 'DD-MM-YYYY HH:mm:ss',
    pageSize: {
      book: 10,
      userRoles: 10
    }
  },
  listeners: [],
  mainModelId: null
}
