<template>
  <div class="register-modal-window">
    <r-title>{{ title }}</r-title>
    <component
      :is="`modal-${mode}`"
      :source_id="source.source_id"
      :source="source"
      @mode="changeMode"
    />
  </div>
</template>

<script>
import ModalView from './modal-view'
import ModalEdit from './modal-edit'
import ModalCreate from './modal-create'
import { titles, sources } from './config/fields'

export default {
  components: { ModalEdit, ModalView, ModalCreate },
  data() {
    return {
      mode: 'view',
      titles,
      sources,
      initHeight: 'calc(90vh - 50px)'
    }
  },
  computed: {
    title() {
      switch (this.mode) {
        case 'edit':
          return this.titles.edit
        case 'create':
          return this.titles.create
        default:
          return this.titles.view
      }
    },
    source() {
      return this.$store.state[this.sources.store][this.sources.field]
    }
  },
  watch: {
    mode(mode) {
      this.setHeight(mode === 'view' ? this.initHeight : 'auto')
    }
  },
  created() {
    this.setHeight(this.initHeight)
    if (this.source.new) this.changeMode('create')
  },
  methods: {
    setHeight(height) {
      document.querySelector('[aria-label="dialog"]').style.height = height
    },
    changeMode(prop) {
      this.mode = prop
    }
  }
}
</script>

<style lang="scss">
.register-modal-window {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;

  .files-comments {
    grid-gap: 1rem;
  }
}
</style>
