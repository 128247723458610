<template>
  <div class="checklist-inspection-list">
    <div class="checklist-inspection-list__header">
      <el-switch
        v-if="list.isShow"
        v-model="list.isShow.value"
        class="r-switch"
      />
      <r-title
        v-if="list.title"
        type="subtitle-2"
      >
        {{ list.title }}
      </r-title>
    </div>
    <div
      v-if="isShow && !isDocs"
      class="checklist-inspection-list__content"
    >
      <checklist-inspection-list-item
        v-for="item in list.items"
        :key="item.id"
        :item="item"
      />
    </div>
    <div
      v-if="isDocs && list.items"
      class="checklist-inspection-list__content"
    >
      <checklist-docs
        :checklist="list.items"
        :source_id="app_source_id"
        :source="app_source"
        :docs="app_docs"
      />
    </div>
  </div>
</template>

<script>
import checklistInspectionListItem from './checklist-inspection-list-item'
import checklistDocs from '../checklist-docs'

export default {
  components: { checklistInspectionListItem, checklistDocs },
  props: {
    list: {
      type: Object,
      required: true
    },
    dataTitle: {
      type: String,
      default: ''
    },
    app_source: {
      type: Object,
      required: true
    },
    app_docs: {
      type: Array,
      default: () => []
    },
    app_source_id: {
      type: String,
      required: true
    }
  },
  computed: {
    isShow() {
      return this.list.isShow ? this.list.isShow.value : true
    },
    isDocs() {
      return this.dataTitle.includes('документации')
    }
  }
}
</script>

<style lang="scss" scoped>
.checklist-inspection-list {
  display: grid;
  grid-gap: 0.5rem;
  border-bottom: 1px solid var(--field_border);

  &:last-child {
    border-bottom: none;
  }

  &__content {
    display: grid;
    grid-gap: 1rem;
  }
}
</style>
