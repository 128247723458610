const colors = [
  '#59D64E',
  '#F4D340',
  '#2E93FE',
  '#FD0879',
  '#64C7FF',
  '#B33EA9',
  '#F48540',
  '#A771FE',
  '#0043A4',
  '#2E93FE',
  '#4E78D6',
  '#12E6D6',
  '#F85C50',
  '#F375F3',
  '#B40A1B',
  '#4A586E'
]
const colorsGradient = [
  '#2E93FE',
  '#2E93FE85',
  '#2E93FE70',
  '#2E93FE55',
  '#2E93FE40',
  '#2E93FE25',
  '#2E93FE10'
]

export const bar = {
  color: colorsGradient,
  tooltip: {
    trigger: 'axis'
  },
  legend: {
    top: 28,
    left: 'center',
    data: ['1', '2', '3', '4'],
    textStyle: {
      fontSize: 10
    },
    icon: 'line'
  },
  grid: {
    bottom: '25%'
  },
  xAxis: {
    type: 'category',
    data: ['1', '2', '3', '4', '5', '6', '7'],
    splitLine: {
      show: false
    },
    axisLabel: {
      textStyle: {
        fontSize: 10
      }
    }
  },
  yAxis: {
    type: 'value',
    splitLine: {
      show: false
    },
    axisLabel: {
      textStyle: {
        fontSize: 10
      }
    }
  },
  series: [
    {
      name: '1',
      type: 'bar',
      stack: '1',
      data: [320, 302, 301, 334, 390, 330, 320]
    },
    {
      name: '2',
      type: 'bar',
      stack: '2',
      data: [120, 132, 101, 134, 90, 230, 210]
    },
    {
      name: '3',
      type: 'bar',
      stack: '3',
      data: [220, 182, 191, 234, 290, 330, 310]
    },
    {
      name: '4',
      type: 'bar',
      stack: '4',
      data: [150, 212, 201, 154, 190, 330, 410]
    }
  ]
}

export const barX = {
  color: colorsGradient,
  tooltip: {
    trigger: 'axis'
  },
  legend: {
    top: 28,
    left: 'center',
    data: ['1', '2', '3', '4'],
    textStyle: {
      fontSize: 10
    },
    icon: 'line'
  },
  grid: {
    bottom: '20%',
    containLabel: true
  },
  yAxis: {
    type: 'category',
    data: ['1', '2', '3', '4', '5', '6', '7'],
    splitLine: {
      show: false
    },
    axisLabel: {
      textStyle: {
        fontSize: 10
      }
    }
  },
  xAxis: {
    type: 'value',
    splitLine: {
      show: false
    },
    axisLabel: {
      textStyle: {
        fontSize: 10
      }
    }
  },
  series: [
    {
      name: '1',
      type: 'bar',
      stack: '1',
      data: [320, 302, 301, 334, 390, 330, 320]
    },
    {
      name: '2',
      type: 'bar',
      stack: '2',
      data: [120, 132, 101, 134, 90, 230, 210]
    },
    {
      name: '3',
      type: 'bar',
      stack: '3',
      data: [220, 182, 191, 234, 290, 330, 310]
    },
    {
      name: '4',
      type: 'bar',
      stack: '4',
      data: [150, 212, 201, 154, 190, 330, 410]
    }
  ],
  textStyle: {
    fontSize: 10
  }
}

export const basic = {
  color: colors,
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      label: {
        backgroundColor: '#59D64E'
      }
    }
  },
  legend: {
    top: 28,
    left: 'center',
    data: ['m2'],
    textStyle: {
      fontSize: 10
    },
    icon: 'line'
  },
  grid: {
    left: '5%',
    right: '5%',
    bottom: '20%',
    containLabel: true
  },
  xAxis: [
    {
      type: 'category',
      boundaryGap: false,
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      splitLine: {
        show: false
      },
      axisLabel: {
        textStyle: {
          fontSize: 10
        }
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      splitLine: {
        show: false
      },
      axisLabel: {
        textStyle: {
          fontSize: 10
        }
      }
    }
  ],
  series: [
    {
      name: 'm2',
      type: 'line',
      areaStyle: {
        color: '#59D64E',
        opacity: 0.6
      },
      smooth: true,
      data: [82, 93, 90, 93, 129, 133, 132],
      lineStyle: {
        width: 1,
        color: '#59D64E'
      },
      itemStyle: {
        color: '#59D64E',
        borderColor: '#59D64E'
      },
      symbol: 'circle'
    }
  ],
  textStyle: {
    fontSize: 10
  },
  animationDuration: 500
}

export const pie = {
  color: colorsGradient,
  legend: {
    top: 28,
    left: 'center',
    data: ['m2'],
    textStyle: {
      fontSize: 10
    },
    icon: 'circle'
  },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b}: {c} ({d}%)',
    textStyle: {
      fontSize: 12
    }
  },
  series: [
    {
      label: {
        show: false
      },
      name: 'Pie',
      type: 'pie',
      radius: '60%',
      center: ['50%', '50%'],
      data: [
        { value: 335, name: 'Part-1' },
        { value: 310, name: 'Part-2' },
        { value: 234, name: 'Part-3' }
      ],
      itemStyle: {
        borderWidth: 3,
        borderColor: '#292B37'
      }
    }
  ],
  textStyle: {
    fontSize: 10
  },
  animationDuration: 500
}
