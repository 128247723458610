import cloneDeep from 'lodash.clonedeep'
import throttle from 'lodash.throttle'
import debounce from 'lodash.debounce'
import { Constants, CommonSelectors } from '@/libs/map-draw/lib-utils'
import {
  getFeaturesByLayerId,
  createHoveredSPHelper,
  removeHoveredSPHelpers,
  createRouteHelper,
  clearAllHelpers,
  createRoutePoints,
  clearAllRouteGeometry,
  getWaypointsQuery,
  getNearestFeature
} from '@/libs/map-draw/helpers'

import { rDate } from '@/utils'

export const createODDDrawLinesMode = (MapboxDraw, map) => {
  const DrawLines = {}
  const customState = {
    currentPoint: null,
    routePoints: [],
    currentRouteCoordinates: [],
    fixedRouteCoordinates: [],
    lineRouteItems: [],
    isLastSP: false
  }

  const clearCustomState = () => {
    customState.routePoints = []
    customState.currentPoint = null
    customState.currentRouteCoordinates = []
    customState.fixedRouteCoordinates = []
    customState.lineRouteItems = []
    customState.isLastSP = false
  }
  const showError = throttle(() => {
    map.$message({
      message:
        'Не удается построить дальнейший маршрут из последней указанной точки',
      type: 'error',
      duration: 1500,
      customClass: 'route-editor-warning'
    })
  }, 1000)

  const { model, editorState, books } = map.$store.state.odd
  const { ids } = model
  const { eventClasses } = books

  map.helpers.clearCustomState = clearCustomState

  DrawLines.onKeyUp = async function(state, e) {
    const cancelDrawing = () => {
      this.deleteFeature([state.line.id], { silent: true })
      editorState.isDrawing = false
      clearCustomState()
      clearAllRouteGeometry(this.map)
      this.updateUIClasses({ mouse: 'add' })

      // return this.changeMode(Constants.modes.SIMPLE_SELECT, {
      //   featureIds: [state.line.id]
      // })
      return this.changeMode('draw_lines')
    }
    if (CommonSelectors.isEscapeKey(e)) {
      cancelDrawing()
    }

    const { routePoints } = customState
    const { code, ctrlKey } = e
    if (code === 'KeyZ' && ctrlKey) {
      if (routePoints.length === 0) return
      else if (routePoints.length === 1) {
        cancelDrawing()
        return
      } else if (routePoints.length === 2) {
        customState.routePoints = [routePoints[0]]
        clearAllRouteGeometry(this.map)
        createRoutePoints(
          this.map,
          customState.routePoints.map(c => c.coordinates)
        )
        clearAllHelpers(this.map)
        return
      }

      const points = customState.routePoints.slice(0, -1)
      const locString = getWaypointsQuery(cloneDeep(points))
      const url = `pubtran_route/${model.id}/${locString}?postprocessing=line_route_item`
      const { data } = await map.$store.dispatch('ROUTE_REQUEST', {
        url
      })
      const { geometry, line_route_items } = data

      line_route_items.forEach(item => {
        delete item.node
        delete item.stop_point
      })

      customState.routePoints = points
      customState.currentRouteCoordinates = geometry.coordinates
      customState.fixedRouteCoordinates = cloneDeep(geometry.coordinates)
      customState.lineRouteItems = line_route_items

      createRouteHelper(this.map, geometry.coordinates)
      createRoutePoints(
        this.map,
        customState.routePoints.map(c => c.coordinates)
      )
    }

    if (code === 'Enter') {
      if (routePoints.length < 2) return

      const { fixedRouteCoordinates } = customState

      customState.currentRouteCoordinates = cloneDeep(fixedRouteCoordinates)
      createRouteHelper(this.map, fixedRouteCoordinates)

      this.finishRoute(state)
    }
  }

  DrawLines.makeRoutingRequest = debounce(async function(currentPoints) {
    try {
      const locString = getWaypointsQuery(cloneDeep(currentPoints))
      const url = `pubtran_route/${model.id}/${locString}?postprocessing=line_route_item`

      const { data } = await map.$store.dispatch('ROUTE_REQUEST', {
        url
      })
      let { geometry, line_route_items } = data

      line_route_items = line_route_items.map(point => {
        const geom = point.node_id ? point.node : point.stop_point
        delete point.node
        delete point.stop_point

        return { ...point, geom }
      })

      customState.currentRouteCoordinates = cloneDeep(geometry.coordinates)
      customState.lineRouteItems = cloneDeep(line_route_items)

      createRouteHelper(this.map, geometry.coordinates)
    } catch (error) {
      console.log(error)
      showError(map)
    }
  }, 100)

  DrawLines.finishRoute = function(state) {
    const { routePoints, currentRouteCoordinates } = customState

    editorState.isDrawing = false

    const newCoordinates = [
      routePoints[0].coordinates,
      ...currentRouteCoordinates
    ]

    state.line.setCoordinates(newCoordinates)
    clearCustomState()

    state.line.setProperty(
      'waypoints',
      routePoints.map(({ id }) => id)
    )

    // set properties
    state.line.setProperty(
      'name',
      `Новое перекрытие ${rDate.format(new Date(), 'DD.MM.YY')}`
    )
    state.line.setProperty('event_class_id', eventClasses[0].id)
    state.line.setProperty('closed_line_count', 1)
    state.line.setProperty('address', null)
    state.line.setProperty('requested', false)
    state.line.setProperty('start_time', null)
    state.line.setProperty('end_time', null)

    // set mode
    this.changeMode(Constants.modes.SIMPLE_SELECT, {
      featureIds: [state.line.id]
    })
    map.$store.commit('SET_ODD_EDITOR_PROP', {
      field: 'mode',
      value: 'select'
    })
    map.$store.commit('SET_ODD_EDITOR_PROP', {
      field: 'editByRouteGeom',
      value: newCoordinates
    })

    clearAllRouteGeometry(this.map)
    removeHoveredSPHelpers(this.map)
  }

  DrawLines.onMouseMove = function(state, e) {
    const stopPointFeatures = getFeaturesByLayerId(this.map, e, ids.stop_points)
    const nodesFeatures = getFeaturesByLayerId(this.map, e, ids.nodes)
    const pointFeatures = [...stopPointFeatures, ...nodesFeatures]
    const { routePoints, fixedRouteCoordinates } = customState

    if (pointFeatures.length) {
      // find nearest feature
      const { lng, lat } = e.lngLat

      const feature = getNearestFeature(pointFeatures, [lng, lat])
      const { properties } = feature
      const { coordinates } = feature.geometry

      if (
        !customState.currentPoint ||
        customState.currentPoint !== feature.properties.id
      ) {
        createHoveredSPHelper(this.map, coordinates, 'green')
        customState.currentPoint = feature.properties.id

        if (routePoints.length > 0) {
          const currentPoints = [
            ...cloneDeep(routePoints),
            cloneDeep({
              coordinates,
              id: properties.id
            })
          ]
          this.makeRoutingRequest(currentPoints)
        }
      }
    } else {
      if (routePoints.length > 1) {
        createRouteHelper(this.map, fixedRouteCoordinates)
      } else createRouteHelper(this.map, [])

      removeHoveredSPHelpers(this.map)
      customState.currentPoint = null
    }

    if (CommonSelectors.isVertex(e)) {
      this.updateUIClasses({ mouse: Constants.cursors.POINTER })
    }
  }

  DrawLines.clickAnywhere = function(state, e) {
    const stopPointFeatures = getFeaturesByLayerId(this.map, e, ids.stop_points)
    const nodesFeatures = getFeaturesByLayerId(this.map, e, ids.nodes)
    const pointFeatures = [...stopPointFeatures, ...nodesFeatures]
    const { routePoints, currentRouteCoordinates } = customState

    if (!pointFeatures.length) return

    if (routePoints.length === 0) editorState.isDrawing = true

    const { lng, lat } = e.lngLat
    const feature = getNearestFeature(pointFeatures, [lng, lat])
    const pointLayer = feature.layer
    const pointId = feature.properties.id
    const pointCoordinates = feature.geometry.coordinates
    const lastPoint = routePoints[routePoints.length - 1] || {}
    const lastCoords = lastPoint.coordinates
    const stopPointsLayer = model.children.find(
      l => l.datatype === 'stop_points'
    )

    if (routePoints.length > 0) {
      if (
        pointCoordinates[0] === lastCoords[0] &&
        pointCoordinates[1] === lastCoords[1]
      ) {
        this.finishRoute(state)

        return
      }
    }

    routePoints.push({ coordinates: pointCoordinates, id: pointId })
    customState.fixedRouteCoordinates = cloneDeep(currentRouteCoordinates)
    customState.isLastSP = pointLayer.id === stopPointsLayer.id
    createRoutePoints(
      this.map,
      routePoints.map(c => c.coordinates)
    )

    this.updateUIClasses({ mouse: 'add' })
  }

  return { ...MapboxDraw.modes.draw_line_string, ...DrawLines }
}
