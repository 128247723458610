import {
  createClassesValue,
  createRangeValue,
  createExtrusionRangeValue,
  createStringsValue,
  getLayerColor,
  getColorValueWithActive,
  getLayerWidth
} from '../helpers'

export class StyleController {
  constructor(map) {
    this.map = map
    this.mapgl = map.mapgl
    this.styleConfig = map.$store.state.map.styleConfig
  }

  getInitialStyling(id, type) {
    const { activeCard } = this.map.$store.state.commonData
    const config = this.styleConfig[id]
    if (!config) return
    const { dashed } = config
    let initialStyling

    switch (type) {
      case 'circle':
        initialStyling = {
          'circle-color': getColorValueWithActive(
            activeCard.map,
            getLayerColor(config, 'circle-color')
          ),
          'circle-radius': getLayerWidth(config, 'circle-radius')
        }
        break
      case 'line':
        initialStyling = {
          'line-color': getColorValueWithActive(
            activeCard.map,
            getLayerColor(config, 'line-color')
          ),
          'line-width': getLayerWidth(config, 'line-width')
        }
        if (dashed && dashed.enabled) initialStyling['line-dasharray'] = [1, 4]
        break
      case 'fill':
        initialStyling = {
          'fill-color': getColorValueWithActive(
            activeCard.map,
            getLayerColor(config, 'fill-color')
          )
        }
    }

    return initialStyling
  }

  changeProperty(id, type, field) {
    const mapLayer = this.mapgl.getLayer(id)
    if (!mapLayer) return

    const style = this.styleConfig[id].style
    let value = style[type][field]
    if (!value) return
    if (!this.mapgl.getLayer(id)) return

    if (field.indexOf('color') > -1) {
      value = getColorValueWithActive(
        this.map.$store.state.commonData.activeCard.map,
        value
      )
    }

    const method = type === 'paint' ? 'setPaintProperty' : 'setLayoutProperty'
    this.mapgl[method](id, field, value)

    // set fill extrusion property
    if (this.mapgl.getLayer(`${id}_fill_extrusion`)) {
      if (field === 'fill-opacity') {
        this.mapgl.setPaintProperty(
          `${id}_fill_extrusion`,
          'fill-extrusion-opacity',
          value
        )
      }
      if (field === 'fill-color') {
        this.mapgl.setPaintProperty(
          `${id}_fill_extrusion`,
          'fill-extrusion-color',
          value
        )
      }
    }

    // set line arrows property
    if (this.mapgl.getLayer(`${id}_arrows`)) {
      if (field === 'line-color') {
        this.mapgl.setPaintProperty(`${id}_arrows`, 'text-color', value)
      }
    }
  }

  toggleLineProperty(id, type) {
    const mapLayer = this.mapgl.getLayer(id)
    if (!mapLayer) return

    const style = this.styleConfig[id]
    const value = style[type].enabled

    switch (type) {
      case 'dashed':
        if (value) this.mapgl.setPaintProperty(id, 'line-dasharray', [1, 4])
        else this.mapgl.setPaintProperty(id, 'line-dasharray', null)
        break
    }
  }

  changeColorClasses(id, property) {
    const config = this.styleConfig[id].classesStyle
    const { field, conditions } = config[property]
    if (!field || !conditions.length) return
    if (!this.mapgl.getLayer(id)) return
    let value = createClassesValue(field, conditions)

    value = getColorValueWithActive(
      this.map.$store.state.commonData.activeCard.map,
      value
    )

    this.map.isUpdate = !this.map.isUpdate
    this.mapgl.setPaintProperty(id, property, value)

    // set fill extrusion property
    if (
      property === 'fill-color' &&
      this.mapgl.getLayer(`${id}_fill_extrusion`)
    ) {
      this.mapgl.setPaintProperty(
        `${id}_fill_extrusion`,
        'fill-extrusion-color',
        value
      )
    }
    // set line arrows property
    if (this.mapgl.getLayer(`${id}_arrows`)) {
      if (property === 'line-color') {
        this.mapgl.setPaintProperty(`${id}_arrows`, 'text-color', value)
      }
    }
  }

  changeColorStrings(id, property) {
    const config = this.styleConfig[id].stringsStyle[property]
    const { field, defaultValue, values } = config
    if (!field || !defaultValue) return
    if (!this.mapgl.getLayer(id)) return
    let value = createStringsValue(field, values, defaultValue)

    value = getColorValueWithActive(
      this.map.$store.state.commonData.activeCard.map,
      value
    )

    this.map.isUpdate = !this.map.isUpdate
    this.mapgl.setPaintProperty(id, property, value)

    // set fill extrusion property
    if (
      property === 'fill-color' &&
      this.mapgl.getLayer(`${id}_fill_extrusion`)
    ) {
      this.mapgl.setPaintProperty(
        `${id}_fill_extrusion`,
        'fill-extrusion-color',
        value
      )
    }
    // set line arrows property
    if (this.mapgl.getLayer(`${id}_arrows`)) {
      if (property === 'line-color') {
        this.mapgl.setPaintProperty(`${id}_arrows`, 'text-color', value)
      }
    }
  }

  changeRange(id, property) {
    const config = this.styleConfig[id].classesStyle
    const { field, fieldMin, fieldMax, range } = config[property]
    if (!field || (range[0] === 0 && range[1] === 0)) return
    if (!this.mapgl.getLayer(id)) return
    if (fieldMin === 0 && fieldMax === 0) return
    const value = createRangeValue(field, range, fieldMin, fieldMax)

    this.mapgl.setPaintProperty(id, property, value)
  }

  toggleFillExtrusion(id) {
    const layerId = `${id}_fill_extrusion`
    const config = this.styleConfig[id]

    const { style, fillExtrusion, classesStyle } = config
    const { paint, fieldMin, fieldMax } = fillExtrusion
    const { field, conditions } = classesStyle['fill-color']
    const color = field
      ? createClassesValue(field, conditions)
      : style.paint['fill-color']
    const opacity = style.paint['fill-opacity']
    const value = fillExtrusion.field
      ? createExtrusionRangeValue(
        fillExtrusion.field,
        fieldMin,
        fieldMax,
        paint['fill-extrusion-height']
      )
      : paint['fill-extrusion-height']

    if (!this.mapgl.getLayer(layerId)) {
      this.mapgl.addLayer({
        id: layerId,
        type: 'fill-extrusion',
        source: id,
        paint: {
          ...paint,
          'fill-extrusion-height': value,
          'fill-extrusion-color': color,
          'fill-extrusion-opacity': opacity,
          'fill-extrusion-vertical-gradient': false
        }
      })
    } else {
      this.mapgl.removeLayer(layerId)
    }
  }

  changeFillExtrusionProperty(id, property) {
    const layerId = `${id}_fill_extrusion`
    const config = this.styleConfig[id].fillExtrusion
    const { paint } = config
    const value = paint[property]
    if (!value) return
    if (!this.mapgl.getLayer(layerId)) return

    this.mapgl.setPaintProperty(layerId, property, value)
  }

  changeFillExtrusionRange(id, property) {
    const layerId = `${id}_fill_extrusion`
    const config = this.styleConfig[id].fillExtrusion
    const { fieldMin, fieldMax, field, paint } = config
    if (!field || (fieldMin === 0 && fieldMax === 0)) return
    if (!this.mapgl.getLayer(layerId)) return

    const value = createExtrusionRangeValue(
      field,
      fieldMin,
      fieldMax,
      paint['fill-extrusion-height']
    )

    this.mapgl.setPaintProperty(layerId, property, value)
  }

  changePolygonBorder(id, type, field) {
    const layerId = `${id}_border`
    const config = this.styleConfig[id].borderStyle
    const value = config[type][field]

    const method = type === 'paint' ? 'setPaintProperty' : 'setLayoutProperty'
    this.mapgl[method](layerId, field, value)
  }
}
