export const geomTypesConfig = {
  point: 'point',
  multi_point: 'point',
  line_string: 'line_string',
  multi_line_string: 'line_string',
  polygon: 'polygon',
  multi_polygon: 'polygon',
  nodes: 'nodes',
  links: 'links',
  zones: 'zones',
  connectors: 'connectors',
  stop_points: 'stop_points',
  line_routes: 'line_routes',
  lines: 'lines'
}

export const specialStylingTypes = ['connectors', 'stop_points', 'line_routes', 'lines']

export const modelTypes = ['nodes', 'links', 'zones', 'connectors']
