<template>
  <div class="m-filters-settings">
    <!-- <sorting
      :id="id"
      :layer-style="layerStyle"
      :controllers="controllers"
    /> -->
    <filters
      :id="id"
      style="margin-top: 16px;"
      :layer-style="layerStyle"
      :controllers="controllers"
    />
  </div>
</template>

<script>
import Filters from './filters'
// import Sorting from '../sorting/sorting'

export default {
  components: {
    Filters
    // Sorting
  },
  props: {
    id: {
      type: String,
      required: true
    },
    layerStyle: {
      type: Object,
      required: true
    },
    controllers: {
      type: Object,
      required: true
    }
  }
}
</script>

<style></style>
