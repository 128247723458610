<template>
  <odd />
</template>

<script>
import odd from '@/components/odd'

export default {
  components: { odd }
}
</script>
