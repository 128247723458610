<template>
  <div class="rp-content">
    <component :is="`rp-${activeModule}`" />
  </div>
</template>

<script>
import rpUsers from '../rp-users/rp-users'
import rpRoles from '../rp-roles/rp-roles'

export default {
  components: {
    rpUsers,
    rpRoles
  },
  computed: {
    activeModule() {
      return this.$store.state.rolePermission.activeModule || 'users'
    }
  }
}
</script>

<style lang="scss">
.rp-content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
