<template>
  <div class="ee-card-attribute input">
    <r-text type="caption">
      {{ config.label }}
    </r-text>
    <el-input
      v-model="feature[config.prop]"
      class="r-input"
      size="mini"
      :disabled="config.disabled"
    />
  </div>
</template>

<script>
export default {
  props: {
    feature: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      required: true
    }
  }
}
</script>

<style></style>
