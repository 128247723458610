<template>
  <r-main-panel>
    <div class="pt-router">
      <div class="pt-router__header">
        <r-title
          type="subtitle-1"
          class="pt-router__header-title"
          color-type="subhead"
        >
          Построить маршрут
        </r-title>
        <r-button
          class="pt-router__header-button"
          @click="$router.push('/app/public-transport/view')"
        >
          Выйти из режима
        </r-button>
      </div>
      <div class="pt-router__build">
        <router-build />
      </div>
    </div>
  </r-main-panel>
</template>

<script>
import RouterBuild from '@/components/public-transport/router/router-build.vue'
export default {
  components: { RouterBuild },
  data() {
    return {
      tab: 'routes'
    }
  }
}
</script>

<style lang="scss">
.pt-router {
  &__header {
    padding: 0.5rem;
    display: grid;
    grid-auto-flow: row;

    &-title {
      border-bottom: 1px solid var(--dividers_low_contrast);
      text-align: center;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}
</style>
