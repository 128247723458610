import { mapColors } from '@/config/colors'

export const layersConfig = {
  vehicles_clusters: {
    type: 'circle',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'step',
        ['get', 'point_count'],
        'rgba(153,213,148, 1)',
        10,
        'rgba(254,224,139, 1)',
        50,
        'rgba(252,141,89, 1)',
        100,
        'rgba(213,62,79, 1)'
      ],
      'circle-radius': [
        'step',
        ['get', 'point_count'],
        10,
        20,
        14,
        50,
        18
      ]
    }
  },
  vehicles_clusters_count: {
    type: 'symbol',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium'],
      'text-size': 11,
      'text-allow-overlap': true,
      'icon-allow-overlap': true
    }
  },
  vehicles: {
    type: 'symbol',
    filter: ['!', ['has', 'point_count']],
    layout: {
      'icon-image': 'aghk-bus-default',
      'icon-pitch-alignment': 'map',
      'icon-rotate': ['get', 'course'],
      'icon-rotation-alignment': 'map',
      'icon-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        0.25,
        14,
        0.5,
        22,
        0.8
      ],
      'icon-ignore-placement': true,
      'text-allow-overlap': true,
      'icon-allow-overlap': true
    },
    paint: {
      'icon-opacity': [
        'match',
        ['to-string', ['get', 'online']],
        'true',
        1,
        0.5
      ]
    }
  },
  nodes: {
    type: 'circle',
    layout: {},
    paint: {
      'circle-radius': 2,
      'circle-color': mapColors.baseColor,
      'circle-pitch-alignment': 'map',
      'circle-opacity': 0.5
    }
  },
  links: {
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': mapColors.baseColor,
      'line-width': 1,
      'line-opacity': 0.9
    }
  },
  // stop points
  stop_points: {
    type: 'circle',
    layout: {},
    paint: {
      'circle-radius': 7,
      'circle-color': mapColors.ptRouteColor,
      'circle-pitch-alignment': 'map',
      'circle-stroke-color': mapColors.white,
      'circle-stroke-width': 1,
      'circle-stroke-opacity': 0.8
    }
  },
  stop_points_icon: {
    type: 'symbol',
    layout: {
      'icon-image': 'stop-point',
      'icon-pitch-alignment': 'map',
      'icon-size': 0.2,
      'icon-ignore-placement': true
    }
  },
  stop_points_projections: {
    type: 'circle',
    layout: {},
    paint: {
      'circle-radius': 2,
      'circle-color': mapColors.white,
      'circle-pitch-alignment': 'map',
      'circle-opacity': 1,
      'circle-stroke-width': 1,
      'circle-stroke-color': mapColors.white
    }
  },
  stop_points_connections: {
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': mapColors.white,
      'line-width': 1,
      'line-dasharray': [2, 4]
    }
  },
  // fixed stop points
  fixed_stop_points: {
    type: 'circle',
    paint: {
      'circle-color': 'white',
      'circle-radius': 8,
      'circle-stroke-color': '#D94141',
      'circle-stroke-width': 2
    }
  },
  fixed_stop_points_icon: {
    type: 'symbol',
    layout: {
      'icon-image': 'fixed-stop-point',
      'icon-pitch-alignment': 'map',
      'icon-size': 0.2,
      'icon-ignore-placement': true
    }
  },
  lines: {
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': ['get', 'color'],
      'line-width': 2
    }
  },
  point: {
    type: 'symbol',
    layout: {
      'icon-allow-overlap': true,
      'icon-image': 'stop-point'
    }
  },
  label: {
    type: 'symbol',
    paint: {
      'text-halo-width': 15,
      'text-halo-color': '#2e93fe',
      'text-color': '#fff'
    },
    layout: {
      'text-field': ['get', 'name'],
      'text-padding': 5,
      'text-font': [
        'Open Sans Bold',
        'Arial Unicode MS Bold'
      ],
      'text-radial-offset': 0.5,
      'text-size': 15,
      'text-justify': 'auto'
    }
  },
  combined: {
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-width': ['get', 'width'],
      'line-color': ['get', 'color']
    }
  }
}
