<template>
  <el-dialog
    :class="
      `r-modal-window main ${modalWindowType} ${noTitle ? 'no-title' : ''}`
    "
    :title="!noTitle ? $t(modalWindowName) : null"
    :width="window.width"
    :top="window.top"
    :visible="isVisible"
    :before-close="beforeClose"
    destroy-on-close
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <component :is="modalWindowName" />
  </el-dialog>
</template>

<script>
import About from '@/components/about/about'
import Account from '@/components/account/account'
import DsManager from '@/components/ds-manager/ds-manager.vue'
import SettingsReset from '@/components/settings-reset/settings-reset'
import BookFilter from '@/components/book/components/book-filter/filter-settings'
import CreateObject from '@/components/create-object/create-object'
import DsExport from '@/components/ds-export/ds-export'
import UserTasks from '@/components/tasks/components/tasks-modal/tasks-modal'
import ScModalWindow from '@/components/situation-center/components/modules/modal-windows/basic-modal-window'
import Profiles from '@/components/profiles/profiles-settings/profiles'
import PublicNotifications from '@/components/notifications/public-notifications/public-notifications'
import EmailDistributions from '@/components/distributions/email-distributions/email-distributions'
import BddApplicationsModal from '@/components/bdd/applications/app-modal/app-modal'
import BddRestrictionModal from '@/components/bdd/restrictions-register/components/modal/restriction-modal'
import BddPreventiveModal from '@/components/bdd/preventive-measures/components/modal/modal'
import BddViolationsModal from '@/components/bdd/violations-register/components/modal/modal'
import BddViolationsCreate from '@/components/bdd/violations-register/components/modal-create/modal'
import BddPassRegisterModal from '@/components/bdd/pass-register/components/modal/modal'
import BddActsModal from '@/components/bdd/acts/components/modal'
import TelematicsModal from '@/components/monitoring/card/tabs/telematics/modal'
import AttributesModal from '@/components/attributes-editor/attributes-editor-modal'
import RpAttrFilter from '@/components/role-permission/components/rp-attr-filter/rp-attr-filter'
import RpUserCreateRole from '@/components/role-permission/components/rp-users/rp-user-create-role'
import PotModalCard from '@/components/pot-1/modal'
import OddModalCard from '@/components/odd/modal'

import { setWindowOptions } from './helpers'

export default {
  components: {
    About,
    Account,
    DsManager,
    SettingsReset,
    BookFilter,
    CreateObject,
    DsExport,
    UserTasks,
    ScModalWindow,
    Profiles,
    BddApplicationsModal,
    EmailDistributions,
    PublicNotifications,
    BddRestrictionModal,
    BddPreventiveModal,
    BddPassRegisterModal,
    BddViolationsModal,
    BddViolationsCreate,
    BddActsModal,
    TelematicsModal,
    AttributesModal,
    RpAttrFilter,
    RpUserCreateRole,
    PotModalCard,
    OddModalCard
  },
  data() {
    return {
      modalWindowType: 'default',
      noTitle: false,

      window: {
        width: '',
        top: ''
      }
    }
  },
  computed: {
    isVisible() {
      return this.$store.state.modalWindow.isShow
    },
    modalWindowName() {
      return this.$store.state.modalWindow.name
    },
    isActive() {
      return this.$store.state.modalWindow.isActive
    }
  },
  watch: {
    modalWindowName: {
      handler: function(val) {
        setWindowOptions(this, val)
      },
      deep: true,
      immediate: true
    }
  },
  beforeDestroy() {
    document.removeEventListener('mousedown', this.checkClose, false)
  },
  mounted() {
    document.addEventListener('mousedown', this.checkClose, false)
  },
  methods: {
    checkClose(e) {
      const classList = [...e.target.classList]
      const triggers = ['el-dialog__wrapper', 'r-modal-window', 'main']
      if (triggers.every(t => classList.includes(t))) {
        this.beforeClose()
      }
    },
    beforeClose() {
      const title = this.$t('before:title')
      const message = this.$t('before-cancel:text')
      const confirmButtonText = this.$t('button-confirm')
      const cancelButtonText = this.$t('button-cancel')

      if (this.isActive) {
        this.$confirm(message, title, {
          customClass: 'r-message-box',
          closeOnPressEscape: true,
          closeOnClickModal: false,
          type: 'warning',
          confirmButtonText,
          cancelButtonText
        })
          .then(() => {
            this.closeWindow()
          })
          .catch(() => {})
      } else {
        this.closeWindow()
      }
    },
    closeWindow() {
      document.removeEventListener('mousedown', this.checkClose, false)
      this.$store.commit('CLOSE_MODAL_WINDOW', '')
      this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', false)
    }
  }
}
</script>

<i18n>
{
  "ru": {
    "about": "Информация о системе",
    "account": "Данные пользователя",
    "ds-manager": "Менеджер источников данных",
    "settings-reset": "Сброс настроек профиля",
    "updates": "Обновления",
    "book-filter": "Настройки фильтра",
    "book-filter-test": "Настройки фильтра",
    "create-object": "Создать новый объект",
    "ds-export": "Экспорт данных",
    "user-tasks": "Пользовательские задачи",
    "rp-attr-filter": "Настроить объекты источника данных",
    "rp-user-create-role": "Вы изменили права пользователя",

    "before:title": "Предупреждение",
    "before-cancel:text": "Все изменения будут отменены. Вы уверены?",
    "button-confirm": "Да",
    "button-cancel": "Отмена"
  },
  "en": {
    "about": "Information",
    "account": "Account",
    "ds-manager": "Datasource manager",
    "settings-reset": "Reset profile settings",
    "updates": "Updates",
    "book-filter": "Filter settings",
    "book-filter-test": "Filter settings",
    "create-object": "Create new object",
    "ds-export": "Data export",
    "user-tasks": "User tasks",
    "rp-attr-filter": "Настроить объекты источника данных",

    "before:title": "Warning",
    "before-cancel:text": "All changes will be discarded. Are you sure?",
    "button-confirm": "Yes",
    "button-cancel": "Cancel"
  }
}
</i18n>
