<template>
  <div class="pt-route-schedule">
    <div
      v-if="stops.length"
      class="pt-route-schedule__container"
    >
      <st-header
        :schedule="schedule"
        :current-line-id="currentLineId"
        :creating-new-line="creatingNewLine"
        :new-line-time="newLineTime"
        :saving="saving"
        @new="newProp"
        @add="handleNewLine"
        @line="handleLine"
        @delete="deleteJourney"
        @send="setSchedule"
      />
      <st-body
        :checked-ids="checkedIds"
        :stops="stops"
        :schedule="schedule"
        :current="currentLineIndex || 0"
        @new="newProp"
        @fixChanges="$emit('fixChanges')"
      />
    </div>
    <r-text
      v-else
      class="pt-route-schedule__nodata"
      type="caption"
    >
      Перед составлением расписания необходимо составить маршрут на карте
    </r-text>
  </div>
</template>

<script>
import stHeader from './schedule-table-header'
import stBody from './schedule-table-body'
import { isString } from '@/utils'

export default {
  components: {
    stHeader,
    stBody
  },
  data() {
    return {
      checkedIds: [],
      schedule: [],
      creatingNewLine: false,
      idCounter: 0,
      currentLineId: null,
      newLineTime: null,
      saving: false,
      deletedLines: []
    }
  },
  computed: {
    source_ids() {
      const { veh_journey_items, veh_journeys } =
        this.$store.state.publicTransport.model.ids
      return { veh_journey_items, veh_journeys }
    },
    stops() {
      const { lineRouteItems, timeProfile } = this.$store.state.publicTransport

      if (!timeProfile) return []

      return timeProfile.time_profile_items.map(
        ({ lr_item_index, arr, dep }, i) => {
          const range = [arr?.slice(0, 5), dep?.slice(0, 5)]
          const lineRouteItem = lineRouteItems.find(
            ({ index }) => index === lr_item_index
          )

          return {
            name:
              lineRouteItem?.stop_point?.name ||
              `Остановка №${lineRouteItem?.stop_point?.no || '—'}`,
            id: lineRouteItem?.stop_point?.id || `id:${i}`,
            stop_point_id: lr_item_index,
            range
          }
        }
      ).sort((a, b) => a.stop_point_id - b.stop_point_id)
    },
    currentLineIndex() {
      return this.schedule.findIndex(i => i.id === this.currentLineId) || 0
    },
    update() {
      const { needToUpdateSchedule, lineRouteId } =
        this.$store.state.publicTransport

      return needToUpdateSchedule + lineRouteId
    }
  },
  watch: {
    update() {
      this.init()
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const { lineRouteItems, vehicleJourneys } =
        this.$store.state.publicTransport

      this.schedule = vehicleJourneys.map(({ id, dep, veh_journey_items }) => ({
        id,
        name: dep,
        stopPoints: veh_journey_items
          .map(({ stop_index, id, vehicle_journey_id, arr, dep }) => {
            const range = [arr?.slice(0, 5), dep?.slice(0, 5)]
            const lineRouteItem = lineRouteItems.find(
              ({ index }) => index === stop_index
            )

            return {
              name:
                lineRouteItem?.stop_point?.name ||
                `Остановка № ${lineRouteItem?.stop_point?.no || '—'}`,
              index: stop_index,
              id,
              range,
              vehicle_journey_id,
              checked: true
            }
          })
          .sort((a, b) => a.index - b.index)
      }))

      if (this.schedule.length) {
        this.currentLineId = this.schedule[0].id
        this.checkedIds = this.schedule[0].stopPoints.map(i => i.id)
      }
    },
    timeAddition(time, addition) {
      const iHh = +time.slice(0, 2)
      const iMm = +time.slice(3, 5)
      const aHh = +addition.slice(0, 2)
      const aMm = +addition.slice(3, 5)
      let fHh = iHh + aHh > 23 ? iHh + aHh - 24 : iHh + aHh
      let fMm = iMm + aMm
      if (fMm > 59) {
        fHh++
        fMm -= 60
      }
      return `${fHh < 10 ? '0' + fHh : fHh}:${fMm < 10 ? '0' + fMm : fMm}`
    },
    newProp(p) {
      const [prop, value] = p
      this[prop] = value
    },
    handleLine(id) {
      this.currentLineId = id
      this.checkedIds = this.schedule[this.currentLineIndex].stopPoints.map(
        i => i.id
      )
    },
    handleNewLine() {
      const time = this.schedule[0]?.name || '07:00'

      this.schedule.push({
        id: this.idCounter,
        name: time,
        stopPoints: this.stops.map(s => ({
          ...s,
          checked: true,
          range: [
            this.timeAddition(time, s.range[0]),
            this.timeAddition(time, s.range[1])
          ]
        }))
      })

      this.currentLineId = this.idCounter
      this.idCounter++
      this.newLineTime = null
    },
    deleteJourney() {
      this.$confirm('Удалить рейс?', {
        customClass: 'r-message-box',
        type: 'warning',
        closeOnClickModal: false,
        confirmButtonText: 'Да'
      })
        .then(() => {
          if (isString(this.currentLineId)) {
            this.deletedLines.push(this.currentLineId)
          }
          this.schedule = this.schedule.filter(i => i.id !== this.currentLineId)
          this.currentLineId = this.schedule[0].id
          this.checkedIds = this.schedule[0].stopPoints.map(i => i.id)
          this.$emit('fixChanges')
        })
        .catch(() => {})
    },
    async saveJorneyItems(data) {
      const url = `objectInfo/${this.source_ids.veh_journey_items}`
      await this.$store.dispatch('POST_REQUEST', {
        url,
        data
      })
    },
    async deleteJorneys(items) {
      items.forEach(async d => {
        await this.$store.dispatch('DELETE_REQUEST', {
          url: `objectInfo/${this.source_ids.veh_journeys}?id=${d}`
        })
      })
    },
    async saveJorneys(data) {
      const url = `objectInfo/${this.source_ids.veh_journeys}`
      await this.$store.dispatch('POST_REQUEST', {
        url,
        data
      })
    },
    async setSchedule() {
      try {
        this.saving = true
        const scheduleData = this.formatSchedule()
        const newJourneysData = scheduleData.filter(s => !s.id)

        const existJourneyItemsData = scheduleData
          .filter(s => isString(s.id))
          .map(d => d.veh_journey_items)
          .flat()
          .filter(s => !!s)

        if (newJourneysData.length) {
          await this.saveJorneys(newJourneysData)
        }

        if (this.deletedLines.length) {
          await this.deleteJorneys(this.deletedLines)
        }

        if (existJourneyItemsData.length) {
          await this.saveJorneyItems(existJourneyItemsData)
        }
      } catch (e) {
        throw new Error(e)
      } finally {
        this.saving = false
        this.$store.commit('SET_PT_FIELD', {
          field: 'needToUpdateSchedule',
          value: true
        })
        this.$emit('close')
      }
    },
    formatSchedule() {
      const { timeProfile, lineRouteId } = this.$store.state.publicTransport

      return this.schedule.map(journey => ({
        line_route_id: lineRouteId,
        time_profile_id: timeProfile.id,
        arr: `${journey.stopPoints[0].range[1]}:00`.slice(0, 8),
        dep: `${journey.stopPoints[0].range[1]}:00`.slice(0, 8),
        id: isString(journey.id) ? journey.id : undefined,
        veh_journey_items: journey.stopPoints.map((vji, i) => {
          const isLast = i === journey.stopPoints.length - 1
          const [rArr, rDep] = vji.range
          const arr = `${rArr}:00`
          const dep = `${isLast ? this.timeAddition(rArr, '00:02') : rDep}:00`

          return {
            arr,
            dep,
            vehicle_journey_id: vji.vehicle_journey_id,
            id: vji.id
          }
        })
      }))
    }
  }
}
</script>

<style lang="scss">
.pt-route-schedule {
  height: 100%;
  align-content: start;

  &__container {
    display: grid;
    grid-template-areas: 'header' 'setup';
    grid-gap: 0.5rem 1.5rem;
    grid-template-columns: 100%;
    position: relative;
  }

  &__control {
    align-self: start;
  }
}
</style>
