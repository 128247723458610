<template>
  <div
    v-if="state.modelLayers.length"
    class="map-editor-model"
  >
    <r-text type="caption">
      Слои модели
    </r-text>
    <div class="map-editor-model__container">
      <ul class="map-editor-model__list">
        <li
          v-for="item in state.modelLayers"
          :key="item.id"
          :class="[
            'map-editor-model__item',
            state.id === item.id ? 'active' : ''
          ]"
          @click="toggleEditingModelLayer(item.id, item.datatype)"
        >
          <div class="map-editor-model__info">
            <r-icon
              v-if="!item.loading"
              name="folder-layer"
              :size="16"
              :color="state.id === item.id ? '#fff' : undefined"
            />
            <span
              v-else
              class="el-icon-loading"
            />
            <div class="map-editor-model__name">
              {{ item.name }}
            </div>
          </div>
          <div
            class="map-editor-model__visible"
            @click.stop="toggleVisibleModelLayer(item.id)"
          >
            <span
              v-if="state.id === item.id || item.visible"
              class="el-icon-view"
            />
          </div>
        </li>
      </ul>
    </div>
    <template v-if="state.mode === 'edit' && state.geom_type === 'links'">
      <r-text type="caption">
        Разделение линка узлом
      </r-text>
      <el-switch
        v-model="linkSeparation"
        class="r-switch"
        :disabled="separationDisabled"
        @change="toggleLinkSeparation"
      />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    state: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      linkSeparation: false
    }
  },
  computed: {
    separationDisabled() {
      return !(this.state.geom_type === 'links')
    },
    nodeLayer() {
      return this.state.modelLayers?.find(l => l.datatype === 'nodes')
    }
  },
  watch: {
    'state.mode'() {
      this.linkSeparation = false
      this.toggleLinkSeparation(false)
    }
  },
  methods: {
    toggleLinkSeparation(val) {
      if (val && !this.nodeLayer.visible) {
        this.$emit('toggle-visible-model-layer', this.nodeLayer.id)
      }

      this.$emit('toggle-editing-link-separation', val)
    },
    toggleEditingModelLayer(payload) {
      if (this.linkSeparation) {
        this.linkSeparation = false
        this.$emit('toggle-editing-link-separation', false)
      }

      this.$emit('toggle-editing-model-layer', payload)
    },
    toggleVisibleModelLayer(id) {
      this.$emit('toggle-visible-model-layer', id)
    }
  }
}
</script>

<style lang="scss">
.map-editor-model {
  display: grid;
  grid-gap: 0.5rem;
  color: var(--text_secondary) !important;

  &__container {
    border-radius: var(--border-radius);
    border: 1px solid transparent;
    max-height: 260px;
    overflow: auto;
    background-color: var(--bg_panel_primary) !important;
    border-color: var(--dividers_low_contrast) !important;
  }

  &__list {
    margin: 10px 0;
  }

  &__item {
    padding: 3px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &.active {
      background-color: var(--accent_primary) !important;
      color: var(--white_white) !important;
    }
  }

  &__visible {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    border-radius: var(--border-radius);
    border: 1px solid transparent;
    border-color: var(--dividers_low_contrast) !important;
    margin-left: 10px;
    position: relative;
    cursor: pointer;

    .el-icon-view {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 11px;
    }
  }

  &__info {
    display: flex;
    align-items: center;

    .r-icon {
      margin-right: 8px;
      width: auto;
    }

    .el-icon-loading {
      font-size: 16px;
      margin-right: 10px;
    }
  }

  &__name {
    font-size: 11px;
  }
}
</style>
