import {
  removeHoveredSPHelpers,
  removeStopPointsHelpers,
  clearAllRouteGeometry,
  clearActiveStopPointsStyling
} from '@/libs/map-draw/helpers'
import { layersConfig } from '../configs'
import { mapColors } from '@/config/colors'

export class EditorController {
  constructor(parent) {
    this.parent = parent
    this.mapgl = parent.mapgl
    this.draw = parent.draw
    this.$store = parent.$store

    // handlers
    this.createHandler = this.createHandler.bind(this)

    // edit handler
    this.mapgl.on('draw.update', e => {
      const [feature] = e.features

      this.$store.commit('SET_ODD_FIELD', {
        field: 'featureToEdit',
        value: feature
      })
    })
  }

  toggleMode(value) {
    const { editorState } = this.$store.state.odd
    const { type } = editorState

    if (value === 'create') {
      this.$store.commit('SET_ODD_FIELD', {
        field: 'cardId',
        value: null
      })
      this.draw.changeMode(`draw_${type}`)
    } else if (this.draw.getMode() !== 'simple_select') {
      this.draw.changeMode('simple_select')
    }
  }

  toggleEditByRoute() {
    this.draw.changeMode('draw_lines')
  }

  // handlers
  createHandler(e) {
    const [feature] = e.features
    const point = feature?.properties?.point

    if (point) {
      const width = 10
      const height = 10
      const bbox = [
        [point.x - width / 2, point.y - height / 2],
        [point.x + width / 2, point.y + height / 2]
      ]
      const [rack] = this.mapgl.queryRenderedFeatures(bbox, {
        layers: ['racks']
      })

      if (rack) {
        feature.rack = rack?.properties?.id
      }
    }

    this.$store.commit('SET_CREATED_OBJECT', feature)

    this.$store.commit('SET_ODD_FIELD', {
      field: 'newCardId',
      value: feature.id
    })
  }

  enableCreateMode() {
    const { type } = this.$store.state.odd.editorState

    if (!this.mapgl.hasControl(this.draw)) {
      this.mapgl.addControl(this.draw)
    }

    this.draw.changeMode(`draw_${type}`)

    this.mapgl.on('draw.create', this.createHandler)

    // close active card
    this.$store.commit('SET_ODD_FIELD', {
      field: 'cardId',
      value: null
    })
    this.$store.commit('SET_ODD_FIELD', {
      field: 'cardType',
      value: type === 'signs' ? 'signs' : 'events'
    })
  }

  enableEditMode() {
    const { featureToEdit } = this.$store.state.odd
    const { id, geometry, properties } = featureToEdit

    this.toggleEditorStyling(true)

    if (!this.mapgl.hasControl(this.draw)) {
      this.mapgl.addControl(this.draw)
    }

    if (geometry.type !== 'LineString') {
      this.draw.set({
        type: 'FeatureCollection',
        features: [
          {
            id,
            type: 'Feature',
            geometry,
            properties
          }
        ]
      })
    }

    if (this.$store.state.odd.cardType === 'signs') {
      const geojson = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: properties.projection
          },
          {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: [
                properties.projection.coordinates,
                geometry.coordinates
              ]
            }
          }
        ]
      }

      const source = this.mapgl.getSource('signs_editor')

      if (source) {
        source.setData(geojson)
      } else {
        this.mapgl.addSource('signs_editor', {
          type: 'geojson',
          data: geojson
        })
        this.mapgl.addLayer({
          id: 'signs_editor_points',
          source: 'signs_editor',
          ...layersConfig.signs_editor_points,
          filter: ['==', '$type', 'Point']
        })
        this.mapgl.addLayer({
          id: 'signs_editor_connections',
          source: 'signs_editor',
          ...layersConfig.signs_editor_connections,
          filter: ['==', '$type', 'LineString']
        })
      }
    }

    if (geometry.type !== 'LineString') {
      this.draw.changeMode('simple_select', { featureIds: [id] })
    }
  }

  updateEditMode(coordinates) {
    const { featureToEdit } = this.$store.state.odd
    const { id, geometry, properties } = featureToEdit

    geometry.coordinates = coordinates

    this.draw.set({
      type: 'FeatureCollection',
      features: [
        {
          id,
          type: 'Feature',
          geometry,
          properties
        }
      ]
    })

    this.draw.changeMode('simple_select', { featureIds: [id] })
  }

  disableEditor() {
    this.toggleEditorStyling(false)
    this.clearHelpers()

    this.parent.helpers.clearCustomState()
    this.draw.deleteAll()
    this.mapgl.removeControl(this.draw)

    this.$store.commit('CLEAR_ODD_EDITOR_STATE')
    this.$store.commit('SET_CREATED_OBJECT', null)

    this.mapgl.off('draw.create', this.createHandler)
    this.mapgl.off('draw.delete', this.deleteHandler)

    // remove signs layers
    if (this.mapgl.getLayer('signs_editor')) {
      this.mapgl.removeLayer('signs_editor')
    }
    if (this.mapgl.getLayer('signs_editor_points')) {
      this.mapgl.removeLayer('signs_editor_points')
    }
    if (this.mapgl.getLayer('signs_editor_connections')) {
      this.mapgl.removeLayer('signs_editor_connections')
    }
    if (this.mapgl.getSource('signs_editor')) {
      this.mapgl.removeSource('signs_editor')
    }
  }

  removeCreatedObject() {
    const { editorState } = this.$store.state.odd
    this.draw.deleteAll()
    this.draw.changeMode(`draw_${editorState.type}`)
  }

  toggleEditorStyling(flag) {
    const { editorState } = this.$store.state.odd
    const { id } = editorState

    const hasLayer = this.mapgl.getLayer('signs_points')

    if (!hasLayer) return

    if (flag) {
      const value = ['case', ['==', ['get', 'id'], id], 0.2, 1]
      const colorValue = [
        'case',
        ['==', ['get', 'id'], id],
        mapColors.activeColor2,
        mapColors.ptEditRouteColor
      ]

      this.mapgl.setPaintProperty('signs_points', 'circle-opacity', value)
      this.mapgl.setPaintProperty(
        'signs_points',
        'circle-stroke-opacity',
        value
      )
      this.mapgl.setPaintProperty('signs_symbols', 'icon-opacity', value)
      this.mapgl.setPaintProperty('signs_connections', 'line-opacity', value)
      this.mapgl.setPaintProperty('events_points', 'icon-opacity', value)
      this.mapgl.setPaintProperty('events_lines', 'line-color', colorValue)
      this.mapgl.setPaintProperty('events_lines_icons', 'icon-opacity', value)
      this.mapgl.setPaintProperty('events_arrows', 'icon-opacity', value)

      return
    }

    this.mapgl.setPaintProperty('signs_points', 'circle-opacity', 1)
    this.mapgl.setPaintProperty('signs_points', 'circle-stroke-opacity', 1)
    this.mapgl.setPaintProperty('signs_symbols', 'icon-opacity', 1)
    this.mapgl.setPaintProperty('signs_connections', 'line-opacity', 1)
    this.mapgl.setPaintProperty('events_points', 'icon-opacity', 1)
    this.mapgl.setPaintProperty('events_lines', 'line-color', mapColors.ptEditRouteColor)
    this.mapgl.setPaintProperty('events_lines_icons', 'icon-opacity', 1)
    this.mapgl.setPaintProperty('events_arrows', 'icon-opacity', 1)
  }

  clearHelpers() {
    // clear sp helpers
    removeHoveredSPHelpers(this.mapgl)
    removeStopPointsHelpers(this.mapgl)
    // clear route helpers
    clearAllRouteGeometry(this.mapgl)
    // clearReverseRouteStyling(this.mapgl)
    clearActiveStopPointsStyling(this.mapgl, this.$store)
  }
}
