<template>
  <div
    class="r-cblock"
    :style="getStyle()"
  >
    <div
      class="r-cblock__bg"
      :style="getBgColor()"
    />
    <span class="r-cblock__content">
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#000000'
    },
    size: {
      type: Number,
      default: null
    },
    borderSize: {
      type: Number,
      default: 1.5
    },
    fontSize: {
      type: Number,
      default: 16
    },
    borderColor: {
      type: String,
      default: null
    }
  },
  data() {
    return {}
  },
  methods: {
    getStyle() {
      const styles = {}

      if (this.color) {
        styles.color = `${this.color} !important`
      }

      if (this.borderSize) {
        styles.border = `${this.borderSize}px solid`
      }

      if (this.fontSize) {
        styles['font-size'] = `${this.fontSize}px`
      }

      if (this.size) {
        styles.width = `${this.size}px`
        styles.height = `${this.size}px`
      }

      if (this.borderColor) {
        styles['border-color'] = `${this.borderColor} !important`
      } else {
        styles['border-color'] = 'transparent !important'
      }

      return styles
    },
    getBgColor() {
      const styles = {}

      if (this.color) {
        styles['background-color'] = `${this.color} !important`
        styles.opacity = '0.1'
      }

      return styles
    }
  }
}
</script>

<style lang="scss">
.r-cblock {
  position: relative;
  height: 24px;
  width: 24px;
  font-size: 12px;
  border-radius: 50%;
  border: 1px solid;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 1;
  color: var(--white_white) !important;
  &__bg {
    margin: 0 !important;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  &__content {
    margin-top: -2px;
  }
}
</style>
