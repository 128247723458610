<template>
  <div
    v-loading="loading"
    class="register-modal-window"
  >
    <r-title>{{ title }}</r-title>
    <component
      :is="`modal-${mode}`"
      v-if="source"
      :source_id="pass.source_id"
      :source="source"
      :application="application"
      @mode="changeMode"
      @application="setApplication"
    />
  </div>
</template>

<script>
import ModalView from './modal-view'
import ModalEdit from './modal-edit'
import ModalCreate from './modal-create'
import ModalPreCreate from './modal-pre-create'
import { titles, sources } from './config/fields'
import { buildUrl } from '../../helpers'

export default {
  components: { ModalEdit, ModalView, ModalCreate, ModalPreCreate },
  data() {
    return {
      mode: 'view',
      loading: false,
      source: null,
      titles,
      sources,
      application: '',
      initHeight: 'calc(90vh - 50px)'
    }
  },
  computed: {
    title() {
      switch (this.mode) {
        case 'pre-create':
          return this.titles.create
        default:
          return this.titles.view
      }
    },
    pass() {
      return this.$store.state.bdd.pass
    }
  },
  async created() {
    await this.load()

    if (this.source.new) this.changeMode('pre-create')
  },
  methods: {
    async load() {
      if (this.pass?.new) {
        this.source = this.pass
        return
      }

      const { id, source_id } = this.pass
      try {
        this.loading = true
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: buildUrl(source_id, [], id)
        })
        this.source = Object.assign(this.pass, data[id])
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    changeMode(prop) {
      this.mode = prop
    },
    setApplication(app) {
      this.application = app
    }
  }
}
</script>

<style lang="scss">
.register-modal-window {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;

  .files-comments {
    grid-gap: 1rem;
  }
}
</style>
