<template>
  <div class="pt-editor">
    <editor-panel />
    <route-card v-if="type === 'lines'" />
  </div>
</template>

<script>
import EditorPanel from '@/components/public-transport/editor/editor-panel'
import RouteCard from '@/components/public-transport/editor/route-card'

export default {
  components: {
    EditorPanel,
    RouteCard
  },
  computed: {
    type() {
      return this.$store.state.publicTransport.editorState.type
    }
  }
}
</script>

<style lang="scss">
.pt-editor {
  position: relative;
  z-index: 100;
}
</style>
