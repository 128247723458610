<template>
  <div class="rp-roles-datasources-multi-edit">
    <div class="rp-roles-datasources-multi-edit__control">
      <el-switch
        v-model="isActive"
        :width="32"
        class="r-switch"
      />
      <r-text @click.native="isActive = !isActive">
        Режим мультиредактирования прав
      </r-text>
    </div>
    <div
      v-if="isActive"
      class="rp-roles-datasources-multi-edit-row"
    >
      <r-text>
        Выберите необходимые права, а затем кликните по источникам данных, чтобы
        применить
      </r-text>
      <div class="rp-roles-datasources-multi-edit-list">
        <div
          v-for="crud in crudsTypes"
          :key="crud.id"
          :class="[
            'rp-roles-datasources-multi-edit__item',
            { active: isCrudActive(crud) }
          ]"
          @click="toggleCrud(crud)"
        >
          <r-title type="subtitle-1">
            {{ crud.name }}
          </r-title>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { crudsTypes } from './configs'

export default {
  data() {
    return {
      isActive: false,
      activeCruds: ['read'],
      crudsTypes
    }
  },
  watch: {
    isActive(val) {
      this.$store.commit('ROLE_PERM_SET_FIELD', {
        field: 'multiEdit',
        value: val ? this.activeCruds : null
      })
    }
  },
  beforeDestroy() {
    this.$store.commit('ROLE_PERM_SET_FIELD', {
      field: 'multiEdit',
      value: null
    })
  },
  methods: {
    toggleCrud(crud) {
      const index = this.activeCruds.indexOf(crud.title)

      if (index !== -1) this.activeCruds.splice(index, 1)
      else this.activeCruds.push(crud.title)
      this.setMultiEdit()
    },
    setMultiEdit() {
      this.$store.commit('ROLE_PERM_SET_FIELD', {
        field: 'multiEdit',
        value: this.activeCruds || null
      })
    },
    isCrudActive(crud) {
      return this.activeCruds.includes(crud.title)
    }
  }
}
</script>

<style lang="scss">
.rp-roles-datasources-multi-edit {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;

  &__control {
    position: relative;
    display: flex;
    margin-bottom: 8px;
    align-items: center;

    > * {
      margin-right: 8px;
    }
  }

  &-row {
    position: relative;
    padding: 8px;
    width: fit-content;
    margin-bottom: 8px;
    align-items: center;
    border-radius: var(--border-radius);
    background-color: var(--bg_containers);

    &:last-child {
      margin-bottom: 0;
    }

    > * {
      margin-right: 8px;
    }
  }

  &-list {
    display: flex;
    margin-top: 16px;
  }

  &__item {
    display: flex;
    padding: 4px 6px;
    border-radius: var(--border-radius);
    background-color: var(--button_secondary_bg);
    cursor: pointer;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      background-color: var(--accent_selected);

      .r-title {
        color: var(--accent_primary);
      }
    }

    &:active {
      background-color: var(--accent_active);
    }
  }
}
</style>
