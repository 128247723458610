<template>
  <div class="js-types-list">
    <ul
      v-if="currentTypes.length"
      class="js-types-list__items"
    >
      <li class="js-types-list__item">
        <el-checkbox
          :value="checkedLength && !isIndeterminate"
          :indeterminate="isIndeterminate"
          class="r-checkbox"
          @change="toggleAll"
        >
          <r-text :weight="600">
            Все уведомления
          </r-text>
        </el-checkbox>
      </li>
      <li
        v-for="item in currentTypes"
        :key="item.id"
        class="js-types-list__item"
      >
        <el-checkbox
          v-model="item.active"
          class="r-checkbox"
          @change="$emit('hasChanges')"
        >
          <div :class="`js-types-list__item-icon ${item.color}`" />
          <r-text>
            {{ item.name }}
          </r-text>
        </el-checkbox>
      </li>
    </ul>
    <div
      v-else
      class="js-types-list__nodata"
    >
      <r-text type="caption">
        Не удалось загрузить список настроек уведомлений
      </r-text>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentTypes: {
      type: Array,
      required: true
    }
  },
  computed: {
    isIndeterminate() {
      return (
        this.checkedLength > 0 && this.checkedLength < this.currentTypes.length
      )
    },
    checkedLength() {
      return this.currentTypes.filter(t => t.active)?.length || 0
    }
  },
  methods: {
    toggleAll() {
      this.$emit('hasChanges')

      const isActiveMore =
        this.checkedLength > Math.floor(this.currentTypes.length / 2)
      this.currentTypes.forEach(t => (t.active = !isActiveMore))
    }
  }
}
</script>

<style lang="scss">
.js-types-list {
  display: grid;
  grid-gap: 0.5rem;
  align-items: center;

  &__items {
    display: grid;
    grid-gap: 0.5rem;
    align-items: center;
  }

  &__item {
    display: flex;
    align-items: center;

    .el-checkbox {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;

      &__label {
        align-items: center;
        grid-gap: 0.5rem;

        display: grid;
        grid-template-columns: auto 1fr;
      }
    }

    &-icon {
      height: 12px;
      width: 12px;
      border-radius: 50%;

      &.default {
        background-color: var(--button_secondary_bg);
      }

      &.warning {
        background-color: var(--accent_warning);
      }

      &.error {
        background-color: var(--accent_error);
      }
    }
  }
}
</style>
