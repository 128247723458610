<template>
  <div class="r-modal-card__block r-modal-card__block--main">
    <r-title
      class="r-modal-card__block-title"
      type="subtitle-1"
    >
      <r-icon
        :size="24"
        name="undo"
      />
      Заполните этот раздел, если хотите отклонить заявку
    </r-title>
    <el-checkbox-group
      v-model="reasons"
      class="r-modal-card__content"
    >
      <el-checkbox
        v-for="item in formList"
        :key="item.id"
        class="
          r-modal-card__input-field r-modal-card__input-field--checkbox-item
          r-checkbox
        "
        :label="item.value"
      >
        <r-text>{{ $t(item.label) }}</r-text>
      </el-checkbox>
    </el-checkbox-group>
    <div class="r-modal-card__input-field r-modal-card__input-field--stretch">
      <r-text color-type="secondary">
        Причина отклонения заявки
      </r-text>
      <el-input
        v-model="comment"
        class="r-input"
        type="textarea"
        :rows="3"
        resize="none"
        placeholder="Комментарий будет прикреплён к акту осмотра"
      />
    </div>
    <div class="r-modal-card__controls">
      <r-button
        type="danger"
        :disabled="disabled"
        :loading="loading"
        @click="declineApplication"
      >
        Отклонить заявку
      </r-button>
    </div>
  </div>
</template>

<script>
import { notifyFactory } from '@/utils'

const formList = [
  {
    id: '1',
    label: 'Не все документы прикреплены',
    value: 1
  },
  {
    id: '2',
    label: 'Ошибка в данных о подрядчике',
    value: 2
  },
  {
    id: '3',
    label: 'Ошибка в данных о ТС',
    value: 3
  },
  {
    id: '4',
    label: 'Другая причина (обязателен комментарий)',
    value: 4
  }
]

export default {
  data() {
    return {
      loading: false,
      source_id: this.$store.state.bdd.applicationsSourceId || null,
      reasons: [],
      comment: '',
      formList
    }
  },
  computed: {
    disabled() {
      const needComment = this.reasons.find(e => e === 4) && !this.comment

      return !this.reasons?.length || needComment
    },
    related() {
      return this.$store.state.bdd.applicationsRelated
    },
    declineStatus() {
      return (
        this.related.applications_status_list.data.find(
          e => e.name.includes('формальным') && e.name.includes('отклонена')
        ).id || null
      )
    },
    activeApplication() {
      return this.$store.state.bdd.activeApplication
    }
  },
  methods: {
    async declineApplication() {
      this.loading = true
      if (!this.activeApplication?.id) return

      try {
        const data = this.getData()

        await this.$store.dispatch('PUT_REQUEST', {
          url: `objectInfo/${this.source_id}`,
          data
        })

        this.$notify(
          notifyFactory('success', 'Уведомление', 'Статус заявки изменен')
        )
        this.$store.commit('BDD_SET_FIELD', {
          field: 'updateApplications',
          value: true
        })
        this.$store.commit('CLOSE_MODAL_WINDOW', '')
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    getData() {
      const comment = this.reasons
        .map(e => {
          if (e === 4) {
            return this.comment?.trim()
          }
          return this.formList[e - 1]?.label
        })
        .join(', ')

      return [
        {
          id: this.activeApplication.id,
          status_id: this.declineStatus,
          inspection_comment: comment
        }
      ]
    }
  }
}
</script>
