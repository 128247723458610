<template>
  <div
    v-loading="isLoading"
    class="checklist-album"
  >
    <div
      v-if="!selectMode"
      class="checklist-album__header"
    >
      <r-title color-type="subhead">
        Фотографии ТС
      </r-title>
      <r-title
        type="title-3"
        color-type="secondary"
      >
        Приложите фотографии осмотра сразу. При финальном заполнении акта вы
        сможете связать фотографию с разделом осмотра
      </r-title>
    </div>
    <div class="checklist-album__wrapper">
      <checklist-album-upload
        :attr-id="attrId"
        :select-mode="selectMode"
        @load="loadResources"
      />
      <checklist-album-list
        v-if="albumResources && albumResources.length"
        :select-mode="selectMode"
        :attr-id="attrId"
        :resources="albumResources"
        @load="loadResources"
      />
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import checklistAlbumUpload from './checklist-album-upload.vue'
import checklistAlbumList from './checklist-album-list.vue'

import constants from '@/constants/url'
import { getHeaders } from '@/utils'

export default {
  components: {
    checklistAlbumUpload,
    checklistAlbumList
  },
  props: {
    selectMode: {
      type: Boolean,
      default: false
    },
    attrId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isLoading: false,
      checklistsUrl: this.$store.state.bdd.checklistsUrl || null,
      id: this.$route.query.id,
      url: constants.URL,
      resources: []
    }
  },
  computed: {
    albumResources() {
      return this.$store.state.bdd.albumResources || []
    },
    updateAlbum() {
      return this.$store.state.bdd.updateAlbum
    }
  },
  watch: {
    updateAlbum(val) {
      if (!val) return
      this.loadResources()
      this.$store.state.bdd.updateAlbum = false
    }
  },
  created() {
    this.loadResources()
  },
  methods: {
    loadResources: debounce(async function() {
      try {
        this.isLoading = true

        const url = `objectResources/${this.checklistsUrl}/${this.id}`
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })
        this.resources = []
        await this.loadImages(data)

        this.resources = this.resources.sort((a, b) => {
          if (a.id > b.id) return 1
          if (a.id < b.id) return -1
          return 0
        })
        this.$store.commit('BDD_SET_FIELD', {
          field: 'albumResources',
          value: this.resources
        })
      } catch (e) {
        console.log(e)
      } finally {
        setTimeout(() => {
          this.isLoading = false
        }, 500)
      }
    }, 256),
    async loadImages(data) {
      await Promise.all(
        data.map(async item => {
          const qwe = await this.getUrl(item)
          item.url = qwe
          this.resources.push(item)
        })
      )
    },
    getUrl(item) {
      const url = `${this.url}/download_resource/${item.id}`

      return new Promise(resolve => {
        setTimeout(() => {}, 2000)
        fetch(url, { headers: getHeaders() })
          .then(response => response.blob())
          .then(imageBlob => {
            const imageObjectURL = URL.createObjectURL(imageBlob)
            resolve(imageObjectURL)
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.checklist-album {
  display: grid;
  grid-gap: 0.5rem;
  width: 100%;

  &__wrapper {
    border-radius: var(--border_radius);
    border: 1px solid var(--field_border);
    padding: 1rem;
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    grid-gap: 1rem;
  }

  &__header {
    display: grid;
    grid-gap: 0.5rem;
  }
}
</style>
