export const types = {
  integer: 'number',
  string: 'text',
  float: 'number',
  datetime: 'datetime',
  boolean: 'switch',
  date: 'date',
  has_many_through: 'multiselect',
  has_many: 'multiselect',
  belongs_to: 'select',
  uuid: 'select',
  has_one: 'select'
}

export const reflectionsKeys = {
  packing_list_no: 'packing_list_no'
}

export const systemFields = ['created_user', 'updated_user']
