export const geomTypes = {
  point: 'Point',
  multi_point: 'MultiPoint',
  line_string: 'LineString',
  multi_line_string: 'MultiLineString',
  polygon: 'Polygon',
  multi_polygon: 'MultiPolygon'
}

export const geomTypesIcon = {
  point: 'node',
  multi_point: 'node',
  line_string: 'line',
  multi_line_string: 'line',
  polygon: 'polygon',
  multi_polygon: 'polygon'
}
