export const makeExportUrl = (uri, exportConfig) => {
  let exportUrl

  if (!uri?.includes('config=')) return ''

  const config = JSON.parse(uri?.split('config=')[1])

  if (exportConfig.only) {
    config.only = exportConfig.only
  }
  if (exportConfig.include) {
    config.include = exportConfig.include
  }
  if (exportConfig.order) {
    config.order = exportConfig.order
  }
  if (exportConfig.with_numeration) {
    config.with_numeration = exportConfig.with_numeration
  }

  delete config.except

  exportUrl = uri?.split('config=')[0] + `config=${
    JSON.stringify(config)
  }`

  if (exportConfig.column_order) {
    exportUrl = exportUrl + `&column_order=${JSON.stringify(exportConfig.column_order)}`
  }

  if (exportConfig.column_labels) {
    exportUrl = exportUrl + `&column_labels=${JSON.stringify(exportConfig.column_labels)}`
  }

  return exportUrl || ''
}
