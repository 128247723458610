<template>
  <div :class="['tree', 'attributes', mapBi ? 'mapbi' : '']">
    <div class="tree__control">
      <el-input
        v-model="filterText"
        size="mini"
        class="r-input"
        placeholder="Поиск по атрибутам"
        suffix-icon="el-icon-search"
      />
      <el-tooltip
        v-if="popoverControlProp"
        :content="$t('save')"
        placement="top"
      >
        <r-button
          :disabled="!activeFields.length"
          type="success"
          icon="check"
          @click="closeParentPopover"
        />
      </el-tooltip>
      <el-tooltip
        v-if="isClearable"
        :content="$t('cancel')"
        placement="top"
      >
        <r-button
          :disabled="!activeFields.length"
          type="success"
          icon="close-delete"
          @click="removeActiveAttrs"
        />
      </el-tooltip>
    </div>

    <el-tree
      ref="attributesTree"
      class="tree__container"
      :data="treeData"
      :default-expand-all="false"
      :empty-text="'Данные отсутствуют'"
      :props="defaultProps"
      :highlight-current="false"
      :filter-node-method="filterNode"
      :render-content="renderAttributes"
      :expand-on-click-node="false"
      @node-click="nodeClickHandler"
    />
  </div>
</template>

<script>
import {
  getAttributePath,
  functionTypes,
  getAttributeConfig,
  parseObjectFields,
  notifyFactory
} from '@/utils'

const createIcon = (h, type) => {
  // create icon function for attributes tree
  switch (type) {
    case 'has_many':
    case 'has_many_through':
      return <div class="tree__icon one-to-many" />
    case 'belongs_to':
    case 'has_one':
      return <div class="tree__icon one-to-one" />
    case 'function':
      return <div class="tree__icon function" />
    case 'number':
    case 'numeric':
    case 'integer':
    case 'decimal':
    case 'float':
      return <div class="tree__icon number" />
    case 'boolean':
      return <div class="tree__icon boolean" />
    case 'datetime':
    case 'date':
      return <div class="tree__icon date" />
    case 'interval':
      return <div class="tree__icon time" />
    case 'string':
    case 'geometry':
    case 'uuid':
    case 'jsonb':
      return <div class="tree__icon string" />
  }
}

export default {
  props: {
    treeData: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    children: {
      type: String,
      default: 'children'
    },
    activeFields: {
      type: [String, Array],
      required: true
    },
    isClearable: {
      type: Boolean,
      default: false
    },
    isNotEditable: {
      type: Boolean,
      default: false
    },
    isOnlyNumber: {
      type: Boolean,
      default: false
    },
    mapBi: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    },
    popoverControlProp: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filterText: '',
      defaultProps: {
        label: this.label,
        children: this.children,
        id: 'id'
      }
    }
  },
  watch: {
    filterText(val) {
      this.$refs.attributesTree.filter(val)
    }
  },
  methods: {
    async nodeClickHandler(object, node) {
      // node click handler - emitting handleNodeClick event
      const { source_id } = object
      const { path, label } = getAttributePath(node)
      const config = getAttributeConfig(node, this)

      if (!source_id) {
        this.$emit('handleNodeClick', { node, object, path, label, config })
      } else {
        object.isLoading = true
        const initialFields = await this.$store.dispatch('GET_REQUEST', {
          url: `objectFields/${source_id}`
        })
        const { default_time_field_attribute } = initialFields.data
        let timeAggregation = null

        if (default_time_field_attribute) {
          const aggConfig = {
            only: [default_time_field_attribute]
          }
          const aggregation = await this.$store.dispatch('GET_REQUEST', {
            url: `aggregation/${source_id}?config=${JSON.stringify(aggConfig)}`
          })

          timeAggregation = {
            sourceName: object.source_name,
            sourceId: object.source_id,
            field: default_time_field_attribute,
            ...aggregation.data[default_time_field_attribute]
          }
        }

        const fields = parseObjectFields(initialFields, false)
        this.$store.commit('SET_OBJECT_FIELDS', { source_id, fields })
        object.children = fields.map(item => ({
          ...item,
          isLoading: false,
          children: [],
          parent_id: source_id,
          source_name: item.source_name,
          parent_name: object.source_name,
          parent_type: object.type,
          fn_type:
            object.type === 'belongs_to' && !item.source_id
              ? object.fn_type
              : 'concat',
          foreign_key:
            object.type === 'belongs_to' && !item.source_id
              ? object.foreign_key
              : null,
          primary_key:
            object.type === 'belongs_to' && !item.source_id
              ? object.primary_key
              : null,
          timeAggregation
        }))
        if (this.isOnlyNumber) {
          object.children = object.children.filter(
            field =>
              field.type === 'integer' ||
              field.type === 'decimal' ||
              field.source_id ||
              field.time_field
          )
        }
        object.isLoading = false
        node.expanded = true
      }
    },
    removeActiveAttrs() {
      this.$emit('clearActiveAttrs')
    },
    async toggleFnType(event, data, fn_type) {
      // toggle fn handler - emitting changeFnType
      event.stopPropagation()
      if (fn_type === 'time_fn') {
        const field = data.children.find(el => el.time_field)
        if (field) {
          const tmState = this.$store.state.map.timemachine
          const { filter } = tmState
          const { from, to } = filter
          const { source_id } = data
          const time_field = field.title
          const config = {
            unique_values: time_field,
            order: [{ [time_field]: 'desc' }],
            limit: 50
          }
          const modifiedConfig = JSON.stringify(config)
          const filterParam = `${time_field}=${from}/${to}`
          const url = `objectInfo/${source_id}?config=${modifiedConfig}&${filterParam}&format=json`
          const response = await this.$store.dispatch('GET_REQUEST', { url })
          const times = response.data.reverse()
          this.$store.commit('SET_TIMEMACHINE_VALUE', {
            field: 'source_id',
            value: source_id
          })
          this.$store.commit('SET_TIMEMACHINE_VALUE', {
            field: 'times',
            value: times
          })
          this.$store.commit('SET_TIMEMACHINE_VALUE', {
            field: 'time_field',
            value: time_field
          })
          this.$store.commit('SET_TIMEMACHINE_VALUE', {
            field: 'current',
            value: times.length - 1
          })
          this.$store.commit('TOGGLE_TIMEMACHINE_BLOCK', true)
          if (!times.length) {
            const title = 'Предупреждение'
            const message =
              'Временные метки для данного слоя по данному атрибуту отсутствуют.'
            this.$notify(notifyFactory('warning', title, message))
          }
        }
      }
      data.fn_type = fn_type
      if (data.children.length) {
        data.children.forEach(child => {
          child.fn_type = child.type === 'belongs_to' ? 'concat' : fn_type
        })
      }
    },
    filterNode(value, data) {
      // filtering tree function
      if (!value) return true
      const full = String(data[this.label]).toLowerCase()
      const substring = value.toLowerCase()
      return full.indexOf(substring) !== -1
    },
    closeParentPopover() {
      this.$parent.$parent.$parent[this.popoverControlProp] = false
    },
    renderAttributes(h, { node, data }) {
      // render attributes tree (with buttons, icons and other)
      const self = this
      const { title, children, fn_type } = data
      const name = !node.parent.parent ? title : getAttributePath(node).path
      const item =
        this.activeFields && !this.single
          ? this.activeFields.find(item =>
            this.mapBi ? name === item : name === item.title
          )
          : name === this.activeFields
      const className = item ? 'tree__text tree__text--selected' : 'tree__text'
      const { type, isLoading } = node.data
      const icon = createIcon(h, type)
      const createButton = title => (
        <el-tooltip
          open-delay={500}
          effect="dark"
          content={title}
          placement="bottom"
        >
          <r-button
            class={
              fn_type === title
                ? `attributes-functions__button ${title} active`
                : `attributes-functions__button ${title}`
            }
            simple
            onClick={event => self.toggleFnType(event, data, title)}
          />
        </el-tooltip>
      )
      const functionTypesArray = [...functionTypes]
      if (this.isOnlyNumber) functionTypesArray.splice(0, 1)
      const functionsButtons = functionTypesArray.map(createButton)
      const editButton = createButton('edit')
      const concatButton = createButton('concat')
      const timeButton = createButton('time_fn')

      if (children && children.length) {
        const timeFlag = children.some(el => el.time_field)
        if (timeFlag) functionsButtons.push(timeButton)
      }

      return (
        <span class={className}>
          {isLoading ? <i class="el-icon-loading" /> : null}
          {icon}
          <span>{node.label}</span>
          {children && children.length ? (
            <span class="attributes-functions">
              {!node.parent.parent && !this.isNotEditable ? editButton : null}
              {data.type !== 'belongs_to'
                ? functionsButtons
                : !node.parent.parent && !this.isOnlyNumber
                  ? concatButton
                  : null}
            </span>
          ) : null}
        </span>
      )
    }
  }
}
</script>

<style lang="scss" src="./tree-attributes.scss"></style>
