<template>
  <div class="bdd-application-list">
    <app-table-header
      :filter-text="filterText"
      :grouping="grouping"
      :filters="filters"
      :uri="uri"
      @handle-search-filter="handleSearchFilter"
      @switchGrouping="switchGrouping"
    />
    <app-table-content
      :data="filteredData"
      :grouping="grouping"
    />
  </div>
</template>

<script>
import appTableHeader from './components/app-table-header'
import appTableContent from './components/app-table-content'

export default {
  components: {
    appTableHeader,
    appTableContent
  },
  props: {
    filters: {
      type: Array,
      required: true
    },
    uri: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      filterByStatus: 'all',
      filterText: '',
      grouping: true
    }
  },
  computed: {
    filteredData() {
      const data = this.$store.state.bdd?.applications || []

      return data.filter(e => {
        return (
          e.reg_number
            ?.toLowerCase()
            .includes(String(this.filterText).trim().toLowerCase()) ||
          e.driver_full_name
            ?.toLowerCase()
            .includes(String(this.filterText).trim().toLowerCase())
        )
      })
    }
  },
  methods: {
    switchGrouping() {
      this.grouping = !this.grouping
    },
    handleSearchFilter(val) {
      this.filterText = val
    }
  }
}
</script>

<style lang="scss" scoped>
.bdd-application-list {
  height: 100%;
  width: 100%;
  display: grid;
  position: relative;
  align-content: flex-start;
  background-color: var(--bg_surface);
}
</style>
