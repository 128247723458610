<template>
  <div
    v-loading="loading"
    class="mt-journal"
  >
    <r-filter-set
      v-if="filters"
      class="journal__filters"
      :filters="filters"
      @reset-all="getJournal"
      @load="updateFilters"
    />
    <r-table-list
      v-if="events.length"
      ref="table"
      :include-columns="columns"
      :fields-names="tableFields"
      bordered
      num-col
      :data="events"
      actions
    >
      <template v-slot:actions="{ row }">
        <div class="journal-table__action-cell">
          <button
            class="journal-table__action-button"
            @click.stop="showOnMap(row)"
          >
            <r-icon
              name="focus-zone"
              :size="20"
            />
            <r-text> На карте </r-text>
          </button>
        </div>
      </template>
    </r-table-list>
    <div
      v-else
      class="journal__nodata"
    >
      <r-text type="caption">
        Список событий пуст
      </r-text>
    </div>
    <el-pagination
      v-if="hasPagination"
      v-loading="loading"
      class="r-pagination"
      :current-page.sync="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next"
      :total="totalObjects"
      @current-change="onPaginationChange"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import { fields as tableFields } from '@/components/journal/config/table'
import { getEventType } from '@/components/journal/helpers/data-formatting'
import { filtersEncoder } from '@/utils'
import { filters } from './config'

export default {
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      data: [],
      checkAll: false,
      isIndeterminate: true,
      tableFields,
      columns: ['no', 'event_time', 'event_type', 'description'],
      filters: cloneDeep(filters),
      currentPage: 1,
      totalObjects: 0,
      pageSize: 20
    }
  },
  computed: {
    eventTime() {
      return this.$store.state.monitoring.eventTime
    },
    cardId() {
      return this.$store.state.monitoring.cardId || null
    },
    events() {
      const startNo = this.currentPage === 1
        ? 0
        : (this.currentPage - 1) * this.pageSize

      return this.data.map((e, i) => ({
        ...e,
        event_time: this.$rDate.format(e.event_time, 'DD.MM.YYYY • HH:mm:ss'),
        event_type: getEventType(e.event_type),
        reg_number: e.vehicle?.reg_number,
        model: e.vehicle?.model?.name,
        vehicle_type: e.vehicle?.vehicle_type?.name,
        vehicle_group: e.vehicle?.vehicle_group?.name,
        contractor: e.vehicle?.contractor?.name,
        no: i + 1 + startNo
      }))
    },
    hasPagination() {
      return this.totalObjects > 10
    }
  },
  watch: {
    cardId(val) {
      if (!val) return
      this.getJournal()
    },
    eventTime() {
      this.getJournal()
    }
  },
  mounted() {
    this.getJournal()
  },
  methods: {
    getConfig(aggregation = false) {
      const eventTime = {
        id: 'event_time',
        source: null,
        type: 'interval-filter',
        format: 'dd.MM.yyyy HH:mm',
        pickerType: 'datetime',
        title: 'Дата события',
        prop: this.eventTime,
        active: true
      }
      const filtersConfig = filtersEncoder([eventTime, ...this.filters])
      const config = {
        limit: this.pageSize,
        offset: this.currentPage === 1
          ? 0
          : this.pageSize * (this.currentPage - 1),
        include: {
          vehicle: {
            only: ['id', 'reg_number'],
            include: {
              model: {
                only: ['id', 'name']
              },
              vehicle_type: {
                only: ['id', 'name']
              },
              contractor: {
                only: ['id', 'name']
              }
            }
          }
        },
        order: { event_time: 'desc' },
        where: [
          {
            field: 'vehicle.id',
            op: '=',
            value: this.cardId
          },
          ...filtersConfig.where
        ]
      }

      if (this.from && this.to) {
        config.where.push({
          field: 'event_time',
          op: 'between',
          value: `${this.from}/${this.to}`
        })
      }
      if (aggregation) {
        delete config.limit
        delete config.offset
      }

      return JSON.stringify(config)
    },
    updateFilters(filter) {
      const target = this.filters.find(f => f.id === filter.id)
      const item = { ...target, ...filter }

      this.filters[this.filters.indexOf(target)] = item

      this.getJournal()
    },
    onPaginationChange(val) {
      this.currentPage = val
      this.getJournal()
    },
    async getJournal() {
      try {
        this.loading = true

        const aggregationUrl = `car_event?format=aggregation&config=${this.getConfig(true)}`
        const { data: aggregation } = await this.$store.dispatch('GET_REQUEST', { url: aggregationUrl })
        this.totalObjects = aggregation.metadata.count || 0

        const url = `objectInfo/telemetry.car_events?array=true&config=${this.getConfig()}`
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        this.data = Object.values(data)
      } catch (error) {
        throw new Error(error)
      } finally {
        this.loading = false
      }
    },
    showOnMap(value) {
      this.$store.commit('SET_MT_FIELD', {
        field: 'journalMapPoint',
        value
      })
    }
  }
}
</script>

<style lang="scss">
.mt-journal {
  display: grid;
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: start;
  grid-gap: 0.5rem;
  grid-auto-flow: row;
}
</style>
