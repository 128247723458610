<template>
  <r-toggle-card
    title="Матрицы корр-й"
    :is-open="layerStyle.matrices.enabled"
    @toggle="toggleMatrices"
  >
    <div
      v-loading="layerStyle.matrices.loading"
      class="matrices-control"
    >
      <div class="matrices-control__block">
        <r-text style="margin-bottom: 4px">
          Тип матрицы
        </r-text>
        <el-select
          v-model="layerStyle.matrices.matrixId"
          class="r-select dark"
          size="default"
          :popper-append-to-body="false"
          @change="changeData"
        >
          <el-option
            v-for="item in matricesList"
            :key="item.id"
            :label="`No ${item.no}`"
            :value="item.id"
          >
            {{ `No ${item.no}` }}
          </el-option>
        </el-select>
      </div>
      <div class="matrices-control__block">
        <r-text style="margin-bottom: 4px">
          Район - источник (опционально)
        </r-text>
        <el-select
          v-model="layerStyle.matrices.zoneId"
          placeholder="Выберите район"
          class="r-select dark"
          size="default"
          :popper-append-to-body="false"
          clearable
          @change="changeData"
        >
          <el-option
            v-for="item in zonesList"
            :key="item.id"
            :label="`No ${item.no}`"
            :value="item.id"
          >
            {{ `No ${item.no}` }}
          </el-option>
        </el-select>
      </div>
      <div class="matrices-control__block">
        <r-slider
          title="Отображаемое количество"
          :number="layerStyle.matrices.count"
          :min="10"
          :max="getCountMax"
          :step="1"
          @change="changeCount"
        />
      </div>
      <div class="matrices-control__block">
        <r-slider
          title="Толщина"
          :number="layerStyle.matrices.strokeWidth"
          :min="1"
          :max="50"
          :step="1"
          @change="changeProperty('strokeWidth', $event)"
        />
      </div>
      <!-- <div class="matrices-control__block">
        <r-text style="margin-bottom: 8px">
          Цвет источника
        </r-text>
        <div class="matrices-control-color__simple">
          <el-color-picker
            v-model="layerStyle.matrices.sourceColor"
            class="r-color"
            size="mini"
            popper-class="r-color-picker-dropdown dark"
            color-format="rgb"
            show-alpha
            @active-change="changeProperty('sourceColor')"
            @change="changeProperty('sourceColor')"
          />
        </div>
      </div>
      <div class="matrices-control__block">
        <r-text style="margin-bottom: 8px">
          Цвет назначения
        </r-text>
        <div class="matrices-control-color__simple">
          <el-color-picker
            v-model="layerStyle.matrices.targetColor"
            class="r-color"
            size="mini"
            popper-class="r-color-picker-dropdown dark"
            color-format="rgb"
            show-alpha
            @active-change="changeProperty('targetColor')"
            @change="changeProperty('targetColor')"
          />
        </div>
      </div> -->
    </div>
  </r-toggle-card>
</template>

<script>
import debounce from 'lodash.debounce'
import { polygonStyleConfig } from '../../../configs'

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    parentId: {
      type: String,
      required: true
    },
    layerStyle: {
      type: Object,
      required: true
    },
    controllers: {
      type: Object,
      required: true
    }
  },
  computed: {
    matricesList() {
      return this.$store.state.map.matricesList[this.id] || []
    },
    zonesList() {
      return this.$store.state.map.zonesList[this.id] || []
    },
    getCountMax() {
      if (this.zonesList.length === 0) return 100

      return this.zonesList.length * this.zonesList.length
    }
  },
  created() {
    if (!this.layerStyle.matrices) {
      this.$set(this.layerStyle, 'matrices', {
        ...polygonStyleConfig.matrices,
        modelId: this.parentId
      })
    } else {
      this.$set(this.layerStyle.matrices, 'modelId', this.parentId)
      this.$set(this.layerStyle.matrices, 'loading', false)
    }
  },
  methods: {
    toggleMatrices(value) {
      this.layerStyle.matrices.enabled = value

      this.controllers.matrices.toggleMatrices(this.id)
    },
    changeProperty(property, value) {
      if (value) this.layerStyle.matrices[property] = value

      this.controllers.matrices.changeProperty(this.id)
    },
    changeData() {
      this.controllers.matrices.changeData(this.id)
    },
    changeCount: debounce(function(value) {
      this.layerStyle.matrices.count = value

      this.changeData()
    }, 300)
  }
}
</script>

<style lang="scss">
.matrices-control {
  &__block {
    &:not(:first-child) {
      margin-top: 8px;
    }
  }

  &__picker {
    display: flex;
    align-items: center;

    .r-slider {
      width: 60%;
      margin-right: 10px;
    }

    .r-input {
      width: 40% !important;
    }
  }

  .r-select {
    width: 100%;

    .el-input {
      font-size: 12px;
    }
  }

  .r-color {
    width: 100%;

    .el-color-picker {
      &__trigger {
        width: 100% !important;
        height: 38px !important;
        border-color: var(--field_border) !important;
        background-color: var(--field_bg) !important;
      }

      &__color {
        width: calc(100% - 28px);
        height: 28px;
        border-radius: var(--border-radius);
        border: none;

        &-inner {
          border-radius: var(--border-radius);
        }
      }

      &__icon {
        left: calc(100% - 14px);
        font-size: 18px !important;
      }
    }
  }
}
</style>
