<template>
  <div
    v-if="application"
    v-loading="loading"
    class="r-modal-card bdd-modal application-view"
  >
    <r-title color-type="subhead">
      Заявка на осмотр транспортного средства
    </r-title>
    <controls
      :loading="loading"
      :can-create-pass="canCreatePass"
      :archive="canArchive"
      :is-editing="applicationEditing"
      @clickHandler="clickHandler"
    />
    <bdd-tabs
      :view-tab="viewTab"
      :tabs="tabs"
      @changeTab="changeTab"
    />
    <div
      v-if="viewTab === 'main'"
      class="r-modal-card__content"
    >
      <bdd-block
        title="Заявка"
        main
        icon="acts-document"
      >
        <div
          class="
            r-modal-card__input-field r-modal-card__input-field--with-button
          "
        >
          <r-text color-type="secondary">
            Дата осмотра
          </r-text>
          <el-date-picker
            v-model="date"
            class="r-date-picker"
            type="datetime"
            size="mini"
            name="datetime"
            format="dd.MM.yyyy HH:mm"
            :picker-options="{ firstDayOfWeek: 1 }"
          />
          <r-button
            type="primary"
            :loading="loading"
            :disabled="applicationEditing"
            @click="clickHandler('add-permit')"
          >
            {{ hasActive ? 'Изменить время' : 'Назначить осмотр' }}
          </r-button>
        </div>
        <div
          class="
            r-modal-card__input-field
            r-modal-card__input-field--with-button
            r-modal-card__input-field--stretch
          "
        >
          <r-text color-type="secondary">
            Текущий статус
          </r-text>
          <r-text v-if="!application.status_id">
            Заявка в архиве
          </r-text>
          <el-select
            v-model="application.status_id"
            @change="changeStatus"
          >
            <el-option
              v-for="(item, i) in statusList"
              :key="i"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
          <r-button
            v-if="canCreatePass"
            type="primary"
            @click="clickHandler('create-pass')"
          >
            Пропуск на основе заявки
          </r-button>
        </div>
        <attributes-list
          :model="mainModel"
          :related="related"
          :source="application"
          :is-editing="applicationEditing"
          @hasChanges="setModalActive($event)"
        />
        <bdd-block title="Чек-листы и учёт осмотров">
          <bdd-checklists
            :application-id="activeApplication.id"
            :need-to-update="needToUpdateCheckLists"
            @setActive="setActive"
            @setUpdateStatus="setChecklistUpdate"
          />
        </bdd-block>
      </bdd-block>
      <bdd-block
        title="Транспортное средство и документы"
        main
        icon="car-hollow"
      >
        <bdd-block
          title="Документы"
          class="bdd-modal__docs-block"
        >
          <doc-list
            :docs="docs"
            :is-editing="applicationEditing"
            :source="application"
            :source_id="source_id"
          />
        </bdd-block>
        <app-vehicle-block
          v-if="application.vehicle"
          :model="vehicleModel"
          :related="related"
          :application="application"
        />
      </bdd-block>
      <reject-form />
    </div>
    <files-comments
      v-if="viewTab === 'filesComments'"
      :id="activeApplication.id"
      :source_id="source_id"
      column
      container
    />
  </div>
</template>

<script>
// helpers
import { tabs, docs, applicationModel } from '../../configs'
import { getApplicationUrlById } from '../helpers'
import { notifyFactory } from '@/utils'

// Components
import filesComments from '@/components/files-comments/files-comments'
import attributesList from '@/components/globals/r-modal-elements/attributes-list'
import bddBlock from '../components/bdd-block'
import bddChecklists from '../components/bdd-checklists'
import docList from '../components/doc-list'
import rejectForm from '../components/reject-form'
import controls from './controls'
import bddTabs from '../components/bdd-tabs'
import appVehicleBlock from './app-vehicle-block'

// Libs
import cloneDeep from 'lodash.clonedeep'

export default {
  components: {
    bddBlock,
    attributesList,
    docList,
    bddChecklists,
    filesComments,
    rejectForm,
    controls,
    bddTabs,
    appVehicleBlock
  },
  data() {
    return {
      loading: false,
      viewTab: 'main',
      application: null,
      applicationEditing: false,
      applicationModel,
      tabs,
      docs,
      checklistsSourceId: this.$store.state.bdd.checklistsUrl,
      needToUpdateCheckLists: false,
      clone: null,
      hasActive: null,
      date: null
    }
  },
  computed: {
    source_id() {
      return this.$store.state.bdd.applicationsSourceId
    },
    related() {
      return this.$store.state.bdd.applicationsRelated
    },
    statusList() {
      return this.related.applications_status_list.data
    },
    activeApplication() {
      return this.$store.state.bdd.activeApplication
    },
    activeApplicationId() {
      return this.$store.state.bdd.activeApplication?.id
    },
    mode() {
      return this.$store.state.bdd.applicationModalMode
    },
    mainModel() {
      return this.applicationModel.filter(
        f => f.parent !== 'vehicle' && f.model !== 'status_id'
      )
    },
    vehicleModel() {
      return this.applicationModel.filter(f => f.parent === 'vehicle')
    },
    canArchive() {
      const allowedStatusIds = this.statusList
        .filter(s => s.name.includes('Проведён'))
        .map(s => s.id)

      return allowedStatusIds.includes(this.activeApplication.status_id)
    },
    canCreatePass() {
      const app = this.activeApplication
      return app.status?.name?.includes() === 'нарушений нет'
    },
    permitStatus() {
      return this.statusList?.find(e => e.name === 'Назначен осмотр')?.id
    }
  },
  watch: {
    activeApplicationId(id) {
      if (id) this.loadApp()
    }
  },
  async mounted() {
    if (this.mode === 'edit') this.applicationEditing = true
    this.date = this.activeApplication?.inspection_dt || Date.now()
  },
  async created() {
    await this.loadApp()
  },
  beforeDestroy() {
    this.$emit('view')
  },
  methods: {
    async setActive(active) {
      this.hasActive = active
      this.date = active?.inspection_dt || this.date
    },
    setModalActive() {
      this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', true)
    },
    async loadApp() {
      this.loading = true
      try {
        const id = this.activeApplication?.id
        if (!id) return

        const url = getApplicationUrlById(id, this.source_id)
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })
        this.clone = cloneDeep(Object.values(data)[0])
        const app = this.formatMultipleField(
          Object.values(data)[0],
          'applications_employees',
          'employee_id'
        )
        this.application = app
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async deleteApp() {
      const { source_id, activeApplication } = this
      const id = activeApplication.id
      const data = { id, disabled: true }

      await this.objectInfoRequest('POST', source_id, data)

      this.$emit('update')
      this.$emit('close')
    },
    formatMultipleField(obj, field, key) {
      obj[field] = obj[field].map(i => {
        if (i) {
          return i[key]
        }
      })
      return obj
    },
    createPass() {
      this.$router.push({ path: '/app/bdd/pass-register' })
      this.$store.commit('CLOSE_MODAL_WINDOW', 'bdd-applications-modal')
      this.$store.commit('BDD_SET_FIELD', {
        field: 'passBasedOnAppId',
        value: this.activeApplication.id
      })
    },
    handleApp(cmd) {
      if (!this.applicationEditing) {
        this.applicationEditing = true
        return
      }
      if (cmd === 'discard') {
        this.loadApp()
        this.applicationEditing = false
        return
      }
      this.saveAppData(false, this.application)
      this.applicationEditing = false
    },
    async archiveApp() {
      this.loading = true
      if (!this.activeApplication?.id) return

      try {
        await this.saveAppData('status_id', null)

        this.$notify(
          notifyFactory('success', 'Уведомление', 'Заявка перенесена в архив')
        )
        this.$emit('update')
        this.$store.commit('CLOSE_MODAL_WINDOW', 'bdd-applications-modal')
        this.loading = false
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    async addPermit() {
      this.loading = true
      const { id } = this.application
      try {
        if (this.hasActive?.id) {
          await this.objectInfoRequest('PUT', this.checklistsSourceId, {
            id: this.hasActive.id,
            application_id: id,
            status_id: this.permitStatus,
            inspection_date: this.$rDate.format(this.date, 'iso')
          })
          const data = {
            id,
            inspection_dt: this.$rDate.format(this.date, 'iso')
          }
          await this.objectInfoRequest('POST', this.source_id, data)
        } else {
          await this.objectInfoRequest('POST', this.checklistsSourceId, {
            application_id: id,
            status_id: this.permitStatus,
            inspection_date: this.$rDate.format(this.date, 'iso')
          })
          const data = {
            id,
            inspection_dt: this.$rDate.format(this.date, 'iso'),
            status_id: this.permitStatus
          }
          await this.objectInfoRequest('POST', this.source_id, data)
        }

        this.$notify(notifyFactory('success', 'Назначена дата и время осмотра'))
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
        await this.loadApp()
        this.setChecklistUpdate(true)
        this.$emit('update')
      }
    },
    setChecklistUpdate(value) {
      this.needToUpdateCheckLists = value
    },
    changeTab(tab) {
      this.viewTab = tab
    },
    changeStatus(status_id) {
      this.saveAppData(
        'status_id',
        status_id || this.activeApplication.status_id
      )
    },
    employeesHandler(data) {
      const disabled =
        this.clone.applications_employees.map(e => {
          if (e) {
            return {
              id: e.id,
              disabled: true
            }
          }
        }) || []
      const newEmployees = data.applications_employees.map(e => {
        if (e) {
          return {
            employee_id: e
          }
        }
      })

      data.applications_employees = [...disabled, ...newEmployees]
      return data
    },
    async saveAppData(field, newData) {
      let data

      if (field) {
        data = { id: this.activeApplication.id }
        data[field] = newData
      } else {
        data = { ...newData }
      }

      if (data.applications_employees !== undefined) {
        data = this.employeesHandler(data)
      }

      await this.objectInfoRequest('POST', this.source_id, data)
      await this.loadApp()
      this.$emit('update')
      this.setChecklistUpdate(true)
    },
    async objectInfoRequest(method, source, data) {
      try {
        await this.$store.dispatch(`${method}_REQUEST`, {
          url: `objectInfo/${source}`,
          data
        })
      } catch (e) {
        console.log(e)
      }
    },
    clickHandler(action) {
      this.$emit('view')
      switch (action) {
        case 'add-permit':
          this.addPermit()
          break
        case 'create-pass':
          this.createPass()
          break
        case 'handle-application':
          this.handleApp()
          break
        case 'discard-changes':
          this.handleApp('discard')
          break
        case 'delete':
          this.deleteApp()
          break
        case 'archive':
          this.archiveApp()
          break
      }
    }
  }
}
</script>

<style lang="scss">
.bdd-modal {
  &__docs-block {
    .file-item-attribute:not(:last-child) {
      padding-bottom: 1rem;
      border-bottom: 1px solid var(--dividers_low_contrast);
    }
  }
}
</style>
