export const fields = [
  {
    name: 'Текущий статус',
    type: 'select',
    model: 'status',
    width: 'half'
  },
  {
    name: 'Дата создания',
    type: 'datetime',
    read_only: true,
    model: 'created_at'
  },
  {
    name: 'Тип',
    type: 'select',
    model: 'type'
  },
  {
    name: 'Группа',
    type: 'select',
    model: 'group'
  },
  {
    name: 'Подгруппа',
    type: 'select',
    model: 'subgroup'
  },
  {
    name: 'Назначение',
    type: 'text',
    model: 'assignment'
  },
  {
    name: 'Описание',
    type: 'text',
    model: 'description'
  },
  {
    name: 'Срок выполнения',
    type: 'datetime',
    model: 'date_until'
  },
  {
    name: 'Подрядчик',
    type: 'select',
    model: 'contractor'
  }
]

export const titles = {
  edit: 'Редактирование КПМ',
  create: 'Создание КПМ',
  view: 'Карточка КПМ'
}

export const sources = {
  store: 'bdd',
  field: 'preventive',
  updateField: 'updatePreventive'
}
