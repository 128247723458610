<template>
  <div class="rp-sidebar-users">
    <div class="rp-sidebar-users-header">
      <r-search-input
        :filter-text="filterText"
        @change="e => (filterText = e)"
      />
    </div>
    <rp-sidebar-users-list
      v-if="allUsers"
      :users="allUsers"
    />
  </div>
</template>

<script>
import rpSidebarUsersList from './rp-sidebar-users-list.vue'

export default {
  components: {
    rpSidebarUsersList
  },
  data() {
    return {
      filterText: ''
    }
  },
  computed: {
    allUsers() {
      const allUsers = this.$store.state.rolePermission.allUsers || []

      if (!this.filterText.trim()) return allUsers

      return (
        allUsers?.filter(e => {
          return (
            e.name
              ?.toLowerCase()
              ?.trim()
              ?.includes(this.filterText?.trim()?.toLowerCase()) ||
            e.last_name
              ?.toLowerCase()
              ?.trim()
              ?.includes(this.filterText?.trim()?.toLowerCase()) ||
            e.first_name
              ?.toLowerCase()
              ?.trim()
              ?.includes(this.filterText?.trim()?.toLowerCase()) ||
            e.middle_name
              ?.toLowerCase()
              ?.trim()
              ?.includes(this.filterText?.trim()?.toLowerCase())
          )
        }) || []
      )
    }
  }
}
</script>

<style lang="scss">
.rp-sidebar-users {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;

  &-header {
    display: flex;
    margin-bottom: 8px;
  }

  .r-button {
    margin-left: 8px;
    cursor: initial;
    &.icon-button {
      padding: 6px !important;
    }
  }
}
</style>
