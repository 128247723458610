export const layersConfig = {
  vehicles_clusters: {
    type: 'circle',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'step',
        ['get', 'point_count'],
        'rgba(153,213,148, 1)',
        10,
        'rgba(254,224,139, 1)',
        50,
        'rgba(252,141,89, 1)',
        100,
        'rgba(213,62,79, 1)'
      ],
      'circle-radius': [
        'step',
        ['get', 'point_count'],
        10,
        20,
        14,
        50,
        18
      ]
    }
  },
  vehicles_clusters_count: {
    type: 'symbol',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium'],
      'text-size': 11,
      'text-allow-overlap': true,
      'icon-allow-overlap': true
    }
  },
  vehicles: {
    type: 'symbol',
    filter: ['!', ['has', 'point_count']],
    layout: {
      'icon-image': [
        'case',
        ['==', ['get', 'type_name'], 'ЖД'], 'aghk-train-default',
        ['==', ['get', 'type_name'], 'Автобус'], 'aghk-bus-default',
        'aghk-car-default'
      ],
      'icon-pitch-alignment': 'map',
      'icon-rotate': ['get', 'course'],
      'icon-rotation-alignment': 'map',
      'icon-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        0.25,
        14,
        0.5,
        22,
        0.8
      ],
      'icon-ignore-placement': true,
      'text-allow-overlap': true,
      'icon-allow-overlap': true
    },
    paint: {
      'icon-opacity': 1
    }
  }
}
