<template>
  <div class="rp-attr-filter-content-note">
    <el-popover
      placement="left"
      width="560"
      trigger="click"
    >
      <div class="rp-attr-filter-content-note-wrapper">
        <r-text>
          <strong>Объекты фильтрации:</strong> В данный раздел попадают только
          те объекты, которые подходят условиям фильтров. Вы можете
          <strong>вручную исключать</strong> объекты.
        </r-text>
        <r-text>
          <strong>Не попавшие в фильтр:</strong> Сюда попадают объекты, которые
          не соответствуют заданным фильтрам. Здесь вы можете
          <strong>вручную добавлять</strong> объекты.
        </r-text>
      </div>
      <r-button
        slot="reference"
        v-popover:popover
        icon="help"
      >
        Помощь
      </r-button>
    </el-popover>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.rp-attr-filter-content-note {
  position: relative;
  flex-shrink: 0;
  margin-left: 8px;

  .r-button {
    padding: 8px 6px !important;
    background-color: transparent !important;

    &:focus {
      background-color: var(--button_secondary_bg) !important;
    }
  }
}
</style>
