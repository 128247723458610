<template>
  <div class="accident-intruders">
    <r-modal-card-block
      main
      icon="users"
      title="Участники"
      transparent
      no-border
      no-padding
      action-name="Добавить участника"
      action-icon="add-plus"
      @action="addIntruder"
    >
      <r-modal-card-block
        v-for="(intruder, i) in intruders"
        :key="i"
        :title="`Участник ${i + 1}`"
        toggler
        :open="intruder.open || false"
        delete-action
        @action="addIntruder"
      >
        <div slot="title-button">
          <r-delete-button
            simple
            tiny
            @delete="deleteIntruder(intruder)"
          />
        </div>
        <el-radio
          v-model="is_guilty"
          class="accident-intruders__radio r-radio"
          :label="intruder.id || intruder.temp_id"
        >
          <r-text>
            Виновник
          </r-text>
        </el-radio>
        <attributes-list
          :model="model.filter(s => s.block !== 'vehicle')"
          :source="intruder"
          :related="related"
          is-editing
          @hasChanges="$emit('hasChanges')"
        />
        <r-modal-card-block
          title="Транспортное средство"
          action-name="Создать ТС"
          action-icon="add-plus"
          @action="toggleVehicleCreating"
        >
          <attributes-list
            :model="model.filter(s => s.block === 'vehicle')"
            :source="intruder"
            :related="related"
            is-editing
          />
          <vehicle-modal-create
            v-if="modalCreateVisible"
            :visible="modalCreateVisible"
            @updateRelated="$emit('updateRelated')"
            @close="toggleVehicleCreating"
          />
        </r-modal-card-block>
      </r-modal-card-block>
    </r-modal-card-block>
  </div>
</template>

<script>
import attributesList from '@/components/globals/r-modal-elements/attributes-list'
import rModalCardBlock from '@/components/globals/r-modal-elements/r-modal-card-block'
import vehicleModalCreate from './vehicle-modal-create'
import model from '../config/intruders-model'

// import cloneDeep from 'lodash.clonedeep'

export default {
  components: {
    attributesList,
    rModalCardBlock,
    vehicleModalCreate
  },
  props: {
    related: {
      type: Object,
      required: true
    },
    source: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      model,
      modalCreateVisible: false,
      is_guilty: ''
    }
  },
  computed: {
    intruders() {
      return this.source.trafficaccident.intruders
        ?.filter(i => i.disabled !== true)
        ?.sort((a, b) => {
          if (a.no === null && b.no === null) {
            return 0
          }
          if (a.no === null) {
            return -1
          }
          if (b.no === null) {
            return 1
          }
          return a.no - b.no
        }) || []
    }
  },
  watch: {
    is_guilty(id) {
      this.source.trafficaccident.intruders = this.intruders.map(i => {
        return { ...i, is_guilty: i.id === id || i.temp_id === id }
      })
    }
  },
  mounted() {
    this.is_guilty = this.intruders.find(i => i.is_guilty)?.id || ''
  },
  methods: {
    toggleVehicleCreating(value) {
      this.modalCreateVisible = value || !this.modalCreateVisible
    },
    addIntruder() {
      const lastNo = this.intruders?.reduce(
        (no, c) => {
          if (!c.no && c.no !== 0) {
            return 0
          } else if (c.no >= no) {
            return c.no + 1
          } else {
            return no
          }
        }, 0) || 0

      const template = {
        trafficaccident_id: this.source.trafficaccident.id,
        open: true,
        disabled: false,
        temp_id: (+new Date()).toString(16),
        no: lastNo
      }
      const blank = this.model.reduce((a, c) => {
        if (c.model) {
          a[c.model] = null
        }
        return a
      }, template)

      this.source.trafficaccident.intruders.push(blank)
    },
    deleteIntruder({ id, temp_id }) {
      if (!id) {
        const index = this.intruders?.findIndex(i =>
          i.temp_id === temp_id
        )
        if (index > -1) {
          this.source.trafficaccident.intruders.splice(index, 1)
        }
      } else {
        const intruder = this.intruders.find(i =>
          temp_id ? i.temp_id === temp_id : i.id === id
        )
        intruder.disabled = true
      }
    }
  }
}
</script>
<style lang="scss">
.accident-intruders {
  &__action {
    justify-self: start;
  }

  &__radio  {
    display: flex !important;
    align-items: center;
  }
}
</style>
