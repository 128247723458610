<template>
  <div class="pt-route-stops">
    <r-table-list
      v-if="stops.length"
      :data="stops"
      :include-columns="['no', 'name', 'address', 'active']"
      :fields-names="fieldsExplanation"
      :actions="true"
    >
      <template v-slot:actions="{ row }">
        <div
          v-if="row.i !== 0 && row.i !== stops.length - 1"
          class="pt-route-stops__action"
        >
          <button
            :class="getFixButtonClass(row)"
            @click="fixItemHandler(row)"
          >
            <r-icon
              name="lock"
              :size="24"
            />
          </button>
          <button
            class="pt-route-stops__action"
            @click="deleteHandler(row)"
          >
            <r-icon
              name="trash"
              color-type="error"
              :size="24"
            />
          </button>
        </div>
      </template>
    </r-table-list>
    <div
      v-else
      class="pt-route-stops__hint"
    >
      <r-text type="caption">
        Нарисуйте маршрут на карте для автоматического добавления остановок
      </r-text>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fieldsExplanation: {
        no: 'Номер',
        name: 'Название',
        address: 'Адрес',
        active: 'Работает'
      }
    }
  },
  computed: {
    stops() {
      const { editorState } = this.$store.state.publicTransport
      const { lineRouteItems } = editorState

      const getGeom = p => {
        if (p.stop_point) {
          if (p.stop_point.geom) {
            return p.stop_point.geom
          }

          return p.stop_point
        }

        return p.geom
      }

      return lineRouteItems
        .filter(p => p.stop_point_id && !p?.disabled)
        .map((p, i) => ({
          no: p.stop_point_no || (p.stop_point && p.stop_point.no),
          name: p.stop_point_name || (p.stop_point && p.stop_point.name),
          address:
            p.stop_point_address ||
            (p.stop_point && p.stop_point.address) ||
            'Нет адреса',
          active:
            p.stop_point_active || (p.stop_point && p.stop_point.is_active)
              ? 'Да'
              : 'Нет',
          index: p.index,
          geom: getGeom(p),
          stop_point_id: p.stop_point_id,
          i
        }))
    }
  },
  methods: {
    fixItemHandler(row) {
      this.$store.commit('TOGGLE_PT_FIXED_ROUTE_ITEM', row)
    },
    getFixButtonClass(row) {
      const { editorState } = this.$store.state.publicTransport
      const { fixedRouteItems } = editorState

      return fixedRouteItems.findIndex(
        r => r.stop_point_id === row.stop_point_id
      ) > -1
        ? 'pt-route-stops__fix-button pt-route-stops__fix-button--active'
        : 'pt-route-stops__fix-button'
    },
    deleteHandler(row) {
      const { editorState } = this.$store.state.publicTransport
      const { lineRouteItems } = editorState

      this.$store.commit('SET_PT_EDITOR_PROP', {
        field: 'lineRouteItems',
        value: lineRouteItems.map(item => {
          if (item.index === row.index) {
            item.disabled = true
          }

          return item
        })
      })

      // TODO: don't work
      // this.$store.commit('SET_PT_EDITOR_PROP', {
      //   field: 'lriToDelete',
      //   value: row.index
      // })
    }
  }
}
</script>

<style lang="scss">
.pt-route-stops {
  width: 100%;

  &__action {
    display: flex;
    align-items: center;

    button {
      background: none;
      border: none;
      cursor: pointer;
    }

    .r-icon {
      margin-right: 8px;
    }
  }

  &__fix-button {
    opacity: 0.4;

    &--active {
      opacity: 1;
    }
  }
}
</style>
