<template>
  <div class="rp-roles-content">
    <rp-roles-header />
    <rp-roles-control />
  </div>
</template>

<script>
import rpRolesHeader from './rp-roles-header'
import rpRolesControl from './rp-roles-control'

export default {
  components: {
    rpRolesHeader,
    rpRolesControl
  }
}
</script>

<style lang="scss">
.rp-roles-content {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 16px;
  display: flex;
  flex-direction: column;
}
</style>
