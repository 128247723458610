<template>
  <div class="ds-manager__component general">
    <div class="ds-manager__component-title">
      <r-title type="title-2">
        {{ $t('ds-manager:general:title') }}
      </r-title>
    </div>
    <div class="ds-manager__component-content">
      <ds-tree
        module="dsManagerTree"
        show-tools
        :node-click="openDsEditor"
        :tool-kit="toolKit"
        :tool-click="toolClick"
      />
    </div>
  </div>
</template>

<script>
import dsTree from '@/components/ds-tree/ds-tree.vue'

const tools = [
  {
    icon: 'download',
    type: 'download',
    name: 'Скачать'
  },
  {
    icon: 'settings',
    type: 'settings',
    name: 'Настройки',
    only: 'model'
  }
]

export default {
  components: {
    dsTree
  },
  props: {
    menuClickHandler: {
      type: Function,
      default: () => null
    }
  },
  computed: {
    dsLength() {
      return this.$store.state.dsManager.dsTree.length
    }
  },
  methods: {
    async toolClick(type, data) {
      switch (type) {
        case 'download': {
          if (!data.source_id) data.source_id = data.id
          this.$store.commit('SET_DS_EXPORT_ITEM', data)
          this.$store.commit('OPEN_MODAL_WINDOW', 'ds-export')
          break
        }
        case 'settings': {
          await this.openDsEditor(data)
          break
        }
      }
    },
    toolKit({ datatype }) {
      return tools.filter(tool => !tool.only || tool.only === datatype)
    },
    openDsEditor(object) {
      this.$store.commit('SET_DS_MANAGER_ACTIVE_DS', object)
      this.menuClickHandler('ds-manager-editor')
    }
  }
}
</script>

<style lang="scss" scoped>
.ds-manager__component {
  &-content {
    background-color: var(--bg_containers);
    border-radius: var(--border-radius);
  }

  &.general {
    .ds-tree {
      height: 100%;
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "ds-manager:general:title": "Менеджер источников данных",
    "ds-manager:general:subtitle": "Список доступных источников данных"
  },
  "en": {
    "ds-manager:general:title": "Data source manager",
    "ds-manager:general:subtitle": "Available data source list"
  }
}
</i18n>
