<template>
  <bdd-checklists />
</template>

<script>
import bddChecklists from '@/components/bdd-checklists/bdd-checklists.vue'

export default {
  components: {
    bddChecklists
  }
}
</script>
