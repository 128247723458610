import { getModelChildrenIds } from '@/utils'

export const setSiCenterEventsInfo = async state => {
  const eventsInfo = await getData(state)

  return eventsInfo
}

export const setMainModelId = async(component) => {
  const { data } = await component.$store.dispatch('POST_REQUEST', {
    url: 'user/datasources'
  })

  const mainModelId = data.filter(e => e.is_main_model)[0]?.id
  component.$store.commit('SET_MAIN_MODEL_ID', mainModelId)
}

export const getDatasourceById = async(component, id) => {
  const config = {
    where: [
      {
        field: 'id',
        op: '=',
        value: id,
        type: 'AND'
      }
    ]
  }

  const { data } = await component.$store.dispatch('GET_REQUEST', {
    url: `permission_object?config=${JSON.stringify(config)}`
  })

  return data[id]
}

const getData = async state => {
  try {
    const { mainModelId } = state
    const data = await getDatasourceById(state, mainModelId)
    const model = {
      id: data.id,
      name: data.name,
      ids: getModelChildrenIds(data.children)
    }
    const classes = await getEventsClasses(state, model.ids.event_classes)
    if (!classes) return
    const eventsInfo = {
      layer_id: model.ids.events,
      stats_id: 'event_stat',
      url_id: `event_stat/${model.id}`,
      classes: classes,
      event_editor_link: `/app/event-editor?id=${model.id}`
    }

    state.$store.commit('SET_SI_CENTER_EVENTS_INFO', eventsInfo)
    state.$store.commit('SET_SI_CENTER_ACTIVE_SC', model)

    return eventsInfo
  } catch (e) {
    console.log(e)
  }
}

const getEventsClasses = async(state, id) => {
  try {
    const { data } = await state.$store.dispatch('GET_REQUEST', {
      url: `objectInfo/${id}`
    })

    return data
  } catch (e) {
    console.log(e)
    return null
  }
}

export const modifyInstanceConfig = state => {
  const eventsInfo = state.$store.state.situationCenter.events
  if (!eventsInfo.layer_id) return
  const layoutBottom =
    state.$store.state.situationCenter.config.layout.bottom_block
  const mapLayesrConfig = state.$store.state.situationCenter.config.mapLayers
  state.$store.state.situationCenter.config.layout.bottom_block = layoutBottom.map(
    e => {
      if (e.props.event_layer) {
        e.props.layer_id = eventsInfo.stats_id
        e.props.request.type = 'statistic'
        e.props.request.url = eventsInfo.url_id
      }
      if (e.props?.request?.event_stat) {
        e.props.request.url = `event_stat/${state.mainModelId}?format=widget&type=${e.props?.request?.propType}`
      }
      return e
    }
  )
  state.$store.state.situationCenter.config.mapLayers = mapLayesrConfig.map(
    e => {
      if (e.event_layer) {
        e.layer_id = 'events'
        e.request.type = 'objectInfo'
        e.request.url = eventsInfo.layer_id
      }
      return e
    }
  )
}
