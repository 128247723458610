export const parseModules = function(modulesConfig, modulesList) {
  const config = modulesConfig.map(e => {
    e.components.map(l => {
      const component = modulesList.find(p => p.name === l.name)

      l.id = component?.id
      l.instruments = component?.instruments || null

      return l
    })

    return e
  })

  return config
}
