export const userFields = [
  {
    id: 1,
    value: 'name',
    type: 'string'
  },
  {
    id: 2,
    value: 'password',
    type: 'password'
  },
  {
    id: 3,
    value: 'email',
    type: 'email'
  },
  {
    id: 4,
    value: 'role_name',
    type: 'role_name'
  },
  {
    id: 5,
    value: 'valid_until',
    type: 'date'
  },
  {
    id: 6,
    value: 'admin',
    type: 'checkbox'
  }
]

export const userRolesList = [
  {
    id: 0,
    value: 'Не выбрано'
  },
  {
    id: 1,
    value: 'Руководитель'
  },
  {
    id: 2,
    value: 'Оператор'
  }
]

export const userFieldsInfo = [
  {
    id: 1,
    value: 'last_name',
    type: 'string'
  },
  {
    id: 2,
    value: 'first_name',
    type: 'string'
  },
  {
    id: 3,
    value: 'middle_name',
    type: 'string'
  },
  {
    id: 4,
    value: 'company',
    type: 'string'
  },
  {
    id: 5,
    value: 'position',
    type: 'string'
  }
]

export const userTemplate = {
  name: '',
  first_name: '',
  last_name: '',
  middle_name: '',
  position_id: '',
  admin: false,
  password: ''
}
