<template>
  <div
    v-loading="loading"
    class="odd-objects"
  >
    <r-panel-header
      v-if="creating"
      title="Новый объект"
      with-back-button
      @back="cancel"
    />
    <r-tabs
      v-show="!creating"
      v-model="tab"
    >
      <odd-add-controls :type="tab" />
      <div class="odd-objects__filters">
        <r-search-input
          :filter-text="filterText"
          @change="value => changeHandler(value, 'filterText')"
        />
        <r-button
          icon="filter"
          :active="isFiltersVisible"
          type="secondary"
          @click="toggleFilters"
        />
        <odd-object-filter
          v-if="isFiltersVisible"
          :tab="tab"
          :active="isFiltersVisible"
        />
      </div>
      <r-tab
        id="signs"
        active
        name="Дорожные знаки"
      >
        <odd-object-list
          v-if="signs.length"
          :objects="signs"
          type="signs"
          class="odd-objects__list"
        />
        <r-text
          v-else-if="!loading"
          type="caption"
        >
          Список пуст
        </r-text>
      </r-tab>
      <r-tab
        id="road_blocks"
        name="Перекрытия"
      >
        <odd-object-list
          v-if="events.length"
          :objects="events"
          type="events"
          class="odd-objects__list"
        />
        <r-text
          v-else-if="!loading"
          type="caption"
        >
          Список пуст
        </r-text>
      </r-tab>
    </r-tabs>
    <odd-create-helper
      v-if="creating"
      key="odd-create-helper"
    />
    <odd-new-object key="odd-new-object" />
  </div>
</template>

<script>
import OddCreateHelper from '../helpers/odd-create-helper'
import OddAddControls from '../controls/add-controls'
import OddObjectList from '../list/object-list'
import OddObjectFilter from '../filter/object-filter'

import OddNewObject from '../card/new-object'

export default {
  components: {
    OddCreateHelper,
    OddAddControls,
    OddObjectList,
    OddObjectFilter,
    //
    OddNewObject
  },
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      tab: 'road_blocks',
      filterText: '',
      isFiltersVisible: false
    }
  },
  computed: {
    creating() {
      return this.$route.name === 'odd-create'
    },
    signs() {
      return this.$store.state.odd.lists.signs.filter(s =>
        s.name
          ?.trim()
          .toLowerCase()
          .includes(this.filterText.trim().toLowerCase())
      )
    },
    events() {
      return this.$store.state.odd.lists.events.filter(s =>
        s.name
          ?.trim()
          .toLowerCase()
          .includes(this.filterText.trim().toLowerCase())
      )
    }
  },

  methods: {
    toggleFilters() {
      this.isFiltersVisible = !this.isFiltersVisible
    },
    cancel() {
      this.$router.push('/app/odd/objects')
      this.$store.commit('SET_ODD_FIELD', { field: 'newCardId', value: null })
    },
    changeHandler(value, field) {
      this[field] = value
    }
  }
}
</script>

<style lang="scss">
.odd-objects {
  display: grid;
  padding: 0.5rem;
  grid-gap: 0.5rem;
  overflow: hidden;
  height: 100%;
  position: relative;
  align-content: start;

  &__filters {
    display: grid;
    grid-gap: 0.25rem;
    grid-template-areas: 'input btn' 'filters filters';

    .r-search-input {
      grid-area: input;
    }

    .r-button {
      grid-area: btn;
    }

    .object-filter {
      grid-area: filters;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
  }
}
</style>
