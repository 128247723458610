<template>
  <div class="pt-l-schedule">
    <div class="pt-l-schedule__header">
      <el-select
        v-if="vehicleJourneys.length"
        v-model="activeSchedule"
        class="pt-l-schedule__filter"
      >
        <el-option
          v-for="(item, i) in vehicleJourneys"
          :key="i"
          :label="item.dep.substring(0, 5)"
          :value="i"
        />
      </el-select>

      <r-button
        type="primary"
        class="pt-l-schedule__editor"
        @click="handleModal('open')"
      >
        Настроить расписание
      </r-button>
    </div>
    <div
      v-if="vehicleJourneys.length"
      class="pt-l-schedule__table"
    >
      <r-table-list
        :excluded-columns="excludedColumns"
        :data="tableVehicleJourneysData"
        :fields-names="fieldsExplanation"
        @click-handler="rowClickHandler"
      />
    </div>
    <div
      v-else
      class="pt-l-schedule__nodata"
    >
      <r-text type="caption">
        Расписание отсутствует
      </r-text>
    </div>

    <schedule-editor
      v-if="showEditor"
      :show="showEditor"
      @close="handleModal('close')"
    />
  </div>
</template>

<script>
import ScheduleEditor from './schedule-modal'
import { subtractTime } from '@/utils'

export default {
  components: {
    ScheduleEditor
  },
  data() {
    return {
      activeSchedule: 0,
      excludedColumns: ['id', 'index'],
      fieldsExplanation: {
        name: 'Остановка',
        arr: 'Прибытие',
        park: 'Стоянка, мин',
        dep: 'Отправление'
      },
      showEditor: false
    }
  },
  computed: {
    tableVehicleJourneysData() {
      const data = this.vehicleJourneys[this.activeSchedule].veh_journey_items
      data[data.length - 1].park = data[data.length - 1].dep = '—'
      return data
    },
    vehicleJourneys() {
      const {
        lineRouteItems,
        vehicleJourneys
      } = this.$store.state.publicTransport

      if (!vehicleJourneys) return []

      return vehicleJourneys.map(journey => ({
        ...journey,
        veh_journey_items: journey?.veh_journey_items
          .map(({ arr, dep, stop_index }) => {
            const items = lineRouteItems || []
            const lineRouteItem = items.find(
              ({ index }) => index === stop_index
            )
            const fArr = arr?.substring(0, 5)
            const fDep = dep?.substring(0, 5)

            return {
              id: lineRouteItem,
              name:
                lineRouteItem && lineRouteItem.stop_point
                  ? lineRouteItem.stop_point?.name ||
                    lineRouteItem.stop_point?.no
                  : '—',
              arr: fArr,
              dep: fDep,
              park: subtractTime(fArr, fDep) || '—',
              index: stop_index
            }
          })
          .sort((a, b) => a.index - b.index)
      }))
    }
  },
  methods: {
    handleModal(action) {
      switch (action) {
        case 'close':
          this.showEditor = false
          this.$store.commit('SET_PT_FIELD', {
            field: 'needToUpdateSchedule',
            value: true
          })
          break
        case 'open':
          this.showEditor = true
          break

        default:
          break
      }
    },
    rowClickHandler({ id }) {
      this.$store.commit('SET_PT_FIELD', {
        field: 'cardType',
        value: 'stop_points'
      })
      this.$store.commit('SET_PT_FIELD', {
        field: 'cardId',
        value: id.stop_point?.id
      })
    }
  }
}
</script>

<style lang="scss">
.pt-l-schedule {
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  overflow: hidden;
  align-content: start;

  &__header {
    margin-bottom: 8px;
    display: grid;
    justify-content: space-between;
    max-width: 100%;
    grid-auto-flow: column;
  }

  &__editor {
    margin-left: auto;
  }

  &__table {
    width: 100%;
    display: flex;
    align-items: flex-start;
    overflow: hidden;
  }

  &__filter {
    margin-right: 16px;
    width: 100%;
    overflow: hidden;
  }
}
</style>
