export const crudsTypes = [
  {
    id: 1,
    index: 0,
    title: 'create',
    name: 'Создание',
    value: false
  },
  {
    id: 2,
    index: 1,
    title: 'read',
    name: 'Чтение',
    value: true
  },
  {
    id: 3,
    index: 2,
    title: 'update',
    name: 'Редактирование',
    value: false
  },
  {
    id: 4,
    index: 3,
    title: 'delete',
    name: 'Удаление',
    value: false
  },
  {
    id: 5,
    index: 4,
    title: 'share',
    name: 'Управление',
    value: false
  },
  {
    id: 6,
    index: 5,
    title: 'export',
    name: 'Экспорт',
    value: false
  }
]
