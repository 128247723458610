export const layersConfig = {
  route: {
    type: 'line',
    layout: {},
    paint: {
      'line-width': 3,
      'line-color': '#2E93FE'
    }
  },
  pot_info: {
    type: 'symbol',
    layout: {
      'text-field': ['get', 'title'],
      'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
      'text-radial-offset': 0.5,
      'text-justify': 'auto'
    }
  },
  checkpoint_info: {
    type: 'symbol',
    layout: {
      'text-field': ['get', 'title'],
      'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
      'text-radial-offset': 0.5,
      'text-justify': 'auto'
    }
  },
  warehouse_info: {
    type: 'symbol',
    layout: {
      'text-field': ['get', 'title'],
      'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
      'text-radial-offset': 0.5,
      'text-justify': 'auto'
    }
  }
}
