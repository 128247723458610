import io from 'socket.io-client'
import constants from '@/constants/url'
import { getGroupId, getUsername } from '@/utils'

export default {
  install(Vue) {
    const socket = io(constants.SOCKET_URL, {
      path: '/ws',
      autoConnect: true,
      reconnectionDelay: 120000,
      reconnectionDelayMax: 120000,
      extraHeaders: {
        remote_user: getUsername(),
        groupid: getGroupId()
      }
    })

    socket.onopen = e => {
      console.log('socket connected')
    }

    socket.onclose = e => {
      console.warn('socket disconnected', e)
    }

    Vue.prototype.$socket = socket
    Vue.$socket = socket
  }
}
