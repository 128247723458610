import { lineString, polygon } from '@turf/helpers'
import { toMercator, toWgs84 } from '@turf/projection'
import lineOffset from '@/libs/line-offset'

export const createClassesValue = (field, conditions) => {
  const value = ['interpolate', ['linear'], ['get', field]]

  for (let i = conditions.length - 1; i >= 0; i--) {
    if (i === conditions.length - 1) {
      value.push(0)
      value.push(conditions[i].style.color)
    } else {
      value.push(conditions[i + 1].value || 0.01)
      value.push(conditions[i].style.color)
    }
  }

  return value
}

export const createStringsValue = (field, values, defaultValue) => {
  const styleValue = ['match', ['get', field]]

  values.forEach(({ color, value }) => {
    styleValue.push(value)
    styleValue.push(color)
  })

  styleValue.push(defaultValue)

  return styleValue
}

export const createHeatmapColorValue = (field, conditions) => {
  const values = ['interpolate', ['linear'], [field]]

  for (let i = 0; i < conditions.length; i++) {
    const { color, value } = conditions[i]
    values.push(value)
    if (value === 0) values.push('rgba(255,255,255,0)')
    else values.push(color)
  }

  return values
}

export const createRangeValue = (field, range, fieldMin, fieldMax) => {
  return [
    'interpolate',
    ['linear'],
    ['get', field],
    fieldMin,
    range[0],
    fieldMax,
    range[1]
  ]
}

export const createExtrusionRangeValue = (
  field,
  fieldMin,
  fieldMax,
  height
) => {
  return [
    'interpolate',
    ['linear'],
    ['get', field],
    fieldMin,
    0,
    fieldMax,
    height
  ]
}

export const getDiagramGeojson = (features, config) => {
  const { field, maxValue, width } = config
  const diagramFeatures = []

  features.forEach(f => {
    const props = f.properties
    const value = Math.abs(props[field]) || 0
    const weight = field ? (value / maxValue) * width : width

    if (isFinite(weight) && weight > 0) {
      const initial = f.geometry.coordinates
      const line = lineString(initial)
      const mercLine = toMercator(line)
      const lineCoords = mercLine.geometry.coordinates
      const offset = lineOffset(mercLine, weight, { units: 'metres' })

      if (offset) {
        const offsetCoords = offset.geometry.coordinates
        offsetCoords.reverse()
        const mercCoordinates = [
          [...lineCoords, ...offsetCoords, lineCoords[0]]
        ]
        const coordinates = toWgs84(polygon(mercCoordinates)).geometry
          .coordinates

        diagramFeatures.push({
          type: 'Feature',
          properties: { ...f.properties },
          geometry: {
            type: 'Polygon',
            coordinates
          }
        })
      }
    }
  })

  return { type: 'FeatureCollection', features: diagramFeatures }
}

export const getLayerColor = (config, type) => {
  const { classesStyle, stringsStyle, style } = config
  const classes = classesStyle[type]
  const strings = stringsStyle[type]
  let color

  if (classes.field) {
    color = createClassesValue(classes.field, classes.conditions)
  } else if (strings.field) {
    color = createStringsValue(
      strings.field,
      strings.values,
      strings.defaultValue
    )
  } else {
    color = style.paint[type]
  }

  return color
}

export const getLayerWidth = (config, type) => {
  const { classesStyle, style } = config
  const rangeConfig = classesStyle[type]
  const { field, range, fieldMin, fieldMax } = rangeConfig

  if (rangeConfig.field) { return createRangeValue(field, range, fieldMin, fieldMax) }

  return style.paint[type]
}

export const getColorValueWithActive = (id, value) => {
  const condition = [
    'case',
    ['==', ['get', 'id'], String(id)],
    'rgb(255, 68, 140)'
  ]
  return id ? [...condition, value] : value
}
