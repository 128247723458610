<template>
  <div class="style-settings">
    <point-style
      v-if="
        geomType === 'point' ||
          geomType === 'multi_point' ||
          geomType === 'geometry'
      "
      :id="id"
      :layer-style="layerStyle"
      :controllers="controllers"
      @change-property="changeProperty"
      @change-classes="changeClasses"
      @change-strings="changeStrings"
      @change-range="changeRange"
    />
    <line-style
      v-else-if="geomType === 'line_string' || geomType === 'multi_line_string'"
      :id="id"
      :layer-style="layerStyle"
      :controllers="controllers"
      @change-property="changeProperty"
      @change-classes="changeClasses"
      @change-strings="changeStrings"
      @change-range="changeRange"
      @toggle-line-property="toggleLineProperty"
    />
    <polygon-style
      v-else-if="geomType === 'polygon' || geomType === 'multi_polygon'"
      :id="id"
      :layer-style="layerStyle"
      :controllers="controllers"
      @change-property="changeProperty"
      @change-classes="changeClasses"
      @change-strings="changeStrings"
      @change-range="changeRange"
    />
  </div>
</template>

<script>
import PointStyle from './point-style'
import LineStyle from './line-style'
import PolygonStyle from './polygon-style'

export default {
  components: {
    PointStyle,
    LineStyle,
    PolygonStyle
  },
  props: {
    id: {
      type: String,
      required: true
    },
    layerStyle: {
      type: Object,
      required: true
    },
    geomType: {
      type: String,
      required: true
    },
    controllers: {
      type: Object,
      required: true
    }
  },
  methods: {
    changeProperty({ type, field }) {
      this.controllers.style.changeProperty(this.id, type, field)
    },
    changeClasses({ field }) {
      this.controllers.style.changeColorClasses(this.id, field)
    },
    changeStrings({ field }) {
      this.controllers.style.changeColorStrings(this.id, field)
    },
    changeRange({ field }) {
      this.controllers.style.changeRange(this.id, field)
    },
    toggleLineProperty(type) {
      switch (type) {
        case 'dashed':
          return this.controllers.style.toggleLineProperty(this.id, type)
        case 'arrows':
          return this.controllers.layers.toggleArrows(this.id)
      }
    }
  }
}
</script>

<style lang="scss">
.map-style {
  &__block {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
</style>
