<template>
  <div :class="['r-modal-card__block', main ? 'r-modal-card__block--main' : '']">
    <div class="r-modal-card__block-header">
      <r-title
        v-if="title"
        class="r-modal-card__block-title"
        :type="`subtitle-${main ? 1 : 2}`"
      >
        <r-icon
          v-if="icon"
          :size="24"
          :name="icon"
        />
        {{ title }}
      </r-title>
      <slot name="button" />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    main: {
      type: Boolean,
      default: false
    }
  }
}
</script>
