import { rDate } from '@/utils'

export const filtersEncoder = filters => {
  const where = []

  filters
    .filter(f => f.active)
    .forEach(f => {
      switch (f.type) {
        case 'checkbox-filter':
          where.push(simpleCheckbox(f))
          break
        case 'day-filter':
        case 'interval-filter':
          where.push(simpleInterval(f, f.id))
          break
        case 'radio-filter':
          where.push(simpleRadio(f))
          break
        case 'string-filter':
          where.push(stringFilter(f))
          break
        case 'range-filter':
          where.push(simpleRange(f))
          break
        default:
          break
      }
    })
  return { where }
}

const stringFilter = f => {
  return {
    field: f.id,
    value: f.prop,
    op: 'like'
  }
}

const simpleCheckbox = f => {
  return {
    field: f.id,
    value: f.prop.filter(p => p.value).map(p => p.id),
    op: 'in'
  }
}

const simpleRadio = f => {
  const value = f.prop.find(p => p.value).id
  return value !== 'all'
    ? {
      field: f.id,
      value: value === 'yes' ? [true] : [null, false],
      op: 'in'
    }
    : {}
}

const simpleInterval = (f, field) => {
  let { from, to } = f.prop.interval

  if (!from || !to) {
    from = from || new Date(0)
    to = to || new Date()
  }

  from = rDate.format(from, 'iso')
  to = rDate.format(to, 'iso')

  return {
    field,
    op: 'between',
    value: `${from}/${to}`
  }
}

const simpleRange = (f) => {
  const { from, to } = f.prop.interval
  const field = f.id

  if (!from && from !== 0) {
    return {
      field,
      op: '<=',
      value: to
    }
  } else if (!to && to !== 0) {
    return {
      field,
      op: '>=',
      value: from
    }
  } else {
    return {
      field,
      op: 'between',
      value: `${from}/${to}`
    }
  }
}
