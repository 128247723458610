<template>
  <div
    v-loading="loading"
    class="r-modal-card bdd-modal"
  >
    <div class="bdd-modal-create">
      <r-title color-type="subhead">
        Создать заявку на осмотр ТС
      </r-title>
      <div
        v-if="alreadyExist"
        class="bdd-modal-create__already-exist"
      >
        <r-text>Для данного ТС уже существует незавершённая заявка.</r-text>
        <r-button
          type="primary"
          @click="clickHandler('create')"
        >
          Открыть
        </r-button>
      </div>
      <div class="bdd-modal-create__picker">
        <r-text> Транспортное средство </r-text>
        <el-select
          v-model="vehicleId"
          class="r-select"
          placeholder="Выбрать"
          size="mini"
          filterable
        >
          <el-option
            v-for="vehicle in vehicles"
            :key="vehicle.id"
            :label="vehicle.reg_number"
            :value="vehicle.id"
          />
        </el-select>
      </div>
      <r-text>
        Если не нашли ТС, вы можете<r-button
          link
          active
          simple
          type="primary"
          @click="createNewVehicle"
        >
          создать новое
        </r-button>
      </r-text>
      <r-button
        class="bdd-modal-create__create-button"
        type="primary"
        :disabled="!vehicleId"
        :loading="loading"
        @click="clickHandler('create')"
      >
        {{ alreadyExist ? 'Открыть заявку' : 'Создать заявку' }}
      </r-button>
    </div>
  </div>
</template>

<script>
import { notifyFactory } from '@/utils'

export default {
  components: {},
  data() {
    return {
      loading: false,
      applicationsSourceId: this.$store.state.bdd.applicationsSourceId || null,
      checklistsUrl: this.$store.state.bdd.checklistsUrl || null,
      vehiclesUrl: this.$store.state.services.vehicles || null,
      vehicleId: null,
      vehicles: null
    }
  },
  computed: {
    applications() {
      return this.$store.state.bdd.applications || null
    },
    related() {
      return this.$store.state.bdd.applicationsRelated
    },
    newStatus() {
      return this.related.applications_status_list.data?.find(
        e => e.name === 'Новая заявка'
      )?.id
    },
    alreadyExist() {
      return (
        this.applications?.find(e => e.vehicle_id === this.vehicleId) || null
      )
    }
  },
  created() {
    this.getVehicles()
  },
  mounted() {
    this.setWidth(400)
  },
  beforeDestroy() {
    this.setWidth(640)
  },
  methods: {
    setWidth(width) {
      const dialog = document.querySelector('[aria-label="dialog"]')
      if (dialog) {
        dialog.style.width = `${width}px`
      }
    },
    async getVehicles() {
      this.loading = true

      try {
        const config = { only: ['id', 'reg_number'] }
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/${this.vehiclesUrl}?config=${JSON.stringify(config)}`
        })

        this.vehicles = data
        this.loading = false
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    clickHandler(action) {
      switch (action) {
        case 'create':
          this.beforeCreate()
          break
      }
    },
    beforeCreate() {
      if (!this.alreadyExist?.id) {
        this.createApplication()
      } else {
        this.openApplication(this.alreadyExist)
      }
    },
    openApplication(app) {
      this.$store.commit('BDD_SET_FIELD', {
        field: 'activeApplication',
        value: app
      })

      this.$store.commit('BDD_SET_FIELD', {
        field: 'applicationModalMode',
        value: 'edit'
      })
      this.$store.commit('BDD_SET_FIELD', {
        field: 'openAfterUpdate',
        value: app.id
      })
      this.$store.commit('BDD_SET_FIELD', {
        field: 'updateApplications',
        value: true
      })
    },
    async createApplication() {
      this.loading = true

      try {
        const bodyData = this.getData()

        const { data } = await this.$store.dispatch('PUT_REQUEST', {
          url: `objectInfo/${this.applicationsSourceId}`,
          data: bodyData
        })
        const app = data[0]
        this.openApplication(app)

        this.$notify(
          notifyFactory(
            'success',
            'Уведомление',
            'Назначена дата и время осмотра'
          )
        )
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false

        this.$store.commit('BDD_SET_FIELD', {
          field: 'updateApplications',
          value: true
        })
      }
    },
    getData() {
      return {
        status_id: this.newStatus,
        vehicle_id: this.vehicleId
      }
    },
    createNewVehicle() {
      this.$store.commit('BDD_SET_FIELD', {
        field: 'modalName',
        value: 'appVehicleCreateModal'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.bdd-modal {
  &-create {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1rem;

    &__picker {
      display: grid;
      grid-gap: 0.5rem;
    }

    &__create-button {
      justify-self: start;
    }

    &__already-exist {
      display: grid;
      grid-template-columns: 250px auto;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem;
      border-radius: var(--border-radius);
      background-color: var(--field_error_bg);
    }
  }
}
</style>
