export * from './table'

export const dataRequest = async function(state, url) {
  try {
    const { data } = await state.$store.dispatch('GET_REQUEST', { url })

    return data
  } catch (e) {
    throw new Error(e)
  }
}

// load icons
const iconsBank = [
  'aghk-bus-default',
  'aghk-bus-disabled',
  'aghk-bus-selected',
  'aghk-car-default',
  'aghk-car-disabled',
  'aghk-car-selected',
  'aghk-train-default',
  'aghk-train-disabled',
  'aghk-train-selected',
  'aghk-truck-default',
  'aghk-truck-disabled',
  'aghk-truck-selected',
  'agpz-bus-default',
  'agpz-bus-disabled',
  'agpz-bus-selected',
  'agpz-car-default',
  'agpz-car-disabled',
  'agpz-car-selected',
  'agpz-train-default',
  'agpz-train-disabled',
  'agpz-train-selected',
  'agpz-truck-default',
  'agpz-truck-disabled',
  'agpz-truck-selected'
]
const icons = iconsBank.map(e => ({
  name: e,
  imageUrl: require(`@/assets/images/monitoring/${e}.svg`)
}))
const MAP_ICON_SIZE = 64

export const loadIcons = (component) => {
  return Promise.all(
    icons.map(
      ({ name, imageUrl }) =>
        new Promise(resolve => {
          const image = new Image(MAP_ICON_SIZE, MAP_ICON_SIZE)
          image.crossOrigin = 'Anonymous'
          image.style.backgroundPosition = '50%, 50%'
          image.style.backgroundSize = '100%'
          image.addEventListener('load', () => {
            component.mapgl.addImage(name, image)
            resolve()
          })
          image.src = imageUrl
        })
    )
  )
    .then(() => console.log('analytics icons loaded!'))
}
