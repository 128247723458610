<template>
  <div class="pt-stoppoint-attributes">
    <div class="pt-stoppoint-attributes__header">
      <r-text>
        Атрибуты остановки
      </r-text>
    </div>
    <div class="pt-stoppoint-attributes__content">
      <div class="pt-stoppoint-attributes__name">
        <r-text color-type="subhead">
          Название
        </r-text>
        <el-input
          v-model="properties.name"
          class="r-input"
          size="mini"
          placeholder="Введите название"
        />
      </div>
      <div class="pt-stoppoint-attributes__adress">
        <r-text color-type="subhead">
          Адрес
        </r-text>
        <el-input
          v-model="properties.address"
          class="r-input"
          size="mini"
          placeholder="Укажите адрес (если есть)"
        />
      </div>
      <div class="pt-stoppoint-attributes__options">
        <r-text color-type="subhead">
          Режим работы
        </r-text>
        <div class="pt-stoppoint-attributes__attribute">
          <el-switch v-model="properties.is_active" />
          <r-text>Остановка работает</r-text>
        </div>
        <div class="pt-stoppoint-attributes__attribute">
          <el-switch v-model="properties.on_demand" />
          <r-text>Только по требованию</r-text>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    properties() {
      return this.$store.state.publicTransport.editorState.properties
    }
  }
}
</script>

<style lang="scss">
.pt-stoppoint-attributes {
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid var(--dividers_low_contrast) !important;

  &__header {
    margin-bottom: 8px;
  }

  &__content {
    display: grid;
    grid-gap: 1rem;
    grid-auto-flow: row;
    & > div {
      display: grid;
      grid-gap: 0.5rem;
      grid-auto-flow: row;
    }
  }

  &__options {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1rem !important;
  }

  &__attribute {
    display: grid;
    grid-gap: 0.5rem;
    grid-auto-flow: column;
    justify-content: start;
  }

  .stop-time {
    position: relative;
    z-index: 2;
    &::after {
      content: 'МИН';
      height: 20px;
      z-index: 20;
      font-size: 12px;
      font-weight: bold;
      line-height: 20;
      position: absolute;
      right: 35px;
      top: -100px;
    }
  }
}
</style>
