import { reportOptions } from '../configs'
import { getTableData } from '../helpers'
import { rDate } from '@/utils'

export class RetranslationStatus {
  constructor(state) {
    this.$store = state.$store
    this.state = state

    this.options = reportOptions.retranslation_status
  }

  getTableData(data) {
    return getTableData(data, this.options)
  }

  getChartData(data) {
    const chartData = {
      data: [],
      fields: {
        data: [],
        title: 'Время фиксации'
      }
    }
    const itemData = []
    let itemTitle = ''
    data.forEach(e => {
      if (!e.distance) return
      itemData.push(e.distance)
      chartData.fields.data.push(rDate.format(e.event_time, 'DD.MM.YYYY HH:mm:ss'))
      itemTitle = e.reg_number
    })
    chartData.data.push({
      title: itemTitle,
      data: itemData
    })
    return chartData
  }
}
