<template>
  <el-row class="ds-manager__component from-file-name">
    <div class="ds-manager__component-title">
      <r-title type="title-2">
        {{ $t('ds-manager:creation:from-file:title') }}
      </r-title>
    </div>
    <el-row
      :class="[
        `ds-manager__component-content`,
        { enlarged },
        { shp: fileType === 'shp' }
      ]"
    >
      <el-row class="ds-manager__component-row">
        <div class="ds-manager__component-subtitle">
          <r-text type="caption">
            {{ $t('ds-manager:creation:from-file:enter-ds-name') }}
          </r-text>
        </div>
        <el-input
          v-model="dsName"
          class="r-input"
          size="mini"
          :placeholder="`${$t(
            'ds-manager:creation:from-file:enter-ds-name-placeholder'
          )}`"
        />
      </el-row>
      <el-row class="ds-manager__component-row">
        <div class="ds-manager__component-subtitle">
          <r-text type="caption">
            {{ $t('ds-manager:creation:from-file:enter-file-name') }}
          </r-text>
        </div>
        <el-input
          v-model="fileName"
          class="r-input"
          size="mini"
          :placeholder="`${$t(
            'ds-manager:creation:from-file:enter-file-name-placeholder'
          )}`"
        />
      </el-row>
      <el-row class="ds-manager__component-row">
        <div class="ds-manager__component-subtitle">
          <r-text type="caption">
            {{ $t('ds-manager:creation:enter-profiles') }}
          </r-text>
        </div>
        <el-select
          v-model="selectedProfiles"
          class="r-select"
          size="mini"
          multiple
          collapse-tags
          :placeholder="`${$t(
            'ds-manager:creation:select-profiles-placeholder'
          )}`"
        >
          <el-option
            v-for="profile in profiles"
            :key="profile.id"
            :value="profile.id"
            :label="profile.name"
          />
        </el-select>
      </el-row>
      <el-row class="ds-manager__component-row">
        <div class="ds-manager__component-subtitle">
          <r-text type="caption">
            {{ $t('ds-manager:creation:from-file:select-type') }}
          </r-text>
        </div>
        <el-select
          v-model="extension"
          class="r-select"
          size="mini"
          :placeholder="`${$t(
            'ds-manager:creation:from-file:select-type-placeholder'
          )}`"
          @change="changeFormat"
        >
          <el-option
            v-for="format in formats"
            :key="format.id"
            :value="format.value"
            :label="format.label"
          />
        </el-select>
      </el-row>
      <el-row
        v-if="enlarged"
        class="ds-manager__component-row"
      >
        <div class="ds-manager__component-subtitle">
          <r-text type="caption">
            {{ $t('ds-manager:creation:from-file:geom-field') }}
          </r-text>
        </div>
        <el-input
          v-model="geomField"
          class="r-input"
          :placeholder="`${$t('ds-manager:creation:from-file:geom-field')}`"
          size="mini"
        />
      </el-row>
      <el-row
        v-if="enlarged"
        class="ds-manager__component-row"
      >
        <div class="ds-manager__component-subtitle">
          <r-text type="caption">
            {{ $t('ds-manager:creation:from-file:geom-type') }}
          </r-text>
        </div>
        <el-select
          v-model="geomType"
          size="mini"
          class="r-select"
          :placeholder="`${$t('ds-manager:creation:from-file:geom-type')}`"
          :no-match-text="`${$t('ds-manager:creation:from-file:no-match')}`"
          clearable
          filterable
        >
          <el-option
            v-for="subitem in geomTypes"
            :key="subitem"
            :label="subitem"
            :value="subitem"
          />
        </el-select>
      </el-row>
      <el-row
        v-if="enlarged || fileType === 'net'"
        class="ds-manager__component-row"
      >
        <div class="ds-manager__component-subtitle">
          <r-text type="caption">
            {{ $t('ds-manager:creation:from-file:srid') }}
          </r-text>
        </div>
        <el-input
          v-model="srid"
          class="r-input"
          :placeholder="`${$t('ds-manager:creation:from-file:srid')}`"
          size="mini"
        />
      </el-row>
      <el-row
        v-if="fileType === 'net'"
        class="ds-manager__component-row"
      >
        <div class="ds-manager__component-subtitle">
          <r-text type="caption">
            {{ $t('ds-manager:creation:from-file:versioning') }}
          </r-text>
        </div>
        <el-checkbox
          v-model="versioning"
          class="r-checkbox"
          :placeholder="`${$t('ds-manager:creation:from-file:versioning')}`"
          size="mini"
        />
      </el-row>
      <el-row class="ds-manager__component-row text-center">
        <r-button
          :loading="fileLoading"
          type="success"
          :disabled="!isActive"
          @click="submitUpload"
        >
          {{ $t('ds-manager:creation:from-file:create') }}
        </r-button>
      </el-row>
    </el-row>
  </el-row>
</template>

<script>
import { notifyFactory, errorParser } from '@/utils'

const formats = [
  { id: 1, label: 'CSV - формат', value: '.csv' },
  { id: 2, label: 'Shape - файл', value: '.7z,.rar,.zip' },
  { id: 3, label: 'NET - файл', value: '.net,.7z,.rar,.zip' },
  { id: 4, label: 'JSON - файл', value: '.json' },
  { id: 5, label: 'GEOJSON - файл', value: '.geojson' },
  { id: 6, label: 'TIFF - файл', value: '.tiff' }
]

export default {
  data() {
    return {
      fileLoading: false,
      selectedProfiles: [],

      extension: '.7z,.rar,.zip',
      fileType: 'shp',
      dsName: '',
      fileName: '',
      geomField: '',
      geomType: '',
      geomTypes: [],
      srid: 4326,
      versioning: false,
      formats
    }
  },
  computed: {
    enlarged() {
      return this.extension === '.csv'
    },
    isActive() {
      if (!this.fileName.trim() || !this.fileType) {
        return false
      }
      if (this.fileType === 'csv') {
        if (!this.geomField.trim() || !this.geomType || !this.srid) {
          return false
        }
      }
      return true
    },
    hasChanges() {
      if (this.hasFile || this.fileName.trim() || this.geomType) return true

      return false
    },
    profiles() {
      return this.$store.state.profiles.profilesTree
    },
    currentProfile() {
      return this.$store.state.profiles.currentProfile
    }
  },
  watch: {
    hasChanges(val) {
      this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', val)
    }
  },
  async created() {
    const { data } = await this.$store.dispatch('GET_REQUEST', {
      url: 'datatypes'
    })

    this.geomTypes = data.geom || []
    this.selectedProfiles = this.profiles
      .filter(e => e.id === this.currentProfile.id)
      .map(e => e.id)
  },
  beforeDestroy() {
    this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', false)
  },
  methods: {
    changeFormat(value) {
      switch (value) {
        case '.csv':
          this.fileType = 'csv'
          break
        case '.7z,.rar,.zip':
          this.fileType = 'shp'
          break
        case '.net,.7z,.rar,.zip':
          this.fileType = 'net'
          break
        case '.json':
          this.fileType = 'json'
          break
        case '.geojson':
          this.fileType = 'geojson'
          break
        case '.tiff':
          this.fileType = 'tiff'
          break
      }
    },
    async submitUpload(e) {
      this.fileLoading = true
      const formData = new FormData()

      formData.append('file_name', this.fileName)
      formData.append('data_source_name', this.dsName)
      formData.append('type', this.fileType)
      formData.append('user_profile_id', this.selectedProfiles.join())

      if (this.fileType === 'csv') {
        formData.append('geom_field', this.geomField)
        formData.append('geom_type', this.geomType)
        formData.append('srid', this.srid)
      }
      if (this.fileType === 'net') {
        formData.append('versioning', this.versioning)
        formData.append('srid', this.srid)
      }

      try {
        const { data } = await this.$store.dispatch('SEND_FORM_DATA', {
          url: 'import_data',
          data: formData
        })
        this.created(data)
        this.fileName = ''
        this.dsName = ''
        this.fileLoading = false
      } catch (e) {
        errorParser.call(this, e)
        this.fileLoading = false
      }
    },
    created(response) {
      const message = `${this.$t(
        'ds-manager:creation:from-file:uploaded-text'
      )} ${response.task_id}`
      const title = this.$t('ds-manager:creation:from-file:uploaded-title')

      this.$notify(notifyFactory('success', title, message))
      this.$store.commit('ADD_ACTIVE_TASK', {
        task_id: response.task_id,
        type: 'import',
        description: `Импорт ИД - ${this.fileName}`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.from-file-name {
  .ds-manager__component-content {
    width: 428px;
    height: 380px;
    transition: all 0.15s ease;
    &.enlarged {
      height: 620px;
    }
  }
  .r-select {
    width: 100%;
  }
}
</style>

<i18n>
{
  "ru": {
    "ds-manager:creation:from-file:title": "Создать источник данных по имени файла",
    "ds-manager:creation:from-file:enter-ds-name": "Название ИД",
    "ds-manager:creation:from-file:enter-ds-name-placeholder": "Введите название ИД",
    "ds-manager:creation:from-file:enter-file-name": "Имя файла",
    "ds-manager:creation:from-file:enter-file-name-placeholder": "Введите имя файла",
    "ds-manager:creation:enter-profiles": "Выберите профили",
    "ds-manager:creation:select-profiles-placeholder": "Выберите профили в которые добавить источник данных",
    "ds-manager:creation:from-file:select-type": "Выберите тип файла",
    "ds-manager:creation:from-file:select-type-placeholder": "Формат исходных данных",
    "ds-manager:creation:from-file:upload": "Загрузка файлов",

    "ds-manager:creation:from-file:geom-field": "Поле с геометрией",
    "ds-manager:creation:from-file:geom-type": "Тип геометрии",
    "ds-manager:creation:from-file:srid": "Проекция",
    "ds-manager:creation:from-file:versioning": "Версионность",

    "ds-manager:creation:from-file:no-match": "Нет совпадений",

    "ds-manager:creation:from-file:upload-tip1": "Перетащите файл сюда или ",
    "ds-manager:creation:from-file:upload-tip2": "кликните для загрузки",
    "ds-manager:creation:from-file:upload-tip3": "Максимальный размер файла — 1024 MB",
    "ds-manager:creation:from-file:upload-tip4": "Некорректный формат",
    "ds-manager:creation:from-file:upload-tip5": "Размер файла превыщает допустимый",
    "ds-manager:creation:from-file:upload-tip-shp": "Для создания источника данных указанного типа, необходимо использовать .zip-архив, содержащий в себе набор файлов следующих форматов - \".dbf\", \".idx\", \".shp\", \".shx\"",
    "ds-manager:creation:from-file:create": "Создать источник данных",

    "ds-manager:creation:from-file:uploaded-title": "Запрос отправлен",
    "ds-manager:creation:from-file:uploaded-text": "Запрос на создание источника данных отправлен. Номер задачи - "
  },
  "en": {
    "ds-manager:creation:from-file:title": "Create data source from file",
    "ds-manager:creation:from-file:enter-ds-name": "Enter name",
    "ds-manager:creation:from-file:enter-ds-name-placeholder": "Name",
    "ds-manager:creation:from-file:enter-file-name": "Enter name",
    "ds-manager:creation:from-file:enter-file-name-placeholder": "Name",
    "ds-manager:creation:enter-profiles": "Select profiles",
    "ds-manager:creation:select-profiles-placeholder": "Select the profiles to add the source to",
    "ds-manager:creation:from-file:select-type": "Select file type",
    "ds-manager:creation:from-file:select-type-placeholder": "Format of data",
    "ds-manager:creation:from-file:upload": "Upload file",

    "ds-manager:creation:from-file:geom-field": "Geometry field",
    "ds-manager:creation:from-file:geom-type": "Geometry type",
    "ds-manager:creation:from-file:srid": "Projection",

    "ds-manager:creation:from-file:no-match": "No match",

    "ds-manager:creation:from-file:upload-tip1": "Drop file here or ",
    "ds-manager:creation:from-file:upload-tip2": "click to upload",
    "ds-manager:creation:from-file:upload-tip3": "Max file size — 1024 MB",
    "ds-manager:creation:from-file:upload-tip4": "Invalid format",
    "ds-manager:creation:from-file:upload-tip5": "File size rxceeds allowed",
    "ds-manager:creation:from-file:upload-tip-shp": "\"To create a data source of the shape type, you must use the .zip archive containing a set of files of the following formats - \".Dbf\", \".Idx\", \".Shp\", \".Shx\"",
    "ds-manager:creation:from-file:create": "Create data source",

    "ds-manager:creation:from-file:uploaded-title": "Request sent",
    "ds-manager:creation:from-file:uploaded-text": "A request to create a data source has been sent. Task number -"
  }
}
</i18n>
