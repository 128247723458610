<template>
  <el-radio-group
    v-model="value"
    class="r-radio-group"
  >
    <el-radio-button
      v-for="(button, i) in buttonList"
      :key="button.id + i"
      :label="button.id"
      :disabled="disabled"
    >
      <r-icon
        v-if="button.icon"
        :name="button.icon"
        :size="14"
      />
      <r-text type="caption">
        {{ button.label }}
      </r-text>
    </el-radio-button>
  </el-radio-group>
</template>

<script>
export default {
  props: {
    buttonList: {
      type: Array,
      default: () => null,
      required: true
    },
    active: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean
    }
  },
  data() {
    return {
      value: this.active
    }
  },
  watch: {
    value(val) {
      this.updateValue(val)
    },
    active(val) {
      this.value = val
    }
  },
  methods: {
    updateValue(val) {
      this.$emit('change', val)
    }
  }
}
</script>

<style lang="scss">
.r-radio-group {
  position: relative;
  width: 100% !important;
  display: flex !important;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: stretch;
  overflow: hidden;
  outline: none !important;

  .el-radio-button {
    flex: 1 !important;
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    border-right: 1px solid var(--dividers_low_contrast) !important;

    &__inner {
      box-shadow: none !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px !important;
      height: 36px;
      border: none !important;
      background-color: var(--bg_containers) !important;
      outline: none !important;

      .r-icon {
        margin-right: 2px;
      }

      &.is-active {
        background-color: var(--accent_selected) !important;
      }
    }
    &:last-child {
      border-right: none !important;
    }

    &.is-active {
      .el-radio-button {
        &__inner {
          background-color: var(--accent_selected) !important;
        }
      }
    }
  }
}
</style>
