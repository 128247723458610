export const parseTrackData = (isEtran, data) => {
  if (isEtran) {
    const trackData = []

    data.track.forEach((e, i) => {
      if (i === 0) {
        trackData.push({
          from_point: e[1],
          from_time: e[0],
          to_point: null,
          to_time: null,
          distance: null,
          avg_speed: null
        })
      } else {
        trackData.push({
          from_point: e[1],
          from_time: e[0],
          to_point: null,
          to_time: null,
          distance: null,
          avg_speed: null
        })

        if (trackData[i - 1]) {
          trackData[i - 1].to_point = e[1]
          trackData[i - 1].to_point = e[0]
        }
      }
    })

    return trackData.reverse()
  } else {
    return data.reverse()
  }
}

export const hasTrackData = (isEtran, data) => {
  if (isEtran) {
    return data?.track?.length > 1
  } else {
    return !!data
  }
}
