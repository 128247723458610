<template>
  <r-simple-card
    title="Информация о расчете"
    :default-closed="true"
  >
    <div class="calculation-info">
      <div class="calculation-info__row">
        <r-button @click="downloadFile(calculation.result.log_archive_input)">
          Скачать Input Log
        </r-button>
      </div>
      <div class="calculation-info__row">
        <r-button @click="downloadFile(calculation.result.log_archive_output)">
          Скачать Output Log
        </r-button>
      </div>
    </div>
  </r-simple-card>
</template>

<script>
import constants from '@/constants/url'
import { downloadFile } from '@/utils'

export default {
  props: {
    calculation: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      url: constants.URL
    }
  },
  methods: {
    downloadFile(item) {
      const file = {
        href: `${this.url}/download_share_resource/${item}`,
        name: 'download'
      }

      downloadFile(file)
    }
  }
}
</script>

<style lang="scss">
.calculation-info {
  &__row {
    &:not(:first-child) {
      margin-top: 16px;
    }

    .r-button {
      width: 100%;
    }
  }
}
</style>
