import vue from 'vue'
import cloneDeep from 'lodash.clonedeep'

const state = {
  isLoading: false,
  activeModule: 'users',

  allUsers: null,
  activeUserId: '',
  updateUsers: false,

  allRoles: null,
  activeRoleId: '',
  updateRoles: false,
  createNewRole: false,

  modulesConfig: null,

  multiEdit: null,

  allPerms: {
    datasources: [],
    modules: []
  },
  rolePerms: {
    datasources: [],
    modules: []
  },
  initialRolePerms: {
    datasources: [],
    modules: []
  },

  roleAttrFilterActiveTab: null,
  roleAttrFilterInitialData: null,
  roleAttrFilterId: null,
  roleAttrFilterFields: null,
  roleAttrFilter: null,
  roleAttrFilterItemsConfig: null,
  roleAttrFilterIncludedItems: null,
  roleAttrFilterExcluded: null,
  roleAttrFilterExcludedItems: null,
  updateAttrFilter: false,

  selectedObjectChanged: false,
  roleAttrFilterHasChanges: false,

  userPerms: {
    datasources: [],
    modules: []
  },
  initialUserPerms: {
    datasources: [],
    modules: []
  }
}

const mutations = {
  ROLE_PERM_SET_IS_LOADING(state, value) {
    vue.set(state, 'isLoading', value)
  },
  ROLE_PERM_SET_FIELD(state, { field, value }) {
    vue.set(state, field, value)
  },
  ROLE_PERM_SET_CREATE_NEW_ROLE(state) {
    vue.set(state, 'createNewRole', true)
    vue.set(state, 'activeRoleId', '')
    vue.set(state, 'rolePerms', {
      datasources: [],
      modules: []
    })
    vue.set(state, 'initialRolePerms', {
      datasources: [],
      modules: []
    })
  },
  ROLE_PERM_SET_ACTIVE_ROLE(state, roleId) {
    vue.set(state, 'activeRoleId', roleId)
    if (state.createNewRole) {
      vue.set(state, 'createNewRole', false)
    }
  },
  ROLE_PERM_SET_ACTIVE_ROLE_DATA(state, { datasources = [], modules = [] }) {
    vue.set(state, 'rolePerms', {
      datasources,
      modules
    })
    vue.set(state, 'initialRolePerms', cloneDeep({
      datasources,
      modules
    }))
  },
  ROLE_PERM_SET_FIELD_AFTER_DELETE(state) {
    vue.set(state, 'createNewRole', false)
    vue.set(state, 'activeRoleId', '')
    vue.set(state, 'updateRoles', true)
    vue.set(state, 'isLoading', false)
  },
  ROLE_PERM_SET_ROLE_DATASOURCES(state, datasources) {
    vue.set(state.rolePerms, 'datasources', datasources)
  },
  ROLE_PERM_SET_USER_DATASOURCES(state, datasources) {
    vue.set(state.userPerms, 'datasources', datasources)
  },
  ROLE_PERM_SET_ACTIVE_USER_DATA(state, { datasources = [], modules = [] }) {
    vue.set(state, 'userPerms', {
      datasources,
      modules
    })
    vue.set(state, 'initialUserPerms', cloneDeep({
      datasources,
      modules
    }))
  }
}

export default {
  state,
  mutations
}
