<template>
  <div class="bdd-application-list-content">
    <r-table-list
      v-if="data.length"
      bordered
      :paginator="data.length > 10"
      :data="tableData"
      :sort-by="grouping ? statusList : null"
      :fields-names="fieldsExplanation"
      :include-columns="columns"
      hide-empty-groups
      num-col
      @click-handler="openApp"
    />
    <div
      v-else
      class="bdd-application-list-content-no-data"
    >
      <r-text> Нет ни одной заявки </r-text>
    </div>
  </div>
</template>

<script>
import { includeColumns, fieldsExplanation } from '../../configs'

export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    grouping: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      fieldsExplanation
    }
  },
  computed: {
    tableData() {
      return this.data.map((d, i) => {
        return { ...d, i: i + 1 }
      })
    },
    columns() {
      return this.grouping
        ? includeColumns.filter(c => c !== 'status_name')
        : includeColumns
    },
    related() {
      return this.$store.state.bdd.applicationsRelated
    },
    statusList() {
      const statusList = this.related.applications_status_list?.data
      return {
        propTitle: 'status_id',
        values: statusList.map((s, i) => {
          return {
            id: s.id,
            order: i + 1,
            value: s.id,
            title: s.name,
            name: s.name
          }
        })
      }
    },
    openAfterUpdate() {
      return this.$store.state.bdd.openAfterUpdate
    },
    activeApplication() {
      return this.data?.find(e => e.id === this.openAfterUpdate) || null
    },
    activeApplicationData() {
      return this.$store.state.bdd.activeApplication
    }
  },
  watch: {
    data: {
      handler: function(val) {
        if (this.openAfterUpdate) {
          this.openApp(this.activeApplication)

          this.$store.commit('BDD_SET_FIELD', {
            field: 'updateApplications',
            value: false
          })
          this.$store.commit('BDD_SET_FIELD', {
            field: 'openAfterUpdate',
            value: null
          })
        }
      },
      deep: true
    },
    activeApplicationData(v) {
      if (v) this.openApp(v)
    }
  },
  methods: {
    openApp(object) {
      this.$store.commit('BDD_SET_FIELD', {
        field: 'activeApplication',
        value: object
      })
      this.$store.commit('BDD_SET_FIELD', {
        field: 'modalName',
        value: 'appMainModal'
      })
      this.$store.commit('OPEN_MODAL_WINDOW', 'bdd-applications-modal')
    }
  }
}
</script>

<style lang="scss">
.bdd-application-list-content {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .r-table-list .cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-no-data {
    margin-top: 100px;
    text-align: center;
  }
}
</style>
