<template>
  <div
    v-loading="isLoading"
    class="an-workspace-tool"
  >
    <r-title type="subtitle-2">
      {{ $t('title') }}
    </r-title>
    <div class="an-workspace-tool-list">
      <div
        v-for="type in typesList"
        :key="type.id"
        class="an-workspace-tool-list__item"
      >
        <el-radio
          v-model="activeType"
          :label="type.value"
        >
          <r-text>{{ $t(type.value) }}</r-text>
        </el-radio>
      </div>
    </div>
  </div>
</template>

<script>
import { reportOptions } from '../../../configs'

export default {
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    report() {
      return this.$store.state.analytics.report
    },
    typesList() {
      const { workspace } = reportOptions[this.report.value] || null

      return workspace
    },
    activeType: {
      get() {
        return this.$store.state.analytics.workspaceType
      },
      set(val) {
        this.$store.commit('ANALYTICS_SET_FIELD', {
          field: 'workspaceType',
          value: val
        })
      }
    }
  },
  watch: {
    activeType(val) {
      this.$store.commit('ANALYTICS_SET_FIELD', {
        field: 'workspaceType',
        value: val
      })
      this.toggleLoading()
    }
  },
  methods: {
    toggleLoading() {
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 500)
    }
  }
}
</script>

<style lang="scss">
.an-workspace-tool {
  display: flex;
  flex-direction: column;
  padding: 8px;
  background-color: var(--modal_bg);
  .r-title {
    margin-bottom: 8px;
  }
  &-list {
    &__item {
      margin-bottom: 4px;
      padding: 4px 0;
      &:last-child {
        margin-bottom: 0;
      }
      .el-radio {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "title": "Выбрать режим отображения",
    "table": "Только таблица",
    "map": "Только карта",
    "table-chart": "Таблица + График",
    "table-map": "Таблица + Карта",
    "table-map-chart": "Таблица + Карта + График"
  },
  "en": {
    "title": "Select workspace type",
    "table": "Table only",
    "map": "Map only",
    "table-chart": "Table + Chart",
    "table-map": "Table + Map",
    "table-map-chart": "Table + Map + Chart"
  }
}
</i18n>
