import { rDate } from '@/utils'
import isPointInPolygon from '@turf/boolean-point-in-polygon'
import mergewith from 'lodash.mergewith'

const baselayerFirstSymbol = {
  'Mapbox Satellite': 'satellite',
  'Mapbox Satellite Streets': 'mapbox-mapbox-satellite'
}

export const getMapParams = (module, defaultCenter = false) => {
  const DEFAULT_CENTER = defaultCenter || [37.617633, 55.755820]
  const zoom = localStorage.getItem(`${module}Zoom`)
    ? JSON.parse(localStorage.getItem(`${module}Zoom`))
    : 10
  let center = localStorage.getItem(`${module}Center`)
    ? JSON.parse(localStorage.getItem(`${module}Center`))
    : DEFAULT_CENTER

  if (center[0] > 180 || center[1] > 180) center = DEFAULT_CENTER

  return { center, zoom }
}

const getBBox = map => {
  const { mapgl } = map
  const bounds = mapgl.getBounds()
  const { _ne, _sw } = bounds

  return [
    [_sw.lng, _ne.lat],
    [_ne.lng, _ne.lat],
    [_ne.lng, _sw.lat],
    [_sw.lng, _sw.lat]
  ]
}

export const getFeaturesInBboxLength = (features = [], map) => {
  const bbox = getBBox(map)
  const bboxFeature = {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [[...bbox, bbox[0]]]
    }
  }
  const featuresInBbox = features.filter(
    f => f.geometry.type === 'Point' && isPointInPolygon(f, bboxFeature)
  )

  return featuresInBbox.length
}

export const getRequestConfig = (map, id, geom_type, geom_field) => {
  const fields = map.requestedFields[id]
  const initial = {
    only: [],
    include: {}
  }
  const customizer = (objValue, srcValue) => {
    if (Array.isArray(objValue)) {
      const result = objValue.concat(srcValue)
      return result.filter((el, i) => result.indexOf(el) === i)
    }
  }

  fields.forEach(f => {
    if (map.objectFieldsConfigs[id] && map.objectFieldsConfigs[id][f]) {
      mergewith(initial, map.objectFieldsConfigs[id][f], customizer)
    } else mergewith(initial, { only: [f] }, customizer)
  })
  const config = {
    limit: geom_type === 'point' ? 30000 : 10000,
    where: [
      {
        field: geom_field || 'geom',
        op: '!null',
        type: 'AND',
        value: ''
      }
    ],
    ...initial
  }

  // bbox settings
  const bboxSettings = {
    field: geom_field || 'geom',
    op: 'bbox',
    type: 'AND',
    value: getBBox(map)
  }

  if (geom_type && geom_type !== 'point') {
    config.where.push(bboxSettings)
  }

  // local filters
  const layerFilters = map.styleConfig[id].filters
  if (layerFilters.length) {
    layerFilters
      .filter(f => f.field && !!f.enabled)
      .forEach(f => {
        const field =
          map.objectFields[id].find(field => field.title === f.field) || {}
        const { type } = field

        if (type === 'string' || type === 'boolean') {
          config.where.push({
            field: f.field,
            op: f.operation,
            type: 'AND',
            value: f.values
          })
        } else {
          let [from, to] = f.range
          if (type === 'datetime') {
            from = rDate.format(from, 'iso')
            to = rDate.format(to, 'iso')
          }
          config.where.push({
            field: f.field,
            op: 'between',
            type: 'AND',
            value: `${from}/${to}`
          })
        }
      })
  }

  // sorting
  const { order } = map.styleConfig[id]
  if (order.field) {
    config.order = [{ [order.field]: order.type }]
  }

  return config
}

const onlyConfig = { only: ['id', 'geom', 'no'] }
const getIncludeConfig = geom_type => {
  switch (geom_type) {
    case 'nodes':
      return {
        include: {
          from_links: onlyConfig,
          to_links: onlyConfig,
          connectors: { only: ['id', 'geom'] },
          stop_points: onlyConfig
        }
      }
    case 'links':
      return {
        include: {
          stop_point: onlyConfig
        }
      }
    case 'zones':
      return {
        include: {
          connectors: { only: ['id', 'geom'] }
        }
      }
    case 'stop_points':
      return {
        only: ['id', { field: 'stop_geom', func: 'st_as_geojson' }, 'geom']
      }
    case 'line_routes':
      return {
        only: ['id', 'geom', 'name'],
        include: {
          line_route_items: {
            only: ['node_id', 'stop_point_id', 'index'],
            include: {
              node: {
                only: ['id', 'no', 'geom']
              },
              stop_point: {
                only: ['id', 'no', 'geom']
              }
            },
            where: [
              {
                field: 'node_id',
                op: '!null',
                type: 'OR',
                value: ''
              },
              {
                field: 'stop_point_id',
                op: '!null',
                type: 'OR',
                value: ''
              }
            ]
          }
        }
      }
    case 'lines':
      return {
        only: ['id', 'geom', 'name'],
        include: {
          line_routes: {
            include: {
              line_route_items: {
                only: ['node_id', 'stop_point_id', 'index'],
                include: {
                  node: {
                    only: ['id', 'no', 'geom']
                  },
                  stop_point: {
                    only: ['id', 'no', 'geom']
                  }
                },
                where: [
                  {
                    field: 'node_id',
                    op: '!null',
                    type: 'OR',
                    value: ''
                  },
                  {
                    field: 'stop_point_id',
                    op: '!null',
                    type: 'OR',
                    value: ''
                  }
                ]
              }
            }
          }
        }
      }
    case 'events':
      return {
        only: undefined
      }
  }
}

export const getEditorRequestConfig = (map, geom_type) => {
  return {
    limit: map.getObjectsLimit(geom_type),
    only: ['id', 'geom'],
    where: [
      {
        field: 'geom',
        op: 'bbox',
        type: 'AND',
        value: getBBox(map)
      },
      {
        field: 'geom',
        op: '!null',
        type: 'AND',
        value: ''
      }
    ],
    ...getIncludeConfig(geom_type)
  }
}

export const flyToOptions = {
  speed: 10,
  curve: 1,
  easing(t) {
    return t
  }
}

export const getModellingRequestConfig = (component, id, datatype) => {
  const { $store } = component
  const { requestedFields, objectFields } = $store.state.modelling
  const fields = objectFields[id]

  let only
  let include = {}

  if (datatype === 'events') {
    only = [
      'id',
      'geom',
      'start_time',
      'end_time',
      'closed_line_count',
      'event_class_id'
    ]
    include = {
      event_class: {
        only: ['id', 'name']
      }
    }
  } else {
    only = requestedFields[id].map(name => {
      const fieldConfig = fields.find(field => field.title === name)

      if (fieldConfig) {
        const { calc_field } = fieldConfig

        return !calc_field
          ? name
          : {
            field: `link_calculation_results.${name}`,
            agg_op: 'avg',
            alias: name
          }
      }

      return name
    })
  }

  const config = {
    limit: 10000,
    where: [
      {
        field: 'geom',
        op: '!null',
        type: 'AND',
        value: ''
      }
    ],
    include,
    only
  }

  return config
}

export const getFirstSymbolId = (mapgl) => {
  const { name, layers } = mapgl.getStyle()
  const except = ['Mapbox Satellite', 'Mapbox Satellite Streets']

  if (except.includes(name)) {
    const pos = layers.findIndex(e => e.id === baselayerFirstSymbol[name]) + 1
    const isLast = pos === layers.length
    const layerId = layers.splice(pos)?.find(l => l.type !== 'raster')?.id || null

    return isLast ? null : layerId
  } else {
    const symbolIndex = layers.findIndex(
      e =>
        e.type === 'symbol' &&
        // ignore specific under road layers
        e.id.indexOf('tunnel-') === -1 &&
        e.id.indexOf('turning-') === -1
    )

    if (symbolIndex !== -1) return layers[symbolIndex].id

    return getLastId(layers)
  }
}

export const getFirstId = (mapgl, types) => {
  const { layers } = mapgl.getStyle()
  const ids = []

  types.forEach(t => {
    const index = layers.findIndex(e => e.type === t)

    if (index > -1) {
      ids.push(index)
    }
  })

  if (ids?.length) {
    const minIndex = Math.min(...ids)

    return layers[minIndex].id
  }

  return getLastId(layers)
}

const getLastId = (layers) => {
  return layers[layers.length - 1].id
}
