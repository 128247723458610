<template>
  <div class="rp-users-datasources-list">
    <r-search-input
      :filter-text="filterText"
      placeholder="Поиск по названию"
      @change="e => (filterText = e)"
    />
    <ul
      v-if="filteredList && filteredList.length > 0"
      class="rp-users-datasources-list-wrapper"
    >
      <li
        v-for="item in filteredList"
        :key="item.id"
        :class="[
          'rp-users-datasources-list__item',
          { active: isItemActive(item) }
        ]"
        @click="selectItem(item)"
      >
        <r-icon
          :name="getIconName(item)"
          :size="24"
        />
        <r-text>{{ item.name }}</r-text>
      </li>
    </ul>
    <div
      v-else
      class="rp-users-datasources-list__no-data"
    >
      <r-text>Нет данных</r-text>
    </div>
  </div>
</template>

<script>
import { crudsTypes } from './configs'
import { hasCrudsChanges } from './helpers'

export default {
  props: {
    list: {
      type: Array,
      required: true
    },
    selectedItems: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      filterText: '',
      crudsTypes
    }
  },
  computed: {
    filteredList() {
      if (!this.filterText?.trim()) return this.list || []

      return (
        this.list?.filter(e => {
          return e.name
            ?.trim()
            ?.toLowerCase()
            ?.includes(this.filterText?.trim()?.toLowerCase())
        }) || []
      )
    },
    initialUserDatasources() {
      return (
        this.$store.state.rolePermission.initialUserPerms?.datasources || []
      )
    }
  },
  methods: {
    getIconName(item) {
      if (item.datatype === 'model') return 'model-layer'

      switch (item.geom_type) {
        case 'line_string':
        case 'multi-line-string':
          return 'line-layer'
        case 'polygon':
        case 'multi_polygon':
          return 'polygon-layer'
        case 'point':
        case 'multi_point':
          return 'node-layer'
        default:
          return 'folder-layer'
      }
    },
    selectItem(item) {
      if (!this.selectedItems) return

      const existIndex = this.selectedItems.findIndex(e => e.id === item.id)

      if (existIndex > -1) {
        this.selectedItems.splice(existIndex, 1)
      } else {
        const hasChanges = hasCrudsChanges(this.initialUserDatasources, item)
        const hasInitial = this.initialUserDatasources.find(
          e => e.id === item.id
        )

        if (hasInitial) {
          if (hasChanges) {
            item._action = 'updated'
          } else {
            item._action = undefined
          }
        } else {
          item._action = 'created'
        }
        this.selectedItems.push(item)
      }
    },
    isItemActive(item) {
      return !!this.selectedItems?.find(e => e.id === item.id)
    }
  }
}
</script>

<style lang="scss">
.rp-users-datasources-list {
  padding: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0px;
  overflow: hidden;

  &-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 0;
    overflow: auto;
    margin-top: 8px;
  }

  &__item {
    padding: 8px;
    border-radius: var(--border-radius);
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid transparent;
    cursor: pointer;

    .r-icon {
      margin-right: 4px;
    }

    :last-child {
      margin-bottom: 0;
    }

    &:hover {
      border: 1px solid var(--accent_hover);
    }

    &.active {
      background-color: var(--accent_selected);
    }
  }

  &__no-data {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 80px;
  }
}
</style>
