<template>
  <div
    id="journal-map"
    class="journal-map"
    :style="`height: ${height}px`"
  >
    <r-map-tools
      v-if="mapgl"
      :tools="['baselayers', 'screenshot']"
      :baselayer-id="baselayerId"
      :map="mapgl"
      @toggle-base-layer="toggleBaselayer"
    />
  </div>
</template>

<script>
import extent from 'turf-extent'
import { initMap } from './core/'
import { layerConfig } from './configs'

export default {
  props: {
    height: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      mapgl: {}
    }
  },
  computed: {
    geom() {
      return this.$store.state.journal.flyToGeom
    },
    baselayerId() {
      return this.$store.state.mapConfigs?.baselayers?.journal || 5
    }
  },
  watch: {
    geom(geom) {
      if (!geom) return
      this.flyToEvent()
    }
  },
  async mounted() {
    await initMap(this)

    if (this.geom) {
      this.mapgl.on('load', () => this.flyToEvent())
    }
  },
  methods: {
    toggleBaselayer({ id }) {
      this.$store.commit('MAP_CONFIGS_SET_CONFIG', {
        field: 'baselayers',
        module: 'journal',
        value: id
      })

      initMap(this)
    },
    flyToEvent() {
      const bounds = extent(this.geom)

      this.mapgl.fitBounds(bounds, {
        padding: 20,
        maxZoom: 16
      })

      this.displayPoint()

      this.$store.commit('SET_JOURNAL_FIELD', ['flyToGeom', null])
    },
    displayPoint() {
      const layerId = 'journal-events'
      const source = this.mapgl.getSource(layerId)
      const layer = this.mapgl.getLayer(layerId)

      if (!this.geom) {
        if (source) {
          if (layer) this.mapgl.removeLayer(layerId)
          this.mapgl.removeSource(layerId)
        }

        return
      }

      const geojson = {
        type: 'FeatureCollection',
        features: [{
          type: 'Feature',
          geometry: this.geom
        }]
      }

      if (source) {
        source.setData(geojson)
      } else {
        this.mapgl.addSource(layerId, {
          type: 'geojson',
          data: geojson
        })
        this.mapgl.addLayer({
          id: layerId,
          source: layerId,
          ...layerConfig.points
        })
      }
    }
  }
}
</script>

<style lang="scss">
.journal-map {
  flex: 1;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid var(--dividers_low_contrast);

  .mapboxgl-canvas {
    &:focus {
      outline: none;
    }
  }
}
</style>
