export const potItemModel = [
  // - - - Railway - - - //
  {
    model: 'carriage_no',
    title: 'Номер вагона',
    block: 'railway',
    type: 'input'
  },
  {
    model: 'waybill_no',
    title: 'Номер накладной',
    block: 'railway',
    type: 'input'
  },
  {
    model: 'destination_station',
    title: 'Станция назначения',
    block: 'railway',
    type: 'input'
  },
  {
    model: 'current_station',
    title: 'Текущая станция',
    block: 'railway',
    type: 'input'
  },
  {
    model: 'arrival_forecast',
    title: 'Прогноз прибытия',
    block: 'railway',
    type: 'date',
    format: 'dd.MM.yyyy'
  },
  // - - - Vehicle - - - //
  {
    model: 'driver_full_name',
    title: 'ФИО водителя',
    block: 'vehicles',
    type: 'input'
  },
  {
    model: 'model_id',
    block: 'vehicles',
    title: 'Марка, модель транспортного средства',
    type: 'select',
    filterable: true,
    select: 'vehicle_models'
  },
  {
    model: 'vehicle_type_id',
    block: 'vehicles',
    filterable: true,
    title: 'Вид ТС',
    type: 'select',
    select: 'vehicle_types'
  },
  {
    model: 'reg_number',
    block: 'vehicles',
    width: 'half',
    title: 'Номер ТС',
    read_only: true,
    type: 'input'
  },
  {
    model: 'trailer_number',
    block: 'vehicles',
    width: 'half',
    title: 'Номер прицепа/полуприцепа',
    type: 'input'
  },
  {
    model: 'driver_phone',
    block: 'vehicles',
    title: 'Телефон водителя',
    type: 'input'
  },
  {
    model: 'main_contractor_id',
    title: 'Наименование подрядной / субподрядной организации',
    type: 'select',
    block: 'vehicles',
    select: 'main_contractor_id',
    filterable: true
  },
  {
    model: 'retranslation_status',
    block: 'vehicles',
    title: 'Статус ретрансляции',
    read_only: true,
    type: 'input',
    handler: status => {
      switch (status) {
        case 'online':
          return 'на связи'
        case 'without_coords_halfline':
        case 'with_coords_halfline':
        case 'with_coords_offline':
          return 'нет связи, отметка более 5 мин назад'
        case 'without_coords_offline':
          return 'на связи, нет координат более 30 мин'
        default:
          return 'нет данных'
      }
    }
  },
  // - - - End of Vehicle - - - //
  // - - - Barges - - - //
  {
    model: 'name',
    title: 'Наименование',
    block: 'barges',
    type: 'input'
  },
  {
    model: 'number',
    title: 'Номер',
    block: 'barges',
    type: 'input'
  },
  // - - - End of Barges - - - //
  {
    model: 'pot_status_id',
    title: 'Статус',
    type: 'select',
    select: 'applications_status_list'
  },
  // - - - Contacts - - - //
  {
    model: 'sender_id',
    type: 'select',
    title: 'Поставщик',
    select: 'main_contractor_id',
    filterable: true,
    block: 'contacts',
    width: 'half'
  },
  {
    model: 'receiving_party',
    title: 'Принимающая сторона',
    block: 'contacts',
    width: 'half',
    type: 'input'
  },
  {
    model: 'forwarder_id',
    type: 'select',
    title: 'Экспедитор',
    select: 'main_contractor_id',
    filterable: true,
    block: 'contacts',
    width: 'half'
  },
  {
    model: 'expediter',
    title: 'Экспедайтер',
    block: 'contacts',
    width: 'half',
    type: 'input'
  },
  {
    model: 'logistician',
    title: 'Логист',
    block: 'contacts',
    width: 'half',
    type: 'input'
  },
  {
    model: 'mols',
    type: 'select',
    title: 'Контактные лица (МОЛы)',
    select: 'mols',
    multiple: true,
    label: 'fio',
    filterable: true,
    block: 'contacts',
    width: 'half'
  },
  // - - - End of Contacts - - - //
  // - - - Cargoes - - - //
  {
    model: 'packing_list_no',
    title: '№ Упаковочного листа',
    block: 'cargo',
    width: 'half',
    type: 'input'
  },
  {
    model: 'project',
    title: 'Проект',
    block: 'cargo',
    width: 'half',
    type: 'input'
  },
  {
    model: 'subproject_id',
    title: 'Подпроект',
    block: 'cargo',
    width: 'half',
    type: 'select',
    select: 'subprojects'
  },
  {
    model: 'subsubproject_id',
    title: 'Подподпроект',
    block: 'cargo',
    width: 'half',
    type: 'select',
    select: 'subsubprojects'
  },
  {
    model: 'external',
    title: 'Внешняя поставка',
    block: 'cargo',
    width: 'half',
    type: 'boolean'
  },
  {
    model: 'name',
    title: 'Вид номенклатуры',
    block: 'cargo',
    width: 'half',
    type: 'input'
  },
  {
    model: 'nomenclature_group',
    title: 'Группа номенклатуры',
    block: 'cargo',
    width: 'half',
    type: 'input'
  },
  {
    model: 'transport_doc_no',
    title: '№ транспортного документа',
    block: 'cargo',
    width: 'half',
    type: 'input'
  },
  {
    model: 'shipment_place',
    title: 'Место отгрузки',
    block: 'cargo',
    width: 'half',
    type: 'input'
  },
  {
    model: 'terms_of_delivery',
    title: 'Условия поставки',
    block: 'cargo',
    width: 'half',
    type: 'input'
  },
  {
    model: 'invoice_no',
    title: '№ инвойса',
    block: 'cargo',
    width: 'half',
    type: 'input'
  },
  {
    model: 'carriage_no',
    title: '№ вагона',
    block: 'cargo',
    width: 'half',
    type: 'input'
  },
  {
    model: 'container_no',
    title: '№ контейнера',
    block: 'cargo',
    width: 'half',
    type: 'input'
  },
  {
    model: 'tth',
    title: 'Номер ТТН',
    block: 'cargo',
    width: 'half',
    type: 'input'
  },
  {
    model: 'tn_date',
    title: 'Дата ТТН/УЛ',
    block: 'cargo',
    width: 'half',
    type: 'date',
    format: 'dd.MM.yyyy'
  },
  {
    model: 'sending_date',
    title: 'Дата Отгрузки, План',
    block: 'cargo',
    width: 'half',
    type: 'date',
    format: 'dd.MM.yyyy HH:mm'
  },
  {
    model: 'sending_date_fact',
    title: 'Дата отгрузки, Факт',
    block: 'cargo',
    width: 'half',
    type: 'date',
    format: 'dd.MM.yyyy HH:mm'
  },
  {
    model: 'est_delivery_date',
    title: 'Плановый срок доставки (Систем.)',
    block: 'cargo',
    width: 'half',
    type: 'date',
    format: 'dd.MM.yyyy HH:mm',
    read_only: true
  },
  {
    model: 'receiving_date_fact',
    title: 'Дата доставки, Факт',
    block: 'cargo',
    width: 'half',
    type: 'date',
    format: 'dd.MM.yyyy HH:mm'
  },
  {
    model: 'receiving_date',
    title: 'Плановый срок доставки (1сСОУ)',
    block: 'cargo',
    width: 'half',
    type: 'date',
    format: 'dd.MM.yyyy HH:mm'
  },
  {
    model: 'user_delivery_date',
    title: 'Плановый срок доставки (Польз.)',
    block: 'cargo',
    width: 'half',
    type: 'date',
    format: 'dd.MM.yyyy HH:mm'
  },
  {
    model: 'shipping_type',
    title: 'Тип перевозки',
    block: 'cargo',
    read_only: true,
    width: 'half',
    type: 'input'
  },
  {
    model: 'rfq_number',
    title: '№ RFQ',
    block: 'cargo',
    width: 'half',
    type: 'input'
  },
  {
    model: 'scn',
    title: 'SCN номер',
    block: 'cargo',
    width: 'half',
    type: 'input'
  },
  {
    model: 'title_number',
    title: 'Номер титула',
    block: 'cargo',
    width: 'half',
    type: 'input'
  },
  {
    model: 'zp_number',
    title: 'ЗП номер',
    block: 'cargo',
    width: 'half',
    type: 'input'
  },
  {
    model: 'places_num',
    title: 'Кол-во грузовых мест',
    block: 'cargo',
    type: 'number',
    width: 'full'
  },
  {
    model: 'weight_gross',
    title: 'Вес брутто, кг',
    width: 'third',
    block: 'cargo',
    type: 'number'
  },
  {
    model: 'weight_net',
    title: 'Вес нетто, кг',
    width: 'third',
    block: 'cargo',
    type: 'number'
  },
  {
    model: 'volume',
    title: 'Объём, м3',
    width: 'third',
    block: 'cargo',
    type: 'number'
  },
  {
    model: 'comment',
    title: 'Комментарий',
    block: 'cargo',
    width: 'full',
    type: 'input'
  }
  // - - - End of Cargoes - - - //
]
export const potConfig = {
  include: {}
}

export const cargoSource = () => ({
  carriage_no: null,
  comment: null,
  contact_persons: null,
  container_no: null,
  est_delivery_date: null,
  expediter: null,
  external: false,
  forwarder: null,
  invoice_no: null,
  logistician: null,
  name: null,
  nomenclature_group: null,
  packing_list_no: null,
  places_num: null,
  project: null,
  receiving_date: null,
  receiving_date_fact: null,
  receiving_party: null,
  rfq_number: null,
  scn: null,
  sender_id: null,
  sending_date: null,
  sending_date_fact: null,
  shipment_place: null,
  shipping_type: null,
  subproject_id: null,
  terms_of_delivery: null,
  title_number: null,
  tn_date: null,
  transport_doc_no: null,
  tth: null,
  user_delivery_date: null,
  volume: null,
  weight_gross: null,
  weight_net: null,
  zp_number: null
})
