export const filterTypes = {
  date: [
    {
      value: '>',
      label: 'Больше'
    },
    {
      value: '=',
      label: 'Равно'
    },
    {
      value: '<',
      label: 'Меньше'
    },
    {
      value: '!null',
      label: 'Заполнено'
    },
    {
      value: 'null',
      label: 'Не заполнено '
    },
    {
      value: 'between',
      label: 'В промежутке'
    }
  ],
  numeric: [
    {
      value: '>',
      label: 'Больше'
    },
    {
      value: '=',
      label: 'Равно'
    },
    {
      value: '<',
      label: 'Меньше'
    },
    {
      value: '!=',
      label: 'Не равно'
    },
    {
      value: '!null',
      label: 'Заполнено'
    },
    {
      value: 'null',
      label: 'Не заполнено'
    },
    {
      value: 'between',
      label: 'В промежутке'
    },
    {
      value: 'in',
      label: 'Один из'
    }
  ],
  string: [
    {
      value: '=',
      label: 'Равно'
    },
    {
      value: '!=',
      label: 'Не равно'
    },
    {
      value: '!null',
      label: 'Заполнено'
    },
    {
      value: 'null',
      label: 'Не заполнено'
    },
    {
      value: 'like',
      label: 'Содержит'
    },
    {
      value: 'in',
      label: 'Один из'
    }
  ],
  boolean: [
    {
      value: '=',
      label: 'Равно'
    },
    {
      value: '!=',
      label: 'Не равно'
    },
    {
      value: '!null',
      label: 'Заполнено'
    },
    {
      value: 'null',
      label: 'Не заполнено'
    }
  ]
}

export const getFilterOpName = function(val) {
  switch (val) {
    case 'in':
      return 'вхождение'
    case 'like':
      return 'содержит'
    case '!null':
      return 'исключить пустые'
    case 'null':
      return 'включить пустые'
    case 'between':
      return 'между'
    default:
      return val
  }
}
