<template>
  <div class="rp-roles">
    <rp-roles-wellcome v-if="!activeRoleId && !createNewRole" />
    <rp-roles-content v-else />
  </div>
</template>

<script>
import rpRolesWellcome from './rp-roles-wellcome'
import rpRolesContent from './rp-roles-content'

export default {
  components: {
    rpRolesWellcome,
    rpRolesContent
  },
  computed: {
    activeRoleId() {
      return this.$store.state.rolePermission.activeRoleId || null
    },
    createNewRole() {
      return this.$store.state.rolePermission.createNewRole || false
    }
  }
}
</script>

<style lang="scss">
.rp-roles {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
