<template>
  <div class="ee-card-attribute input-number">
    <r-text
      type="caption"
      style="margin-bottom: 8px;"
    >
      {{ config.label }}
    </r-text>
    <el-input-number
      v-model="feature[config.prop]"
      :min="0"
      class="r-input"
      size="mini"
      :step="0.1"
    />
  </div>
</template>

<script>
export default {
  props: {
    feature: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      required: true
    }
  }
}
</script>

<style></style>
