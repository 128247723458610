<template>
  <div
    id="register-map"
    class="register-map"
    :style="`height: ${height}px`"
  >
    <r-map-tools
      v-if="mapgl"
      :tools="['baselayers', 'screenshot']"
      :baselayer-id="baselayerId"
      :map="mapgl"
      @toggle-base-layer="toggleBaselayer"
    />
  </div>
</template>

<script>
import { initMap } from './core/'
import extent from 'turf-extent'
import { jsonToGeojson } from '@/utils'
import { layerConfig } from './configs'

export default {
  props: {
    height: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      mapgl: {}
    }
  },
  computed: {
    geom() {
      return this.$store.state.bdd.rrflyToGeom
    },
    restriction() {
      return this.$store.state.bdd.restriction
    },
    baselayerId() {
      return this.$store.state.mapConfigs?.baselayers?.bdd || 5
    }
  },
  watch: {
    geom(geom) {
      if (!geom) return
      this.handleRestriction()
    }
  },
  async mounted() {
    await initMap(this)
    if (this.geom) {
      this.mapgl.on('load', () => this.handleRestriction())
    }
  },
  methods: {
    toggleBaselayer({ id }) {
      this.$store.commit('MAP_CONFIGS_SET_CONFIG', {
        field: 'baselayers',
        module: 'bdd',
        value: id
      })

      initMap(this)
    },
    handleRestriction() {
      const bounds = extent(this.geom)

      this.mapgl.fitBounds(bounds, {
        padding: 20,
        maxZoom: 16
      })

      this.displayPoint(this.restriction)

      this.$store.commit('BDD_SET_FIELD', {
        field: 'rrflyToGeom',
        value: null
      })
    },
    displayPoint(data) {
      const layerId = 'restriction'
      const source = this.mapgl.getSource(layerId)
      const layer = this.mapgl.getLayer(layerId)

      if (!data) {
        if (source) {
          if (layer) this.mapgl.removeLayer(layerId)
          this.mapgl.removeSource(layerId)
        }
        return
      }

      const geojson = jsonToGeojson([data])

      if (source) {
        source.setData(geojson)
        this.mapgl.removeLayer(layerId)
        this.mapgl.removeLayer(`${layerId}_line_icons`)
        this.mapgl.removeLayer(`${layerId}_point_icons`)
      } else {
        this.mapgl.addSource(layerId, {
          type: 'geojson',
          data: geojson
        })
      }

      this.mapgl.addLayer({
        id: layerId,
        source: layerId,
        filter: ['==', '$type', 'LineString'],
        ...layerConfig.events_lines
      })
      this.mapgl.addLayer({
        id: `${layerId}_line_icons`,
        source: layerId,
        filter: ['==', '$type', 'LineString'],
        ...layerConfig.events_lines_icons
      })
      this.mapgl.addLayer({
        id: `${layerId}_point_icons`,
        source: layerId,
        filter: ['==', '$type', 'Point'],
        ...layerConfig.events_icons
      })
    }
  }
}
</script>

<style lang="scss">
.register-map {
  flex: 1;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid var(--dividers_low_contrast);

  .mapboxgl-canvas {
    &:focus {
      outline: none;
    }
  }
}
</style>
