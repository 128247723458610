<template>
  <div class="m-event-list">
    <r-simple-list
      v-if="events.length"
      :list="events"
      @click-handler="clickHandler"
    />
    <div
      v-else
      class="m-event-list__nodata"
    >
      <r-text type="caption">
        Нет ни одного события. Создайте первое на карте, чтобы настроить
        сценарий и запустить расчёт
      </r-text>
    </div>
  </div>
</template>

<script>
import { Constants } from '@/libs/map-draw/lib-utils'

export default {
  data() {
    return {
      draw: this.$cControllers.editor.parent.draw,
      events: []
    }
  },
  watch: {
    '$store.state.modelling.editorState.updateEvents'() {
      this.updateEvents()
    }
  },
  methods: {
    updateEvents() {
      const { features } = this.draw.getAll()

      this.events = features
        .filter(f => f.geometry.coordinates.length)
        .map(f => ({
          ...f,
          title: 'Событие ДТП',
          icon: 'car-accident'
        }))
    },
    clickHandler(item) {
      const { editorState } = this.$store.state.modelling
      const { id } = item

      if (editorState.mode === 'create') {
        this.$cControllers.editor.toggleEditorMode('edit')
      }

      this.$cControllers.drawContext.setSelected(id)
      editorState.activeObject = this.$cControllers.drawContext.getFeature(id)
      editorState.editingCallback = point => {
        this.$cControllers.editor.mapgl.fire(Constants.events.UPDATE, {
          action: 'change_properties',
          features: [point.toGeoJSON()]
        })
        this.$cControllers.drawContext.clearSelectedFeatures()
      }
      this.$cControllers.drawContext.doRender(id)

      this.$store.commit('SET_CALC_FIELD', {
        field: 'activeEventId',
        value: id
      })
    }
  }
}
</script>

<style lang="scss">
.m-event-list {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__nodata {
    text-align: center;
    padding: 32px 8px;
  }
}
</style>
