import { reportOptions } from '../configs'
import { getTableData } from '../helpers'

export class MovementsStat {
  constructor(state) {
    this.$store = state.$store
    this.state = state
    this.mapgl = state.mapgl

    this.options = reportOptions.movements_stat
  }

  getTableData(data) {
    return getTableData(data, this.options)
  }
}
