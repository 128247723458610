<template>
  <odd-router-view />
</template>

<script>
import oddRouterView from '@/components/odd/panels/router/view'

export default {
  components: { oddRouterView }
}
</script>
