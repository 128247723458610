<template>
  <el-dialog
    v-loading="loading"
    title="Создать новое транспортное средство"
    :visible.sync="visible"
    width="400px"
    append-to-body
    :close-on-click-modal="false"
    :before-close="close"
    class="r-modal-window vehicle-modal-create"
  >
    <div class="r-modal-card__controls">
      <r-button
        type="success"
        :disabled="saveDisabled"
        @click="create"
      >
        Сохранить ТС
      </r-button>
      <r-button
        simple
        @click="close"
      >
        Отмена
      </r-button>
    </div>
    <attributes-list
      v-if="source"
      :model="model"
      :related="related"
      :source="source"
      is-editing
    />
  </el-dialog>
</template>

<script>
import { model, related } from '../config/vehicle'
import attributesList from '@/components/globals/r-modal-elements/attributes-list'
import { notifyFactory } from '@/utils'

export default {
  components: {
    attributesList
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      model,
      related,
      source: null,
      loading: false
    }
  },
  computed: {
    saveDisabled() {
      return this.source && !this.source?.reg_number
    }
  },
  async created() {
    await this.loadRelated()

    this.source = model.reduce((a, c) => {
      a[c.model] = null
      return a
    }, {})
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async loadRelated() {
      this.loading = true

      for (const r in this.related) {
        const urlName = this.related[r].urlName
        const source_id = this.$store.state.services[urlName]
        if (!source_id) return

        try {
          const config = JSON.stringify(this.related[r].config)
          const url = `objectInfo/${source_id}?config=${config}`

          const { data } = await this.$store.dispatch('GET_REQUEST', {
            url
          })

          this.related[r].data = Object.values(data)
        } catch (e) {
          console.error(e)
        }
      }
      this.loading = false
    },
    async create() {
      this.loading = true

      try {
        const data = this.source
        await this.$store.dispatch('PUT_REQUEST', {
          url: `objectInfo/${this.$store.state.services.vehicles}`,
          data
        })
        this.$emit('updateRelated')
        this.$notify(notifyFactory('succcess', 'ТС успешно создано'))
      } catch (e) {
        console.error(e)
        this.$notify(notifyFactory('error', 'Произошла ошибка'))
      } finally {
        this.loading = false
        this.close()
      }
    }
  }
}
</script>

<style lang="scss">
.vehicle-modal-create {
  .el-dialog__body {
    display: grid;
    grid-gap: 1rem;
  }
}
</style>
