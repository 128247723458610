<template>
  <div class="email-distributions">
    <r-title>{{ title }}</r-title>
    <component
      :is="`email-distributions-${mode}`"
      @mode="changeMode"
    />
  </div>
</template>

<script>
import emailDistributionsView from './email-distributions-view'
import emailDistributionsCreate from './email-distributions-create'

export default {
  components: {
    emailDistributionsView,
    emailDistributionsCreate
  },
  data() {
    return {
      mode: 'view',
      initHeight: 'calc(90% - 50px)'
    }
  },
  computed: {
    title() {
      switch (this.mode) {
        case 'create':
          return 'Создать Email-уведомление'
        default:
          return 'Email-уведомления и рассылка'
      }
    }
  },
  watch: {
    mode(mode) {
      this.setHeight(mode)
    }
  },
  mounted() {
    this.setHeight('view')
  },
  methods: {
    setHeight(mode) {
      const height = mode === 'view' ? this.initHeight : 'auto'
      document.querySelector('[aria-label="dialog"]').style.height = height
    },
    changeMode(mode) {
      this.mode = mode
      this.setHeight(mode)
    }
  }
}
</script>

<style lang="scss">
.email-distributions {
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: row;
  align-items: start;
  align-content: start;
  height: 100%;
  overflow: hidden;
}
</style>
