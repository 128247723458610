<template>
  <div
    v-loading="isLoading"
    class="role-permission"
  >
    <r-main-panel>
      <rp-sidebar />
    </r-main-panel>
    <rp-content />
  </div>
</template>

<script>
import rpContent from './components/rp-content/rp-content.vue'
import rpSidebar from './components/rp-sidebar/rp-sidebar'
import { modulesConfig } from './configs'
import { parseModules } from './helpers'

export default {
  components: {
    rpSidebar,
    rpContent
  },
  data() {
    return {
      modulesConfig
    }
  },
  computed: {
    isLoading() {
      return this.$store.state.rolePermission.isLoading
    }
  },
  created() {
    this.loadAllPerms()
  },
  methods: {
    async loadAllPerms() {
      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: 'permission_object_tree?'
        })

        this.$store.commit('ROLE_PERM_SET_FIELD', {
          field: 'allPerms',
          value: data
        })

        const parsedModulesConfig = parseModules(this.modulesConfig, data?.modules)

        this.$store.commit('ROLE_PERM_SET_FIELD', {
          field: 'modulesConfig',
          value: parsedModulesConfig
        })
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>

<style lang="scss">
.role-permission {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
