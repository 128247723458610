<template>
  <div class="event-editor">
    <select-modal
      v-if="mapgl && view === 'model-list'"
      :map="mapgl"
      :base-controller="BaseController"
      :editor-controller="EditorController"
    />
    <div class="event-editor-layout">
      <r-main-panel
        v-if="view === 'editor'"
        title="Редактор событий"
        :subheader="model.name"
        icon="edit"
      >
        <editor
          :map="mapgl"
          :state="editorState"
          :controller="EditorController"
        />
      </r-main-panel>
      <div
        id="event-editor-map"
        class="event-editor-map"
      >
        <div
          v-if="overlay"
          class="event-editor-overlay"
        />
        <div
          v-if="view === 'editor'"
          class="event-editor-controls"
        >
          <r-button
            type="primary"
            :disabled="layersLoading || editorState.isDrawing"
            @click="backToModeSelect"
          >
            <span class="el-icon-arrow-left" />
            К списку городов
          </r-button>
        </div>
        <div
          v-if="layersLoading"
          class="event-editor-loader"
        >
          <span class="el-icon-loading" />
          Загрузка данных...
        </div>
        <div class="event-editor-map-actions">
          <r-map-tools
            v-if="mapgl"
            :map="mapgl"
            :map-bearing="mapBearing"
            :is3d="is3d"
            @change-prop="changeProp"
          />
          <ee-legend
            v-if="view === 'editor'"
            :is-legend-active="isLegendActive"
            @toggle-legend="toggleLegend"
          />
        </div>
      </div>
      <r-info-panel
        v-if="activeCard"
        :width="320"
      >
        <active-card
          :draw="draw"
          :mapgl="mapgl"
          :state="editorState"
        />
      </r-info-panel>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import SelectModal from './components/ee-select-modal'
import EeLegend from './components/ee-legend'
import Editor from './components/ee-editor'
import ActiveCard from './components/active-card/active-card'
import { initMap } from './core'
import { initialEditorState } from './configs'

export default {
  components: {
    SelectModal,
    EeLegend,
    Editor,
    ActiveCard
  },
  data() {
    return {
      mapgl: null,
      draw: null,
      mapLoading: null,
      mapBearing: null,
      is3d: false,
      BaseController: null,
      EditorController: null,
      isLegendActive: true,
      editorState: cloneDeep(initialEditorState)
    }
  },
  computed: {
    view() {
      return this.$store.state.eventEditor.view
    },
    model() {
      return this.$store.state.eventEditor.model
    },
    activeCard() {
      return this.$store.state.eventEditor.activeCard
    },
    activeObjectId() {
      return this.$store.state.eventEditor.activeCardId
    },
    overlay() {
      return this.$store.state.eventEditor.overlay
    },
    layersLoading() {
      return this.$store.state.eventEditor.loadingLayers.length > 0
    }
  },
  watch: {
    'store.state.eventEditor.view': function() {
      this.$nextTick(() => this.mapgl.resize())
    },
    '$store.state.eventEditor.activeCard': function(val) {
      this.$nextTick(() => this.mapgl.resize({ noRequest: !!val }))
    }
    // '$store.state.theme': function (val, prev) {
    //   this.mapgl.setLayoutProperty(prev, 'visibility', 'none')
    //   this.mapgl.setLayoutProperty(val, 'visibility', 'visible')
    // }
  },
  mounted() {
    initMap(this)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.saveMapParams)
    this.$store.commit('CLEAR_EE_MODEL')
    this.$store.commit('SET_EE_STATE_PROP', {
      name: 'activeCard',
      value: false
    })
    this.backToModeSelect()
    this.saveMapParams()
  },
  methods: {
    saveMapParams() {
      const zoom = this.mapgl.getZoom()
      const center = this.mapgl.getCenter()

      localStorage.setItem('ptMapCenter', JSON.stringify(center))
      localStorage.setItem('ptMapZoom', JSON.stringify(zoom))
    },
    changeProp({ field, value }) {
      this[field] = value
    },
    toggleLegend() {
      this.isLegendActive = !this.isLegendActive
    },
    getObjectsLimit() {
      return 10000
    },
    backToModeSelect() {
      if (this.layersLoading) return
      const { ids } = this.$store.state.eventEditor.model

      this.BaseController.toggleOff(ids)
      this.EditorController.toggleEditorOff()
      this.$store.commit('SET_EE_STATE_PROP', {
        name: 'view',
        value: 'model-list'
      })
      this.editorState.isDrawing = false
    }
  }
}
</script>

<style lang="scss">
.event-editor {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  background-color: var(--bg_surface) !important;

  &-layout {
    width: 100%;
    height: 100%;
    display: flex;
  }

  &-map {
    height: 100%;
    width: 100%;
  }

  &-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }

  &-controls {
    position: absolute;
    left: 16px;
    top: 16px;
    z-index: 100;
    display: flex;
  }

  &-map-actions {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
  }

  // toggle off mapbox focus and controls
  .mapboxgl-canvas {
    &:focus {
      outline: none;
    }
  }
  .mapboxgl-ctrl-top-right {
    display: none;
  }

  // loader styling
  &-loader {
    position: absolute;
    bottom: 16px;
    left: calc(50%);
    transform: translateX(-50%);
    font-size: 12px;
    line-height: 18px;
    padding: 10px 20px;
    border-radius: var(--border-radius);
    border: 1px solid transparent;
    border-color: var(--dividers_low_contrast) !important;
    background-color: var(--bg_panel_primary) !important;
    display: flex;
    align-items: center;
    color: var(--accent_primary) !important;
    z-index: 100;

    i {
      font-size: 18px;
      line-height: 18px;
      margin-right: 5px;
    }
  }
}
</style>
