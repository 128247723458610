<template>
  <sc-card
    v-loading="isLoading"
    :title="$t('traffic:title')"
    :footer="!mini"
  >
    <div class="sc-traffic-widget">
      <div class="sc-traffic-widget__block sc-traffic-widget__block--top">
        <div class="sc-traffic-widget__block-item">
          <r-cblock
            :size="$mq === `r_medium` ? 34 : 44"
            :border-size="3"
            color="#3FB735"
            border-color="#3FB735"
            :font-size="$mq === `r_medium` ? 24 : 32"
          >
            5
          </r-cblock>
        </div>
        <div class="sc-traffic-widget__block-item">
          <r-text
            :size="$mq === `r_medium` ? 16 : 26"
            style="font-weight: 600;"
          >
            50 км/ч
          </r-text>
          <r-text
            :size="$mq === `r_medium` ? 12 : 16"
            type="caption"
          >
            На дорогах чисто
          </r-text>
        </div>
      </div>
    </div>
    <div
      v-if="!mini"
      slot="footer"
      class="sc-traffic-widget__block sc-traffic-widget__block-footer"
    >
      <div
        v-for="(item, i) in 5"
        :key="i"
        class="sc-traffic-widget__block-item"
      >
        <r-text
          v-if="i === 0"
          type="caption"
        >
          {{
            $t("traffic:time-now")
          }}
        </r-text>
        <r-text
          v-else
          type="caption"
        >
          {{ `8:00` }}
        </r-text>
        <r-cblock
          :size="$mq === `r_medium` ? 24 : 36"
          color="#3FB735"
          border-color="#3FB735"
          :font-size="$mq === `r_medium` ? 16 : 20"
        >
          5
        </r-cblock>
      </div>
    </div>
  </sc-card>
</template>

<script>
import scCard from '../../layout/card'

export default {
  components: { scCard },
  props: {
    mini: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false
    }
  }
}
</script>

<style lang="scss">
.sc-traffic-widget {
  width: 100%;
  height: 100%;
  &__block {
    display: flex;
    justify-content: space-between;
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &--top {
      justify-content: flex-start;
      .sc-traffic-widget__block-item {
        margin-right: 28px;
        align-items: flex-start;
        &:last-child {
          margin: 0;
        }
      }
    }
    &-footer {
      .r-text.caption-2 {
        margin-bottom: 12px !important;
      }
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "traffic:title": "Пробки",
    "traffic:time-now": "Сейчас"
  },
  "en": {
    "traffic:title": "Traffic",
    "traffic:time-now": "Now"
  }
}
</i18n>
