import { mapColors } from '@/config/colors'

export const pointStyleConfig = {
  style: {
    paint: {
      'circle-radius': 3,
      'circle-color': mapColors.default,
      'circle-opacity': 1,
      'circle-pitch-alignment': 'map',
      'circle-stroke-width': 1,
      'circle-stroke-color': 'rgb(255,255,255)',
      'circle-stroke-opacity': 1
    }
  },
  popup: {
    enabled: false,
    fields: []
  },
  popupClick: {
    enabled: false,
    field: null
  },
  classesStyle: {
    'circle-color': {
      field: '',
      conditions: [],
      currentPallete: {}
    },
    'circle-radius': {
      field: '',
      fieldMax: 1,
      fieldMin: 0,
      range: [1, 10]
    }
  },
  stringsStyle: {
    'circle-color': {
      field: '',
      defaultValue: 'rgba(255,255,255,1)',
      values: []
    }
  },
  filters: [],
  order: {
    field: null,
    type: 'asc'
  },
  heatmap: {
    enabled: false,
    opacity: 0.8,
    currentPalette: null,
    conditions: [],
    loading: false,
    loaded: false
  },
  hexagon: {
    enabled: false,
    opacity: 0.8,
    radius: 2000,
    coverage: 0.8,
    upperPercentile: 100,
    maxElevation: 1000,
    elevationScale: 200,
    currentPalette: {},
    conditions: [],
    loading: false,
    loaded: false
  },
  cluster: {
    enabled: false,
    loading: false,
    loaded: false
  },
  icon: {
    enabled: false,
    name: '1.5_Russian_road_sign.svg',
    size: 16
  }
}

export const lineStringStyleConfig = {
  style: {
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-opacity': 1,
      'line-color': mapColors.default,
      'line-width': 2
    }
  },
  popup: {
    enabled: false,
    fields: []
  },
  popupClick: {
    enabled: false,
    field: null
  },
  classesStyle: {
    'line-color': {
      field: '',
      conditions: [],
      currentPallete: {}
    },
    'line-width': {
      field: '',
      fieldMax: 1,
      fieldMin: 0,
      range: [1, 10]
    }
  },
  stringsStyle: {
    'line-color': {
      field: '',
      defaultValue: 'rgba(255,255,255,1)',
      values: []
    }
  },
  diagram: {
    field: '',
    secondField: '',
    extruded: false,
    minValue: 0,
    maxValue: 1,
    conditions: [],
    currentPalette: {},
    color: 'rgb(255, 68, 140)',
    opacity: 0.7,
    width: 350,
    timeAggregation: null
  },
  filters: [],
  order: {
    field: null,
    type: 'asc'
  },
  dashed: {
    enabled: false
  },
  arrows: {
    enabled: false
  }
}

export const polygonStyleConfig = {
  style: {
    paint: {
      'fill-opacity': 0.7,
      'fill-color': mapColors.default
    }
  },
  borderStyle: {
    paint: {
      'line-color': mapColors.default,
      'line-width': 1,
      'line-opacity': 1
    },
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    }
  },
  popup: {
    enabled: false,
    fields: []
  },
  popupClick: {
    enabled: false,
    field: null
  },
  classesStyle: {
    'fill-color': {
      field: '',
      conditions: [],
      currentPallete: {}
    }
  },
  stringsStyle: {
    'fill-color': {
      field: '',
      defaultValue: 'rgba(255,255,255,1)',
      values: []
    }
  },
  fillExtrusion: {
    paint: {
      'fill-extrusion-height': 500,
      'fill-extrusion-opacity': 1
    },
    enabled: false,
    field: '',
    fieldMin: 0,
    fieldMax: 1
  },
  filters: [],
  order: {
    field: null,
    type: 'asc'
  },
  matrices: {
    enabled: false,
    loading: false,
    modelId: null,
    matricesSourceId: null,
    zoneId: null,
    matrixId: null,
    count: 30,
    min: 0,
    max: 1,
    strokeWidth: 8,
    sourceColor: 'rgba(50,136,189, 1)',
    targetColor: 'rgba(49,163,84, 1)'
  }
}

export const styleConfigs = {
  point: pointStyleConfig,
  multi_point: pointStyleConfig,
  line_string: lineStringStyleConfig,
  multi_line_string: lineStringStyleConfig,
  polygon: polygonStyleConfig,
  multi_polygon: polygonStyleConfig,
  geometry: pointStyleConfig
}

export const layerTypesConfig = {
  geometry: 'circle',
  point: 'circle',
  multi_point: 'circle',
  line_string: 'line',
  multi_line_string: 'line',
  polygon: 'fill',
  multi_polygon: 'fill'
}

export const clusterLayerConfig = {
  type: 'circle',
  filter: ['has', 'point_count'],
  paint: {
    'circle-pitch-alignment': 'map',
    'circle-color': [
      'step',
      ['get', 'point_count'],
      'rgba(153,213,148, 1)',
      50,
      'rgba(254,224,139, 1)',
      200,
      'rgba(252,141,89, 1)',
      1000,
      'rgba(213,62,79, 1)'
    ],
    'circle-opacity': 0.8,
    'circle-radius': ['step', ['get', 'point_count'], 8, 10, 12, 100, 16]
  }
}

export const clusterCountLayerConfig = {
  type: 'symbol',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium'],
    'text-size': 12,
    'text-pitch-alignment': 'map'
  },
  paint: {
    'text-color': 'rgba(255,255,255,1)'
  }
}

export const activeObjectsConfig = {
  point: {
    type: 'circle',
    layout: {},
    paint: {
      'circle-radius': 3,
      'circle-color': 'rgb(255, 68, 140)',
      'circle-pitch-alignment': 'map',
      'circle-stroke-color': 'rgb(255,255,255)',
      'circle-stroke-width': 1
    }
  },
  line_string: {
    type: 'line',
    layout: {},
    paint: {
      'line-width': 3,
      'line-color': 'rgb(255, 68, 140)'
    }
  },
  polygon: {
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': 'rgb(255, 68, 140)',
      'fill-opacity': 0.6
    }
  },
  polygon_border: {
    type: 'line',
    layout: {},
    paint: {
      'line-width': 2,
      'line-color': 'rgb(255, 68, 140)'
    }
  }
}
