<template>
  <div class="analytics">
    <an-select-modal v-if="!reportUrl" />
    <an-content v-else />
  </div>
</template>

<script>
import anSelectModal from './components/an-select-modal/an-select-modal'
import anContent from './components/an-content/an-content'

export default {
  components: {
    anSelectModal,
    anContent
  },
  computed: {
    reportUrl() {
      return this.$store.state.analytics.reportUrl || null
    }
  }
}
</script>

<style lang="scss">
.analytics {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  background-color: var(--bg_surface) !important;
}
</style>
