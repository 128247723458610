export const getPrimaryAttributes = ids => [
  {
    prop: 'event_class_id',
    label: 'Тип события',
    request: ids.event_classes,
    values: 'eventTypes',
    type: 'select'
  },
  {
    prop: 'start_time',
    label: 'Время начала',
    type: 'time'
  },
  {
    prop: 'end_time',
    label: 'Время окончания',
    type: 'time'
  },
  {
    prop: 'closed_line_count',
    label: 'Количество перекрытых полос',
    type: 'input-number'
  }
]

export const getSecondaryAttributes = () => [
  {
    prop: 'address',
    label: 'Адрес',
    type: 'input'
  },
  {
    prop: 'customer',
    label: 'Заказчик',
    type: 'input'
  },
  {
    prop: 'performer',
    label: 'Подрядчик',
    type: 'input'
  },
  {
    prop: 'phone_number',
    label: 'Телефон',
    type: 'input'
  }
]
