<template>
  <bdd-preventive-measures />
</template>

<script>
import bddPreventiveMeasures from '@/components/bdd/preventive-measures/bdd-preventive-measures'

export default {
  components: { bddPreventiveMeasures }
}
</script>
