<template>
  <div
    v-loading="isLoading"
    class="rp-sidebar"
  >
    <div class="rp-sidebar__title">
      <r-title type="subtitle-1">
        Роли и разрешения
      </r-title>
    </div>
    <rp-sidebar-content />
  </div>
</template>

<script>
import rpSidebarContent from './rp-sidebar-content.vue'

export default {
  components: {
    rpSidebarContent
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    activeUserId() {
      return this.$store.state.rolePermission.activeUserId || null
    }
  },
  watch: {
    '$store.state.rolePermission.updateRoles': {
      handler: function(val) {
        if (!val) return
        this.loadRoles()
        this.$store.state.rolePermission.updateRoles = false
      }
    },
    '$store.state.rolePermission.updateUsers': {
      handler: function(val) {
        if (!val) return
        this.loadUsers()
        this.loadUserPermission()
        this.$store.state.rolePermission.updateUsers = false
      }
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.isLoading = true

      try {
        await this.loadUsers()
        await this.loadRoles()
      } catch (e) {
        throw new Error(e)
      } finally {
        this.isLoading = false
      }
    },
    async loadUsers() {
      try {
        const config = {
          include: { user_roles: {} }
        }
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/auth.users?config=${JSON.stringify(config)}`
        })

        this.$store.commit('ROLE_PERM_SET_FIELD', {
          field: 'allUsers',
          value: Object.values(data)
        })
      } catch (e) {
        throw new Error(e)
      }
    },
    async loadRoles() {
      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: 'objectInfo/auth.roles'
        })
        const roles = Object.values(data)

        roles.push({
          id: 'ib_admin',
          name: 'Администратор'
        })

        roles.push({
          id: 'admin',
          name: 'Менеджер'
        })

        this.$store.commit('ROLE_PERM_SET_FIELD', {
          field: 'allRoles',
          value: roles
        })
      } catch (e) {
        throw new Error(e)
      }
    },
    async loadUserPermission() {
      if (!this.activeUserId) return
      try {
        this.$store.commit('ROLE_PERM_SET_IS_LOADING', true)
        const perms = {
          modules: [],
          datasources: []
        }
        const modules = await this.$store.dispatch('POST_REQUEST', {
          url: `user/modules?user_id=${this.activeUserId}`
        })
        perms.modules = modules.data

        const datasources = await this.$store.dispatch('POST_REQUEST', {
          url: `user/datasources?user_id=${this.activeUserId}`
        })
        const datasourcesUniqId = {}
        datasources.data.forEach(e => {
          if (!datasourcesUniqId[e.id]) {
            perms.datasources.push(e)
            datasourcesUniqId[e.id] = {}
          }
        })
        this.$store.commit('ROLE_PERM_SET_ACTIVE_USER_DATA', perms)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.$store.commit('ROLE_PERM_SET_IS_LOADING', false)
      }
    }
  }
}
</script>

<style lang="scss">
.rp-sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;

  &__title {
    padding: 7px 8px 8px;
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid var(--dividers_low_contrast);
  }
}
</style>
