<template>
  <div class="sc-status-widget type-3">
    <div class="sc-status-widget__status">
      <el-progress
        class="sc-progress"
        type="circle"
        :percentage="getPercentage()"
        :stroke-width="12"
        stroke-linecap="butt"
        :color="getProgressColor"
        :width="100"
        :style="getTextStyle"
      />
    </div>
    <div class="sc-status-widget__list">
      <div
        v-for="(item, i) in filteredData"
        :key="item + i"
        class="sc-status-widget__list-item"
      >
        <r-title :color="colors[item.type] || colors.default">
          {{ item.value }}
        </r-title>
        <r-text type="caption">
          {{ $t(item.type) }}
        </r-text>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    statusData: {
      type: Object,
      required: true
    },
    colors: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    filteredData() {
      return this.statusData.items || []
    },
    getProgressColor() {
      if (this.getPercentage() > 80) return '#59D64E'
      if (this.getPercentage() > 60) return '#2e93fe'
      if (this.getPercentage() > 40) return '#6f7ad3'
      if (this.getPercentage() > 20) return '#ffac00'

      return '#FF3E3E'
    },
    getTextStyle() {
      return {
        color: `${this.getProgressColor} !important`
      }
    }
  },
  methods: {
    getPercentage() {
      const workCount = this.filteredData
        ? this.filteredData.filter(e => e.type === 'work')[0]
          ? this.filteredData.filter(e => e.type === 'work')[0].value
          : 0
        : 0
      return (
        Number(((workCount * 100) / this.statusData.total).toFixed(0)) || 0
      )
    }
  }
}
</script>

<style lang="scss">
.sc-status-widget {
  &.type-3 {
    display: flex;
    .sc-status-widget__status {
      display: flex;
      align-items: center;
      margin-right: 32px;
      .el-progress__text {
        font-size: 26px !important;
        font-weight: 600;
        color: inherit !important;
      }
    }
    .sc-status-widget__list {
      display: flex;
      width: calc(100% - 24px);
      height: 100%;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      &-item {
        width: calc(50% - 4px);
        min-width: auto;
        height: 50px;
        overflow: hidden;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 4px;
        position: relative;
        &:last-child {
          margin: 0;
        }
        .r-text.caption-2 {
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
    .el-progress-circle__track {
      stroke: var(--black);
      stroke-opacity: 0.1 !important;
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "work": "Работают",
    "error": "Ошибка",
    "inactive": "Неактивны"
  },
  "en": {
    "work": "Work",
    "error": "Error",
    "inactive": "Inactive"
  }
}
</i18n>
