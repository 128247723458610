<template>
  <div class="ds-manager__component menu">
    <div class="ds-manager__component-title">
      <r-title type="title-2">
        {{ $t('ds-manager:menu:title') }}
      </r-title>
    </div>
    <div class="ds-manager__component-content">
      <div class="ds-manager__component-subtitle">
        <r-text type="caption">
          {{ $t('ds-manager:menu:subtitle') }}
        </r-text>
      </div>
      <ul class="ds-manager__menu-list">
        <li
          v-for="i in menuItems"
          :key="i.id"
          class="ds-manager__menu-list-item"
          @click="menuClickHandler(i.name)"
        >
          <r-icon
            :name="i.icon"
            :size="18"
          />
          <r-text>{{ $t(`ds-manager:menu:item-name:${i.name}`) }}</r-text>
          <span class="r-icon-arrow" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
const menuItems = [
  { id: 1, name: 'ds-manager-empty-ds', icon: 'folder-layer' },
  { id: 2, name: 'ds-manager-empty-model', icon: 'model-layer' },
  { id: 3, name: 'ds-manager-from-file', icon: 'download' },
  { id: 4, name: 'ds-manager-from-file-name', icon: 'acts-document' }
]

export default {
  props: {
    menuClickHandler: {
      type: Function,
      default: () => null
    }
  },
  data() {
    return {
      menuItems
    }
  }
}
</script>

<style lang="scss">
.menu {
  .ds-manager__component-content {
    width: 400px;
    height: 240px;
  }
}
.ds-manager__menu {
  &-list {
    &-item {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 4px 24px;
      box-sizing: border-box;
      border: 1px solid;
      border-radius: var(--border-radius);
      border-color: var(--dividers_low_contrast) !important;
      height: 40px;
      margin-bottom: 12px;
      transition: all 0.1s ease;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      gap: 0.5rem;
      .r-text {
        transition: all 0.1s ease;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .r-icon-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
        width: 16px;
        transition: all 0.1s ease;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        border-color: var(--accent_primary) !important;
        .r-text {
          color: var(--accent_primary) !important;
        }
        .r-icon-arrow {
          &:before {
            border-color: var(--accent_primary) !important;
          }
          right: 10px;
        }
        .r-icon {
          svg {
            fill: var(--accent_primary) !important;
          }
        }
      }
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "ds-manager:menu:title": "Создать источник данных",
    "ds-manager:menu:subtitle": "Выберите действие:",
    "ds-manager:menu:item-name:ds-manager-empty-ds": "Создать пустой источник данных",
    "ds-manager:menu:item-name:ds-manager-empty-model": "Создать пустую модель данных",
    "ds-manager:menu:item-name:ds-manager-from-file": "Создать источник данных из файла",
    "ds-manager:menu:item-name:ds-manager-from-file-name": "Создать источник данных по имени файла"
  },
  "en": {
    "ds-manager:menu:title": "Create data source",
    "ds-manager:menu:subtitle": "Select an action:",
    "ds-manager:menu:item-name:ds-manager-empty-ds": "Create an empty data source",
    "ds-manager:menu:item-name:ds-manager-empty-model": "Create an empty data model",
    "ds-manager:menu:item-name:ds-manager-from-file": "Create data source from file",
    "ds-manager:menu:item-name:ds-manager-from-file-name": "Create data source by file name"
  }
}
</i18n>
