// import { jsonToGeojson } from '@/utils'
import { layersConfig } from '../configs'
// import { modifyLines, getSPFeatures } from '../helpers'

export class RouterController {
  constructor(parent) {
    this.parent = parent
    this.$store = parent.$store
    this.mapgl = parent.mapgl
    this.handlers = {}
  }

  initRouter() {
    this.$store.commit('CLEAR_PT_CARD_INFO')

    const { ids } = this.$store.state.publicTransport.model
    const stopPoints = [`${ids.stop_points}_points`, `${ids.stop_points}_icons`]
    stopPoints.forEach(s => {
      this.addLayerHandler(s)
    })
  }

  addUserRoute() {
    const layers = [
      {
        name: 'userRoute',
        type: 'combined'
      },
      {
        name: 'extremePoints',
        type: 'label'
      }
    ]

    layers.forEach(layer => {
      const data = this.$store.state.publicTransport[layer.name]
      const source = this.mapgl.getSource(layer.name) || null

      source
        ? source.setData(data)
        : this.mapgl.addSource(layer.name, {
          type: 'geojson',
          data
        })

      if (!this.mapgl.getLayer(layer.name)) {
        this.mapgl.addLayer({
          id: layer.name,
          source: layer.name,
          ...layersConfig[layer.type]
        })
      }
    })

    this.setLinesVisibility('none')
  }

  stopPointsLayers() {
    const { stop_points } = this.$store.state.publicTransport.model.ids
    return [`${stop_points}_points`, `${stop_points}_icons`]
  }

  setLinesVisibility(value) {
    const { ids } = this.$store.state.publicTransport.model
    if (this.mapgl.getLayer(ids.lines)) {
      this.mapgl.setLayoutProperty(ids.lines, 'visibility', value)
    }
  }

  clearUserRoute() {
    const layers = ['userRoute', 'extremePoints']
    layers.forEach(e => {
      if (!this.mapgl.getSource(e)) return
      this.mapgl.removeLayer(e)
      this.mapgl.removeSource(e)
    })
    this.setLinesVisibility('visible')
  }

  addLayerHandler(layer) {
    this.mapgl.on('click', layer, e => {
      const { features, point } = e
      const { properties } = features
      const { x, y } = point
      this.parent.routerPopupSettings = {
        display: 'grid',
        top: y + 7,
        left: x - 75,
        id: properties.id
      }

      this.$store.commit('SET_PT_FIELD', {
        field: 'selectedStop',
        value: properties.id
      })
    })

    this.mapgl.on('move', () => {
      this.parent.routerPopupSettings = {
        display: 'none'
      }
    })
  }
}
