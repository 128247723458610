
const state = {
  updated: false
}

const mutations = {
  ATTRIBUTES_EDITOR_SET(state, [field, value]) {
    state[field] = value
  }
}

export default {
  state,
  mutations
}
