export const openObjectInfo = (component, source_id, id, module, feature) => {
  component.$store.commit('ADD_ACTIVE_CARD', { source_id, id, module })
  if (module === 'map' && feature) {
    component.$store.commit('SET_MAP_ACTIVE_CARD_FEATURE', feature)
  }
}

export const changeObjectInfo = (component, source_id, id, module, feature) => {
  component.$store.commit('CHANGE_ACTIVE_CARD', { source_id, id, module })
  if (module === 'map' && feature) {
    component.$store.commit('SET_MAP_ACTIVE_CARD_FEATURE', feature)
  }
}

export const addSmartObjectInfo = (
  component,
  item,
  layer_id,
  layer_name,
  module
) => {
  component.$store.commit('ADD_SMART_ACTIVE_CARD', {
    item,
    layer_id,
    layer_name,
    module
  })
}

export const changeSmartObjectInfo = (
  component,
  item,
  layer_id,
  layer_name,
  module
) => {
  component.$store.commit('CHANGE_SMART_ACTIVE_CARD', {
    item,
    layer_id,
    layer_name,
    module
  })
}
