<template>
  <div class="modelling">
    <div class="modelling__panel">
      <router-view />
    </div>
    <div class="modelling__map">
      <m-map />
      <m-timeline v-if="routeName === 'm-scenario' && times.length" />
    </div>
  </div>
</template>

<script>
import MMap from '@/components/modelling/map/map'
import MTimeline from '@/components/modelling/timeline/timeline'

export default {
  components: {
    MMap,
    MTimeline
  },
  computed: {
    routeName() {
      return this.$route.name
    },
    times() {
      return this.$store.state.modelling.times
    }
  },
  created() {
    if (this.routeName !== 'm-scenarios') {
      this.$router.push('/app/modelling/main/list')
    }
  }
}
</script>

<style lang="scss">
.modelling {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  background-color: var(--bg_surface) !important;

  &__panel {
    height: 100%;
    min-width: 260px;
    box-shadow: 2px 0 10px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    background-color: var(--bg_panel_primary) !important;
  }

  &__map {
    flex: 1;
    height: 100%;
    position: relative;
  }
}
</style>
