<template>
  <div class="ts-types">
    <ul class="ts-types__list">
      <li
        v-for="item in values"
        :key="item.id"
        :class="getClassName(item.id)"
        @click="changeType(item.id)"
      >
        <r-text>
          {{ item.name }}
        </r-text>
      </li>
    </ul>
  </div>
</template>

<script>
const values = [
  {
    id: 0,
    name: 'Текущая ситуация'
  },
  {
    id: 15,
    name: 'Прогноз на 15 минут'
  },
  {
    id: 30,
    name: 'Прогноз на 30 минут'
  },
  {
    id: 45,
    name: 'Прогноз на 45 минут'
  }
]

export default {
  props: {
    value: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      values
    }
  },
  methods: {
    getClassName(id) {
      return `ts-types__item ${
        this.value === id ? 'ts-types__item--active' : ''
      }`
    },
    changeType(value) {
      this.$emit('change', value)
    }
  }
}
</script>

<style lang="scss">
.ts-types {
  &__list {
    text-align: center;
  }

  &__item {
    padding: 4px;
    cursor: pointer;
    border-radius: var(--border-radius);
    margin-top: 4px;

    &:hover {
      background-color: var(--accent_hover);
    }

    &--active {
      background-color: var(--accent_active) !important;
    }
  }

  &__separator {
    height: 1px;
    background-color: var(--dividers_low_contrast);
    margin-top: 4px;
  }
}
</style>
