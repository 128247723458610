<template>
  <el-dialog
    v-loading="isLoading"
    class="r-dialog-modal"
    :visible.sync="showModal"
    :title="title"
    :top="top"
    :width="width"
    :before-close="beforeCloseWindow"
  >
    <slot />
  </el-dialog>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    showModal: {
      type: Boolean,
      default: false,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    top: {
      type: String,
      default: '10vh'
    },
    width: {
      type: String,
      default: '50%'
    }
  },
  mounted() {
    window.addEventListener('mousedown', this.beforeCloseHandler)
    window.addEventListener('keydown', this.beforeCloseHandler)
  },
  beforeDestroy() {
    window.removeEventListener('mousedown', this.beforeCloseHandler)
    window.removeEventListener('keydown', this.beforeCloseHandler)
  },
  methods: {
    beforeCloseWindow() {
      return false
    },
    beforeCloseHandler(e) {
      let closeWindow = false

      if (e.target.classList) {
        e.target.classList.forEach(e => {
          if (e === 'el-dialog__wrapper' ||
            e === 'el-icon-close' ||
            e === 'el-dialog__headerbtn'
          ) {
            closeWindow = true
          }
        })
      }

      if (e.keyCode === 27) {
        closeWindow = true
      }

      if (closeWindow) this.$emit('close-handler')
    }
  }
}
</script>

<style lang="scss" scoped>
.r-dialog-modal {
  position: initial;
}
</style>
