export const exportConfig = {
  order: { no: 'desc' },
  column_order: [
    'no',
    'created_at',
    'event_class.name',
    'name',
    'address',
    'start_time',
    'end_time',
    'closed_line_count',
    'requested',
    'relevance'
  ],
  column_labels: [
    '№',
    'Создано',
    'Тип',
    'Название',
    'Адрес',
    'Начало',
    'Окончание',
    'Перекрытие',
    'Заявка',
    'Актуальность'
  ],
  only: [
    'no',
    'created_at',
    'name',
    'address',
    'start_time',
    'end_time',
    'closed_line_count',
    'requested'
  ],
  include: {
    event_class: { only: ['name'] }
  }
}
