<template>
  <sc-card
    v-loading="!initialRequest"
    :title="$t(`events-log`)"
  >
    <div :class="`sc-events-log ${type}`">
      <sc-events-log-list
        v-if="eventsData"
        :events-data="eventsData"
        :type="type"
      />
    </div>
  </sc-card>
</template>

<script>
import scCard from '../../layout/card'
import scEventsLogList from './components/events-log-list'

export default {
  components: {
    scCard,
    scEventsLogList
  },
  props: {
    options: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      titleIcon: {
        name: 'traffic-cone',
        color: '#FF3E3E'
      }
    }
  },
  computed: {
    eventsInfo() {
      return this.$store.state.situationCenter.events
    },
    eventsData() {
      return this.$store.state.situationCenter.layersData.map.events || null
    },
    type() {
      return this.options.type || 'type-1'
    },
    initialRequest() {
      return (
        this.$store.state.situationCenter.completedRequests.indexOf(
          this.options.layer_id
        ) !== -1
      )
    }
  }
}
</script>

<style lang="scss">
.sc-events-log {
  width: 440px;

  &.type-2 {
    width: 800px;
  }
}
</style>

<i18n>
{
  "ru": {
    "events-log": "Журнал событий"
  },
  "en": {
    "events-log": "Events log"
  }
}
</i18n>
