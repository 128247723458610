const LAYER_ID = 'map-history-features'
const colorValue = [
  'case',
  ['==', ['get', 'type'], 'insert'], '#40A228',
  ['==', ['get', 'type'], 'update'], '#D69338',
  ['==', ['get', 'type'], 'delete'], '#D94141',
  '#EBEDF0'
]

export class HistoryController {
  constructor(parent) {
    this.parent = parent
    this.mapgl = parent.mapgl
    this.$store = parent.$store

    this.mousemoveHandler = this.mousemoveHandler.bind(this)
    this.mouseleaveHandler = this.mouseleaveHandler.bind(this)
  }

  updateHistoryLayer(value) {
    if (value) {
      this.addLayer()
    } else {
      this.removeLayer()
    }
  }

  addLayer() {
    const { historyFeatures } = this.$store.state.map
    const source = this.mapgl.getSource(LAYER_ID)

    if (source) {
      source.setData(historyFeatures)
    } else {
      this.mapgl.addSource(LAYER_ID, {
        type: 'geojson',
        data: historyFeatures
      })
      this.mapgl.addLayer({
        id: `${LAYER_ID}_points`,
        source: LAYER_ID,
        type: 'circle',
        paint: {
          'circle-color': colorValue,
          'circle-radius': 6,
          'circle-stroke-color': '#fff',
          'circle-stroke-width': 2
        },
        filter: ['==', '$type', 'Point']
      })
      this.mapgl.addLayer({
        id: `${LAYER_ID}_lines`,
        source: LAYER_ID,
        type: 'line',
        paint: {
          'line-color': colorValue,
          'line-width': 10
        },
        layout: {
          'line-cap': 'round',
          'line-join': 'round'
        },
        filter: ['==', '$type', 'LineString']
      })
      this.mapgl.addLayer({
        id: `${LAYER_ID}_fills`,
        source: LAYER_ID,
        type: 'fill',
        paint: {
          'fill-color': colorValue,
          'fill-opacity': 0.5
        },
        filter: ['==', '$type', 'Polygon']
      })

      this.mapgl.on('mousemove', `${LAYER_ID}_points`, this.mousemoveHandler)
      this.mapgl.on('mouseleave', `${LAYER_ID}_points`, this.mouseleaveHandler)

      this.mapgl.on('mousemove', `${LAYER_ID}_lines`, this.mousemoveHandler)
      this.mapgl.on('mouseleave', `${LAYER_ID}_lines`, this.mouseleaveHandler)

      this.mapgl.on('mouesemove', `${LAYER_ID}_fills`, this.mousemoveHandler)
      this.mapgl.on('mouesemove', `${LAYER_ID}_fills`, this.mouseleaveHandler)
    }
  }

  removeLayer() {
    this.mapgl.off('mousemove', `${LAYER_ID}_points`, this.mousemoveHandler)
    this.mapgl.off('mouseleave', `${LAYER_ID}_points`, this.mouseleaveHandler)

    this.mapgl.off('mousemove', `${LAYER_ID}_lines`, this.mousemoveHandler)
    this.mapgl.off('mouseleave', `${LAYER_ID}_lines`, this.mouseleaveHandler)

    this.mapgl.off('mouesemove', `${LAYER_ID}_fills`, this.mousemoveHandler)
    this.mapgl.off('mouesemove', `${LAYER_ID}_fills`, this.mouseleaveHandler)

    if (this.mapgl.getLayer(`${LAYER_ID}_points`)) this.mapgl.removeLayer(`${LAYER_ID}_points`)
    if (this.mapgl.getLayer(`${LAYER_ID}_lines`)) this.mapgl.removeLayer(`${LAYER_ID}_lines`)
    if (this.mapgl.getLayer(`${LAYER_ID}_fills`)) this.mapgl.removeLayer(`${LAYER_ID}_fills`)
    if (this.mapgl.getSource(LAYER_ID)) this.mapgl.removeSource(LAYER_ID)
  }

  // handlers
  mousemoveHandler(e) {
    const { index } = e.features[0].properties

    this.$store.commit('SET_HISTORY_HOVERED_FEATURE', index)
  }

  mouseleaveHandler() {
    this.$store.commit('SET_HISTORY_HOVERED_FEATURE', -1)
  }
}
