<template>
  <component
    :is="modalType"
    v-if="event"
    :related="related"
    :source="event"
    @updateRelated="loadRelated"
    @close="close"
    @hasChanges="hasChanges"
  />
</template>

<script>
import accident from './accident'
import closure from './closure'

import related from './config/related'

export default {
  components: {
    accident,
    closure
  },
  data() {
    return {
      related,
      loading: false,
      changes: false
    }
  },
  computed: {
    modalType() {
      return this.$store.state.odd.modalType
    },
    event() {
      return this.$store.state.odd.featureToEdit?.properties
    }
  },
  mounted() {
    this.loadRelated()
  },
  methods: {
    hasChanges() {
      this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', true)
    },
    async loadRelated() {
      this.loading = true

      for (const r in this.related) {
        const urlName = this.related[r].urlName
        const source_id = this.$store.state.services[urlName]
        if (!source_id) return

        try {
          const config = JSON.stringify(this.related[r].config)
          const url = `objectInfo/${source_id}?config=${config}`

          const { data } = await this.$store.dispatch('GET_REQUEST', {
            url
          })

          this.related[r].data = Object.values(data)
        } catch (e) {
          console.log(e)
        }
      }
      this.loading = false
    },
    close() {
      this.$store.commit('CLOSE_MODAL_WINDOW')
    }
  }
}
</script>
