import cloneDeep from 'lodash.clonedeep'
import throttle from 'lodash.throttle'
import {
  CommonSelectors,
  Constants,
  doubleClickZoom,
  createSupplementaryPoints
} from '@/libs/map-draw/lib-utils'
import {
  undraggableTypes,
  getPointFeatures,
  createHoveredNodeHelper,
  clearAllHelpers,
  createLineGeoJson,
  getLineRouteItemsCoordinates,
  getLineRouteIds,
  getWaypointsQuery,
  createRouteHelper,
  clearAllRouteGeometry,
  clearReverseRouteStyling,
  // clearActiveStopPointsStyling,
  setActiveStopPointsStyling
} from '@/libs/map-draw/helpers'

export const createEEDirectSelectMode = (MapboxDraw, editor, component) => {
  const DirectSelect = {}
  const showError = throttle(() => {
    component.$message({
      message:
        'Не удается построить дальнейший маршрут из последней указанной точки',
      type: 'error',
      duration: 1500,
      customClass: 'route-editor-warning'
    })
  }, 1000)

  // TODO: modify to IDS
  const getWaypoints = (id, index, lineRouteItems) => {
    switch (index) {
      case 0:
        return [{ id }, lineRouteItems[lineRouteItems.length - 1]]
      case lineRouteItems.length - 1:
        return [lineRouteItems[0], { id }]
      default:
        return [
          lineRouteItems[0],
          { id },
          lineRouteItems[lineRouteItems.length - 1]
        ]
    }
  }

  DirectSelect.onSetup = function(opts) {
    const featureId = opts.featureId
    const feature = this.getFeature(featureId)

    if (!feature) {
      throw new Error(
        'You must provide a featureId to enter direct_select mode'
      )
    }

    if (feature.type === Constants.geojsonTypes.POINT) {
      throw new TypeError("direct_select mode doesn't handle point features")
    }

    const { properties } = feature
    const { line_route_items } = properties
    const state = {
      featureId,
      feature,
      dragMoveLocation: opts.startPos || null,
      dragMoving: false,
      canDragMove: false,
      selectedCoordPaths: opts.coordPath ? [opts.coordPath] : [],
      lineRouteItems: line_route_items,
      waypoints: [],
      prevLineRouteItems: cloneDeep(line_route_items),
      draggingPointIndex: 0,
      currentCoordinates: [],
      prevCoordinates: feature.getCoordinates(),
      currentPoint: null
    }

    this.setSelectedCoordinates(
      this.pathsToCoordinates(featureId, state.selectedCoordPaths)
    )
    this.setSelected(featureId)
    doubleClickZoom.disable(this)

    this.setActionableState({
      trash: true
    })

    if (editor.geom_type === 'line_routes') {
      createRouteHelper(this.map, feature.getCoordinates(), true)
    }

    return state
  }

  DirectSelect.onMouseMove = function(state, e) {
    // On mousemove that is not a drag, stop vertex movement.
    const isFeature = CommonSelectors.isActiveFeature(e)
    const onVertex = CommonSelectors.isVertex(e)
    const noCoords = state.selectedCoordPaths.length === 0
    if (isFeature && noCoords) {
      this.updateUIClasses({ mouse: Constants.cursors.POINTER })
    } else if (onVertex && !noCoords) {
      this.updateUIClasses({ mouse: Constants.cursors.POINTER })
    } else this.updateUIClasses({ mouse: Constants.cursors.NONE })

    if (editor.geom_type !== 'line_routes') this.stopDragging(state)
  }

  DirectSelect.onDrag = async function(state, e) {
    if (state.canDragMove !== true) return
    state.dragMoving = true
    e.originalEvent.stopPropagation()

    if (editor.geom_type === 'line_routes') {
      const stopPointFeatures = getPointFeatures(
        this.map,
        editor,
        e,
        'stop_points',
        true
      )
      const nodesFeatures = getPointFeatures(this.map, editor, e, 'nodes', true)
      const pointFeatures = [...stopPointFeatures, ...nodesFeatures]

      if (pointFeatures.length) {
        const point = pointFeatures[0]
        const { geometry, properties } = point
        const { coordinates } = geometry

        if (!state.currentPoint || state.currentPoint !== properties.id) {
          const { draggingPointIndex, lineRouteItems, feature } = state

          const isEndOfRoute =
            draggingPointIndex === 0 ||
            draggingPointIndex === lineRouteItems.length - 1

          if ((isEndOfRoute && stopPointFeatures.length) || !isEndOfRoute) {
            try {
              createHoveredNodeHelper(this.map, coordinates, 'stop_points')
              state.currentPoint = properties.id
              const waypoints = getWaypoints(
                properties.id,
                draggingPointIndex,
                getLineRouteIds(lineRouteItems)
              )
              const locString = getWaypointsQuery(waypoints)
              const url = `pubtran_route/${editor.modelId}/${locString}?postprocessing=line_route_item`
              const { data } = await component.$store.dispatch(
                'ROUTE_REQUEST',
                {
                  url
                }
              )
              const { geometry, line_route_items } = data

              state.lineRouteItems = line_route_items.map((item, i) => ({
                ...item,
                geom: { coordinates: geometry.coordinates[i] },
                node: undefined
              }))
              state.waypoints = waypoints.map(w => w.id)
              feature.setCoordinates(geometry.coordinates)
              createRouteHelper(this.map, feature.getCoordinates(), true)
              state.selectedCoordPaths = []
              state.feature.changed()
            } catch (error) {
              console.log(error)
              showError(component)
            }
          } else {
            createHoveredNodeHelper(
              this.map,
              [e.lngLat.lng, e.lngLat.lat],
              'stop_points'
            )
          }
        }
      } else {
        createHoveredNodeHelper(
          this.map,
          [e.lngLat.lng, e.lngLat.lat],
          'stop_points'
        )
        state.currentPoint = null
        if (state.prevLineRouteItems.length) {
          state.lineRouteItems = cloneDeep(state.prevLineRouteItems)
          state.feature.setCoordinates(state.prevCoordinates)
          createRouteHelper(this.map, state.feature.getCoordinates(), true)
          state.feature.changed()
        }
        this.clearSelectedCoordinates()
      }
    } else {
      const delta = {
        lng: e.lngLat.lng - state.dragMoveLocation.lng,
        lat: e.lngLat.lat - state.dragMoveLocation.lat
      }
      if (state.selectedCoordPaths.length > 0) this.dragVertex(state, e, delta)
      else this.dragFeature(state, e, delta)
    }

    state.dragMoveLocation = e.lngLat
  }

  DirectSelect.startDragging = function(state, e) {
    this.map.dragPan.disable()
    const { meta } = e.featureTarget.properties
    state.canDragMove =
      meta === 'vertex' || undraggableTypes.indexOf(editor.geom_type) === -1
    state.dragMoveLocation = e.lngLat
    state.draggingPointIndex = Number(e.featureTarget.properties.coord_path)
  }

  DirectSelect.stopDragging = function(state) {
    this.map.dragPan.enable()
    state.dragMoving = false
    state.canDragMove = false
    state.dragMoveLocation = null

    if (editor.geom_type === 'line_routes') {
      clearAllHelpers(this.map)

      state.feature.setProperty('line_route_items', state.lineRouteItems)
      state.feature.setProperty('waypoints', state.waypoints)
      clearAllRouteGeometry(this.map)

      // change stop points styling
      const spIds = state.lineRouteItems
        .filter(item => item.stop_point_id)
        .map(item => item.stop_point_id)
      // stop points styling
      setActiveStopPointsStyling(this.map, component.$store, spIds)

      component.draw.changeMode('simple_select', {
        featureIds: [state.featureId]
      })

      if (state.currentPoint) {
        this.fireUpdate()
      }
    }
  }

  DirectSelect.onTouchEnd = DirectSelect.onMouseUp = function(state) {
    if (state.dragMoving && editor.geom_type !== 'line_routes') {
      this.fireUpdate()
    }

    this.stopDragging(state)
  }

  DirectSelect.clickNoTarget = function() {
    clearAllRouteGeometry(this.map)
    clearReverseRouteStyling(this.map)
    // clearActiveStopPointsStyling(this.map, component.$store)
    this.changeMode(Constants.modes.SIMPLE_SELECT)
  }

  DirectSelect.toDisplayFeatures = function(state, geojson, push) {
    if (state.featureId === geojson.properties.id) {
      geojson.properties.active = 'true'
      push(geojson)
      let supplementaryPoints

      if (editor.geom_type === 'line_routes') {
        const coordinates = getLineRouteItemsCoordinates(
          state.prevLineRouteItems.filter(
            item => item.node_id || item.stop_point_id
          )
        )
        const options = {
          map: this.map,
          selectedPaths: state.selectedCoordPaths
        }

        supplementaryPoints = createSupplementaryPoints(
          createLineGeoJson(coordinates),
          options
        )
      } else {
        supplementaryPoints = createSupplementaryPoints(geojson, {
          map: this.map,
          midpoints: true,
          selectedPaths: state.selectedCoordPaths
        })
      }

      if (editor.geom_type === 'links') {
        supplementaryPoints = supplementaryPoints.slice(
          1,
          supplementaryPoints.length - 1
        )
      }
      if (editor.geom_type === 'connectors') supplementaryPoints = []
      supplementaryPoints.forEach(push)
    } else {
      geojson.properties.active = 'false'
      push(geojson)
    }
    this.fireActionable(state)
  }

  return { ...MapboxDraw.modes.direct_select, ...DirectSelect }
}
