export const fileTypes = [
  {
    id: 1,
    value: 'xlsx',
    icon: 'xls'
  },
  {
    id: 2,
    value: 'json',
    icon: 'json'
  },
  {
    id: 3,
    value: 'csv',
    icon: 'csv'
  }
]
