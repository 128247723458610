<template>
  <div
    v-show="view"
    class="odd-add-controls"
  >
    <r-button
      v-show="!eventSelection"
      icon="add-plus"
      class="odd-add-controls__button"
      type="primary"
      @click="createObject('signs')"
    >
      {{ getButtonText() }}
    </r-button>
    <div
      v-show="eventSelection"
      class="odd-add-controls__event-selection"
    >
      <r-text type="caption">
        Выберите тип объекта
      </r-text>
      <div class="odd-add-controls__event-types">
        <r-button
          mini
          icon="node-layer"
          @click="createObject('points')"
        >
          Точечный
        </r-button>
        <r-button
          mini
          icon="line-layer"
          @click="createObject('lines')"
        >
          Линейный
        </r-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      view: true,
      eventSelection: false
    }
  },
  watch: {
    type(t) {
      this.eventSelection = false
    }
  },
  methods: {
    getButtonText() {
      return this.type === 'signs' ? 'Новый знак' : 'Новое ДТП, перекрытие'
    },
    createObject(type) {
      if (this.type !== 'signs' && !this.eventSelection) {
        this.eventSelection = true
        return
      }
      this.eventSelection = false
      const { ids } = this.$store.state.odd.model

      const editorState = {
        id: null,
        layerId: ids[type],
        type
      }
      this.$store.commit('SET_ODD_FIELD', {
        field: 'cardId',
        value: null
      })

      this.$store.commit('SET_ODD_EDITOR_STATE', editorState)
      this.$router.push('/app/odd/create')
    }
  }
}
</script>

<style lang="scss">
.odd-add-controls {
  &__event-selection {
    display: grid;
    text-align: center;
    grid-gap: 0.5rem;
  }

  &__event-types {
    display: grid;
    grid-gap: 0.5rem;
    grid-auto-flow: column;
  }

  &__button {
    width: 100%;
  }
}
</style>
