<template>
  <div class="ee-editor">
    <div class="ee-editor-container">
      <div
        v-if="state.history.length"
        style="margin: 0; margin-bottom: 16px"
        class="ee-editor__controls"
      >
        <r-button
          class="map-editor__controls-button"
          :loading="state.loading"
          type="success"
          @click="saveData"
        >
          Сохранить изменения
        </r-button>
      </div>

      <mode-switcher
        :state="state"
        @toggle-editor-mode="toggleEditorMode"
      />
      <event-type-select
        v-if="state.mode === 'create'"
        :state="state"
        @toggle-geometry-mode="toggleGeometryMode"
      />

      <editor-history
        style="margin-top: 16px"
        :state="state"
        @change-history-step="changeHistoryStep"
        @clear-editing-state="clearEditingState"
      />
    </div>

    <ee-help :state="state" />
  </div>
</template>

<script>
import ModeSwitcher from './editor/mode-switcher'
import EventTypeSelect from './editor/event-type-select'
import EditorHistory from '@/components/map/components/map-editor/editor-history'
import EeHelp from './editor/help'

export default {
  components: {
    ModeSwitcher,
    EventTypeSelect,
    EditorHistory,
    EeHelp
  },
  props: {
    map: {
      type: Object,
      required: true
    },
    state: {
      type: Object,
      required: true
    },
    controller: {
      type: Object,
      required: true
    }
  },
  methods: {
    toggleEditorMode(mode) {
      this.controller.toggleEditorMode(mode)
    },
    toggleGeometryMode(mode) {
      const value = `draw_${mode}`

      this.controller.changeGeometryMode(value)
    },
    changeHistoryStep(type) {
      this.controller.changeHistoryStep(type)
    },
    clearEditingState() {
      this.controller.clearEditingState()
    },
    saveData() {
      this.controller.saveData()
    }
  }
}
</script>

<style lang="scss">
.ee-editor {
  display: flex;
  flex-direction: column;
  flex: 1;

  &-container {
    padding: 16px;
    flex: 1;
  }

  &__controls {
    .r-button {
      width: 100%;
    }
  }

  .map-editor-mode {
    margin-top: 0;
  }
}
</style>
