const MAP_ICON_SIZE = 64

const getPromisesFromArray = (component, array) =>
  array.map(
    item =>
      new Promise(resolve => {
        const image = new Image(MAP_ICON_SIZE, MAP_ICON_SIZE)
        image.crossOrigin = 'Anonymous'
        image.style.backgroundPosition = '50%, 50%'
        image.style.backgroundSize = '100%'
        image.addEventListener('load', () => {
          if (!component.mapgl.hasImage(item.name)) {
            component.mapgl.addImage(item.name, image)
          }
          resolve()
        })

        image.src = item.url
      })
  )

export const loadIcons = async component => {
  const icons = [
    {
      name: 'event-icon',
      url: require('@/assets/images/odd/event-icon.svg')
    },
    {
      name: 'work-icon',
      url: require('@/assets/images/odd/work-icon.svg')
    },
    {
      name: 'accident-icon',
      url: require('@/assets/images/odd/accident-icon.svg')
    }
  ]

  const promises = getPromisesFromArray(component, icons)

  return Promise.all(promises).then(() => {})
}
