<template>
  <div
    class="map-color-classes"
    @change="classesChangeHandler"
  >
    <div
      v-if="conditions.length"
      class="map-color-classes__add"
    >
      <r-button
        icon="add-plus"
        :disabled="conditions[conditions.length - 1] === 0"
        @click="addClassifition"
      >
        Добавить значение
      </r-button>
    </div>
    <el-row
      v-if="conditions.length"
      type="flex"
      :gutter="8"
    >
      <el-col :span="8">
        <r-text type="caption">
          Цвет
        </r-text>
      </el-col>
      <el-col :span="12">
        <r-text type="caption">
          Значение
        </r-text>
      </el-col>
    </el-row>
    <el-row
      v-for="row in conditions"
      :key="row.id"
      :gutter="8"
    >
      <el-col :span="8">
        <el-color-picker
          v-model="row.style.color"
          class="r-color"
          size="mini"
          popper-class="r-color-picker-dropdown dark"
          color-format="rgb"
          :show-alpha="true"
          @change="classesChangeHandler"
        />
      </el-col>
      <el-col
        class="map-color-classes__op-cell"
        :span="12"
      >
        <span class="map-color-classes__op-text">
          <r-text type="caption">
            {{ getOpText(row.initial) }}
          </r-text>
        </span>
        <el-input-number
          v-model="row.value"
          class="r-input dark"
          size="mini"
          :disabled="row.initial"
          :controls="false"
        />
      </el-col>
      <el-col :span="4">
        <div
          :class="[
            conditions.length < 3 || row.initial ? 'disabled' : '',
            'map-color-classes__delete'
          ]"
          @click="removeClassification(row.id)"
        >
          <r-icon
            name="trash"
            :size="14"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getRandomColor } from '@/utils'

export default {
  props: {
    conditions: {
      type: Array,
      required: true
    }
  },
  methods: {
    getOpText(initial) {
      return initial ? '>' : '<='
    },
    sortClasses() {
      const newConditions = this.conditions.sort((a, b) => {
        if (a.value > b.value) return -1
        if (a.value < b.value) return 1
        if (a.initial) return -1
        if (b.initial) return 1
        return 0
      })
      this.$emit('change-conditions', newConditions)
    },
    setInitialMax() {
      const max = this.conditions.reduceRight((prev, current) =>
        prev.value > current.value || current.initial ? prev : current
      )
      const initial = this.conditions.find(el => el.initial)
      initial.value = max.value
    },
    setPrevInitialMax() {
      this.conditions[1].value = this.conditions[0].value
    },
    sortAndModifyClasses() {
      this.setInitialMax()
      this.sortClasses()
      this.setPrevInitialMax()
    },
    addClassifition() {
      const conditions = this.conditions
      const { value } = conditions[conditions.length - 1]
      if (value === 0) return
      const color = getRandomColor(0.8)
      this.conditions.push({
        value: value - value / 2,
        style: {
          color: color
        },
        id: Date.now()
      })
      this.sortAndModifyClasses()
      this.$emit('apply-classes')
    },
    removeClassification(id) {
      const conditions = this.conditions
      if (conditions.length < 3) return
      if (conditions[0].id === id) return
      const index = conditions.findIndex(c => c.id === id)
      this.conditions.splice(index, 1)
      this.sortAndModifyClasses()
      this.$emit('apply-classes')
    },
    classesChangeHandler() {
      this.sortAndModifyClasses()
      this.$emit('apply-classes')
    }
  }
}
</script>

<style lang="scss">
.map-color-classes {
  margin-top: 16px;
  font-size: 11px;
  padding-left: 10px;

  &__add {
    margin-bottom: 8px;

    .r-button {
      width: 100%;
    }
  }

  .el-row {
    display: flex !important;
    align-items: center !important;

    &:not(:first-child) {
      margin-bottom: 5px;
    }
  }
  .el-col {
    text-align: center;
  }
  .r-input {
    width: calc(100%) !important;

    .el-input__inner {
      height: 38px !important;
      line-height: 38px !important;
      font-size: 12px !important;
    }
  }

  &__op-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3px;
  }

  &__op-text {
    display: block;
    text-align: center;
    width: 30px;
  }

  &__delete {
    display: inline-block;
    width: 24px;
    height: 24px;
    cursor: pointer;

    &.disabled {
      opacity: 0.3 !important;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .r-color {
    .el-color-picker {
      &__trigger {
        width: 64px !important;
        height: 38px !important;
        border-color: var(--field_border) !important;
        background-color: var(--field_bg) !important;
      }

      &__color {
        width: 28px;
        height: 28px;
        border-radius: var(--border-radius);
        border: none;

        &-inner {
          border-radius: var(--border-radius);
        }
      }

      &__icon {
        left: 75%;
        font-size: 18px !important;
      }
    }
  }
}
</style>
