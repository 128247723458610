<template>
  <div class="railway-control__content-header">
    <div class="railway-control__content-header-controls">
      <r-button
        v-if="isEditable"
        type="success"
        :disabled="!changedCars.length"
        :loading="isLoading"
        @click="confirm()"
      >
        {{ $t('button-title') }}
      </r-button>
      <r-button
        v-if="isEditable && changedCars.length"
        simple
        @click="cancelChanges()"
      >
        {{ $t('cancel') }}
      </r-button>
    </div>
    <div
      v-if="!isEditable"
      class="railway-control__content-header__return-button"
    >
      <r-text>Режим просмотра истории распределения.</r-text>
      <r-button
        type="primary"
        @click="returnToCurrentDate()"
      >
        Актуальное состояние
      </r-button>
    </div>
    <railway-control-content-header-calendar />
  </div>
</template>

<script>
import railwayControlContentHeaderCalendar from './railway-control-content-header-calendar'
import { notifyFactory } from '@/utils'

export default {
  components: {
    railwayControlContentHeaderCalendar
  },
  data() {
    return {
      isLoading: false,
      changedObjects: [],
      hasChanges: false
    }
  },
  computed: {
    carsListId() {
      return this.$store.state.railwayControl.carsListId
    },
    carsList() {
      return this.$store.state.railwayControl.carsList
    },
    initialCarsList() {
      return this.$store.state.railwayControl.initialCarsList
    },
    changedCars() {
      return this.$store.state.railwayControl.changedCars
    },
    dateFilter() {
      return this.$store.state.railwayControl.dateFilter || null
    },
    isEditable() {
      const dateFilter = this.$rDate.format(this.dateFilter, 'DD.MM.YYYY')
      const today = this.$rDate.format(new Date(), 'DD.MM.YYYY')

      return dateFilter === today
    }
  },
  methods: {
    async confirm() {
      this.isLoading = true
      try {
        await this.$store.dispatch('PUT_REQUEST', {
          url: 'ZdCarriage',
          data: this.changedCars
        })

        const title = this.$t('edit-notify:title')
        const message = this.$t('edit-notify:text')
        this.$notify(notifyFactory('success', title, message))
        this.$store.commit('RAILWAY_CONTROL_UPDATE_INITIAL_CARS_LIST')
        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.isLoading = false
      }
    },
    cancelChanges() {
      this.$store.commit('RAILWAY_CONTROL_CANCEL_CHANGES')
    },
    returnToCurrentDate() {
      const date = this.$rDate.format(new Date(), 'iso')

      this.$store.commit('RAILWAY_CONTROL_SET_DATE_FILTER', date)
    }
  }
}
</script>

<style lang="scss">
.railway-control__content {
  &-header {
    border-bottom: 1px solid;
    border-left: 1px solid;
    border-color: var(--dividers_low_contrast);
    background-color: var(--bg_panel_primary);
    padding: 6px 1rem;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    display: grid;
    grid-gap: 0.5rem;
    justify-items: start;
    max-height: 50px;
    justify-content: start;

    &-controls {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 0.5rem;

      &:empty {
        display: none;
      }
    }

    &__return-button {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 0.5rem;
      align-items: center;
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "edit-notify:title": "Редактирование",
    "edit-notify:text": "Редактирование выполнено успешно",
    "button-title": "Подтвердить распределение",
    "cancel": "Отменить изменения"
  },
  "en": {
    "edit-notify:title": "Editing",
    "edit-notify:text": "Editing completed successfully",
    "button-title": "Confirm",
    "cancel": "Cancel changes"
  }
}
</i18n>
