export default [
  {
    type: 'input',
    model: 'name',
    width: 8,
    title: 'ФИО пострадавшего'
  },
  {
    type: 'date',
    model: 'birthday',
    format: 'dd.MM.yyyy',
    width: 4,
    title: 'Дата рождения'
  },
  {
    type: 'input',
    model: 'position',
    title: 'Должность'
  },
  {
    type: 'select',
    select: 'contractors',
    filterable: true,
    model: 'organization_id',
    title: 'Организация'
  },
  {
    type: 'input',
    model: 'injured_part',
    title: 'Травмированная часть тела'
  },
  {
    type: 'select',
    select: 'contractors',
    model: 'contractor_id',
    filterable: true,
    title: 'Подрядчик'
  },
  {
    type: 'select',
    select: 'contractors',
    model: 'subcontractor_id',
    filterable: true,
    title: 'Субподрядчик'
  },
  {
    type: 'select',
    select: 'contractors',
    model: 'subsubcontractor_id',
    filterable: true,
    title: 'Субсубподрядчик'
  },
  {
    type: 'checkbox',
    model: 'is_died',
    column: true,
    width: 4,
    title: 'Погиб'
  },
  {
    type: 'checkbox',
    model: 'is_injured',
    column: true,
    width: 4,
    title: 'Ранен'
  },
  {
    type: 'checkbox',
    model: 'is_microtrauma',
    column: true,
    width: 4,
    title: 'Микротравма'
  }
]
