<template>
  <div class="rp-roles-control">
    <el-tabs
      v-model="activeTab"
      class="r-tabs"
      stretch
    >
      <el-tab-pane
        v-for="tab in rolesTabsList"
        :key="tab.id"
        :name="tab.value"
        :disabled="isIbAdminRole || isAdminRole"
      >
        <r-text
          slot="label"
          class="r-tabs__label"
        >
          {{ tab.name }}
        </r-text>
        <component
          :is="`rp-roles-${activeTab}`"
          v-if="!isIbAdminRole && !isAdminRole"
        />
        <rp-roles-tip
          v-if="isIbAdminRole"
          tip="Роль администратора имеет полные права для работы с модулями, компонентами и источникам данных, поэтому вы не можете изменять эти настройки."
        />
        <rp-roles-tip
          v-if="isAdminRole"
          tip="Роль Менеджера имеет полные права для работы с модулями, компонентами и источникам данных, поэтому вы не можете изменять эти настройки."
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import rpRolesModules from './rp-roles-modules/rp-roles-modules'
import rpRolesDatasources from './rp-roles-datasources/rp-roles-datasources'
import rpRolesTip from './rp-roles-tip'
import { rolesTabsList } from '../../configs'

export default {
  components: {
    rpRolesModules,
    rpRolesDatasources,
    rpRolesTip
  },
  data() {
    return {
      activeTab: '',
      rolesTabsList
    }
  },
  computed: {
    activeRoleId() {
      return this.$store.state.rolePermission.activeRoleId
    },
    isIbAdminRole() {
      return this.activeRoleId === 'ib_admin'
    },
    isAdminRole() {
      return this.activeRoleId === 'admin'
    }
  },
  created() {
    this.activeTab = this.rolesTabsList[0]?.value
  }
}
</script>

<style lang="scss">
.rp-roles-control {
  position: relative;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;

  .el-tabs__header {
    width: 400px;
  }

  .el-tabs__item {
    &.is-disabled {
      opacity: 0.4;
    }
  }

  .el-tabs__content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 0;
    overflow: auto;
  }
}
</style>
