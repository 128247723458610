<template>
  <div class="map-legend-container">
    <div class="map-legend-layer-header">
      <div class="map-legend-title">
        {{ item.name }}
      </div>
      <div class="map-legend-count">
        {{ item.count }} ({{ item.allCount }})
      </div>
    </div>
    <div class="map-legend-subtitle">
      {{ item.geom_type }}
    </div>
    <div
      v-if="item.geom_type !== 'Tilelayer'"
      class="map-legend-row"
    >
      <div
        v-if="item.type === 'basic'"
        class="map-legend-color"
        :style="getLegendColor(item)"
      />
      <div
        v-else
        class="map-legend-icon"
      >
        <img
          :src="`${baseURL}/${downloadURL}/${item.icon}`"
          alt="no-data"
        >
      </div>
    </div>
  </div>
</template>

<script>
import constants from '@/constants/url'

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    getLegendColor: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      baseURL: constants.URL,
      downloadURL: 'download_share_resource'
    }
  }
}
</script>

<style></style>
