<template>
  <component
    :is="componentName"
    @close="close"
    @update="update"
  />
</template>

<script>
import potMainModal from './main/modal'
import potNewCargoModal from './new-cargo'

export default {
  components: {
    potMainModal,
    potNewCargoModal
  },
  computed: {
    componentName() {
      return this.$store.state.pot1.modalName || 'potMainModal'
    }
  },
  methods: {
    close() {
      this.$store.commit('CLOSE_MODAL_WINDOW')
    },
    update() {
      this.$store.commit('SET_POT_FIELD', {
        field: 'updateData',
        value: true
      })
    }
  }
}
</script>
