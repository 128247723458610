<template>
  <div class="map-color-strings">
    <div class="map-color-strings__add-row">
      <el-select
        v-model="currentValue"
        class="r-select dark"
        placeholder="Добавьте значение"
        :loading="loading"
        popper-class="r-select__popper dark"
        @change="addValue"
      >
        <el-option
          v-for="item in filteredFieldValues"
          :key="item.value"
          :label="item.value"
          :value="item.value"
        >
          <span
            class="map-color-strings__option"
            style="float: left"
          >
            {{ item.value }}
          </span>
          <span style="float: right; color: #8492a6; font-size: 11px">
            {{ item.count }}
          </span>
        </el-option>
      </el-select>
    </div>
    <div>
      <el-row
        :gutter="8"
        class="map-color-strings__header"
      >
        <el-col :span="8">
          <r-text type="caption">
            Цвет
          </r-text>
        </el-col>
        <el-col :span="12">
          <r-text type="caption">
            Значение
          </r-text>
        </el-col>
        <el-col :span="4" />
      </el-row>
      <el-row
        v-for="row in values"
        :key="row.value"
        :gutter="8"
      >
        <el-col :span="8">
          <el-color-picker
            v-model="row.color"
            class="r-color"
            size="mini"
            popper-class="r-color-picker-dropdown dark"
            color-format="rgb"
            :show-alpha="false"
            @change="changeStrings('values', $event)"
          />
        </el-col>
        <el-col :span="12">
          <r-text>{{ row.value }}</r-text>
        </el-col>
        <el-col :span="4">
          <div
            :class="[
              values.length < 2 ? 'disabled' : '',
              'map-color-classes__delete'
            ]"
            @click="removeValue(row.value)"
          >
            <r-icon
              name="trash"
              :size="14"
            />
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="8">
        <el-col :span="8">
          <el-color-picker
            v-model="defaultColorValue"
            class="r-color"
            size="mini"
            popper-class="r-color-picker-dropdown dark"
            color-format="rgb"
            :show-alpha="true"
            @change="changeStrings('default', $event)"
          />
        </el-col>
        <el-col :span="12">
          <r-text>Другие</r-text>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getRandomColor } from '@/utils'
// import classificationsVue from "./classifications.vue";

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    field: {
      type: String,
      required: true
    },
    default: {
      type: String,
      required: true
    },
    values: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      fieldValues: [],
      loading: false,
      currentValue: '',
      defaultColorValue: ''
    }
  },
  computed: {
    filteredFieldValues() {
      return this.fieldValues.filter(
        ({ value }) => !this.values.find(v => v.value === value)
      )
    }
  },
  created() {
    this.loadFieldValues()
  },
  methods: {
    addValue(value) {
      const color = getRandomColor(0.8)

      this.values.push({
        value,
        color
      })
      this.currentValue = ''
      this.$emit('apply-strings')
    },
    removeValue(value) {
      if (this.values.length < 2) return

      const index = this.values.findIndex(v => v.value === value)
      this.values.splice(index, 1)
      this.$emit('apply-strings')
    },
    changeStrings(type, value) {
      this.$emit('change-strings', { type, value })
      this.$emit('apply-strings')
    },
    async loadFieldValues() {
      const { id, field } = this
      const source_id = this.$store.state.profiles.sourceIdById[id]

      try {
        this.loading = true
        const url = `objectInfo/${source_id}?format=unique_field_count&field=${field}&limit=100`
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        this.fieldValues = data.filter(v => v.count && !!v.value)
        this.defaultColorValue = this.default
        if (!this.values.length) this.addValue(String(data[0].value))
        this.$emit('apply-strings')
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.map-color-strings {
  margin-top: 16px;
  font-size: 12px;

  &__add-row {
    margin-bottom: 8px;

    .r-select {
      width: 100%;

      .el-input__inner {
        font-size: 11px;
        line-height: 28px;
        height: 28px;
      }
      .el-input__icon {
        font-size: 11px;
        line-height: 28px;
      }
    }
  }

  &__header {
    .el-col {
      padding: 8px 0;
      line-height: 20px;
    }
  }
  .el-row {
    &:not(:first-child) {
      margin-bottom: 5px;
    }
  }
  .el-col {
    text-align: center;

    .r-text {
      line-height: 28px;
    }
  }

  &__option {
    max-width: 320px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    margin-right: 24px;
  }

  &__selected-value {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    display: -webkit-box;
    line-height: 18px;
    text-align: left;
  }

  &__delete {
    display: inline-block;
    width: 24px;
    height: 24px;
    cursor: pointer;

    &.disabled {
      opacity: 0.3 !important;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .r-color {
    .el-color-picker {
      &__trigger {
        width: 64px !important;
        height: 38px !important;
        border-color: var(--field_border) !important;
        background-color: var(--field_bg) !important;
      }

      &__color {
        width: 28px;
        height: 28px;
        border-radius: var(--border-radius);
        border: none;

        &-inner {
          border-radius: var(--border-radius);
        }
      }

      &__icon {
        left: 75%;
        font-size: 18px !important;
      }
    }
  }
}
</style>
