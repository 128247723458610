<template>
  <div class="files__file-item">
    <a
      class="files__file-body"
      target="_blank"
      @click="clickHandler('open', file, $event)"
    >
      <r-icon
        class="files__file-icon"
        name="acts-document"
        :size="32"
      />
      <r-text class="files__file-name">{{ file.name }}</r-text>
    </a>
    <div class="files__file-controls">
      <el-tooltip
        :open-delay="1000"
        :content="`${$t('files:tooltips:btn-download')}`"
        placement="top"
      >
        <span
          class="files__file-controls-item"
          @click="clickHandler('download', file, $event)"
        >
          <r-icon
            name="download"
            :size="16"
          />
        </span>
      </el-tooltip>

      <el-tooltip
        :open-delay="1000"
        :content="`${$t('files:tooltips:btn-delete')}`"
        placement="top"
      >
        <span
          class="files__file-controls-item"
          @click="clickHandler('delete', file, $event)"
        >
          <r-icon
            name="trash"
            :size="16"
          />
        </span>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import constants from '@/constants/url'
import { errorParser, getHeaders, getGroupId } from '@/utils'

export default {
  props: {
    file: {
      type: Object,
      required: true
    },
    source_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      url: constants.URL
    }
  },
  methods: {
    async clickHandler(action, item, e) {
      switch (action) {
        case 'open':
          this.openFile(item)
          break
        case 'download':
          this.downloadFile(item)
          break
        case 'delete':
          this.deleteFile(item, e)
          break
      }
    },
    async openFile(item) {
      const url = `${this.url}/download_resource/${item.id}`
      const response = await fetch(url, { headers: getHeaders() })
      const blob = await response.blob()
      const targetBlank = window.open('/')

      targetBlank.onload = () => {
        targetBlank.location = URL.createObjectURL(blob)
      }
    },
    async downloadFile(item) {
      window.open(`${this.url}/download_resource/${item.id}?groupid=${getGroupId()}`)
    },
    async deleteFile(item, e) {
      this.$emit('loading', ['files', true])
      e.stopPropagation()
      e.preventDefault()

      const { id } = item
      const url = `objectResources/${this.source_id}/${id}`

      try {
        await this.$store.dispatch('DELETE_REQUEST', { url })
        this.$emit('load')
      } catch (error) {
        errorParser.call(this, error)
      }
    }
  }
}
</script>

<style lang="scss">
.files-comments {
  .files__file {
    &-item {
      position: relative;
    }

    &-body {
      display: grid;
      width: 100%;
      grid-auto-flow: column;
      grid-template-areas: 'icon name';
      height: 56px;
      border-radius: var(--border-radius);
      grid-template-columns: 50px 1fr;
      justify-content: start;
      align-items: center;
      background-color: var(--bg_containers);
      transition: all ease 0.15s;
      cursor: pointer;
      &:hover {
        background-color: var(--button_secondary_bg_hover);
      }
    }

    &-icon {
      grid-area: icon;
      width: 50px;
    }

    &-name {
      text-align: left;
      grid-area: name;
    }

    &-controls {
      position: absolute;
      display: flex;
      right: 0;
      bottom: 0;
      top: 0;
      height: 100%;
      align-items: center;
      padding: 0 12px;
      z-index: 1;
      cursor: default;

      &-item {
        height: 16px;
        width: 16px;
        border-radius: var(--border-radius);
        cursor: pointer;
        margin-left: 4px;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          background-color: var(--accent_hover);
        }

        &:active {
          background-color: var(--accent_active);
        }
      }
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "files:tooltips:btn-download": "Скачать файл",
    "files:tooltips:btn-delete": "Удалить файл"
  },
  "en": {
    "files:tooltips:btn-download": "Download file",
    "files:tooltips:btn-delete": "Delete file"
  }
}
</i18n>
