<template>
  <div class="mt-telematics">
    <mt-date-filter
      :interval="interval"
      :loading="loadingTelematics"
      :show="showTelematics"
      @load="loadTelematics"
    />
    <r-table-list
      v-if="tableData.length"
      :data="tableData"
      :fields-names="fieldsNames"
      :include-columns="includedColumns"
      :actions="true"
      paginator
    >
      <template v-slot:actions="{ row }">
        <div class="mt-telematics__action-cell">
          <button
            class="mt-telematics__action-cell-button"
            @click="showOnMap(row)"
          >
            <r-icon
              name="focus-zone"
              :size="20"
            />
            <r-text> На карте </r-text>
          </button>
          <button
            class="mt-telematics__action-cell-button"
            @click="openModal(row)"
          >
            <r-icon
              name="info"
              :size="18"
            />
            <r-text> Инфо </r-text>
          </button>
        </div>
      </template>
    </r-table-list>
  </div>
</template>

<script>
import mtDateFilter from '../mt-date-filter/mt-date-filter'
import { notifyFactory } from '@/utils'

export default {
  components: { mtDateFilter },
  props: {
    id: {
      type: String,
      default: null
    },
    info: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tableData: [],
      source: '40c30689-7918-4117-97e4-39222d6751aa',
      loadingTelematics: false,
      showTelematics: false,
      includedColumns: ['time', 'speed', 'coordinates'],
      fieldsNames: {
        time: 'Дата и время',
        speed: 'Скорость',
        coordinates: 'Координаты'
      }
    }
  },
  computed: {
    interval() {
      return this.$store.state.monitoring.eventTime.interval
    }
  },
  watch: {
    id() {
      this.removeTelematics()
    },
    interval() {
      if (!this.showTelematics) return

      this.loadTelematics()
    }
  },
  beforeDestroy() {
    this.removeTelematics()
  },
  methods: {
    removeTelematics() {
      this.showTelematics = false
      this.tableData = []

      this.$store.commit('SET_MT_FIELD', {
        field: 'telematicsData',
        value: null
      })
    },
    showOnMap(row) {
      this.$store.commit('SET_MT_FIELD', {
        field: 'telematicsData',
        value: row
      })
    },
    openModal(value) {
      this.$store.commit('SET_MT_FIELD', {
        field: 'telematicsModal',
        value
      })
      this.$store.commit('OPEN_MODAL_WINDOW', 'telematics-modal')
    },
    async loadTelematics() {
      const { source, $store, interval, info } = this

      if (!info.track_data_id) {
        const title = 'Ошибка'
        const message =
          'ID телематики отсутствует, обратитесь к администратору'
        this.$notify(notifyFactory('warning', title, message))
        return
      }

      this.loadingTelematics = true

      const { from, to } = interval
      const fromUp = new Date(from)
      const fromInit = fromUp.setSeconds(0)
      const toUp = new Date(to)
      const toInit = toUp.setSeconds(59)
      const fromValue = this.$rDate.format(fromInit, 'iso')
      const toValue = this.$rDate.format(toInit, 'iso')

      const config = {
        where: [
          { func: 'st_x', field: 'geom', op: '!=', value: 0 },
          {
            field: 'uid',
            op: '=',
            value: info.track_data_id
          },
          {
            field: 'gps_time',
            op: '>',
            value: fromValue
          },
          {
            field: 'gps_time',
            op: '<',
            value: toValue
          }
        ]
      }

      const url = `objectInfo/${source}?array=true&config=${JSON.stringify(config)}`

      try {
        const { data } = await $store.dispatch('GET_REQUEST', {
          url
        })

        this.tableData = Object.values(data).map(d => ({
          ...d,
          time: this.$rDate.format(d.gps_time, 'DD.MM.YYYY • HH:mm:ss'),
          speed: d.speed,
          coordinates: d.geom.coordinates.join(', '),
          geom: d.geom
        }))

        if (!this.tableData.length) {
          const title = 'Данные по телематике отсутствуют'
          const message =
            'Информация о телематике ТС за указанный период отсутствует'
          this.$notify(notifyFactory('info', title, message))
        }
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loadingTelematics = false
      }
    }
  }
}
</script>

<style lang="scss">
.mt-telematics {
  display: grid;
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: start;
  grid-gap: 0.5rem;
  grid-auto-flow: row;

  &__action-cell {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    justify-content: start;

    &-button {
      border-radius: var(--border-radius);
      border: none;
      background-color: transparent;
      display: grid;
      grid-gap: 0.25rem;
      grid-auto-flow: column;
      cursor: pointer;

      &:hover {
        background-color: var(--accent_hover);
      }
    }
  }
}
</style>
