import {
  CommonSelectors,
  Constants,
  doubleClickZoom,
  moveFeatures
} from '@/libs/map-draw/lib-utils'
import {
  clearAllHelpers,
  getLinkFeatures,
  undraggableTypes,
  nearestPointOnLine
} from '@/libs/map-draw/helpers'

export const createMSimpleSelectMode = (MapboxDraw, map) => {
  const SimpleSelect = {}
  const customState = {
    previousCoords: null
  }

  SimpleSelect.onSetup = function(opts) {
    // turn the opts into state.
    const state = {
      dragMoveLocation: null,
      boxSelectStartLocation: null,
      boxSelectElement: undefined,
      boxSelecting: false,
      canBoxSelect: false,
      dragMoving: false,
      canDragMove: false,
      initiallySelectedFeatureIds: opts.featureIds || []
    }

    this.setSelected(
      state.initiallySelectedFeatureIds.filter(
        id => this.getFeature(id) !== undefined
      )
    )
    this.fireActionable()

    this.setActionableState({
      combineFeatures: true,
      uncombineFeatures: true,
      trash: true
    })

    map.controllers.drawContext = this

    return state
  }

  SimpleSelect.onMouseOut = function(state) {
    // As soon as you mouse leaves the canvas, update the feature
    if (state.dragMoving) return this.fireUpdate()
  }

  SimpleSelect.startOnActiveFeature = function(state, e) {
    const { editorState } = map.$store.state.modelling

    // Stop any already-underway extended interactions
    this.stopExtendedInteractions(state)

    // Disable map.dragPan immediately so it can't start
    this.map.dragPan.disable()

    // Re-render it and enable drag move
    this.doRender(e.featureTarget.properties.id)

    // Set up the state for drag moving
    state.canDragMove = undraggableTypes.indexOf(editorState.geom_type) === -1
    state.dragMoveLocation = e.lngLat
  }

  SimpleSelect.clickOnFeature = function(state, e) {
    const { editorState } = map.$store.state.modelling

    // Stop everything
    doubleClickZoom.disable(this)
    this.stopExtendedInteractions(state)

    const isShiftClick = CommonSelectors.isShiftDown(e)
    const selectedFeatureIds = this.getSelectedIds()
    const featureId = e.featureTarget.properties.id || e.featureTarget.id
    const isFeatureSelected = this.isSelected(featureId)

    customState.previousCoords = e.featureTarget.geometry.coordinates

    clearAllHelpers(this.map)

    // Click (without shift) on any selected feature but a point
    if (
      !isShiftClick &&
      isFeatureSelected &&
      this.getFeature(featureId).type !== Constants.geojsonTypes.POINT
    ) {
      // Enter direct select mode
      return this.changeMode(Constants.modes.DIRECT_SELECT, {
        featureId: featureId
      })
    }

    // Shift-click on a selected feature
    if (isFeatureSelected && isShiftClick) {
      // Deselect it
      this.deselect(featureId)
      this.updateUIClasses({ mouse: Constants.cursors.POINTER })
      if (selectedFeatureIds.length === 1) {
        doubleClickZoom.enable(this)
      }
      // Shift-click on an unselected feature
    } else if (!isFeatureSelected && isShiftClick) {
      // Add it to the selection
      this.select(featureId)
      this.updateUIClasses({ mouse: Constants.cursors.MOVE })
      // Click (without shift) on an unselected feature
    } else if (!isFeatureSelected && !isShiftClick) {
      // Make it the only selected feature
      selectedFeatureIds.forEach(id => this.doRender(id))
      this.setSelected(featureId)
      this.updateUIClasses({ mouse: Constants.cursors.MOVE })
    }

    editorState.activeObject = this.getFeature(featureId)
    editorState.editingCallback = point => {
      this.map.fire(Constants.events.UPDATE, {
        action: 'change_properties',
        features: [point.toGeoJSON()]
      })
      this.clearSelectedFeatures()
      editorState.updateEvents = !editorState.updateEvents
    }

    map.$store.commit('SET_CALC_FIELD', {
      field: 'activeEventId',
      value: featureId
    })

    // No matter what, re-render the clicked feature
    this.doRender(featureId)
  }

  SimpleSelect.onTrash = function() {
    const { editorState } = map.$store.state.modelling

    this.deleteFeature(this.getSelectedIds())
    this.fireActionable()

    editorState.activeObject = null
    editorState.updateEvents = !editorState.updateEvents
    map.$store.commit('SET_CALC_FIELD', {
      field: 'activeEventId',
      value: null
    })
  }

  SimpleSelect.clickAnywhere = function(state) {
    const { editorState } = map.$store.state.modelling

    // Clear the re-render selection
    const wasSelected = this.getSelectedIds()
    if (wasSelected.length) {
      this.clearSelectedFeatures()
      wasSelected.forEach(id => this.doRender(id))
    }
    doubleClickZoom.enable(this)
    this.stopExtendedInteractions(state)

    map.$store.commit('SET_CALC_FIELD', {
      field: 'activeEventId',
      value: null
    })

    editorState.activeObject = null
  }

  SimpleSelect.dragMove = function(state, e) {
    const { editorState } = map.$store.state.modelling

    // Dragging when drag move is enabled
    state.dragMoving = true
    e.originalEvent.stopPropagation()

    let delta
    const selected = this.getSelected()
    const selectedFeature = selected[0]
    const { link_id } = selectedFeature.properties

    // move count points
    const linksFeatures = getLinkFeatures(this.map, editorState, e)
    const filteredLinks = linksFeatures.filter(
      l => l.properties.id === link_id
    )

    if (filteredLinks.length) {
      const link = filteredLinks[0]
      const nearest = nearestPointOnLine(
        [e.lngLat.lng, e.lngLat.lat],
        link.geometry.coordinates
      )
      const { coordinates } = nearest.geometry

      delta = {
        lng: coordinates[0] - state.dragMoveLocation.lng,
        lat: coordinates[1] - state.dragMoveLocation.lat
      }
      moveFeatures(this.getSelected(), delta)
    }

    const newFeature = this.getSelected()
    const newCoords = newFeature[0].coordinates

    customState.previousCoords = newCoords
    state.dragMoveLocation = {
      lng: newCoords[0],
      lat: newCoords[1]
    }
  }

  return { ...MapboxDraw.modes.simple_select, ...SimpleSelect }
}
