<template>
  <div class="mt-main-panel">
    <div class="mt-main-panel__header">
      <div class="mt-main-panel__stats">
        <div class="mt-main-panel__stats-row">
          <div>
            <r-text type="caption">
              Активно объектов на карте
            </r-text>
          </div>
          <div>
            <r-text type="caption">
              {{ vehiclesCount }}
            </r-text>
          </div>
        </div>
        <div class="mt-main-panel__stats-row">
          <div>
            <r-text type="caption">
              Нет геометрии
            </r-text>
          </div>
          <div>
            <r-text type="caption">
              {{ noGeomCount }}
            </r-text>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-main-panel__vehicles">
      <r-button
        icon="list"
        @click="toggleVehicleList"
      >
        Показать список объектов
      </r-button>
    </div>
    <div class="mt-main-panel__filters">
      <filters />
    </div>
  </div>
</template>

<script>
import Filters from '../filters/filters'

export default {
  components: {
    Filters
  },
  computed: {
    isVehicleListOpened: {
      get() {
        return this.$store.state.monitoringTmc.isVehicleListOpened
      },
      set(value) {
        this.$store.commit('MT_TMC_SET_FIELD', {
          field: 'isVehicleListOpened',
          value
        })
      }
    },
    vehiclesCount() {
      return this.$store.state.monitoringTmc.vehiclesCount || 0
    },
    noGeomCount() {
      const vehicle = this.$store.state.monitoringTmc.noGeomVehicles || []

      return vehicle?.length
    }
  },
  methods: {
    toggleVehicleList() {
      this.isVehicleListOpened = !this.isVehicleListOpened
    }
  }
}
</script>

<style lang="scss" scoped>
.mt-main-panel {
  padding: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid transparent;
    border-color: var(--dividers_low_contrast) !important;
    padding-bottom: 8px;
  }

  &__stats {
    width: 100%;

    &-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__vehicles {
    padding: 8px 0;

    .r-button {
      width: 100%;
    }
  }

  &__filters {
    flex: 1;
    overflow: auto;
  }
}
</style>
