import vue from 'vue'

const state = {
  activeTasks: [],
  list: [],
  update: false
}

const mutations = {
  ADD_ACTIVE_TASK(state, task) {
    state.activeTasks.push(task)
  },
  REMOVE_ACTIVE_TASK(state, task_id) {
    state.activeTasks = state.activeTasks.filter(t => t.task_id !== task_id)
  },
  SET_USER_TASKS_LIST(state, tasksList) {
    vue.set(state, 'list', tasksList)
  },
  UPDATE_USER_TASKS_LIST(state, value) {
    vue.set(state, 'update', value)
  }
}

export default {
  state,
  mutations
}
