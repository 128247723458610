<template>
  <div class="odd-router-help">
    <div class="odd-router-help__title">
      <r-text type="caption">
        Последовательно по точкам постройте маршрут на карте
      </r-text>
    </div>
    <div class="odd-router-help__keys">
      <r-title type="subtitle-2">
        Горячие клавиши
      </r-title>
      <ul class="odd-router-help__keys-list">
        <li class="odd-router-help__key">
          <r-text>
            ЛКМ
          </r-text>
          <r-text type="caption">
            Построить маршрут из / в указанную точку
          </r-text>
        </li>
        <li class="odd-router-help__key">
          <r-text>
            CTRL + Z
          </r-text>
          <r-text type="caption">
            Отменить последнюю точку
          </r-text>
        </li>
        <li class="odd-router-help__key">
          <r-text>
            Escape, Backspace
          </r-text>
          <r-text type="caption">
            Отменить создание всего маршрута
          </r-text>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.odd-router-help {
  padding: 8px;

  &__keys {
    margin-top: 16px;

    &-list {
      margin-top: 8px;
    }
  }

  &__key {
    &:not(:first-child) {
      margin-top: 8px;
    }
  }
}
</style>
