<template>
  <div class="railway-control-card-way-control">
    <r-text color-type="subhead">
      {{ $t('way-control') }}
    </r-text>
    <el-select
      :value="info.planned_way_no"
      class="r-select"
      :placeholder="$t('select-way')"
      filterable
      clearable
      size="mini"
      :disabled="disableControl"
      @change="e => changeHandler(e)"
    >
      <el-option
        v-for="item in wayTypes"
        :key="item.id"
        :label="$t(item.title)"
        :value="item.title"
        :disabled="isWayAvailable(item.title)"
      />
    </el-select>
  </div>
</template>

<script>
import { railwaysLayout } from '../railway-control-content/configs'

const wayTypes = [
  {
    id: 1,
    title: '7'
  },
  {
    id: 2,
    title: '6'
  },
  {
    id: 3,
    title: '5'
  },
  {
    id: 4,
    title: '3'
  },
  {
    id: 5,
    title: '1'
  },
  {
    id: 6,
    title: '4'
  }
]

export default {
  props: {
    info: {
      type: Object,
      required: true
    },
    disableControl: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      railwaysLayout,
      wayTypes
    }
  },
  computed: {
    itemsList() {
      return this.$store.state.railwayControl.carsList
    }
  },
  methods: {
    isWayAvailable() {
      return false
    },
    changeHandler(e) {
      this.$store.commit('RAILWAY_CONTROL_UPDATE_CAR_BY_ID', {
        id: this.info.id,
        field: 'planned_way_no',
        value: e
      })
      this.$store.commit('RAILWAY_CONTROL_OBSERV_CHANGES_BY_ID', {
        id: this.info.id
      })
    }
  }
}
</script>

<style lang="scss">
.railway-control-card-way-control {
  position: relative;
  .r-select {
    margin-top: 8px;
    width: 100%;
  }
}
</style>

<i18n>
{
  "ru": {
    "way-control": "Запланировать вагон на путь",
    "select-way": "Выбрать путь"
  },
  "en": {
    "way-control": "Plan car to way",
    "select-way": "Select way"
  }
}
</i18n>
