import { mapColors } from '@/config/colors'
import { calcFields } from './fields'

export const colors = ['rgb(85, 183, 126)', '#f8c348', '#ff7f0b', '#d63838']

const eventsConfig = {
  style: {
    type: 'circle',
    layout: {},
    paint: {
      'circle-radius': 6,
      'circle-color': mapColors.routeColor,
      'circle-opacity': 1,
      'circle-pitch-alignment': 'map',
      'circle-stroke-width': 1,
      'circle-stroke-color': 'rgb(255,255,255)',
      'circle-stroke-opacity': 1
    }
  },
  popup: {
    enabled: true,
    fields: ['event_class_name', 'start_time', 'end_time', 'closed_line_count']
  },
  classesStyle: {
    'circle-color': {
      field: '',
      conditions: [],
      currentPallete: {}
    },
    'circle-radius': {
      field: '',
      fieldMax: 1,
      fieldMin: 0,
      range: [1, 10]
    }
  },
  stringsStyle: {
    'circle-color': {
      field: '',
      defaultValue: 'rgba(255,255,255,1)',
      values: []
    }
  },
  filters: [],
  order: {
    field: null,
    type: 'asc'
  },
  heatmap: {
    enabled: false,
    opacity: 0.8,
    currentPalette: null,
    conditions: [],
    loading: false,
    loaded: false
  },
  hexagon: {
    enabled: false,
    opacity: 0.8,
    radius: 2000,
    coverage: 0.8,
    upperPercentile: 100,
    maxElevation: 1000,
    elevationScale: 200,
    currentPalette: {},
    conditions: [],
    loading: false,
    loaded: false
  },
  cluster: {
    enabled: false,
    loading: false,
    loaded: false
  },
  icon: {
    enabled: false,
    name: '1.5_Russian_road_sign.svg',
    size: 16
  }
}
const nodesConfig = {
  style: {
    type: 'circle',
    layout: {},
    paint: {
      'circle-radius': 3,
      'circle-color': mapColors.baseColor,
      'circle-opacity': 1,
      'circle-pitch-alignment': 'map',
      'circle-stroke-width': 1,
      'circle-stroke-color': 'rgb(255,255,255)',
      'circle-stroke-opacity': 1
    }
  },
  popup: {
    enabled: false,
    fields: []
  },
  classesStyle: {
    'circle-color': {
      field: '',
      conditions: [],
      currentPallete: {}
    },
    'circle-radius': {
      field: '',
      fieldMax: 1,
      fieldMin: 0,
      range: [1, 10]
    }
  },
  stringsStyle: {
    'circle-color': {
      field: '',
      defaultValue: 'rgba(255,255,255,1)',
      values: []
    }
  },
  filters: [],
  order: {
    field: null,
    type: 'asc'
  },
  heatmap: {
    enabled: false,
    opacity: 0.8,
    currentPalette: null,
    conditions: [],
    loading: false,
    loaded: false
  },
  hexagon: {
    enabled: false,
    opacity: 0.8,
    radius: 2000,
    coverage: 0.8,
    upperPercentile: 100,
    maxElevation: 1000,
    elevationScale: 200,
    currentPalette: {},
    conditions: [],
    loading: false,
    loaded: false
  },
  cluster: {
    enabled: false,
    loading: false,
    loaded: false
  },
  icon: {
    enabled: false,
    name: '1.5_Russian_road_sign.svg',
    size: 16
  }
}
const linksConfig = {
  style: {
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': mapColors.baseColor,
      'line-width': 1,
      'line-opacity': 1
    }
  },
  popup: {
    enabled: true,
    fields: [...calcFields]
  },
  classesStyle: {
    'line-color': {
      field: '',
      conditions: [],
      currentPallete: {}
    },
    'line-width': {
      field: '',
      fieldMax: 1,
      fieldMin: 0,
      range: [1, 10]
    }
  },
  stringsStyle: {
    'line-color': {
      field: '',
      defaultValue: 'rgba(255,255,255,1)',
      values: []
    }
  },
  diagram: {
    enabled: true,
    field: 'vehonlink',
    secondField: 'curspeed',
    extruded: false,
    minValue: 0,
    maxValue: 1,
    conditions: [],
    currentPalette: {
      id: 13,
      value: [
        'rgba(26,152,80, 1)',
        'rgba(145,207,96, 1)',
        'rgba(217,239,139, 1)',
        'rgba(254,224,139, 1)',
        'rgba(252,141,89, 1)',
        'rgba(215,48,39, 1)'
      ]
    },
    color: 'rgb(255, 68, 140)',
    opacity: 0.3,
    width: 50,
    timeAggregation: null
  },
  filters: [],
  order: {
    field: null,
    type: 'asc'
  },
  dashed: {
    enabled: false
  },
  arrows: {
    enabled: false
  }
}
const zonesConfig = {
  style: {
    type: 'fill',
    paint: {
      'fill-color': mapColors.baseColor,
      'fill-opacity': 0.6
    }
  },
  borderStyle: {
    paint: {
      'line-color': mapColors.baseColor,
      'line-width': 1,
      'line-opacity': 1
    },
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    }
  },
  popup: {
    enabled: false,
    fields: []
  },
  classesStyle: {
    'fill-color': {
      field: '',
      conditions: [],
      currentPallete: {}
    }
  },
  stringsStyle: {
    'fill-color': {
      field: '',
      defaultValue: 'rgba(255,255,255,1)',
      values: []
    }
  },
  fillExtrusion: {
    paint: {
      'fill-extrusion-height': 500,
      'fill-extrusion-opacity': 1
    },
    enabled: false,
    field: '',
    fieldMin: 0,
    fieldMax: 1
  },
  filters: [],
  order: {
    field: null,
    type: 'asc'
  },
  matrices: {
    enabled: false,
    modelId: null,
    matricesSourceId: null,
    zoneId: null,
    matrixId: null,
    count: 30,
    min: 0,
    max: 1,
    strokeWidth: 8,
    sourceColor: 'rgba(50,136,189, 1)',
    targetColor: 'rgba(49,163,84, 1)'
  }
}

export const layerStyles = {
  nodes: nodesConfig,
  events: eventsConfig,
  links: linksConfig,
  zones: zonesConfig
}

export const baseLayersStyles = {
  nodes: {
    type: 'circle',
    layout: {},
    paint: {
      'circle-radius': 2,
      'circle-color': mapColors.baseColor,
      'circle-opacity': 1,
      'circle-pitch-alignment': 'map'
    }
  },
  links: {
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': mapColors.baseColor,
      'line-width': 1,
      'line-opacity': 1
    }
  }
}
