<template>
  <el-collapse class="r-collapse style-collapse bordered">
    <tasks-modal-list-item
      v-for="(task, i) in tasks"
      :key="task.task_id + i"
      :task="task"
      :name="i"
    />
  </el-collapse>
</template>

<script>
import tasksModalListItem from './tasks-modal-list-item'

export default {
  components: { tasksModalListItem },
  props: {
    tasks: {
      type: Array,
      required: true,
      default: () => []
    }
  }
}
</script>

<style lang="scss">
.tasks-modal {
  .r-collapse {
    padding-right: 4px;
    .el-collapse-item {
      padding: 4px;
      border-bottom: 1px solid !important;
      margin-bottom: 4px;
      border-color: var(--dividers_low_contrast) !important;
      &:last-child {
        margin-bottom: 0;
      }
      &:first-child {
        border-top: 1px solid !important;
      }
      &__header {
        margin-bottom: 4px;
      }
    }
  }
}
</style>
