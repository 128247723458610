<template>
  <div class="an-report-info">
    <div class="an-report-info-col">
      <r-text type="caption">
        Дата
      </r-text>
      <r-text>
        {{ date }}
      </r-text>
    </div>
    <div
      v-for="service in servicesData"
      :key="service.id"
      class="an-report-info-col"
    >
      <r-text
        v-if="reportName !== 'bdd_report'"
        type="caption"
      >
        {{ service.name }}
      </r-text>
      <r-text v-if="reportName !== 'bdd_report'">
        {{ getServiceValueName(service) }}
      </r-text>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    reportName() {
      return this.$store.state.analytics.reportName || null
    },
    datetime() {
      return this.$store.state.analytics.selectedDate || null
    },
    date() {
      if (this.datetime?.from) {
        return this.$rDate.format(this.datetime.from, 'MMMM YYYY')
      }
      if (this.datetime?.date) {
        return this.$rDate.format(this.datetime.date, 'DD-MM-YYYY')
      }
      if (this.datetime?.from_dt && this.datetime?.to_dt) {
        const { from_dt, to_dt } = this.datetime
        const dateFrom = this.$rDate.format(decodeURIComponent(from_dt))
        const dateTo = this.$rDate.format(decodeURIComponent(to_dt))

        return `с ${dateFrom} - по ${dateTo}`
      }

      return '-'
    },
    servicesData() {
      if (!this.$store.state.analytics.servicesData) return {}

      return this.$store.state.analytics.servicesData || {}
    }
  },
  methods: {
    getServiceValueName(service) {
      if (service.key) {
        return service.value
      } else {
        return (
          this.servicesData[service.name]?.data[service.value]?.[
            service.serviceField
          ] || '-'
        )
      }
    }
  }
}
</script>

<style lang="scss">
.an-report-info {
  position: relative;
  display: flex;
  padding: 0 8px;
  margin-left: 8px;

  > * {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
