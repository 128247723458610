<template>
  <div class="accident-info">
    <attributes-list
      :model="model"
      :source="source.trafficaccident"
      :related="related"
      is-editing
      @hasChanges="$emit('hasChanges')"
    />
  </div>
</template>

<script>
import attributesList from '@/components/globals/r-modal-elements/attributes-list'

export default {
  components: {
    attributesList
  },
  props: {
    model: {
      type: Array,
      required: true
    },
    related: {
      type: Object,
      required: true
    },
    source: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.accident-info {
  .el-select-dropdown__list {
    max-width: 608px;
  }

  .r-modal-card__content--columns {
    overflow-y: hidden;
  }
}
</style>
