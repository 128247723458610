<template>
  <div
    v-if="!!schedule.length"
    class="pt-route-schedule__setup"
  >
    <table
      v-if="schedule.length"
      class="pt-route-schedule__setup-table schedule-setup-table"
      summary="Информация о рейсе"
    >
      <thead>
        <tr class="schedule-setup-table__row">
          <th
            scope="col"
            class="schedule-setup-table__title start"
            style="width: 50%"
          >
            <r-title type="subtitle-2">
              Остановка
            </r-title>
          </th>
          <th
            scope="col"
            class="schedule-setup-table__title"
          >
            <r-title type="subtitle-2">
              Прибытие
            </r-title>
          </th>
          <th
            scope="col"
            class="schedule-setup-table__title"
          >
            <r-title type="subtitle-2">
              Стоянка
            </r-title>
          </th>
          <th
            scope="col"
            class="schedule-setup-table__title"
          >
            <r-title type="subtitle-2">
              Отправление
            </r-title>
          </th>
        </tr>
      </thead>
      <tbody class="schedule-setup-table__body">
        <tr
          v-for="(stop, i) in currentJorneyStopPoints"
          :key="stop.id + i"
          class="schedule-setup-table__row"
        >
          <td class="schedule-setup-table__stop-name start">
            <r-title type="subtitle-1">
              {{ stop.name || `Остановка № ${stop.no}` }}
            </r-title>
          </td>
          <td class="center">
            <r-no-data v-if="i === 0" />
            <el-time-picker
              v-else
              v-model="stop.range[0]"
              format="HH:mm"
              value-format="HH:mm"
              class="schedule-setup-table__input r-time-picker"
              :picker-options="getSelectableRange(i, 'arr')"
              popper-class="build-time-popper"
              prefix-icon="none"
              :clearable="false"
              :disabled="isDisabledIndexRange(i)"
              @change="$emit('fixChanges')"
            />
          </td>
          <td class="schedule-setup-table__stop">
            <r-text>{{ getSeparatorByRange(stop.range, i) }}</r-text>
          </td>
          <td class="center">
            <r-no-data v-if="i === currentJorneyStopPoints.length - 1" />
            <el-time-picker
              v-else
              v-model="stop.range[1]"
              format="HH:mm"
              value-format="HH:mm"
              class="schedule-setup-table__input r-time-picker"
              :picker-options="getSelectableRange(i, 'dep')"
              popper-class="build-time-popper"
              prefix-icon="none"
              :clearable="false"
              :disabled="isDisabledIndexRange(i)"
              @change="$emit('fixChanges')"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { isString, isNumber, subtractTime } from '@/utils'

export default {
  props: {
    checkedIds: {
      type: Array,
      required: true
    },
    schedule: {
      type: Array,
      required: true
    },
    stops: {
      type: Array,
      required: true
    },
    current: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isNumber,
      isString,
      checkedItems: [],
      isIndeterminate: true,
      checkAll: false
    }
  },
  computed: {
    currentJorney() {
      return this.schedule[this.current]
    },
    currentJorneyStopPoints() {
      return this.schedule[this.current].stopPoints
    },
    isNew() {
      return this.isNumber(this.schedule[this.current].id)
    }
  },
  watch: {
    checkedItems() {
      this.$emit('new', ['checkedIds', this.checkedItems])
    },
    checkedIds() {
      this.checkedItems = this.checkedIds
    }
  },
  methods: {
    getSeparatorByRange(range, i) {
      const [from, to] = range
      const isLast = i === this.currentJorneyStopPoints.length - 1
      const isNew = this.isNumber(this.schedule[this.current].id)
      return isNew || isLast ? '—' : subtractTime(from, to)
    },
    isDisabledIndexRange(i) {
      const { id } = this.currentJorney
      return (this.isNumber(id) && i !== 0) || (this.isString(id) && i === 0)
    },
    getSelectableRange(i, type) {
      const isNew = this.isNumber(this.schedule[this.current].id)
      const isLast = i === this.currentJorneyStopPoints.length - 1

      if (isNew) {
        return {}
      }

      const list = this.currentJorneyStopPoints
      const isArrive = type === 'arr'
      const prevRowIndex = i - 1 >= 0 ? i - 1 : 0
      const nextRowIndex = i + 1 >= list.length ? i : i + 1
      const invertedIndex = isArrive ? 1 : 0
      const from = list[isArrive ? prevRowIndex : i].range[invertedIndex]
      const to = list[isArrive ? i : nextRowIndex].range[invertedIndex]

      if (isLast) {
        return from ? { selectableRange: `${from}:00 - 23:59:59` } : {}
      } else {
        return from && to ? { selectableRange: `${from}:00 - ${to}:00` } : {}
      }
    }
  }
}
</script>

<style lang="scss">
.pt-route-schedule {
  &__setup {
    display: flex;
    grid-area: setup;
    width: 100%;
    justify-content: space-between;

    .schedule-setup-table {
      border-collapse: collapse;
      width: 100% !important;

      &__title {
        text-align: center;
        display: grid;
        justify-content: center;
        grid-gap: 0.5rem;
        align-items: center;
        padding: 0.5rem;
        position: sticky;
        top: 0;
        background-color: var(--modal_bg);
        z-index: 2;
      }

      &__body {
        overflow-y: scroll;
        border-top: 1px solid var(--dividers_low_contrast);
        display: grid;
        grid-gap: 0.5rem;
        padding-top: 0.5rem;
      }

      .start {
        justify-content: start;
      }

      &__row {
        width: 100%;
        grid-template-columns: auto 150px 75px 150px;
        grid-gap: 1rem;
        display: grid;
        overflow: hidden;

        td {
          height: 2.5rem;
          padding: 0.5rem 0;
          z-index: 1;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
        }
      }

      &__stop {
        &-name {
          min-width: 200px;
          display: grid !important;
          align-items: center;
          grid-template-columns: max-content minmax(10px, 90%);
          grid-gap: 1rem;
          text-align: left !important;
          width: 100%;

          &::after {
            content: '';
            border-bottom: 1px dashed var(--dividers_low_contrast);
            width: 100%;
            justify-self: end;
            right: 0;
          }
        }
      }

      &__input {
        .el-input__inner {
          & + span + span {
            overflow: hidden;
            position: absolute;
            top: 9px;
            right: 1rem;
            border: solid var(--dividers_low_contrast);
            border-width: 0 2px 2px 0;
            height: 9px;
            width: 9px;
            transform: scale(1, 0.75) rotate(45deg);
            transform-origin: 50% 100%;
          }

          &:focus {
            & + span + span {
              transform: scale(1, -0.75) rotate(45deg);
            }
          }
        }
      }
    }
  }
}
</style>
