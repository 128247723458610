<template>
  <pot-1-dispatcher :is-dispatcher="false" />
</template>

<script>
import Pot1Dispatcher from '@/components/pot-1/pot-1'

export default {
  components: {
    Pot1Dispatcher
  }
}
</script>
