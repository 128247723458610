<template>
  <div class="r-slider">
    <r-text
      v-if="title"
      color-type="subhead"
      style="margin-bottom: 8px"
    >
      {{ title }}
    </r-text>
    <div
      :class="['r-slider__wrapper', { noInput }]"
    >
      <el-slider
        v-model="value"
        :show-tooltip="false"
        class="r-slider__slider"
        :min="min"
        :max="max"
        :step="step"
      />
      <el-input-number
        v-if="!noInput"
        v-model="value"
        :controls="false"
        class="r-slider__input"
        size="mini"
        type="number"
      />
    </div>
  </div>
</template>

<script>
import throttle from 'lodash.throttle'

export default {
  props: {
    title: {
      type: String,
      default: null
    },
    number: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 9
    },
    step: {
      type: Number,
      default: 1
    },
    noInput: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: this.number
    }
  },
  watch: {
    value(val) {
      this.updateInput(val)
    },
    number(val) {
      this.value = val
    }
  },
  methods: {
    updateInput: throttle(function(val) {
      this.$emit('change', val)
    }, 100)
  }
}
</script>

<style lang="scss">
.r-slider {
  position: relative;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.noInput {
      .r-slider {
        &__slider {
          width: 100%;
        }
      }
    }
  }

  &__slider {
    padding-left: 4px;
    width: calc(100% - 72px);

    .el-slider__button {
      width: 18px !important;
      height: 18px !important;
      border: none !important;

      &.el-tooltip {
        background-color: #e0e0e0 !important;
      }

      &-wrapper {
        width: 32px;
        height: 32px;
      }
    }
  }

  &__input {
    width: 56px !important;

    .el-input__inner {
      height: 36px !important;
      padding: 8px !important;
      font-size: 14px;
    }
  }

  .el-slider {
    &__runway {
      height: 2px !important;
      border-radius: 1px;
      background-color: var(--dividers_high_contrast) !important;
    }

    &__bar {
      height: 2px;
      border-radius: 1px;
      background-color: var(--accent_primary) !important;
    }
  }
}
</style>
