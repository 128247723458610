<template>
  <div class="map-legend-container">
    <div class="map-legend-layer-header">
      <div class="map-legend-title">
        {{ item.name }}
      </div>
      <div class="map-legend-count">
        {{ item.count }} ({{ item.allCount }})
      </div>
    </div>
    <div class="map-legend-subtitle">
      {{ item.geom_type }}
    </div>
    <div class="map-legend-table">
      <div class="map-legend-table-header">
        классификация по <strong>{{ item.field }}</strong>
      </div>
      <div class="map-legend-table-block">
        <div
          v-for="field in item.conditions"
          :key="field.id"
          class="map-legend-row"
        >
          <div
            class="map-legend-color"
            :style="getLegendColor(field)"
          />
          <div class="map-legend-text">
            {{ field.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    getLegendColor: {
      type: Function,
      required: true
    }
  }
}
</script>

<style></style>
