<template>
  <div class="public-transport">
    <pt-router
      v-if="routeName === 'pt-router'"
      class="public-transport__panel"
    />
    <div
      v-if="routeName === 'pt-view'"
      class="public-transport__panel"
    >
      <pt-view />
    </div>
    <div class="public-transport__main">
      <pt-router-button v-if="$route.name !== 'pt-editor'" />
      <pt-editor v-if="routeName === 'pt-editor'" />
      <pt-map />
      <pt-card v-if="cardId && $route.name !== 'pt-router'" />
    </div>
  </div>
</template>

<script>
import PtView from './panels/view'
import PtEditor from './panels/editor'
import PtRouter from './panels/router'
import PtRouterButton from '@/components/public-transport/router/router-button'
import PtMap from '@/components/public-transport/map/map'
import PtCard from '@/components/public-transport/card/card'

export default {
  components: {
    PtView,
    PtEditor,
    PtRouter,
    PtRouterButton,
    PtMap,
    PtCard
  },
  computed: {
    cardId() {
      return this.$store.state.publicTransport.cardId
    },
    routeName() {
      return this.$route.name
    }
  },
  created() {
    if (this.routeName === 'pt-editor') {
      this.$router.push('/app/public-transport')
    }
  }
}
</script>

<style lang="scss">
.public-transport {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  background-color: var(--bg_surface) !important;

  &__panel {
    z-index: 10;
  }

  &__main {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
  }
}
</style>
