<template>
  <r-toggle-card
    :title="filter.field || 'Фильтр'"
    :is-open="filter.enabled"
    @toggle="toggleFilter"
  >
    <div class="map-filter-item">
      <div class="map-filter-item__container">
        <div class="map-filter-item__block">
          <r-text
            v-if="!filter.field"
            type="caption"
            style="margin-bottom: 4px"
          >
            Выберите атрибут
          </r-text>
          <div class="map-filter-item__attribute-select">
            <simple-attributes
              :id="id"
              field="field"
              :settings="filter"
              :filter-id="filter.id"
              type="number-time-string-bool"
              :change-handler="changeFilterField"
              :multiple="false"
            />
            <el-tooltip
              :open-delay="1000"
              effect="dark"
              content="Удалить фильтр"
              placement="top"
            >
              <r-delete-button
                mini
                simple
                @delete="removeFilter(filter)"
              />
            </el-tooltip>
          </div>
        </div>

        <div
          v-if="filter.field"
          class="map-filter-item__block"
        >
          <div class="map-filter-item__slider">
            <div class="map-filter-item__slider-title">
              <r-text type="caption">
                Значение фильтра
                <span
                  v-if="filter.loading"
                  class="el-icon-loading"
                />
              </r-text>
            </div>
            <div
              v-if="
                filter.fieldType === 'string' || filter.fieldType === 'boolean'
              "
              class="map-filter-item__value-container"
            >
              <div class="map-filter-item__strings-values">
                <el-select
                  v-model="filter.values"
                  class="r-select dark"
                  placeholder="Выберите значения"
                  multiple
                  clearable
                  popper-class="r-select__popper dark"
                  collapse-tags
                  @change="changeFilter"
                >
                  <el-option
                    v-for="item in filter.allValues"
                    :key="item.value"
                    :label="String(item.value)"
                    :value="item.value"
                    class="map-filter-item__option"
                  >
                    <span style="float: left">{{ item.value }}</span>
                    <span style="float: right; color: #8492a6; font-size: 11px">
                      {{ item.count }}
                    </span>
                  </el-option>
                </el-select>
              </div>
              <div class="map-filter-item__operation">
                <r-text type="caption">
                  Тип операции
                </r-text>
                <el-select
                  v-model="filter.operation"
                  class="r-select dark"
                  popper-class="r-select__popper dark"
                  @change="changeFilter"
                >
                  <el-option
                    v-for="item in operations"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </div>
            </div>
            <div
              v-else
              class="map-filter-item__value-container"
            >
              <distribution-diagram
                :slider="filter.range"
                :max="filter.max"
                :min="filter.min"
                :all-count="filter.allCount"
                :chunks="filter.chunks"
              />
              <div class="map-filter-item__slider-block full-width">
                <el-slider
                  ref="filterSlider"
                  :value="filter.range"
                  class="r-slider dark square"
                  range
                  :min="filter.min"
                  :max="filter.max"
                  :step="1"
                  :format-tooltip="
                    filter.fieldType === 'number'
                      ? parseTooltipValue
                      : parseTooltipTimeValue
                  "
                  @input="sliderChange"
                  @change="changeFilter(filter.filterId)"
                />
              </div>
              <div
                v-if="filter.fieldType === 'number'"
                class="map-filter-item__slider-inputs"
              >
                <el-input-number
                  v-model="filter.range[0]"
                  :controls="false"
                  class="r-input dark"
                  size="mini"
                  type="number"
                  @change="changeFilter(filter.id)"
                />
                <r-text type="caption">
                  -
                </r-text>
                <el-input-number
                  v-model="filter.range[1]"
                  :controls="false"
                  class="r-input dark"
                  size="mini"
                  type="number"
                  @change="changeFilter(filter.id)"
                />
              </div>
              <div
                v-else
                class="map-filter-item__slider-time-row"
              >
                <div class="map-filter-item__slider-time-item">
                  <r-text type="caption">
                    {{ parseTimeValue(filter.min) }}
                  </r-text>
                </div>
                <div class="map-filter-item__slider-time-item">
                  <r-text type="caption">
                    {{ parseTimeValue(filter.max) }}
                  </r-text>
                </div>
              </div>
              <r-block
                v-if="filter.fieldType.includes('date')"
                no-padding
              >
                <el-date-picker
                  v-model="dateMin"
                  class="r-date-picker"
                  type="datetime"
                  format="dd.MM.yyyy HH:mm"
                  :clearable="false"
                  :picker-options="fromPickerOptions"
                  @change="changeFilter"
                />
                <el-date-picker
                  v-model="dateMax"
                  class="r-date-picker"
                  type="datetime"
                  format="dd.MM.yyyy HH:mm"
                  :clearable="false"
                  :picker-options="toPickerOptions"
                  @change="changeFilter"
                />
              </r-block>
            </div>
          </div>
        </div>
      </div>
    </div>
  </r-toggle-card>
</template>

<script>
import debounce from 'lodash.debounce'
import DistributionDiagram from '../../distribution-diagram/distribution-diagram'
import SimpleAttributes from '../../simple-attributes/simple-attributes'

const operations = [
  { value: 'in', label: 'Равно' },
  { value: '!in', label: 'Не равно' }
]

export default {
  components: {
    SimpleAttributes,
    DistributionDiagram
  },
  props: {
    id: {
      type: String,
      required: true
    },
    filter: {
      type: Object,
      required: true
    },
    controllers: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      fromPickerOptions: {
        firstDayOfWeek: 1,
        disabledDate: this.disableFrom
      },
      toPickerOptions: {
        firstDayOfWeek: 1,
        disabledDate: this.disableTo
      },
      operations
    }
  },
  computed: {
    dateMin: {
      get() {
        return this.filter.range[0]
      },
      set(value) {
        this.$set(this.filter.range, 0, this.$rDate.format(value, 'x'))
        this.$refs.filterSlider.setValues(this.filter.range)
      }
    },
    dateMax: {
      get() {
        return this.filter.range[1]
      },
      set(value) {
        this.$set(this.filter.range, 1, this.$rDate.format(value, 'x'))
        this.$refs.filterSlider.setValues(this.filter.range)
      }
    }
  },
  methods: {
    disableFrom(date) {
      const dateFrom = new Date(this.filter.min)
      let dateTo = new Date(this.filter.max)

      if (this.filter.range?.[1]) {
        dateTo = new Date(this.filter.range[1])
      }

      return date < dateFrom || date > dateTo
    },
    disableTo(date) {
      let dateFrom = new Date(this.filter.min)
      const dateTo = new Date(this.filter.max)

      if (this.filter.range?.[0]) {
        dateFrom = new Date(this.filter.range[0])
      }

      return date < dateFrom || date > dateTo
    },
    removeFilter(filter) {
      const { field } = filter
      this.$emit('remove-filter', filter)
      if (field) this.changeFilter()
    },
    toggleFilter(value) {
      this.filter.enabled = value

      this.changeFilter()
    },
    changeFilter() {
      this.controllers.layers.handlers[this.id]('filters')
      this.controllers.layers.updateHeatmapLayer(this.id)
    },
    async changeFilterField(id, value) {
      if (value) {
        this.controllers.layers.changeFilterField(this.filter, id, value)
      }
      this.changeFilter()
    },
    parseTimeValue(value) {
      return this.$rDate.format(value, 'DD.MM.YYYY')
    },
    parseTooltipTimeValue(value) {
      return this.$rDate.format(value)
    },
    parseTooltipValue(value) {
      return value
    },
    sliderChange: debounce(function(value) {
      this.filter.range = value
    }, 128)
  }
}
</script>

<style lang="scss">
.map-filter-item {
  &:not(:first-child) {
    margin-top: 20px;
  }

  &__subheader {
    background-color: var(--field_bg) !important;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-left: 3px solid;
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    border-left-color: var(--dividers_low_contrast) !important;

    &.datetime {
      border-color: #ff4c8d;
    }
    &.number {
      border-color: #1e98f0;
    }
    &.string {
      border-color: #f8c348;
    }
    &.boolean {
      border-color: #00b65c;
    }

    &-title {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 5px;

      .small {
        font-weight: normal;
      }
    }
    &-subtitle {
      font-style: italic;
      font-size: 11px;
    }

    &-actions {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
    }

    &-close {
      width: 24px;
      height: 24px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__attribute-select {
    display: grid;
    grid-gap: 1rem;
    grid-auto-flow: column;
    align-items: center;
  }

  &__block:not(:first-child) {
    margin-top: 10px;
  }
  &__title {
    margin-bottom: 5px;
  }

  .r-select.el-select {
    width: 100%;
  }

  &__strings-values {
    margin-top: 5px;

    .el-tag {
      display: flex;
      align-items: center;
    }

    .el-select__tags-text {
      max-width: 68px;
      text-overflow: ellipsis;
      display: inline-block;
      overflow: hidden;
    }
  }

  &__option {
    &-text {
      max-width: 320px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
      margin-right: 24px;
    }

    &.selected {
      .map-filter-item__option-count {
        color: #fff !important;
      }
    }

    &:after {
      display: none;
    }
  }

  &__operation {
    margin-top: 16px;

    .r-text {
      margin-bottom: 4px;
    }

    .r-select {
      width: 50%;
      .el-input__inner {
        font-size: 11px;
        line-height: 28px;
        height: 28px;
      }
      .el-input__icon {
        line-height: 28px;
        font-size: 11px !important;
      }
    }
  }

  &__slider {
    position: relative;

    &.top {
      margin-top: 10px;
    }

    &-title {
      vertical-align: middle;
    }

    &-block {
      padding: 0 10px !important;
      width: 75%;

      &.full-width {
        width: 100%;
      }
    }
    &-container {
      display: flex;
      align-items: center;
    }
    .r-input {
      width: 30% !important;
    }

    &-inputs {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .r-input {
        width: 45% !important;
        .el-input__inner {
          height: 24px;
          line-height: 24px;
        }
      }
    }
    &-time {
      &-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &-item {
        font-size: 10px;
        color: var(--white_overlay);
      }
    }
  }
}
</style>
