<template>
  <div class="distribution-diagram">
    <svg
      :width="svgWidth"
      :height="svgHeight"
    >
      <rect
        v-for="(chunk, i) in chunks"
        :key="chunk.num"
        :width="`${getWidth()}%`"
        :x="`${getXposition(i)}%`"
        :y="`${getYposition(chunk.count)}%`"
        :height="`${getHeight(chunk.count)}%`"
        :fill="getFill(chunk.num)"
        stroke-width="0"
      />
      <rect
        :width="`${getOverflowWidth()}%`"
        height="100%"
        :fill="getOverflowColor"
        :x="`${getOverflowX()}%`"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    slider: {
      type: Array,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    min: {
      type: Number,
      required: true
    },
    allCount: {
      type: Number,
      required: true
    },
    chunks: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      svgHeight: 50,
      svgWidth: 200
    }
  },
  computed: {
    getOverflowColor() {
      return this.$store.state.theme === 'dark-theme'
        ? 'rgba(238, 238, 238, 0.05)'
        : 'rgba(0, 0, 0, 0.05)'
    },
    getOtherColor() {
      return this.$store.state.theme === 'dark-theme' ? '#424555' : '#c1c1c4'
    }
  },
  methods: {
    getWidth() {
      const width =
        (this.svgWidth / (this.chunks.length * 2 - 1) / this.svgWidth) * 100
      return isFinite(width) ? width : 0
    },
    getXposition(index) {
      if (index === 0) return 0
      return this.getWidth() * 2 * index || 0
    },
    getYposition(count) {
      const height = (count / this.allCount) * this.svgHeight * 5
      return ((this.svgHeight - height) / this.svgHeight) * 100
    },
    getHeight(count) {
      return (count / this.allCount) * 500
    },
    getFill(num) {
      const percent = (num / this.chunks.length) * 100
      const delta = this.getWidth() / 2
      const minSliderPercent =
        ((this.slider[0] - this.min) / (this.max - this.min)) * 100 + delta
      const maxSliderPercent =
        ((this.slider[1] - this.min) / (this.max - this.min)) * 100 + delta

      return percent >= minSliderPercent && percent <= maxSliderPercent
        ? '#3A8FD2'
        : this.getOtherColor
    },
    getOverflowWidth() {
      return (
        ((this.slider[1] - this.slider[0]) / (this.max - this.min)) * 100 || 0
      )
    },
    getOverflowX() {
      return ((this.slider[0] - this.min) / (this.max - this.min)) * 100 || 0
    }
  }
}
</script>

<style lang="scss">
.distribution-diagram {
  margin-top: 10px;
  margin-left: 10px;
}
</style>
