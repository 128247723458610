<template>
  <simple-button
    :mapgl="mapgl"
    :module="module"
    :layer-id="id"
    icon="ksodd-map"
    title="Геозоны"
    :add-handler="addHandler"
    :layers-ids="layersIds"
  />
</template>

<script>
import mapboxgl from 'mapbox-gl'
import { mapColors } from '@/config/colors'
import { getFirstSymbolId, jsonToGeojson } from '@/utils'
import SimpleButton from '../buttons/simple-button'

export default {
  components: {
    SimpleButton
  },
  props: {
    mapgl: {
      type: Object,
      required: true
    },
    module: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      id: 'bfbc88aa-6085-4a9e-9be2-c288c579c494',
      layersIds: []
    }
  },
  methods: {
    async addHandler() {
      const { id } = this

      try {
        const url = `objectInfoBodyConfig/${id}`
        const data = {
          config: { only: ['id', 'geom', 'name'] }
        }

        const response = await this.$store.dispatch('POST_REQUEST', {
          url,
          data
        })
        const geojson = jsonToGeojson(Object.values(response.data)?.filter(
          e => e.geom)
        )

        const source = this.mapgl.getSource(id)

        if (!source) {
          this.mapgl.addSource(id, {
            type: 'geojson',
            data: geojson
          })
          this.addLayers(id)
        } else {
          source.setData(geojson)
        }

        this.layersIds = [id, `${id}_border`]
        this.addLayerHandlers(id)
      } catch (error) {
        console.log(error)
      }
    },
    addLayerHandlers(layer) {
      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        offset: 15,
        anchor: 'left',
        className: 'info-layer__custom-popup'
      })

      this.mapgl.on('mousemove', layer, e => {
        this.mapgl.getCanvas().style.cursor = ''
        popup.remove()
        this.addPopup(e, popup)
        this.mapgl.getCanvas().style.cursor = 'pointer'
      })

      this.mapgl.on('mouseleave', layer, _ => {
        this.mapgl.getCanvas().style.cursor = ''
        popup.remove()
      })
    },
    addPopup(e, popup) {
      const html = e.features?.map(f => {
        const { properties } = f
        const { name } = properties

        const project = '-'

        const item = '<dl><dt>Название: </dt><dd>' + name + '</dd><dt>Проект: </dt><dd>' + project + '</dd></dl>'

        return item
      })?.join('<hr size="1" noshade>')

      popup
        .setLngLat(e.lngLat)
        .setHTML(html)
        .addTo(this.mapgl)
    },
    addLayers(id) {
      const index = getFirstSymbolId(this.mapgl)

      this.mapgl.addLayer(
        {
          id,
          source: id,
          type: 'fill',
          paint: {
            'fill-color': mapColors.baseColor,
            'fill-opacity': 0.2
          }
        },
        index
      )

      this.mapgl.addLayer(
        {
          id: `${id}_border`,
          source: id,
          type: 'line',
          paint: {
            'line-color': mapColors.baseColor,
            'line-width': 1
          }
        },
        index
      )
    }
  }
}
</script>

<style lang='scss'>
.info-layer__custom-popup {
  z-index: 99;
}
</style>
