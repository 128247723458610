<template>
  <div :class="[`railway-control__list-item-status ${getColorType()}`]">
    <r-text
      type="caption"
      :color-type="getColorType()"
    >
      {{ $t(getCarStatusName()) }}
    </r-text>
  </div>
</template>

<script>
import { getStatusNameById, statusDecryption } from '../../configs'

export default {
  props: {
    carStatus: {
      type: String,
      required: true
    }
  },
  methods: {
    getColorType() {
      const carStatusName = getStatusNameById(this, this.carStatus) || 'default'

      return statusDecryption[carStatusName]?.color
    },
    getCarStatusName() {
      const carStatusName = getStatusNameById(this, this.carStatus)

      switch (carStatusName) {
        case '':
        case null:
        case 'На сортировке':
          return 'На сортировке'
        default:
          return carStatusName
      }
    }
  }
}
</script>

<style lang="scss">
.railway-control__list-item-status {
  padding-left: 12px;
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 6px;
    width: 6px;
    border: 1px solid #fff;
    border-radius: 50%;
    background-color: var(--icons_low_contrast);
  }
  &.accent-primary {
    &:before {
      background-color: var(--accent_primary);
    }
  }
  &.accent-warning {
    &:before {
      background-color: var(--accent_warning);
    }
  }
  &.accent-success {
    &:before {
      background-color: var(--accent_success);
    }
  }
}
</style>
