<template>
  <div class="odd-accident">
    <template v-if="source.trafficaccident">
      <div class="odd-accident__controls">
        <r-button
          type="success"
          :disabled="!canSave"
          @click="save"
        >
          Сохранить
        </r-button>
        <r-button
          simple
          @click="cancel"
        >
          Выйти без сохранения
        </r-button>
      </div>
      <r-tabs>
        <r-tab
          v-for="tab in tabs"
          :id="tab.id"
          :key="tab.id"
          :name="tab.name"
        >
          <component
            :is="`accident-${tab.id}`"
            :model="getModelByBlock(tab.id)"
            :source="source"
            :related="related"
            @hasChanges="hasChanges"
            @updateRelated="$emit('updateRelated')"
          />
        </r-tab>
      </r-tabs>
    </template>
    <template v-else>
      <r-text>
        К событию не привязана таблица ДТП.
        <r-button
          link
          simple
          @click="createAdditionalTable"
        >
          Привязать
        </r-button>
      </r-text>
    </template>
  </div>
</template>

<script>
import accidentIntruders from './accident-intruders'
import accidentInjureds from './accident-injureds'
import accidentObject from './accident-object'
import accidentDescription from './accident-description'
import accidentInfo from './accident-info'

import model from '../config/accident-model'

import cloneDeep from 'lodash.clonedeep'

export default {
  components: {
    accidentDescription,
    accidentIntruders,
    accidentInjureds,
    accidentObject,
    accidentInfo
  },
  props: {
    related: {
      type: Object,
      required: true
    },
    source: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      clone: null,
      tabs: [
        {
          id: 'object',
          name: 'Объект'
        },
        {
          id: 'info',
          name: 'Информация о ДТП'
        },
        {
          id: 'intruders',
          name: 'Участники'
        },
        {
          id: 'injureds',
          name: 'Пострадавшие'
        },
        {
          id: 'description',
          name: 'Описание'
        }
      ]
    }
  },
  computed: {
    canSave() {
      return !!this.source.name
    }
  },
  mounted() {
    this.clone = cloneDeep(this.source)
  },
  beforeDestroy() {
    if (!this.$store.state.odd.needToSave) {
      this.cancel()
    }
  },
  methods: {
    async createAdditionalTable() {
      const { id } = this.source
      const source_id = this.$store.state.services?.trafficaccident
      const url = `objectInfo/${source_id}`

      try {
        const { data } = await this.$store.dispatch('POST_REQUEST', {
          url,
          data: { event_id: id }
        })
        this.source.trafficaccident = data[0]
      } catch (e) {
        throw new Error(e)
      }
    },
    hasChanges() {
      this.$emit('hasChanges')
    },
    getModelByBlock(blockName) {
      return model.filter(({ block }) => block === blockName)
    },
    cancel() {
      const { systemFields } = this.$store.state.services
      const keys = Object.keys(this.source).filter(
        k => !systemFields.includes(k)
      )
      keys.forEach(k => {
        this.source[k] = this.clone[k]
      })
      this.$emit('close')
    },
    save() {
      this.$store.commit('SET_ODD_FIELD', {
        field: 'needToSave',
        value: true
      })
    }
  }
}
</script>

<style lang="scss">
.odd-accident {
  display: grid;
  grid-gap: 1rem;

  &__controls {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    justify-items: start;
    justify-content: start;
    align-items: center;
  }
}
</style>
