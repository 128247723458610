<template>
  <div
    class="files-comments"
    :style="column ? 'grid-template-columns: 1fr;' : ''"
  >
    <div
      v-for="entity in filteredEntities"
      :key="entity.id"
      :class="container && !wrap ? 'files-comments__container' : 'files-comments__wrapper'"
    >
      <r-title
        v-if="container && !wrap"
        type="subtitle-2"
      >
        {{ entity.title }}
      </r-title>
      <component
        :is="wrap ? 'r-simple-card' : 'div'"
        v-loading="entity.loading"
        class="files-comments__wrapper"
        :default-closed="wrap && closed"
        :title="entity.title"
        :no-toggler="noToggler"
      >
        <component
          :is="entity.name"
          :id="id"
          :source_id="source_id"
          @loading="loading"
        />
      </component>
    </div>
  </div>
</template>

<script>
import comments from './comments/comments'
import files from './files/files'

export default {
  components: {
    comments,
    files
  },
  props: {
    source_id: {
      type: String,
      default: null
    },
    id: {
      type: [String, Number],
      default: null
    },
    only: {
      type: String,
      default: null
    },
    wrap: {
      type: Boolean,
      default: false
    },
    closed: {
      type: Boolean,
      default: false
    },
    column: {
      type: Boolean,
      default: false
    },
    container: {
      type: Boolean,
      default: false
    },
    noToggler: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      entities: [
        { id: 1, name: 'comments', title: 'Комментарии', loading: false },
        { id: 2, name: 'files', title: 'Файлы', loading: false }
      ]
    }
  },
  computed: {
    filteredEntities() {
      return this.only
        ? this.entities.filter(e => this.only.includes(e.name))
        : this.entities
    },
    reactiveEntities() {
      return this.entities
    }
  },
  methods: {
    loading(prop) {
      this.entities.find(e => e.name === prop[0]).loading = prop[1]
    }
  }
}
</script>

<style lang="scss">
.files-comments {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  overflow: hidden;

  &__container {
    display: grid;
    grid-gap: 0.5rem;
    padding: 0.5rem;
    background-color: var(--bg_containers);
    border-radius: var(--border-radius);
  }

  &__wrapper {
    height: 100%;
    overflow: hidden;
  }

  &__files,
  &__comments {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.5rem;
    height: 100%;
    align-items: start;
    grid-template-rows: 56px auto;
    overflow: hidden;
  }
}
</style>
