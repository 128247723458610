import { rDate } from '@/utils'

export const filtersEncoder = filters => {
  const config = { where: [], type: 'AND' }

  filters
    .filter(f => f.active)
    .forEach(f => {
      switch (f.id) {
        case 'type_id':
        case 'group_id':
        case 'subgroup_id':
        case 'contractor_id':
        case 'status_id':
          config.where.push(simpleCheckbox(f))
          break
        case 'created_at':
          config.where.push(customInterval(f, 'created_at'))
          break
        case 'date_until':
          config.where.push(customInterval(f, 'date_until'))
          break
        default:
          break
      }
    })
  return config
}

const simpleCheckbox = f => {
  return {
    field: f.id,
    value: f.prop.filter(p => p.value).map(p => p.id),
    op: 'in'
  }
}

const customInterval = (f, field) => {
  let { from, to } = f.prop.interval

  if (!from || !to) {
    from = from || new Date(0)
    to = to || new Date()
  }

  from = rDate.format(from, 'iso')
  to = rDate.format(to, 'iso')

  return {
    field,
    op: 'between',
    value: `${from}/${to}`
  }
}
