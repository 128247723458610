<template>
  <div class="monitoring-tmc">
    <div class="monitoring-tmc__panels">
      <r-main-panel>
        <main-panel />
      </r-main-panel>
      <r-second-panel
        v-if="isVehicleListOpened"
        v-loading="vehiclesLoading || loading"
        @close="closeVehicleList"
      >
        <vehicle-list :vehicles="allVehicles" />
      </r-second-panel>
    </div>
    <div class="monitoring-tmc__main">
      <mt-map />
      <card
        v-if="cardId"
        :id="cardId"
      />
    </div>
  </div>
</template>

<script>
import MainPanel from './components/main-panel/main-panel'
import VehicleList from './components/vehicle-list/vehicle-list'
import MtMap from './components/map/map'
import Card from './components/card/card'

export default {
  components: {
    MainPanel,
    VehicleList,
    MtMap,
    Card
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    etran_source_id() {
      return this.$store.state.monitoringTmc.etran_source_id
    },
    vehicles_source_id() {
      return this.$store.state.monitoringTmc.vehicles_source_id
    },
    cargoes_source_id() {
      return this.$store.state.services.cargoes
    },
    cardId() {
      return this.$store.state.monitoringTmc.cardId
    },
    isVehicleListOpened() {
      return this.$store.state.monitoringTmc.isVehicleListOpened
    },
    allVehicles() {
      return this.$store.state.monitoringTmc.vehicles
    },
    vehiclesLoading() {
      return this.$store.state.monitoringTmc.vehiclesLoading
    }
  },
  watch: {
    allVehicles: {
      handler: function(val) {
        this.$store.commit('MT_TMC_SET_FIELD', {
          field: 'vehiclesCount',
          value: val?.length || 0
        })
      },
      deep: true
    }
  },
  created() {
    this.getObjectFields()
  },
  methods: {
    closeVehicleList() {
      this.$store.commit('MT_TMC_SET_FIELD', {
        field: 'isVehicleListOpened',
        value: false
      })
    },
    async getObjectFields() {
      try {
        this.loading = true

        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectFields/${this.etran_source_id}`
        })
        const { data: vehicles } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectFields/${this.vehicles_source_id}`
        })
        const { data: cargoes } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectFields/${this.cargoes_source_id}`
        })

        this.$store.commit('MT_TMC_SET_FIELD', {
          field: 'etranObjectFields',
          value: data
        })

        this.$store.commit('MT_TMC_SET_FIELD', {
          field: 'vehiclesObjectFields',
          value: vehicles
        })

        this.$store.commit('MT_TMC_SET_FIELD', {
          field: 'cargoesObjectFields',
          value: cargoes
        })
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.monitoring-tmc {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  background-color: var(--bg_surface) !important;

  &__panels {
    display: flex;
    z-index: 10;
    box-shadow: 2px 0 10px 0px rgba(0, 0, 0, 0.1);
  }

  &__main {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
</style>
