<template>
  <ul class="comments__comments-list">
    <comment-item
      v-for="(comment, index) in comments"
      :key="index"
      :comment="comment"
      :source_id="source_id"
      @load="$emit('load')"
    />
  </ul>
</template>

<script>
import commentItem from './comment-item'

export default {
  components: { commentItem },
  props: {
    source_id: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    comments: {
      type: Array,
      default: null
    }
  }
}
</script>

<style lang="scss">
.files-comments {
  .comments {
    &__comments-list {
      display: grid;
      grid-auto-flow: row;
      grid-gap: 0.5rem;
      align-items: start;
      align-content: flex-start;
      overflow: auto;
      height: 100%;
    }
  }
}
</style>
