<template>
  <div class="monitoring">
    <div class="monitoring__panels">
      <r-main-panel>
        <main-panel />
      </r-main-panel>
      <r-second-panel
        v-if="isVehicleListOpened"
        v-loading="loading"
        @close="closeVehicleList"
      >
        <vehicle-list :vehicles="vehicles" />
      </r-second-panel>
    </div>
    <div class="monitoring__main">
      <mt-map />
      <card
        v-if="cardId"
        :id="cardId"
      />
    </div>
  </div>
</template>

<script>
import MainPanel from '@/components/monitoring/main-panel/main-panel'
import VehicleList from '@/components/monitoring/vehicle-list/vehicle-list'
import MtMap from '@/components/monitoring/map/map'
import Card from '@/components/monitoring/card/card'

export default {
  components: {
    MainPanel,
    VehicleList,
    MtMap,
    Card
  },
  computed: {
    cardId() {
      return this.$store.state.monitoring.cardId
    },
    isVehicleListOpened() {
      return this.$store.state.monitoring.isVehicleListOpened
    },
    vehicles() {
      return this.$store.state.monitoring.vehicles.map(item => ({
        ...item,
        contractor_name: item.contractor ? item.contractor.name : ''
      }))
    },
    loading() {
      return this.$store.state.monitoring.vehiclesLoading
    }
  },
  created() {
    this.$socket.open()
  },
  beforeDestroy() {
    // this.$socket.close()
  },
  methods: {
    closeVehicleList() {
      this.$store.commit('SET_MT_FIELD', {
        field: 'isVehicleListOpened',
        value: false
      })
    }
  }
}
</script>

<style lang="scss">
.monitoring {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  background-color: var(--bg_surface) !important;

  &__panels {
    display: flex;
    z-index: 10;
    box-shadow: 2px 0 10px 0px rgba(0, 0, 0, 0.1);
  }

  &__main {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
</style>
