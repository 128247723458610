<template>
  <dropdown-button
    v-if="layers.length"
    :mapgl="mapgl"
    :module="module"
    :layer-id="id"
    icon="ksodd-map"
    title="Ортофотопланы"
    :layers="layers"
    :add-handler="addHandler"
    :layers-ids="layersIds"
  />
</template>

<script>
import DropdownButton from '../buttons/dropdown-button'
import { getDatasourcesByDatatype, getFirstSymbolId } from '@/utils'

export default {
  components: {
    DropdownButton
  },
  props: {
    mapgl: {
      type: Object,
      required: true
    },
    module: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      id: 'tile_layers',
      layers: [],
      layersIds: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      // get events id
      this.layers = await getDatasourcesByDatatype(this, 'tile_layer') || []
    },
    updateLayers(layers) {
      this.layers.forEach(l => {
        if (layers.includes(l.id)) {
          this.addHandler(l)
        }
      })
    },
    addHandler({ id, source_info }) {
      this.mapgl.addSource(id, {
        type: 'raster',
        tiles: [`${source_info.url}`],
        tileSize: 256,
        minzoom: 8,
        maxzoom: 21,
        scheme: 'tms'
      })
      const firstId = getFirstSymbolId(this.mapgl)

      this.mapgl.addLayer(
        {
          id,
          type: 'raster',
          source: id
        },
        firstId
      )
      this.layersIds = [id]
    }
  }
}
</script>
