<template>
  <r-simple-card title="Обводка">
    <r-color-picker
      :color="layerStyle.paint['line-color']"
      :color-opacity="layerStyle.paint['line-opacity']"
      @change="changeColorProperty"
    />
    <r-slider
      style="margin-top: 8px"
      title="Ширина"
      :number="layerStyle.paint['line-width']"
      :min="1"
      :max="10"
      :step="1"
      @change="changeWidthProperty"
    />
  </r-simple-card>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    layerStyle: {
      type: Object,
      required: true
    },
    controllers: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    changeColorProperty({ color, opacity }) {
      this.layerStyle.paint['line-color'] = color
      this.layerStyle.paint['line-opacity'] = opacity

      this.$emit('change-property', { type: 'paint', field: 'line-color' })
      this.$emit('change-property', { type: 'paint', field: 'line-opacity' })
    },
    changeWidthProperty(width) {
      this.layerStyle.paint['line-width'] = width

      this.$emit('change-property', { type: 'paint', field: 'line-width' })
    }
  }
}
</script>

<style></style>
