<template>
  <div :class="['checklist-album-list', { selectMode }]">
    <ul class="checklist-album-list__wrapper">
      <li
        v-for="item in resources"
        :key="item.id"
        class="checklist-album-list__item"
        @click="handlePictureCardPreview(item)"
      >
        <checklist-album-list-item
          v-if="item.url"
          :url="item.url"
        />
        <div
          v-if="isActive(item)"
          class="checklist-album-list__item-active"
        >
          <r-icon
            name="check"
            :size="48"
          />
          <r-title type="subtitle-2">
            Выбрано
          </r-title>
        </div>
        <span class="checklist-album-list__item-actions">
          <span
            v-if="!disabled"
            class="checklist-album-list__item-delete"
            @click="handleRemove(item, $event)"
          >
            <r-icon
              name="close-delete"
              :size="20"
              color-type="primary"
            />
          </span>
        </span>
      </li>
    </ul>
    <el-dialog :visible.sync="dialogVisible">
      <img
        width="100%"
        :src="dialogImageUrl"
        alt="photo"
      >
    </el-dialog>
  </div>
</template>

<script>
import constants from '@/constants/url'
import checklistAlbumListItem from './checklist-album-list-item.vue'

export default {
  components: { checklistAlbumListItem },
  props: {
    resources: {
      type: Array,
      default: () => [],
      required: true
    },
    selectMode: {
      type: Boolean,
      default: false
    },
    attrId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      checklistsUrl: this.$store.state.bdd.checklistsUrl || null,
      id: this.$route.query.id,
      url: constants.URL,

      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false
    }
  },
  methods: {
    async handleRemove(item, e) {
      e.stopPropagation()
      e.preventDefault()
      const { id } = item
      const url = `objectResources/${this.checklistsUrl}/${id}`

      try {
        await this.$store.dispatch('DELETE_REQUEST', { url })
        this.$emit('load')
      } catch (error) {
        console.log(error)
      }
    },
    handlePictureCardPreview(item, e) {
      if (!this.selectMode) {
        this.dialogImageUrl = item.url
        this.dialogVisible = true
      } else {
        this.saveChanges(item)
      }
    },
    async saveChanges(item) {
      const { id } = item
      const url = `objectResources/${this.checklistsUrl}/${this.id}/${id}`
      const data = { attr_ids: this.getAttrIds(item) }

      try {
        await this.$store.dispatch('PUT_REQUEST', {
          data,
          url
        })
        this.$emit('load')
      } catch (error) {
        console.log(error)
      }
    },
    getAttrIds(item) {
      const result = Array.isArray(item.attr_ids) ? item.attr_ids : []
      const index = result.findIndex(e => e === this.attrId)

      if (index === -1) {
        result.push(this.attrId)
      } else {
        result.splice(index, 1)
      }

      return result
    },
    isActive(item) {
      if (!this.selectMode) return false
      if (!Array.isArray(item.attr_ids)) return false
      if (item.attr_ids.includes(this.attrId)) return true
      return false
    }
  }
}
</script>

<style lang="scss">
.checklist-album-list {
  position: relative;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &__item {
    position: relative;
    width: 7.5rem;
    height: 7.5rem;
    border-radius: var(--border-radius);
    overflow: hidden;

    &:last-child {
      margin-right: 0;
    }

    img {
      width: 100%;
      height: 100%;
      cursor: zoom-in;
      object-fit: cover;
    }

    &-actions {
      position: absolute;
      top: 0;
      right: 0;
      height: 0;
      width: 100%;
      display: flex;
      transition: 0.15s ease-in-out;
      justify-content: flex-end;
      background: linear-gradient(
        to top,
        var(--accent_transparent),
        var(--bg_surface)
      );

      .r-icon {
        cursor: pointer;
      }
    }

    &:hover {
      .checklist-album-list__item-actions {
        height: 2rem;
      }
    }

    &-active {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.7);
      cursor: pointer;

      .r-icon {
        height: auto !important;
        margin-bottom: 4px;
      }

      .r-title {
        margin: 0 !important;
        width: auto !important;
        padding: 0 !important;
      }
    }
  }

  &.selectMode {
    img {
      cursor: pointer;
    }
  }
}
</style>
