<template>
  <div class="r-info-card">
    <div class="r-info-card__header">
      <div class="r-info-card__name">
        <r-icon
          :size="18"
          :name="icon"
        />
        <r-text>
          {{ name }}
        </r-text>
      </div>
      <button
        class="r-info-card__close"
        @click="$emit('close')"
      >
        <r-icon
          :size="18"
          name="close-delete"
        />
      </button>
    </div>

    <div class="r-info-card__actions">
      <slot name="actions" />
    </div>

    <div class="r-info-card__attributes">
      <r-simple-card title="Информация">
        <ul class="r-info-card__attributes-list">
          <li
            v-for="attribute in attributes"
            :key="attribute.key"
            class="r-info-card__attribute"
          >
            <div class="r-info-card__attribute__key">
              <r-text type="caption">
                {{ attribute.key }}
              </r-text>
            </div>
            <div class="r-info-card__attribute__value">
              <r-text>
                {{ attribute.value }}
              </r-text>
            </div>
          </li>
        </ul>
      </r-simple-card>
    </div>

    <slot name="additional" />

    <div class="r-info-card__footer">
      <button @click="flyTo">
        <r-icon
          :size="18"
          name="focus-zone"
        />
        <r-text>
          Найти на карте
        </r-text>
      </button>
    </div>
  </div>
</template>

<script>
import extent from 'turf-extent'

export default {
  props: {
    objectId: {
      type: String,
      required: true
    },
    layerId: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    attributes: {
      type: Array,
      required: true
    },
    geometry: {
      type: Object,
      required: true
    },
    mapgl: {
      type: Object,
      required: true
    }
  },
  methods: {
    flyTo() {
      const bounds = extent(this.geometry)

      this.mapgl.fitBounds(bounds, {
        padding: 20
      })
    }
  }
}
</script>

<style lang="scss">
.r-info-card {
  border-radius: var(--border-radius);
  background-color: var(--modal_bg) !important;
  min-width: 260px;

  &__header {
    padding: 8px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--dividers_low_contrast) !important;
  }

  &__name {
    display: flex;
    align-items: center;

    .r-icon {
      margin-right: 4px;
    }
  }

  &__close {
    background: none;
    border: none;
    margin-left: auto;
    cursor: pointer;
  }

  &__actions {
    padding: 8px;
  }

  &__attributes {
    padding: 0 8px;
    padding-bottom: 8px;
  }

  &__attribute {
    &:not(:first-child) {
      margin-top: 12px;
    }

    &__value {
      margin-top: 4px;
    }
  }

  &__footer {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid var(--dividers_low_contrast) !important;

    button {
      background: none;
      border: none;
      display: flex;
      align-items: center;
      cursor: pointer;

      .r-icon {
        margin-right: 8px;
      }
    }
  }
}
</style>
