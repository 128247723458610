export default [
  // Object block
  {
    model: 'name',
    block: 'object',
    parent: 'event_class',
    title: 'Тип события',
    read_only: true,
    type: 'text',
    caption:
      'Чтобы изменить тип события, создайте объект заново. Текущие изменения не сохранятся.'
  },
  {
    model: 'name',
    block: 'object',
    title: 'Название события',
    required: true,
    caption: 'Обязательное поле для заполнения',
    type: 'input'
  },
  {
    model: 'closed_line_count',
    block: 'object',
    title: 'Количество перекрытых полос',
    type: 'number',
    width: 'half',
    step: 0.1,
    min: 0
  },
  {
    model: 'requested',
    block: 'object',
    title: 'Заявка',
    type: 'boolean',
    width: 'half'
  },
  {
    type: 'input',
    block: 'object',
    model: 'address',
    title: 'Место ДТП'
  },
  {
    type: 'input',
    block: 'object',
    model: 'accident_site',
    parent: 'trafficaccident',
    title: 'Участок ДТП'
  },
  {
    type: 'datetime',
    block: 'object',
    format: 'dd.MM.yyyy HH:mm',
    model: 'start_time',
    width: 'half',
    title: 'Начало действия'
  },
  {
    type: 'datetime',
    block: 'object',
    format: 'dd.MM.yyyy HH:mm',
    model: 'end_time',
    width: 'half',
    title: 'Окончание действия'
  },
  // Info block
  {
    type: 'select',
    block: 'info',
    width: 'half',
    model: 'subproject_id',
    select: 'subprojects',
    title: 'Подпроект'
  },
  {
    block: 'info',
    divider: true
  },
  {
    type: 'select',
    block: 'info',
    width: 'full',
    model: 'detection_employee_id',
    filterable: true,
    select: 'employees',
    title: 'ФИО выявившего нарушение'
  },
  {
    type: 'input',
    block: 'info',
    width: 'full',
    model: 'sibur_no',
    title: 'Номер в реестре СИБУР'
  },
  {
    block: 'info',
    divider: true
  },
  {
    type: 'select',
    block: 'info',
    model: 'accident_category_id',
    select: 'accident_categories',
    title: 'Категория нарушения'
  },
  {
    type: 'select',
    block: 'info',
    model: 'accident_classification',
    select: 'accident_classifications_custom',
    title: 'Классификация нарушения'
  },
  {
    type: 'select',
    block: 'info',
    model: 'trafficaccident_type_id',
    select: 'trafficaccident_types',
    title: 'Вид ДТП'
  },
  {
    type: 'input',
    block: 'info',
    model: 'material_damage',
    title: 'Материальный ущерб'
  },
  {
    type: 'checkbox',
    width: 'half',
    block: 'info',
    model: 'not_statistic',
    title: 'Не подлежит статистическому учету'
  },
  {
    type: 'checkbox',
    width: 'half',
    block: 'info',
    model: 'not_related_to_prod',
    title: 'Не связанно с производством'
  }
]
