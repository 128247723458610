<template>
  <sc-card>
    <div :class="[`sc-title-bar ${$mq}`]">
      <city-title :city="city" />
      <date-time :time-zone="timeZone" />
    </div>
  </sc-card>
</template>

<script>
import scCard from '../../layout/card'
import cityTitle from '../../modules/city-title/city-title'
import dateTime from '../../modules/datetime/datetime'

export default {
  components: { scCard, cityTitle, dateTime },
  props: {
    options: {
      type: Object,
      required: true,
      default: () => ({
        type: 'default'
      })
    }
  },
  computed: {
    city() {
      return this.$store.state.situationCenter.config.city
    },
    timeZone() {
      return this.$store.state.situationCenter.config.timeZone
    }
  }
}
</script>

<style lang="scss">
.sc-title-bar {
  position: relative;
  width: 100%;
  min-height: 100px;
  padding: 4px;
  display: flex;
  flex-direction: column;
}
</style>
