<template>
  <simple-button
    :mapgl="mapgl"
    :module="module"
    :layer-id="id"
    icon="matrix-layer"
    title="Граф"
    :add-handler="addHandler"
    :layers-ids="layersIds"
  />
</template>

<script>
import SimpleButton from '../buttons/simple-button'
import {
  getDatasourcesByDatatype,
  getModelChildrenIds,
  jsonToGeojson,
  getFirstSymbolId,
  modifyLinksGeometry
} from '@/utils'
import { layersConfig } from '@/components/public-transport/map/configs'

export default {
  components: {
    SimpleButton
  },
  props: {
    mapgl: {
      type: Object,
      required: true
    },
    module: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      id: 'ev6y-hty7-3gn5-hy76-v412y',
      data: null,
      handlers: {},
      layersIds: [],
      isloaded: false
    }
  },
  computed: {
    activeLayers() {
      const { mapConfigs } = this.$store.state
      const active = mapConfigs?.activeLayers?.[this.module] || []

      return active.join()
    },
    zoom() {
      return this.mapgl.getZoom()
    }
  },
  watch: {
    activeLayers() {
      this.isloaded = false
    },
    '$store.state.map.updateGraph'(val) {
      if (val) {
        this.$store.commit('MAP_SET_FIELD', ['updateGraph', false])
        this.addHandler()
      }
    }
  },
  methods: {
    async addHandler() {
      try {
        const models = await getDatasourcesByDatatype(this, 'model')
        const model = models.find(m => !!m.is_main_model) || models[0]

        const { id, children, name } = model
        const ids = getModelChildrenIds(children)

        this.$store.commit('SET_PT_MODEL', { id, ids, name, children })
        this.$store.commit('SET_ODD_MODEL', { id, ids, name, children })

        this.layersIds = []
        const layers = ['nodes', 'links']

        layers.forEach(async(type, i) => {
          await this.addLayers(ids[type], type)
          this.layersIds.push(ids[type])
          if (i === layers.length - 1) this.isloaded = true
        })
      } catch (error) {
        console.log('graph not loaded, error', error)
      }
    },
    async addLayers(modelId, type) {
      this.$store.commit('SET_GRAPH_LOADING_STATUS', true)
      try {
        // get data & build geojson
        const config = {
          only: ['id', 'geom'],
          where: [
            {
              field: 'geom',
              op: '!null',
              type: 'AND',
              value: ''
            }
          ]
        }
        const url = `objectInfo/${modelId}?config=${JSON.stringify(config)}`
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })

        let features = Object.values(data)

        if (type === 'links') {
          features = modifyLinksGeometry(features)
        }
        const geojson = jsonToGeojson(features)
        // Setup layer & source
        const options = {
          id: modelId,
          source: modelId,
          ...layersConfig[type]
        }
        const source = this.mapgl.getSource(modelId)
        const layer = this.mapgl.getLayer(modelId)
        if (source) {
          source.setData(geojson)
        } else {
          this.mapgl.addSource(modelId, {
            type: 'geojson',
            data: geojson
          })
        }
        if (!layer) this.mapgl.addLayer(options, getFirstSymbolId(this.mapgl))

        this.$store.commit('SET_GRAPH_LOADING_STATUS', false)
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>
