<template>
  <div :class="['map-palette-colors', isCurrent ? 'current' : '']">
    <div
      v-for="(color, i) in colors"
      :key="color + i"
      class="map-palette-colors__item"
      :style="`background-color: ${color}`"
    />
  </div>
</template>

<script>
export default {
  props: {
    colors: {
      type: Array,
      required: true
    },
    isCurrent: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.map-palette-colors {
  display: flex;
  align-items: center;
  height: 36px;
  width: 100%;

  &.current {
    margin-top: 1px;

    .map-palette-colors__item {
      height: 28px;
    }
  }

  &__item {
    line-height: 36px;
    height: 28px;
    width: 20%;

    &:first-child {
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
    }

    &:last-child {
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }
  }
}
</style>
