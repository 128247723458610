<template>
  <div :class="[`r-tip ${type}`]">
    <r-icon
      :name="icon"
      :size="24"
    />
    <r-text>
      <slot />
    </r-text>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    icon: {
      type: String,
      default: 'info'
    }
  }
}
</script>

<style lang="scss">
.r-tip {
  position: relative;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  align-content: center;
  padding: 0.5rem;
  grid-gap: 0.5rem;
  border-radius: var(--border-radius);
  overflow: hidden;
  width: 100%;

  &.primary {
    background-color: var(--accent_selected);
  }

  &.warning {
    background-color: var(--accent_warning_hover);
  }

  &.success {
    background-color: var(--accent_success_hover);
  }
}
</style>
