<template>
  <div class="sc-object-card__rtsp">
    <hls-player
      v-if="isReady"
      :url="url"
    />
  </div>
</template>

<script>
import hlsPlayer from './renderer/hls-player'
import constants from '@/constants/url'

export default {
  components: { hlsPlayer },
  props: {
    id: {
      type: String,
      required: true
    },
    cardData: {
      type: Object,
      required: true
    },
    rtsp: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isReady: false
    }
  },
  computed: {
    url() {
      const uri = this.cardData?.stream_url || this.rtsp.uri?.replace('#ID', this.id)

      return `${constants.BASE_URL}/${uri}`
    }
  },
  watch: {
    url: {
      handler: function() {
        this.isReady = false
        setTimeout(() => {
          this.isReady = true
        }, 256)
      },
      deep: true
    }
  },
  mounted() {
    this.isReady = true
  }
}
</script>

<style lang="scss">
.sc-object-card__rtsp {
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border: 1px solid;
  border-radius: var(--border-radius);
  border-color: var(--dividers_low_contrast) !important;
}
</style>
