export const mapColors = {
  default: '#2e93fc',
  white: '#fff',
  baseColor: '#3A8FD2',
  routeColor: '#d63838',
  activeColor: '#55b77e',
  activeColor2: 'rgb(255, 68, 140)',
  reverseColor: '#99ddb5',
  ptRouteColor: '#FF8A48',
  ptEditRouteColor: '#ff5949'
}

export const routeColors = [
  { value: '#B532F2', label: 'Фиолетовый' },
  { value: '#2CCB29', label: 'Зеленый' },
  { value: '#EC2323', label: 'Красный' },
  { value: '#32DBF2', label: 'Голубой' },
  { value: '#1774FF', label: 'Синий' }
]
