<template>
  <div class="pass-register-modal">
    <div class="pass-register-modal__controls">
      <r-button
        type="primary"
        class="pass-register-modal__edit-button"
        icon="edit"
        @click="$emit('mode', 'edit')"
      >
        {{ $t('register_modal::edit-button') }}
      </r-button>
      <r-delete-button
        mini
        @delete="deleteRegisterItem"
      />
    </div>

    <div class="pass-register-modal__application">
      <r-title type="subtitle-2">
        Выдан на основе заявки
      </r-title>
      <router-link
        v-if="source.application && !!source.application.vehicle"
        :to="`/app/bdd/applications?id=${source.application.id}`"
        class="pass-register-modal__application-info"
        @click.native="close"
      >
        <r-title type="subtitle-1">
          {{ preparedFields.reg_number.value }}
        </r-title>
        <r-text>
          {{ $rDate.format(source.application.updated_at) }}
        </r-text>
        <r-text>
          {{ getApplicationField(source, 'status') }}
        </r-text>
      </router-link>
      <r-text
        v-else
        color-type="error"
      >
        Заявка удалена
      </r-text>
    </div>
    <div
      v-if="source.application && !!source.application.vehicle"
      class="pass-register-modal__attribute-list"
    >
      <div
        v-for="field in preparedFields"
        :key="field.name"
        class="pass-register-modal__attribute"
      >
        <r-text color-type="secondary">
          {{ field.name }}
        </r-text>
        <r-text>
          {{ field.value || '—' }}
        </r-text>
      </div>
    </div>
    <filesComments
      :id="source.id"
      :source_id="source_id"
      class="pass-register-modal__row"
      container
      column
    />
  </div>
</template>

<script>
import filesComments from '@/components/files-comments/files-comments'
import { fields, sources } from './config/fields'

export default {
  components: { filesComments },
  props: {
    source_id: {
      type: String,
      required: true
    },
    source: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      hasChanges: false,
      fields,
      sources
    }
  },
  computed: {
    preparedFields() {
      const { source } = this
      return {
        withdrawal: {
          value: source.withdrawal ? 'Да' : 'Нет',
          name: 'Изъят'
        },
        date_from: {
          value: this.$rDate.format(source.date_from),
          name: 'Действует с'
        },
        date_to: {
          value: this.$rDate.format(source.date_to),
          name: 'Действует по'
        },
        number: { value: source.number, name: '№ пропуска' },
        permit_type: { value: source.permit_type?.name, name: 'Тип пропуска' },
        reg_number: {
          value: source.application?.vehicle?.reg_number || null,
          name: 'ГРЗ'
        },
        is_valid: {
          value: source.is_valid ? 'Да' : 'Нет',
          name: 'Действителен'
        },
        vehicle_type: {
          value: source.application.vehicle.vehicle_type?.name,
          name: 'Вид ТС'
        },
        model: {
          value: source.application.vehicle.model?.name,
          name: 'Модель ТС'
        },
        subproject: { value: this.getValueByField('subproject'), name: 'Подпроект' },
        vrc_full_name: {
          value: source.application.vehicle.vrc_full_name,
          name: 'Подрядчик'
        }
      }
    }
  },
  methods: {
    getApplicationField(source, field) {
      return source.application?.[field]?.name || 'Заявка в архиве'
    },
    getNestedField(source, field) {
      return source[field.parent][field.model][field.target]?.name || '—'
    },
    getValueByField(field) {
      const value = this.source.related[field].data[this.source[`${field}_id`]]

      return value?.name || '—'
    },
    async deleteRegisterItem() {
      const { source, source_id, $store } = this
      try {
        await $store.dispatch('POST_REQUEST', {
          url: `objectInfo/${source_id}`,
          data: { id: source.id, disabled: true }
        })
      } catch (e) {
        throw new Error(e)
      } finally {
        $store.commit('BDD_SET_FIELD', {
          field: sources.updateField,
          value: true
        })
        this.close()
      }
    },
    close() {
      this.$store.commit('CLOSE_MODAL_WINDOW')
    }
  }
}
</script>

<style lang="scss">
.pass-register-modal {
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: row;

  &__controls {
    display: grid;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: start;
    grid-auto-flow: column;
  }

  &__create-button {
    justify-self: start;
  }

  &__application {
    display: grid;
    grid-gap: 0.5rem;
    background-color: var(--bg_containers);
    border-radius: var(--border-radius);
    padding: 0.5rem;

    &-info {
      display: grid;
      grid-auto-flow: column;
      justify-content: start;
      grid-gap: 1rem;
      align-items: center;
      height: 36px;
      background-color: var(--bg_containers);
      border-radius: var(--border-radius);
      padding: 0 0.5rem;

      &:hover {
        transition: 0.15s ease;
        background-color: var(--accent_hover);
      }
    }
  }

  &__attribute {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.5rem;

    .el-date-editor {
      &.el-input {
        width: 100%;
      }
    }

    .el-input__inner {
      padding-right: 2rem !important;
    }

    &-list {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr;
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "register_modal::edit-button": "Редактировать"
  },
  "en": {
    "register_modal::edit-button": "Edit"
  }
}
</i18n>
