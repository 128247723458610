<template>
  <div
    v-loading="loading"
    class="pot-modal__fast-settings r-modal-card__main-layout"
  >
    <div class="r-modal-card__input-field">
      <r-text color-type="secondary">
        Текущий статус
      </r-text>
      <el-select
        v-model="potItem[statusField]"
        @change="instantApply(statusField, $event)"
      >
        <el-option
          v-for="(item, i) in statusList"
          :key="i"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
    </div>
    <div
      v-if="isVehicle"
      class="r-modal-card__attribute-list"
    >
      <div class="r-modal-card__input-field">
        <r-text color-type="secondary">
          КПП
        </r-text>
        <el-select
          v-model="potItem.checkpoint_id"
          filterable
          @change="instantApply('checkpoint_id', potItem.checkpoint_id)"
        >
          <el-option
            v-for="(item, i) in checkpointList"
            :key="i"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
      <div class="r-modal-card__input-field">
        <r-text color-type="secondary">
          Склад / Площадка
        </r-text>
        <el-select
          v-model="potItem.warehouse_id"
          class="r-select"
          placeholder="Выбрать"
          filterable
          clearable
          @change="instantApply('warehouse_id', potItem.warehouse_id)"
        >
          <el-option
            v-for="item in warehouseList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import { notifyFactory } from '@/utils'
export default {
  props: {
    potItem: {
      type: Object,
      required: true
    },
    statusList: {
      type: Array,
      required: true
    },
    checkpointList: {
      type: Array,
      required: true
    },
    warehouseList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    isVehicle() {
      return this.$store.state.pot1.activeModule === 'vehicles'
    },
    statusField() {
      return this.isVehicle ? 'pot_status_id' : 'status_pre_id'
    }
  },
  methods: {
    instantApply(field, value) {
      if (this.isVehicle) {
        this.vehicleApply(field, value)
      } else {
        this.railwayApply(value)
      }
    },
    async railwayApply(value) {
      this.loading = true
      const data = {
        id: this.potItem.id
      }
      data.status_pre_id = value
      try {
        await this.$store.dispatch('PUT_REQUEST', {
          url: 'objectInfo/42651eb5-0954-4687-a9d6-23872b98b009',
          data
        })
        const title = 'Изменения успешно внесены'

        this.$notify(notifyFactory('success', title))

        this.$store.commit('SET_POT_FIELD', {
          field: 'updateData',
          value: true
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async vehicleApply(field, value) {
      this.loading = true
      const data = {
        id: this.potItem.id
      }
      data[field] = value
      try {
        await this.$store.dispatch('PUT_REQUEST', {
          url: 'vehicle',
          data
        })
        const title = 'Изменения успешно внесены'

        this.$notify(notifyFactory('success', title))

        this.$store.commit('SET_POT_FIELD', {
          field: 'updateData',
          value: true
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
