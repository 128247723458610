<template>
  <div class="sc-card-toggler">
    <r-radio-group
      :button-list="buttons"
      :active="active"
      @change="toggleChart"
    />
  </div>
</template>

<script>
const buttons = [{ id: 'info', icon: 'list' }, { id: 'chart', icon: 'pie' }]

export default {
  props: {
    toggleChart: {
      type: Function,
      default: () => null
    },
    active: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      buttons
    }
  }
}
</script>
<style lang="scss">
.sc-card-toggler {
  .el-radio-button__inner {
    padding: 2px 8px !important;
    height: 31px !important;
  }
}
</style>

<i18n>
{
  "ru": {
    "chart": "График",
    "info": "Информация"
  },
  "en": {
    "chart": "Chart",
    "info": "Info"
  }
}
</i18n>
