<template>
  <simple-button
    :mapgl="mapgl"
    :module="module"
    :layer-id="id"
    icon="road-sign"
    title="Дорожные знаки"
    :add-handler="addHandler"
    :layers-ids="layersIds"
  />
</template>

<script>
import SimpleButton from '../buttons/simple-button'

import { layersConfig } from '@/components/odd/map/configs'
import {
  getSignsFeatures,
  loadIcons as loadOddIcons
} from '@/components/odd/map/helpers'

export default {
  components: {
    SimpleButton
  },
  props: {
    mapgl: {
      type: Object,
      required: true
    },
    module: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      id: 'e010924a-220b-4ecf-b423-9e756ff1d18e',
      iconsLoaded: false,
      layersIds: []
    }
  },
  methods: {
    async loadIcons() {
      if (this.iconsLoaded) return

      try {
        const url = 'objectInfo/telemetry.sign_icons'
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        await loadOddIcons(this, Object.values(data))

        this.iconsLoaded = true
      } catch (e) {
        throw new Error(e)
      }
    },
    async addHandler() {
      await this.loadIcons()

      const { id } = this

      try {
        const data = {
          only: ['id', 'name', 'sign_icon_id', 'projection', 'rack_position'],
          include: {
            sign_icon: {
              only: ['id', 'resource_id']
            },
            rack: {
              only: ['id', 'geom', 'projection']
            }
          }
        }
        const url = `objectInfo/${id}?scope=active&config=${JSON.stringify(data)}`
        const response = await this.$store.dispatch('GET_REQUEST', {
          url,
          data
        })

        const modifiedFeatures = getSignsFeatures(Object.values(response.data))
        const geojson = {
          type: 'FeatureCollection',
          features: modifiedFeatures
        }

        const source = this.mapgl.getSource(id)

        if (!source) {
          this.mapgl.addSource(id, {
            type: 'geojson',
            data: geojson
          })
          this.addLayers(id)
        } else {
          source.setData(geojson)
        }
      } catch (error) {
        throw new Error(error)
      }
    },
    addLayers(id) {
      this.mapgl.addLayer({
        id: `${id}_connections`,
        source: id,
        ...layersConfig.signs_connections,
        filter: ['==', '$type', 'LineString']
      })
      this.mapgl.addLayer({
        id: `${id}_points`,
        source: id,
        ...layersConfig.signs_points,
        filter: ['==', ['get', 'type'], 'points']
      })
      this.mapgl.addLayer({
        id: id,
        source: id,
        ...layersConfig.signs_symbols,
        filter: ['==', ['get', 'type'], 'symbols']
      })
      this.layersIds = [id, `${id}_points`, `${id}_connections`]
    }
  }
}
</script>

<style></style>
