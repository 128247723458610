import { rDate } from '@/utils'

export const filtersEncoder = filters => {
  const where = []
  if (!filters) return where

  const activeFilters = filters.filter(f => f.active)

  activeFilters.forEach(f => {
    switch (f.id) {
      case 'main_contractor_id':
      case 'status_id':
        where.push(simpleCheckbox(f))
        break
      case 'archive':
        where.push(customRadio(f))
        break
      case 'datetime':
        where.push(customInterval(f, 'datetime'))
        break
      case 'created_at':
      case 'inspection_dt':
        where.push(customInterval(f, f.id))
        break
      case 'vehicle.vehicle_type.vehicle_type_group.id':
        where.push(vehicleTypeCheckbox(f))
        break
      default:
        break
    }
  })

  return where
}

const vehicleTypeCheckbox = f => {
  return {
    field: 'vehicle.vehicle_type.vehicle_type_group.id',
    value: f.prop.filter(p => p.value).map(p => p.id),
    op: 'in'
  }
}

const simpleCheckbox = f => {
  return {
    field: f.id,
    value: f.prop.filter(p => p.value).map(p => p.id),
    op: 'in'
  }
}

const customRadio = f => {
  const value = f.prop.find(p => p.value).id
  return {
    field: 'status_id',
    value: null,
    op: value === 'yes' ? '=' : '!='
  }
}

const customInterval = (f, field) => {
  let { from, to } = f.prop.interval

  if (!from || !to) {
    from = from || new Date(0)
    to = to || new Date()
  }

  from = rDate.format(from, 'iso')
  to = rDate.format(to, 'iso')

  return {
    field,
    op: 'between',
    value: `${from}/${to}`
  }
}
