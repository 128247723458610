<template>
  <div class="r-panel-header">
    <div
      v-if="withBackButton"
      class="r-panel-header__back-button"
    >
      <r-button
        simple
        mini
        icon="arrow-back"
        @click="back"
      />
    </div>
    <div class="r-panel-header__title">
      <r-title type="subtitle-1">
        {{ title }}
      </r-title>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    withBackButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    back() {
      this.$emit('back')
    }
  }
}
</script>

<style lang="scss">
.r-panel-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid transparent;
  border-color: var(--dividers_low_contrast) !important;
  height: 36px;
  padding: 0 1rem;
  flex-shrink: 0;

  &__back-button {
    margin-right: 8px;
  }

  &__title {
    flex: 1;
    text-align: center;

    .r-title {
      justify-content: center;
    }
  }
}
</style>
