// additional geometry configs
export const hoveredLinkConfig = {
  paint: {
    'line-color': '#ff448c',
    'line-width': 3
  },
  layout: {
    'line-cap': 'round',
    'line-join': 'round'
  }
}
export const getHoveredNodeConfig = type => {
  if (type === 'stop_points') {
    return {
      paint: {
        'circle-radius': 6,
        'circle-color': 'rgba(255,255,255, 1)',
        'circle-pitch-alignment': 'map',
        'circle-stroke-width': 2,
        'circle-stroke-color': '#d63838'
      }
    }
  }

  return {
    paint: {
      'circle-radius': 4,
      'circle-color': '#ff448c',
      'circle-pitch-alignment': 'map',
      'circle-stroke-width': 2,
      'circle-stroke-color': 'rgb(255,255,255)'
    }
  }
}

// draw additional geometry
export const clearAllHelpers = map => {
  if (map.getLayer('links-helpers')) map.removeLayer('links-helpers')
  if (map.getLayer('links-helpers-arrow')) { map.removeLayer('links-helpers-arrow') }
  if (map.getSource('links-helpers')) map.removeSource('links-helpers')
  if (map.getLayer('hovered-node-helpers')) { map.removeLayer('hovered-node-helpers') }
  if (map.getSource('hovered-node-helpers')) { map.removeSource('hovered-node-helpers') }
}

export const createLinkHelper = (map, type, coordinates) => {
  map.addSource('links-helpers', {
    type: 'geojson',
    lineMetrics: true,
    data: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: { type, coordinates }
        }
      ]
    }
  })
  map.addLayer({
    type: 'line',
    source: 'links-helpers',
    id: 'links-helpers',
    ...hoveredLinkConfig
  })
  map.addLayer({
    id: 'links-helpers-arrow',
    source: 'links-helpers',
    type: 'symbol',
    layout: {
      'symbol-placement': 'line',
      'text-field': '▶',
      'text-size': ['interpolate', ['linear'], ['zoom'], 12, 16, 22, 18],
      'symbol-spacing': ['interpolate', ['linear'], ['zoom'], 12, 32, 22, 45],
      'text-keep-upright': false
    },
    paint: {
      'text-color': '#ff448c',
      'text-halo-color': '#ffffff',
      'text-halo-width': 1.3
    }
  })
}

export const createHoveredNodeHelper = (map, coordinates, type = 'nodes') => {
  const data = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: { type: 'Point', coordinates }
      }
    ]
  }

  if (!map.getSource('hovered-node-helpers')) {
    map.addSource('hovered-node-helpers', {
      type: 'geojson',
      data
    })
    map.addLayer({
      id: 'hovered-node-helpers',
      type: 'circle',
      source: 'hovered-node-helpers',
      ...getHoveredNodeConfig(type)
    })
  } else {
    map.getSource('hovered-node-helpers').setData(data)
  }
}
