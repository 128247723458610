<template>
  <div class="system-information">
    <about-info />
  </div>
</template>

<script>
import aboutInfo from './components/about-info'

export default {
  components: {
    aboutInfo
  }
}
</script>
