import cloneDeep from 'lodash.clonedeep'
import throttle from 'lodash.throttle'
import debounce from 'lodash.debounce'
import { Constants, CommonSelectors } from '@/libs/map-draw/lib-utils'
import {
  getFeaturesByLayerId,
  createHoveredSPHelper,
  removeHoveredSPHelpers,
  createRouteHelper,
  clearAllHelpers,
  createRoutePoints,
  clearAllRouteGeometry,
  getWaypointsQuery,
  showStopsWarnings,
  getNearestFeature
} from '@/libs/map-draw/helpers'

export const createDrawRoutesMode = (MapboxDraw, map) => {
  const DrawRoutes = {}
  const customState = {
    currentPoint: null,
    routePoints: [],
    currentRouteCoordinates: [],
    fixedRouteCoordinates: [],
    lineRouteItems: [],
    waypoints: [],
    isLastSP: false
  }

  const clearCustomState = () => {
    customState.routePoints = []
    customState.currentPoint = null
    customState.currentRouteCoordinates = []
    customState.fixedRouteCoordinates = []
    customState.lineRouteItems = []
    customState.waypoints = []
    customState.isLastSP = false
  }
  const showError = throttle(() => {
    map.$message({
      message:
        'Не удается построить дальнейший маршрут из последней указанной точки',
      type: 'error',
      duration: 1500,
      customClass: 'route-editor-warning'
    })
  }, 1000)

  const { model, editorState } = map.$store.state.publicTransport
  const { ids } = model

  DrawRoutes.onKeyUp = async function(state, e) {
    const cancelDrawing = () => {
      this.deleteFeature([state.line.id], { silent: true })
      editorState.isDrawing = false
      clearCustomState()
      clearAllRouteGeometry(this.map)
      this.updateUIClasses({ mouse: 'add' })

      // return this.changeMode(Constants.modes.SIMPLE_SELECT, {
      //   featureIds: [state.line.id]
      // })
      return this.changeMode('draw_lines')
    }
    if (CommonSelectors.isEscapeKey(e)) {
      cancelDrawing()
    }

    const { routePoints } = customState
    const { code, ctrlKey } = e
    if (code === 'KeyZ' && ctrlKey) {
      if (routePoints.length === 0) return
      else if (routePoints.length === 1) {
        cancelDrawing()
        return
      } else if (routePoints.length === 2) {
        customState.routePoints = [routePoints[0]]
        clearAllRouteGeometry(this.map)
        createRoutePoints(
          this.map,
          customState.routePoints.map(c => c.coordinates)
        )
        clearAllHelpers(this.map)
        return
      }

      const points = customState.routePoints.slice(0, -1)
      const locString = getWaypointsQuery(cloneDeep(points))
      const url = `pubtran_route/${model.id}/${locString}?postprocessing=line_route_item`
      const { data } = await map.$store.dispatch('ROUTE_REQUEST', {
        url
      })
      const { geometry, line_route_items, waypoints } = data

      line_route_items.forEach(item => {
        delete item.node
        delete item.stop_point
      })

      customState.routePoints = points
      customState.currentRouteCoordinates = geometry.coordinates
      customState.fixedRouteCoordinates = cloneDeep(geometry.coordinates)
      customState.lineRouteItems = line_route_items
      customState.waypoints = waypoints

      createRouteHelper(this.map, geometry.coordinates)
      createRoutePoints(
        this.map,
        customState.routePoints.map(c => c.coordinates)
      )
    }

    if (code === 'Enter') {
      if (routePoints.length < 2) return

      const { isLastSP, fixedRouteCoordinates } = customState
      if (!isLastSP) return showStopsWarnings(map)

      customState.currentRouteCoordinates = cloneDeep(fixedRouteCoordinates)
      createRouteHelper(this.map, fixedRouteCoordinates)

      this.finishRoute(state)
    }
  }

  DrawRoutes.makeRoutingRequest = debounce(async function(currentPoints) {
    try {
      map.$store.commit('SET_PT_EDITOR_PROP', {
        field: 'routingRequesting',
        value: true
      })

      const locString = getWaypointsQuery(cloneDeep(currentPoints))
      const url = `pubtran_route/${model.id}/${locString}?postprocessing=line_route_item`

      const { data } = await map.$store.dispatch('ROUTE_REQUEST', {
        url
      })
      let { geometry, line_route_items, waypoints } = data

      line_route_items = line_route_items.map(point => {
        const geom = point.node_id ? point.node : point.stop_point
        delete point.node
        delete point.stop_point

        return { ...point, geom }
      })

      customState.currentRouteCoordinates = cloneDeep(geometry.coordinates)
      customState.lineRouteItems = cloneDeep(line_route_items)
      customState.waypoints = waypoints

      createRouteHelper(this.map, geometry.coordinates)

      map.$store.commit('SET_PT_EDITOR_PROP', {
        field: 'routingRequesting',
        value: false
      })
    } catch (error) {
      console.log(error)
      showError(map)

      map.$store.commit('SET_PT_EDITOR_PROP', {
        field: 'routingRequesting',
        value: false
      })
    }
  }, 300)

  DrawRoutes.finishRoute = function(state) {
    const {
      routePoints,
      currentRouteCoordinates,
      lineRouteItems,
      waypoints
    } = customState

    editorState.isDrawing = false

    const newCoordinates = [
      lineRouteItems[0].geom.coordinates,
      ...currentRouteCoordinates
    ]

    state.line.setCoordinates(newCoordinates)
    clearCustomState()

    state.line.setProperty('line_route_items', lineRouteItems)
    state.line.setProperty(
      'waypoints',
      routePoints.map(({ id }) => id)
    )

    this.changeMode(Constants.modes.SIMPLE_SELECT, {
      featureIds: [state.line.id]
    })

    clearAllRouteGeometry(this.map)
    removeHoveredSPHelpers(this.map)

    // save line_route_items to store
    map.$store.commit('SET_PT_EDITOR_PROP', {
      field: 'lineRouteItems',
      value: lineRouteItems
    })
    map.$store.commit('SET_PT_EDITOR_PROP', {
      field: 'waypoints',
      value: waypoints
    })
  }

  DrawRoutes.onMouseMove = function(state, e) {
    const stopPointFeatures = getFeaturesByLayerId(this.map, e, ids.stop_points)
    const nodesFeatures = getFeaturesByLayerId(this.map, e, ids.nodes)
    const pointFeatures = [...stopPointFeatures, ...nodesFeatures]
    const { routePoints, fixedRouteCoordinates } = customState

    if (pointFeatures.length) {
      const { lng, lat } = e.lngLat
      const feature = getNearestFeature(pointFeatures, [lng, lat])
      const { properties } = feature
      const { coordinates } = feature.geometry

      if (
        !customState.currentPoint ||
        customState.currentPoint !== feature.properties.id
      ) {
        createHoveredSPHelper(this.map, coordinates, 'green')
        customState.currentPoint = feature.properties.id

        if (routePoints.length > 0) {
          const currentPoints = [
            ...cloneDeep(routePoints),
            cloneDeep({
              coordinates,
              id: properties.id
            })
          ]
          this.makeRoutingRequest(currentPoints)
        }
      }
    } else {
      if (routePoints.length > 1) {
        createRouteHelper(this.map, fixedRouteCoordinates)
      } else createRouteHelper(this.map, [])

      removeHoveredSPHelpers(this.map)
      customState.currentPoint = null
    }

    if (CommonSelectors.isVertex(e)) {
      this.updateUIClasses({ mouse: Constants.cursors.POINTER })
    }
  }

  DrawRoutes.clickAnywhere = function(state, e) {
    if (editorState.routeActiveTab !== 'route-stops') {
      map.$store.commit('SET_PT_EDITOR_PROP', {
        field: 'routeActiveTab',
        value: 'route-stops'
      })
    }

    const stopPointFeatures = getFeaturesByLayerId(
      this.map,
      e,
      ids.stop_points,
      25
    )
    const nodesFeatures = getFeaturesByLayerId(this.map, e, ids.nodes, 25)
    const pointFeatures = [...stopPointFeatures, ...nodesFeatures]
    const { routePoints, currentRouteCoordinates } = customState

    const { lng, lat } = e.lngLat
    const feature = getNearestFeature(pointFeatures, [lng, lat])
    const pointLayer = feature.layer

    if (routePoints.length === 0 && pointLayer.id !== ids.stop_points) {
      showStopsWarnings(map)

      return
    }

    if (!pointFeatures.length) return

    if (routePoints.length === 0) editorState.isDrawing = true

    const pointId = feature.properties.id
    const pointCoordinates = feature.geometry.coordinates
    const lastPoint = routePoints[routePoints.length - 1] || {}
    const lastCoords = lastPoint.coordinates

    if (routePoints.length > 0) {
      if (
        pointCoordinates[0] === lastCoords[0] &&
        pointCoordinates[1] === lastCoords[1]
      ) {
        if (pointLayer.id !== ids.stop_points) {
          showStopsWarnings(map)
          return
        }

        this.finishRoute(state)

        return
      }
    }

    routePoints.push({ coordinates: pointCoordinates, id: pointId })
    customState.fixedRouteCoordinates = cloneDeep(currentRouteCoordinates)
    customState.isLastSP = pointLayer.id === ids.stop_points
    createRoutePoints(
      this.map,
      routePoints.map(c => c.coordinates)
    )

    this.updateUIClasses({ mouse: 'add' })
  }

  return { ...MapboxDraw.modes.draw_line_string, ...DrawRoutes }
}
