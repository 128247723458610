<template>
  <div class="pass-register__header">
    <r-title>{{ title }}</r-title>
    <r-button
      type="primary"
      @click="$emit('add')"
    >
      {{ buttonText }}
    </r-button>
    <export-button
      name="Реестр пропусков"
      class="end"
      :uri="exportUrl"
    />
  </div>
</template>

<script>
import exportButton from '@/components/export-button'
import { exportConfig } from '../../configs'
import { makeExportUrl } from '@/utils'

export default {
  components: { exportButton },
  props: {
    title: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    uri: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      exportUrl: ''
    }
  },
  watch: {
    uri() {
      this.initUrl()
    }
  },
  mounted() {
    this.initUrl()
  },
  methods: {
    initUrl() {
      this.exportUrl = makeExportUrl(this.uri, exportConfig)
    }
  }
}
</script>

<style lang="scss">
.pass-register__header {
  flex: 1;
  display: grid;
  justify-items: start;
  grid-gap: 2rem;
  grid-auto-flow: column;
  align-items: center;
  padding: 0.5rem 1rem;
  grid-template-columns: auto 1fr auto;
  background-color: var(--bg_panel_primary);
  border-bottom: 1px solid var(--dividers_low_contrast);

  .r-button {
    justify-self: start;
  }

  .end {
    justify-self: end;
  }
}
</style>
