import { Constants } from '@/libs/map-draw/lib-utils'
import {
  getPointFeatures,
  getLinkFeatures,
  createHoveredSPHelper,
  getStopPointsData,
  createStopPointsHelpers,
  removeStopPointsHelpers,
  removeHoveredSPHelpers,
  nearestPointOnLine,
  isPointInLinkArea
} from '@/libs/map-draw/helpers'

export const createEEDrawPointsMode = (MapboxDraw, editor, component) => {
  const DrawPoints = {}
  const customState = {
    hoveredFeatureId: '',
    currentPoint: null,
    projectionPoint: null
  }

  DrawPoints.onMouseOut = function() {
    // removeStopPointsHelpers(this.map);
  }

  DrawPoints.onTap = DrawPoints.onClick = function(state) {
    const { hoveredFeatureId, currentPoint, projectionPoint } = customState

    if (hoveredFeatureId && currentPoint) {
      const { coordinates } = projectionPoint
      this.updateUIClasses({ mouse: Constants.cursors.MOVE })
      state.point.updateCoordinate('', coordinates[0], coordinates[1])

      // attributes editing helper
      editor.creatingCallback = () => {
        removeStopPointsHelpers(this.map)
        this.map.fire(Constants.events.CREATE, {
          features: [state.point.toGeoJSON()]
        })
        this.changeMode(Constants.modes.SIMPLE_SELECT, {
          featureIds: [state.point.id]
        })
      }
      editor.activeObject = state.point
      component.$store.commit('SET_EE_STATE_PROP', {
        name: 'activeCard',
        value: true
      })
      component.$store.commit('SET_EE_STATE_PROP', {
        name: 'overlay',
        value: true
      })
    }
  }

  DrawPoints.onMouseMove = function(state, e) {
    const { lng, lat } = e.lngLat
    const nodesFeatures = getPointFeatures(this.map, editor, e, 'nodes')
    const linksFeatures = getLinkFeatures(this.map, editor, e)

    if (nodesFeatures.length) {
      const feature = nodesFeatures[0]
      const { coordinates } = feature.geometry

      if (customState.hoveredFeatureId !== feature.properties.id) {
        removeStopPointsHelpers(this.map)
        customState.hoveredFeatureId = ''

        createHoveredSPHelper(this.map, coordinates)
        const { id } = feature.properties
        customState.hoveredFeatureId = id
        customState.currentPoint = {
          type: 'Point',
          coordinates
        }
        customState.projectionPoint = {
          type: 'Point',
          coordinates
        }
      }
    } else if (linksFeatures.length) {
      const link = linksFeatures.find(l =>
        isPointInLinkArea([lng, lat], l.geometry.coordinates, 100)
      )

      if (link) {
        removeHoveredSPHelpers(this.map)
        const nearest = nearestPointOnLine(
          [lng, lat],
          link.geometry.coordinates
        )
        const current = {
          geometry: {
            type: 'Point',
            coordinates: [lng, lat]
          },
          properties: {
            projection: 'true'
          }
        }
        const connection = {
          geometry: {
            type: 'LineString',
            coordinates: [nearest.geometry.coordinates, [lng, lat]]
          }
        }

        const { id } = link.properties
        customState.hoveredFeatureId = id
        customState.currentPoint = current.geometry
        customState.projectionPoint = nearest.geometry

        const data = getStopPointsData(nearest, current, connection)
        createStopPointsHelpers(this.map, data)
      } else {
        removeStopPointsHelpers(this.map)
        customState.hoveredFeatureId = ''
        customState.currentPoint = null
        customState.projectionPoint = null
      }
    } else {
      removeStopPointsHelpers(this.map)
      customState.hoveredFeatureId = ''
      customState.currentPoint = null
      customState.projectionPoint = null
    }
  }

  return { ...MapboxDraw.modes.draw_point, ...DrawPoints }
}
