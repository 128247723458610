<template>
  <simple-button
    v-if="loaded"
    slot="reference"
    :mapgl="mapgl"
    :module="module"
    :layer-id="id"
    icon="closed"
    title="Перекрытия"
    :add-handler="addHandler"
    :layers-ids="layersIds"
  />
</template>

<script>
import {
  jsonToGeojson,
  getDatasourcesByDatatype,
  getModelChildrenIds
} from '@/utils'
import { layersConfig } from '@/components/odd/map/configs'
import SimpleButton from '../buttons/simple-button'

export default {
  components: {
    SimpleButton
  },
  props: {
    mapgl: {
      type: Object,
      required: true
    },
    module: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loaded: false,
      id: '',
      layersIds: []
    }
  },
  created() {
    this.setEventsId()
  },
  methods: {
    async setEventsId() {
      try {
        // get events id
        const models = await getDatasourcesByDatatype(this, 'model')
        const model = models.find(m => !!m.is_main_model) || models[0]

        const ids = getModelChildrenIds(model.children)
        this.$set(this, 'id', ids.events)

        this.loaded = true
      } catch (error) {
        console.log(error)
      }
    },
    async addHandler() {
      try {
        if (!this.id) {
          await this.setEventsId()
        }

        const { id } = this

        // load events
        const config = {
          only: ['id', 'name', 'geom'],
          include: {
            event_class: {
              only: ['id', 'name']
            }
          },
          where: [{ field: 'geom', op: '!=', value: null }]
        }
        if (this.module === 'sit_center') {
          const nullDate = this.$rDate.format(new Date(0), 'iso')
          const currentDate = this.$rDate.format(new Date(), 'iso')

          config.where = [
            {
              field: 'start_time',
              value: `${nullDate}/${currentDate}`,
              op: 'between'
            },
            {
              field: 'end_time',
              value: currentDate,
              op: '>'
            }
          ]
        }
        const url = `objectInfo/${this.id}?config=${JSON.stringify(config)}`
        const response = await this.$store.dispatch('GET_REQUEST', {
          url
        })
        const features = Object.values(response.data).map(f => ({
          ...f,
          event_class_name: f.event_class ? f.event_class.name : null
        }))
        const geojson = jsonToGeojson(features)

        const source = this.mapgl.getSource(id)

        if (!source) {
          this.mapgl.addSource(id, {
            type: 'geojson',
            data: geojson
          })
          this.addLayers(id)
        } else {
          source.setData(geojson)
        }

        this.layersIds = [id, `${id}_lines`, `${id}_lines_icons`, `${id}_arrows`]
      } catch (error) {
        console.log(error)
      }
    },
    addLayers(id) {
      this.mapgl.addLayer({
        id: id,
        source: id,
        filter: ['==', '$type', 'Point'],
        ...layersConfig.events_points
      })
      this.mapgl.addLayer({
        id: `${id}_lines`,
        source: id,
        filter: ['==', '$type', 'LineString'],
        ...layersConfig.events_lines
      })
      this.mapgl.addLayer({
        id: `${id}_lines_icons`,
        source: id,
        filter: ['==', '$type', 'LineString'],
        ...layersConfig.events_lines_icons
      })
      this.mapgl.addLayer({
        id: `${id}_arrows`,
        source: id,
        filter: ['==', '$type', 'LineString'],
        ...layersConfig.events_arrows
      })
    }
  }
}
</script>
