const colors = [
  '#59D64E',
  '#F4D340',
  '#2E93FE',
  '#FD0879',
  '#64C7FF',
  '#B33EA9',
  '#F48540',
  '#A771FE',
  '#0043A4',
  '#2E93FE',
  '#4E78D6',
  '#12E6D6',
  '#F85C50',
  '#F375F3',
  '#B40A1B',
  '#4A586E'
]

export const pie = {
  color: colors,
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b}: {c} ({d}%)',
    textStyle: {
      fontSize: 12
    }
  },
  series: [
    {
      label: {
        show: false
      },
      name: 'Pie',
      type: 'pie',
      radius: '80%',
      center: ['50%', '55%'],
      data: [
        { value: 335, name: 'Part-1' },
        { value: 310, name: 'Part-2' },
        { value: 234, name: 'Part-3' }
      ],
      itemStyle: {
        borderWidth: 2,
        borderColor: '#292B37'
      }
    }
  ],
  animationDuration: 500
}

export const doughnut = {
  color: colors,
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b}: {c} ({d}%)',
    textStyle: {
      fontSize: 12
    }
  },
  series: [
    {
      name: 'Donuts',
      type: 'pie',
      radius: ['60%', '80%'],
      center: ['50%', '55%'],
      avoidLabelOverlap: false,
      label: {
        show: false
      },
      labelLine: {
        normal: {
          show: false
        }
      },
      data: [
        { value: 335, name: 'One' },
        { value: 310, name: 'Two' },
        { value: 234, name: 'Three' },
        { value: 135, name: 'Four' },
        { value: 1548, name: 'Five' }
      ],
      itemStyle: {
        borderWidth: 2,
        borderColor: '#292B37'
      }
    }
  ],
  animationDuration: 500
}

export const bar = {
  color: colors,
  tooltip: {
    textStyle: {
      fontSize: 12
    }
  },
  angleAxis: {
    max: 100,
    startAngle: 90,
    splitLine: {
      show: false
    },
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      show: false
    }
  },
  radiusAxis: {
    type: 'category',
    z: 10,
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      show: true
    }
  },
  polar: {
    z: 40,
    radius: 100
  },
  series: [
    {
      type: 'bar',
      data: [20],
      coordinateSystem: 'polar',
      roundCap: true,
      name: 'z',
      color: '#F4D340'
    }
  ],
  barWidth: 20,
  legend: {
    show: true,
    data: ['z', 'x', 'c']
  }
}
