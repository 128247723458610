<template>
  <div class="sidebar-hint">
    <div class="sidebar-hint__title">
      <r-icon
        name="help"
        :size="20"
      />
      <r-title
        v-if="hint.title"
        type="title-2"
      >
        {{ hint.title }}
      </r-title>
    </div>

    <img
      v-if="hint.img"
      class="sidebar-hint__img"
      :src="hint.img"
      alt="hint"
    >
    <p
      v-if="hint.text"
      class="sidebar-hint__text"
    >
      {{ hint.text }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    hint: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.sidebar-hint {
  display: grid;
  grid-gap: 0.5rem;
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;

  &__title {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: start;
  }

  &__text {
    font-size: 14px;
    color: var(--text_primary) !important;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    line-height: 1.25;
    text-align: justify;
    margin: 0;
    white-space: pre-line;
  }

  &__img {
    width: 100%;
    border-radius: var(--border-radius);
  }
}
</style>
