<template>
  <el-dialog
    width="720px"
    title="Редактирование атрибутов объекта"
    :visible="visible"
    class="map-editor-create-modal"
    @close="cancel"
  >
    <div class="map-editor-create-modal__container">
      <div
        v-if="!attributes.length"
        class="map-editor-create-modal__nodata"
      >
        У данного источника данных отсутствуют атрибуты для заполнения
      </div>
      <ul
        v-else
        class="map-editor-create-modal__attributes"
      >
        <li
          v-for="item in attributes"
          :key="item.name"
          class="map-editor-create-modal__item"
        >
          <div class="map-editor-create-modal__title">
            {{ item.name }}
          </div>
          <div class="map-editor-create-modal__value">
            <el-input
              v-if="item.type === 'string'"
              v-model="item.value"
              class="r-input"
              size="mini"
            />
            <el-input-number
              v-else
              v-model="item.value"
              class="r-input-number"
              :controls="false"
              size="mini"
              type="number"
            />
          </div>
        </li>
      </ul>
    </div>
    <div
      slot="footer"
      class="gcp-settings__actions"
    >
      <r-button
        simple
        @click="cancel"
      >
        Отменить
      </r-button>
      <r-button
        class="main"
        type="success"
        @click="saveObject"
      >
        Сохранить
      </r-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    draw: {
      type: Object,
      required: true
    },
    state: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      attributes: []
    }
  },
  created() {
    const { objectFields } = this.state

    this.attributes = objectFields.map(({ type, name }) => ({
      type,
      name,
      value: null
    }))
  },
  methods: {
    modifyType(geom_type) {
      switch (geom_type) {
        case 'multi_polygon':
          return 'polygon'
        case 'multi_line_string':
          return 'line_string'
        case 'multi_point':
          return 'point'
        default:
          return geom_type
      }
    },
    cancel() {
      const { creatingObject, geom_type } = this.state
      if (creatingObject) this.draw.delete(creatingObject.id)
      this.draw.changeMode(`draw_${this.modifyType(geom_type)}`)
      this.$emit('toggle-create-modal')
    },
    saveObject() {
      const { creatingObject, creatingCallback } = this.state

      if (creatingCallback) {
        this.attributes.forEach(({ name, value }) => {
          creatingObject.setProperty(name, value)
        })
        creatingCallback()
      }
      this.$emit('toggle-create-modal')
    }
  }
}
</script>

<style lang="scss">
.gcp-settings__actions {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  justify-content: end;
}

.map-editor-create-modal {
  &__nodata {
    color: var(--text_secondary) !important;
    font-size: 14px;
    text-align: center;
    padding: 20px;
  }

  &__attributes {
    max-height: 50vh;
    overflow: auto;
    padding: 0 8px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
  }

  &__title {
    font-size: 14px;
    font-weight: bold;
    margin-right: 20px;
    color: var(--text_secondary) !important;
  }

  &__value {
    width: 40%;

    .r-input-number {
      width: 100% !important;

      .el-input__inner {
        text-align: left !important;
      }
    }
  }
}
</style>
