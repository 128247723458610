<template>
  <simple-button
    :mapgl="mapgl"
    :module="module"
    :layer-id="layerId"
    icon="bus-hollow"
    title="Транспорт"
    :add-handler="addHandler"
    :layers-ids="layersIds"
  />
</template>

<script>
import mapboxgl from 'mapbox-gl'
import SimpleButton from '../buttons/simple-button'
import { jsonToGeojson } from '@/utils'
import { layersConfig } from '@/components/public-transport/map/configs'

export default {
  components: {
    SimpleButton
  },
  props: {
    mapgl: {
      type: Object,
      required: true
    },
    module: {
      type: String,
      required: true
    },
    vehicleCategoryId: {
      type: String,
      default: 'all_vehicles'
    }
  },
  data() {
    return {
      data: null,
      timer: null,
      refresh: 50000,
      layersIds: []
    }
  },
  computed: {
    layerId() {
      return this.vehicleCategoryId || 'all_vehicles'
    },
    filterChanged() {
      return this.$store.state.publicTransport.filteredVehicles
    },
    isLayerActive() {
      const { mapConfigs } = this.$store.state

      return mapConfigs?.activeLayers?.[this.module]?.includes(
        this.layerId
      )
    }
  },
  watch: {
    filterChanged() {
      if (!this.isLayerActive) return

      this.addHandler()
    },
    isLayerActive(v) {
      if (!v && this.timer) {
        clearInterval(this.timer)
      } else if (v) {
        this.timer = setInterval(() => {
          this.addHandler()
        }, this.refresh)
      }
    }
  },
  mounted() {
    if (this.isLayerActive) {
      this.timer = setInterval(() => {
        this.addHandler()
      }, this.refresh)
    }
  },
  beforeDestroy() {
    if (this.isLayerActive) {
      clearInterval(this.timer)
    }
  },
  methods: {
    async addHandler() {
      let { vehicleCategoryId: id } = this
      if (!id) id = 'all_vehicles'

      try {
        await this.loadVehicles()

        const data = jsonToGeojson(Object.values(this.data))
        const source = this.mapgl.getSource(id)

        if (source) {
          source.setData(data)
        } else {
          this.mapgl.addSource(id, {
            type: 'geojson',
            cluster: true,
            clusterMaxZoom: 14,
            clusterRadius: 36,
            data
          })

          this.mapgl.addLayer({
            id: `${id}_clusters`,
            source: id,
            ...layersConfig.vehicles_clusters
          })

          this.mapgl.addLayer({
            id: `${id}_clusters-count`,
            source: id,
            ...layersConfig.vehicles_clusters_count
          })

          this.mapgl.addLayer({
            id: id,
            source: id,
            ...layersConfig.vehicles
          })

          this.layersIds = [id, `${id}_clusters-count`, `${id}_clusters`]
        }
        this.addLayerHandlers(id)
        this.addLayerHandlers(`${id}_clusters`)
      } catch (error) {
        console.log(error)
      }
    },
    addLayerHandlers(layer) {
      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        offset: 15,
        anchor: 'left',
        className: 'vehicle__popup'
      })

      this.mapgl.on('mouseenter', layer, e => {
        if (!layer?.includes('clusters')) {
          this.addPopup(e, popup)
        } else {
          this.addClusterPopup(e, popup)
        }
        this.mapgl.getCanvas().style.cursor = 'pointer'
      })

      this.mapgl.on('mouseleave', layer, _ => {
        this.mapgl.getCanvas().style.cursor = ''
        popup.remove()
      })
    },
    addPopup(e, popup) {
      const html = e.features?.map(f => {
        const data = f.properties
        const { reg_number } = data
        const contractor = JSON.parse(data.contractor)

        const item = '<dl><dt>Гос. номер: </dt><dd>' + (reg_number || '—') + '</dd><dt>Подрядчик: </dt><dd>' + (contractor?.name || '—') + '</dd></dl>'

        return item
      })?.join('<hr size="1" noshade>')

      popup
        .setLngLat(e.lngLat)
        .setHTML(html)
        .addTo(this.mapgl)
    },
    addClusterPopup(e, popup) {
      const features = this.mapgl.queryRenderedFeatures(e.point, {
        layers: ['all_vehicles_clusters']
      })
      const clusterId = features[0].properties.cluster_id
      const pointCount = features[0].properties.point_count
      const clusterSource = this.mapgl.getSource('all_vehicles')

      clusterSource.getClusterLeaves(clusterId, pointCount, 0, (_, features) => {
        const html = features?.map(f => {
          const data = f.properties
          const { reg_number, contractor } = data

          const item = '<dl><dt>Гос. номер: </dt><dd>' + (reg_number || '—') + '</dd><dt>Подрядчик: </dt><dd>' + (contractor?.name || '—') + '</dd></dl>'

          return item
        })?.join('<hr size="1" noshade>')

        popup
          .setLngLat(e.lngLat)
          .setHTML(html)
          .addTo(this.mapgl)
      })
    },
    async loadVehicles() {
      try {
        const { filteredVehicles: filtered } = this.$store.state.publicTransport
        this.data = {}

        const where = []

        if (this.vehicleCategoryId) {
          where.push({
            field: 'categories.id',
            op: '=',
            value: this.vehicleCategoryId
          })
        }

        const config = JSON.stringify({
          where
        })

        const url = `vehicles_map?config=${config}`

        const { data } = await this.$store.dispatch('GET_REQUEST', { url })
        if (!data) {
          clearInterval(this.timer)
          return
        }

        let filteredData = data.filter(d => !!d.current_point)

        if (filtered) {
          filteredData = filteredData.filter(d =>
            filtered.includes(d.reg_number)
          )
        }

        filteredData.forEach(vehicle => {
          this.data[vehicle.id] = {
            ...vehicle,
            geom: vehicle.current_point,
            current_point: null
          }
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style lang="scss">
.vehicle__popup {
  z-index: 99;
  .mapboxgl-popup {
    &-tip {
      border: none !important;
    }

    &-content {
      background-color: var(--bg_surface);
      display: grid;
      align-items: center;
      justify-content: start;
      grid-gap: 0.25rem;
      grid-auto-flow: row;
      padding: 0;
      border-radius: var(--border-radius);
      border: 1px solid transparent;
      border-color: var(--dividers_low_contrast) !important;

      dl {
        padding: 0.25rem 0.5rem;
        margin: 0;
      }

      dt {
        margin: 0;
        color: var(--text_secondary);
        font-size: 12px;
      }

      dd {
        font-weight: bold;
        margin: 0;
        color: var(--text_primary);
      }
    }
  }
}
</style>
