import {
  CommonSelectors,
  Constants,
  doubleClickZoom,
  mouseEventPoint,
  createSupplementaryPoints
} from '@/libs/map-draw/lib-utils'
import {
  isPointInLinkArea,
  clearAllHelpers,
  createLinkHelper,
  undraggableTypes,
  clearAllRouteGeometry,
  getDependenciesData,
  createDependenciesHelpers,
  createDeletedDepHelpers
} from '@/libs/map-draw/helpers'

export const createSimpleSelectMode = (MapboxDraw, editor, component) => {
  const SimpleSelect = {}
  const customState = {
    hoveredLinkId: ''
  }

  SimpleSelect.onSetup = function(opts) {
    // turn the opts into state.
    const state = {
      dragMoveLocation: null,
      boxSelectStartLocation: null,
      boxSelectElement: undefined,
      boxSelecting: false,
      canBoxSelect: false,
      dragMoveing: false,
      canDragMove: false,
      initiallySelectedFeatureIds: opts.featureIds || []
    }

    this.setSelected(
      state.initiallySelectedFeatureIds.filter(id => {
        return this.getFeature(id) !== undefined
      })
    )
    this.fireActionable()

    this.setActionableState({
      combineFeatures: true,
      uncombineFeatures: true,
      trash: true
    })

    clearAllRouteGeometry(this.map)

    return state
  }

  SimpleSelect.startOnActiveFeature = function(state, e) {
    // Stop any already-underway extended interactions
    this.stopExtendedInteractions(state)

    // Disable map.dragPan immediately so it can't start
    this.map.dragPan.disable()

    // Re-render it and enable drag move
    this.doRender(e.featureTarget.properties.id)

    // Set up the state for drag moving
    state.canDragMove = undraggableTypes.indexOf(editor.geom_type) === -1
    state.dragMoveLocation = e.lngLat
  }

  SimpleSelect.onMouseMove = function(state, e) {
    if (
      (editor.geom_type === 'links' || editor.geom_type === 'connectors') &&
      !this.getSelected().length
    ) {
      const { x, y } = e.point
      const { lng, lat } = e.lngLat
      const bbox = [
        [x - Constants.PIXELS, y - Constants.PIXELS],
        [x + Constants.PIXELS, y + Constants.PIXELS]
      ]
      const features = this.map.queryRenderedFeatures(bbox, {
        layers: ['gl-draw-line-inactive.cold']
      })

      if (features.length) {
        try {
          features.forEach(f => {
            const { coordinates } = f.geometry

            if (isPointInLinkArea([lng, lat], coordinates, 50)) {
              if (f.properties.id !== customState.hoveredLinkId) {
                clearAllHelpers(this.map)

                const { coordinates, type } = this.getFeature(f.properties.id)
                createLinkHelper(this.map, type, coordinates)
                customState.hoveredLinkId = f.properties.id
              }

              throw new Error()
            }
          })
        } catch (error) {}
      } else {
        clearAllHelpers(this.map)
        customState.hoveredLinkId = ''
      }
    }

    if (editor) return this.stopExtendedInteractions(state)
  }

  SimpleSelect.clickOnFeature = function(state, e) {
    // Stop everything
    doubleClickZoom.disable(this)
    this.stopExtendedInteractions(state)

    const isShiftClick = CommonSelectors.isShiftDown(e)
    const selectedFeatureIds = this.getSelectedIds()
    const featureId =
      customState.hoveredLinkId || e.featureTarget.properties.id
    const isFeatureSelected = this.isSelected(featureId)

    clearAllHelpers(this.map)
    customState.hoveredLinkId = ''

    // Click (without shift) on any selected feature but a point
    if (
      !isShiftClick &&
      isFeatureSelected &&
      this.getFeature(featureId).type !== Constants.geojsonTypes.POINT
    ) {
      // Enter direct select mode
      return this.changeMode(Constants.modes.DIRECT_SELECT, {
        featureId: featureId
      })
    }

    // Shift-click on a selected feature
    if (isFeatureSelected && isShiftClick) {
      // Deselect it
      this.deselect(featureId)
      this.updateUIClasses({ mouse: Constants.cursors.POINTER })
      if (selectedFeatureIds.length === 1) {
        doubleClickZoom.enable(this)
      }
      // Shift-click on an unselected feature
    } else if (!isFeatureSelected && isShiftClick) {
      // Add it to the selection
      this.select(featureId)
      this.updateUIClasses({ mouse: Constants.cursors.MOVE })
      // Click (without shift) on an unselected feature
    } else if (!isFeatureSelected && !isShiftClick) {
      // Make it the only selected feature
      selectedFeatureIds.forEach(id => this.doRender(id))
      this.setSelected(featureId)
      this.updateUIClasses({ mouse: Constants.cursors.MOVE })
    }

    // No matter what, re-render the clicked feature
    this.doRender(featureId)
  }

  SimpleSelect.onTap = SimpleSelect.onClick = function(state, e) {
    // Click (with or without shift) on no feature
    if (customState.hoveredLinkId) return this.clickOnFeature(state, e)
    if (CommonSelectors.noTarget(e)) return this.clickAnywhere(state, e) // also tap
    if (CommonSelectors.isOfMetaType(Constants.meta.VERTEX)(e)) { return this.clickOnVertex(state, e) } // tap
    if (CommonSelectors.isFeature(e)) return this.clickOnFeature(state, e)
  }

  SimpleSelect.fireUpdate = function(type, feature) {
    if (type === 'center_geom') {
      const { type, coordinates } = feature
      const polygon = this.getFeature(feature.properties.id)
      polygon.setProperty('center_geom', { type, coordinates })

      this.map.fire(Constants.events.UPDATE, {
        action: Constants.updateActions.CHANGE_COORDINATES,
        features: [polygon.toGeoJSON()]
      })
      return
    }

    this.map.fire(Constants.events.UPDATE, {
      action: Constants.updateActions.MOVE,
      features: this.getSelected().map(f => f.toGeoJSON())
    })
  }

  SimpleSelect.onMouseOut = function(state) {
    // As soon as you mouse leaves the canvas, update the feature
    if (state.dragMoving) return this.fireUpdate()
    clearAllHelpers(this.map)
  }

  SimpleSelect.onMouseUp = function(state, e) {
    // End any extended interactions
    if (state.dragMoving) {
      if (
        editor.geom_type === 'zones' &&
        e.featureTarget.geometry.type === 'Point'
      ) {
        const { id } = e.featureTarget.properties
        const center_geom = this.getFeature(id)
        this.fireUpdate('center_geom', center_geom)
      } else {
        this.fireUpdate()
      }
    } else if (state.boxSelecting) {
      const bbox = [
        state.boxSelectStartLocation,
        mouseEventPoint(e.originalEvent, this.map.getContainer())
      ]
      const featuresInBox = this.featuresAt(null, bbox, 'click')
      const idsToSelect = this.getUniqueIds(featuresInBox).filter(
        id =>
          !this.isSelected(id) && !this.getFeature(id).properties.is_center_geom
      )

      if (idsToSelect.length) {
        this.select(idsToSelect)
        idsToSelect.forEach(id => this.doRender(id))
        this.updateUIClasses({ mouse: Constants.cursors.MOVE })
      }
    }
    this.stopExtendedInteractions(state)
  }

  const dependenciesTypes = {
    nodes: ['from_links', 'connectors', 'stop_point', 'to_links'],
    links: ['stop_point'],
    zones: ['connectors']
  }
  const getDependencies = (geom_type, features) => {
    if (!dependenciesTypes[geom_type]) {
      return {
        count: 0,
        objects: []
      }
    }

    let dependencies = []

    features.forEach(f => {
      const { properties } = f

      dependenciesTypes[geom_type].forEach(type => {
        if (properties[type] && properties[type].length) {
          dependencies = [
            ...dependencies,
            ...properties[type].map(f => ({
              ...f,
              type: `no ${f.no}`
            }))
          ]
        }
      })
    })

    return {
      count: dependencies.length,
      objects: dependencies
    }
  }

  SimpleSelect.onTrash = function() {
    const selectedIds = this.getSelectedIds()
    const features = this.getSelected()
    const { count, objects } = getDependencies(editor.geom_type, features)
    const deletedIds = objects.map(obj => obj.id)

    if (count) {
      const ids = [...editor.deletedDependenciesIds, ...deletedIds]
      editor.deletingCallback = () => {
        this.deleteFeature(selectedIds)
        this.fireActionable()

        // add helpers to map (deleted and edited dependencies)
        createDeletedDepHelpers(this.map, editor.modelLayers, ids)
        deletedIds.forEach(id => delete editor.dependenciesData[id])
        createDependenciesHelpers(this.map, editor.dependenciesData)
      }

      editor.dependenciesCount = count
      editor.deletedDependenciesIds = ids
      editor.deletingIds = selectedIds
      component.toggleDeleteModal(true)

      return
    }

    this.deleteFeature(selectedIds)
    this.fireActionable()
  }

  SimpleSelect.onDrag = function(state, e) {
    if (state.canDragMove) {
      // draw dependencies map geometry
      this.dragMove(state, e)
      if (editor.geom_type === 'nodes') {
        const { count, objects } = getDependencies(
          editor.geom_type,
          this.getSelected()
        )

        if (count) {
          const point = this.getSelected()

          getDependenciesData(editor, objects, point[0].coordinates)
          createDependenciesHelpers(this.map, editor.dependenciesData)
        }
      }

      return
    }
    if (this.drawConfig.boxSelect && state.canBoxSelect) { return this.whileBoxSelect(state, e) }
  }

  SimpleSelect.onMouseUp = function(state, e) {
    // End any extended interactions
    if (state.dragMoving) {
      this.fireUpdate()
    } else if (state.boxSelecting) {
      const bbox = [
        state.boxSelectStartLocation,
        mouseEventPoint(e.originalEvent, this.map.getContainer())
      ]
      const featuresInBox = this.featuresAt(null, bbox, 'click')
      const idsToSelect = this.getUniqueIds(featuresInBox).filter(
        id => !this.isSelected(id)
      )

      if (idsToSelect.length) {
        this.select(idsToSelect)
        idsToSelect.forEach(id => this.doRender(id))
        this.updateUIClasses({ mouse: Constants.cursors.MOVE })
      }
    }
    this.stopExtendedInteractions(state)
  }

  SimpleSelect.toDisplayFeatures = function(state, geojson, display) {
    geojson.properties.active = this.isSelected(geojson.properties.id)
      ? Constants.activeStates.ACTIVE
      : Constants.activeStates.INACTIVE
    display(geojson)
    this.fireActionable()
    if (
      geojson.properties.active !== Constants.activeStates.ACTIVE ||
      geojson.geometry.type === Constants.geojsonTypes.POINT
    ) { return }

    createSupplementaryPoints(geojson).forEach(display)
  }

  return { ...MapboxDraw.modes.simple_select, ...SimpleSelect }
}
