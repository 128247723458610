<template>
  <div class="table-prime-simple r-table">
    <DataTable
      class="p-datatable-sm p-datatable-gridlines"
      :value="data"
      :scrollable="true"
      scroll-height="flex"
      :paginator="true"
      :rows="25"
      :rows-per-page-options="[10, 25, 50]"
      data-key="id"
      :selection.sync="selectedObjects"
    >
      <Column
        selection-mode="multiple"
        header-style="width: 40px"
      />
      <Column
        v-for="item in columns"
        :key="item.header"
        :field="item.title"
        :header="item.name"
        :header-style="getHeaderStyle(item)"
      >
        <template #body="slotProps">
          <div
            v-if="item.type === 'boolean'"
            class="table-prime-simple__cell center"
          >
            <r-icon
              v-if="getValue(item, slotProps.data)"
              name="check"
              :size="20"
            />
          </div>
          <div
            v-else
            class="table-prime-simple__cell"
          >
            <span class="table-prime-simple__cell-inner">
              {{ getValue(item, slotProps.data) }}
            </span>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'

import { formatValue } from './helpers'

export default {
  components: {
    DataTable,
    Column
  },
  props: {
    tableId: {
      type: String,
      default: null
    },
    data: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    allChecked: {
      type: Boolean,
      default: false
    },
    columnWidth: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      isReady: false,
      page: 0,
      tableData: null,
      activeColumns: null,
      selectedObjects: null
    }
  },
  watch: {
    selectedObjects: {
      handler: function(val) {
        if (this.tableId) {
          this.$store.commit('TABLE_PRIME_SIMPLE_SET_FIELD', {
            tableId: this.tableId,
            field: 'selectedObjects',
            value: val
          })
        }

        if (!this.isReady) return

        this.$emit('selected-changed', val)
      },
      deep: true
    }
  },
  mounted() {
    this.selectAll()
  },
  methods: {
    selectAll() {
      if (this.allChecked) {
        this.selectedObjects = this.data.map(e => e)
      } else {
        this.selectedObjects = []
      }
      this.$nextTick(() => (this.isReady = true))
    },
    getValue(item, obj) {
      const value = obj[item.title] || obj[item.origin_title]
      return formatValue(item, value)
    },
    getHeaderStyle({ title }) {
      const itemWidth = this.columnWidth?.[title] || '120px'

      return `width: ${itemWidth};`
    }
  }
}
</script>

<style lang="scss">
.table-prime-simple {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  height: 100%;
  position: relative;
  width: 100%;
  .p-datatable {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
    &-thead {
      height: 40px;
      th {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        color: var(--text_subhead);
        text-transform: uppercase;
        &:first-child {
          padding: 0 !important;
        }
      }
    }
    &-scrollable {
      &-wrapper {
        max-height: calc(100% - 60px);
        height: auto;
      }
      &-header {
        background: none !important;
      }
    }
    &-header {
      background: none !important;
      border: none !important;
    }
    .p-paginator {
      background: none !important;
      border: none !important;
      .p-link {
        color: var(--text_subhead);
        &:hover {
          color: var(--text_subhead) !important;
          background-color: var(--accent_hover) !important;
        }
        &.p-highlight {
          color: var(--text_subhead) !important;
          background-color: var(--accent_active) !important;
        }
        &.p-disabled {
          opacity: 0.4;
        }
      }
      .p-dropdown {
        background-color: var(--field_bg);
        border-color: var(--field_border);
        box-shadow: none !important;
        &-panel {
          background-color: var(--field_droplist_bg);
        }
        &-item {
          color: var(--text_primary);
          &:hover {
            color: var(--text_primary) !important;
            background-color: var(--accent_hover) !important;
          }
          &.p-highlight {
            color: var(--text_primary) !important;
            background-color: var(--accent_active) !important;
          }
        }
      }
    }
    td {
      border-color: var(--table_border)!important;
      position: relative;
      padding: 0 !important;
      .table-prime-simple__cell {
        position: relative;
        color: var(--text_primary) !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        font-family: var(--font-family) !important;
        min-height: 32px;
        display: flex;
        align-items: center;
        &-inner {
          padding: 4px 8px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        em.el-icon-arrow-down {
          display: none;
          position: absolute;
          top: 51%;
          right: 10px;
          transform: translateY(-50%);
        }
        &:hover {
          em.el-icon-arrow-down {
            display: block;
          }
        }
        &.center {
          justify-content: center;
        }
      }
      .highlight {
        background-color: var(--accent_primary) !important;
        font-weight: 500;
        color: var(--white_white) !important;
      }
      &:first-child {
        background-color: var(--table_header_bg);
      }
    }
    .p-checkbox {
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
      &-box {
        border-color: var(--icons_low_contrast) !important;
        outline: none !important;
        background: none !important;
        box-shadow: none !important;
        width: 18px;
        height: 18px;
        &.p-highlight {
          background-color: var(--accent_primary) !important;
          border-color: var(--accent_primary) !important;
        }
        .p-checkbox-icon {
          font-size: 8px;
          font-weight: 900;
        }
      }
    }
  }
}
</style>
