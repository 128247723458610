<template>
  <ul class="rp-roles-datasources-list-cruds">
    <li
      v-for="crud in crudsTypes"
      :key="crud.id"
      :class="['rp-roles-datasources-list-cruds__item', { active: isActive(crud.index) }]"
      @click="clickHandler($event, crud.index)"
    >
      <r-text
        type="caption"
        color-type="primary"
      >
        {{ crud.name }}
      </r-text>
    </li>
  </ul>
</template>

<script>
import vue from 'vue'
import { crudsTypes } from './configs'
import { hasCrudsChanges } from './helpers'

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      crudsTypes
    }
  },
  computed: {
    roleDatasources() {
      return this.$store.state.rolePermission.rolePerms?.datasources || []
    },
    initialRoleDatasources() {
      return this.$store.state.rolePermission.initialRolePerms?.datasources || []
    },
    multiEdit() {
      return this.$store.state.rolePermission.multiEdit || null
    }
  },
  methods: {
    clickHandler(e, crud) {
      e.stopPropagation()
      if (this.multiEdit) return
      if (crud === 1) {
        vue.set(this.item.cruds, crud, true)
        if (this.item.children?.length) {
          this.item.children.forEach(e => {
            e.cruds[crud] = true
            vue.set(e.cruds, crud, true)
          })
        }
        return
      }

      if (!this.item.cruds) return
      const crudValues = !this.item.cruds[crud]
      vue.set(this.item.cruds, crud, crudValues)
      if (this.item.children?.length) {
        this.item.children.forEach(e => {
          vue.set(e.cruds, crud, crudValues)
        })
      }
      const hasChanges = hasCrudsChanges(this.initialRoleDatasources, this.item)

      if (hasChanges) {
        if (this.item.children?.length) {
          this.item.children.forEach(e => {
            vue.set(e, '_action', 'updated')
          })
        }
        vue.set(this.item, '_action', 'updated')
      } else if (this.item._action === 'updated') {
        this.item.children.forEach(e => {
          vue.set(e, '_action', undefined)
        })
        vue.set(this.item, '_action', undefined)
      }
    },
    isActive(crud) {
      return this.item?.cruds[crud]
    }
  }
}
</script>

<style lang="scss">
.rp-roles-datasources-list-cruds {
  position: relative;
  display: flex;
  align-items: center;

  &__item {
    position: relative;
    border-radius: var(--border-radius);
    border: 1px solid var(--dividers_low_contrast);
    padding: 2px 4px;
    margin-right: 6px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: var(--accent_hover);
    }

    &.active {
      background-color: var(--accent_selected);
      border-color: var(--accent_selected);
    }

    &:active {
      background-color: var(--accent_active);
    }
  }
}
</style>
