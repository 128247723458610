import mapboxgl from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'

import { mapToken } from '@/constants/tokens'
import { MAP_ICON_SIZE, icons } from '@/config/icons'
import {
  getMapParams,
  openObjectInfo,
  changeObjectInfo,
  getDatasourceById
} from '@/utils'
import { ModesController } from '@/libs/map-draw/modes/modes-controller'
import { editorStyles } from '@/libs/map-draw/config'
import {
  LayerController,
  StyleController,
  HeatmapController,
  HistoryController,
  DiagramController,
  MatricesController,
  EditorController
} from '../controllers'
import mapboxStyles from '@/constants/mapbox_styles'

export const initMap = async component => {
  // create map instance
  const { center, zoom } = getMapParams('mapGIS')
  const baselayer = component.$store.state.map.baselayers.find(
    l => l.id === component.baselayerId
  )
  const name = baselayer ? baselayer.name : 'dark'
  const style = mapboxStyles[name]

  mapboxgl.accessToken = mapToken

  component.mapgl = new mapboxgl.Map({
    container: 'map-container',
    preserveDrawingBuffer: true,
    style,
    center,
    zoom
  })

  // map view handlers
  component.mapgl.on('pitch', e => {
    component.is3d = e.target.getPitch() !== 0
  })
  component.mapgl.on('rotate', e => {
    component.mapBearing = e.target.getBearing()
  })

  // map mouse move and click handlers
  component.mapgl.on('mousemove', e => {
    const { activeLayers } = component.$store.state.map
    const layers = [...activeLayers, ...activeLayers.map(l => `${l}_icons`)]
    const mapLayers = layers.filter(l => component.mapgl.getLayer(l))
    if (!mapLayers.length) return

    const { x, y } = e.point
    const bbox = [
      [x - 5, y - 5],
      [x + 5, y + 5]
    ]
    const features = component.mapgl.queryRenderedFeatures(bbox, {
      layers: mapLayers
    })

    if (!features.length) {
      component.mapgl.getCanvas().style.cursor = ''
      component.popupSettings.display = 'none'
      component.popupSettings.values = []
      component.popupListSettings.display = 'none'
      component.popupListSettings.values = []
      return
    }

    component.mapgl.getCanvas().style.cursor = 'pointer'
    const feature = features[0]
    const { properties, layer } = feature
    const layerId = layer.id.includes('_') ? layer.id.split('_')[0] : layer.id
    const { fields, enabled } = component.styleConfig[layerId].popup
    if (fields.length && enabled) {
      component.popupSettings.values = fields.map(key => ({
        key,
        value: properties[key] === 'null' ? null : properties[key]
      }))
      component.popupSettings.top = y - 38
      component.popupSettings.left = x + 10
      component.popupSettings.display = 'block'
    }
  })

  component.mapgl.on('click', e => {
    const { activeLayers } = component.$store.state.map
    const layers = [...activeLayers, ...activeLayers.map(l => `${l}_icons`)]
    const mapLayers = layers.filter(l => component.mapgl.getLayer(l))
    if (!mapLayers.length) return

    const { x, y } = e.point
    const bbox = [
      [x - 5, y - 5],
      [x + 5, y + 5]
    ]
    const features = component.mapgl.queryRenderedFeatures(bbox, {
      layers: mapLayers
    })
    if (!features.length) return
    if (features.length > 1) {
      component.popupListSettings.top = y - 38
      component.popupListSettings.left = x - 10
      component.popupListSettings.display = 'block'
      component.popupListSettings.values = features
    } else {
      const feature = features[0]
      const { properties, layer } = feature
      const layerId = layer.id.includes('_') ? layer.id.split('_')[0] : layer.id
      const source_id = component.$store.state.profiles.sourceIdById[layerId]

      if (e.originalEvent.ctrlKey || e.originalEvent.metaKey) {
        openObjectInfo(component, source_id, properties.id, 'map', feature)
      } else {
        changeObjectInfo(component, source_id, properties.id, 'map', feature)
      }
    }
  })

  // save map params handler
  window.addEventListener('beforeunload', component.saveMapParams)

  // load icons after map loaded
  const loadIcons = () => {
    function importAll(r) {
      const images = {}
      r.keys().map((item, index) => {
        images[item.replace('./', '')] = r(item)
      })
      return images
    }

    const images = importAll(
      require.context(
        '../../../assets/images/map-icons',
        false,
        /\.(png|jpe?g|svg)$/
      )
    )

    component.$store.commit('SET_ICONS_CONTEXT', images)

    return Promise.all(
      icons.map(
        ({ name }) =>
          new Promise(resolve => {
            const url = images[name]
            const image = new Image(MAP_ICON_SIZE, MAP_ICON_SIZE)
            image.crossOrigin = 'Anonymous'
            image.style.backgroundPosition = '50%, 50%'
            image.style.backgroundSize = '100%'
            image.addEventListener('load', () => {
              component.mapgl.addImage(name, image)
              resolve()
            })
            image.src = url
          })
      )
    ).then(() => console.log('map icons loaded!'))
  }

  component.mapgl.on('load', async() => {
    await loadIcons()

    const { initialActiveLayers } = component.$store.state.map

    if (initialActiveLayers && initialActiveLayers.length) {
      setTimeout(async() => {
        for (let i = 0; i < initialActiveLayers.length; i++) {
          const id = initialActiveLayers[i]
          const source_id = component.$store.state.profiles.sourceIdById[id]
          const layer = await getDatasourceById(component, source_id)

          if (layer) {
            await component.controllers.layers.toggleLayer({ id })
          }
        }
      }, 2000)
    }
  })
}

export const initControllers = component => {
  const { controllers } = component

  controllers.layers = new LayerController(component)
  controllers.style = new StyleController(component)
  controllers.diagram = new DiagramController(component)
  controllers.heatmap = new HeatmapController(component)
  controllers.matrices = new MatricesController(component)
  controllers.history = new HistoryController(component)

  // editor creation
  const mc = new ModesController(component, MapboxDraw, 'editorState')
  component.draw = new MapboxDraw({
    displayControlsDefault: false,
    controls: {
      line_string: true,
      point: true,
      polygon: true,
      trash: true
    },
    styles: editorStyles,
    userProperties: true,
    modes: Object.assign(MapboxDraw.modes, {
      draw_point: mc.createDrawPointMode(),
      draw_line_string: mc.createDrawLineStringMode(),
      draw_polygon: mc.createDrawPolygonMode(),
      draw_nodes: mc.createDrawNodesMode(),
      draw_links: mc.createDrawLinksMode(),
      draw_zones: mc.createDrawZonesMode(),
      draw_connectors: mc.createDrawConnectorsMode(),
      simple_select: mc.createSimpleSelectMode(),
      link_splice: mc.createLinkSpliceMode(),
      direct_select: mc.createDirectSelectMode()
    })
  })

  controllers.editor = new EditorController(component)
  controllers.editor.initDrawListeners()
}
