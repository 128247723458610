<template>
  <div class="pt-route-schedule__header">
    <div class="pt-route-schedule__control">
      <r-button
        v-if="schedule.length"
        type="success"
        :loading="saving"
        @click="$emit('send')"
      >
        Сохранить и выйти
      </r-button>
      <r-text v-else>
        Нет ни одного рейса.
      </r-text>
      <r-delete-button
        v-if="schedule.length > 1"
        simple
        class="pt-route-schedule__control-button"
        mini
        @delete="$emit('delete')"
      >
        Удалить рейс
      </r-delete-button>
    </div>
    <ul class="start-time__tabs">
      <li
        v-for="(line, index) in schedule"
        :key="line.id + index"
        :class="[
          'start-time__tab-item',
          line.id === currentLineId ? 'active' : ''
        ]"
        @click="$emit('line', line.id)"
      >
        <el-popover
          v-if="isNumber(line.id)"
          placement="bottom"
          popper-class="start-time__popover-item"
          width="200"
          trigger="hover"
          :content="popoverText"
        >
          <r-text
            slot="reference"
            :weight="500"
          >
            <r-icon
              name="alert"
              color="var(--accent_warning)"
              :size="18"
            />
            {{ line.name.slice(0, 5) }}
          </r-text>
        </el-popover>
        <r-text
          v-else
          :weight="500"
        >
          {{ line.name.slice(0, 5) }}
        </r-text>
      </li>

      <li class="start-time__add-new">
        <el-time-picker
          v-if="creatingNewLine"
          ref="time"
          v-model="newLine"
          format="HH:mm"
          value-format="HH:mm"
          popper-class="build-time-popper"
          prefix-icon="none"
          clear-icon="none"
          type="number"
        />
        <r-button
          type="primary"
          icon="add-plus"
          @click="$emit('add')"
        >
          Добавить
        </r-button>
      </li>
    </ul>
  </div>
</template>

<script>
import { isNumber } from '@/utils'

export default {
  props: {
    schedule: {
      type: Array,
      required: true
    },
    currentLineId: {
      type: [String, Number],
      default: null
    },
    creatingNewLine: {
      type: Boolean,
      default: false
    },
    newLineTime: {
      type: String,
      default: ''
    },
    saving: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      newLine: null,
      isNumber,
      popoverText:
        'Изменения сохранятся после сохранения. Времена отправления и прибытия по всем остановкам будут пересчитаны автоматически.'
    }
  },
  watch: {
    newLineTime() {
      if (this.newLineTime === null) {
        this.newLine = null
      }
    },
    newLine() {
      this.$emit('new', ['newLineTime', this.newLine])
    }
  }
}
</script>

<style lang="scss">
.pt-route-schedule {
  height: 100%;
  align-content: start;

  &__header {
    grid-area: header;
    display: grid;
    grid-gap: 1rem;
    grid-auto-flow: row;
    position: sticky;
    background-color: var(--modal_bg);
    top: 0;
    width: 100%;
    z-index: 99;

    .start-time {
      &__tabs {
        width: 100%;
        padding-bottom: 0.5rem;
        display: grid;
        grid-gap: 0.25rem 0;
        grid-template-columns: repeat(9, minmax(80px, 1fr));
        grid-template-rows: repeat(auto-fill, 37px);

        // li + li.start-time__add-new {
        //   margin-left: 0.5rem;
        //   display: grid;
        // }
      }

      &__add-new {
        display: flex;
        align-items: center;
        height: 100%;
        grid-column: span 2;

        .r-button {
          width: 200px;
        }

        .el-input {
          &__inner {
            width: 60px !important;
            padding: 0.5rem !important;
            text-align: center;
            height: 37px !important;
            border: 1px solid var(--dividers_low_contrast) !important;
            border-right: 0;
            border-left: 0;
          }
        }
      }

      &__delete-current {
        display: grid;
        align-items: center;
        justify-content: center;
        height: 37px;
      }

      &__tab-item {
        background-color: var(--bg_containers);
        box-shadow: 1px 0px 0px 0px var(--field_border);
        cursor: pointer;
        display: grid;
        justify-content: center;
        align-items: center;
        transition: 0.15s;

        .r-text {
          display: grid;
          justify-content: center;
          grid-gap: 0.25rem;
          align-items: center;
          grid-auto-flow: column;
        }

        &:nth-last-of-type(-n + 2) {
          margin-right: 0.5rem;
        }

        &:nth-last-child(-n + 2) {
          border-radius: 0 var(--border-radius) var(--border-radius) 0 !important;
          border-right: 0;
          box-shadow: none;
        }

        &:nth-child(9n) {
          border-radius: 0 var(--border-radius) var(--border-radius) 0 !important;
          box-shadow: none;
        }

        &:nth-child(9n + 1) {
          border-radius: var(--border-radius) 0 0 var(--border-radius) !important;
        }

        &:first-child {
          border-radius: var(--border-radius) 0 0 var(--border-radius) !important;
        }

        &:first-child,
        &:nth-child(9n + 1) {
          &:nth-last-child(-n + 2) {
            border-radius: var(--border-radius) !important;
          }
        }

        &:hover {
          background-color: var(--accent_hover);
        }

        &.active {
          background-color: var(--accent_active);
        }
      }

      &__new-item {
        padding: 0.25rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 100%;
        border-radius: var(--border-radius);
        border: 0;
        background-color: transparent;

        &:hover,
        &:active,
        &:focus {
          background-color: transparent;
          svg {
            fill: var(--accent_active);
          }
        }
      }
    }
  }

  &__control {
    display: grid;
    grid-gap: 0.5rem;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;

    &-button {
      font-weight: 400;
      margin: 0;

      &:hover {
        span {
          .r-text,
          .r-icon {
            opacity: 0.75;
            transition: all 0.15s ease;
          }
        }
      }
    }
  }
}

.start-time__popover-item.el-popover {
  background-color: var(--field_droplist_bg);
  color: var(--text_primary);
  padding: 0.5rem !important;
}
</style>
