import mapboxgl from 'mapbox-gl'
import { reportOptions } from '../configs'
import { jsonToGeojson, rDate } from '@/utils'
import { layersConfig } from '../components/an-map/configs'
import cloneDeep from 'lodash.clonedeep'

export class PassingReportHourlyAll {
  constructor(state) {
    this.$store = state.$store
    this.state = state
    this.mapgl = state.mapgl

    this.options = reportOptions.passing_report_hourly_all
  }

  getTableData(data) {
    const parsedData = data.map(e => {
      if (e.name === 'Всего') {
        return e
      }
      return {
        from_time: e.from_time,
        to_time: e.to_time,
        result: e.result,
        total: e.total
      }
    })
    return parsedData?.sort((a, b) => {
      const aValue = rDate.format(a.from_time, 'HH')
      const bValue = rDate.format(b.from_time, 'HH')
      if (aValue < bValue) return -1
      if (aValue > bValue) return 1
      return 0
    }).map(e => {
      const item = {}

      item['время'] = rDate.format(e.from_time, 'HH:mm') + ' - ' + rDate.format(e.to_time, 'HH:mm')
      if (e?.result) {
        e.result.forEach(s => {
          item[s.name] = s.value
        })
      }
      if (e.name === 'Всего') {
        item['время'] = 'Всего'
        e.result.forEach(s => {
          item[s.name] = s.value
        })
      }

      return item
    })
  }

  getChartData(_, tableData) {
    const cloneData = cloneDeep(tableData)
    const chartData = {
      data: [],
      fields: {
        data: [],
        title: 'Время'
      }
    }

    if (cloneData) {
      cloneData.forEach(e => {
        if (e['время'] === 'Всего') return

        chartData.fields.data.push(e?.['время'] || 0)

        delete e['время']

        Object.keys(e).forEach(o => {
          const index = chartData?.data?.findIndex(d => d.title === o)

          if (index === -1) {
            chartData.data.push({
              title: o || 0,
              data: [e?.[o] || 0]
            })
          } else {
            chartData.data[index].data.push(e?.[o] || 0)
          }
        })
      })
    }

    return chartData
  }

  async getMapData() {
    try {
      const { data } = await this.$store.dispatch('GET_REQUEST', {
        url: 'objectInfo/15064f3c-2218-4276-94ce-c0b775895387'
      })
      const filteredData = Object.values(data).filter(e => e.cam_type === 'kpp_cam' || e.cam_type === 'astra')
      const geojson = jsonToGeojson(filteredData)

      return geojson
    } catch (e) {
      console.log(e)
      return null
    }
  }

  async addMapData(data) {
    this.mapgl.addSource('passing-statistic', {
      type: 'geojson',
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 36,
      data
    })

    // add clusters
    this.mapgl.addLayer({
      id: 'passing-statistic-clusters',
      source: 'passing-statistic',
      ...layersConfig.passing_statistic_clusters
    })
    this.mapgl.addLayer({
      id: 'passing-statistic-cluster-count',
      source: 'passing-statistic',
      ...layersConfig.passing_statistic_clusters_count
    })

    this.mapgl.addLayer({
      id: 'passing-statistic',
      source: 'passing-statistic',
      ...layersConfig.passing_statistic
    })

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: 15,
      anchor: 'left',
      className: 'info-layer__custom-popup'
    })

    this.mapgl.on('mouseenter', 'passing-statistic', (e) => {
      this.mapgl.getCanvas().style.cursor = 'pointer'

      const coordinates = e.features[0].geometry.coordinates.slice()
      const name = e.features[0].properties?.name

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
      }

      if (name) {
        popup
          .setLngLat(e.lngLat)
          .setHTML(
          `<dl>
              <dd>${name}</dd>
          </dl>`
          )
          .addTo(this.mapgl)
      }
    })

    this.mapgl.on('mouseleave', 'passing-statistic', () => {
      this.mapgl.getCanvas().style.cursor = ''
      popup.remove()
    })
  }
}
