<template>
  <ul class="mt-filters">
    <filters-item
      v-for="filter in filledFilters"
      :key="filter.id"
      :filter="filter"
      @change-handler="changeHandler"
      @toggle-show="toggleShow"
    />
  </ul>
</template>

<script>
import { getVehiclesRequestConfig } from '../map/helpers'
import FiltersItem from '@/components/commons/monitoring/filter-item'

const filters = [
  {
    type: 'type_group_id',
    title: 'Тип ТС',
    config: {
      format: 'count_by_type'
    },
    items: [],
    show: false
  },
  {
    type: 'project',
    alias: 'project',
    title: 'Проекты',
    config: {
      format: 'count_by_project'
    },
    items: [],
    show: false
  },
  {
    type: 'main_contractor_id',
    alias: 'main_contractor_id',
    title: 'Подрядчики',
    config: {
      format: 'count_by_contractor'
    },
    items: [],
    show: false
  }
]

export default {
  components: {
    FiltersItem
  },
  data() {
    return {
      filters
    }
  },
  computed: {
    filledFilters() {
      return this.filters.filter(f => f.items.length > 0)
    },
    filterCategories() {
      return this.$store.state.monitoringTmc.filterCategories
    }
  },
  created() {
    this.loadFilters(true)
  },
  methods: {
    loadFilters(isInitial) {
      this.filters.forEach(async filter => {
        const config = {}
        const requestConfig = getVehiclesRequestConfig(this.$store)

        if (requestConfig.where.length) {
          config.where = requestConfig.where
        }

        const uri = 'vehicles_cargo'
        const { data } = await this.$store.dispatch('POST_REQUEST', {
          url: uri,
          data: {
            config,
            ...filter.config
          }
        })
        const currentItems = filter.items
        const items = Object.values(data).map(v => ({
          ...v,
          active:
            isInitial || !!currentItems.find(item => item.id === v.id)?.active
        }))
        this.$set(filter, 'items', items)
      })

      this.$store.commit('MT_TMC_SET_FIELD', {
        field: 'filters',
        value: this.filters
      })
    },
    changeHandler({ type, items }) {
      const { filterCategories } = this.$store.state.monitoringTmc
      const allActive = this.filters?.find(
        e => e.type === type
      )?.items?.every(e => e.active)

      if (allActive) {
        filterCategories[type] = []
      } else {
        filterCategories[type] = items.reduce((a, { id, active }) => {
          if (active) a.push(id)
          return a
        }, [])
      }

      this.loadFilters()
    },
    toggleShow(filter) {
      const value = !filter.show

      this.filters.forEach(item => {
        this.$set(item, 'show', false)
      })

      this.$set(filter, 'show', value)
    }
  }
}
</script>
