export const mapIcons = [
  {
    name: 'camera',
    icon: 'iconCamera'
  },
  {
    name: 'cameraActive',
    icon: 'iconCameraActive'
  },
  {
    name: 'cameraError',
    icon: 'iconCameraError'
  },
  {
    name: 'cameraWork',
    icon: 'iconCameraWork'
  },
  {
    name: 'cameraInactive',
    icon: 'iconCameraInactive'
  },
  {
    name: 'roadWork',
    icon: 'iconRoadWork'
  },
  {
    name: 'roadWorkActive',
    icon: 'iconRoadWorkActive'
  },
  {
    name: 'roadEvent',
    icon: 'iconRoadEvent'
  },
  {
    name: 'roadEventActive',
    icon: 'iconRoadEventActive'
  },
  {
    name: 'roadAccident',
    icon: 'iconRoadAccident'
  },
  {
    name: 'roadAccidentActive',
    icon: 'iconRoadAccidentActive'
  }
]
