import { render, staticRenderFns } from "./r-block.vue?vue&type=template&id=44767f22&scoped=true&"
import script from "./r-block.vue?vue&type=script&lang=js&"
export * from "./r-block.vue?vue&type=script&lang=js&"
import style0 from "./r-block.vue?vue&type=style&index=0&id=44767f22&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44767f22",
  null
  
)

export default component.exports