<template>
  <div class="user-info__block avatar-block">
    <div class="user-info__avatar">
      <div class="user-info__avatar-wrapper">
        <el-upload
          ref="avatarUpload"
          class="avatar-uploader"
          :action="`${baseURL}/${uploadURL}`"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :on-success="onSuccessUploading"
        >
          <div class="user-info__avatar-block">
            <img
              v-if="user && user.avatar"
              :src="getImageSource(user.avatar)"
              class="user-info__avatar-image"
              alt="no-data"
            >
            <div
              slot="trigger"
              ref="avatarUploadButton"
              class="user-info__avatar-edit"
            >
              <span class="el-icon-edit" />
            </div>
            <div
              v-if="user && user.avatar"
              class="user-info__avatar-actions"
            >
              <el-tooltip
                :open-delay="1500"
                :content="`${$t('user-info:tooltip:delete')}`"
                placement="top"
              >
                <r-button
                  type="danger"
                  simple
                  @click="deleteAvatar"
                >
                  {{ $t('user-info:button:delete') }}
                </r-button>
              </el-tooltip>
            </div>
          </div>
        </el-upload>
      </div>
    </div>
    <div class="user-info__avatar-info">
      <div class="user-info__avatar-info__row">
        <div class="user-info__avatar-info__button-upload">
          <el-tooltip
            :open-delay="1500"
            :content="`${$t('user-info:tooltip:upload')}`"
            placement="top"
          >
            <r-button
              type="primary"
              @click="handleUpload"
            >
              {{ $t('user-info:button:upload') }}
            </r-button>
          </el-tooltip>
        </div>
        <div
          v-if="user && user.avatar"
          class="user-info__avatar-info__button-delete"
        >
          <el-tooltip
            :open-delay="1500"
            :content="`${$t('user-info:tooltip:delete')}`"
            placement="top"
          >
            <r-delete-button
              simple
              mini
              @delete="deleteAvatar"
            />
          </el-tooltip>
        </div>
      </div>
      <div class="user-info__avatar-info__row">
        <r-text>{{ $t('user-info:avatar:file-info') }}</r-text>
      </div>
    </div>
  </div>
</template>

<script>
import { notifyFactory } from '@/utils'
import constants from '@/constants/url'

export default {
  props: {
    saveUser: {
      type: Function,
      default: () => null
    },
    module: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      downloadURL: 'download_share_resource',
      baseURL: constants.URL,
      uploadURL: 'upload_share_resource'
    }
  },
  computed: {
    user() {
      return this.module === 'account'
        ? this.$store.state.userRoles.currentUser
        : this.$store.state.userRoles.activeUser
    }
  },
  methods: {
    getImageSource(file) {
      return `${this.baseURL}/${this.downloadURL}/${file}`
    },
    handleUpload() {
      this.$refs.avatarUploadButton.click()
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg '
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        const title = this.$t('user-info:avatar:incorrect-format-title')
        const message = this.$t('user-info:avatar:incorrect-format-text')
        this.$notify(notifyFactory('error', title, message))
      }
      if (!isLt2M) {
        const title = this.$t('user-info:avatar:incorrect-size-title')
        const message = this.$t('user-info:avatar:incorrect-size-text')
        this.$notify(notifyFactory('error', title, message))
      }
      return isJPG && isLt2M
    },
    onSuccessUploading(res) {
      this.$store.commit('CHANGE_ACTIVEUSER_AVATAR', {
        avatar: res.resource_name,
        module: this.module
      })
      this.saveUser()
    },
    deleteAvatar(e) {
      e.stopPropagation()
      this.$store.commit('CHANGE_ACTIVEUSER_AVATAR', {
        avatar: '',
        module: this.module
      })
      this.saveUser()
    }
  }
}
</script>

<style lang="scss"></style>

<i18n>
{
  "ru": {
    "user-info:button:upload": "Изменить аватар",
    "user-info:button:delete": "Удалить",

    "user-info:button:save": "Сохранить изменения",
    "user-info:button:cancel": "Отмена",

    "user-info:tooltip:delete": "Удалить аватар",
    "user-info:tooltip:upload": "Сохранить изменения",

    "user-info:avatar:file-info": "Поддерживаемые форматы: JPEG, JPG и PNG. Макс. размер: 2 МБ",
    "user-info:avatar:incorrect-format": "Некорректный формат",
    "user-info:avatar:incorrect-format-text": "Формат изображения должен быть JPEG, JPG или PNG",
    "user-info:avatar:incorrect-size": "Некорректный размерз",
    "user-info:avatar:incorrect-size-text": "Размер изображения должен быть не более 2МБ"
  },
  "en": {
    "user-info:button:upload": "Change avatar",
    "user-info:button:delete": "Delete",

    "user-info:button:save": "Save changes",
    "user-info:button:cancel": "Cancel",

    "user-info:tooltip:delete": "Delete avatar",
    "user-info:tooltip:upload": "Save changes",

    "user-info:avatar:file-info": "Must be JPEG, JPG, PNG and cannot exceed 2MB",
    "user-info:avatar:incorrect-format-title": "Incorrect format",
    "user-info:avatar:incorrect-format-text": "Image format must be JPEG, JPG or PNG",
    "user-info:avatar:incorrect-size-title": "Incorrect size",
    "user-info:avatar:incorrect-size-text": "Image size should be no more than 2MB"
  }
}
</i18n>
