import vue from 'vue'

const state = {
  permissions: null
}

const mutations = {
  SET_USER_PERMISSIONS(state, permissions) {
    vue.set(state, 'permissions', permissions)
  }
}

export default {
  state,
  mutations
}
