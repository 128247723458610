import bearing from '@turf/bearing'
import constants from '@/constants/url'
import { getHeaders, blobToBase64 } from '@/utils'

// load icons
const MAP_ICON_SIZE = 64
const events = [
  {
    name: 'sign-icon',
    url: require('@/assets/images/odd/sign-icon.svg')
  },
  {
    name: 'event-icon',
    url: require('@/assets/images/odd/event-icon.svg')
  },
  {
    name: 'work-icon',
    url: require('@/assets/images/odd/work-icon.svg')
  },
  {
    name: 'accident-icon',
    url: require('@/assets/images/odd/accident-icon.svg')
  },
  {
    name: 'play',
    url: require('@/assets/icons/mono/general/play.svg'),
    sdf: true
  },
  {
    name: 'aghk-bus-default',
    url: require('@/assets/images/monitoring/aghk-bus-default.svg'),
    size: 44
  }
]

const getPromisesFromArray = (component, array) =>
  array.map(
    item =>
      new Promise(resolve => {
        const size = item.size || MAP_ICON_SIZE
        const image = new Image(size, size)
        image.crossOrigin = 'Anonymous'
        image.style.backgroundPosition = '50%, 50%'
        image.style.backgroundSize = '100%'
        image.addEventListener('load', () => {
          if (!component.mapgl.hasImage(item.name)) {
            component.mapgl.addImage(item.name, image, { sdf: item.sdf })
          }
          resolve()
        })

        image.addEventListener('error', () => {
          console.log('odd icon not loaded: ', item.name)
          resolve()
        })

        image.src = item.url
      })
  )

export const loadIcons = async(component, signIcons) => {
  const icons = [...events]

  component.$store.commit('SET_ODD_FIELD', { field: 'iconsLoading', value: true })

  for (let i = 0; i < signIcons.length; i++) {
    const item = signIcons[i]
    const url = `${constants.URL}/download_resource/${item.resource_id}`
    const lsId = `icon-${item.resource_id}`
    const iconText = localStorage.getItem(lsId)
    let blob

    if (iconText === null) {
      const res = await fetch(url, { headers: getHeaders() })

      blob = await res.blob()
      blobToBase64(blob).then(base64 => {
        localStorage.setItem(lsId, base64)
      })
    } else {
      const base64Response = await fetch(iconText)

      blob = await base64Response.blob()
    }

    icons.push({
      name: item.resource_id,
      test: item.name,
      url: URL.createObjectURL(blob)
    })
  }

  const promises = getPromisesFromArray(component, icons)

  component.$store.commit('SET_ODD_FIELD', { field: 'iconsLoading', value: false })

  return Promise.all(promises)
    .then(() => console.log('odd icons loaded!'))
    .catch(() => console.log('odd icons not loaded'))
}

export const getSignsFeatures = features =>
  features.reduce((prev, current) => {
    current.projection = current.rack?.geom || null
    const items = []
    let angle = 0

    if (current.rack?.projection) {
      angle = bearing(current.rack?.projection, current.rack?.geom) - 90
    }

    items.push({
      type: 'Feature',
      geometry: current.rack?.geom || current.geom,
      properties: {
        id: current.id,
        type: 'symbols',
        position: current.rack_position,
        resource_id: current.sign_icon
          ? current.sign_icon.resource_id
          : 'sign-icon',

        layerType: 'signs',
        angle
      }
    })

    return [...prev, ...items]
  }, [])

export const getRackFeatures = features =>
  features.reduce((prev, current) => {
    const items = []

    if (!current.projection && current.signs?.length) {
      current.projection = current.signs.find(s => s.projection)?.projection || null
    }

    if (current.projection) {
      items.push({
        type: 'Feature',
        geometry: current.projection,
        properties: {
          id: current.id,
          type: 'points'
        }
      })
      items.push({
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            current.projection.coordinates,
            current.geom.coordinates
          ]
        },
        properties: {
          id: current.id,
          layerType: 'line'
        }
      })
    }

    items.push({
      type: 'Feature',
      geometry: current.geom,
      properties: {
        id: current.id,
        type: 'symbols'
      }
    })

    return [...prev, ...items]
  }, [])
