<template>
  <div class="rp-users-wellcome">
    <r-icon
      name="user"
      :size="64"
    />
    <r-title
      type="title-2"
      align="center"
    >
      Выберите пользователя в списке, чтобы посмотреть информацию
    </r-title>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.rp-users-wellcome {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 340px;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);

  .r-icon {
    margin-bottom: 8px;
  }
}
</style>
