<template>
  <calendar />
</template>

<script>
export default {
  components: {
    calendar: () => import('@/components/calendar/')
  }
}
</script>

<style></style>
