import { mapColors } from '@/config/colors'

export const layerConfig = {
  points: {
    type: 'circle',
    paint: {
      'circle-color': mapColors.default,
      'circle-radius': 6,
      'circle-stroke-color': mapColors.white,
      'circle-stroke-width': 2
    }
  }
}
