export default {
  sign: {
    title: 'Как добавить дорожный знак',
    img: '/hints/odd-sign-create.png',
    text:
      'Подведите указатель к существующей стойки или к графу дороги, чтобы появился указатель. Нажмите ЛКМ (левую кнопку мыши) и добавьте объект.'
  },
  closure: {
    title: 'Как добавить перекрытие',
    img: '/hints/odd-sign-create.jpg',
    text:
      'Подведите указатель к графу дороги, чтобы появился указатель. Нажмите ЛКМ (левую кнопку мыши) и добавьте одну или несколько точек(узлов), по которым будет строиться отрезок.  \n\nESC — удалить все узлы. \nCTRL+Z — удалить последний узер. \nЛКМ по последнему узлу — завершить отрезок .'
  }
}
