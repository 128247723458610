import { Constants } from '@/libs/map-draw/lib-utils'
import {
  getLinkFeatures,
  getStopPointsData,
  createStopPointsHelpers,
  removeStopPointsHelpers,
  removeHoveredSPHelpers,
  nearestPointOnLine,
  isPointInLinkArea
} from '@/libs/map-draw/helpers'

export const createMDrawPointsMode = (MapboxDraw, component) => {
  const DrawPoints = {}
  const customState = {
    linkId: null,
    currentPoint: null,
    projectionPoint: null
  }

  DrawPoints.onMouseOut = function() {
    // removeStopPointsHelpers(this.map);
  }

  DrawPoints.onTap = DrawPoints.onClick = function(state) {
    const { linkId, currentPoint, projectionPoint } = customState
    const { editorState } = component.$store.state.modelling

    if (linkId && currentPoint) {
      const { coordinates } = projectionPoint
      this.updateUIClasses({ mouse: Constants.cursors.MOVE })
      state.point.updateCoordinate('', coordinates[0], coordinates[1])

      // attributes editing helper
      editorState.creatingCallback = () => {
        removeStopPointsHelpers(this.map)

        this.map.fire(Constants.events.CREATE, {
          features: [state.point.toGeoJSON()]
        })
        this.changeMode(Constants.modes.SIMPLE_SELECT, {
          featureIds: [state.point.id]
        })

        editorState.activeObject = null
        editorState.updateEvents = !editorState.updateEvents
      }
      editorState.activeObject = state.point

      state.point.setProperty('link_id', linkId)

      component.$store.commit('SET_CALC_FIELD', {
        field: 'activeEventId',
        value: state.point.id
      })
      component.$store.commit('SET_CALC_FIELD', {
        field: 'overlay',
        value: true
      })
    }
  }

  DrawPoints.onMouseMove = function(state, e) {
    const { editorState } = component.$store.state.modelling
    const { lng, lat } = e.lngLat
    const linksFeatures = getLinkFeatures(this.map, editorState, e)

    if (linksFeatures.length) {
      const link = linksFeatures.find(l =>
        isPointInLinkArea([lng, lat], l.geometry.coordinates, 100)
      )

      if (link) {
        removeHoveredSPHelpers(this.map)
        const nearest = nearestPointOnLine(
          [lng, lat],
          link.geometry.coordinates
        )
        const current = {
          geometry: {
            type: 'Point',
            coordinates: [lng, lat]
          },
          properties: {
            projection: 'true'
          }
        }
        const connection = {
          geometry: {
            type: 'LineString',
            coordinates: [nearest.geometry.coordinates, [lng, lat]]
          }
        }

        const { id } = link.properties
        customState.linkId = id
        customState.currentPoint = current.geometry
        customState.projectionPoint = nearest.geometry

        const data = getStopPointsData(nearest, current, connection)
        createStopPointsHelpers(this.map, data)
      } else {
        removeStopPointsHelpers(this.map)
        customState.linkId = ''
        customState.currentPoint = null
        customState.projectionPoint = null
      }
    } else {
      removeStopPointsHelpers(this.map)
      customState.linkId = null
      customState.currentPoint = null
      customState.projectionPoint = null
    }
  }

  return { ...MapboxDraw.modes.draw_point, ...DrawPoints }
}
