<template>
  <div class="r-info-layers__dropdown-button">
    <el-dropdown
      class="r-dropdown r-info-layers__dropdown"
      size="mini"
      :hide-on-click="false"
      :show-timeout="100"
      placement="bottom-start"
      trigger="click"
      @visible-change="e => toggleHandler(e)"
    >
      <div :class="['r-dropdown__item', { active: isDropdownActive }]">
        <r-icon
          name="folder-layer"
          :size="24"
          style="cursor: pointer"
          arrow-down
          :color-type="isDropdownActive ? 'accent-primary' : ''"
        />
      </div>
      <el-dropdown-menu
        slot="dropdown"
        class="r-dropdown-menu"
      >
        <el-dropdown-item
          v-for="layer in layers"
          :key="layer.id"
        >
          <div
            class="r-dropdown-menu__item-wrapper"
            @click="toggleLayer(layer)"
          >
            <el-checkbox
              class="r-checkbox"
              size="mini"
              :value="isLayerActive(layer.id)"
              @change="toggleLayer(layer)"
            />
            <r-text style="margin-left: 12px">
              {{ layer.name }}
            </r-text>
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    mapgl: {
      type: Object,
      required: true
    },
    module: {
      type: String,
      required: true
    },
    layerId: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    addHandler: {
      type: Function,
      required: true
    },
    layers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isDropdownShow: false
    }
  },
  computed: {
    activeLayers() {
      return this.$store.state.mapConfigs?.activeLayers?.[this.module] || []
    },
    isDropdownActive() {
      if (this.isDropdownShow) return true

      return !!this.layers.some(l => this.activeLayers.includes(l.id))
    }
  },
  methods: {
    toggleHandler(val) {
      this.isDropdownShow = val
    },
    isLayerActive(id) {
      if (!this.activeLayers) return false
      return this.activeLayers.includes(id)
    },
    getLayerClassName(id) {
      if (this.isLayerActive(id)) {
        return 'r-info-layers__item active'
      }

      return 'r-info-layers__item'
    },
    toggleLayer(layer) {
      const { id } = layer
      if (this.isLayerActive(id)) {
        this.$store.commit('MAP_CONFIGS_SET_CONFIG', {
          field: 'activeLayers',
          module: this.module,
          value: this.activeLayers.filter(item => item !== id && !!item)
        })

        if (this.mapgl.getSource(id)) {
          this.mapgl.removeLayer(id)
          this.mapgl.removeSource(id)
        }
      } else {
        this.addHandler(layer)
        this.$store.commit('MAP_CONFIGS_SET_CONFIG', {
          field: 'activeLayers',
          module: this.module,
          value: [...this.activeLayers, id]
        })
      }
    }
  }
}
</script>

<style lang="scss">
.r-info-layers {
  &__dropdown-button {
    width: 36px;
    height: 36px;
    border-radius: var(--border-radius);
    cursor: pointer;

    .r-icon {
      height: 36px;
    }

    &.active {
      background-color: var(--accent_selected) !important;
    }

    &:hover {
      background-color: var(--accent_hover) !important;
    }
  }
}
</style>
