<template>
  <event-editor />
</template>

<script>
import EventEditor from '@/components/event-editor/event-editor'

export default {
  components: {
    EventEditor
  }
}
</script>
