<template>
  <div :class="`r-simple-list-item ${withBg ? 'r-simple-list-item--bg' : ''}`">
    <div
      v-if="iconName"
      class="r-simple-list-item__col icon-col"
    >
      <r-icon
        :name="iconName"
        :size="24"
      />
    </div>
    <div class="r-simple-list-item__col title-col">
      <div class="r-simple-list-item__row title">
        <r-text
          type="caption"
          color-type="primary"
          :size="size || 12"
        >
          {{ title }}
        </r-text>
      </div>
      <div
        v-if="subtitle"
        class="r-simple-list-item__row subtitle"
      >
        <r-text type="caption">
          {{ subtitle }}
        </r-text>
      </div>
    </div>
    <div
      v-if="!noIcon"
      :class="['r-simple-list-item__controls', { 'no-bg' : simpleIcon }]"
    >
      <r-icon
        name="chevron-right"
        :size="24"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null,
      required: true
    },
    subtitle: {
      type: String,
      default: null
    },
    withBg: {
      type: Boolean,
      default: false
    },
    noIcon: {
      type: Boolean,
      default: false
    },
    simpleIcon: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: null
    }
  }
}
</script>

<style lang="scss">
.r-simple-list-item {
  position: relative;
  display: flex;
  border-radius: var(--border-radius);
  overflow: hidden;
  padding: 4px 8px;
  cursor: pointer;
  min-height: 34px;

  &--bg {
    background-color: var(--bg_containers) !important;
  }

  &__controls {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    padding: 6px;
    border-radius: var(--border-radius);
    overflow: hidden;
    background-color: var(--accent_primary) !important;

    &.no-bg {
      background: none !important;
      display: flex;
    }
  }

  &:hover,
  .active {
    background-color: var(--accent_selected) !important;
    .r-simple-list-item {
      &__controls {
        display: flex;
      }
    }
  }
  &__col {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    &.icon-col {
      width: 24px;
      margin-right: 8px;
    }
    &.title-col {
      width: calc(100% - 28px);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__row {
    width: 100%;
    .r-text {
      text-align: start;
    }
    &.title {
      .r-text {
        min-height: 16px;
        width: calc(100% - 8px);
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: auto;
      }
    }
    &.subtitle {
      margin-top: 2px;
    }
  }
}
</style>
