<template>
  <div class="m-layer-settings">
    <r-panel-header
      :with-back-button="!!path"
      :title="getPanelTitle"
      @back="changePath(null)"
    />
    <div
      v-if="!path"
      class="m-layer-settings__edit"
    >
      <r-button @click="openEditor">
        {{ $t('edit') }}
      </r-button>
    </div>
    <div class="m-layer-settings__container">
      <template v-if="!path">
        <r-simple-list
          :list="tabs"
          :with-bg="true"
          @click-handler="e => changePath(e.id)"
        />
      </template>
      <template v-else-if="path === 'settings' && hasInstrument('Styles')">
        <style-settings
          :id="id"
          :layer-style="layerStyle"
          :geom-type="layerGeomType"
          :controllers="controllers"
        />
      </template>
      <template v-else-if="path === 'filters' && hasInstrument('Filters')">
        <filters-settings
          :id="id"
          :layer-style="layerStyle"
          :controllers="controllers"
        />
      </template>
      <template v-else-if="path === 'diagram' && hasInstrument('Diagrams')">
        <diagram-settings
          :id="id"
          :layer-style="layerStyle"
          :geom-type="layerGeomType"
          :controllers="controllers"
        />
      </template>
      <template v-else-if="path === 'heatmap' && hasInstrument('Heatmaps')">
        <heatmaps
          :id="id"
          :layer-style="layerStyle"
          :controllers="controllers"
        />
      </template>
      <template v-else-if="path === 'matrices'">
        <matrices-control
          :id="id"
          :parent-id="layerParentId"
          :layer-style="layerStyle"
          :controllers="controllers"
        />
      </template>
      <template v-else-if="path === 'history'">
        <history :id="id" />
      </template>
    </div>
  </div>
</template>

<script>
import StyleSettings from './style/style-settings'
import FiltersSettings from './filters/filters-settings'
import DiagramSettings from './diagram/diagram-settings'
import Heatmaps from './heatmaps/heatmaps'
import MatricesControl from './matrices/matrices-control'
import History from './history/history'

const commonTabs = [
  {
    id: 'settings',
    title: 'Общие настройки',
    subtitle: 'Настройка цвета, обводки, размеров',
    icon: 'stylize'
  },
  {
    id: 'filters',
    title: 'Фильтры',
    subtitle: 'Настройка сортировки и фильтрации объектов',
    icon: 'filter'
  }
]

export default {
  components: {
    StyleSettings,
    FiltersSettings,
    DiagramSettings,
    Heatmaps,
    MatricesControl,
    History
  },
  props: {
    id: {
      type: String,
      required: true
    },
    styleConfig: {
      type: Object,
      required: true
    },
    controllers: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      path: null
    }
  },
  computed: {
    sourceId() {
      return this.$store.state.profiles.sourceIdById[this.id]
    },
    layerStyle() {
      return this.styleConfig[this.id]
    },
    layerGeomType() {
      return this.$store.state.datasources[this.sourceId].geom_type
    },
    tabs() {
      let tabs = []

      switch (this.layerGeomType) {
        case 'point':
        case 'multipoint':
        case 'geometry':
          tabs = [
            ...commonTabs,
            {
              id: 'heatmap',
              title: 'Тепловые карты',
              subtitle: 'Построение и настройка тепловых карт',
              icon: 'heatmap-layer'
            }
          ]
          break
        case 'line_string':
        case 'multi_line_string':
          tabs = [
            ...commonTabs,
            {
              id: 'diagram',
              title: 'Эпюры',
              subtitle: 'Отображение и настройка эпюр по объектам',
              icon: 'epure-layer'
            }
          ]
          break
        case 'polygon':
        case 'multi_polygon':
          if (
            this.$store.state.datasources[this.sourceId].datatype === 'zones'
          ) {
            tabs = [
              ...commonTabs,
              {
                id: 'matrices',
                title: 'Матрицы корреспонденций',
                subtitle: 'Построение и настройка матриц корреспонденций',
                icon: 'heatmap-layer'
              }
            ]
          } else {
            tabs = [...commonTabs]
          }
          break
      }

      if (this.$store.state.datasources[this.sourceId].versioning) {
        tabs.push({
          id: 'history',
          title: 'История изменений',
          subtitle: 'Просмотр всех пользовательских изменений слоя',
          icon: 'in-process'
        })
      }

      return tabs
    },
    layerParentId() {
      return this.$store.state.datasources[this.sourceId].parent_id
    },
    getPanelTitle() {
      switch (this.path) {
        case 'settings':
          return 'Общие настройки'
        case 'diagram':
          return 'Настройки эпюр'
        case 'filters':
          return 'Фильтры и сортировка'
        case 'heatmap':
          return 'Тепловые карты'
        case 'history':
          return 'История изменений'
        default:
          return 'Параметры слоя'
      }
    }
  },
  methods: {
    changePath(path) {
      this.path = path
    },
    hasInstrument(instrument) {
      return this.$store.getters.hasInstrument('Map', instrument)
    },
    openEditor() {
      const data = { id: this.id, source_id: this.sourceId }
      this.$emit('toggle-module', { type: 'editor', data })
    }
  }
}
</script>

<style lang="scss">
.m-layer-settings {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__edit {
    padding: 8px;

    .r-button {
      width: 100%;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 0px;
    padding: 8px;
    overflow: auto;
  }
}
</style>

<i18n>
{
  "ru": {
    "edit": "Редактировать"
  },
  "en": {
    "edit": "Edit"
  }
}
</i18n>
