var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"pt-route-transport"},[_c('div',{staticClass:"pt-route-transport__on-route pt-route-transport__field"},[_c('r-title',{attrs:{"type":"subtitle-2"}},[_vm._v(" Транспорт на маршруте ")]),_c('div',{staticClass:"on-route__container transport-list"},_vm._l((_vm.onRouteList),function(transport,index){return _c('div',{key:index,class:[
          'transport-list__item',
          {
            'pt-route-transport__field': _vm.routeClipBoard.find(
              item => item.id === transport.id
            )
          }
        ],on:{"click":function($event){return _vm.selectItem(transport.id)}}},[_c('r-icon',{attrs:{"name":"bus-hollow","size":32}}),_c('r-text',[_vm._v(" "+_vm._s(transport.reg_number)+" ")]),_c('r-text',{attrs:{"type":"caption"}},[_vm._v(" "+_vm._s(transport.contractor ? transport.contractor.name : '')+" ")])],1)}),0)],1),_c('div',{staticClass:"pt-route-transport__controls"},[_c('r-button',{attrs:{"type":"primary","disabled":!_vm.routeClipBoard.length,"icon":"chevron-right"},on:{"click":function($event){return _vm.moveTo('all')}}}),_c('r-button',{attrs:{"type":"primary","disabled":!_vm.allClipBoard.length,"icon":"chevron-left"},on:{"click":function($event){return _vm.moveTo('route')}}})],1),_c('div',{staticClass:"pt-route-transport__all pt-route-transport__field"},[_c('r-title',{attrs:{"type":"subtitle-2"}},[_vm._v(" Весь транспорт ")]),_c('r-search-input',{attrs:{"filter-text":_vm.filterText},on:{"change":e => _vm.searchChange(e)}}),_c('div',{staticClass:"all-transport__container transport-list"},_vm._l((_vm.filteredList),function(transport,index){return _c('div',{key:index,class:[
          'transport-list__item',
          {
            'pt-route-transport__field': _vm.allClipBoard.find(
              item => item.id === transport.id
            )
          }
        ],on:{"click":function($event){return _vm.selectItem(transport.id)}}},[_c('r-icon',{attrs:{"name":"bus-hollow","size":32}}),_c('r-text',[_vm._v(" "+_vm._s(transport.reg_number)+" ")]),_c('r-text',{attrs:{"type":"caption"}},[_vm._v(" "+_vm._s(transport.contractor ? transport.contractor.name : '')+" ")])],1)}),0)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }