export default {
  subprojects: {
    id: 1,
    urlName: 'subprojects',
    data: [],
    config: { only: ['name', 'id'] }
  },
  accident_categories: {
    id: 2,
    urlName: 'accident_categories',
    data: [],
    config: { only: ['name', 'id'] }
  },
  trafficaccident_types: {
    id: 4,
    urlName: 'trafficaccident_types',
    data: [],
    config: { only: ['name', 'id'] }
  },
  contractors: {
    id: 5,
    urlName: 'mainContractor',
    data: [],
    config: { only: ['name', 'id'] }
  },
  three_vio: {
    id: 'three_vio',
    urlName: 'three_vio',
    data: [],
    config: { only: ['name', 'id'] }
  },
  employees: {
    id: 6,
    urlName: 'employees',
    data: [],
    config: { only: ['name', 'id', 'position', 'is_prime'] }
  },
  vehicles: {
    id: 7,
    urlName: 'vehicles',
    data: [],
    config: {
      only: ['reg_number', 'id', 'vehicle_type_id', 'model_id'],
      include: { vehicle_type: {}, model: {} }
    }
  },
  vehicleTypes: {
    id: 7,
    urlName: 'vehicleTypes',
    data: [],
    config: {
      only: ['name', 'id'],
      include: { vehicle_type_group: { only: ['id', 'name'] } }
    }
  },
  accident_classifications_custom: {
    id: 3,
    data: [
      { id: 'significant', name: 'Значительное ДТП' },
      { id: 'major', name: 'Крупное ДТП' },
      { id: 'potential', name: 'Потенциально-опасное ДТП' }
    ]
  }
}
