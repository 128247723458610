<template>
  <div class="odd-legend">
    <div class="odd-legend__header">
      Легенда
    </div>
    <div class="odd-legend__content">
      <ul class="odd-legend__list">
        <li class="odd-legend__item">
          <div class="odd-legend__item-value">
            <r-icon
              name="road-sign"
              :size="24"
            />
          </div>
          <div class="odd-legend__item-label">
            <r-text type="caption">
              Дорожные знаки
            </r-text>
          </div>
        </li>
        <li class="odd-legend__item">
          <div class="odd-legend__item-value">
            <r-icon
              name="closed"
              :size="24"
            />
          </div>
          <div class="odd-legend__item-label">
            <r-text type="caption">
              ДТП, перекрытия
            </r-text>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.odd-legend {
  position: absolute;
  right: 16px;
  top: 200px;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  width: 200px;
  z-index: 1000;
  font-size: 12px;
  color: var(--white_white);
  background-color: var(--modal_bg) !important;
  border-color: var(--dividers_low_contrast) !important;

  &__header {
    padding: 8px;
    font-weight: bold;
    border-radius: var(--border-radius);
    text-transform: uppercase;
    color: var(--text_primary) !important;
    background-color: var(--modal_bg) !important;
  }

  &__content {
    padding: 8px;
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-top: 8px;
    }

    &-value {
      margin-right: 8px;
    }
  }
}
</style>
