<template>
  <ul
    v-if="roles && roles.length"
    class="rp-sidebar-roles-list"
  >
    <li
      v-for="role in sortedRoles"
      :key="role.id"
      :class="[
        'rp-sidebar-roles-list__item',
        { active: role.id === activeRoleId }
      ]"
      @click="editRole(role)"
    >
      <r-text
        type="caption"
        color-type="primary"
        style="text-transform: capitalize;"
      >
        {{ role.name }}
      </r-text>
    </li>
  </ul>
  <div
    v-else
    class="rp-sidebar-roles-list__no-data"
  >
    <r-text>Нет данных</r-text>
  </div>
</template>

<script>
export default {
  props: {
    roles: {
      type: Array,
      required: true
    }
  },
  computed: {
    sortedRoles() {
      const roles = this.roles?.map(e => e) || []

      return roles.sort((a, b) => {
        if (a.id === 'ib_admin' || a.id === 'admin') {
          return -1
        } else {
          if (a.name > b.name) return 1
          if (a.name < b.name) return -1
          return 0
        }
      })
    },
    activeRoleId() {
      return this.$store.state.rolePermission.activeRoleId || null
    },
    createNewRole() {
      return this.$store.state.rolePermission.createNewRole || false
    },
    allRoles() {
      return this.$store.state.rolePermission.allRoles || []
    },
    activeRole() {
      return this.allRoles?.find(e => e.id === this.activeRoleId)
    }
  },
  methods: {
    editRole(role) {
      if (!role?.id || role?.id === this.activeRoleId) return

      this.$store.commit('ROLE_PERM_SET_ACTIVE_ROLE', role.id)
      if (role.id === 'ib_admin' || role.id === 'admin') return
      this.loadRolePermission(role.id)
    },
    async loadRolePermission(roleId) {
      try {
        this.$store.commit('ROLE_PERM_SET_IS_LOADING', true)
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `role?role_id=${roleId}&permissions=true`
        })

        this.$store.commit('ROLE_PERM_SET_ACTIVE_ROLE_DATA', data?.permissions)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.$store.commit('ROLE_PERM_SET_IS_LOADING', false)
      }
    }
  }
}
</script>

<style lang="scss">
.rp-sidebar-roles-list {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;
  overflow: auto;

  &__item {
    display: flex;
    padding: 8px;
    align-items: center;
    cursor: pointer;
    border-radius: var(--border-radius);
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }

    .icon-col {
      margin-right: 4px;
    }

    .info-col {
      > * {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &:hover {
      background-color: var(--accent_hover);
    }

    &:active {
      background-color: var(--accent_active);
    }

    &.active {
      background-color: var(--accent_selected);
      cursor: initial;
    }
  }

  &__no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
  }
}
</style>
