export const sidebarTabsList = [
  {
    id: 1,
    name: 'Пользователи',
    value: 'users'
  },
  {
    id: 2,
    name: 'Роли',
    value: 'roles'
  }
]
