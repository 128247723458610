<template>
  <div class="rp-users-modules">
    <rp-users-tip :tip="tip" />
    <div class="rp-users-modules-row">
      <div class="rp-users-modules-col">
        <rp-users-modules-item
          v-for="module in firstColModules"
          :key="module.id"
          :module="module"
          :subtitle="getSubtitle(module)"
          @before-toggle="beforeToggle"
        />
      </div>
      <div class="rp-users-modules-col">
        <rp-users-modules-item
          v-for="module in secondColModules"
          :key="module.id"
          :module="module"
          :subtitle="getSubtitle(module)"
          @before-toggle="beforeToggle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import rpUsersModulesItem from './rp-users-modules-item'
import rpUsersTip from '../rp-users-tip'
import { isModuleActive } from './helpers'

export default {
  components: {
    rpUsersModulesItem,
    rpUsersTip
  },
  data() {
    return {
      tip: 'Модули, добавленные для роли, убирать нельзя. Пользователю вы можете только добавить новые. Полный контроль изменений доступен при редактировании роли.',
      firstColModules: null,
      secondColModules: null
    }
  },
  computed: {
    activeRoleId() {
      return this.$store.state.rolePermission.activeRoleId || null
    },
    createNewRole() {
      return this.$store.state.rolePermission.createNewRole || false
    },
    modulesConfig() {
      return this.$store.state.rolePermission.modulesConfig || []
    },
    allModules() {
      return this.$store.state.rolePermission.allPerms?.modules || []
    },
    userModules() {
      return this.$store.state.rolePermission.userPerms?.modules || []
    },
    roleModules() {
      return this.$store.state.rolePermission.rolePerms?.modules || []
    }
  },
  created() {
    this.firstColModules = this.modulesConfig.filter((e, i) => (i + 1) % 2 !== 0)
    this.secondColModules = this.modulesConfig.filter((e, i) => (i + 1) % 2 === 0)
  },
  methods: {
    getSubtitle(module) {
      const moduleComponentsIds = module?.components.map(c => c.id) || []
      let amount = 0
      let count = 0

      moduleComponentsIds.forEach(c => {
        ++amount
        if (this.userModules.find(e => e.id === c && e._action !== 'deleted')) {
          ++count
        }
      })
      return `Активно ${count} из ${amount}`
    },
    beforeToggle({ module, component, instrument }) {
      if (instrument) {
        this.toggleInstrument(component, instrument)
      } else if (component) {
        this.toggleComponent(component)
      } else {
        this.toggleModule(module)
      }
    },
    toggleInstrument(componentId, instrumentId) {
      const currentComponent = this.allModules.find(c => c.id === componentId)
      const currentInstrument = currentComponent?.instruments?.find(i => i.id === instrumentId)
      const roleComponent = this.userModules.find(c => c.id === componentId)
      const instruments = roleComponent?.instruments || []
      const founded = instruments.find(i => i.id === instrumentId)

      if (roleComponent && roleComponent._action === 'deleted') {
        if (founded) {
          founded._action = undefined
        } else {
          instruments.push({ ...currentInstrument, _action: 'created' })
        }
        module._action = undefined
      } else if (roleComponent) {
        let index

        if (founded) {
          switch (founded._action) {
            case 'deleted':
              founded._action = undefined
              break
            case 'created':
              index = instruments.findIndex(i => i.id === founded.id)
              instruments.splice(index, 1)
              break
            default:
              founded._action = 'deleted'
              instruments.push({ changed: true })
              instruments.splice(instruments.length - 1, 1)
              break
          }
        } else {
          instruments.push({ ...currentInstrument, _action: 'created' })
        }
      } else {
        const component = { ...currentComponent }

        component._action = 'created'
        component.instruments = []
        component.instruments.push({
          ...currentInstrument,
          _action: 'created'
        })
        this.userModules.push(component)
      }

      this.$forceUpdate()
    },
    toggleComponent(componentId, value = null) {
      const currentComponent = this.allModules.find(c => c.id === componentId)
      const roleComponent = this.userModules.find(c => c.id === componentId)

      if (value !== null) {
        if (value) {
          if (roleComponent) {
            roleComponent._action = roleComponent._action !== 'deleted' ? roleComponent._action : undefined
            roleComponent.instruments = currentComponent?.instruments.map(i => ({
              ...i,
              _action: 'created'
            }))
          } else if (!roleComponent) {
            this.userModules.push({
              ...currentComponent,
              _action: 'created',
              instruments: currentComponent?.instruments.map(i => ({
                ...i,
                _action: 'created'
              }))
            })
          }
        } else {
          if (roleComponent) {
            let index

            switch (roleComponent._action) {
              case 'created':
                index = this.userModules.findIndex(c => c.id === roleComponent.id)
                this.userModules.splice(index, 1)
                break
              default:
                roleComponent._action = 'deleted'
                roleComponent.instruments = roleComponent?.instruments.map(i => ({
                  ...i,
                  _action: 'deleted'
                }))
                break
            }
          }
        }
      } else {
        if (roleComponent) {
          let index

          switch (roleComponent._action) {
            case 'created':
              index = this.userModules.findIndex(c => c.id === roleComponent.id)
              this.userModules.splice(index, 1)
              break
            case 'deleted':
              roleComponent._action = undefined
              roleComponent.instruments = roleComponent?.instruments.map(i => ({
                ...i,
                _action: 'created'
              }))
              break
            default:
              roleComponent._action = 'deleted'
              roleComponent.instruments = roleComponent?.instruments.map(i => ({
                ...i,
                _action: 'deleted'
              }))
              break
          }
        } else {
          this.userModules.push({
            ...currentComponent,
            _action: 'created',
            instruments: currentComponent?.instruments.map(i => ({
              ...i,
              _action: 'created'
            }))
          })
        }
      }

      this.$forceUpdate()
    },
    toggleModule(moduleName) {
      const module = this.modulesConfig?.find(c => c.name === moduleName)
      const moduleComponentsIds = module.components.map(e => e.id) || []
      const isAllActive = isModuleActive(module, this.userModules)

      moduleComponentsIds.forEach(c => {
        if (this.roleModules?.find(e => e.id === c)) return

        this.toggleComponent(c, !isAllActive)
      })
    }
  }
}
</script>

<style lang="scss">
.rp-users-modules {
  position: relative;
  width: 100%;
  max-width: 1080px;
  display: flex;
  flex-direction: column;

  &-row {
    position: relative;
    width: 100%;
    max-width: 1260px;
    height: calc(100% - 74px);
    display: flex;
    justify-content: space-between;

    > * {
      width: calc(50% - 8px);
    }
  }
}
</style>
