<template>
  <div class="print">
    <pot-1-print-sheet
      v-if="id"
      :id="id"
    />
  </div>
</template>

<script>
import pot1PrintSheet from '@/components/pot-1-print-sheet/pot-1-print-sheet'

export default {
  components: {
    pot1PrintSheet
  },
  data() {
    return {
      module: null,
      id: null
    }
  },
  computed: {
    isReady() {
      return this.$store.state.print.isReady
    }
  },
  watch: {
    isReady(val) {
      if (!val) return
      this.startPrint()
    }
  },
  mounted() {
    const { m, id } = this.$route.query
    this.module = m
    this.id = id
  },
  methods: {
    startPrint() {
      window.addEventListener('afterprint', function() {
        window.close()
      })
      setTimeout(function() {
        window.print()
      }, 500)
    }
  }
}
</script>

<style lang="scss">
@page {
  size: 21cm 29.7cm;
  margin: 15mm 20mm 15mm 20mm;
}

@media print {
  *, *:before, *:after {
    color: #000;
    box-shadow: none;
    text-shadow: none;
  }

  body {
    width: 21cm;
    height: 29.7cm;
    margin: 15mm 20mm 15mm 20mm;
    visibility: hidden;
  }

  #app-main, #app-main * {
    visibility: visible;
  }

  #app-main {
    left: 0px;
    top: 0px;
    position:absolute;
  }

  * {
    page-break-before: always;
  }

  html, body {
    height:100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}

.print {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
}
</style>
