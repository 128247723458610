<template>
  <div class="pt-card-tabs">
    <r-tabs
      v-model="activeTab"
      indent
      metro
    >
      <r-tab
        v-for="item in filteredTabs"
        :id="item.id"
        :key="item.id"
        :name="item.title"
      >
        <component :is="item.type" />
      </r-tab>
    </r-tabs>
  </div>
</template>

<script>
import Schedule from './tabs/schedule/schedule'
import SpSchedule from './tabs/sp-schedule'
import SpForecast from './tabs/sp-forecast'
import Transport from './tabs/transport'
import Stops from './tabs/stops'

const tabs = {
  stop_points: [
    {
      id: '0',
      type: 'sp-forecast',
      title: 'Прогнозы прибытия'
    },
    {
      id: '1',
      type: 'sp-schedule',
      title: 'Расписание'
    },
    {
      id: '2',
      type: 'transport',
      title: 'Транспорт'
    }
  ],
  lines: [
    {
      id: '0',
      type: 'schedule',
      title: 'Расписание'
    },
    {
      id: '1',
      type: 'stops',
      title: 'Остановки'
    },
    {
      id: '2',
      type: 'transport',
      title: 'Транспорт'
    }
  ]
}

export default {
  components: {
    Schedule,
    SpSchedule,
    SpForecast,
    Transport,
    Stops
  },
  data() {
    return {
      activeTab: '0',
      tabs
    }
  },
  computed: {
    type() {
      return this.$store.state.publicTransport.cardType
    },
    filteredTabs() {
      return tabs[this.type]
    }
  },
  watch: {
    type() {
      this.activeTab = '0'
    }
  }
}
</script>

<style lang="scss">
.pt-card-tabs {
  height: 100%;
  overflow: hidden;
}
</style>
