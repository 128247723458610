<template>
  <div class="map-filters">
    <div class="map-filters__header">
      <r-title type="subtitle-2">
        Фильтрация объектов
      </r-title>
    </div>
    <div class="map-filters__container">
      <div class="map-filters__actions">
        <r-button
          type="primary"
          @click="addLayerFilter"
        >
          Добавить фильтр
        </r-button>
      </div>
      <div
        v-if="filters.length"
        class="map-filters__list"
      >
        <div class="map-filters__list-container">
          <filter-item
            v-for="filter in filters"
            :id="id"
            :key="filter.id"
            :filter="filter"
            :controllers="controllers"
            @remove-filter="removeFilter"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilterItem from './filter-item'

const filterConfig = {
  enabled: true,
  id: '',
  type: '',
  label: '',
  field: '',
  range: [1, 50],
  min: 1,
  max: 50,
  show: true,
  chunks: [],
  allCount: 0,
  fieldType: 'number',
  loading: false,
  layerId: '',
  // strings
  allValues: [],
  values: [],
  operation: 'in'
}

export default {
  components: {
    FilterItem
  },
  props: {
    id: {
      type: String,
      required: true
    },
    layerStyle: {
      type: Object,
      required: true
    },
    controllers: {
      type: Object,
      required: true
    }
  },
  computed: {
    filters() {
      return this.layerStyle.filters || []
    }
  },
  methods: {
    addLayerFilter() {
      const source_id = this.$store.state.profiles.sourceIdById[this.id]
      const { datatype, default_type } =
        this.$store.state.datasources[source_id]
      this.layerStyle.filters.push({
        ...filterConfig,
        type: datatype === 'arc_layer' ? datatype : default_type,
        layerId: this.id,
        id: Date.now()
      })
    },
    removeFilter(filter) {
      this.layerStyle.filters.splice(this.filters.indexOf(filter), 1)
    }
  }
}
</script>

<style lang="scss">
.map-filters {
  &__title {
    vertical-align: middle;
    font-weight: bold;

    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 4px;
      height: 18px;
      border-radius: 2px;
      background-color: var(--accent_primary) !important;
      margin-right: 8px;
      margin-top: -1px;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 5px;
    line-height: 24px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;

    .r-button {
      flex: 1;
    }
  }

  &__list {
    margin-top: 20px;

    &-title {
      margin-bottom: 10px;
    }
  }
}
</style>
