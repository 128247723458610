export const rolesTabsList = [
  {
    id: 1,
    name: 'Модули и компоненты',
    value: 'modules'
  },
  {
    id: 2,
    name: 'Источники данных',
    value: 'datasources'
  }
]
