<template>
  <div class="pt-stops-list">
    <ul
      v-if="stopList.length"
      class="pt-stops-list__items"
    >
      <li
        v-for="(stop, i) in stopList"
        :key="stop.id + i"
        :class="getStopClass(stop.id)"
        @click="clickHandler(stop)"
      >
        <r-icon
          :size="18"
          name="bus-station"
        />
        <r-text>
          {{ stop.name || stop.no }}
        </r-text>
      </li>
    </ul>
    <div
      v-else
      class="pt-stops-list__nodata"
    >
      <r-text type="caption">
        Список пуст
      </r-text>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stops: {
      type: Array,
      required: true
    }
  },
  computed: {
    cardId() {
      return this.$store.state.publicTransport.cardId
    },
    stopList() {
      return this.stops
    }
  },
  methods: {
    getStopClass(id) {
      return id === this.cardId
        ? 'pt-stops-list__item pt-stops-list__item--active'
        : 'pt-stops-list__item'
    },
    clickHandler({ id }) {
      this.$store.commit('SET_PT_FIELD', {
        field: 'cardId',
        value: id
      })
      this.$store.commit('SET_PT_FIELD', {
        field: 'cardType',
        value: 'stop_points'
      })
    }
  }
}
</script>

<style lang="scss">
.pt-stops-list {
  width: 100%;
  height: 100%;
  overflow: auto;

  &__items {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.25rem;
  }

  &__item {
    padding: 0.25rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: var(--border-radius);
    width: 100%;

    &.pt-stops-list__item--active {
      background-color: var(--accent_active) !important;
    }

    &:hover {
      background-color: var(--accent_hover) !important;
    }

    .r-icon {
      margin-right: 8px;
    }
    .r-text {
      flex: 1;
    }
  }
}
</style>
