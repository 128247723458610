<template>
  <odd-router-create />
</template>

<script>
import OddRouterCreate from '@/components/odd/panels/router/create'

export default {
  components: { OddRouterCreate }
}
</script>
