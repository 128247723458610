<template>
  <div class="rp-users-control">
    <el-tabs
      v-model="activeTab"
      class="r-tabs"
      stretch
    >
      <el-tab-pane
        v-for="tab in usersTabsList"
        :key="tab.id"
        :name="tab.value"
        :disabled="isIbAdmin || isAdmin"
      >
        <r-text
          slot="label"
          class="r-tabs__label"
        >
          {{ tab.name }}
        </r-text>
        <component :is="`rp-users-${activeTab}`" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import rpUsersPersonal from './rp-users-personal/rp-users-personal'
import rpUsersModules from './rp-users-modules/rp-users-modules'
import rpUsersDatasources from './rp-users-datasources/rp-users-datasources'

import { usersTabsList } from './configs'

export default {
  components: {
    rpUsersPersonal,
    rpUsersModules,
    rpUsersDatasources
  },
  data() {
    return {
      activeTab: '',
      usersTabsList
    }
  },
  computed: {
    activeUserId() {
      return this.$store.state.rolePermission.activeUserId || null
    },
    activeUser() {
      const users = this.$store.state.rolePermission.allUsers || []

      return users?.find(u => u.id === this.activeUserId)
    },
    isIbAdmin() {
      return this.activeUser?.ib_admin || false
    },
    isAdmin() {
      return this.activeUser?.admin || false
    }
  },
  watch: {
    activeUserId() {
      if (this.isAdmin || this.isIbAdmin) {
        this.activeTab = this.usersTabsList[0]?.value
      }
    }
  },
  created() {
    this.activeTab = this.usersTabsList[0]?.value
  }
}
</script>

<style lang="scss">
.rp-users-control {
  position: relative;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0px;

  .el-tabs__header {
    width: 540px;
  }

  .el-tabs__item {
    &.is-disabled {
      opacity: 0.4;
    }
  }

  .el-tabs__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 0;
    overflow: auto;
  }
}
</style>
