import {
  removeHoveredSPHelpers,
  removeStopPointsHelpers,
  clearAllRouteGeometry,
  clearActiveStopPointsStyling,
  clearFixedRouteItems
} from '@/libs/map-draw/helpers'
import { layersConfig } from '../configs'

export class EditorController {
  constructor(parent) {
    this.parent = parent
    this.mapgl = parent.mapgl
    this.draw = parent.draw
    this.$store = parent.$store
  }

  enableCreateMode() {
    const { editorState } = this.$store.state.publicTransport
    const { type } = editorState

    this.mapgl.addControl(this.draw)
    this.draw.changeMode(`draw_${type}`)

    this.mapgl.on('draw.create', e => {
      const [feature] = e.features
      const { geometry, properties } = feature
      const { node_id, link_id, stop_geom, line_route_items } = properties

      this.$store.commit('SET_PT_EDITOR_PROP', {
        field: 'geometry',
        value: geometry
      })

      if (type === 'stop_points') {
        this.$store.commit('SET_PT_CREATED_PROPERTY', {
          field: 'node_id',
          value: node_id
        })
        this.$store.commit('SET_PT_CREATED_PROPERTY', {
          field: 'link_id',
          value: link_id
        })
        this.$store.commit('SET_PT_CREATED_PROPERTY', {
          field: 'stop_geom',
          value: stop_geom
        })
      } else {
        this.$store.commit('SET_PT_CREATED_PROPERTY', {
          field: 'line_route_items',
          value: line_route_items
        })
      }
    })

    this.mapgl.on('draw.delete', () => {
      this.draw.changeMode(`draw_${type}`)

      this.$store.commit('SET_PT_EDITOR_PROP', {
        field: 'geometry',
        value: null
      })
    })

    this.mapgl.on('draw.modechange', () => {
      clearActiveStopPointsStyling(this.mapgl, this.$store)
    })
  }

  enableEditMode() {
    const { editorState } = this.$store.state.publicTransport
    const { id, geometry, properties } = editorState
    if (editorState.type !== 'stop_points') return

    this.toggleEditorStyling(true)

    this.mapgl.setLayoutProperty('pt-active-card', 'visibility', 'none')

    this.mapgl.addControl(this.draw)
    this.draw.set({
      type: 'FeatureCollection',
      features: [
        {
          id,
          type: 'Feature',
          geometry,
          properties
        }
      ]
    })
    this.$store.commit('SET_PT_EDITOR_PROP', {
      field: 'stopGeom',
      value: properties.geom
    })

    this.mapgl.on('draw.update', e => {
      const [feature] = e.features
      const { geometry, properties } = feature

      this.$store.commit('SET_PT_EDITOR_PROP', {
        field: 'geometry',
        value: geometry
      })
      this.$store.commit('SET_PT_EDITOR_PROP', {
        field: 'stopGeom',
        value: properties.stop_geom
      })
    })

    this.draw.changeMode('simple_select', { featureIds: [id] })

    const geojson = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: properties.geom
        },
        {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: [
              properties.geom.coordinates,
              properties.stop_geom
                ? properties.stop_geom.coordinates
                : properties.geom.coordinates
            ]
          }
        }
      ]
    }

    const source = this.mapgl.getSource('pt_editor_helpers')

    if (source) {
      source.setData(geojson)
    } else {
      this.mapgl.addSource('pt_editor_helpers', {
        type: 'geojson',
        data: geojson
      })
      this.mapgl.addLayer({
        id: 'pt_editor_helpers_projections',
        source: 'pt_editor_helpers',
        ...layersConfig.stop_points_projections,
        filter: ['==', '$type', 'Point']
      })
      this.mapgl.addLayer({
        id: 'pt_editor_helpers_connections',
        source: 'pt_editor_helpers',
        ...layersConfig.stop_points_connections,
        filter: ['==', '$type', 'LineString']
      })
    }
  }

  disableEditor() {
    if (this.mapgl.hasControl(this.draw)) {
      this.mapgl.removeControl(this.draw)
    }

    this.toggleEditorStyling(false)
    this.clearHelpers()

    this.mapgl.setLayoutProperty('pt-active-card', 'visibility', 'visible')

    this.$store.commit('CLEAR_PT_EDITOR_STATE')

    if (this.mapgl.getSource('pt_editor_helpers')) {
      this.mapgl.removeLayer('pt_editor_helpers_connections')
      this.mapgl.removeLayer('pt_editor_helpers_projections')
      this.mapgl.removeSource('pt_editor_helpers')
    }

    const source = this.mapgl.getSource('fix-route-items')
    source.setData({
      type: 'FeatureCollection',
      features: []
    })
  }

  toggleEditorStyling(flag) {
    const { editorState } = this.$store.state.publicTransport
    const { layerId, type, id } = editorState

    if (!layerId) return

    if (flag) {
      const value = ['case', ['==', ['get', 'id'], id], 0.2, 1]

      if (type === 'stop_points') {
        this.mapgl.setPaintProperty(layerId, 'circle-opacity', value)
        this.mapgl.setPaintProperty(layerId, 'circle-stroke-opacity', value)
        this.mapgl.setPaintProperty(`${layerId}_icons`, 'icon-opacity', value)
        this.mapgl.setPaintProperty(
          `${layerId}_points`,
          'circle-opacity',
          value
        )
        this.mapgl.setPaintProperty(
          `${layerId}_points`,
          'circle-stroke-opacity',
          value
        )
        this.mapgl.setPaintProperty(
          `${layerId}_connections`,
          'line-opacity',
          value
        )
      } else {
        this.mapgl.setPaintProperty(layerId, 'line-opacity', value)
      }

      return
    }

    if (type === 'stop_points') {
      this.mapgl.setPaintProperty(layerId, 'circle-opacity', 1)
      this.mapgl.setPaintProperty(layerId, 'circle-stroke-opacity', 1)
      this.mapgl.setPaintProperty(`${layerId}_icons`, 'icon-opacity', 1)
      this.mapgl.setPaintProperty(`${layerId}_points`, 'circle-opacity', 1)
      this.mapgl.setPaintProperty(
        `${layerId}_points`,
        'circle-stroke-opacity',
        1
      )
      this.mapgl.setPaintProperty(`${layerId}_connections`, 'line-opacity', 1)
    } else {
      this.mapgl.setPaintProperty(layerId, 'line-opacity', 1)
    }
  }

  clearHelpers() {
    // clear sp helpers
    removeHoveredSPHelpers(this.mapgl)
    removeStopPointsHelpers(this.mapgl)
    // clear route helpers
    clearAllRouteGeometry(this.mapgl)
    // clearReverseRouteStyling(this.mapgl)
    clearActiveStopPointsStyling(this.mapgl, this.$store)

    clearFixedRouteItems(this.mapgl, this.$store)
  }
}
