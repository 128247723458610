<template>
  <div class="m-map">
    <div id="m-map" />

    <div class="m-map__actions">
      <r-map-tools
        v-if="mapgl"
        :map="mapgl"
        :tools="mapTools"
        :map-bearing="mapBearing"
        :is3d="is3d"
        :baselayer-id="baselayerId"
        @change-prop="changeProp"
        @toggle-base-layer="toggleBaselayer"
      />
    </div>

    <r-map-popup :settings="popupSettings" />

    <div
      v-if="overlay"
      class="m-map__overlay"
    />

    <div
      v-if="mapLoading"
      class="m-map__loader"
    >
      <span class="el-icon-loading" />
      Загрузка данных...
    </div>
  </div>
</template>

<script>
import { initMap } from './core'

export default {
  data() {
    return {
      mapgl: null,
      mapBearing: null,
      is3d: false,
      isLegendActive: true,
      controllers: {},
      popupSettings: {
        display: 'none',
        top: 0,
        left: 0,
        values: []
      },
      editorState: this.$store.state.modelling.editorState
    }
  },
  computed: {
    mapLoading() {
      return this.$store.state.modelling.loadingLayers.length > 0
    },
    overlay() {
      return this.$store.state.modelling.overlay
    },
    baselayerId() {
      return this.$store.state.mapConfigs?.baselayers?.modelling || 5
    },
    mapTools() {
      return ['baselayers', '3d', 'reset', 'screenshot']?.filter(e => {
        if (e === 'baselayers') {
          return this.$route.name === 'm-scenarios'
        }
        return true
      })
    }
  },
  watch: {
    $route: {
      handler: function(to, from) {
        const { name } = this.$route

        if (name === from.name) return

        if (from.name === 'm-editor') {
          this.controllers.editor.toggleEditorOff()
        }

        switch (name) {
          case 'm-scenarios':
            this.controllers.layers.removeLinksLayer()

            this.$store.commit('SET_CALC_FIELD', {
              field: 'settingsId',
              value: null
            })

            this.controllers.model.loadScenarios(from.name === 'm-editor' ? 4000 : 500)

            return
          case 'm-editor':
            this.controllers.layers.addLinksLayer()
            this.controllers.editor.toggleEditorOn()
            return
          case 'm-scenario':
            this.controllers.layers.addLinksLayer()
        }
      }
    },
    '$store.state.modelling.settingsId': function() {
      this.$nextTick(() => this.mapgl.resize({ noRequest: true }))
    }
  },
  async mounted() {
    await initMap(this)

    const { model } = this.controllers

    await model.loadModel()
    await model.loadScenarios()
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.saveMapParams)
    this.saveMapParams()
  },
  methods: {
    async toggleBaselayer({ id }) {
      this.$store.commit('MAP_CONFIGS_SET_CONFIG', {
        field: 'baselayers',
        module: 'modelling',
        value: id
      })

      await initMap(this)

      const { model } = this.controllers

      await model.loadModel()
      await model.loadScenarios()
    },
    saveMapParams() {
      const zoom = this.mapgl.getZoom()
      const center = this.mapgl.getCenter()

      localStorage.setItem('cMapCenter', JSON.stringify(center))
      localStorage.setItem('cMapZoom', JSON.stringify(zoom))
    },
    changeProp({ field, value }) {
      this[field] = value
    },
    toggleLegend() {
      this.isLegendActive = !this.isLegendActive
    },
    getObjectsLimit() {
      return 10000
    }
  }
}
</script>

<style lang="scss">
.m-map {
  height: 100%;
  width: 100%;
  position: relative;

  &__actions {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }

  &__loader {
    position: absolute;
    top: 10px;
    left: calc(50%);
    transform: translateX(-50%);
    font-size: 12px;
    line-height: 18px;
    padding: 10px 20px;
    border-radius: var(--border-radius);
    border: 1px solid transparent;
    border-color: var(--dividers_low_contrast) !important;
    background-color: var(--bg_panel_primary) !important;
    display: flex;
    align-items: center;
    color: var(--accent_primary) !important;

    span {
      font-size: 18px;
      line-height: 18px;
      margin-right: 4px;
    }
  }

  #m-map {
    width: 100%;
    height: 100%;
  }

  .mapboxgl-canvas {
    &:focus {
      outline: none;
    }
  }
  .mapboxgl-ctrl-top-right {
    display: none;
  }
}
</style>
