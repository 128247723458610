import { widgetStyleConfig } from '../../../configs'

export const getColorValueWithActive = (ids, value) => {
  const condition = [
    'case',
    ['in', ['get', 'id'], ['literal', ids]],
    'rgb(255, 68, 140)'
  ]
  return ids?.length ? [...condition, value] : value
}

export const getWidthValueWithActive = (ids, value) => {
  const condition = ['case', ['in', ['get', 'id'], ['literal', ids]], 3]
  return ids?.length ? [...condition, value] : value
}

export const getSymbolValueWithActive = (ids, activeIcon, value) => {
  const condition = ['case', ['in', ['get', 'id'], ['literal', ids]], activeIcon]
  return ids?.length ? [...condition, value] : value
}

export const updateLayerStyle = (state, objectCards, layers) => {
  layers.forEach(l => {
    const features = state.layersData[l]
    const featuresByTypes = {}

    if (!features) return

    features.forEach(e => {
      if (!featuresByTypes[e.geom.type]) featuresByTypes[e.geom.type] = []
      featuresByTypes[e.geom.type].push(e)
    })

    const layerActiveItems = objectCards?.filter(a => a.layer_id === l)?.map(a => a.id)
    const findType = Object.keys(featuresByTypes)[0]
    const isDefault = !findType || !layerActiveItems?.length
    const sourceId = `${l}_sc_map_${findType}`
    const source = state.mapgl.getSource(sourceId)

    if (!source) return

    const layer = state.mapgl.getLayer(sourceId)

    if (!layer) return

    const { type } = layer
    const property = type === 'circle' ? `${type}-stroke-color` : `${type}-color`
    const defaultColor =
      state.scLayersConfig[`${l}_${findType}`].paint[property]
    const value = isDefault
      ? defaultColor
      : getColorValueWithActive(layerActiveItems, defaultColor)
    state.mapgl.setPaintProperty(sourceId, property, value)

    if (type === 'circle') {
      const defaultStroke =
        state.scLayersConfig[`${l}_Point`].paint['circle-stroke-width']
      const strokeValue = isDefault
        ? defaultStroke
        : getWidthValueWithActive(layerActiveItems, defaultStroke)
      state.mapgl.setPaintProperty(sourceId, 'circle-stroke-width', strokeValue)

      const activeIcon = getActiveIcon(l)
      if (activeIcon) {
        const defaultSymbol =
          state.scLayersConfig[`${l}_Point_s`].layout['icon-image']
        const symbolValue = isDefault
          ? defaultSymbol
          : getSymbolValueWithActive(layerActiveItems, activeIcon, defaultSymbol)
        state.mapgl.setLayoutProperty(`${sourceId}_s`, 'icon-image', symbolValue)
      }
    }
  })
}

const getActiveIcon = layer_id => {
  return widgetStyleConfig[layer_id] && widgetStyleConfig[layer_id].icons
    ? widgetStyleConfig[layer_id].icons.active
    : null
}
