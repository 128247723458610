<template>
  <div
    v-loading="loading"
    class="map-history"
  >
    <div class="map-history-filters">
      <div class="map-history-filters__row">
        <r-text type="caption">
          Начало
        </r-text>
        <el-date-picker
          v-model="start"
          type="datetime"
          class="r-input"
          placeholder="Выберите дату"
          format="dd.MM.yyyy HH:mm"
          :clearable="false"
          :picker-options="{ firstDayOfWeek: 1 }"
        />
      </div>
      <div class="map-history-filters__row">
        <r-text type="caption">
          Окочание
        </r-text>
        <el-date-picker
          v-model="end"
          type="datetime"
          class="r-input"
          placeholder="Выберите дату"
          format="dd.MM.yyyy HH:mm"
          :clearable="false"
          :picker-options="{ firstDayOfWeek: 1 }"
        />
      </div>
      <div class="map-history-filters__row">
        <r-button
          type="primary"
          @click="updateHistory"
        >
          Показать
        </r-button>
      </div>
    </div>
    <history-list :list="list" />
  </div>
</template>

<script>
import HistoryList from './history-list'

export default {
  components: {
    HistoryList
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      list: [],
      start: '',
      end: ''
    }
  },
  async created() {
    const day = 24 * 60 * 60 * 1000
    const now = new Date()
    const time = now.getTime()
    now.setTime(time - day * 3)
    this.start = this.$rDate.format(now, 'iso')
    this.end = this.$rDate.format(new Date(), 'iso')

    this.updateHistory()
  },
  beforeDestroy() {
    this.$store.commit('SET_HISTORY_FEATURES', null)
  },
  methods: {
    async updateHistory() {
      this.loading = true

      try {
        const source_id = this.$store.state.profiles.sourceIdById[this.id]
        const { geom_field } = this.$store.state.datasources[source_id]
        const start = this.$rDate.format(this.start, 'iso')
        const end = this.$rDate.format(this.end, 'iso')
        const url = `objectHistory/${source_id}?limit=100&start_time=${start}&end_time=${end}`
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        this.list = data.map(
          ({ operation, created_at, created_user, new_val }, index) => ({
            type: operation.toLowerCase(),
            user: created_user?.name || 'Без автора',
            date: this.$rDate.format(created_at),
            geom: new_val ? new_val[geom_field] : null,
            index
          })
        )

        // set on map
        const features = this.list
          .filter(({ geom }) => !!geom)
          .map(feature => ({
            type: 'Feature',
            properties: {
              ...feature,
              geom: undefined
            },
            geometry: feature.geom
          }))
        this.$store.commit('SET_HISTORY_FEATURES', {
          type: 'FeatureCollection',
          features
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.map-history {
  height: 100%;
  display: flex;
  flex-direction: column;

  &-filters {
    border-bottom: 1px solid transparent;
    border-color: var(--dividers_low_contrast) !important;
    padding-bottom: 8px;
    margin-bottom: 8px;

    &__row {
      &:not(:first-child) {
        margin-top: 8px;
      }

      .r-button,
      .r-input {
        width: 100% !important;
      }

      .r-text {
        margin-bottom: 4px;
      }
    }
  }
}
</style>
