<template>
  <div class="sc-object-card__macro-cam">
    <img
      class="sc-object-card__macro-cam__image"
      :src="src"
      alt="no-data"
    >
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
      default: null
    }
  },
  data() {
    return {
      refreshData: null,
      login: 'ritm',
      password: '70f9be88b454c25a62a8e4c29f028d5a',
      drawWidth: 800,
      drawHeight: 600,
      delay: 500,
      src: '',
      randomString: ''
    }
  },
  computed: {
    partUrl() {
      return `https://tagil.ritm3.ru/site?login=${this.login}&password=${
        this.password
      }&channelid=${this.id}&resolutionX=${this.drawWidth}&resolutionY=${
        this.drawHeight
      }`
    }
  },
  mounted() {
    this.setSource()
    this.refreshData = setInterval(this.setSource, this.delay)
  },
  beforeDestroy() {
    clearInterval(this.refreshData)
  },
  methods: {
    async setSource() {
      try {
        await fetch(this.partUrl)
          .then(res => {
            return res.blob()
          })
          .then(blob => {
            const img = URL.createObjectURL(blob)
            this.src = img
          })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss">
.sc-object-card__macro-cam {
  position: relative;
  &__image {
    max-width: calc(100% - 4px);
    width: calc(100% - 4px);
    border-radius: var(--border-radius);
    overflow: hidden;
  }
}
</style>

<i18n>
{
  "ru": {
    "location": "Месторасположение",
    "no-cameras": "Камер нет",
    "status": "Статус"
  },
  "en": {
    "location": "Location",
    "no-cameras": "No cameras",
    "status": "Status"
  }
}
</i18n>
