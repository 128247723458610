<template>
  <div class="ee-select-modal">
    <div class="ee-select-modal-content">
      <model-list @change-model="changeModel" />
    </div>
  </div>
</template>

<script>
import ModelList from './model-list/model-list'
import { getModelChildrenIds } from '@/utils'

export default {
  components: {
    ModelList
  },
  props: {
    map: {
      type: Object,
      required: true
    },
    baseController: {
      type: Object,
      required: true
    },
    editorController: {
      type: Object,
      required: true
    }
  },
  methods: {
    async changeModel({ id, children, name }) {
      const ids = getModelChildrenIds(children)
      this.$store.commit('SET_EE_MODEL', { ids, id, name, children })
      this.$store.commit('SET_EE_STATE_PROP', {
        name: 'view',
        value: 'editor'
      })

      await this.baseController.toggleOn(ids)
      setTimeout(() => this.baseController.flyToModel(id), 100)
      await this.editorController.toggleEditorOn('points')
    }
  }
}
</script>

<style lang="scss">
.ee-select-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;

  &-content {
    position: absolute;
    top: 15vh;
    left: 50%;
    transform: translate(-50%);
    border: 1px solid transparent;
    border-radius: var(--border-radius);
    background-color: var(--modal_bg) !important;
    border-color: var(--dividers_low_contrast) !important;
    color: var(--text_secondary) !important;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 16px;
    height: auto;
    min-width: 320px;
    width: max-content;
  }
}
.light-theme {
  .pt-select-modal-content {
    border: none !important;
  }
}
</style>
