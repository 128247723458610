<template>
  <r-block
    col
    top
    no-padding
  >
    <sc-object-card
      v-for="card in objectCards"
      :key="card.id"
      :object-card="card"
    />
  </r-block>
</template>

<script>
import scObjectCard from './object-card.vue'

export default {
  components: {
    scObjectCard
  },
  computed: {
    objectCards() {
      return this.$store.state.situationCenter.objectCards || []
    }
  }
}
</script>
