var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'ds-manager',
    { general: _vm.activeComponent === 'ds-manager-general' }
  ]},[(_vm.activeComponent !== 'ds-manager-general')?_c('div',{staticClass:"ds-manager__header"},[_c('ul',{staticClass:"ds-manager__navigation"},[_c('li',{staticClass:"ds-manager__navigation-item",on:{"click":function($event){return _vm.changeComponent(_vm.previousComponent)}}},[_c('span',{staticClass:"r-icon-arrow left"}),_c('r-text',{attrs:{"color-type":"accent-primary"}},[_vm._v(" "+_vm._s(_vm.$t(`ds-manager:navigation:item-name:${_vm.previousComponent}`))+" ")])],1)])]):_vm._e(),(
      _vm.activeComponent === 'ds-manager-menu' ||
        _vm.activeComponent === 'ds-manager-general' ||
        _vm.activeComponent === 'ds-manager-editor'
    )?_c(_vm.activeComponent,{tag:"component",attrs:{"menu-click-handler":_vm.changeComponent}}):_c(_vm.activeComponent,{tag:"component"}),(_vm.activeComponent === 'ds-manager-general')?_c('r-button',{staticClass:"ds-manager__create-button",attrs:{"type":"primary","icon":"add-plus"},on:{"click":function($event){return _vm.changeComponent('ds-manager-menu')}}},[_vm._v(" "+_vm._s(_vm.$t('ds-manager:create-button'))+" ")]):_vm._e(),(_vm.activeComponent === 'ds-manager-general')?_c('ds-manager-file-import',{on:{"click-handler":_vm.changeComponent}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }