import mapboxgl from 'mapbox-gl'
import { reportOptions } from '../configs'
import { jsonToGeojson, rDate } from '@/utils'
import { layersConfig } from '../components/an-map/configs'
import cloneDeep from 'lodash.clonedeep'

export class PassingReportLoadForPeriod {
  constructor(state) {
    this.$store = state.$store
    this.state = state
    this.mapgl = state.mapgl

    this.options = reportOptions.passing_report_load_for_period
  }

  getTableData(data) {
    const cloneData = cloneDeep(data)

    return cloneData.sort((a, b) => {
      const aValue = a.dt ? rDate.format(a.dt, 'x') : 0
      const bValue = b.dt ? rDate.format(b.dt, 'x') : 0

      return aValue - bValue
    })?.map((e, i) => {
      const item = {}

      e.result.forEach(r => {
        item[r.name] = r.value
      })

      return {
        'Дата распознования': item.date ? rDate.format(item.date, 'DD.MM.YYYY') : '-',
        'Время распознавания': e.dt ? rDate.format(e.dt, 'HH:mm') : '-',
        Распознователь: item.camera_name || '-',
        ГРЗ: item.reg_number || '-',
        'Тип ТС': item.type || '-',
        'Марка/модель ТС': item.model || '-',
        Проект: item.project && item.project !== 'NULL' && item.project !== 'null' ? item.project : '-',
        Подпроект: item.subproject || '-',
        Подрядчик: item.contractor || '-',
        Субподрядчик: item.subcontractor || '-',
        Камера: item.cam || '-',
        Направление: item.direction || '-',
        'Есть пропуск': item.has_permit ? '✓' : '-',
        'Номер пропуска': item.permit_number || '-',
        'Тип пропуска': item.permit_type || '-',
        'Действует с': rDate.format(item.date_from, 'DD.MM.YYYY') || '-',
        'Действует по': rDate.format(item.date_to, 'DD.MM.YYYY') || '-'
      }
    })
  }

  async getMapData() {
    try {
      const { data } = await this.$store.dispatch('GET_REQUEST', {
        url: 'objectInfo/15064f3c-2218-4276-94ce-c0b775895387'
      })
      const filteredData = Object.values(data).filter(e => e.cam_type === 'kpp_cam' || e.cam_type === 'astra')
      const geojson = jsonToGeojson(filteredData)

      return geojson
    } catch (e) {
      console.log(e)
      return null
    }
  }

  async addMapData(data) {
    this.mapgl.addSource('passing-statistic', {
      type: 'geojson',
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 36,
      data
    })

    // add clusters
    this.mapgl.addLayer({
      id: 'passing-statistic-clusters',
      source: 'passing-statistic',
      ...layersConfig.passing_statistic_clusters
    })
    this.mapgl.addLayer({
      id: 'passing-statistic-cluster-count',
      source: 'passing-statistic',
      ...layersConfig.passing_statistic_clusters_count
    })

    this.mapgl.addLayer({
      id: 'passing-statistic',
      source: 'passing-statistic',
      ...layersConfig.passing_statistic
    })

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: 15,
      anchor: 'left',
      className: 'info-layer__custom-popup'
    })

    this.mapgl.on('mouseenter', 'passing-statistic', (e) => {
      this.mapgl.getCanvas().style.cursor = 'pointer'

      const coordinates = e.features[0].geometry.coordinates.slice()
      const name = e.features[0].properties?.name

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
      }

      if (name) {
        popup
          .setLngLat(e.lngLat)
          .setHTML(
          `<dl>
              <dd>${name}</dd>
          </dl>`
          )
          .addTo(this.mapgl)
      }
    })

    this.mapgl.on('mouseleave', 'passing-statistic', () => {
      this.mapgl.getCanvas().style.cursor = ''
      popup.remove()
    })
  }
}
