export const fields = {
  no: '№',
  created_at: 'Создано',
  assignment: 'Назначение',
  contractor_id: 'Подрядчик',
  type_id: 'Тип',
  group_id: 'Группа',
  subgroup_id: 'Подгруппа',
  date_until: 'Срок выполнения',
  description: 'Описание'
}

export const columns = [
  'no',
  'created_at',
  'assignment',
  'contractor_id',
  'type_id',
  'group_id',
  'subgroup_id',
  'date_until',
  'description'
]

export const exportConfig = {
  column_order: [
    'no',
    'created_at',
    'assignment',
    'contractor.name',
    'type.name',
    'group.name',
    'subgroup.name',
    'date_until',
    'description'
  ],
  column_labels: [
    '№',
    'Создано',
    'Назначение',
    'Подрядчик',
    'Тип',
    'Группа',
    'Подгруппа',
    'Срок выполнения',
    'Описание'
  ],
  only: [
    'no',
    'created_at',
    'assignment',
    'date_until',
    'description'
  ],
  include: {
    contractor: {
      only: ['name']
    },
    type: {
      only: ['name']
    },
    group: {
      only: ['name']
    },
    subgroup: {
      only: ['name']
    }
  }
}
