export const mapData = (data) => {
  if (!Array.isArray(data)) return

  return data?.map(e => {
    e.crs = e.geom.crs
    delete e.geom.crs
    e.entry_count = e.counts?.find(k => k.event_type === 'entry')?.count || 0
    e.exit_count = e.counts?.find(k => k.event_type === 'exit')?.count || 0
    delete e.counts
    e.total_count = e.total_count || 0
    e.name = e.name + '&'
    return e
  }) || []
}
