<template>
  <button
    class="pt-router-button"
    @click="
      routeName === 'pt-router'
        ? $router.replace({ name: 'pt-view' })
        : $router.replace({ name: 'pt-router' })
    "
  >
    <r-icon
      name="route"
      :size="24"
      :color-type="routeName === 'pt-router' ? 'accent-primary' : ''"
    />
  </button>
</template>

<script>
export default {
  computed: {
    routeName() {
      return this.$route.name
    }
  }
}
</script>

<style lang="scss">
.pt-router {
  &-button {
    background-color: var(--modal_bg);
    padding: 0;
    position: absolute;
    left: 1rem;
    top: 1rem;
    border: none;
    border-radius: var(--border-radius);
    height: 36px;
    width: 36px;
    z-index: 100;
    cursor: pointer;
    transition: ease 0.15s;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
    &:hover {
      background-color: var(--accent_selected_noopacity);
    }
  }
}
</style>
