<template>
  <bdd-restrictions-register />
</template>

<script>
import bddRestrictionsRegister from '@/components/bdd/restrictions-register/bdd-restrictions-register'

export default {
  components: { bddRestrictionsRegister }
}
</script>
