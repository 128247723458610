<template>
  <div
    :style="`display: ${settings.display};top: ${settings.top}px; left: ${settings.left}px`"
    class="map-popup-list"
  >
    <div class="map-popup-list__title">
      <r-text style="display: inline">
        {{ $t('title') }}
      </r-text>
      <r-text type="caption">
        {{ `(${$t('amount')} ${list.length})` }}
      </r-text>
    </div>
    <ul class="map-popup-list__list">
      <li
        v-for="item in list"
        :key="item.id"
        :class="[
          'map-popup-list__item',
          { active: isActive(item.id) }
        ]"
        @click="clickHandler(item)"
      >
        <div class="map-popup-list__layer-name">
          <r-text type="caption">
            {{ $t(item.layerType) }}
          </r-text>
        </div>
        <icon-image
          v-if="item.resource_id"
          :resource_id="item.resource_id"
          :size="24"
        />
        <div
          v-else
          class="map-popup-list__item-id"
        >
          <r-text>
            {{ item.name || item.id }}
          </r-text>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import iconImage from './icon-image'

export default {
  components: { iconImage },
  props: {
    settings: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    list() {
      return this.settings.values?.map(v => {
        const { properties } = v
        return {
          ...properties
        }
      }
      )
    },
    activeCard() {
      return this.$store.state.odd.cardId
    }
  },
  methods: {
    clickHandler({ id, layerType }) {
      this.$store.commit('SET_ODD_FIELD', {
        field: 'cardId',
        value: id
      })
      this.$store.commit('SET_ODD_FIELD', {
        field: 'cardType',
        value: layerType
      })
    },
    isActive(id) {
      return this.activeCard === id
    }
  }
}
</script>

<style lang="scss">
.map-popup-list {
  position: absolute;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  background-color: var(--bg_surface) !important;
  border-color: var(--dividers_low_contrast) !important;
  min-width: 120px;
  padding: 8px;
  font-size: 12px;
  z-index: 10;
  &__title {
    margin-bottom: 8px;
  }
  &__list {
    max-height: 40vh;
    overflow: auto;
    max-width: 300px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 4px 8px;
    border-bottom: 1px solid;
    border-radius: var(--border-radius);
    border-color: var(--dividers_low_contrast) !important;
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
    &.active {
      background-color: var(--accent_hover) !important;
      * {
        color: var(--accent_primary) !important;
      }
    }
    &:hover {
      background-color: var(--accent_hover) !important;
    }
    &:active {
      background-color: var(--accent_active)!important;
    }
  }
  &__key {
    margin-right: 16px;
  }
  &__value {
    font-weight: bold;
  }
}
</style>

<i18n>
{
  "ru": {
    "title": "Доступные объекты",
    "amount": "кол-во",
    "signs": "Знаки",
    "events": "События"
  },
  "en": {
    "title": "Available objects",
    "amount": "amount",
    "signs": "Signs",
    "events": "Events"
  }
}
</i18n>
