export const getRequestConfig = function(id) {
  const config = {
    where: { field: 'id', value: id, op: '=' },
    only: [
      'id',
      'driver_full_name',
      'driver_phone',
      'reg_number',
      'pot_status_id',
      'pot_status_updated_at',
      'vrc_full_name',
      'arrival_forecast',
      'warehouse_id',
      'checkpoint_id'
    ],
    include: {
      cargoes: {},
      vehicle_type: {
        only: ['name', 'id']
      }
    }
  }
  return config
}

export const infoConfig = {
  main: {
    title: 'Общая информация',
    header: [
      {
        id: 1,
        title: 'ФИО',
        value: 'driver_full_name'
      }
    ],
    info: [
      {
        id: 1,
        title: 'Телефон',
        value: 'driver_phone'
      },
      {
        id: 2,
        title: 'ГРЗ',
        value: 'reg_number'
      },
      {
        id: 3,
        title: 'Телефон диспетчера',
        value: 'dispatcher_phone'
      }
    ]
  },
  delivery: {
    title: 'Информация о доставке',
    header: [
      {
        id: 1,
        title: 'Дата разгрузки',
        value: 'arrival_forecast'
      }
    ],
    info: [
      {
        id: 1,
        title: 'КПП',
        value: 'checkpoint'
      },
      {
        id: 2,
        title: 'Склад',
        value: 'warehouse'
      },
      {
        id: 3,
        title: 'Проект',
        value: 'project'
      },
      {
        id: 4,
        title: 'Подпроект',
        value: 'subproject'
      }
    ]
  },
  cargo: {
    title: 'Информация о грузе',
    info: [
      {
        id: 1,
        title: 'Инвойс',
        value: 'invoice_no'
      },
      {
        id: 2,
        title: 'Упаковочный лист',
        value: 'packing_list_no'
      },
      {
        id: 3,
        title: 'Тип груза',
        value: 'name'
      }
    ]
  }
}
