<template>
  <div :class="['journal-list', { full: journalPinned}]">
    <ul
      v-if="events.length"
      class="journal-list__container"
    >
      <li
        v-for="event in events"
        :key="event.id"
      >
        <journal-list-item :event="event" />
      </li>
    </ul>
    <div
      v-else
      class="journal-list__nodata"
    >
      <r-text
        center
        type="caption"
      >
        Список событий пуст
      </r-text>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    JournalListItem: () => import('./journal-list-item')
  },
  props: {
    events: {
      type: Array,
      required: true
    }
  },
  computed: {
    journalPinned() {
      return this.$store.state.journal.pinned
    }
  }
}
</script>

<style lang="scss">
.journal-list {
  height: 420px;
  overflow: hidden;

  &.full {
    height: auto;
  }

  &__container {
    height: 100%;
    overflow-y: auto;
    display: grid;
    align-content: start;
    grid-gap: 1rem;
    padding: 0.25rem;
  }
}
</style>
