<template>
  <div class="map-style line">
    <classes-color-picker
      :id="id"
      :layer-style="layerStyle.style"
      :controllers="controllers"
      property="line"
      title="Цвет"
      @change-property="changeProperty"
      @change-classes="changeClasses"
      @change-strings="changeStrings"
    />
    <classes-slider-picker
      :id="id"
      :layer-style="layerStyle.style"
      :controllers="controllers"
      property="line-width"
      title="Толщина"
      @change-property="changeProperty"
      @change-range="changeRange"
    />
    <popup-fields-picker
      :id="id"
      :layer-style="layerStyle"
      :controllers="controllers"
      title="Информация"
    />
    <popup-click-fields-picker
      :id="id"
      :layer-style="layerStyle"
      :controllers="controllers"
    />
    <dashed
      style="margin-top: 8px;"
      :config="layerStyle.dashed"
      @toggle="toggleLineProperty('dashed', $event)"
    />
    <arrows
      :config="layerStyle.arrows"
      @toggle="toggleLineProperty('arrows', $event)"
    />
  </div>
</template>

<script>
import ClassesColorPicker from '../input-sources/classes-color-picker'
import ClassesSliderPicker from '../input-sources/classes-slider-picker'
import PopupFieldsPicker from '../input-sources/popup-fields-picker'
import PopupClickFieldsPicker from '../input-sources/popup-click-fields-picker'
import Arrows from '../arrows/arrows'
import Dashed from '../dashed/dashed'

export default {
  components: {
    ClassesColorPicker,
    ClassesSliderPicker,
    PopupFieldsPicker,
    PopupClickFieldsPicker,
    Arrows,
    Dashed
  },
  props: {
    id: {
      type: String,
      required: true
    },
    layerStyle: {
      type: Object,
      required: true
    },
    controllers: {
      type: Object,
      required: true
    }
  },
  created() {
    if (!this.layerStyle.arrows) {
      this.$set(this.layerStyle, 'arrows', { enabled: false })
    }
    if (!this.layerStyle.dashed) {
      this.$set(this.layerStyle, 'dashed', { enabled: false })
    }
  },
  methods: {
    changeProperty(payload) {
      this.$emit('change-property', payload)
    },
    changeClasses(payload) {
      this.$emit('change-classes', payload)
    },
    changeStrings(payload) {
      this.$emit('change-strings', payload)
    },
    changeRange(payload) {
      this.$emit('change-range', payload)
    },
    toggleLineProperty(type, value) {
      this.layerStyle[type].enabled = value
      this.$emit('toggle-line-property', type)
    }
  }
}
</script>

<style lang="scss"></style>
