export const filters = () => [
  {
    id: 'date_from',
    additional_id: 'date_to',
    initType: 'date-filter',
    type: 'day-filter',
    title: 'Срок действия',
    prop: { interval: { from: '', to: '' } },
    active: false
  },
  {
    id: 'permit_type_id',
    type: 'checkbox-filter',
    title: 'Тип пропуска',
    prop: [],
    active: false
  },
  {
    id: 'is_valid',
    type: 'radio-filter',
    title: 'Действителен',
    prop: [
      { id: 'all', label: 'Все' },
      { id: 'yes', label: 'Да' },
      { id: 'no', label: 'Нет' }
    ],
    active: false
  }
]
