<template>
  <div class="m-nodes-settings">
    <r-panel-header title="Параметры слоя" />
    <div class="m-nodes-settings__container">
      <color-control
        :id="id"
        :config="stylesConfigs.style.paint"
        color-field="circle-color"
        opacity-field="circle-opacity"
      />
      <width-control
        :id="id"
        :config="stylesConfigs.style.paint"
        field="circle-radius"
      />
      <outline-control
        :id="id"
        type="nodes"
        :config="stylesConfigs.style.paint"
        color-field="circle-stroke-color"
        opacity-field="circle-stroke-opacity"
        width-field="circle-stroke-width"
      />
    </div>
  </div>
</template>

<script>
import ColorControl from '../controls/color'
import WidthControl from '../controls/width'
import OutlineControl from '../controls/outline'

export default {
  components: {
    ColorControl,
    WidthControl,
    OutlineControl
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    stylesConfigs() {
      return this.$store.state.modelling.stylesConfigs[this.id]
    }
  }
}
</script>

<style lang="scss">
.c-events-settings {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__container {
    flex: 1;
    height: 0px;
    padding: 8px;
  }
}
</style>
