<template>
  <div class="an-export">
    <r-button
      type="primary"
      @click="visible = true"
    >
      Экспортировать дэшборд
    </r-button>
    <an-export-modal
      :visible="visible"
      @close="visible = false"
    />
  </div>
</template>

<script>
import anExportModal from './an-export-modal'

export default {
  components: {
    anExportModal
  },
  data() {
    return {
      visible: false
    }
  }
}
</script>

<style lang="scss">
.an-export {
  position: relative;
}
</style>
