import { rDate } from '@/utils'
import { applicationsConfig } from '../configs'
import { filtersEncoder } from './filters-formatting'

export const parseApplications = function(data) {
  return data.map(e => {
    e.status_name = e.status?.name || 'В архиве'
    e.reg_number = e.vehicle?.reg_number || '—'
    e.driver_full_name = e.vehicle?.driver_full_name || '—'
    e.driver_phone = e.vehicle?.driver_phone || '—'
    e.vehicle_type_group = e.vehicle?.vehicle_type?.vehicle_type_group?.name || '—'
    e.vehicle_model = e.vehicle?.model?.name || '—'
    e.contractor_name = e.contractor?.name || '—'
    e.inspection_comment = e.inspection_comment || '—'
    e.created_at = e.created_at ? rDate.format(e.created_at) : e.created_at
    e.inspection_dt = e.inspection_dt ? rDate.format(e.inspection_dt) : e.inspection_dt
    e.inspection_dt_fact = e.inspection_dt_fact ? rDate.format(e.inspection_dt_fact) : e.inspection_dt_fact
    delete e.status

    return e
  })
}

export const getApplicationsConfig = function(filters) {
  applicationsConfig.where = filtersEncoder(filters)

  return applicationsConfig
}
