import {
  CommonSelectors,
  Constants,
  doubleClickZoom,
  mouseEventPoint,
  createSupplementaryPoints,
  moveFeatures
} from '@/libs/map-draw/lib-utils'
import {
  clearAllHelpers,
  undraggableTypes,
  createLineGeoJson,
  getLineRouteItemsCoordinates,
  clearAllRouteGeometry,
  createRouteHelper,
  // clearReverseRouteStyling,
  setActiveStopPointsStyling,
  clearActiveStopPointsStyling,
  nearestPointOnLine,
  getFeaturesByLayerId,
  clearFixedRouteItems
} from '@/libs/map-draw/helpers'

export const createPTSimpleSelectMode = (MapboxDraw, map) => {
  const SimpleSelect = {}
  const customState = {
    hoveredLinkId: ''
  }

  const { editorState, model } = map.$store.state.publicTransport
  const { ids } = model

  SimpleSelect.onSetup = function(opts) {
    // turn the opts into state.
    const state = {
      dragMoveLocation: null,
      boxSelectStartLocation: null,
      boxSelectElement: undefined,
      boxSelecting: false,
      canBoxSelect: false,
      dragMoveing: false,
      canDragMove: false,
      initiallySelectedFeatureIds: opts.featureIds || []
    }

    this.setSelected(
      state.initiallySelectedFeatureIds.filter(id => {
        return this.getFeature(id) !== undefined
      })
    )
    this.fireActionable()

    this.setActionableState({
      combineFeatures: true,
      uncombineFeatures: true,
      trash: true
    })

    clearAllRouteGeometry(this.map)

    map.modes.simple_select = this

    return state
  }

  SimpleSelect.startOnActiveFeature = function(state, e) {
    // Stop any already-underway extended interactions
    this.stopExtendedInteractions(state)

    // Disable map.dragPan immediately so it can't start
    this.map.dragPan.disable()

    // Re-render it and enable drag move
    this.doRender(e.featureTarget.properties.id)

    // Set up the state for drag moving
    state.canDragMove = undraggableTypes.indexOf(editorState.type) === -1
    state.dragMoveLocation = e.lngLat
  }

  SimpleSelect.onMouseMove = function(state, e) {
    const selected = this.getSelectedIds()

    if (selected && selected[0]) {
      this.doRender(selected[0])
    }
  }

  SimpleSelect.clickOnFeature = function(state, e) {
    // Stop everything
    doubleClickZoom.disable(this)
    this.stopExtendedInteractions(state)

    const isShiftClick = CommonSelectors.isShiftDown(e)
    const selectedFeatureIds = this.getSelectedIds()
    const featureId = customState.hoveredLinkId || e.featureTarget.properties.id
    const isFeatureSelected = this.isSelected(featureId)

    clearAllHelpers(this.map)
    customState.hoveredLinkId = ''

    if (map.$store.state.publicTransport.loadingLayers.length) return

    // Click (without shift) on any selected feature but a point
    if (
      !isShiftClick &&
      isFeatureSelected &&
      this.getFeature(featureId).type !== Constants.geojsonTypes.POINT
    ) {
      // Enter direct select mode

      return this.changeMode(Constants.modes.DIRECT_SELECT, {
        featureId: featureId
      })
    }

    // Shift-click on a selected feature
    if (isFeatureSelected && isShiftClick) {
      // Deselect it
      this.deselect(featureId)
      this.updateUIClasses({ mouse: Constants.cursors.POINTER })
      if (selectedFeatureIds.length === 1) {
        doubleClickZoom.enable(this)
      }
      // Shift-click on an unselected feature
    } else if (!isFeatureSelected && isShiftClick) {
      // Add it to the selection
      this.select(featureId)
      this.updateUIClasses({ mouse: Constants.cursors.MOVE })
      // Click (without shift) on an unselected feature
    } else if (!isFeatureSelected && !isShiftClick) {
      // Make it the only selected feature
      selectedFeatureIds.forEach(id => this.doRender(id))
      this.setSelected(featureId)
      this.updateUIClasses({ mouse: Constants.cursors.MOVE })
    }

    editorState.activeObject = this.getFeature(featureId)

    if (editorState.geom_type === 'lines') {
      const { line_route_items } = editorState.activeObject.properties
      const spIds = line_route_items
        .filter(item => item.stop_point_id)
        .map(item => item.stop_point_id)

      // route arrows
      createRouteHelper(
        this.map,
        editorState.activeObject.getCoordinates(),
        true
      )
      // reverse route styling
      // setReverseRouteStyling(this.map, reverse_route_id)
      // stop points styling
      setActiveStopPointsStyling(this.map, map.$store, spIds)
    }

    // No matter what, re-render the clicked feature
    this.doRender(featureId)
  }

  SimpleSelect.onTap = SimpleSelect.onClick = function(state, e) {
    // Click (with or without shift) on no feature
    if (customState.hoveredLinkId) return this.clickOnFeature(state, e)
    if (CommonSelectors.noTarget(e)) {
      clearFixedRouteItems(this.map, map.$store)
      return this.clickAnywhere(state, e) // also tap
    }
    if (CommonSelectors.isOfMetaType(Constants.meta.VERTEX)(e)) {
      return this.clickOnVertex(state, e)
    } // tap
    if (CommonSelectors.isFeature(e)) return this.clickOnFeature(state, e)
  }

  SimpleSelect.fireUpdate = function() {
    this.map.fire(Constants.events.UPDATE, {
      action: Constants.updateActions.MOVE,
      features: this.getSelected().map(f => f.toGeoJSON())
    })
  }

  SimpleSelect.onMouseOut = function(state) {
    // As soon as you mouse leaves the canvas, update the feature
    if (state.dragMoving) return this.fireUpdate()
    clearAllHelpers(this.map)
  }

  SimpleSelect.onMouseUp = function(state, e) {
    // End any extended interactions
    if (state.dragMoving) {
      this.fireUpdate()
    } else if (state.boxSelecting) {
      const bbox = [
        state.boxSelectStartLocation,
        mouseEventPoint(e.originalEvent, this.map.getContainer())
      ]
      const featuresInBox = this.featuresAt(null, bbox, 'click')
      const idsToSelect = this.getUniqueIds(featuresInBox).filter(
        id =>
          !this.isSelected(id) && !this.getFeature(id).properties.is_center_geom
      )

      if (idsToSelect.length) {
        this.select(idsToSelect)
        idsToSelect.forEach(id => this.doRender(id))
        this.updateUIClasses({ mouse: Constants.cursors.MOVE })
      }
    }
    this.stopExtendedInteractions(state)
  }

  SimpleSelect.onTrash = function() {
    const { type } = editorState
    const { mode } = map.$route.params

    if (mode === 'edit') {
      return
    }

    const selectedIds = this.getSelectedIds()

    if (type === 'lines') {
      clearAllRouteGeometry(this.map)
      // clearReverseRouteStyling(this.map)

      map.$store.commit('SET_PT_EDITOR_PROP', {
        field: 'lineRouteItems',
        value: []
      })
    } else {
      clearActiveStopPointsStyling(this.map, map.$store)
    }

    this.deleteFeature(selectedIds)
    this.fireActionable()

    return this.changeMode(`draw_${type}`)
  }

  SimpleSelect.dragMove = function(state, e) {
    // Dragging when drag move is enabled
    state.dragMoving = true
    e.originalEvent.stopPropagation()

    let delta
    const source = this.map.getSource('pt_editor_helpers')
    const selected = this.getSelected()
    const selectedFeature = selected[0]
    const { link_id } = selectedFeature.properties

    // move count points
    const linksFeatures = getFeaturesByLayerId(this.map, e, ids.links)
    const filteredLinks = linksFeatures.filter(l => l.properties.id === link_id)

    if (filteredLinks.length) {
      const link = filteredLinks[0]
      const nearest = nearestPointOnLine(
        [e.lngLat.lng, e.lngLat.lat],
        link.geometry.coordinates
      )
      const { lngLat } = e

      delta = {
        lng: lngLat.lng - state.dragMoveLocation.lng,
        lat: lngLat.lat - state.dragMoveLocation.lat
      }
      moveFeatures(this.getSelected(), delta)

      const [feature] = this.getSelected()
      feature.setProperty('stop_geom', nearest.geometry)

      // set helpers
      source.setData({
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: nearest.geometry
          },
          {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: [
                nearest.geometry.coordinates,
                [lngLat.lng, lngLat.lat]
              ]
            }
          }
        ]
      })
    }

    const newFeature = this.getSelected()
    const newCoords = newFeature[0].coordinates

    customState.previousCoords = newCoords
    state.dragMoveLocation = {
      lng: newCoords[0],
      lat: newCoords[1]
    }
  }

  SimpleSelect.onDrag = function(state, e) {
    if (state.canDragMove) {
      // draw dependencies map geometry
      this.dragMove(state, e)

      return
    }
    if (this.drawConfig.boxSelect && state.canBoxSelect) {
      return this.whileBoxSelect(state, e)
    }
  }

  SimpleSelect.onMouseUp = function(state, e) {
    // End any extended interactions
    if (state.dragMoving) {
      this.fireUpdate()
    } else if (state.boxSelecting) {
      const bbox = [
        state.boxSelectStartLocation,
        mouseEventPoint(e.originalEvent, this.map.getContainer())
      ]
      const featuresInBox = this.featuresAt(null, bbox, 'click')
      const idsToSelect = this.getUniqueIds(featuresInBox).filter(
        id => !this.isSelected(id)
      )

      if (idsToSelect.length) {
        this.select(idsToSelect)
        idsToSelect.forEach(id => this.doRender(id))
        this.updateUIClasses({ mouse: Constants.cursors.MOVE })
      }
    }
    this.stopExtendedInteractions(state)
  }

  SimpleSelect.toDisplayFeatures = function(state, geojson, display) {
    geojson.properties.active = this.isSelected(geojson.properties.id)
      ? Constants.activeStates.ACTIVE
      : Constants.activeStates.INACTIVE
    display(geojson)
    this.fireActionable()
    if (
      geojson.properties.active !== Constants.activeStates.ACTIVE ||
      geojson.geometry.type === Constants.geojsonTypes.POINT
    ) {
      return
    }
    if (editorState.type === 'lines') {
      const { fixedRouteItems } = editorState
      const lineRouteItems = geojson.properties.user_line_route_items
      const filteredItems = lineRouteItems.filter(
        (item) =>
          item.stop_point_id &&
          fixedRouteItems.findIndex(
            f => f.stop_point_id === item.stop_point_id
          ) === -1
      )
      const coordinates = getLineRouteItemsCoordinates(filteredItems)
      const pointsGeojson = createLineGeoJson(coordinates)

      pointsGeojson.properties = geojson.properties
      createSupplementaryPoints(pointsGeojson).forEach(display)

      const source = this.map.getSource('fix-route-items')

      if (fixedRouteItems.length) {
        source.setData({
          type: 'FeatureCollection',
          features: fixedRouteItems.map(f => ({
            type: 'Feature',
            geometry: {
              ...f.geom,
              type: 'Point'
            }
          }))
        })
      } else {
        source.setData({
          type: 'FeatureCollection',
          features: []
        })
      }
    } else createSupplementaryPoints(geojson).forEach(display)
  }

  return { ...MapboxDraw.modes.simple_select, ...SimpleSelect }
}
