<template>
  <div class="mt-vehicle-list">
    <r-panel-header title="Список всех объектов" />
    <div class="mt-vehicle-list__container">
      <div class="mt-vehicle-list__search">
        <r-search-input
          :filter-text="filterText"
          @change="searchChange"
        />
      </div>
      <ul
        v-if="filteredVehicles.length"
        class="mt-vehicle-list__items"
      >
        <vehicle-item
          v-for="(vehicle, i) in filteredVehicles"
          :key="(vehicle.id + i)"
          :vehicle="vehicle"
          :main-field="getMainField(vehicle)"
          :is-active="vehicle.id === cardId"
          :sub-fields="subFields"
          :icon-name="getIconName(vehicle)"
          has-background
          @click-handler="openCard"
        />
      </ul>
      <div
        v-else
        class="mt-vehicle-list__nodata"
      >
        <r-text type="caption">
          Список пуст
        </r-text>
      </div>
    </div>
  </div>
</template>

<script>
import vehicleItem from '@/components/commons/monitoring/vehicle-item'

import { subFields } from './config'

export default {
  components: {
    vehicleItem
  },
  props: {
    vehicles: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      filterText: '',
      subFields
    }
  },
  computed: {
    preparedVehicles() {
      return this.vehicles.map(e => {
        if (e.cargoes?.length) {
          e.cargoes.forEach((c, i) => {
            if (i > 0) {
              if (c.packing_list_no) e.packing_list_no += `${e.packing_list_no ? ', ' : ''}${c.packing_list_no}`
            } else {
              e.packing_list_no = c.packing_list_no || ''
            }
          })
        }
        return e
      })
    },
    filteredVehicles() {
      const query = this.filterText?.trim()?.toLowerCase()
      if (!query) return this.preparedVehicles

      const queryFields = ['number', 'packing_list_no']

      return this.preparedVehicles?.filter(pv => {
        return queryFields?.map(q => pv?.[q])?.join('')?.toLowerCase()?.includes(query)
      })
    },
    cardId() {
      return this.$store.state.monitoringTmc.cardId
    }
  },
  methods: {
    openCard(vehicle) {
      this.$store.commit('MT_TMC_SET_FIELD', {
        field: 'cardId',
        value: vehicle.id
      })
      this.$store.commit('MT_TMC_SET_FIELD', {
        field: 'trackDataId',
        value: vehicle.track_data_id
      })
    },
    searchChange(e) {
      this.filterText = e
    },
    getMainField(e) {
      return 'number'
    },
    getIconName(e) {
      switch (e.type_name) {
        case 'ЖД':
          return 'train-filled'
        default:
          return 'car-filled'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mt-vehicle-list {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 8px;
    height: 0;
  }

  &__items {
    flex: 1;
    overflow: auto;
    padding:  0 0 8px;
    margin-top: 8px;
  }

  &__nodata {
    padding: 8px;
  }
}
</style>
