<template>
  <bdd-violations-register />
</template>

<script>
import bddViolationsRegister from '@/components/bdd/violations-register/bdd-violations-register'

export default {
  components: { bddViolationsRegister }
}
</script>
