import vue from 'vue'

const state = {
  list: {}
}

const mutations = {
  CHARTS_INIT_CHART(state, id) {
    if (!state.list[id]) {
      vue.set(state.list, id, {})
    }
  },
  CHARTS_SET_FIELD_BY_ID(state, { id, field, value }) {
    if (!state.list[id]) {
      vue.set(state.list, id, {})
    }
    vue.set(state.list[id], field, value)
  }
}

export default {
  state,
  mutations
}
