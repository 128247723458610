<template>
  <div class="ee-card-buttons">
    <r-button
      simple
      @click="cancel"
    >
      Отменить
    </r-button>
    <r-button
      type="primary"
      :disabled="disabled"
      @click="save"
    >
      Применить
    </r-button>
  </div>
</template>

<script>
export default {
  props: {
    save: {
      type: Function,
      default: () => {}
    },
    cancel: {
      type: Function,
      default: () => {}
    },
    feature: {
      type: Object,
      required: true
    },
    required: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    disabled() {
      return this.required.some(prop => !this.feature[prop])
    }
  }
}
</script>

<style lang="scss">
.ee-card-buttons {
  margin-top: 16px;
  display: flex;

  .r-button {
    flex: 1;

    &:last-child {
      margin-left: 16px;
    }
  }
}
</style>
