<template>
  <div id="an-map">
    <r-map-tools
      v-if="mapgl"
      :tools="['baselayers', 'screenshot']"
      :baselayer-id="baselayerId"
      :map="mapgl"
      @toggle-base-layer="toggleBaselayer"
    />
  </div>
</template>

<script>
import { initMap } from './core/'
import extent from 'turf-extent'

export default {
  data() {
    return {
      mapgl: {},
      controllers: {},
      mapData: {},
      activeObjectCache: null
    }
  },
  computed: {
    report() {
      return this.$store.state.analytics.report
    },
    reportData() {
      return this.$store.state.analytics.data
    },
    activeObject() {
      return this.$store.state.analytics.activeObject
    },
    baselayerId() {
      return this.$store.state.mapConfigs?.baselayers?.analytics || 5
    }
  },
  watch: {
    '$store.state.analytics.flyToGeom': function(geom) {
      if (!geom) return

      const bounds = extent(geom)

      this.mapgl.fitBounds(bounds, {
        padding: 20,
        maxZoom: 16
      })
      this.$store.commit('ANALYTICS_SET_FIELD', {
        field: 'flyToGeom',
        value: null
      })
    },
    activeObject: {
      handler: function(object) {
        if (!object || !this.controllers.active_object) return

        if (object.id === this.activeObjectCache?.id) {
          this.controllers.active_object.removeLayer()
          this.activeObjectCache = null
        } else {
          this.controllers.active_object.addLayer(object)
          this.activeObjectCache = object
        }
        this.$store.commit('ANALYTICS_SET_FIELD', {
          field: 'activeObject',
          value: null
        })
      },
      deep: true
    }
  },
  mounted() {
    initMap(this)
  },
  methods: {
    toggleBaselayer({ id }) {
      this.$store.commit('MAP_CONFIGS_SET_CONFIG', {
        field: 'baselayers',
        module: 'analytics',
        value: id
      })

      initMap(this)
    }
  }
}
</script>

<style lang="scss">
#an-map {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .mapboxgl-canvas {
    &:focus {
      outline: none;
    }
  }
}
</style>
