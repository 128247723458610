<template>
  <div class="mt-vehicle-list">
    <r-panel-header title="Список всех объектов" />
    <div class="mt-vehicle-list__container">
      <div class="mt-vehicle-list__search">
        <r-search-input
          :filter-text="filter"
          @change="searchChange"
        />
      </div>
      <ul
        v-if="filteredVehicles.length"
        class="mt-vehicle-list__items"
      >
        <vehicle-item
          v-for="vehicle in filteredVehicles"
          :key="vehicle.id"
          :vehicle="vehicle"
          :is-active="vehicle.id === cardId"
          icon-name="bus-hollow"
          main-field="reg_number"
          :sub-fields="subFields"
          has-background
          @click-handler="openCard"
        />
      </ul>
      <div
        v-else
        class="mt-vehicle-list__nodata"
      >
        <r-text type="caption">
          Список пуст
        </r-text>
      </div>
    </div>
  </div>
</template>

<script>
import VehicleItem from '@/components/commons/monitoring/vehicle-item'

export const subFields = [
  {
    id: 1,
    label: 'Подрядчик',
    value: 'contractor_name'
  }
]

export default {
  components: {
    VehicleItem
  },
  props: {
    vehicles: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      filter: '',
      subFields
    }
  },
  computed: {
    filteredVehicles() {
      if (!this.filter) return this.vehicles

      return this.vehicles.filter(
        v =>
          (v.reg_number &&
            v.reg_number.toLowerCase().includes(this.filter.toLowerCase())) ||
          (v.contractor_name &&
            v.contractor_name.toLowerCase().includes(this.filter.toLowerCase()))
      )
    },
    cardId() {
      return this.$store.state.monitoring.cardId
    }
  },
  methods: {
    openCard(vehicle) {
      this.$store.commit('SET_MT_FIELD', {
        field: 'cardId',
        value: vehicle.id
      })
      this.$store.commit('SET_MT_FIELD', {
        field: 'trackDataId',
        value: vehicle.track_data_id
      })
    },
    searchChange(e) {
      this.filter = e
    }
  }
}
</script>

<style lang="scss" scoped>
.mt-vehicle-list {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 8px;
    height: 0;
  }

  &__items {
    flex: 1;
    overflow: auto;
    padding: 0 0 8px;
    margin-top: 8px;
  }

  &__nodata {
    padding: 8px;
  }
}
</style>
