<template>
  <div class="m-scenarios">
    <r-panel-header title="Сценарии" />
    <div
      v-loading="loading"
      class="m-scenarios__container"
    >
      <div class="m-scenarios__controls">
        <div class="m-scenarios__control">
          <r-button
            type="primary"
            @click="createScenario"
          >
            Новый сценарий
          </r-button>
        </div>
      </div>
      <div class="m-scenarios__filter">
        <el-input
          v-model="filter"
          prefix-icon="el-icon-search"
          class="r-input"
          placeholder="Поиск по названию сценария"
        />
        <r-radio-group
          style="margin-top: 16px"
          :button-list="scenarioTypes"
          :active="activeType"
          @change="changeType"
        />
      </div>
      <template>
        <r-simple-list
          v-if="filteredScenarios.length"
          style="margin: 8px"
          :list="filteredScenarios"
          @click-handler="clickHandler"
        />
        <div
          v-else
          class="m-scenarios__nodata"
        >
          <r-text type="caption">
            Нет ни одного сценария с данным статусом
          </r-text>
        </div>
      </template>
    </div>

    <!-- <r-help
      :title="helpTitle"
      :text="helpText"
    /> -->
  </div>
</template>

<script>
const scenarioTypes = [
  { id: 'done', label: 'Готовые' },
  { id: 'in_progress', label: 'В процессе' },
  { id: 'draft', label: 'Черновики' }
]

export default {
  data() {
    return {
      filter: '',
      activeType: 'done',
      scenarioTypes
    }
  },
  computed: {
    model() {
      return this.$store.state.modelling.model
    },
    scenarios() {
      return this.$store.state.modelling.scenarios || []
    },
    filteredScenarios() {
      return this.scenarios
        .filter(
          m => m.name.toLowerCase().indexOf(this.filter.toLowerCase()) > -1
        )
        .filter(({ status }) => status === this.activeType)
    },
    loading() {
      return this.$store.state.modelling.scenariosLoading
    },
    helpTitle() {
      switch (this.activeType) {
        case 'done':
          return 'Рассчитанные сценарии'
        case 'in_progress':
          return 'Сценарии в расчёте'
        case 'draft':
          return 'Черновик сценария'
        default:
          return ''
      }
    },
    helpText() {
      switch (this.activeType) {
        case 'done':
          return 'В таких сценариях вы не можете изменять и добавлять события, но можете настраивать отображение результатов. Для этого выберите нужный слой в панели слева'
        case 'in_progress':
          return 'Пока сценарий рассчитывается, вы не можете производить с ним никаких действий, кроме просмотра его содержимого'
        case 'draft':
          return 'Черновик – это сценарий, не запущенный в расчёт, его можно редактировать или удалить'
        default:
          return ''
      }
    }
  },
  methods: {
    changeType(value) {
      this.activeType = value
    },
    createScenario() {
      this.$router.push('/app/modelling/main/editor')
    },
    async moveToBranch(branch_id) {
      const { modelId } = this.$route.params
      const url = `branch/${modelId}`
      const data = { branch_id }

      await this.$store.dispatch('POST_REQUEST', { url, data })
    },
    async clickHandler({ id, name, status }) {
      // await this.moveToBranch(id)

      switch (status) {
        case 'draft':
          return this.$router.push(`/app/modelling/main/editor?id=${id}`)
        case 'done':
          this.$store.commit('SET_CALC_FIELD', {
            field: 'currentScenarioId',
            value: id
          })
          this.$store.commit('SET_CALC_FIELD', {
            field: 'currentScenarioName',
            value: name
          })

          return this.$router.push(`/app/modelling/main/${id}`)
        default:
      }
    }
  }
}
</script>

<style lang="scss">
.m-scenarios {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 48px);
  width: 260px;
  position: relative;

  &__controls {
    padding: 8px;
    border-bottom: 1px solid transparent;
    border-color: var(--dividers_low_contrast) !important;

    .r-button {
      width: 100%;
    }
  }

  &__filter {
    padding: 8px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 0px;
  }

  &__list {
    flex: 1;
    overflow: auto;
    height: 0px;
  }

  &__nodata {
    padding: 8px;
    text-align: center;
  }
}
</style>
