export const exceptAttrs = () => [
  'contractor_id',
  'sender_id',
  'subproject_id',
  'subsubproject_id',
  'forwarder_id',
  'xml_doc'
]

export const sortConfig = () => ({
  sender: 1,
  title_number: 2
})
