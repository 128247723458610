<template>
  <div class="rp-attr-filter-content-table">
    <div class="rp-attr-filter-content-table__header">
      <r-text>Выбрано:</r-text>
      <r-text color-type="secondary">
        {{ selectedLength }}
      </r-text>
      <r-text>Всего в списке:</r-text>
      <r-text color-type="secondary">
        {{ data.length }}
      </r-text>
    </div>
    <r-table-search
      v-if="!isLoading"
      placeholder="Поиск по атрибуту"
      :filter-text="filterText"
      :filter-field="filterField"
      :list="fieldList"
      @start-search="startSearch"
    />
    <table-prime-simple
      table-id="rpAttrFilter"
      :data="filteredData"
      :columns="columns"
      :all-checked="allChecked"
      :column-width="columnWidth"
      @selected-changed="setHasChanges"
    />
  </div>
</template>

<script>
import tablePrimeSimple from '@/components/table-prime-simple/table-prime-simple'
import { columnWidth } from './configs'

export default {
  components: { tablePrimeSimple },
  props: {
    data: {
      type: Array,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    allChecked: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      filterText: '',
      filterField: '',
      attrList: [],
      columnWidth
    }
  },
  computed: {
    selectedObjects() {
      return this.$store.state.tablePrimeSimple.selectedObjects?.rpAttrFilter || null
    },
    selectedLength() {
      return this.selectedObjects?.length || 0
    },
    columns() {
      return this.fields.map(f => {
        const key = f.key || f.reflection?.default_show_attribute

        return {
          ...f,
          title: key ? f.title + '.' + key : f.title
        }
      })
    },
    fieldList() {
      return this.columns.map(e => {
        return {
          ...e,
          title: e.value || e.name,
          alias: e.name || e.title
        }
      })
    },
    filteredData() {
      const query = this.filterText?.trim()?.toLowerCase()
      const field = this.filterField?.trim()

      if (!query || !field) return this.data

      return this.data.filter(e => {
        return e[field]?.trim()?.toLowerCase().includes(
          query
        )
      }) || []
    }
  },
  methods: {
    setHasChanges() {
      this.$store.commit('ROLE_PERM_SET_FIELD', {
        field: 'selectedObjectChanged',
        value: true
      })
    },
    startSearch({ filterField, filterText }) {
      this.filterField = filterField || ''
      this.filterText = filterText || ''
    }
  }
}
</script>

<style lang="scss">
.rp-attr-filter-content-table {
  position: relative;
  display: grid;
  grid-gap: 0.5rem;
  overflow: hidden;

  &__header {
    display: flex;
    margin-bottom: 4px;

    > * {
      margin-right: 4px;

      &:nth-child(2) {
        margin-right: 16px;
      }
    }
  }

  .r-table-search__input-block {
    width: 100%;

    .r-input {
      width: 100% !important;
    }
  }
}
</style>
