export const railwaysLayout = {
  7: {
    id: 1,
    way_no: '7',
    capacity: 8
  },
  6: {
    id: 2,
    way_no: '6',
    capacity: 8
  },
  5: {
    id: 3,
    way_no: '5',
    capacity: 8
  },
  3: {
    id: 4,
    way_no: '3',
    capacity: 9
  },
  1: {
    id: 5,
    way_no: '1',
    capacity: 18
  },
  4: {
    id: 6,
    way_no: '4',
    capacity: 1
  }
}
