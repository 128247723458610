<template>
  <div class="mt-card-tabs">
    <r-tabs
      v-model="activeTab"
      metro
      indent
    >
      <r-tab
        v-for="item in tabs"
        :id="item.id"
        :key="item.id"
        :name="item.title"
      />
    </r-tabs>
    <div class="mt-card-tabs__comp">
      <component
        :is="activeTab"
        v-if="info"
        :id="cardId"
        :info="info"
        :source_id="etran_source_id"
      />
    </div>
  </div>
</template>

<script>
import MtTrack from './tabs/track'
import Cargo from './tabs/cargo'
import Forecast from './tabs/forecast/forecast'
import FilesComments from '@/components/files-comments/files-comments'

const tabs = [
  {
    id: 'mt-track',
    title: 'Трек'
  },
  {
    id: 'cargo',
    title: 'Груз'
  },
  {
    id: 'forecast',
    title: 'Прогноз движения'
  },
  {
    id: 'files-comments',
    title: 'Файлы и комментарии'
  }
]

export default {
  components: {
    MtTrack,
    Cargo,
    Forecast,
    FilesComments
  },
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeTab: 'mt-track',
      tabs
    }
  },
  computed: {
    etran_source_id() {
      return this.$store.state.monitoringTmc.etran_source_id
    },
    cardId() {
      return this.$store.state.monitoringTmc.cardId
    }
  }
}
</script>

<style lang="scss">
.mt-card-tabs {
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-gap: 0.5rem;
  align-content: flex-start;
  position: relative;

  &__comp {
    padding: 0.25rem 0.5rem;
    overflow: auto;
    height: 100%;
  }
}
</style>
