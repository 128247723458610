<template>
  <ritm-tree
    :tree-data="treeData"
    :loading="loading"
    :show-tools="showTools"
    :tool-click="toolClick"
    :node-click="nodeClick"
    :active-nodes="activeNodes"
    :icon-by-item="iconByItem"
    :tool-kit="toolKit"
    :no-tools="noTools"
    no-animations
  />
</template>

<script>
import { iconByItem } from '@/utils'

export default {
  components: {
    ritmTree: () => import('@/components/ritm-tree')
  },
  props: {
    nodeClick: {
      type: Function,
      default: () => null
    },
    toolKit: {
      type: Function,
      default: () => null
    },
    toolClick: {
      type: Function,
      default: () => null
    },
    noTools: {
      type: Boolean,
      default: false
    },
    showTools: {
      type: Boolean,
      default: false
    },
    activeNodes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      iconByItem,
      loading: null,
      treeData: []
    }
  },
  computed: {
    isUpdate() {
      return this.$store.state.dsManager.update
    }
  },
  async created() {
    this.updateDsTree()
  },
  methods: {
    async updateDsTree() {
      try {
        this.loading = true
        this.$store.commit('SET_UPDATE_DS_TREE', true)
        const { data } = await this.$store.dispatch('POST_REQUEST', {
          url: 'user/datasources'
        })

        this.treeData = data
        this.$store.commit('SET_DATASOURCE', data)
        this.$store.commit('SET_UPDATE_DS_TREE', false)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
