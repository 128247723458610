<template>
  <el-select
    v-model="settings[field]"
    class="r-select dark"
    clearable
    size="mini"
    popper-class="r-select__popper"
    :multiple="multiple"
    @change="changeHandler(id, $event, filterId)"
  >
    <el-option
      v-for="item in getProps(id, type)"
      :key="item.title"
      :label="item.title"
      :value="item.title"
    >
      <div class="simple-attributes__option-block">
        <span :class="`simple-attributes__option ${item.type}`">
          {{ item.type }}
        </span>
        <span>{{ item.alias }}</span>
      </div>
    </el-option>
  </el-select>
</template>

<script>
export default {
  props: {
    settings: {
      type: Object,
      required: true
    },
    field: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    changeHandler: {
      type: Function,
      required: true
    },
    type: {
      type: String,
      default: 'number'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    filterId: {
      type: Number,
      required: true
    }
  },
  methods: {
    getProps(id, type) {
      const source_id = this.$store.state.profiles.sourceIdById[id]
      let result = this.$clientStore.getDataFields(source_id) || []

      switch (type) {
        case 'number':
          result = result
            .filter(
              f =>
                f.type === 'integer' ||
                f.type === 'decimal' ||
                f.type === 'float' ||
                f.type === 'numeric'
            )
            .filter(f => f.max !== null && f.min !== null && f.max !== 0)
          break
        case 'number-time':
          result = result
            .filter(
              f =>
                f.type === 'integer' ||
                f.type === 'decimal' ||
                f.type === 'float' ||
                f.type === 'numeric' ||
                f.type === 'datetime'
            )
            .filter(f => f.max !== null && f.min !== null && f.max !== 0)
          break
        case 'number-time-string':
          result = result
            .filter(
              f =>
                f.type === 'integer' ||
                f.type === 'decimal' ||
                f.type === 'float' ||
                f.type === 'numeric' ||
                f.type === 'datetime' ||
                f.type === 'string'
            )
            .filter(f => f.max !== null && f.min !== null && f.max !== 0)
          break
        case 'number-time-string-bool':
          result = result
            .filter(
              f =>
                f.type === 'integer' ||
                f.type === 'decimal' ||
                f.type === 'float' ||
                f.type === 'numeric' ||
                f.type === 'datetime' ||
                f.type === 'string' ||
                f.type === 'boolean'
            )
            .filter(f => f.max !== null && f.min !== null && f.max !== 0)
          break
        default:
          result = result.filter(f => !f.source_id)
      }

      return result.map(f => ({ alias: f.alias, title: f.title, type: f.type }))
    }
  }
}
</script>

<style lang="scss">
.simple-attributes {
  &__option-block {
    display: flex;
    align-items: center;
  }

  &__option {
    border: 1px solid white;
    padding: 2px 5px;
    margin-right: 10px;
    position: relative;
    font-size: 10px;
    width: 38px;
    display: block;
    text-align: center;

    &.string {
      color: #eaae00;
      border-color: #eaae00;
    }
    &.uuid {
      color: #767676;
      border-color: #767676;
    }
    &.boolean {
      color: #b5cc18;
      border-color: #b5cc18;
    }
    &.float {
      color: #1678c2;
      border-color: #1678c2;
    }
    &.numeric {
      color: rgb(210, 96, 248);
      border-color: rgb(210, 96, 248);
    }
    &.integer {
      color: rgb(145, 94, 255);
      border-color: rgb(145, 94, 255);
    }
    &.decimal {
      color: rgb(210, 96, 248);
      border-color: rgb(210, 96, 248);
    }
    &.datetime {
      color: #ff4c8d;
      border-color: #ff4c8d;
    }
  }
}

.el-select-dropdown__item span.simple-attributes__option {
  line-height: 16px !important;
}
</style>
