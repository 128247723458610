import debounce from 'lodash.debounce'
import {
  getEditorRequestConfig,
  jsonToGeojson,
  flyToOptions
} from '@/utils'
import { eventEditorLayersConfig } from '../configs'

export class BaseController {
  constructor(parent) {
    this.parent = parent
    this.mapgl = parent.mapgl
    this.$store = parent.$store
    this.handlers = {}
  }

  toggleOn(ids) {
    ['nodes', 'links'].forEach(type => this.toggleOnBaselayer(type, ids))
  }

  toggleOff(ids) {
    ['nodes', 'links'].forEach(type => this.toggleOffBaselayer(type, ids))
  }

  // toggling one base layer
  toggleOnBaselayer(type, ids) {
    const id = ids[type]

    const loadModelLayer = async e => {
      if (e && e.noRequest) return
      const zoom = this.mapgl.getZoom()
      const config = getEditorRequestConfig(this.parent)

      try {
        this.$store.commit('ADD_EE_LOADING_LAYER', id)
        const url = `objectInfo/${id}?config=${JSON.stringify(
          config
        )}&zoom=${zoom}`
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })
        const geojson = jsonToGeojson(Object.keys(data).map(id => data[id]))
        if (!this.mapgl.getLayer(id)) {
          const options = {
            id,
            source: {
              type: 'geojson',
              data: geojson
            },
            ...eventEditorLayersConfig[type]
          }

          if (this.mapgl.getLayer(ids.nodes)) { this.mapgl.addLayer(options, ids.nodes) } else if (this.mapgl.getLayer('gl-draw-polygon-fill-inactive.cold')) { this.mapgl.addLayer(options, 'gl-draw-polygon-fill-inactive.cold') } else this.mapgl.addLayer(options)
        } else {
          this.mapgl.getSource(id).setData(geojson)
        }
        this.$store.commit('REMOVE_EE_LOADING_LAYER', id)
      } catch (error) {
        console.log(error)
        this.$store.commit('REMOVE_EE_LOADING_LAYER', id)
      }
    }

    this.handlers[id] = debounce(loadModelLayer, 200)
    this.handlers[id]()
    this.mapgl.on('moveend', this.handlers[id])
  }

  toggleOffBaselayer(type, ids) {
    const id = ids[type]

    if (this.mapgl.getLayer(id)) this.mapgl.removeLayer(id)
    if (this.mapgl.getSource(id)) this.mapgl.removeSource(id)
    this.mapgl.off('moveend', this.handlers[id])
    delete this.handlers[id]
  }

  // fly to model
  async flyToModel(id) {
    if (!this.$store.state.map.extent[id]) {
      const { data } = await this.$store.dispatch('GET_REQUEST', {
        url: `geom_extent/${id}?`
      })
      const extent = data
      this.$store.commit('SET_LAYER_EXTENT', { id, extent })
    }

    const extent = this.$store.state.map.extent[id]
    const { center, zoom } = this.mapgl.cameraForBounds(
      [extent.slice(0, 2), extent.slice(2)],
      { padding: { top: 10, bottom: 10, left: 10, right: 10 } }
    )
    this.mapgl.flyTo({
      center,
      zoom,
      ...flyToOptions
    })
  }
}
