export const hasCrudsChanges = function(initialList, current) {
  const initialCruds = initialList?.find(e => e.id === current.id)?.cruds
  const currentCruds = current?.cruds

  if (!initialCruds) return false

  const hasChanges = currentCruds.some((e, i) => {
    return e !== initialCruds[i]
  })

  return !!hasChanges
}
