<template>
  <div class="odd-helpers">
    <r-toggle-card
      v-if="isSignsType"
      title="Фикс. привязка знака"
      :is-open="signsFixDistance"
      @toggle="changeHandler"
    >
      <r-text type="caption">
        Фиксированная или свободная привязка знака к дорожному графу (расстояние
        фиксации по умолчанию - 20 м)
      </r-text>
    </r-toggle-card>
    <r-button
      class="odd-helpers__button"
      @click="cancel"
    >
      Отменить создание
    </r-button>
    <sidebar-hint :hint="isSignsType ? hints.sign : hints.closure" />
  </div>
</template>

<script>
import sidebarHint from './sidebar-hint'
import hints from './hints'

export default {
  components: {
    sidebarHint
  },
  data() {
    return {
      hints
    }
  },
  computed: {
    isSignsType() {
      return this.$store.state.odd.editorState?.type === 'signs'
    },
    signsFixDistance: {
      get() {
        return this.$store.state.odd.editorState.signsFixDistance
      },
      set(value) {
        this.$store.commit('SET_ODD_EDITOR_PROP', {
          field: 'signsFixDistance',
          value
        })
      }
    }
  },
  methods: {
    cancel() {
      this.$router.push('/app/odd/objects')
    },
    changeHandler(value) {
      this.signsFixDistance = value
    }
  }
}
</script>
<style lang="scss">
.odd-helpers {
  display: grid;
  grid-gap: 0.5rem;

  &__button {
    width: 100%;
  }
}
</style>
