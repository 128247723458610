export const filters = () => [
  {
    id: 'created_at',
    initType: 'date-filter',
    type: 'day-filter',
    title: 'Дата создания',
    prop: { interval: { from: '', to: '' } },
    active: false
  },
  {
    id: 'inspection_dt',
    initType: 'date-filter',
    type: 'day-filter',
    title: 'Плановая дата осмотра',
    prop: { interval: { from: '', to: '' } },
    active: false
  },
  {
    id: 'vehicle.vehicle_type.vehicle_type_group.id',
    source: 'vehicle_type_group',
    type: 'checkbox-filter',
    title: 'Тип ТС',
    prop: [],
    active: false
  },
  {
    id: 'status_id',
    source: 'applications_status_list',
    type: 'checkbox-filter',
    title: 'Статус',
    prop: [],
    active: false
  },
  {
    id: 'archive',
    type: 'radio-filter',
    title: 'Показать архивные',
    prop: [
      { id: 'all', label: 'Нет' },
      { id: 'yes', label: 'Да' }
    ],
    active: false
  },
  {
    id: 'main_contractor_id',
    source: 'main_contractor_id',
    type: 'checkbox-filter',
    title: 'Подрядчик',
    prop: [],
    active: false
  }
]
