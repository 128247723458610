export const getPlugData = function(options, type) {
  switch (type) {
    case 'category':
      return getDataTypeById(options.layer_id)
    case 'status':
      return 'default_status'
  }
}

const getDataTypeById = function(layer_id) {
  switch (layer_id) {
    case 'monitoring':
      return {
        data: {
          zd: 0,
          ship: 0,
          avto: 0,
          count: 0
        },
        layer_id: 'monitoring',
        type: 'basic_widget'
      }
    case 'violations':
      return {
        data: {
          speed: 0,
          markup: 0,
          count: 0
        },
        layer_id: 'violations',
        type: 'basic_widget'
      }
    default:
      return {
        data: {
          contractor_1: 0,
          contractor_2: 0,
          contractor_3: 0,
          contractor_4: 0,
          count: 0
        },
        layer_id: 'default_category',
        type: 'basic_widget'
      }
  }
}

export const getPlugDataStyleType = function(options, type) {
  switch (type) {
    case 'category':
      return getStyleTypeById(options.layer_id)
    case 'status':
      return 'default_status'
  }

  return ''
}

const getStyleTypeById = function(layer_id) {
  switch (layer_id) {
    case 'public_transport':
      return 'default_category_1'
    case 'monitoring':
      return 'monitoring'
    case 'violations':
      return 'violations'
    default:
      return 'default_category_3'
  }
}
