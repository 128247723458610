import { getUsername } from '@/utils'

const VUE_APP_URL = process.env?.VUE_APP_URL
const VUE_SOCKET_URL = process.env?.VUE_APP_SOCKET_URL

let BASE_URL

const DEV_URL = 'https://dmtlp.zone-it.studio'

switch (process.env.NODE_ENV) {
  case 'development':
    BASE_URL = VUE_APP_URL || DEV_URL
    break
  default:
    BASE_URL = process.env?.VUE_APP_URL || window.location.origin
    break
}

console.log('BASE_URL', BASE_URL)
console.log('SOCKET_URL', VUE_SOCKET_URL || BASE_URL)
export default {
  BASE_URL,
  URL: `${BASE_URL}/rest`,
  SOCKET_URL: `${VUE_SOCKET_URL || BASE_URL}?remote_user=${getUsername()}`
}
