<template>
  <div class="reports-list">
    <r-title color-type="subhead">
      {{ $t('title') }}
    </r-title>
    <r-search-input
      :filter-text="filterText"
      :is-loading="filterLoading"
      @change="e => filterChange(e)"
    />
    <r-simple-list
      v-if="filteredList.length"
      :list="filteredList"
      no-icon
      @click-handler="clickHandler"
    />
    <div
      v-else
      class="reports-list__nodata"
    >
      <r-text type="caption">
        {{ $t('no-data') }}
      </r-text>
    </div>
  </div>
</template>

<script>
import { testReportsList } from '../../configs'

export default {
  data() {
    return {
      filterText: '',
      filterLoading: false,
      testReportsList
    }
  },
  computed: {
    filteredList() {
      return this.testReportsList.filter(
        e =>
          e.title.toLowerCase().indexOf(
            this.filterText.toLowerCase().trim()) > -1
      )
    }
  },
  methods: {
    clickHandler(e) {
      this.$store.commit('REPORTS_SET_ACTIVE_ID', e.id)
    },
    filterChange(e) {
      this.filterText = e
    }
  }
}
</script>

<style lang="scss">
.reports-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100% - 32px);
  > * {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__nodata {
    padding: 40px 8px;
    text-align: center;
  }
}
</style>

<i18n>
{
  "ru": {
    "title": "Выбор отчёта или формы",
    "no-data": "Нет ни одного отчета или формы"
  },
  "en": {
    "title": "Select report or form",
    "no-data": "No data"
  }
}
</i18n>
