<template>
  <div class="r-search-input">
    <el-input
      v-model="value"
      class="r-input"
      :placeholder="placeholderValue"
      :clearable="clearable"
      @blur="handleEvent('blur')"
      @focus="handleEvent('focus')"
    />
    <r-icon
      class="r-search-input__icon"
      :loading="isLoading"
      name="search"
      :size="20"
    />
  </div>
</template>

<script>
import throttle from 'lodash.throttle'

export default {
  props: {
    filterText: {
      type: String,
      defalut: '',
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    clearable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      value: this.filterText
    }
  },
  computed: {
    placeholderValue() {
      return this.placeholder || this.$t('search')
    }
  },
  watch: {
    value(val) {
      this.updateInput(val)
    },
    filterText(text) {
      this.value = text
    }
  },
  methods: {
    updateInput: throttle(function(val) {
      this.$emit('change', val)
    }, 256),
    handleEvent(event) {
      this.$emit('handle-event', event)
    }
  }
}
</script>

<style lang="scss">
.r-search-input {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  .r-input {
    border-bottom: 1px solid;
    border-color: var(--dividers_low_contrast) !important;

    .el-input__inner {
      padding-left: 25px !important;
      background-color: transparent !important;
      border: none !important;
    }
  }
  &__icon {
    position: absolute;
    top: 0;
    height: 100%;
    width: 20px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 4px;
    color: var(--text_secondary) !important;
  }
}
</style>

<i18n>
{
  "ru": {
    "search": "Поиск"
  },
  "en": {
    "search": "Search"
  }
}
</i18n>
