<template>
  <div class="map-tree">
    <ritm-tree
      :loading="loading"
      :tree-data="treeData"
      mini-tools
      :node-click="nodeClick"
      :tool-click="toolClick"
      :tool-kit="toolKitByItem"
      :icon-by-item="iconByItem"
      :active-nodes="activeLayers"
      :loading-nodes="loadingLayers"
    />
  </div>
</template>

<script>
import { flyToOptions, iconByItem } from '@/utils'
const ritmTree = () => import('@/components/ritm-tree')

const layers = [
  'point',
  'line_string',
  'multi_line_string',
  'polygon',
  'multi_polygon',
  'nodes',
  'zones',
  'links',
  'connectors',
  'stop_points',
  'geometry'
]

export default {
  components: {
    ritmTree
  },
  props: {
    map: {
      type: Object,
      required: true
    },
    controllers: {
      type: Object,
      required: true
    },
    toggleModule: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      treeData: [],
      loading: false,
      iconByItem
    }
  },
  computed: {
    loadingLayers() {
      return this.$store.state.map.loadingLayers
    },
    activeLayers() {
      return this.$store.state.map.activeLayers
    },
    currentProfileId() {
      return this.$store.state.profiles.currentProfile.id
    },
    currentProfileTree() {
      return this.$store.state.profiles.currentProfileTree || []
    }
  },
  watch: {
    '$store.state.profiles.updatedTreeTS': {
      handler(v) {
        if (v) this.load()
      }
    }
  },
  created() {
    this.load()
  },
  methods: {
    async load() {
      try {
        this.loading = true

        const sourceFilter = tree => {
          return tree.filter(t => {
            if (!t.children) return !!t.geom_type || t.datatype === 'tile_layer'
            t.children = sourceFilter(t.children)
            return t.children
          })
        }

        if (this.currentProfileTree?.length) {
          this.treeData = sourceFilter(this.currentProfileTree)
        } else {
          const url = `profile_tree?profile_id=${this.currentProfileId}`
          const { data } = await this.$store.dispatch('GET_REQUEST', { url })

          this.$store.commit('SET_CURRENT_PROFILE_TREE', data.profile_trees)
          this.treeData = sourceFilter(data.profile_trees)
        }
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    async nodeClick(layer) {
      await this.controllers.layers.toggleLayer(layer)
    },
    async toolClick(type, data) {
      switch (type) {
        case 'settings':
        case 'editor':
        case 'traffic-counts':
          this.toggleModule({ type, data })
          break
        case 'fly-to-layer':
          await this.flyToLayer(data.id)
          break
        case 'download': {
          if (!data.source_id) data.source_id = data.id
          this.$store.commit('SET_DS_EXPORT_ITEM', data)
          this.$store.commit('OPEN_MODAL_WINDOW', 'ds-export')
          break
        }
      }
    },
    toolKitByItem({ datatype, geom_type, cruds }) {
      const hasPermissions = type => {
        const entities = {
          editor: ['Editor'],
          settings: ['Styles', 'Filters', 'Heatmaps', 'Diagrams']
        }
        const checkGetter = e => this.$store.getters.hasInstrument('Map', e)
        return entities[type]?.some(checkGetter)
      }

      const tools = [
        {
          icon: 'focus-zone',
          perm: true,
          type: 'fly-to-layer',
          name: 'Приблизить на карте',
          purpose: layers
        },
        {
          icon: 'edit-zone',
          perm: hasPermissions('editor'),
          type: 'editor',
          name: 'Редактировать',
          purpose: layers.filter(l => l !== 'geometry')
        },
        {
          icon: 'settings',
          perm: hasPermissions('settings'),
          type: 'settings',
          name: 'Настройки',
          purpose: layers
        },
        {
          icon: 'download',
          perm: cruds?.[5],
          type: 'download',
          name: 'Скачать',
          purpose: ['model']
        }
      ]

      return tools.filter(({ purpose, type, perm }) => {
        const geom = purpose.includes(datatype) || purpose.includes(geom_type)
        return (
          (perm && geom) ||
          (geom && type === 'fly-to-layer') ||
          (datatype === 'model' && purpose.includes(datatype))
        )
      })
    },
    async flyToLayer(id) {
      const source_id = this.$store.state.profiles.sourceIdById[id]

      if (!this.$store.state.map.extent[source_id]) {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `geom_extent/${source_id}?`
        })
        const extent = data
        this.$store.commit('SET_LAYER_EXTENT', { id: source_id, extent })
      }

      const [lon1, lat1, lon2, lat2] = this.$store.state.map.extent[source_id]

      const options = [
        [
          [lon1, lat1],
          [lon2, lat2]
        ],
        { padding: 75 }
      ]

      const { center, zoom } = this.map.cameraForBounds(...options)
      this.map.flyTo({
        center,
        zoom,
        ...flyToOptions
      })
    }
  }
}
</script>

<style lang="scss">
.map-tree {
  height: 100%;
  padding: 0.25rem;
}
</style>
