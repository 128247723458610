<template>
  <div
    v-loading="isLoading"
    class="pot-1-terminal-container"
  >
    <el-input
      v-model="filterText"
      class="r-input"
      placeholder="Поиск по номеру ГРЗ"
      prefix-icon="el-icon-search"
      @focus="handleEvent('focus')"
    />
    <pot-1-terminal-list
      :title="$t('title-1')"
      :data="allowedList"
      :no-data-text="$t('no-data-1')"
      active
    />
    <pot-1-terminal-list
      :title="$t('title-2')"
      :data="waitingList"
      :no-data-text="$t('no-data-2')"
    />
    <div
      v-if="isShowKeyboard"
      class="pot-1-terminal-container__keyboard-wrapper"
    >
      <numeric-keyboard @handle-click="handleKeyboardClick" />
      <div
        class="pot-1-terminal-container__keyboard-close"
        @click="isShowKeyboard = false"
      >
        <r-icon
          name="close-delete"
          :size="40"
        />
      </div>
    </div>
  </div>
</template>

<script>
import pot1TerminalList from './components/pot-1-terminal-list'
import numericKeyboard from '@/components/commons/numeric-keyboard'
import { toggleBodyTheme, sortDataByField } from '@/utils'

export default {
  components: {
    pot1TerminalList,
    numericKeyboard
  },
  data() {
    return {
      filterText: '',
      isShowKeyboard: false,
      isLoading: false
    }
  },
  computed: {
    vehicleListId() {
      return this.$store.state.pot1.vehicleListId || null
    },
    statusListId() {
      return this.$store.state.pot1.statusListIds.vehicles || null
    },
    updateData() {
      return this.$store.state.pot1.updateData || null
    },
    statusList() {
      return this.$store.state.pot1.statusList || []
    },
    terminalList() {
      const list = this.$store.state.pot1.terminalList || []
      if (!this.filterText.trim()) return list

      return list.filter(e =>
        e.reg_number.toLowerCase().indexOf(this.filterText.trim().toLowerCase()) > -1
      )
    },
    allowedList() {
      if (!this.terminalList.length) return []
      const statusId = this.statusList.find(
        e => e.name === 'Разрешён въезд'
      ) || ''

      return this.terminalList.filter(
        e => e.pot_status_id === statusId.id
      ) || []
    },
    waitingList() {
      if (!this.terminalList.length) return []
      const statusId = this.statusList.find(
        e => e.name === 'Ожидает разрешение'
      ) || ''

      return this.terminalList.filter(
        e => e.pot_status_id === statusId.id
      ) || []
    },
    categoriesId() {
      return this.$store.state.pot1.categoriesId || null
    },
    categoriesList() {
      const list = this.$store.state.pot1.categoriesList || {}
      return Object.values(list) || []
    },
    tmcCategoryId() {
      return this.categoriesList.find(e => e.name === 'ТС с ТМЦ')?.id || null
    }
  },
  watch: {
    updateData: {
      handler: function(val) {
        if (!val) return
        this.loadVehicleList()
        this.$store.commit('SET_POT_FIELD', {
          field: 'updateData',
          value: false
        })
      },
      deep: true
    }
  },
  created() {
    this.$store.commit('SET_THEME', 'light-theme')
    toggleBodyTheme('light-theme')
    this.initModule()
  },
  methods: {
    async initModule() {
      try {
        this.isLoading = true
        await this.loadStatusList()
        await this.loadCategoriesList()
        await this.loadVehicleList()
        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.isLoading = false
      }
    },
    handleKeyboardClick(e) {
      switch (e) {
        case 'delete':
          this.filterText = this.filterText.slice(0, -1)
          break
        default:
          this.filterText = this.filterText + e
          break
      }
    },
    searchChange(e) {
      this.filterText = e
    },
    handleEvent(val) {
      switch (val) {
        case 'focus':
          this.isShowKeyboard = true
          break
      }
    },
    async loadStatusList() {
      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/${this.statusListId}`
        })

        const formattedList = Object.values(data).map(s => {
          return {
            name: s.name,
            id: s.id,
            ordered: s.ordered
          }
        })

        formattedList.unshift({
          id: 0,
          name: 'Нет статуса'
        })

        const value = sortDataByField(formattedList, 'ordered')

        this.$store.commit('SET_POT_FIELD', {
          field: 'statusList',
          value
        })
      } catch (e) {
        console.log(e)
      }
    },
    async loadCategoriesList() {
      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/${this.categoriesId}`
        })

        this.$store.commit('SET_POT_FIELD', {
          field: 'categoriesList',
          value: data
        })
      } catch (e) {
        console.log(e)
      }
    },
    async loadVehicleList() {
      if (!this.vehicleListId) return true

      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: 'vehicles_cargo?data=vehicles'
        })

        this.$store.commit('SET_POT_FIELD', {
          field: 'terminalList',
          value: data
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss">
.pot-1-terminal-container {
  max-width: calc(100% - 128px);
  padding: 64px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100% - 128px);
  overflow: auto;
  &__keyboard-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: 0 !important;
  }
  &__keyboard-close {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
  }
  > * {
    margin-bottom: 64px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .r-input {
    border: none !important;
    .el-input__inner {
      font-size: 32px !important;
      padding: 18px 64px !important;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.08);
      height: auto;
    }
    &.el-input--prefix .el-input__inner {
      padding-left: 64px !important;
    }
    .el-input__prefix {
      .el-input__icon  {
        font-size: 40px;
        color: var(--text_secondary);
        padding: 0 0 0 16px;
      }
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "title-1": "Разрешён въезд на разгрузку",
    "no-data-1": "В этом списке появятся водители, которые получили разрешение на разгрузку",
    "title-2": "Ожидают разрешение на разгрузку",
    "no-data-2": "В этом списке появятся водители, которые ожидают разрешение"
  }
}
</i18n>
