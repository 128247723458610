<template>
  <div class="pot-1-print-sheet-info-block">
    <div
      v-if="title"
      class="pot-1-print-sheet-info-block__title"
    >
      <r-title
        type="subtitle-2"
        color="#666666"
        style="font-weight:600"
      >
        {{ title }}
      </r-title>
    </div>
    <div class="pot-1-print-sheet-info-block__content">
      <div
        v-if="info.header"
        class="pot-1-print-sheet-info-block__content-header"
      >
        <div
          v-for="item in info.header"
          :key="item.id"
          class="pot-1-print-sheet-info-block__content-header__item"
        >
          <r-title
            type="subtitle-1"
            :weight="600"
          >
            {{ item.title }}
          </r-title>
          <r-text>
            {{ data[item.value] || 'нет данных' }}
          </r-text>
        </div>
      </div>
      <div
        v-if="info.info"
        class="pot-1-print-sheet-info-block__content-info"
      >
        <div
          v-for="item in info.info"
          :key="item.id"
          class="pot-1-print-sheet-info-block__content-info__item"
        >
          <r-title
            type="subtitle-1"
            :weight="600"
          >
            {{ item.title }}
          </r-title>
          <r-text>
            {{ data[item.value] || 'нет данных' }}
          </r-text>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    info: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.pot-1-print-sheet-info-block {
  position: relative;
  width: 100%;

  &__title {
    margin-bottom: 8px;
  }

  &__content {
    border: 1px solid;
    border-radius: var(--border-radius);
    padding: 12px;

    &-header {
      padding-bottom: 16px;
      border-bottom: 1px solid var(--dividers_low_contrast);
      margin-bottom: 16px;
    }

    &-info {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      > * {
        width: calc(50% - 4px);
        margin-bottom: 16px;

        &:last-child,
        &:nth-child(odd) {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
