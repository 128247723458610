<template>
  <div class="navbar__actions">
    <ul class="navbar__links-list">
      <li
        v-for="link in filteredActionLinks"
        :key="link.id"
        :class="{ bordered: link.value === 'updates', separated: link.divider }"
        @click="linkClickHandler(link.value)"
      >
        <div
          v-if="!link.to"
          class="navbar__links-list__item"
        >
          <el-tooltip
            :open-delay="1000"
            :content="$t(link.value)"
            placement="bottom"
          >
            <r-icon
              :name="link.icon"
              :size="24"
            />
          </el-tooltip>
        </div>
        <div
          v-else
          class="navbar__links-list__item"
        >
          <el-tooltip
            :open-delay="1000"
            :content="$t(link.value)"
            placement="bottom"
          >
            <div
              :class="{ active: $route.path == link.to, navbar__link: true }"
              @click="beforeSetDefaultPath(link)"
            >
              <r-icon
                :name="link.icon"
                :size="24"
                :color="$route.path === link.to ? 'white' : undefined"
              />
            </div>
          </el-tooltip>
        </div>
      </li>
      <li
        v-if="hasModule('Journal') && !journalPinned"
      >
        <el-tooltip
          :open-delay="1000"
          content="Уведомления"
          placement="bottom"
        >
          <el-popover
            v-model="journalPopover"
            placement="bottom-end"
            width="380"
            popper-class="journal-short-popover"
            :visible-arrow="false"
          >
            <journal-short @close="closeJournal" />
            <div
              slot="reference"
              class="navbar__actions-icon"
            >
              <div class="navbar__links-list__item">
                <r-icon
                  name="notification"
                  :size="24"
                />
              </div>
            </div>
          </el-popover>
        </el-tooltip>
      </li>
      <li>
        <account-menu />
      </li>
    </ul>
  </div>
</template>

<script>
import accountMenu from '../account/account-menu'
import JournalShort from '@/components/journal-short/journal-short'
import { actionLinks } from '../../config/'

export default {
  components: {
    accountMenu,
    JournalShort
  },
  data() {
    return {
      taskPopover: false,
      journalPopover: false,
      notifications: true,
      actionLinks
    }
  },
  computed: {
    isAdmin() {
      return this.$store.state.admin
    },
    isLocalAdmin() {
      return this.$store.state.local_admin
    },
    instance() {
      return this.$store.state.instance
    },
    filteredActionLinks() {
      const links = this.actionLinks[this.instance] || this.actionLinks.default

      return links.filter(l => {
        let access
        switch (l.name) {
          case 'UserRoles':
            access = this.isAdmin || this.isLocalAdmin
            break
          default:
            access = this.hasModule(l.name)
            break
        }
        return access
      })
    },
    activeTasks() {
      return this.$store.state.userTasks.activeTasks.length
    },
    beforeClose() {
      return this.$store.state.navbar.beforeClose || null
    },
    journalPinned() {
      return this.$store.state.journal.pinned
    }
  },
  methods: {
    beforeSetDefaultPath(link) {
      if (this.beforeClose) {
        const title = 'Предупреждение'
        const message = 'Все изменения будут отменены. Продолжить?'
        const confirm = 'Подтвердить'
        const cancel = 'Отмена'
        this.$confirm(message, title, {
          customClass: 'r-message-box',
          type: 'warning',
          closeOnPressEscape: false,
          closeOnClickModal: false,
          confirm,
          cancel
        })
          .then(() => {
            this.setDefaultPath(link)
            this.$store.commit('NAVBAR_SET_FIELD', {
              field: 'beforeClose',
              value: null
            })
          })
          .catch(() => {})
      } else {
        this.setDefaultPath(link)
      }
    },
    setDefaultPath(link) {
      if (this.$route.path === link.to) return
      this.$store.commit('SET_DEFAULT_PATH', link.to)
      this.$router.push(link.to)
      this.$store.dispatch('SAVE_MAIN_USER_CONFIG')
    },
    hasModule(name) {
      return this.$store.getters.hasModule(name)
    },
    toggleTaskPopover() {
      this.taskPopover = !this.taskPopover
    },
    closeJournal() {
      this.journalPopover = false
    },
    openWindow(title) {
      const options = { title, type: title }

      this.$store.commit('ADD_NEW_OVERHEAD_WINDOW', options)
      this.$store.dispatch('SAVE_MAIN_USER_CONFIG')
    },
    openModalBox(val) {
      this.$store.commit('OPEN_MODAL_WINDOW', val)
    },
    linkClickHandler(type) {
      switch (type) {
        case 'ds-manager':
        case 'updates':
        case 'email-distributions':
        case 'public-notifications':
          this.openModalBox(type)
          break
      }
    }
  }
}
</script>

<style lang="scss">
.navbar__actions {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  height: 100%;
  margin-left: auto;

  li {
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0 0.5rem;

    &.separated {
      border-right: 1px solid var(--dividers_low_contrast);
    }

    &.bordered {
      width: 64px;
      border-left: 1px solid;
      border-right: 1px solid;
      border-color: var(--dividers_low_contrast) !important;
    }
    a:hover {
      border: none !important;
    }
  }
  .navbar__links {
    &-list {
      display: grid;
      grid-auto-flow: column;

      &__item {
        width: 36px;
        height: 36px;
        border-radius: var(--border-radius);

        .el-tooltip {
          margin-right: 0;
        }

        &:hover {
          background-color: var(--accent_selected) !important;
        }
      }
    }
  }

  &-icon {
    height: 100%;
    display: flex;
    width: 32px;
    margin-right: 1px;
    cursor: pointer;
    position: relative;
    &:last-child {
      margin-right: 0;
    }
    &.el-popover__reference {
      width: 36px;
    }
  }
}
.active-tasks-count {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  right: 0;
  background-color: var(--accent_success) !important;
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px;
    color: var(--white_white) !important;
  }
  &.updates {
    width: 8px;
    height: 8px;
    top: 4px;
    right: 16px;
    background-color: var(--accent_error) !important;
  }
}
</style>

<i18n>
{
  "ru": {
    "public-notifications": "Публичные уведомления в приложении ПАТ",
    "email-distributions": "E-Mail уведомления и рассылка",
    "user-roles": "Роли и разрешения",
    "calendar": "Календарь",
    "updates": "Обновления",
    "ds-manager": "Менеджер ИД",
    "tasks": "Активные задачи",
    "tasks.active-tasks::list": "Список активных задач"
  },
  "en": {
    "public-notifications": "Public notifications in the PT app",
    "email-distributions": "E-mail notifications and distribution",
    "user-roles": "Users&Roles",
    "calendar": "Calendar",
    "updates": "Updates",
    "ds-manager": "DS manager",
    "tasks": "Active tasks",
    "tasks.active-tasks::list": "Active tasks list"
  }
}
</i18n>
