<template>
  <div
    :class="className"
    @click="clickHandler"
  >
    <div class="mt-vehicle-item__header">
      <div class="mt-vehicle-item__icon">
        <r-icon
          :name="iconName"
          :size="24"
        />
      </div>
      <div class="mt-vehicle-item__title">
        <r-text>{{ mainField ? vehicle[mainField] : vehicle.reg_number }}</r-text>
      </div>
    </div>
    <el-tooltip
      v-for="item in subFields"
      :key="item.id"
      :open-delay="1500"
      :content="getValue(item.value)"
      placement="right"
    >
      <div class="mt-vehicle-item__row">
        <r-text type="caption">
          {{ `${item.label}:` }}
        </r-text>
        <r-text type="caption">
          {{ getValue(item.value) }}
        </r-text>
      </div>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    vehicle: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    },
    iconName: {
      type: String,
      default: 'car'
    },
    hasBackground: {
      type: Boolean,
      default: false
    },
    subFields: {
      type: Array,
      default: () => []
    },
    mainField: {
      type: String,
      default: 'name'
    }
  },
  computed: {
    className() {
      const className = this.hasBackground
        ? 'mt-vehicle-item bg'
        : 'mt-vehicle-item'

      return this.isActive ? className + ' active' : className
    }
  },
  methods: {
    clickHandler() {
      this.$emit('click-handler', this.vehicle)
    },
    getValue(val) {
      return this.vehicle[val] ? ` ${this.vehicle[val]}` : ' нет данных'
    }
  }
}
</script>

<style lang="scss" scoped>
.mt-vehicle-item {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: var(--border-radius);

  &.bg {
    background-color: var(--bg_containers) !important;
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__row {
    display: flex;

    .r-text {
      &:first-child {
        flex-shrink: 0;
        margin-right: 4px;
      }

      &:last-child {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }

  &.active {
    background-color: var(--accent_selected) !important;
  }

  &:hover {
    background-color: var(--accent_hover) !important;
  }

  &:not(:first-child) {
    margin-top: 4px;
  }

  &__title {
    margin-left: 8px;
  }
}
</style>
