<template>
  <div
    v-loading="isLoading"
    class="rp-user-create-role"
  >
    <div class="rp-user-create-role__note">
      <r-text>
        Текущие права отличаются от шаблона роли и будут применены только для
        данного пользователя. Вы можете создать новую роль, она сразу применится
        к пользователю.
      </r-text>
    </div>
    <div class="rp-user-create-role__name">
      <r-text color-type="subhead">
        Название роли
      </r-text>
      <el-input
        v-model="roleName"
        placeholder="Инспектор осмотра ТС"
      />
    </div>
    <div class="rp-user-create-role__control">
      <r-button
        simple
        @click="close"
      >
        Отменить
      </r-button>
      <r-button
        type="success"
        :disabled="isDisabled"
        @click="createRole"
      >
        Сохранить
      </r-button>
    </div>
  </div>
</template>

<script>
import { notifyFactory } from '@/utils'
import { getPermsForNewRole } from './helpers'

export default {
  data() {
    return {
      isLoading: false,
      roleName: ''
    }
  },
  computed: {
    activeUserId() {
      return this.$store.state.rolePermission.activeUserId || null
    },
    activeUser() {
      const users = this.$store.state.rolePermission.allUsers || []

      return users?.find(u => u.id === this.activeUserId)
    },
    userRoles() {
      return this.activeUser?.user_roles || []
    },
    allRolesName() {
      return this.$store.state.rolePermission.allRoles.map(e => e.name) || []
    },
    isDisabled() {
      if (!this.roleName?.trim()) return true

      return !!this.allRolesName.find(
        n => n.trim().toLowerCase() === this.roleName.trim().toLowerCase()
      )
    },
    userPerms() {
      return this.$store.state.rolePermission.userPerms || {}
    }
  },
  methods: {
    close() {
      this.$store.commit('CLOSE_MODAL_WINDOW')
    },
    async createRole() {
      this.isLoading = true

      try {
        const { data } = await this.$store.dispatch('POST_REQUEST', {
          url: 'objectInfo/auth.roles?',
          data: {
            name: this.roleName
          }
        })
        const newRoleId = data[0]?.id
        const permissions = getPermsForNewRole(this.userPerms)

        if (newRoleId) {
          await this.$store.dispatch('POST_REQUEST', {
            url: `role?role_id=${newRoleId}`,
            data: { permissions }
          })
          this.$store.commit('ROLE_PERM_SET_FIELD', {
            field: 'updateRoles',
            value: true
          })

          const userRolesData = this.userRoles.map(e => {
            e.disabled = true

            return e
          })
          userRolesData.push({
            user_id: this.activeUserId,
            role_id: newRoleId
          })
          await this.$store.dispatch('POST_REQUEST', {
            url: 'objectInfo/auth.user_roles',
            data: userRolesData
          })
        }
        const title = 'Создание роли'
        const message = 'Создание роли выполнено успешно'

        this.$notify(notifyFactory('success', title, message))
        this.$store.commit('ROLE_PERM_SET_FIELD', {
          field: 'activeUserId',
          value: null
        })
        this.$store.commit('ROLE_PERM_SET_FIELD', {
          field: 'updateUsers',
          value: true
        })
        this.$store.commit('CLOSE_MODAL_WINDOW')
      } catch (e) {
        throw new Error(e)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss">
.rp-user-create-role {
  position: relative;

  > * {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__name {
    > * {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__control {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    justify-content: end;
  }
}
</style>
