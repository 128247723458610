export const widgetStyleConfig = {
  default: {
    icons: {
      active: 'carActive',
      default: 'car'
    },
    colors: {
      default: '#777'
    },
    fields: {
      name: 'name'
    }
  },
  'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=categories':
    {
      icons: {
        'Внутренние ТС': 'car-filled',
        'ТС с ТМЦ': 'truck-filled',
        'Пассажирский транспорт': 'bus-filled',
        default: 'car-filled'
      },
      colors: {
        default: '#777'
      }
    },
  'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=status': {
    icons: {
      default: 'car-filled'
    },
    colors: {
      'На связи': '#59D64E',
      'Нет связи (отметка более 5 мин назад)': '#FF3E3E',
      'На связи (нет координат более 30 мин)': '#D69338',
      'Нет данных': '#FF3E3E',
      default: '#777'
    }
  },
  'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=cargo_types':
    {
      icons: {
        'Грузовые ТС': 'truck-filled',
        'ЖД-вагоны': 'train-filled',
        Баржи: 'ship-filled',
        default: 'truck-filled'
      },
      colors: {
        default: '#777'
      }
    },
  'ZdCarriage/d771ab92-6787-4aa6-ae65-e64d687f5234?format=widget&type=carriages':
    {
      icons: {
        default: 'train-filled'
      },
      colors: {
        'Прибыл на завод': '#5398e0',
        Разгружен: '#5d9e4e',
        'На разгрузке': '#cc8f3d',
        'На сортировке': '#777',
        default: '#777'
      }
    },
  'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=pot_status':
    {
      icons: {
        default: '1-square'
      },
      colors: {
        'Прибыл на завод': '#5398e0',
        Разгружен: '#5d9e4e',
        'На разгрузке': '#cc8f3d',
        'На сортировке': '#777',
        default: '#777'
      }
    },
  'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=app_status':
    {
      icons: {
        default: 'acts-document'
      },
      colors: {
        'Новая заявка': '#777',
        'Назначен осмотр': '#5398e0',
        'Проведён осмотр, нарушений нет': '#5d9e4e',
        'Проведён осмотр, допущен с замечаниями': '#d9ecd0',
        'Заявка отклонена по формальным признакам': '#cc8f3d',
        'Проведён осмотр, есть нарушения, заявка отклонена': '#E9967A',
        'Проведён осмотр, есть нарушения, назначен повторный осмотр': '#87CEFA',
        default: '#777'
      }
    },
  event_stat_class: {
    icons: {
      'Ремонтные работы дорожной сети': 'work',
      ДТП: 'car-accident',
      'Недостатки дорожной сети': 'closed',
      default: 'acts-document'
    },
    colors: {
      default: '#777'
    }
  },
  event_stat_speed: {
    icons: {
      default: 'closed'
    },
    colors: {
      'Без перекрытия': '#79DB62',
      Частичное: '#D69338',
      Полное: '#D94141',
      default: '#777'
    }
  },
  event_stat_time: {
    icons: {
      default: 'date-calendar'
    },
    colors: {
      'Новая заявка': '#777',
      'Назначен осмотр': '#5398e0',
      'Проведён осмотр, нарушений нет': '#5d9e4e',
      'Заявка отклонена по формальным признакам': '#cc8f3d',
      default: '#777'
    }
  },
  'bdd_violation/1b013139-c2d9-4641-a549-b162fce4eb57?format=widget&type=violations':
    {
      icons: {
        default: ''
      },
      colors: {
        default: '#777'
      }
    },
  'api/py/iss/stat': {
    icons: {
      default: 'camera'
    },
    colors: {
      work: '#59D64E',
      error: '#FF3E3E',
      default: '#59D64E'
    }
  },
  'api/py/iss/cameras': {
    icons: {
      active: 'cameraActive',
      default: 'camera'
    },
    colors: {
      work: '#59D64E',
      error: '#FF3E3E',
      inactive: '#999999',
      default: '#59D64E'
    },
    fields: {
      name: 'name',
      server: 'server'
    }
  },
  'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=stat': {
    icons: {
      active: 'carActive',
      default: 'car-filled'
    },
    colors: {
      'Сервис-Интегратор': '#377eb8',
      Неизвестные: '#999999',
      default: '#59D64E'
    },
    fields: {
      reg_number: 'reg_number',
      name: 'name'
    }
  },
  events_log: {
    icons: {
      ДТП: 'car-accident',
      'Ремонтные работы': 'work',
      Мероприятия: 'closed',
      default: 's-center_editor'
    },
    colors: {
      default: '#FF3E3E'
    }
  },
  vehicles: {
    icons: {
      default: 'car-filled'
    },
    colors: {
      default: '#59D64E'
    },
    fields: {
      'Рег. номер': 'reg_number',
      Модель: 'model',
      vrc_full_name: 'vrc_full_name'
    }
  },
  'network.links': {
    icons: {
      default: 'folder-layer'
    },
    colors: {
      default: '#2E93FE'
    },
    fields: {
      Наименование: 'name',
      Номер: 'no'
    }
  },
  events: {
    icons: {
      ДТП: 'car-accident',
      'Ремонтные работы': 'work',
      Мероприятия: 'closed',
      active: [
        'match',
        ['to-string', ['get', 'event_class_id']],
        'ДТП',
        'roadAccidentActive',
        'Ремонтные работы',
        'roadWorkActive',
        'Мероприятия',
        'roadEventActive',
        'roadEventActive'
      ],
      default: 's-center_editor'
    },
    colors: {
      default: '#FF3E3E'
    },
    fields: {
      type: 'event_class_id'
    }
  },
  'orto-agpz': {
    icons: {
      default: 'folder-layer'
    },
    colors: {
      default: '#59D64E'
    },
    fields: {
      name: 'name'
    }
  },
  'orto-aghk': {
    icons: {
      default: 'folder-layer'
    },
    colors: {
      default: '#59D64E'
    },
    fields: {
      name: 'name'
    }
  },
  event_stat: {
    icons: {
      accident: 'car-accident',
      road_works: 'work',
      events: 'closed',
      default: 'closed'
    },
    colors: {
      accident: '#FF3E3E',
      road_works: '#FF3E3E',
      events: '#FF3E3E',
      default: '#FF3E3E'
    }
  },
  track_layer: {
    icons: {
      default: 'gps'
    },
    colors: {
      default: '#2e93fe'
    },
    fields: {
      avg_speed: 'avg_speed',
      to_time: 'to_time',
      from_time: 'from_time',
      distance: 'distance'
    }
  },
  default_status: {
    icons: {
      default: 'camera'
    },
    colors: {
      work: '#59D64E',
      error: '#FF3E3E',
      inactive: '#999999',
      default: '#59D64E'
    },
    fields: {
      name: 'name'
    }
  },
  default_category_1: {
    icons: {
      default: 'bus-filled'
    },
    colors: {
      contractor_1: '#59D64E',
      contractor_2: '#2e93fe',
      contractor_3: '#B33EA9',
      contractor_4: '#ffac00',
      default: '#59D64E'
    }
  },
  default_category_2: {
    icons: {
      default: 'bulldozer-2'
    },
    colors: {
      contractor_1: '#2e93fe',
      contractor_2: '#2e93fe',
      contractor_3: '#2e93fe',
      contractor_4: '#2e93fe'
    }
  },
  default_category_3: {
    icons: {
      default: 'bulldozer-2'
    },
    colors: {
      contractor_1: '#e41a1c',
      contractor_2: '#377eb8',
      contractor_3: '#4daf4a',
      contractor_4: '#a65628',
      default: '#59D64E'
    }
  },
  monitoring: {
    icons: {
      default: 'modelling_transport'
    },
    colors: {
      zd: '#e41a1c',
      ship: '#377eb8',
      avto: '#a65628',
      default: '#59D64E'
    }
  },
  violations: {
    icons: {
      default: 'car-accident'
    },
    colors: {
      speed: '#e41a1c',
      markup: '#e41a1c',
      default: '#e41a1c'
    }
  },
  cameras: {
    icons: {
      default: 'camera'
    }
  },
  cameras_fvf: {
    icons: {
      default: 'camera'
    }
  },
  cameras_kpp: {
    icons: {
      default: 'camera'
    }
  },
  public_transport: {
    icons: {
      default: 'bus-filled'
    }
  },
  road_graf: {
    icons: {
      default: 'folder-layer'
    }
  },
  'bf72f468-4e50-4468-a70f-995e5e22a156': {
    icons: {
      active: 'cameraActive',
      default: 'camera'
    },
    colors: {
      work: '#59D64E',
      error: '#FF3E3E',
      default: '#59D64E'
    },
    fields: {
      'Назавние ': 'name',
      'Номер камеры': 'cam_id'
    }
  },
  'a2a758af-ab06-4add-b555-449fa1f73ab7': {
    icons: {
      active: 'cameraActive',
      default: 'camera'
    },
    colors: {
      work: '#59D64E',
      error: '#FF3E3E',
      default: '#59D64E'
    },
    fields: {
      'Название ': 'name',
      'Номер камеры': 'cam_id'
    }
  }
}

export const getWidgetStyleConfig = layer_id => {
  return widgetStyleConfig[layer_id] || widgetStyleConfig.default
}
