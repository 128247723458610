import inside from '@turf/inside'
import { lineString, point, polygon } from '@turf/helpers'
import nearestOnLine from '@turf/nearest-point-on-line'
import { toMercator, toWgs84 } from '@turf/projection'
import lineOffset from '@/libs/line-offset'

export const isPointInLinkArea = (pointCoords, linkCoords, areaOffset) => {
  const line = lineString(linkCoords)
  const mercLine = toMercator(line)
  const mercLineCoords = mercLine.geometry.coordinates
  const offset = lineOffset(mercLine, areaOffset, { units: 'metres' })
  const offsetCoords = offset.geometry.coordinates
  offsetCoords.reverse()
  const completedCoords = [
    [...mercLineCoords, ...offsetCoords, mercLineCoords[0]]
  ]
  const eventPoint = point(pointCoords)
  const linkPolygon = toWgs84(polygon(completedCoords))

  return inside(eventPoint, linkPolygon)
}

export const nearestPointOnLine = (pointCoords, linkCoords) => {
  const eventPoint = point(pointCoords)
  const eventLine = lineString(linkCoords)

  return nearestOnLine(eventLine, eventPoint)
}
