<template>
  <div class="pt-route-info">
    <div class="pt-route-info__data">
      <div class="data__route-name">
        <r-text color-type="subhead">
          Название маршрута
        </r-text>
        <el-input
          v-model="properties.name"
          class="r-input"
          size="mini"
          placeholder="Новый маршрут"
        />
      </div>
      <div class="data__route-contragent">
        <r-text color-type="subhead">
          Перевозчик
        </r-text>
        <el-select
          v-model="properties.contractor_id"
          class="r-select"
          placeholder="Выберите компанию"
          filterable
          clearable
          size="mini"
        >
          <el-option
            v-for="item in operators"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
      <div class="data__route-color">
        <r-text color-type="subhead">
          Цвет
        </r-text>
        <el-select
          v-model="properties.color"
          class="r-select"
          placeholder="Выбрать цвет"
          size="mini"
          value-key="value"
        >
          <template slot="prefix">
            <span
              class="pt-route-info__color"
              :style="`background-color: ${properties.color}`"
            />
          </template>
          <el-option
            v-for="color in routeColors"
            :key="color.value"
            :value="color.value"
          >
            <span
              class="pt-route-info__color"
              :style="`background-color: ${color.value}`"
            />
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="pt-route-info__options">
      <div class="options__route-toggler">
        <el-switch v-model="properties.active" />
        <r-text>Маршрут работает</r-text>
      </div>
      <div class="options__route-period">
        <r-title type="title-2">
          Начало работы:
        </r-title>
        <el-date-picker
          v-model="properties.valid_from"
          type="date"
          class="r-input"
          format="dd.MM.yyyy"
          placeholder="Выберите дату"
          :picker-options="{ firstDayOfWeek: 1 }"
        />
        <r-title type="title-2">
          Окончание работы:
        </r-title>
        <el-date-picker
          v-model="properties.valid_until"
          type="date"
          class="r-input"
          format="dd.MM.yyyy"
          placeholder="Выберите дату"
          :picker-options="{ firstDayOfWeek: 1 }"
        />
      </div>
      <div class="data__route-description">
        <r-text color-type="subhead">
          Описание маршрута (не обязательно)
        </r-text>
        <el-input
          v-model="properties.description"
          type="textarea"
          class="r-input"
          :rows="3"
          placeholder="Введите описание"
          name="description"
          maxlength="140"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { routeColors } from '@/config/colors'

export default {
  data() {
    return {
      datePickerOptionsFrom: {
        disabledDate: this.disableDatesFrom
      },
      datePickerOptionsTo: {
        disabledDate: this.disableDatesTo
      },
      operators: [],
      routeColors
    }
  },
  computed: {
    properties() {
      return this.$store.state.publicTransport.editorState.properties
    },
    from() {
      return this.route.period.from || null
    },
    to() {
      return this.route.period.to || null
    }
  },
  created() {
    this.getOperators()
  },
  methods: {
    async getOperators() {
      try {
        // const { ids } = this.$store.state.publicTransport.model
        // const id = ids.contractors
        const id = 'ef021b44-a650-478e-a25f-ace1e9b545ca'
        const url = `objectInfo/${id}`

        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        this.operators = Object.values(data)
      } catch (error) {
        console.log(error)
      }
    },
    disableDatesFrom(date) {
      if (!this.to) {
        return date > new Date()
      } else {
        const toDate = new Date(this.to)
        return toDate > new Date() ? date > new Date() : date > toDate
      }
    },
    disableDatesTo(date) {
      const fromDate = new Date(this.from)
      return date < fromDate || date > new Date()
    }
  }
}
</script>

<style lang="scss">
.pt-route-info {
  display: grid;
  grid-template-columns: 270px auto;
  grid-gap: 1rem 2rem;
  justify-items: start;

  &__data {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    & > div {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0.5rem;
    }
  }
  &__options {
    display: grid;
    align-content: start;
    grid-auto-flow: row;
    grid-gap: 1rem;
    & > div {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 0.75rem;
      justify-content: start;
    }
    .options {
      &__route-period {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 2fr;
        grid-gap: 0.5rem 0.5rem;
      }
    }
  }

  .data__route {
    &-color {
      .r-select {
        width: 100%;

        .el-input__prefix {
          width: 80%;
          margin-left: 3px;
          margin-top: 7px;
        }
      }
    }

    &-description {
      display: block;
      .r-text {
        margin-bottom: 0.75rem;
      }
    }
  }

  &__color {
    width: 100%;
    height: 24px;
    display: block;
    border-radius: var(--border-radius);
  }
}
</style>
