<template>
  <div class="railway-control-card-header">
    <r-icon
      name="train-hollow"
      :size="24"
    />
    <r-title
      type="subtitle-1"
      color-type="subhead"
      style="margin-left: 8px"
    >
      {{ `${$t('train-car')} № ${info.number}` }}
    </r-title>
    <div
      style="margin-left: auto; cursor: pointer"
      @click="closeCard()"
    >
      <r-icon
        name="close-delete"
        :size="24"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  methods: {
    closeCard() {
      this.$store.commit('RAILWAY_CONTROL_SET_CARD_ID', '')
    }
  }
}
</script>

<style lang="scss">
.railway-control-card-header {
  position: relative;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px solid;
  border-color: var(--dividers_low_contrast);
}
</style>

<i18n>
{
  "ru": {
    "train-car": "Вагон поезда"
  },
  "en": {
    "train-car": "Train car"
  }
}
</i18n>
