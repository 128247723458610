<template>
  <div
    v-loading="loading"
    class="preventive-register-modal"
  >
    <div class="preventive-register-modal__controls">
      <r-button
        type="primary"
        class="preventive-register-modal__edit-button icon-button"
        icon="edit"
        @click="$emit('mode', 'edit')"
      >
        {{ $t('register_modal::edit-button') }}
      </r-button>
      <r-delete-button
        mini
        @delete="deleteRegisterItem"
      />
    </div>

    <div class="preventive-register-modal__attribute-list">
      <el-select
        v-model="source[`${status.model}_id`]"
        class="r-select"
        size="mini"
        :style="getFieldPosition(status)"
        @change="changeStatus(source[`${status.model}_id`])"
      >
        <el-option
          v-for="item in source.related[status.model].data"
          :key="item.id"
          :value="item.id"
          :label="item.name"
        />
      </el-select>
      <div
        v-for="field in fields"
        :key="field.name"
        class="preventive-register-modal__attribute"
        :style="getFieldPosition(field)"
      >
        <r-text color-type="secondary">
          {{ field.name }}
        </r-text>
        <r-text v-if="field.type === 'text' || field.type === 'textarea'">
          {{ source[field.model] || '—' }}
        </r-text>
        <r-text v-else-if="field.type === 'select'">
          {{ getValueByField(field.model) }}
        </r-text>
        <r-text v-else-if="field.type === 'datetime'">
          {{ $rDate.format(source[field.model]) }}
        </r-text>
      </div>
    </div>
    <filesComments
      :id="source.id"
      :source_id="source_id"
      class="preventive-register-modal__row"
      container
      column
    />
  </div>
</template>

<script>
import filesComments from '@/components/files-comments/files-comments'
import { fields, sources } from './config/fields'

export default {
  components: { filesComments },
  props: {
    source_id: {
      type: String,
      required: true
    },
    source: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      hasChanges: false,
      sources
    }
  },
  computed: {
    fields() {
      return fields.filter(f => f.model !== 'status')
    },
    status() {
      return fields.find(f => f.model === 'status')
    }
  },
  methods: {
    getValueByField(field) {
      const value = this.source.related[field].data.find(
        d => d.id === this.source[`${field}_id`]
      )
      return value?.name || '—'
    },
    async deleteRegisterItem() {
      const { source, source_id, $store } = this
      try {
        await $store.dispatch('POST_REQUEST', {
          url: `objectInfo/${source_id}`,
          data: { id: source.id, disabled: true }
        })
      } catch (e) {
        console.log(e)
      } finally {
        $store.commit('BDD_SET_FIELD', {
          field: sources.updateField,
          value: true
        })
        $store.commit('CLOSE_MODAL_WINDOW')
      }
    },
    async changeStatus(status_id) {
      this.loading = true
      const { source, source_id } = this
      try {
        await this.$store.dispatch('POST_REQUEST', {
          url: `objectInfo/${source_id}`,
          data: {
            id: source.id,
            status_id
          }
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.$store.commit('BDD_SET_FIELD', {
          field: this.sources.updateField,
          value: true
        })
        this.loading = false
      }
    },
    getFieldPosition(field) {
      const width = {
        full: 2,
        half: 1
      }
      const grid = `span ${width[field.width] ?? 2}`
      return `grid-column: ${grid};`
    }
  }
}
</script>

<style lang="scss">
.preventive-register-modal {
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: row;

  &__controls {
    display: grid;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: start;
    grid-auto-flow: column;
  }

  &__attribute {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.5rem;

    .el-date-editor {
      &.el-input {
        width: 100%;
      }
    }

    .el-input__inner {
      padding-right: 2rem !important;
    }

    &-list {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr;
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "register_modal::cancel_button": "Нет",
    "register_modal::confirm_button": "Да",
    "register_modal::warning_title": "Предупреждение",
    "register_modal::warning_text::delete": "Событие будет удалено без возможности восстановления. Продолжить?",
    "register_modal::edit-button": "Редактировать"
  },
  "en": {
    "register_modal::warning_title": "Warning",
    "register_modal::warning_text::delete": "The event will be deleted permanently. Continue?",
    "register_modal::confirm_button": "Yes",
    "register_modal::cancel_button": "No",
    "register_modal::edit-button": "Edit"
  }
}
</i18n>
