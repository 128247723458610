<template>
  <div
    class="r-bottom-panel"
    :style="getStyle"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: [String, Number],
      default: 'auto'
    }
  },
  computed: {
    getStyle() {
      return {
        height:
          typeof this.height === 'number' ? `${this.height}px` : this.height
      }
    }
  }
}
</script>

<style lang="scss">
.r-bottom-panel {
  width: 100%;
  z-index: 10;
  flex-shrink: 0;
  box-shadow: 0 -2px 5px 0px rgba(0, 0, 0, 0.1);
  border-top: 1px solid transparent;
  background-color: var(--bg_panel_secondary) !important;
  border-color: var(--dividers_low_contrast) !important;
}
</style>
