<template>
  <div class="event-attributes">
    <r-button
      v-if="isLine"
      type="primary"
      @click="editByRouter"
    >
      Редактировать геометрию
    </r-button>
    <r-button @click="openModal">
      Дополнительные параметры
    </r-button>
    <div
      key="name"
      class="event-attributes__item"
    >
      <r-text>Название</r-text>
      <el-input v-model="object.name" />
    </div>
    <div
      key="address"
      class="event-attributes__item"
    >
      <r-text>{{ isAccident ? 'Место ДТП' : 'Место' }}</r-text>
      <el-input v-model="object.address" />
    </div>
    <div class="event-attributes__item">
      <r-text>Количество перекрытых полос</r-text>
      <el-input-number
        v-model="object.closed_line_count"
        class="r-input"
        size="mini"
        :min="0"
        :step="0.1"
      />
    </div>
    <r-simple-card
      no-toggler
      title="Период действия"
    >
      <div class="event-attributes__item">
        <div class="event-attributes__item">
          <r-text color-type="secondary">
            Начало
          </r-text>
          <el-date-picker
            v-model="object.start_time"
            type="datetime"
            class="r-input"
            placeholder="Выберите дату"
            format="dd.MM.yyyy HH:mm"
            :picker-options="{ firstDayOfWeek: 1 }"
          />
        </div>

        <div class="event-attributes__item">
          <r-text color-type="secondary">
            Окончание
          </r-text>
          <el-date-picker
            v-model="object.end_time"
            type="datetime"
            class="r-input"
            placeholder="Выберите дату"
            format="dd.MM.yyyy HH:mm"
            :picker-options="{ firstDayOfWeek: 1 }"
          />
        </div>
      </div>
    </r-simple-card>
    <filesComments
      v-if="$route.name === 'odd-objects'"
      :id="object.id"
      :source_id="sourceId"
      class="event-attributes__row"
      closed
      wrap
      column
    />
  </div>
</template>

<script>
import filesComments from '@/components/files-comments/files-comments'

export default {
  components: {
    filesComments
  },
  props: {
    object: {
      type: Object,
      required: true
    },
    geometryType: {
      type: String,
      default: 'Point'
    }
  },
  data() {
    return {
      showNotify: false,
      showSigns: false
    }
  },
  computed: {
    sourceId() {
      return this.$store.state.odd.model.ids.events
    },
    isAccident() {
      return this.object?.event_class?.name === 'ДТП'
    },
    eventClasses() {
      return this.$store.state.odd.books.eventClasses
    },
    signIcons() {
      return this.$store.state.odd.books.signIcons
    },
    isLine() {
      return this.object?.geom?.type === 'LineString'
    }
  },
  methods: {
    editByRouter() {
      if (this.isLine) {
        this.$store.commit('SET_ODD_EDITOR_PROP', {
          field: 'editByRoute',
          value: true
        })
        this.$store.commit('SET_ODD_EDITOR_PROP', {
          field: 'editByRouteGeom',
          value: null
        })
      }
    },
    openModal() {
      const lookup = {
        ДТП: 'accident',
        Недостатки: 'closure',
        'Ремонтные работы': 'closure'
      }
      const typeName = this.eventClasses.find(
        ({ id }) => id === this.object.event_class_id
      )?.name
      const value = lookup[typeName]

      if (value) {
        this.$store.commit('SET_ODD_FIELD', {
          field: 'modalType',
          value
        })
        this.$store.commit('OPEN_MODAL_WINDOW', 'odd-modal-card')
      }
    }
  }
}
</script>

<style lang="scss">
.event-attributes {
  display: grid;
  grid-gap: 1rem;

  &__item {
    display: grid;
    grid-gap: 0.5rem;

    .el-date-editor {
      width: 100% !important;
    }

    &.signs {
      .el-select {
        width: 100%;

        .el-input {
          &__inner {
            font-size: 12px;
            padding-left: 40px !important;
          }

          &__prefix {
            width: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  &__popper {
    max-width: 380px;

    &-image {
      width: 24px;
      height: 24px;
    }

    .el-select-dropdown {
      &__list {
        display: flex;
        flex-wrap: wrap;
        padding: 8px;
      }

      &__item {
        width: 20%;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 38px;
        height: 38px;
        border-radius: var(--border-radius);
      }
    }
  }
}
</style>
