<template>
  <div
    v-loading="loading"
    class="violations-register"
  >
    <violations-header
      :is-grouping="grouping"
      :uri="uri"
      @add="addViolation"
      @grouping="grouping = !grouping"
    />
    <r-filter-set
      class="violations-register__filters"
      :filters="filters"
      @reset-all="resetFilters"
      @load="updateFilters"
    />
    <div class="violations-register__content">
      <r-table-list
        v-if="violations.length"
        ref="table"
        :include-columns="includeColumns"
        :fields-names="tableFields"
        bordered
        :paginator="violations.length > 10"
        num-col
        :data="tableData"
        @click-handler="openModal"
      />
    </div>
  </div>
</template>

<script>
import { fields, tableFields, columns, related, filters } from './configs'
import { buildUrl, buildExportUri, filtersEncoder } from './helpers'
import { getDatasourcesByDatatype, getModelChildrenIds } from '@/utils'
import cloneDeep from 'lodash.clonedeep'

export default {
  components: { violationsHeader: () => import('./components/header/header') },
  data() {
    return {
      loading: false,
      grouping: false,
      filters: [],
      violations: [],
      tableFields,
      uri: `${this.source_id}`,
      source_id: '1b013139-c2d9-4641-a549-b162fce4eb57',
      related: cloneDeep(related)
    }
  },
  computed: {
    includeColumns() {
      return this.grouping ? columns.filter(c => c !== 'status_id') : columns
    },
    tableData() {
      return this.violations.map((v, i) => {
        const getRelatedValue = (source, field = false) => {
          const currentField = field || `${source}_id`
          return (
            this.related[source].data.find(d => d.id === v[currentField])
              ?.name || '—'
          )
        }
        return {
          ...v,
          no: v.no || '-',
          violation_act_no: v.violation_act_no || '—',
          datetime: this.$rDate.format(v.datetime),
          elimination_date: this.$rDate.format(v.elimination_date),
          created_at: this.$rDate.format(v.created_at),
          type_id: getRelatedValue('type'),
          contractor_id: getRelatedValue('contractor'),
          detection_employee_id: getRelatedValue('employees', 'detection_employee_id'),
          subproject_id: getRelatedValue('subproject'),
          subcontractor_id: getRelatedValue('contractor', 'subcontractor_id'),
          subsubcontractor_id: getRelatedValue(
            'contractor',
            'subsubcontractor_id'
          ),
          status_id: getRelatedValue('status'),
          eliminated: v.eliminated ? 'Да' : 'Нет'
        }
      })
    },
    updateViolations() {
      return this.$store.state.bdd.updateViolations
    },
    openAfterUpdate() {
      return this.$store.state.bdd.openAfterUpdate
    }
  },
  watch: {
    updateViolations() {
      this.loadViolations()
    }
  },
  async created() {
    this.loading = true
    this.setFilters()
    await this.loadEventId()
    await this.loadRelated()
    await this.loadViolations()
    this.loading = false
  },
  mounted() {
    const event_id = this.$route.query['create-with-event-id']

    if (event_id) {
      this.openModal(null, event_id)
      this.$router.replace({
        query: {}
      })
    }
  },
  methods: {
    setFilters() {
      const userFilters = this.$store.state.bdd.filters?.violations
      const initFilters = filters()

      this.filters = initFilters?.map(f => {
        if (!userFilters?.length) return f

        const userItem = userFilters?.find(uf => uf.id === f.id)

        return userItem || f
      })
    },
    async resetFilters() {
      this.$store.commit('BDD_SET_FIELD', {
        field: 'filters.violations',
        value: this.filters?.filter(f => f.active)
      })

      try {
        await this.$store.dispatch('SAVE_MAIN_USER_CONFIG')
      } catch (e) {
        throw new Error(e)
      }

      this.loadViolations()
    },
    async updateFilters(filter) {
      const target = this.filters.find(f => f.id === filter.id)
      const item = { ...target, ...filter }

      this.filters[this.filters.indexOf(target)] = item

      this.$store.commit('BDD_SET_FIELD', {
        field: 'filters.violations',
        value: this.filters?.filter(f => f.active)
      })

      try {
        await this.$store.dispatch('SAVE_MAIN_USER_CONFIG')
      } catch (e) {
        throw new Error(e)
      }

      this.loadViolations()
    },
    async loadViolations() {
      try {
        const filters = filtersEncoder(this.filters)
        const url = buildUrl(this.source_id, filters)
        this.uri = buildExportUri(this.source_id, filters)

        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })

        this.violations = Object.values(data)

        if (this.openAfterUpdate) {
          this.openModal({ id: this.openAfterUpdate })

          this.$store.commit('BDD_SET_FIELD', {
            field: 'openAfterUpdate',
            value: null
          })
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.$store.commit('BDD_SET_FIELD', {
          field: 'updateViolations',
          value: false
        })
      }
    },
    async loadEventId() {
      try {
        const models = await getDatasourcesByDatatype(this, 'model')
        const model = models.find(m => !!m.is_main_model) || models[0]
        const { events } = getModelChildrenIds(model.children)
        this.related.event.id = events
      } catch (e) {
        console.log(e)
        this.related.event.id = null
      }
    },
    async loadRelated() {
      for (const r in this.related) {
        if (this.related[r].id) {
          try {
            const config = JSON.stringify(this.related[r].config)
            const url = `objectInfo/${this.related[r].id}?config=${config}`

            const { data } = await this.$store.dispatch('GET_REQUEST', {
              url
            })

            this.related[r].data = Object.values(data)

            this.filters = this.filters.map(f => {
              let filter = f
              if (f.source === r) {
                filter = {
                  ...f,
                  prop: this.related[r].data.map(d => {
                    const fValue = !!f?.prop?.find(fi => fi.id === d.id)?.value

                    return {
                      ...d,
                      value: fValue
                    }
                  })
                }
              }
              return {
                ...filter
              }
            })
          } catch (e) {
            console.log(e)
          }
        }
      }
    },
    async addViolation() {
      this.openModal()
    },
    openModal(row, event_id) {
      const { source_id, related, violations } = this

      const newViolation = {}
      fields.forEach(f => {
        if (f.read_only) return
        newViolation[f.model] = null
      })

      const current = row ? violations.find(e => e.id === row.id) : newViolation

      const value = {
        ...current,
        source_id,
        related,
        new: !row
      }
      if (event_id) value.event_id = event_id

      this.$store.commit('BDD_SET_FIELD', {
        field: 'violation',
        value
      })
      const modalName = !row ? 'bdd-violations-create' : 'bdd-violations-modal'
      this.$store.commit('OPEN_MODAL_WINDOW', modalName)
    }
  }
}
</script>

<style lang="scss">
.violations-register {
  display: grid;
  grid-auto-flow: row;
  align-items: start;
  width: 100%;
  align-content: start;
  height: calc(100% - 1rem);
  grid-auto-rows: minmax(50px, 100%);
  grid-template-rows: min-content min-content minmax(50px, 90%);

  &__filters {
    border-bottom: 1px solid var(--dividers_low_contrast);
  }

  &-table {
    &__action {
      &-cell {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 1rem;
        justify-content: start;
      }

      &-button {
        border-radius: var(--border-radius);
        border: none;
        background-color: transparent;
        display: grid;
        grid-gap: 0.25rem;
        grid-auto-flow: column;
        cursor: pointer;

        &:hover {
          background-color: var(--accent_hover);
        }
      }
    }
  }

  &__content {
    display: grid;
    grid-template-rows: 1fr;
    overflow: hidden;
    height: 100%;
    align-items: center;
    justify-content: stretch;

    .r-title {
      justify-content: center;
      width: 100%;
      padding: 1rem;
      text-align: center;
    }
  }
}
</style>
