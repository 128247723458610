export const mapboxPropsConfig = {
  point: {
    type: 'circle',
    layout: {},
    paint: {
      'circle-radius': 5,
      'circle-color': '#3A8FD2',
      'circle-pitch-alignment': 'map'
    }
  },
  multi_point: {
    type: 'circle',
    layout: {},
    paint: {
      'circle-radius': 7,
      'circle-color': '#3A8FD2',
      'circle-pitch-alignment': 'map'
    }
  },
  stop_points: {
    type: 'circle',
    layout: {},
    paint: {
      'circle-radius': 4,
      'circle-color': '#e56566',
      'circle-pitch-alignment': 'map',
      'circle-stroke-color': '#fff',
      'circle-stroke-width': 1
    }
  },
  line_string: {
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#3A8FD2',
      'line-width': 2
    }
  },
  multi_line_string: {
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#3A8FD2',
      'line-width': 2
    }
  },
  line_routes: {
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#e56566',
      'line-width': 4
    }
  },
  lines: {
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#e56566',
      'line-width': 4
    }
  },
  connectors: {
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#3A8FD2',
      'line-width': 1,
      'line-dasharray': [0.5, 4]
    }
  },
  polygon: {
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': '#3A8FD2',
      'fill-opacity': 0.3
    }
  },
  multi_polygon: {
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': '#3A8FD2',
      'fill-opacity': 0.3
    }
  },
  center_geom: {
    type: 'circle',
    layout: {},
    paint: {
      'circle-radius': 5,
      'circle-color': '#fff',
      'circle-pitch-alignment': 'map',
      'circle-stroke-color': '#3A8FD2',
      'circle-stroke-width': 2
    }
  }
}
