<template>
  <book />
</template>

<script>
import book from '@/components/book/book'

export default {
  components: { book }
}
</script>

<style></style>
