<template>
  <div class="railway-control-card-way-control">
    <r-text color-type="subhead">
      {{ $t('way-control') }}
    </r-text>
    <el-select
      :value="info.way_no"
      class="r-select"
      :placeholder="$t('select-way')"
      filterable
      clearable
      size="mini"
      @change="changeHandler"
    >
      <el-option
        v-for="item in wayTypes"
        :key="item.id"
        :label="$t(item.title)"
        :value="item.title"
        :disabled="isWayAvailable(item.title)"
      />
    </el-select>
  </div>
</template>

<script>
import { railwaysLayout } from '../railway-control-content/configs/'

const wayTypes = [
  {
    id: 1,
    title: '7'
  },
  {
    id: 2,
    title: '6'
  },
  {
    id: 3,
    title: '5'
  },
  {
    id: 4,
    title: '3'
  },
  {
    id: 5,
    title: '1'
  },
  {
    id: 6,
    title: '4'
  }
]

export default {
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      railwaysLayout,
      wayTypes
    }
  },
  computed: {
    itemsList() {
      return this.$store.state.railwayControl.carsList
    },
    statusList() {
      return Object.values(this.$store.state.railwayControl.statusList)
    },
    sortStatusId() {
      return this.statusList.find(s => s.name === 'На сортировке')?.id || null
    },
    arrivalStatus() {
      return this.statusList.find(s => s.name === 'Прибыл на завод')?.id || null
    }
  },
  methods: {
    isWayAvailable(way_no) {
      const wayItems = this.itemsList.filter(
        e => String(e.way_no) === String(way_no)
      )
      return wayItems.length >= Number(railwaysLayout[way_no]?.capacity)
    },
    changeHandler(e) {
      const peronNo = this.getFirstAvailablePeron(e)

      this.$store.commit('RAILWAY_CONTROL_UPDATE_CAR_BY_ID', {
        id: this.info.id,
        field: 'way_no',
        value: e
      })
      this.$store.commit('RAILWAY_CONTROL_UPDATE_CAR_BY_ID', {
        id: this.info.id,
        field: 'peron_no',
        value: peronNo
      })
      this.$store.commit('RAILWAY_CONTROL_OBSERV_CHANGES_BY_ID', {
        id: this.info.id
      })

      if (this.info.status_id === this.sortStatusId) {
        this.$store.commit('RAILWAY_CONTROL_UPDATE_CAR_BY_ID', {
          id: this.info.id,
          field: 'status_id',
          value: this.arrivalStatus
        })
      }
    },
    getFirstAvailablePeron(way_no) {
      const wayItems = this.itemsList.filter(
        e => String(e.way_no) === String(way_no)
      )
      const capacity = railwaysLayout[way_no]?.capacity || 1
      const allPeronList = Array.from(Array(capacity), (_, i) => i + 1)
      const availablePerons = allPeronList.filter(e => !wayItems.find(n => n.peron_no === e))

      return availablePerons[0] || 1
    }
  }
}
</script>

<style lang="scss">
.railway-control-card-way-control {
  position: relative;
  .r-select {
    margin-top: 8px;
    width: 100%;
  }
}
</style>

<i18n>
{
  "ru": {
    "way-control": "Назначить вагон на путь",
    "select-way": "Выбрать путь"
  },
  "en": {
    "way-control": "Way control",
    "select-way": "Select way"
  }
}
</i18n>
