<template>
  <div class="railway-control__content">
    <railway-control-content-header />
    <div class="railway-control__content-wrapper">
      <div class="railway-control__content-wrapper__row">
        <railway-control-content-peron :info="railwaysLayout[7]" />
      </div>
      <div class="railway-control__content-wrapper__row">
        <railway-control-content-peron :info="railwaysLayout[6]" />
      </div>
      <div class="railway-control__content-wrapper__row">
        <railway-control-content-peron :info="railwaysLayout[5]" />
      </div>
      <div class="railway-control__content-wrapper__row">
        <railway-control-content-peron :info="railwaysLayout[3]" />
      </div>
      <div class="railway-control__content-wrapper__row">
        <railway-control-content-peron :info="railwaysLayout[1]" />
        <railway-control-content-peron :info="railwaysLayout[4]" />
        <railway-control-content-delete-block />
      </div>
    </div>
  </div>
</template>

<script>
import railwayControlContentHeader from './railway-control-content-header'
import railwayControlContentPeron from './railway-control-content-peron'
import railwayControlContentDeleteBlock from './railway-control-content-delete-block'
import { railwaysLayout } from './configs/'

export default {
  components: {
    railwayControlContentHeader,
    railwayControlContentPeron,
    railwayControlContentDeleteBlock
  },
  data() {
    return {
      railwaysLayout
    }
  }
}
</script>

<style lang="scss">
.railway-control__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  &-wrapper {
    position: relative;
    padding: 16px;
    width: 100%;
    min-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
    flex: 1;
    height: 0px;
    &__row {
      position: relative;
      width: 100%;
      max-width: 1200px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 64px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
