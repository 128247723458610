<template>
  <acts />
</template>

<script>
export default {
  components: {
    acts: () => import('@/components/bdd/acts/')
  }
}
</script>
