import { rDate } from '@/utils'

export class CargoReportVehiclesReport {
  constructor(state) {
    this.$store = state.$store
    this.state = state
  }

  getTableData(data) {
    const tableData = []
    const parsedData = {}

    data.forEach(e => {
      const columnName = e.name !== 'Итого' ? rDate.format(e.from_time, 'MMM-YY') : 'Итого'
      e.stat.forEach(s => {
        if (!parsedData[s.subproject]) {
          parsedData[s.subproject] = {}
        }
        parsedData[s.subproject][columnName] = {
          count: s.count,
          weight: s.weight
        }
      })
    })
    const rows = data.find(e => e.name === 'Итого')?.stat?.map(e => e.subproject)

    rows.forEach(r => {
      const itemCount = {}
      const itemWeight = {}

      itemCount['Подпроект'] = r
      itemWeight['Подпроект'] = r
      itemCount['Объем'] = 'авто'
      itemWeight['Объем'] = 'вес, кг'
      Object.keys(parsedData[r]).forEach(s => {
        if (s !== 'Итого') {
          itemCount[s] = parsedData[r][s].count || 0
          itemWeight[s] = parsedData[r][s].weight || 0
        } else {
          itemCount['Итого'] = parsedData[r][s].count
          itemWeight['Итого'] = parsedData[r][s].weight
        }
      })

      tableData.push(itemCount)
      tableData.push(itemWeight)
    })

    return tableData
  }
}
