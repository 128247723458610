<template>
  <el-row class="ds-manager__component from-file">
    <div class="ds-manager__component-title">
      <r-title type="title-2">
        {{ $t('ds-manager:creation:from-file:title') }}
      </r-title>
    </div>
    <el-row
      :class="[
        `ds-manager__component-content`,
        { enlarged },
        { shp: fileType === 'shp' }
      ]"
    >
      <el-row class="ds-manager__component-row">
        <div class="ds-manager__component-subtitle">
          <r-text type="caption">
            {{ $t('ds-manager:creation:from-file:enter-name') }}
          </r-text>
        </div>
        <el-input
          v-model="fileName"
          class="r-input"
          size="mini"
          :placeholder="`${$t(
            'ds-manager:creation:from-file:enter-name-placeholder'
          )}`"
        />
      </el-row>
      <el-row class="ds-manager__component-row">
        <div class="ds-manager__component-subtitle">
          <r-text type="caption">
            {{ $t('ds-manager:creation:enter-profiles') }}
          </r-text>
        </div>
        <el-select
          v-model="selectedProfiles"
          class="r-select"
          size="mini"
          multiple
          collapse-tags
          :placeholder="`${$t(
            'ds-manager:creation:select-profiles-placeholder'
          )}`"
        >
          <el-option
            v-for="profile in profiles"
            :key="profile.id"
            :value="profile.id"
            :label="profile.name"
          />
        </el-select>
      </el-row>
      <el-row class="ds-manager__component-row">
        <div class="ds-manager__component-subtitle">
          <r-text type="caption">
            {{ $t('ds-manager:creation:from-file:select-type') }}
          </r-text>
        </div>
        <el-select
          v-model="extension"
          class="r-select"
          size="mini"
          :placeholder="`${$t(
            'ds-manager:creation:from-file:select-type-placeholder'
          )}`"
          @change="changeFormat"
        >
          <el-option
            v-for="format in formats"
            :key="format.id"
            :value="format.value"
            :label="format.label"
          />
        </el-select>
      </el-row>
      <el-row
        v-if="enlarged"
        class="ds-manager__component-row"
      >
        <div class="ds-manager__component-subtitle">
          <r-text type="caption">
            {{ $t('ds-manager:creation:from-file:geom-field') }}
          </r-text>
        </div>
        <el-input
          v-model="geomField"
          class="r-input"
          :placeholder="`${$t('ds-manager:creation:from-file:geom-field')}`"
          size="mini"
        />
      </el-row>
      <el-row
        v-if="enlarged"
        class="ds-manager__component-row"
      >
        <div class="ds-manager__component-subtitle">
          <r-text type="caption">
            {{ $t('ds-manager:creation:from-file:geom-type') }}
          </r-text>
        </div>
        <el-select
          v-model="geomType"
          size="mini"
          class="r-select"
          :placeholder="`${$t('ds-manager:creation:from-file:geom-type')}`"
          :no-match-text="`${$t('ds-manager:creation:from-file:no-match')}`"
          clearable
          filterable
        >
          <el-option
            v-for="subitem in geomTypes"
            :key="subitem"
            :label="subitem"
            :value="subitem"
          />
        </el-select>
      </el-row>
      <el-row
        v-if="enlarged || fileType === 'net'"
        class="ds-manager__component-row"
      >
        <div class="ds-manager__component-subtitle">
          <r-text type="caption">
            {{ $t('ds-manager:creation:from-file:srid') }}
          </r-text>
        </div>
        <el-input
          v-model="srid"
          class="r-input"
          :placeholder="`${$t('ds-manager:creation:from-file:srid')}`"
          size="mini"
        />
      </el-row>
      <el-row
        v-if="fileType === 'net'"
        class="ds-manager__component-row"
      >
        <div class="ds-manager__component-subtitle">
          <r-text type="caption">
            {{ $t('ds-manager:creation:from-file:versioning') }}
          </r-text>
        </div>
        <el-checkbox
          v-model="versioning"
          class="r-checkbox"
          :placeholder="`${$t('ds-manager:creation:from-file:versioning')}`"
          size="mini"
        />
      </el-row>
      <el-row class="ds-manager__component-row">
        <div class="ds-manager__component-subtitle no-padding">
          <r-text type="caption">
            {{ $t('ds-manager:creation:from-file:upload') }}
          </r-text>
        </div>
      </el-row>
      <el-row class="ds-manager__component-row upload-block">
        <el-upload
          ref="dsManagerUpload"
          :class="[`r-upload single`, { hasFile }]"
          action=""
          drag
          :limit="1"
          :auto-upload="false"
          :multiple="false"
          :on-change="handleChange"
          :show-file-list="false"
          :http-request="manualUpload"
        >
          <div class="el-upload__text">
            <div class="r-upload__tip">
              {{ $t('ds-manager:creation:from-file:upload-tip1') }}
              <em>{{ $t('ds-manager:creation:from-file:upload-tip2') }}</em>
            </div>
            <div
              v-if="fileType === 'shp'"
              class="r-upload__tip shp"
            >
              {{ $t('ds-manager:creation:from-file:upload-tip-shp') }}
            </div>
            <!-- <div class="r-upload__subtip">
              {{ $t("ds-manager:creation:from-file:upload-tip3") }}
            </div> -->
          </div>
          <div
            v-if="hasFile"
            slot="tip"
            class="el-upload__tip"
          >
            <r-icon
              name="folder-layer"
              :size="16"
              type="regular"
              class="r-upload__file-icon"
            />
            <div class="r-upload__file-name">
              {{ uploadFileName }}
            </div>
            <div class="r-upload__file-info">
              {{ fileInfo }}
              <span
                v-if="!correctFormat"
                class="r-upload__file-info--error"
              >• {{ invalidFormat }}</span>
            </div>
            <div
              class="r-upload__clear-button"
              @click="clearFileList"
            >
              <r-icon
                name="close-delete"
                :size="12"
                type="additional"
              />
            </div>
          </div>
        </el-upload>
      </el-row>
      <el-row class="ds-manager__component-row text-center">
        <r-button
          :loading="fileLoading"
          type="success"
          :disabled="!isActive || !correctFormat"
          @click="submitUpload"
        >
          {{ $t('ds-manager:creation:from-file:create') }}
        </r-button>
      </el-row>
    </el-row>
  </el-row>
</template>

<script>
import filesize from 'filesize'
import { notifyFactory } from '@/utils'

const formats = [
  { id: 1, label: 'CSV - формат', value: '.csv' },
  { id: 2, label: 'Shape - файл', value: '.7z,.rar,.zip' },
  { id: 3, label: 'NET - файл', value: '.net,.7z,.rar,.zip' },
  { id: 4, label: 'JSON - файл', value: '.json' },
  { id: 5, label: 'GEOJSON - файл', value: '.geojson' },
  { id: 6, label: 'TIFF - файл', value: '.tiff' }
]

export default {
  data() {
    return {
      fileLoading: false,
      fileList: [],
      selectedProfiles: [],
      uploadFileName: '',
      uploadFileSize: null,
      uploadFileType: '',

      extension: '.7z,.rar,.zip',
      fileType: 'shp',
      fileName: '',
      geomField: '',
      geomType: '',
      geomTypes: [],
      srid: 4326,
      versioning: false,
      hasFile: false,
      correctFormat: true,
      invalidFormat: this.$t('ds-manager:creation:from-file:upload-tip4'),
      invalidSize: this.$t('ds-manager:creation:from-file:upload-tip5'),
      formats
    }
  },
  computed: {
    enlarged() {
      return this.extension === '.csv'
    },
    isActive() {
      if (
        !this.hasFile ||
        !this.fileName.trim() ||
        !this.fileType ||
        !this.correctFormat
      ) {
        return false
      }
      if (this.fileType === 'csv') {
        if (!this.geomField.trim() || !this.geomType || !this.srid) {
          return false
        }
      }
      return true
    },
    fileInfo() {
      return `${this.uploadFileSize} • ${this.uploadFileType}`
    },
    hasChanges() {
      if (this.hasFile || this.fileName.trim() || this.geomType) return true

      return false
    },
    profiles() {
      return this.$store.state.profiles.profilesTree
    },
    currentProfile() {
      return this.$store.state.profiles.currentProfile
    }
  },
  watch: {
    hasChanges(val) {
      this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', val)
    }
  },
  async created() {
    const { data } = await this.$store.dispatch('GET_REQUEST', {
      url: 'datatypes'
    })

    this.geomTypes = data.geom || []
    this.selectedProfiles = this.profiles
      .filter(e => e.id === this.currentProfile.id)
      .map(e => e.id)
  },
  beforeDestroy() {
    this.$refs.dsManagerUpload.clearFiles()
    this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', false)
  },
  methods: {
    changeFormat(value) {
      switch (value) {
        case '.csv':
          this.fileType = 'csv'
          break
        case '.7z,.rar,.zip':
          this.fileType = 'shp'
          break
        case '.net,.7z,.rar,.zip':
          this.fileType = 'net'
          break
        case '.json':
          this.fileType = 'json'
          break
        case '.geojson':
          this.fileType = 'geojson'
          break
        case '.tiff':
          this.fileType = 'tiff'
          break
      }
      this.correctFormat = this.extension.includes(this.uploadFileType)
    },
    handleChange(file) {
      if (file && !this.fileList.length) {
        const fileName = file.name.split('.')

        this.fileList.push(file)
        this.hasFile = true
        this.uploadFileType = fileName.pop()
        this.uploadFileName = fileName.join()
        this.uploadFileSize = filesize(file.size)
        this.correctFormat = this.extension.includes(this.uploadFileType)
      }
    },
    clearFileList() {
      this.$refs.dsManagerUpload.clearFiles()
      this.hasFile = false
      this.fileList.length = 0
      this.uploadFileType = ''
      this.uploadFileName = ''
      this.uploadFileSize = null
    },
    submitUpload() {
      this.$refs.dsManagerUpload.submit()
    },
    async manualUpload(e) {
      this.fileLoading = true
      const formData = new FormData()

      formData.append('file', e.file)
      formData.append('data_source_name', this.fileName)
      formData.append('type', this.fileType)
      formData.append('user_profile_id', this.selectedProfiles.join())

      if (this.fileType === 'csv') {
        formData.append('geom_field', this.geomField)
        formData.append('geom_type', this.geomType)
        formData.append('srid', this.srid)
      }
      if (this.fileType === 'net') {
        formData.append('versioning', this.versioning)
        formData.append('srid', this.srid)
      }

      try {
        const { data } = await this.$store.dispatch('SEND_FORM_DATA', {
          url: 'import_data',
          data: formData
        })
        this.uploaded(data)
        this.fileLoading = false
        this.fileName = ''
      } catch (e) {
        this.fileLoading = false
      }
    },
    uploaded(response) {
      const message = `${this.$t(
        'ds-manager:creation:from-file:uploaded-text'
      )} ${response.task_id}`
      const title = this.$t('ds-manager:creation:from-file:uploaded-title')

      this.$notify(notifyFactory('success', title, message))
      this.$store.commit('ADD_ACTIVE_TASK', {
        task_id: response.task_id,
        type: 'import',
        description: `Импорт ИД - ${this.fileName}`
      })
      this.clearFileList()
    }
  }
}
</script>

<style lang="scss">
.from-file {
  .ds-manager__component-content {
    width: 428px;
    height: 380px;
    transition: all 0.15s ease;
    &.enlarged {
      height: 620px;
    }
    &.shp {
      height: 460px;
      .ds-manager__component-row {
        &.upload-block {
          height: 130px;
          .el-upload-dragger {
            height: auto !important;
          }
        }
      }
    }
  }
  .r-select {
    width: 100%;
  }
  .ds-manager__component-row {
    &.upload-block {
      height: 66px;
      overflow: hidden;
      overflow-y: auto;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .r-upload__tip {
        &.shp {
          white-space: normal;
          word-break: normal;
        }
      }
    }
  }
  .no-padding {
    padding: 0;
  }
}
</style>

<i18n>
{
  "ru": {
    "ds-manager:creation:from-file:title": "Создать источник данных из файла",
    "ds-manager:creation:from-file:enter-name": "Укажите название",
    "ds-manager:creation:from-file:enter-name-placeholder": "Название",
    "ds-manager:creation:enter-profiles": "Выберите профили",
    "ds-manager:creation:select-profiles-placeholder": "Выберите профили в которые добавить источник данных",
    "ds-manager:creation:from-file:select-type": "Выберите тип файла",
    "ds-manager:creation:from-file:select-type-placeholder": "Формат исходных данных",
    "ds-manager:creation:from-file:upload": "Загрузка файлов",

    "ds-manager:creation:from-file:geom-field": "Поле с геометрией",
    "ds-manager:creation:from-file:geom-type": "Тип геометрии",
    "ds-manager:creation:from-file:srid": "Проекция",
    "ds-manager:creation:from-file:versioning": "Версионность",

    "ds-manager:creation:from-file:no-match": "Нет совпадений",

    "ds-manager:creation:from-file:upload-tip1": "Перетащите файл сюда или ",
    "ds-manager:creation:from-file:upload-tip2": "кликните для загрузки",
    "ds-manager:creation:from-file:upload-tip3": "Максимальный размер файла — 1024 MB",
    "ds-manager:creation:from-file:upload-tip4": "Некорректный формат",
    "ds-manager:creation:from-file:upload-tip5": "Размер файла превыщает допустимый",
    "ds-manager:creation:from-file:upload-tip-shp": "Для создания источника данных указанного типа, необходимо использовать .zip-архив, содержащий в себе набор файлов следующих форматов - \".dbf\", \".idx\", \".shp\", \".shx\"",
    "ds-manager:creation:from-file:create": "Создать источник данных",

    "ds-manager:creation:from-file:uploaded-title": "Запрос отправлен",
    "ds-manager:creation:from-file:uploaded-text": "Запрос на создание источника данных отправлен. Номер задачи - "
  },
  "en": {
    "ds-manager:creation:from-file:title": "Create data source from file",
    "ds-manager:creation:from-file:enter-name": "Enter name",
    "ds-manager:creation:from-file:enter-name-placeholder": "Name",
    "ds-manager:creation:enter-profiles": "Select profiles",
    "ds-manager:creation:select-profiles-placeholder": "Select the profiles to add the source to",
    "ds-manager:creation:from-file:select-type": "Select file type",
    "ds-manager:creation:from-file:select-type-placeholder": "Format of data",
    "ds-manager:creation:from-file:upload": "Upload file",

    "ds-manager:creation:from-file:geom-field": "Geometry field",
    "ds-manager:creation:from-file:geom-type": "Geometry type",
    "ds-manager:creation:from-file:srid": "Projection",

    "ds-manager:creation:from-file:no-match": "No match",

    "ds-manager:creation:from-file:upload-tip1": "Drop file here or ",
    "ds-manager:creation:from-file:upload-tip2": "click to upload",
    "ds-manager:creation:from-file:upload-tip3": "Max file size — 1024 MB",
    "ds-manager:creation:from-file:upload-tip4": "Invalid format",
    "ds-manager:creation:from-file:upload-tip5": "File size rxceeds allowed",
    "ds-manager:creation:from-file:upload-tip-shp": "\"To create a data source of the shape type, you must use the .zip archive containing a set of files of the following formats - \".Dbf\", \".Idx\", \".Shp\", \".Shx\"",
    "ds-manager:creation:from-file:create": "Create data source",

    "ds-manager:creation:from-file:uploaded-title": "Request sent",
    "ds-manager:creation:from-file:uploaded-text": "A request to create a data source has been sent. Task number -"
  }
}
</i18n>
