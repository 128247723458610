<template>
  <div class="railway-control__list">
    <r-search-input
      :filter-text="filterText"
      :is-loading="filterLoading"
      @change="e => filterChange(e)"
    />
    <div
      class="railway-control__list-wrapper"
      @dragstart="onDragStart(key, $event)"
    >
      <railway-control-list-item
        v-for="car in filteredCars"
        :id="String(car.id)"
        :key="car.id"
        :car="car"
        @click-handler="clickHandler(car.id)"
      />
    </div>
  </div>
</template>

<script>
import railwayControlListItem from './railway-control-list-item'
import { getStatusNameById, statusDecryption } from '../../configs'

export default {
  components: {
    railwayControlListItem
  },
  data() {
    return {
      filterLoading: false,
      key: 0,
      filterText: ''
    }
  },
  computed: {
    allCars() {
      return this.$store.state.railwayControl.carsList || []
    },
    filteredCars() {
      return this.allCars.filter(e => {
        return String(e.number).includes(String(this.filterText).trim().toLowerCase()) && !!e.status_id
      }
      ).sort((a, b) => {
        const indexOfA = this.getSortIndex(a.status_id)
        const indexOfB = this.getSortIndex(b.status_id)

        if (indexOfA > indexOfB) {
          return 1
        } else if (indexOfA < indexOfB) {
          return -1
        } else {
          if (a.way_no > b.way_no) return 1
          if (a.way_no < b.way_no) return -1
          return 0
        }
      })
    }
  },
  methods: {
    getSortIndex(id) {
      const carStatusName = getStatusNameById(this, id) || 'default'

      return statusDecryption[carStatusName]?.id
    },
    clickHandler(i) {
      this.key = i
    },
    filterChange(e) {
      this.filterText = e
    },
    onDragStart(key, e) {
      e.dataTransfer.setData('Text', this.key)
      e.dataTransfer.effectAllowed = 'move'
    }
  }
}
</script>

<style lang="scss">
.railway-control__list {
  position: relative;
  padding: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0px;
  &-wrapper {
    margin-top: 8px;
    position: relative;
    padding: 8px 4px 8px 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
    height: 0px;
  }
}
</style>
