import { mapColors } from '@/config/colors'

export const initialEditorState = {
  isMap: true,
  enabled: true,
  modelId: null,
  mode: 'create',
  id: '',
  geom_type: '',
  geometry: '',
  history: [],
  historyIndex: 0,
  activeLayers: [],
  modelLayers: [],
  name: '',
  isDrawing: false,
  loading: false,
  // creating object helpers
  withAttributes: false,
  objectFields: [],
  creatingCallback: null,
  activeObject: null,
  centerGeom: null,
  editingCallback: null
}

export const eventEditorLayersConfig = {
  nodes: {
    type: 'circle',
    layout: {},
    paint: {
      'circle-radius': 3,
      'circle-color': mapColors.baseColor,
      'circle-pitch-alignment': 'map'
    }
  },
  links: {
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': mapColors.baseColor,
      'line-width': 1
    }
  }
}
