<template>
  <railway-control />
</template>

<script>
import RailwayControl from '@/components/railway-control/railway-control'

export default {
  components: {
    RailwayControl
  }
}
</script>
