import vue from 'vue'

const state = {
  configs: {},
  baselayers: {},
  activeLayers: {
    default: []
  },
  lockedLayers: {
    default: []
  }
}

const mutations = {
  MAP_CONFIGS_SET_CONFIG(state, { field, module, value }) {
    vue.set(state[field], module, value)
  }

}

const getters = {
  isLayerIdActive: (state) => (module, id) => {
    return state.activeLayers[module]?.includes(id)
  },
  activeLayersList: (state) => (module) => {
    return state.activeLayers[module] || []
  },
  lockedLayersList: (state) => (module) => {
    return state.lockedLayers[module] || []
  },
  layersToRenderList: (state) => (module) => {
    const active = state.activeLayers[module] || []
    const locked = state.lockedLayers[module] || []

    return [...new Set([...active, ...locked])]
  }
}

export default {
  state,
  getters,
  mutations
}
