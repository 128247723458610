export const rackPositions = () => {
  return [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 }
  ]
}

export const signTemplate = () => ({
  id: null,
  name: null,
  geom: null,
  end_time: null,
  sign_icon_id: null,
  rack_position: null
})
