<template>
  <r-toggle-card
    title="Информация"
    :is-open="config.enabled"
    @toggle="toggleHandler"
  >
    <div class="m-popup">
      <ul
        v-if="config.fields.length"
        class="m-popup__list"
      >
        <li
          v-for="field in config.fields"
          :key="field"
          class="m-popup__item"
        >
          <div class="m-popup__info">
            <div :class="`m-popup__icon ${getType(field)}`" />
            <r-text>
              {{ getTitle(field) }}
            </r-text>
          </div>
          <r-delete-button
            simple
            mini
            @delete="removeField(field)"
          />
        </li>
      </ul>
      <el-select
        v-model="config.fields"
        class="r-select dark"
        size="mini"
        multiple
        collapse-tags
        @change="updateLayer"
      >
        <el-option
          v-for="field in fields"
          :key="field.title"
          :value="field.title"
          :label="field.alias"
        >
          <span>{{ field.alias }}</span>
        </el-option>
      </el-select>
    </div>
  </r-toggle-card>
</template>

<script>
import { calcFields } from '../../map/config'

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    model() {
      return this.$store.state.modelling.model
    },
    config() {
      return this.$store.state.modelling.stylesConfigs[this.id].popup
    },
    fields() {
      const initialFields =
        this.$store.state.modelling.objectFields[this.id] || []

      if (this.model.ids.links === this.id) {
        return initialFields.filter(
          ({ title, type }) => calcFields.includes(title) && type === 'float'
        )
      }

      return initialFields
    }
  },
  methods: {
    getType(field) {
      const config = this.fields.find(f => f.title === field)

      return config.type || 'number'
    },
    getTitle(field) {
      const fieldData = this.fields.find(({ title }) => title === field)

      return fieldData.alias || field
    },
    toggleHandler(value) {
      this.config.enabled = value
    },
    removeField(field) {
      this.$set(
        this.config,
        'fields',
        this.config.fields.filter(f => f !== field)
      )
    },
    updateLayer() {
      if (this.model.ids.links === this.id) return

      const fields = ['id', 'geom', ...this.config.fields]

      this.$store.commit('SET_REQUESTED_FIELDS', { id: this.id, fields })

      this.$cControllers.layers.handlers[this.id]()
    }
  }
}
</script>

<style lang="scss">
.m-popup {
  .r-select {
    width: 100%;
  }

  &__list {
    margin-bottom: 16px;
  }

  &__item {
    border: 1px solid transparent;
    border-radius: var(--border-radius);
    padding: 8px;
    align-items: center;

    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: 1fr auto;

    color: var(--field_primary_text) !important;
    border-color: var(--field_border) !important;
    background-color: var(--field_bg) !important;

    &:not(:first-child) {
      margin-top: 8px;
    }
  }

  &__info {
    display: flex;
    align-items: center;
  }

  &__icon {
    height: 16px;
    margin-right: 10px;
    width: 16px;
    background-size: 75%;
    background-position: 50%;
    opacity: 0.8;
    background-repeat: no-repeat;
    border-radius: var(--border-radius);

    &.string {
      background-image: url('../../../../assets/images/attributes/types/string.svg');
      background-color: var(--string);
    }
    &.float {
      background-image: url('../../../../assets/images/attributes/types/number.svg');
      background-color: var(--number);
    }
    &.number {
      background-image: url('../../../../assets/images/attributes/types/number.svg');
      background-color: var(--number);
    }
    &.numeric {
      background-image: url('../../../../assets/images/attributes/types/number.svg');
      background-color: var(--numeric);
    }
    &.integer {
      background-image: url('../../../../assets/images/attributes/types/number.svg');
      background-color: var(--numeric);
    }
    &.decimal {
      background-image: url('../../../../assets/images/attributes/types/number.svg');
      background-color: var(--numeric);
    }
    &.boolean {
      background-image: url('../../../../assets/images/attributes/types/boolean.svg');
      background-color: var(--boolean);
    }
    &.date {
      background-image: url('../../../../assets/images/attributes/types/datetime.svg');
      background-color: var(--date);
    }
    &.time {
      background-image: url('../../../../assets/images/attributes/types/interval.svg');
      background-color: var(--time);
    }
  }
}
</style>
