import { mapColors } from '@/config/colors'

const spPointConfig = {
  type: 'circle',
  paint: {
    'circle-radius': [
      'match',
      ['get', 'projection'],
      'true',
      6,
      2
    ],
    'circle-color': [
      'match',
      ['get', 'projection'],
      'true',
      mapColors.white,
      mapColors.activeColor
    ],
    'circle-pitch-alignment': 'map',
    'circle-stroke-width': 2,
    'circle-stroke-color': mapColors.activeColor
  }
}
const spConnectionConfig = {
  type: 'line',
  layout: {
    'line-join': 'round',
    'line-cap': 'round'
  },
  paint: {
    'line-color': mapColors.activeColor,
    'line-width': 1,
    'line-dasharray': [2, 4]
  }
}
export const getStopPointsData = (nearest, current, connection) => {
  return {
    points: {
      type: 'FeatureCollection',
      features: [nearest, current]
    },
    connection: {
      type: 'FeatureCollection',
      features: [connection]
    }
  }
}
export const createStopPointsHelpers = (map, { points, connection }) => {
  if (!map.getSource('sp_point_helpers')) {
    map.addSource('sp_point_helpers', {
      type: 'geojson',
      data: points
    })
  } else map.getSource('sp_point_helpers').setData(points)

  if (!map.getSource('sp_connection_helpers')) {
    map.addSource('sp_connection_helpers', {
      type: 'geojson',
      data: connection
    })
  } else map.getSource('sp_connection_helpers').setData(connection)

  if (!map.getLayer('sp_connection_helpers')) {
    map.addLayer({
      id: 'sp_connection_helpers',
      source: 'sp_connection_helpers',
      ...spConnectionConfig
    })
  }

  if (!map.getLayer('sp_point_helpers')) {
    map.addLayer({
      id: 'sp_point_helpers',
      source: 'sp_point_helpers',
      ...spPointConfig
    })
  }
}

export const createHoveredSPHelper = (map, coordinates, type = 'red') => {
  const data = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: { type: 'Point', coordinates }
      }
    ]
  }

  if (!map.getSource('sp_hovered_helpers')) {
    map.addSource('sp_hovered_helpers', {
      type: 'geojson',
      data
    })
    map.addLayer({
      id: 'sp_hovered_helpers',
      type: 'circle',
      source: 'sp_hovered_helpers',
      paint: {
        'circle-radius': 6,
        'circle-color': mapColors.white,
        'circle-pitch-alignment': 'map',
        'circle-stroke-width': 2,
        'circle-stroke-color': mapColors.activeColor
      }
    })
  } else {
    map.getSource('sp_hovered_helpers').setData(data)
  }
}

export const removeHoveredSPHelpers = map => {
  if (map.getLayer('sp_hovered_helpers')) map.removeLayer('sp_hovered_helpers')
  if (map.getSource('sp_hovered_helpers')) { map.removeSource('sp_hovered_helpers') }
}

export const removeStopPointsHelpers = map => {
  removeHoveredSPHelpers(map)
  if (map.getLayer('sp_connection_helpers')) { map.removeLayer('sp_connection_helpers') }
  if (map.getLayer('sp_point_helpers')) map.removeLayer('sp_point_helpers')
  if (map.getSource('sp_point_helpers')) map.removeSource('sp_point_helpers')
  if (map.getSource('sp_connection_helpers')) { map.removeSource('sp_connection_helpers') }
}
