<template>
  <div
    :class="[`sc-basic-widget__footer`, { active: options.hasModal }]"
    @click="openModalWindow"
  >
    <div class="sc-basic-widget__footer-block">
      <r-text>
        {{ $t("sc-basic-widget:total") }}
      </r-text>
      <r-icon
        v-if="options.hasModal"
        name="link-to"
        :size="8"
      />
    </div>
    <div class="sc-basic-widget__footer-block">
      <r-title>{{ widgetData.total }}</r-title>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    openModalWindow: {
      type: Function,
      default: () => null
    },
    widgetData: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true,
      default: () => ({
        layer_id: '',
        type: 'default',
        size: 'default',
        hasChart: false,
        hasModal: false
      })
    }
  }
}
</script>

<style lang="scss">
.sc-basic-widget__footer {
  width: calc(100% - 20px);
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .r-text,
  .r-text.caption-2 {
    transition: color 0.15s ease;
  }
  &-block {
    display: flex;
    .r-icon {
      width: auto;
      .r-icon__svg {
        fill: var(--text_secondary) !important;
      }
    }
  }
  &.active {
    cursor: pointer;
    &:hover {
      .r-text,
      .r-text.caption-2 {
        color: var(--accent_primary) !important;
      }
      .r-icon__svg {
        fill: var(--accent_primary) !important;
      }
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "sc-basic-widget:total": "Всего"
  },
  "en": {
    "sc-basic-widget:total": "Total"
  }
}
</i18n>
