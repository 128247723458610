export const buildUrl = (source_id, filters) => {
  const include = {
    violation_representatives_employees: {},
    violation_appointed_employees: {},
    detection_employee: { only: ['name'] },
    responsible: {},
    vehicle: {
      only: ['reg_number'],
      include: {
        model: {
          only: ['name']
        },
        vehicle_type: {
          only: ['name']
        }
      }
    }
  }
  const order = [{ created_at: 'desc' }]

  const config = JSON.stringify({
    ...filters,
    order,
    include
  })
  return `objectInfo/${source_id}?config=${config}`
}

export const buildExportUri = (source_id, filters) => {
  const except = [
    'id',
    'created_by',
    'disabled',
    'type_id',
    'vehicle_id',
    'updated_by',
    'contractor_id',
    'responsible_id',
    'giver_id',
    'compiler_id',
    'control_id',
    'recipient_id',
    'event_id',
    'subcontractor_id',
    'contract_id'
  ]

  const include = {
    status: {
      only: ['name']
    },
    type: {
      only: ['name']
    },
    contractor: {
      only: ['name']
    },
    giver: {
      only: ['name']
    },
    responsible: {
      only: ['name']
    },
    compiler: {
      only: ['name']
    },
    control: {
      only: ['name']
    },
    recipient: {
      only: ['name']
    },
    vehicle: {
      only: ['reg_number'],
      include: {
        model: {
          only: ['name']
        },
        vehicle_type: {
          only: ['name']
        }
      }
    }
  }

  const order = [{ created_at: 'desc' }]

  const config = JSON.stringify({
    ...filters,
    order,
    include,
    except
  })

  return `objectInfo/${source_id}?config=${config}`
}
