<template>
  <r-button
    icon="edit"
    @click="edit"
  />
</template>

<script>
export default {
  props: {
    source_id: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  watch: {
    '$store.state.attributesEditor.updated'(val) {
      if (val) {
        this.$emit('updated')
        this.$store.commit('ATTRIBUTES_EDITOR_SET', ['updated', false])
      }
    }
  },
  methods: {
    edit() {
      const { source_id, id } = this
      this.$store.commit('OPEN_ATTRIBUTES_EDITOR', { id, source_id })
    }
  }
}
</script>
