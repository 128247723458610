<template>
  <div
    v-show="show"
    class="r-help"
  >
    <r-title>{{ title }}</r-title>
    <r-text>{{ text }}</r-text>
    <r-button @click="show = false">
      Понятно
    </r-button>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      show: true
    }
  }
}
</script>

<style lang="scss">
.r-help {
  position: absolute;
  padding: 16px;
  left: calc(100% + 16px);
  top: 16px;
  z-index: 1000;
  width: 268px;
  border-radius: var(--border-radius);
  background-color: var(--bg_panel_secondary) !important;

  .r-text,
  .r-button {
    margin-top: 16px;
  }
}
</style>
