<template>
  <div class="sc-object-card__list-item__wrapper">
    <r-text type="caption">
      {{ $t(field.type) }}
    </r-text>
    <r-text v-if="field.type === 'phase' || field.type === 'closed_line_count'">
      {{ field.value !== 'null' ? field.value : '-' }}
    </r-text>
    <r-text
      v-else-if="field.type === 'start_time' || field.type === 'end_time'"
    >
      {{ getDateTime(field.value) }}
    </r-text>
    <r-text v-else>
      {{ $t(`${field.value}`) }}
    </r-text>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true,
      default: () => null
    }
  },
  methods: {
    getDateTime(val) {
      if (!val || val === 'null') return '-'

      return this.$rDate.format(val)
    }
  }
}
</script>

<style lang="scss">
.sc-object-card__list-item__wrapper {
  position: relative;
}
</style>

<i18n>
{
  "ru": {
    "true": "Работает",
    "false": "Не работает",
    "location": "Месторасположение",
    "type": "Тип",
    "code": "Код",
    "phase": "Фаза",
    "status": "Статус",
    "mode": "Мод",
    "report": "Отчет",
    "voltage": "Вольтаж",
    "reg_number": "Рег.номер",
    "organization": "Организация",
    "event_class": "Тип события",
    "ДТП": "ДТП",
    "Мероприятия": "Мероприятие",
    "Ремонтные работы": "Дорожные работы",
    "contractor": "Контрактор",
    "contact": "Контакт",
    "contract": "Контракт",
    "period": "Период",
    "obj_id": "ИД объекта",
    "null": "Не указано"
  },
  "en": {
    "true": "Work",
    "false": "Error",
    "location": "Location",
    "type": "Type",
    "code": "Code",
    "phase": "Phase",
    "status": "Status",
    "mode": "Mode",
    "report": "Report",
    "voltage": "Voltage",
    "reg_number": "Reg.number",
    "organization": "Organization",
    "event_class": "Event type",
    "ДТП": "Accident",
    "Мероприятия": "Event",
    "Ремонтные работы": "Road work",
    "contractor": "Contractor",
    "contact": "Contact",
    "contract": "Contract",
    "period": "Period",
    "obj_id": "Object ID",
    "null": "No data"
  }
}
</i18n>
