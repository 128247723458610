<template>
  <div class="ee-legend">
    <div class="ee-legend__toggle">
      <div
        :class="[isLegendActive ? 'active' : '', 'ee-legend__button legend']"
        @click="toggleLegend"
      >
        <r-icon
          name="help"
          :size="20"
        />
      </div>
    </div>

    <div
      v-if="isLegendActive"
      class="ee-legend__content"
    >
      <div class="ee-legend-header">
        Легенда
      </div>
      <ul class="ee-legend__list">
        <li class="ee-legend__item">
          <div class="ee-legend__item-key">
            <div class="ee-legend__item-symbol nodes" />
          </div>
          <div class="ee-legend__item-value">
            Узлы
          </div>
        </li>
        <li class="ee-legend__item">
          <div class="ee-legend__item-key">
            <div class="ee-legend__item-symbol links" />
          </div>
          <div class="ee-legend__item-value">
            Отрезки
          </div>
        </li>
        <li class="ee-legend__item">
          <div class="ee-legend__item-key">
            <div class="ee-legend__item-symbol events" />
          </div>
          <div class="ee-legend__item-value">
            События
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isLegendActive: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleLegend() {
      this.$emit('toggle-legend')
    }
  }
}
</script>

<style lang="scss">
.ee-legend {
  position: absolute;
  right: 16px;
  top: 176px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  &__toggle {
    color: var(--text_primary) !important;
    background-color: var(--modal_bg) !important;
    border-radius: var(--border-radius);
  }

  &__button {
    border-radius: var(--border-radius);
    width: 36px;
    height: 36px;
    cursor: pointer;

    &.active {
      background-color: var(--accent_active) !important;
    }
  }

  &__content {
    border-radius: var(--border-radius);
    border: 1px solid transparent;
    width: 240px;
    z-index: 1000;
    font-size: 12px;
    color: var(--white_white);
    user-select: none;
    margin-top: 8px;

    background-color: var(--modal_bg) !important;
    border-color: var(--dividers_low_contrast) !important;
  }

  &-header {
    padding: 8px;
    font-weight: bold;
    border-radius: var(--border-radius);
    text-transform: uppercase;
    color: var(--text_primary) !important;
    background-color: var(--modal_bg) !important;
  }

  &__list {
    padding: 8px;
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-top: 5px;
    }

    &-key {
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-value {
      margin-left: 10px;
      color: var(--text_secondary) !important;
    }

    &-symbol {
      &.nodes {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #3a8fd2;
      }

      &.links {
        width: 100%;
        height: 2px;
        background: #3a8fd2;
      }

      &.events {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #d63838;
        border: 2px solid #d63838;
      }
    }
  }
}
</style>
