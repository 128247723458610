<template>
  <div class="ds-manager-file-import">
    <el-dropdown
      class="r-dropdown"
      :hide-on-click="false"
      placement="bottom-end"
      trigger="click"
      @command="clickHandler"
    >
      <r-button dropdown>
        Загрузить из файла
      </r-button>
      <el-dropdown-menu
        slot="dropdown"
        class="r-dropdown-menu"
      >
        <el-dropdown-item
          v-for="type in importTypes"
          :key="type.id"
          :command="type.value"
        >
          <div class="r-dropdown-menu__item-wrapper">
            {{ type.title }}
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
const importTypes = [
  {
    id: 'ds-manager-reg-list',
    value: 'ds-manager-reg-list',
    title: 'Лист регистрации БСМТС (.xlsx)'
  },
  {
    id: 'ds-manager-etran-aghk',
    value: 'ds-manager-etran-aghk',
    title: 'Выгрузка из ЭТРАН (.xls)'
  },
  {
    id: 'ds-manager-retranslation',
    value: 'ds-manager-retranslation',
    title: 'Лист ретрансляции ТС с ТМЦ (.xlsx)'
  },
  {
    id: 'ds-manager-rzd-aghk',
    value: 'ds-manager-rzd-aghk',
    title: 'Выгрузка РЖД (.xlsx)'
  },
  {
    id: 'ds-manager-application-import',
    value: 'ds-manager-application-import',
    title: 'Заявка на ТО (.xlsx)'
  },
  {
    id: 'ds-manager-permit',
    value: 'ds-manager-permit',
    title: 'Реестр пропусков'
  }
]

export default {
  data() {
    return {
      importTypes
    }
  },
  methods: {
    clickHandler(e) {
      this.$emit('click-handler', e)
    }
  }
}
</script>

<style lang="scss">
.ds-manager-file-import {
  position: absolute;
  top: 36px;
  right: 0;
}
</style>
