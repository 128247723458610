<template>
  <div class="date-filter">
    <r-tabs v-model="active">
      <r-tab
        v-for="tab in tabs"
        :id="tab.id"
        :key="tab.id"
        :name="tab.title"
      />
    </r-tabs>

    <component
      :is="active"
      v-bind="$props"
      @load="prepareData"
    />
  </div>
</template>

<script>
export default {
  components: {
    dayFilter: () => import('./day-filter'),
    intervalFilter: () => import('./interval-filter')
  },
  props: {
    filterValue: {
      type: Object,
      required: true
    },
    pickerType: {
      type: String,
      default: 'date'
    },
    format: {
      type: String,
      default: 'dd.MM.yyyy'
    },
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tabs: [
        {
          id: 'day-filter',
          title: 'По дате'
        },
        {
          id: 'interval-filter',
          title: 'За период'
        }
      ],
      active: 'day-filter'
    }
  },
  methods: {
    resetFilter(resetAll) {
      const data = {
        active: false,
        id: this.id,
        type: this.pickerType,
        prop: {
          interval: {
            from: null,
            to: null
          }
        }
      }
      this.prepareData(data, resetAll)
    },
    prepareData(data, resetAll) {
      if (!resetAll) {
        this.$emit('load', data)
      } else {
        this.$emit('reset-filter', data)
      }
    }
  }
}
</script>

<style lang="scss">
.date-filter {
  display: grid;
  grid-gap: 1rem;
  align-content: start;
}
</style>
