<template>
  <div class="tasks-modal-list-item-title">
    <el-tooltip
      :open-delay="500"
      effect="dark"
      :content="getTaskHint(task.worker_class)"
      placement="top"
    >
      <div :class="getTaskImage(task.worker_class)" />
    </el-tooltip>
    <div class="tasks-modal-list-item-block">
      <div class="tasks-modal-list-item-block__title">
        <r-text
          type="caption"
          style="margin:0 8px 0 0"
        >
          {{ $t('task:type') }}
        </r-text>
        <r-text>
          {{ $t(task.worker_class) }}
        </r-text>
      </div>
      <div class="tasks-modal-list-item-block__title">
        <r-text
          type="caption"
          style="margin:0 8px 0 0"
        >
          {{ $t('task:start_time') }}
        </r-text>
        <r-text>{{ getDate(task.start_time) }}</r-text>
      </div>
    </div>
    <tasks-item-status :task="task" />
  </div>
</template>

<script>
import { getTaskImage, getTaskHint } from '../../configs'

import tasksItemStatus from '../tasks-list/tasks-item-status'

export default {
  components: { tasksItemStatus },
  props: {
    task: {
      type: Object,
      default: () => null,
      required: true
    }
  },
  methods: {
    getDate(date) {
      return this.$rDate.format(date, 'HH:mm • DD.MM.YYYY')
    },
    getTaskImage,
    getTaskHint
  }
}
</script>

<style lang="scss" scoped>
.tasks-modal-list-item {
  &-title {
    display: flex;
    align-items: center;
  }
  &-block {
    width: 320px;
    overflow: hidden;
    white-space: nowrap;
    &__title {
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 0;
      line-height: 1.6;
      .r-text {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "task:start_time": "Начало выполнения:",
    "task:type": "Тип задачи: ",
    "task:type:import_data": "Импорт данных",

    "GeojsonImporter": "Импорт GEOJSON",
    "DataBaseImporter": "Подключение PostgreSQL таблицы",
    "SkimMatrix": "SkimMatrix",
    "TileLayerImporter": "Импорт TileLayer",
    "DemandMatrix": "DemandMatrix",
    "StatisticsDataWriter": "StatisticsDataWriter",
    "CalculationRuner": "CalculationRuner",
    "ChsmeoCamsDataWriter": "ChsmeoCamsDataWriter",
    "LTMProcess": "Расчет LTM",
    "DataImport": "Импорт данных",
    "TestWorker": "TestWorker",
    "DueProcess": "Расчет DUE",
    "OsrmProcess": "Подготовка данных для OSRM",
    "VisumProcess": "Тестовый расчет Visum",
    "STA": "STA",
    "GdbImporter": "Импорт GDB",
    "NetImporter": "Импорт NET",
    "DueImporter": "Импорт DUE",
    "CustomImporter": "Создание пользовательского слоя",
    "CsvImporter": "Импорт CSV",
    "DmdImporter": "Импорт DMD",
    "UserImporter": "Импорт пользовательских данных",
    "JsonImporter": "Импорт JSON",
    "GtfsImporter": "Импорт GTFS",
    "ShpImporter": "Импорт SHP",
    "OsmExport": "Экспорт в OSM",
    "LtmExport": "Экспорт данных для LTM",
    "DueExport": "Экспорт данных для DUE",
    "DorfondImporter": "Импорт данных Dorfond",
    "TracksImporter": "Импорт данных трэков",
    "DnlProcess": "Расчет DNL"
  },
  "en": {
    "task:start_time": "Start time:",
    "task:type": "Task type: ",
    "task:type:import_data": "Import data",

    "GeojsonImporter": "GEOJSON import",
    "DataBaseImporter": "PostgreSQL table connecting",
    "SkimMatrix": "SkimMatrix",
    "TileLayerImporter": "TileLayer import",
    "DemandMatrix": "DemandMatrix",
    "StatisticsDataWriter": "StatisticsDataWriter",
    "CalculationRuner": "CalculationRuner",
    "ChsmeoCamsDataWriter": "ChsmeoCamsDataWriter",
    "LTMProcess": "LTM calculation",
    "DataImport": "Data import",
    "TestWorker": "TestWorker",
    "DueProcess": "DUE calculation",
    "OsrmProcess": "OSRM data preparation",
    "VisumProcess": "Visum test calculation",
    "STA": "STA",
    "GdbImporter": "GDB import",
    "NetImporter": "NET import",
    "DueImporter": "DUE import",
    "CustomImporter": "User layer creating",
    "CsvImporter": "CSV import",
    "DmdImporter": "DMD import",
    "UserImporter": "User data import",
    "JsonImporter": "JSON import",
    "GtfsImporter": "GTFS import",
    "ShpImporter": "SHP import",
    "OsmExport": "OSM export",
    "LtmExport": "LTM export",
    "DueExport": "DUE export",
    "DorfondImporter": "Dorfond import",
    "TracksImporter": "Tracks import",
    "DnlProcess": "DNL calculation"
  }
}
</i18n>
