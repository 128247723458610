import Vue from 'vue'
import VueI18n from 'vue-i18n'

import Element, { Notification } from 'element-ui'

import lang from './libs/element_local'
import locale from 'element-ui/lib/locale'

import App from './App.vue'
import router from './router'
import store from './store'
import messages from './config/local'
import clientStore from './store-client'
import socket from './socket'
import VueMq from 'vue-mq'
import SvgSprite from 'vue-svg-sprite'
import VueVideoPlayer from 'vue-video-player'

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import '@/components/globals'
import 'element-ui/lib/theme-chalk/index.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import './styles/main.scss'
import './styles/ritm-variables.scss'

import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
import 'videojs-flash'
import 'videojs-contrib-hls/dist/videojs-contrib-hls'
import loader from './loader'
import { rDate } from '@/utils'
Vue.use(VueVideoPlayer)

Vue.prototype.$rDate = rDate
Vue.prototype.$notify = Notification

Vue.config.productionTip = false

Vue.use(SvgSprite, {
  url: '/sprite/symbol/main.svg',
  class: ''
})

locale.use(lang)

Vue.use(VueI18n)
Vue.use(Element, { size: 'small' })
Vue.use(clientStore)
Vue.use(socket)
Vue.use(VueMq, {
  breakpoints: {
    r_medium: 1800,
    r_large: Infinity
  }
})

const LOCALE = store.getters.getLocale
const i18n = new VueI18n({
  fallbackLocale: LOCALE,
  locale: LOCALE,
  silentTranslationWarn: true,
  messages
})

Vue.directive('loading', loader)

export default new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
