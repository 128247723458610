<template>
  <bdd-applications />
</template>

<script>
import bddApplications from '@/components/bdd/applications/bdd-applications'

export default {
  components: { bddApplications }
}
</script>
