import $store from '@/store'
import { layersConfig } from '../config/'

const SOURCE_ID = 'track-player-car'
const POINT_ID = 'track-player-car-point'

export class TrackPlayerController {
  constructor(mapgl) {
    this.mapgl = mapgl
    this.colors = $store.state.monitoring.trackColors
  }

  updateLayer(data) {
    if (data) {
      this.addLayer(data)
    } else {
      this.removeLayer()
    }
  }

  addLayer(data) {
    const source = this.mapgl.getSource(SOURCE_ID)
    const features = {
      type: 'FeatureCollection',
      features: data
    }

    if (source) {
      source.setData(features)
    } else {
      this.mapgl.addSource(SOURCE_ID, {
        type: 'geojson',
        data: features
      })

      const pointStyles = layersConfig[POINT_ID]

      pointStyles.paint['circle-stroke-color'] = [
        'case',
        ['==', ['get', 'type'], 'main'], this.colors.main,
        ['==', ['get', 'type'], 'second'], this.colors.second,
        '#ccc'
      ]

      this.mapgl.addLayer(
        {
          id: POINT_ID,
          source: SOURCE_ID,
          ...pointStyles
        }
      )

      const styles = layersConfig[SOURCE_ID]

      styles.paint = {
        'icon-color': [
          'case',
          ['==', ['get', 'type'], 'main'], this.colors.main,
          ['==', ['get', 'type'], 'second'], this.colors.second,
          '#ccc'
        ]
      }

      this.mapgl.addLayer(
        {
          id: SOURCE_ID,
          source: SOURCE_ID,
          ...styles
        }
      )
    }
  }

  removeLayer() {
    if (this.mapgl.getLayer(SOURCE_ID)) {
      this.mapgl.removeLayer(SOURCE_ID)
    }
    if (this.mapgl.getLayer(POINT_ID)) {
      this.mapgl.removeLayer(POINT_ID)
    }
    if (this.mapgl.getSource(SOURCE_ID)) {
      this.mapgl.removeSource(SOURCE_ID)
    }
  }
}
