<template>
  <div class="reports-modal-preview">
    <img
      v-for="i in 4"
      :key="i"
      class="reports-modal-preview__image"
      src="@/assets/images/reports/report-preview.png"
      :alt="`preview ${i}`"
    >
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.reports-modal-preview {
  position: relative;
  display: flex;
  width: 100%;
  &__image {
    border-radius: var(--border-radius);
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
