<template>
  <div class="profiles__component profile-list">
    <div class="profiles__component-content">
      <ritm-tree
        show-tools
        no-sort
        no-counter
        no-animations
        :tree-data="profilesList"
        :icon-by-item="iconByItem"
        :node-click="nodeClick"
        :tool-click="toolClick"
        :tool-kit="toolKitByItem"
      />
    </div>
  </div>
</template>

<script>
import { notifyFactory } from '@/utils'

export default {
  components: {
    ritmTree: () => import('@/components/ritm-tree')
  },
  props: {
    nodeClickHandler: {
      type: Function,
      default: () => null
    }
  },
  data() {
    return {
      userId: this.$store.state.userId,
      list: this.$store.state.profiles.profilesTree
    }
  },
  computed: {
    profilesList() {
      return this.$store.state.profiles.profilesTree || []
    }
  },
  methods: {
    toolKitByItem() {
      return [
        {
          icon: 'copy',
          type: 'copy',
          name: 'Скопировать'
        },
        {
          icon: 'settings',
          type: 'settings',
          name: 'Настройки'
        },
        {
          icon: 'trash',
          type: 'delete',
          name: 'Удалить'
        }
      ]
    },
    iconByItem() {
      return 'user'
    },
    toolClick(type, { id }) {
      switch (type) {
        case 'settings':
          this.$store.commit('SET_CONSTRUCTOR_PROFILE_ID', id)
          this.nodeClickHandler('profiles-settings')
          break
        case 'copy':
          this.beforeCopyProfile(id)
          break
        case 'delete':
          this.beforeDeleteProfile(id)
          break
      }
    },
    nodeClick({ id }) {
      this.$store.commit('SET_CONSTRUCTOR_PROFILE_ID', id)
      this.nodeClickHandler('profiles-settings')
    },
    beforeCopyProfile(id) {
      const title = this.$t('profiles-list:copy:message-title')
      const message = this.$t('profiles-list:copy:message-text')
      const confirmButtonText = this.$t('profiles-list:copy:button-confirm')
      const cancelButtonText = this.$t('profiles-list:button-cancel')

      this.$confirm(message, title, {
        confirmButtonText,
        cancelButtonText
      })
        .then(() => {
          this.copyProfile(id)
        })
        .catch(() => {})
    },
    async copyProfile(id) {
      this.isCreating = true
      const url = `user/copy_profile?profile_id=${id}`

      try {
        await this.$store.dispatch('POST_REQUEST', { url })
        const user = await this.$store.dispatch('GET_REQUEST', { url: 'user' })
        this.$store.commit('SET_PROFILES_TREE', user.data.user_profiles)
        const title = this.$t('profiles-list:message-title')
        const message = this.$t('profiles-list:copy:message-success')
        this.$notify(notifyFactory('info', title, message))
      } catch (e) {
        console.error(e)
      }
      this.isCreating = false
    },
    beforeDeleteProfile(id) {
      const title = this.$t('profiles-list:delete:message-title')
      const message = this.$t('profiles-list:delete:message-text')
      const confirmButtonText = this.$t('profiles-list:delete:button-confirm')
      const cancelButtonText = this.$t('profiles-list:button-cancel')

      this.$confirm(message, title, {
        confirmButtonClass: 'el-button--danger',
        confirmButtonText,
        cancelButtonText
      })
        .then(() => {
          this.deleteProfile(id)
        })
        .catch(() => {})
    },
    async deleteProfile(id) {
      if (!id) return
      const currentProfileId = this.$store.state.profiles.currentProfile.id

      if (this.profilesList.length <= 1) {
        await this.createNewProfile('default')
      }
      const siblingProfile = this.profilesList.find(e => {
        if (e.id !== id) return e
      })

      const options = {
        url: 'user/profile?',
        data: { id, disabled: true }
      }

      try {
        await this.$store.dispatch('POST_REQUEST', options)
        const user = await this.$store.dispatch('GET_REQUEST', { url: 'user' })
        if (id === currentProfileId) {
          await this.changeProfile(siblingProfile.id)
          document.location.reload(true)
        }
        this.$store.commit('SET_PROFILES_TREE', user.data.user_profiles)
        const title = this.$t('profiles-list:message-title')
        const message = this.$t('profiles-list:delete:message-success')
        this.$notify(notifyFactory('info', title, message))
      } catch (e) {
        console.log(e)
      }
    },
    async createNewProfile(profileName) {
      const options = {
        url: 'user/profile?',
        data: { name: profileName }
      }
      try {
        await this.$store.dispatch('POST_REQUEST', options)
        const user = await this.$store.dispatch('GET_REQUEST', { url: 'user' })
        this.$store.commit('SET_PROFILES_TREE', user.data.user_profiles)

        const title = this.$t('profiles-list:message-title')
        const message = this.$t('profiles-list:create:message-success')
        this.$notify(notifyFactory('info', title, message))
      } catch (e) {
        console.error(e)
      }
    },
    async changeProfile(id) {
      const dataUserObj = {
        id: this.userId,
        current_profile_id: id
      }
      try {
        this.$store.commit('CHANGE_PROFILE', true)
        await this.$store.dispatch('PUT_REQUEST', {
          url: 'user',
          data: dataUserObj
        })
      } catch (e) {
        this.$store.commit('CHANGE_PROFILE', false)
        console.log(e)
      }
    }
  }
}
</script>

<i18n>
{
  "ru": {
    "profiles-list:title": "Список доступных профилей",

    "profiles-list:copy:message-text": "Скопированный профиль появится в списке профилей. Продолжить?",
    "profiles-list:copy:message-title": "Уведомление",
    "profiles-list:copy:message-success": "Профиль успешно скопирован!",
    "profiles-list:copy:button-confirm": "Сделать копию",
    "profiles-list:button-cancel": "Отмена",

    "profiles-list:delete:message-text": "Профиль будет удален без возможности восстановления. Продолжить?",
    "profiles-list:delete:message-title": "Предупреждение",
    "profiles-list:delete:message-success": "Профиль успешно удален!",
    "profiles-list:delete:button-confirm": "Удалить",

    "profiles-list:create:message-success": "Новый профиль успешно создан!",
    "profiles-list:message-title": "Уведомление!"
  },
  "en": {
    "profiles-list:title": "Profiles list",

    "profiles-list:copy:message-text": "The copied profile appears in the profile list. Continue?",
    "profiles-list:copy:message-title": "Notification",
    "profiles-list:copy:message-success": "Profile successfully copied!",
    "profiles-list:copy:button-confirm": "Create copy",
    "profiles-list:button-cancel": "Cancel",

    "profiles-list:delete:message-text": "The profile will be deleted permanently. Continue?",
    "profiles-list:delete:message-title": "Warning",
    "profiles-list:delete:message-success": "Profile successfully deleted!",
    "profiles-list:delete:button-confirm": "Delete",

    "profiles-list:create:message-success": "New profile created successfully!",
    "profiles-list:message-title": "Notification!"
  }
}
</i18n>
