var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-map"},[_c('div',{attrs:{"id":"mt-map"}}),(_vm.hasModule('Layers Top Panel') && _vm.mapReady)?_c('div',{staticClass:"mt-map__layers"},[_c('r-info-layers',{ref:"infoLayers",attrs:{"mapgl":_vm.mapgl,"module":"monitoring","enabled-layers":[
        'graph',
        'transportSituation',
        'geozones',
        'signs',
        'events',
        'tilelayers'
      ]}})],1):_vm._e(),_c('div',{staticClass:"mt-map__actions"},[(_vm.mapgl)?_c('r-map-tools',{attrs:{"tools":['baselayers', '3d', 'reset', 'screenshot'],"map":_vm.mapgl,"map-bearing":_vm.mapBearing,"is3d":_vm.is3d,"baselayer-id":_vm.baselayerId},on:{"change-prop":_vm.changeProp,"toggle-base-layer":_vm.toggleBaselayer}}):_vm._e(),_c('mt-legend')],1),(_vm.vehiclesLoading)?_c('r-map-loader'):_vm._e(),_c('mt-popup',{attrs:{"settings":_vm.popupSettings}}),(_vm.mainTrackGeom)?_c('track-player',{attrs:{"mapgl":_vm.mapgl}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }