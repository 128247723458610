import { getDatasourcesByDatatype, getModelChildrenIds } from '@/utils'
import { modifyLines } from '../helpers'

export class ModelController {
  constructor(parent) {
    this.parent = parent
    this.$store = parent.$store
    this.mapgl = parent.mapgl
  }

  async loadModel() {
    try {
      const models = await getDatasourcesByDatatype(this.parent, 'model')
      const model = models.find(m => !!m.is_main_model) || models[0]
      const { id, children, name } = model

      const ids = getModelChildrenIds(children)

      this.$store.commit('SET_PT_MODEL', { id, ids, name, children })

      await this.loadLists()
    } catch (error) {
      console.log(error)
    }
  }

  async loadLists() {
    const { ids } = this.$store.state.publicTransport.model
    const linesConfig = {
      only: ['id', 'name'],
      include: {
        line_routes: {
          only: ['id', 'name', 'geom'],
          include: {
            line_route_items: {
              only: ['id', 'index', 'node_id', 'stop_point_id'],
              order: [{ index: 'asc' }],
              include: {
                stop_point: {
                  only: ['id', 'no', 'name']
                }
              }
            }
          }
        }
      }
    }
    const linesUrl = `objectInfo/${ids.lines}?config=${JSON.stringify(
      linesConfig
      )}`
    const linesData = await this.$store.dispatch('GET_REQUEST', {
      url: linesUrl
    })

    const spConfig = {
      order: ['name', 'no']
    }
    const spUrl = `objectInfo/${ids.stop_points}?config=${JSON.stringify(
      spConfig
    )}`
    const spData = await this.$store.dispatch('GET_REQUEST', { url: spUrl })

    const stopPoints = Object.values(spData.data)
    const lines = modifyLines(Object.values(linesData.data))

    // set lists
    this.$store.commit('SET_PT_LIST', {
      list: stopPoints,
      type: 'stop_points'
    })
    this.$store.commit('SET_PT_LIST', {
      list: lines,
      type: 'lines'
    })
  }
}
