<template>
  <div class="mt-track-filters">
    <div class="mt-track-filters__item">
      <r-button
        type="primary"
        :loading="loading"
        @click="toggleTrack"
      >
        {{ trackButtonText }}
      </r-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    },
    interval: {
      type: Object,
      required: true
    }
  },
  computed: {
    // isSuitableRange() {
    //   return !this.getDisabledRange(this.interval.to)
    // },
    trackButtonText() {
      if (this.loading) {
        return 'Загрузка трека...'
      }

      if (this.show) {
        return 'Скрыть трек'
      }

      return 'Показать трек'
    }
  },
  methods: {
    // getDisabledRange(time) {
    //   const to = new Date(time).getTime()
    //   return to > Date.now()
    // },
    loadTrack() {
      if (this.isSuitableRange) {
        this.$emit('load')
      }
    },
    toggleTrack() {
      this.$emit('toggle')
    }
  }
}
</script>

<style lang="scss">
.mt-track-filters {
  display: grid;
  grid-gap: 0.5rem;
  grid-auto-flow: column;
  align-items: end;
  justify-content: start;

  .r-date-picker.el-input {
    width: 180px;
  }
}
</style>
