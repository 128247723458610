<template>
  <div :class="['mt-legend', { show }]">
    <r-block
      col
      no-padding
      space-between
    >
      <div
        v-if="show"
        class="mt-legend__header"
      >
        Легенда
      </div>
      <r-button
        :icon="show ? 'close-delete' : 'info'"
        simple
        @click="show = !show"
      />
    </r-block>
    <div
      v-if="show"
      class="mt-legend__content"
    >
      <div class="mt-legend__title">
        <r-text>
          Транспортные средства
        </r-text>
      </div>
      <ul class="mt-legend__list">
        <li
          v-for="item in statuses"
          :key="item.title"
          :class="`mt-legend__item mt-legend__item--${item.className}`"
        >
          <div class="mt-legend__item-icon" />
          <div class="mt-legend__item-label">
            <r-text type="caption">
              {{ item.title }}
            </r-text>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
const statuses = [
  {
    title: 'На связи',
    className: 'green'
  },
  {
    title: 'Нет связи (отметка более 5 мин назад)',
    className: 'yellow'
  },
  {
    title: 'На связи (нет координат более 30 мин)',
    className: 'blue'
  },
  {
    title: 'Нет данных',
    className: 'red'
  }
]

export default {
  data() {
    return {
      show: false,
      statuses
    }
  }
}
</script>

<style lang="scss">
.mt-legend {
  position: absolute;
  right: 16px;
  top: 200px;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  width: auto;
  z-index: 1000;
  font-size: 12px;
  color: var(--white_white);
  background-color: var(--modal_bg) !important;
  border-color: var(--dividers_low_contrast) !important;

  &.show {
    width: 200px;
  }

  &__header {
    padding: 8px;
    font-weight: bold;
    border-radius: var(--border-radius);
    text-transform: uppercase;
    color: var(--text_primary) !important;
    background-color: var(--modal_bg) !important;
  }

  &__content {
    padding: 8px;
  }

  &__list {
    margin-top: 8px;
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-top: 8px;
    }

    &--green {
      .mt-legend__item-icon {
        background-image: url('../../../../assets/images/monitoring/monitoring-car-green.svg');
      }
    }

    &--blue {
      .mt-legend__item-icon {
        background-image: url('../../../../assets/images/monitoring/monitoring-car-blue.svg');
      }
    }

    &--yellow {
      .mt-legend__item-icon {
        background-image: url('../../../../assets/images/monitoring/monitoring-car-yellow.svg');
      }
    }

    &--red {
      .mt-legend__item-icon {
        background-image: url('../../../../assets/images/monitoring/monitoring-car-red.svg');
        opacity: 0.5
      }
    }

    &-icon {
      margin-right: 8px;
      width: 32px;
      height: 32px;
      background-size: cover;
      background-repeat: no-repeat;
      flex-shrink: 0;
    }
  }
}
</style>
