<template>
  <div class="mt-date-filter">
    <div class="mt-date-filter__item">
      <r-button
        type="primary"
        :loading="loading"
        @click="load"
      >
        {{ buttonText }}
      </r-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    },
    interval: {
      type: Object,
      required: true
    }
  },
  computed: {
    buttonText() {
      return this.loading ? 'Загрузка...' : 'Показать'
    }
  },
  methods: {
    load() {
      this.$emit('load')
    }
  }
}
</script>

<style lang="scss">
.mt-date-filter {
  display: grid;
  grid-gap: 0.5rem;
  grid-auto-flow: column;
  align-items: end;
  justify-content: start;
}
</style>
