import { rDate } from '@/utils'
import mapboxgl from 'mapbox-gl'
import extent from 'turf-extent'
import midpoint from '@turf/midpoint'
import { point } from '@turf/helpers'
import { layersConfig, eventsTranslate } from '../config/'

export class TrackController {
  constructor(map) {
    this.map = map
    this.$store = map.$store
    this.mapgl = map.mapgl

    this.selected = null
    this.hovered = null

    this.mousemoveHandler = this.mousemoveHandler.bind(this)
    this.mouseleaveHandler = this.mouseleaveHandler.bind(this)
    this.clickHandler = this.clickHandler.bind(this)

    this.pointPopup = null
  }

  toggleTrack(data, type) {
    if (data) {
      this.addTrack(type)
      this.addEvents(type)
    } else {
      this.removeTrack(type)
      this.removeEvents(type)
    }
  }

  addTrack(type) {
    const trackSourceId = `mt-track-${type}`
    const color = this.map.trackColors?.[type] || '#ccc'
    const source = this.mapgl.getSource(trackSourceId)

    if (source) {
      source.setData(this.getData(type))
    } else {
      this.mapgl.addSource(trackSourceId, {
        type: 'geojson',
        data: this.getData(type)
      })

      this.mapgl.addLayer(
        {
          id: trackSourceId,
          source: trackSourceId,
          type: 'line',
          layout: {
            'line-cap': 'round',
            'line-join': 'round'
          },
          paint: {
            'line-color': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              '#55b77e',
              [
                'step',
                ['get', 'speed'],
                'rgb(180,220,177)',
                40,
                'rgba(153,213,148, 1)',
                60,
                'rgb(236,209,158)',
                70,
                'rgb(248,216,127)',
                90,
                'rgba(252,141,89, 1)',
                110,
                'rgba(213,62,79, 1)'
              ]
            ],
            'line-width': [
              'match',
              ['get', 'type'],
              'active',
              ['case', ['boolean', ['feature-state', 'hover'], false], 12, 6],
              3
            ],
            'line-opacity': ['match', ['get', 'type'], 'active', 0.9, 0.2]
          }
        },
        'vehicles'
      )

      this.mapgl.addLayer(
        {
          id: `${trackSourceId}_arrows`,
          source: trackSourceId,
          type: 'symbol',
          layout: {
            'symbol-placement': 'line',
            'text-field': '▶',
            'text-size': ['interpolate', ['linear'], ['zoom'], 12, 10, 22, 12],
            'symbol-spacing': [
              'interpolate',
              ['linear'],
              ['zoom'],
              12,
              32,
              22,
              45
            ],
            'text-keep-upright': false,
            'text-allow-overlap': true,
            'icon-allow-overlap': true
          },
          paint: {
            'text-color': color,
            'text-halo-color': '#ffffff',
            'text-halo-width': 0.8
          }
        },
        'vehicles'
      )

      this.mapgl.on('mousemove', trackSourceId, this.mousemoveHandler)
      this.mapgl.on('mouseleave', trackSourceId, this.mouseleaveHandler)
      this.mapgl.on('click', trackSourceId, this.clickHandler)
    }
  }

  removeTrack(type) {
    const trackSourceId = `mt-track-${type}`

    this.mapgl.off('mousemove', trackSourceId, this.mousemoveHandler)
    this.mapgl.off('mouseleave', trackSourceId, this.mouseleaveHandler)
    this.mapgl.off('click', trackSourceId, this.clickHandler)

    if (this.mapgl.getLayer(trackSourceId)) {
      this.mapgl.removeLayer(trackSourceId)
    }
    if (this.mapgl.getLayer(`${trackSourceId}_arrows`)) {
      this.mapgl.removeLayer(`${trackSourceId}_arrows`)
    }
    if (this.mapgl.getSource(trackSourceId)) {
      this.mapgl.removeSource(trackSourceId)
    }
  }

  addEvents(type) {
    const eventsSourceId = `mt-events-${type}`
    const source = this.mapgl.getSource(eventsSourceId)

    const data = this.$store.state.monitoring[`${type}TrackEvents`] || []

    if (!data?.length && !data?.features?.length) {
      this.removeEvents(type)
    } else if (source) {
      source.setData(data)
    } else {
      this.mapgl.addSource(eventsSourceId, {
        type: 'geojson',
        data: data
      })
      const styles = layersConfig.events

      this.mapgl.addLayer(
        {
          id: eventsSourceId,
          source: eventsSourceId,
          type: 'line',
          ...styles
        }
      )

      this.mapgl.on('click', eventsSourceId, this.clickHandler)
    }
  }

  removeEvents(type) {
    const eventsSourceId = `mt-events-${type}`

    this.mapgl.off('click', eventsSourceId, this.clickHandler)

    if (this.mapgl.getLayer(eventsSourceId)) {
      this.mapgl.removeLayer(eventsSourceId)
    }
    if (this.mapgl.getSource(eventsSourceId)) {
      this.mapgl.removeSource(eventsSourceId)
    }
  }

  updateTracks() {
    const { mainTrackGeom, secondTrackGeom } = this.$store.state.monitoring

    if (mainTrackGeom) this.addTrack('main')
    if (secondTrackGeom) this.addTrack('second')
  }

  parseDatetime(datetime) {
    const [date, time] = datetime.split(' ')
    const [d, m, y] = date.split('.')
    const init = `${m}-${d}-${y} ${time}`
    return init
  }

  getData(type) {
    const { charts, monitoring } = this.$store.state
    const features = monitoring[`${type}TrackGeom`]
    let start = rDate.format(features[0].properties.time, 'x')
    let end = rDate.format(features[features.length - 1].properties.time, 'x')
    const { mt_track_chart } = charts.list

    if (mt_track_chart) {
      const startInit = this.parseDatetime(mt_track_chart.datazoom.start)
      const endInit = this.parseDatetime(mt_track_chart.datazoom.end)
      start = rDate.format(startInit, 'x')
      end = rDate.format(endInit, 'x')
    }

    return {
      type: 'FeatureCollection',
      features: features.map((f, index) => {
        const currentValue = rDate.format(f.properties.time, 'x')

        return {
          ...f,
          id: index,
          properties: {
            type:
              currentValue >= start && currentValue <= end
                ? 'active'
                : 'inactive',
            time: f.properties.time,
            speed: f.properties.speed,
            series: type === 'main' ? 0 : 1,
            index
          }
        }
      })
    }
  }

  clickHandler(e) {
    this.mapgl.getCanvas().style.cursor = 'pointer'

    if (e.features.length > 0) {
      const { properties, geometry, source } = e.features[0]

      if (source === 'mt-events-main') {
        const { time, description, event_type } = properties

        this.showPopup({
          coordinates: geometry.coordinates,
          event_type,
          time,
          description
        })
      } else {
        const { time, speed, index, series } = properties

        this.selected = e.features[0].id

        this.$store.commit('CHARTS_SET_FIELD_BY_ID', {
          id: 'mt_track_chart',
          field: 'highlightByTime',
          value: {
            datetime: rDate.format(time, 'YYYY-MM-DD HH:mm:ss'),
            dataIndex: index,
            series
          }
        })

        console.log('clicked on map index --- ', index)

        this.showPopup({
          coordinates: this.getMidPoint(geometry.coordinates),
          time,
          speed
        })
      }
    }
  }

  mousemoveHandler(e) {
    this.mapgl.getCanvas().style.cursor = 'pointer'

    if (e.features.length > 0) {
      const { source } = e.features[0]

      if (this.hovered !== null) {
        if (this.mapgl.getLayer('mt-track-main')) {
          this.mapgl.setFeatureState(
            { source: 'mt-track-main', id: this.hovered },
            { hover: false }
          )
        }
        if (this.mapgl.getLayer('mt-track-second')) {
          this.mapgl.setFeatureState(
            { source: 'mt-track-second', id: this.hovered },
            { hover: false }
          )
        }
      }
      this.hovered = e.features[0].id
      this.mapgl.setFeatureState({ source, id: this.hovered }, { hover: true })
    }
  }

  mouseleaveHandler() {
    this.mapgl.getCanvas().style.cursor = ''

    if (this.hovered !== null) {
      this.mapgl.setFeatureState(
        { source: 'mt-track-main', id: this.hovered },
        { hover: false }
      )
      if (this.mapgl.getSource('mt-track-second')) {
        this.mapgl.setFeatureState(
          { source: 'mt-track-second', id: this.hovered },
          { hover: false }
        )
      }
    }

    this.hovered = null
  }

  updateHighlighedTrack(series, index) {
    const source = series === 0 ? 'mt-track-main' : 'mt-track-second'

    this.selected = index
    this.mapgl.setFeatureState({ source, id: this.selected }, { hover: false })
  }

  showPointOnMap(clickData) {
    if (!clickData) return

    const { index, title, data } = clickData

    console.log('clicked on chart index --- ', index)

    const type = title === 'Скорость (1)' ? 'main' : 'second'
    const { monitoring } = this.$store.state
    const features = monitoring[`${type}TrackGeom`]
    const feature = features[index]
    const { coordinates } = feature.geometry

    const bounds = extent(feature.geometry)

    this.mapgl.fitBounds(bounds, {
      padding: 20,
      maxZoom: 17
    })

    this.showPopup({
      coordinates: this.getMidPoint(coordinates),
      time: data[0],
      speed: data[1]
    })
  }

  hidePopup() {
    if (this.pointPopup) {
      this.pointPopup.remove()
    }
  }

  showPopup({ coordinates, time, speed, description, event_type }) {
    if (event_type) {
      if (this.pointPopup) {
        this.pointPopup.remove()
      }

      this.pointPopup = new mapboxgl.Popup({ closeOnClick: true })
        .setLngLat(coordinates)
        .setHTML(
          `
          <ul>
            <li>Событие: <b>${eventsTranslate[event_type]}</b></li>
            <li>Время: <b>${rDate.format(time)}</b></li>
            <li>Инфо: <b>${description}</b></li>
          </ul>
        `
        )
        .addTo(this.mapgl)
    } else {
      if (this.pointPopup) {
        this.pointPopup.remove()
      }

      this.pointPopup = new mapboxgl.Popup({ closeOnClick: true })
        .setLngLat(coordinates)
        .setHTML(
          `
          <ul>
            <li>Время: <b>${rDate.format(time)}</b></li>
            <li>Скорость: <b>${speed}</b> км/ч</li>
          </ul>
        `
        )
        .addTo(this.mapgl)
    }
  }

  getMidPoint(lineCoords) {
    const point1 = point(lineCoords[0])
    const point2 = point(lineCoords[1])

    return midpoint(point1, point2).geometry.coordinates
  }
}
