<template>
  <r-toggle-card
    title="Пунктирная линия"
    :is-open="config.enabled"
    @toggle="toggleProperty"
  />
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  methods: {
    toggleProperty(value) {
      this.$emit('toggle', value)
    }
  }
}
</script>

<style></style>
