<template>
  <div class="email-distribution-item">
    <div class="email-distribution-item__created-by">
      <r-text color-type="secondary">
        {{
          `Создано: ${email.created_user.name}, ${$rDate.format(
            email.created_at
          )} `
        }}
      </r-text>
    </div>
    <div class="email-distribution-item__title">
      <r-title type="title-1">
        {{ email.subject }}
      </r-title>
    </div>
    <div class="email-distribution-item__content">
      <r-title type="title-2">
        {{ email.values.name }}
      </r-title>
      <p class="email-distribution-item__content-body">
        {{ email.values.desc }}
      </p>
      <r-text>- - -</r-text>
      <p class="email-distribution-item__content-signature">
        {{ email.values.signature }}
      </p>
    </div>
    <div
      v-if="email.attachments.length"
      class="email-distribution-item__attachments"
    >
      <r-title type="subtitle-2">
        Вложения
      </r-title>
      <ul class="email-distribution-item__attachments-list">
        <file-item
          v-for="(item, i) in email.attachments"
          :key="i"
          :file="item"
        />
      </ul>
    </div>
    <r-block
      v-if="recipients.length"
      no-padding
      col
      start
    >
      <r-title type="subtitle-2">
        Получатели:
      </r-title>
      <r-text>{{ recipients.join('; ') }}</r-text>
    </r-block>
  </div>
</template>

<script>
import fileItem from './file-item'

export default {
  components: { fileItem },
  props: {
    email: { type: Object, required: true },
    groups: { type: Object, default: () => {} }
  },
  computed: {
    recipients() {
      const { recipients } = this.email

      if (!recipients) return []

      const { emails, groups } = recipients
      const address = emails || []

      groups.forEach(g => {
        if (this.groups[g]) {
          this.groups[g].subscribers.forEach(s => {
            if (!address?.includes(s.email)) {
              address.push(s.email)
            }
          })
        }
      })

      return address
    }
  }
}
</script>

<style lang="scss">
.email-distribution-item {
  display: grid;
  align-items: center;
  grid-auto-flow: row;
  padding: 0.5rem;
  grid-gap: 0.5rem;
  background-color: var(--bg_containers);
  border-radius: var(--border-radius);

  &__created-by {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--dividers_low_contrast);
  }

  &__content {
    &-body,
    &-signature {
      hyphens: auto;
      margin: 0;
      color: var(--text_primary);
      white-space: pre-line;
    }

    &-signature {
      color: var(--text_subhead);
    }
  }

  &__attachments {
    border-top: 1px solid var(--dividers_low_contrast);
    padding-top: 0.25rem;

    &-list {
      display: grid;
      grid-auto-flow: row;
      grid-gap: 0.5rem;
      padding-top: 0.25rem;
    }
  }
}
</style>
