<template>
  <div class="pot-1-terminal">
    <pot-1-terminal />
  </div>
</template>

<script>
import pot1Terminal from '@/components/pot-1-terminal/pot-1-terminal-container'

export default {
  components: {
    pot1Terminal
  }
}
</script>

<style lang="scss">
.pot-1-terminal {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
}
</style>
