export const roleEquality = (rolePerms, userPerms) => {
  const roleModules = rolePerms?.modules
  const userModules = userPerms?.modules

  const isModulesEqual = !userModules.some(um => {
    const module = roleModules.find(rm => rm.name === um.name)

    if (!module) return true

    const isInstrumentsEqual = !um?.instruments.some(ui => {
      const instrument = module?.instruments.find(ri => ri.name === ui.name)

      if (!instrument) return true
    })

    return !isInstrumentsEqual
  })

  const roleDS = rolePerms?.datasources
  const userDS = userPerms?.datasources

  const isDSEqual = !userDS.some(ud => {
    const ds = roleDS.find(rd => rd.name === ud.name)

    if (!ds) return true

    const isCrudsEqual = !ud?.cruds.some((uc, i) => {
      return ds?.cruds[i] !== uc
    })

    return !isCrudsEqual
  })

  return isDSEqual && isModulesEqual
}

export const getPermsForNewRole = (perms) => {
  return {
    modules: perms.modules.map(e => {
      e._action = 'created'

      if (e.instruments) {
        e.instruments = e.instruments.map(i => {
          i._action = 'created'

          return i
        })
      }

      return e
    }),
    datasources: perms.datasources.map(e => {
      e._action = 'created'

      if (e.children) {
        e.children = e.children.map(c => {
          c._action = 'created'

          return c
        })
      }

      return e
    })
  }
}
