import vue from 'vue'

const state = {
  activeModule: 'vehicles',
  vehicleListId: 'f09e59fb-ee58-482c-8b0f-9ac520cf2099',
  allVehicleList: null,
  potVehicleList: null,
  initialPotVehicleList: null,
  statusListIds: {
    vehicles: '1d5f5799-6371-4c33-b741-e517d5676fb0',
    railway: 'faee0926-9f71-4f37-8524-5199b2787201'
  },
  statusList: null,
  contractorId: 'ef021b44-a650-478e-a25f-ace1e9b545ca',
  contractorList: null,
  checkpointId: 'c3c87a16-85d0-4160-8101-cedab2440e56',
  checkpointList: null,
  warehouseId: '30cd86ef-4c58-44ee-ab31-6f34f1c327b2',
  warehouseList: null,
  cargoId: 'd1709d93-ca52-4453-a83b-ff7ab253a464',
  categoriesId: 'c3e5d963-f252-43e9-8857-e20bc39cb64e',
  categoriesList: null,
  cardId: null,
  newVehicleModal: false,
  createNewVehicleModal: false,
  editVehicleModal: null,
  updateData: false,
  terminalList: null,
  terminalActive: null,
  printVehicle: null,
  //
  source_id: 'f09e59fb-ee58-482c-8b0f-9ac520cf2099',
  railway_id: '42651eb5-0954-4687-a9d6-23872b98b009',
  barges_id: 'telemetry.barges',
  mainModelId: null,
  modalName: null,
  related: null,
  isAdmin: true,

  filters: {
    dispatcher_vehicles: null,
    logistic_vehicles: null,
    dispatcher_railway: null,
    logistic_railway: null,
    dispatcher_barges: null,
    logistic_barges: null
  }
}

const mutations = {
  SET_POT_FIELD(state, { field, value }) {
    if (field.includes('.')) {
      const splited = field.split('.')

      vue.set(state[splited[0]], splited[1], value)
    } else {
      vue.set(state, field, value)
    }
  }
}

export default {
  state,
  mutations
}
