<template>
  <div class="map-panels">
    <r-main-panel>
      <component
        :is="mainPanelInfo.component"
        :map="map"
        :draw="draw"
        :controllers="controllers"
        :state="state"
        :toggle-module="toggleModule"
      />
    </r-main-panel>
    <r-second-panel
      v-if="isSecondPanelActive"
      @close="closeSecondPanel"
    >
      <layer-settings
        v-if="secondPanelMode === 'layer-settings'"
        :id="layerSettingsId"
        :style-config="styleConfig"
        :controllers="controllers"
      />
    </r-second-panel>
  </div>
</template>

<script>
import MapTree from '../map-tree/map-tree'
import MapEditor from '../map-editor/map-editor'
import LayerSettings from '../layer-settings/settings'

export default {
  components: {
    MapTree,
    MapEditor,
    LayerSettings
  },
  props: {
    map: {
      type: Object,
      required: true
    },
    draw: {
      type: Object,
      required: true
    },
    styleConfig: {
      type: Object,
      required: true
    },
    state: {
      type: Object,
      required: true
    },
    controllers: {
      type: Object,
      required: true
    },
    mapMode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isSecondPanelActive: false,
      secondPanelMode: 'layer-settings',
      layerSettingsId: ''
    }
  },
  computed: {
    mainPanelInfo() {
      switch (this.mapMode) {
        case 'editor':
          return {
            title: this.$t('editor'),
            icon: 'vizualization',
            component: MapEditor
          }
        default:
          return {
            title: this.$t('vizualization'),
            icon: 'vizualization',
            component: MapTree
          }
      }
    }
  },
  methods: {
    toggleModule({ type, data }) {
      const { id } = data

      switch (type) {
        case 'settings':
          if (
            this.isSecondPanelActive &&
            this.secondPanelMode === 'layer-settings' &&
            (this.layerSettingsId === id || !id)
          ) {
            this.isSecondPanelActive = false
            this.layerSettingsId = ''
          } else {
            this.secondPanelMode = 'layer-settings'
            this.isSecondPanelActive = true
            this.layerSettingsId = id
          }
          this.$nextTick(() => this.map.resize())
          break
        case 'editor':
          if (this.mapLoading) return
          this.controllers.editor.toggleEditor(data)
          break
      }
    },
    closeSecondPanel() {
      this.toggleModule({ type: 'settings', data: { id: this.id } })
    }
  }
}
</script>

<style lang="scss">
.map-panels {
  display: flex;
  z-index: 10;
  box-shadow: 2px 0 10px 0px rgba(0, 0, 0, 0.1);
}
</style>
