import { rDate } from '@/utils'

export const getTableData = (data, options) => {
  const { tableFields, geomField, geomFieldExpression } = options

  return data
    ?.map((e, i) => {
      const item = {}

      tableFields.forEach(l => {
        let val

        if (l.value.includes('.')) {
          const subField = l.value.split('.')

          val = e?.[subField[0]]?.[subField[1]] || null
        } else {
          val = e[l.value] ?? null
        }

        switch (l.type) {
          case 'date':
            item[l.title] = val ? rDate.format(val, l.dateFormat || 'DD.MM.YYYY HH:mm:ss') : '-'
            break
          case 'select':
            item[l.title] = l.values?.[val] || (l.default || 'нет данных')
            break
          case 'array': {
            if (l.value.includes('.')) {
              const subField = l.value.split('.')

              item[l.title] = e?.[subField[0]]
                ?.map(s => (s?.[subField[1]] || (l.default || '')))
                ?.join('; ') || (l.default || 'нет данных')
            } else {
              item[l.title] = e?.[l.value]?.join('; ') || (l.default || 'нет данных')
            }
            break
          }
          case 'boolean':
            item[l.title] = val ? '✓' : '-'
            break
          case 'number':
            if (l.toFixed) {
              item[l.title] = !val ? val : val.toFixed(l.toFixed)
            } else {
              item[l.title] = val
            }
            break
          default:
            item[l.title] = val
            break
        }
      })

      item.id = e.id || 'id:' + i

      if (geomFieldExpression) {
        item.geom = geomFieldExpression(e)
      } else {
        item.geom = e[geomField || 'geom'] || null
      }

      return item
    })
}
