import constants from '@/constants/url'

export const getFormatsList = function(state, data) {
  const { geomType, datatype, children, source_id } = data
  const modelFormat = 'net'
  const formats = ['json', 'csv']
  let exportFormats = []

  if (geomType) {
    formats.push('geojson')
    formats.push('shp')
  }

  if (datatype === 'model') {
    exportFormats.push({
      // eslint-disable-next-line
      href: `${constants.URL}/objectInfo/${source_id}?access_token=${state.token}&as_file=true&format=${modelFormat}`,
      name: modelFormat
    })
  } else if (!children) {
    exportFormats = formats.map(f => {
      return {
        // eslint-disable-next-line
        href: `${constants.URL}/objectInfo/${source_id}?access_token=${state.token}&as_file=true&format=${f}&array=true`,
        name: f
      }
    })
  }

  return exportFormats
}
