<template>
  <div class="odd-router1">
    <router-view />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.odd-router1 {
  height: 100%;
  overflow: hidden;
}
</style>
