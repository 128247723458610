<template>
  <el-dialog
    title="Редактирование расписания"
    :visible.sync="show"
    :before-close="handleClose"
    append-to-body
    width="824px"
    top="48px"
  >
    <div class="pt-schedule-editor">
      <schedule-table
        @close="$emit('close')"
        @fixChanges="fixChanges"
      />
    </div>
  </el-dialog>
</template>

<script>
import ScheduleTable from './schedule-table/schedule-table'

export default {
  components: {
    ScheduleTable
  },
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      hasChanges: false
    }
  },
  methods: {
    fixChanges() {
      this.hasChanges = true
    },
    handleClose() {
      if (!this.hasChanges) {
        this.$emit('close')
      } else {
        const warningText = 'Закрытие окна отменит все изменения'
        const warningTitle = 'Вы уверены?'

        this.$confirm(warningText, warningTitle, {
          customClass: 'r-message-box',
          type: 'warning',
          closeOnPressEscape: false,
          closeOnClickModal: false,
          confirmButtonText: 'Да'
        })
          .then(() => {
            this.$emit('close')
            this.hasChanges = false
          })
          .catch(() => {})
      }
    }
  }
}
</script>

<style lang="scss">
.pt-schedule-editor {
  max-height: 75vh;
  overflow: auto;
}
</style>
