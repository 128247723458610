<template>
  <div class="rp-users-personal-photo">
    <div class="rp-users-personal-photo-col photo">
      <el-upload
        ref="avatarUpload"
        class="avatar-uploader"
        :action="`${baseURL}/${uploadURL}`"
        :show-file-list="false"
        :before-upload="beforeAvatarUpload"
        :on-success="onSuccessUploading"
      >
        <div class="rp-users-personal-photo-wrapper">
          <img
            v-if="user && user.avatar"
            :src="getImageSource(user.avatar)"
            class="rp-users-personal-photo-image"
            alt="no-data"
          >
          <div
            slot="trigger"
            ref="avatarUploadButton"
            class="user-info__avatar-edit"
          >
            <span class="el-icon-edit" />
          </div>
        </div>
      </el-upload>
    </div>
    <div class="rp-users-personal-photo-col control">
      <r-button
        style="margin-bottom: 8px"
        @click="handleUpload"
      >
        Добавить фото
      </r-button>
      <r-text color-type="secondary">
        Форматы: JPG, JPEG, PNG не более 2 МБ
      </r-text>
    </div>
  </div>
</template>

<script>
import { notifyFactory } from '@/utils'
import constants from '@/constants/url'

export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      downloadURL: 'download_share_resource',
      baseURL: constants.URL,
      uploadURL: 'upload_share_resource'
    }
  },
  methods: {
    getImageSource(file) {
      return `${this.baseURL}/${this.downloadURL}/${file}`
    },
    handleUpload() {
      this.$refs.avatarUploadButton.click()
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg '
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        const title = 'Некорректный формат'
        const message = 'Формат изображения должен быть JPEG, JPG или PNG'
        this.$notify(notifyFactory('error', title, message))
      }
      if (!isLt2M) {
        const title = 'Некорректный размерз'
        const message = 'Размер изображения должен быть не более 2МБ'
        this.$notify(notifyFactory('error', title, message))
      }
      return isJPG && isLt2M
    },
    onSuccessUploading(res) {
      if (!res.resource_name) return

      this.saveUserPhoto(res.resource_name)
    },
    async saveUserPhoto(resource_name) {
      try {
        await this.$store.dispatch('POST_REQUEST', {
          url: `user?user_id=${this.user.id}`,
          data: {
            id: this.user.id,
            avatar: resource_name
          }
        })
        const title = 'Редактирование пользователя'
        const message = 'Редактирование пользователя выполнено успешно'

        this.$notify(notifyFactory('success', title, message))
        this.$store.commit('ROLE_PERM_SET_FIELD', {
          field: 'updateUsers',
          value: true
        })
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>

<style lang="scss">
.rp-users-personal-photo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &-col {
    position: relative;

    .r-text {
      margin-bottom: 0;
    }

    &.photo {
      width: 64px;
      height: 64px;
      overflow: hidden;
      border-radius: 50%;
      background-color: #a9a9a9;
      margin-right: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
</style>
