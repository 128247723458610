export const state = {
  activeType: 'car_event',
  lists: {
    car_event: [],
    system_event: [],
    cameras: []
  },
  userNoticesTypes: [],
  userNoticesCars: [],
  updateList: null,
  // show on map helper
  flyToGeom: null,
  exportUrl: null,
  related: null,
  pinned: false
}

export const mutations = {
  SET_JOURNAL_FIELD(state, [field, value]) {
    state[field] = value
  },
  SET_JOURNAL_LIST(state, [type, list]) {
    state.lists[type] = list
  }
}

export default {
  state,
  mutations
}
