export const undraggableTypes = [
  'links',
  'connectors',
  'line_routes',
  'lines',
  // 'stop_points',
  'general_count_points'
  // "count_points"
]

export const actionLabelConfig = {
  move: 'Перемещение',
  change_coordinates: 'Изменение координат',
  change_properties: 'Изменение свойств',
  delete: 'Удаление',
  create: 'Создание'
}

export const getHistoryLabel = (action, length = 1) => {
  return `${actionLabelConfig[action]} ${
    length === 1 ? 'объекта' : `объектов (${length})`
  }`
}
