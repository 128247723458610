export const notifyFactory = (type, title, message, duration = 3000, dangerouslyUseHTMLString) => {
  return {
    customClass: `r-notification ${type}`,
    offset: 40,
    message,
    duration,
    type,
    dangerouslyUseHTMLString,
    title
  }
}
