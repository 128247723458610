export const getRequestConfig = (type, id) => {
  switch (type) {
    case 'lines':
      return {
        where: [{ field: 'id', op: '=', value: id }],
        only: [
          'id',
          'name',
          'color',
          'active',
          'description',
          'valid_from',
          'valid_until',
          'contractor_id'
        ],
        include: {
          contractor: {
            only: ['id', 'name']
          },
          vehicles: {
            only: ['id', 'reg_number', 'retranslation_status', 'vrc_full_name'],
            include: {
              model: {
                only: ['id', 'name']
              },
              line_vehicle: { only: ['id', 'line_id'] },
              categories: { only: ['name'] },
              contractor: { only: ['name'] }
            }
          },
          line_routes: {
            only: ['id', 'name', 'geom', 'rev'],
            include: {
              line_route_items: {
                only: ['id', 'index', 'node_id', 'stop_point_id'],
                order: [{ index: 'asc' }],
                include: {
                  stop_point: {
                    only: [
                      'id',
                      'no',
                      'name',
                      'address',
                      'is_active',
                      'on_demand',
                      'geom'
                    ]
                  }
                }
              }
            }
          }
        }
      }

    case 'stop_points':
      return {
        where: [{ field: 'id', op: '=', value: id }],
        only: [
          'id',
          'no',
          'name',
          'address',
          'is_active',
          'on_demand',
          'geom',
          'stop_geom',
          'link_id',
          'node_id'
        ]
      }

    case 'schedule':
      return {
        where: [{ field: 'id', op: '=', value: id }],
        only: ['id'],
        include: {
          time_profiles: {
            include: {
              time_profile_items: {}
            }
          },
          veh_journeys: {
            include: {
              veh_journey_items: {}
            }
          }
        }
      }
  }
}
