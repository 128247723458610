import { CommonSelectors, Constants } from '@/libs/map-draw/lib-utils'
import {
  getPointFeatures,
  createHoveredNodeHelper,
  clearAllHelpers
} from '@/libs/map-draw/helpers'

export const createDrawConnectorsMode = (MapboxDraw, editor, component) => {
  const DrawConnectors = {}
  const customState = {
    nodeNo: null,
    nodeGeometry: null
  }

  DrawConnectors.onKeyUp = function(state, e) {
    if (CommonSelectors.isEscapeKey(e)) {
      this.deleteFeature([state.line.id], { silent: true })
      editor.isDrawing = false
      return this.changeMode(Constants.modes.SIMPLE_SELECT, {
        featureIds: [state.line.id]
      })
    }
  }

  DrawConnectors.onMouseOut = function() {
    clearAllHelpers(this.map)
  }

  DrawConnectors.clickAnywhere = function(state, e) {
    const nodesFeatures = getPointFeatures(this.map, editor, e, 'nodes')
    const zonesFeatures = getPointFeatures(this.map, editor, e, 'zones')
    const features = [...nodesFeatures, ...zonesFeatures]

    this.updateUIClasses({ mouse: 'add' })
    if (!features.length) return

    const nodeCoordinates = features[0].geometry.coordinates
    const { properties } = features[0]

    if (state.currentVertexPosition === 0) {
      const propertyName = properties.center_geom ? 'zone_id' : 'node_id'
      state.currentVertexPosition++
      state.line.addCoordinate(0, nodeCoordinates[0], nodeCoordinates[1])
      state.line.setProperty(propertyName, properties.id)
      editor.isDrawing = true
    } else if (state.currentVertexPosition === 1) {
      const propertyName = properties.center_geom ? 'zone_id' : 'node_id'
      if (state.line.properties[propertyName]) return
      state.currentVertexPosition++
      state.line.addCoordinate(0, nodeCoordinates[0], nodeCoordinates[1])
      state.line.setProperty(propertyName, properties.id)

      // attributes editing helper
      const { withAttributes } = editor

      if (withAttributes) {
        editor.creatingCallback = () => {
          editor.isDrawing = false
          this.changeMode(Constants.modes.SIMPLE_SELECT, {
            featureIds: [state.line.id]
          })
        }
        editor.creatingObject = state.line
        component.toggleCreateModal(true)

        return
      }

      editor.isDrawing = false
      return this.changeMode(Constants.modes.SIMPLE_SELECT, {
        featureIds: [state.line.id]
      })
    }
  }

  DrawConnectors.onMouseMove = function(state, e) {
    const { lng, lat } = e.lngLat
    const nodesFeatures = getPointFeatures(this.map, editor, e, 'nodes')
    const zonesFeatures = getPointFeatures(this.map, editor, e, 'zones')
    const features = [...nodesFeatures, ...zonesFeatures]

    if (features.length) {
      const feature = features[0]
      const { coordinates } = feature.geometry

      if (!customState.nodeNo || customState.nodeNo !== feature.properties.id) {
        createHoveredNodeHelper(this.map, coordinates)
        customState.nodeNo = feature.properties.no || feature.properties.zoneno
        state.line.updateCoordinate(
          state.currentVertexPosition,
          coordinates[0],
          coordinates[1]
        )
      }
    } else {
      clearAllHelpers(this.map)
      customState.nodeNo = null
      state.line.updateCoordinate(state.currentVertexPosition, lng, lat)
    }

    if (CommonSelectors.isVertex(e)) {
      this.updateUIClasses({ mouse: Constants.cursors.POINTER })
    }
  }

  return { ...MapboxDraw.modes.draw_line_string, ...DrawConnectors }
}
