import vue from 'vue'

const model = {
  id: null,
  name: null,
  ids: {},
  children: []
}
const editorState = {
  isMap: true,
  enabled: false,
  loading: false,
  id: null,
  modelId: null,
  modelsLayers: [],
  mode: 'create',
  geom_type: 'events',
  geometry: '',
  history: [],
  historyIndex: 0,
  isDrawing: false,
  activeObject: null,
  creatingCallback: null,
  editingCallback: null,
  updateEvents: false
}

const state = {
  // current model data
  model: {
    ...model
  },
  scenarios: [],
  scenariosLoading: false,
  // map layers
  loadingLayers: [],
  activeLayers: [],
  // layers object fields
  objectFields: {},
  requestedFields: {},
  // scenario id
  currentScenarioId: null,
  currentScenarioName: null,
  // layer settings and styles
  settingsId: null,
  stylesConfigs: {},
  // timeline
  currentTime: 300,
  times: [],
  playing: false,
  // editor
  calculating: false,
  activeEventId: null,
  overlay: false,
  isEdited: false,
  geometryMode: 'points',
  eventType: '',
  editorOverlay: false,
  eventClasses: null,
  eventTypes: null,
  editorState
}

const mutations = {
  SET_CALC_FIELD(state, { field, value }) {
    state[field] = value
  },
  SET_OBJECT_FIELDS(state, { id, fields }) {
    state.objectFields[id] = fields
  },
  SET_REQUESTED_FIELDS(state, { id, fields }) {
    state.requestedFields[id] = fields
  },
  SET_STYLE_CONFIG(state, { id, config }) {
    vue.set(state.stylesConfigs, id, config)
  },
  ADD_CALC_LAYER_BY_TYPE(state, { type, id }) {
    const layers = state[`${type}Layers`]

    if (layers.indexOf(id) === -1) layers.push(id)
  },
  REMOVE_CALC_LAYER_BY_TYPE(state, { type, id }) {
    const layers = state[`${type}Layers`]

    layers.splice(layers.indexOf(id), 1)
  }
}

export default {
  state,
  mutations
}
