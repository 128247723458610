<template>
  <div class="account">
    <el-tabs
      v-model="activeTab"
      class="r-tabs simple"
    >
      <el-tab-pane
        v-for="item in tabs"
        :key="item.id"
        :name="item.id"
      >
        <r-text
          slot="label"
          class="r-tabs__label"
        >
          {{ $t(`account-tabs:${item.id}`) }}
        </r-text>
        <component
          :is="item.id"
          :show-password="false"
          module="account"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import userInfo from '@/components/user-info/user-info'
import accountSecurity from './components/account-security'

const tabs = [
  { id: 'user-info', name: 'Информация о пользователе' },
  { id: 'account-security', name: 'Безопасность' }
]

export default {
  components: {
    userInfo,
    accountSecurity
  },
  data() {
    return {
      activeTab: 'user-info',
      loading: false,
      tabs
    }
  },
  created() {
    const user = cloneDeep(this.$store.state.auth.user)
    this.$store.commit('SET_ACTIVE_USER_INFO', user)
    this.$store.commit('SET_INITIAL_USER_INFO', user)
  }
}
</script>

<style lang="scss">
div.r-tabs {
  &.simple {
    .el-tabs {
      &__header {
        background-color: transparent !important;
        border-radius: 0;
      }
      &__item {
        border: none !important;
        &.is-active {
          background-color: transparent !important;
        }
      }
    }
    .el-tabs__active-bar {
      display: block !important;
    }
    .el-tabs__nav-wrap {
      &:after {
        display: block !important;
        content: "" !important;
      }
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "account-tabs:user-info": "Информация о пользователе",
    "account-tabs:account-security": "Безопасность"
  },
  "en": {
    "account-tabs:user-info": "User Information",
    "account-tabs:account-security": "Security"
  }
}
</i18n>
