<template>
  <div
    v-if="settings && settings.layer_id"
    class="sc-map-popup-item"
  >
    <div
      v-if="settings.icons && settings.colors"
      class="sc-map-popup-item__col"
    >
      <r-cblock
        :size="28"
        :color="getColor()"
        :border-color="getColor()"
      >
        <r-icon
          :name="getIcon()"
          :size="20"
          :color="getColor()"
        />
      </r-cblock>
    </div>
    <div class="sc-map-popup-item__col">
      <div class="sc-map-popup-item__title">
        <r-title type="subtitle-1">
          {{ $t(settings.layer_id) }}
        </r-title>
      </div>
      <ul
        v-if="settings.values && settings.values.length"
        class="sc-map-popup-item__list"
      >
        <li
          v-for="item in settings.values"
          :key="item.key"
          class="sc-map-popup-item__item"
        >
          <r-text
            v-if="item.value && item.key !== `pdk_cams`"
            type="caption"
          >
            {{ $t(item.key) }}
          </r-text>
          <r-text v-if="item.value && item.key === `avg_speed`">
            {{ $t(item.value) }} {{ $t(`km/h`) }}
          </r-text>
          <r-text v-if="item.value && item.key === `distance`">
            {{ $t(item.value) }} {{ $t(`m`) }}
          </r-text>
          <r-text
            v-if="
              item.value &&
                item.key !== `pdk_cams` &&
                item.key !== `distance` &&
                item.key !== `avg_speed`
            "
          >
            {{ $t(item.value) }}
          </r-text>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    settings: {
      type: Object,
      required: true,
      default: () => null
    }
  },
  methods: {
    getColor() {
      let type = ''

      switch (this.settings.layer_id) {
        default:
          type = this.settings.type || 'default'
          break
      }

      return this.settings.colors[type] || this.settings.colors.default
    },
    getIcon() {
      const type = this.settings.type === 2 ? 'default' : this.settings.type
      return this.settings.icons[type] || this.settings.icons.default
    }
  }
}
</script>

<style lang="scss">
.sc-map-popup-item {
  display: flex;
  &__col {
    width: 60px;
    overflow: hidden;
    &:last-child {
      width: calc(100% - 60px);
      text-align: start;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    div {
      text-align: end;
      text-overflow: unset;
      &:first-child {
        flex-shrink: 0;
        margin-right: 8px;
      }
    }
  }

  &__key {
    margin-right: 16px;
  }
  &__value {
    font-weight: bold;
  }
}
.r_medium {
  .sc-map-popup-item {
    &__col {
      width: 50px;
      &:last-child {
        width: calc(100% - 50px);
      }
    }

    &__item {
      div {
        text-align: end;
        &:first-child {
          margin-right: 8px;
        }
      }
    }

    &__key {
      margin-right: 12px;
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "true": "Работает",
    "false": "Не работает",
    "location": "Адрес",
    "type": "Тип",
    "code": "Код",
    "phase": "Фаза",
    "status": "Статус",
    "mode": "Мод",
    "report": "Отчет",
    "voltage": "Вольтаж",
    "reg_number": "Рег.номер",
    "organization": "Организация",
    "event_class": "Тип события",
    "ДТП": "ДТП",
    "Мероприятия": "Мероприятие",
    "Ремонтные работы": "Дорожные работы",
    "contractor": "Контрактор",
    "contact": "Контакт",
    "contract": "Контракт",
    "period": "Период",
    "obj_id": "ИД объекта",
    "track_layer": "Трек",
    "avg_speed": "Средняя скорость",
    "distance": "Расстояние",
    "km/h": "км/ч",
    "m": "км",
    "to_time": "Время, до",
    "from_time": "Время, с"
  },
  "en": {
    "true": "Work",
    "false": "Error",
    "location": "Address",
    "type": "Type",
    "code": "Code",
    "phase": "Phase",
    "status": "Status",
    "mode": "Mode",
    "report": "Report",
    "voltage": "Voltage",
    "reg_number": "Reg.number",
    "organization": "Organization",
    "event_class": "Event type",
    "ДТП": "Accident",
    "Мероприятия": "Event",
    "Ремонтные работы": "Road work",
    "contractor": "Contractor",
    "contact": "Contact",
    "contract": "Contract",
    "period": "Period",
    "obj_id": "Object ID",
    "track_layer": "Track",
    "avg_speed": "Average speed",
    "distance": "Distance",
    "km/h": "km/h",
    "m": "km",
    "to_time": "To",
    "from_time": "From"
  }
}
</i18n>
