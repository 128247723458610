<template>
  <div class="odd-router-create">
    <r-panel-header title="Построить маршрут" />
    <div class="odd-router-create__controls">
      <r-button @click="cancel">
        Выйти без сохранения
      </r-button>
      <r-button
        type="success"
        :loading="saving"
        :disabled="isDisabled"
        @click="save"
      >
        Сохранить маршрут
      </r-button>
    </div>
    <div class="odd-router-create__attributes">
      <r-title type="subtitle-2">
        Информация о маршруте
      </r-title>
      <div class="odd-router-create__attribute">
        <r-text type="caption">
          Название маршрута
        </r-text>
        <el-input
          v-model="route.name"
          class="r-input"
          size="mini"
        />
      </div>
      <div class="odd-router-create__attribute">
        <r-text type="caption">
          Цвет маршрута
        </r-text>
        <el-select
          v-model="route.color"
          class="r-select"
          placeholder="Выбрать цвет"
          size="mini"
          value-key="value"
        >
          <template slot="prefix">
            <span
              class="odd-router-create__color"
              :style="`background-color: ${route.color}`"
            />
          </template>
          <el-option
            v-for="color in colors"
            :key="color.value"
            :value="color.value"
          >
            <span
              class="odd-router-create__color"
              :style="`background-color: ${color.value}`"
            />
          </el-option>
        </el-select>
      </div>
    </div>
    <create-help />
  </div>
</template>

<script>
import createHelp from '@/components/odd/router/create-help'
import { routeColors as colors } from '@/config/colors'

export default {
  components: {
    createHelp
  },
  data() {
    return {
      route: {
        name: '',
        color: '#B532F2'
      },
      saving: false,
      colors
    }
  },
  computed: {
    geometry() {
      return this.$store.state.odd.routerState.geometry
    },
    isDisabled() {
      const { name, color } = this.route

      if (!name || !color || !this.geometry) return true

      return false
    }
  },
  methods: {
    cancel() {
      this.$router.push('/app/odd/router/view')
    },
    async save() {
      try {
        this.saving = true

        const url = 'objectInfo/telemetry.user_routes'
        const data = {
          ...this.route,
          geom: this.geometry
        }

        await this.$store.dispatch('POST_REQUEST', { url, data })
        this.cancel()
      } catch (e) {
        console.error(e)
      } finally {
        this.saving = false
      }
    }
  }
}
</script>

<style lang="scss">
.odd-router-create {
  &__controls {
    padding: 8px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--dividers_low_contrast) !important;

    .r-button {
      margin: 0 !important;

      &:not(:first-child) {
        margin-top: 8px !important;
      }
    }
  }

  &__attributes {
    padding: 8px;
    border-bottom: 1px solid var(--dividers_low_contrast) !important;
  }

  &__attribute {
    &:not(:first-child) {
      margin-top: 8px;
    }

    .r-text {
      margin-bottom: 4px;
    }

    .r-select {
      width: 100%;

      .el-input__prefix {
        width: 80%;
        margin-left: 3px;
        margin-top: 7px;
      }
    }
  }

  &__color {
    width: 100%;
    height: 24px;
    display: block;
    border-radius: var(--border-radius);
  }
}
</style>
