<template>
  <div class="bdd-application-list-header">
    <div class="bdd-application-list-header-row">
      <div class="bdd-application-list-header__title">
        <r-title>Заявки на осмотр ТС</r-title>
      </div>
      <div class="bdd-application-list-header__create">
        <r-button
          type="primary"
          @click="createNew"
        >
          Создать заявку
        </r-button>
      </div>
      <div class="bdd-application-list-header__grouping">
        <r-button
          simple
          :active="grouping"
          @click="$emit('switchGrouping')"
        >
          Группировка
        </r-button>
        <export-button
          name="Заявки на осмотр ТС"
          :uri="exportUrl"
        />
      </div>
    </div>
    <div class="bdd-application-list-header-row tools">
      <div class="bdd-application-list-header__search">
        <r-search-input
          :filter-text="filterText"
          @change="searchChange"
        />
      </div>
      <app-table-filters :filters="filters" />
    </div>
  </div>
</template>

<script>
import appTableFilters from './app-table-filters'
import exportButton from '@/components/export-button'
import { exportConfig } from '../../configs'
import { makeExportUrl } from '@/utils'

export default {
  components: { appTableFilters, exportButton },
  props: {
    filterText: {
      type: String,
      default: ''
    },
    grouping: {
      type: Boolean,
      default: () => true
    },
    filters: {
      type: Array,
      required: true
    },
    uri: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      exportUrl: ''
    }
  },
  watch: {
    uri() {
      this.initUrl()
    }
  },
  mounted() {
    this.initUrl()
  },
  methods: {
    initUrl() {
      this.exportUrl = makeExportUrl(this.uri, exportConfig)
    },
    searchChange(e) {
      this.$emit('handle-search-filter', e)
    },
    createNew() {
      this.$store.commit('BDD_SET_FIELD', {
        field: 'modalName',
        value: 'appPreCreateModal'
      })
      this.$store.commit('OPEN_MODAL_WINDOW', 'bdd-applications-modal')
    }
  }
}
</script>

<style lang="scss">
.bdd-application-list-header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: var(--bg_panel_primary);

  &__grouping {
    justify-self: end;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
  }

  &-row {
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-template-columns: max-content auto auto;
    padding: 0.5rem 1rem;
    grid-gap: 2rem;
    border-bottom: 1px solid var(--dividers_low_contrast);

    &.tools {
      grid-gap: 0.5rem;
      padding: 0 0 0 1rem;
      grid-template-columns: 16rem 1fr;
    }
  }
}
</style>
