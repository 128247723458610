<template>
  <div class="ts-legend">
    <div class="ts-legend__block">
      <div class="ts-legend__header">
        <r-text type="caption">
          Скорость, км/ч
        </r-text>
      </div>
      <ul class="ts-legend__list">
        <li
          v-for="color in colors"
          :key="color.color"
          class="ts-legend__item"
        >
          <div
            class="ts-legend__key ts-legend__key--color"
            :style="`background-color: ${color.color}`"
          />
          <div class="ts-legend__value">
            <r-text>
              {{ color.value }}
            </r-text>
          </div>
        </li>
      </ul>
    </div>
    <div class="ts-legend__block">
      <div class="ts-legend__header">
        <r-text type="caption">
          Интенсивность, ТС
        </r-text>
      </div>
      <ul class="ts-legend__list">
        <li
          v-for="width in widths"
          :key="width.width"
          class="ts-legend__item"
        >
          <div
            class="ts-legend__key ts-legend__key--width"
            :style="`height: ${width.width}px`"
          />
          <div class="ts-legend__value">
            <r-text>
              {{ width.value }}
            </r-text>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
const colors = [
  { color: 'rgba(213,62,79, 1)', value: '< 5' },
  { color: 'rgba(252,141,89, 1)', value: '5 - 15' },
  { color: 'rgba(254,224,139, 1)', value: '15 - 30' },
  { color: 'rgba(153,213,148, 1)', value: '> 30' }
]
const widths = [
  { width: '2', value: '0 - 14' },
  { width: '4', value: '14 - 22' },
  { width: '6', value: '> 22' }
]

export default {
  data() {
    return {
      colors,
      widths
    }
  }
}
</script>

<style lang="scss">
.ts-legend {
  padding-top: 4px;
  display: flex;

  &__block {
    width: 50%;

    &:not(:first-child) {
      margin-left: 4px;
    }
  }

  &__header {
    margin-bottom: 4px;
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-top: 4px;
    }
  }

  &__key {
    width: 20px;
    height: 6px;
    border-radius: var(--border-radius);

    &--width {
      background-color: var(--text_subhead);
    }
  }

  &__value {
    margin-left: 8px;
  }
}
</style>
