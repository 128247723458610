<template>
  <div class="m-layer-settings">
    <component
      :is="`${layerType}-settings`"
      :id="settingsId"
    />
  </div>
</template>

<script>
import LinksSettings from './types/links-settings'
import NodesSettings from './types/nodes-settings'
import ZonesSettings from './types/zones-settings'
import EventsSettings from './types/events-settings'

export default {
  components: {
    LinksSettings,
    NodesSettings,
    ZonesSettings,
    EventsSettings
  },
  computed: {
    settingsId() {
      return this.$store.state.modelling.settingsId
    },
    layerType() {
      const { model } = this.$store.state.modelling
      const layer = model.children.find(l => l.id === this.settingsId)

      return layer ? layer.datatype : null
    }
  }
}
</script>

<style lang="scss">
.m-layer-settings {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>
