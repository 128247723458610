import cloneDeep from 'lodash.clonedeep'

const model = {
  id: null,
  name: null,
  ids: {},
  children: []
}

const cardInfo = {
  layerId: null,
  name: '',
  properties: null
}

const editorState = {
  // type: null,
  type: 'lines',
  id: null,
  layerId: null,
  geometry: null,
  properties: null,
  isDrawing: false,
  stopGeom: null,
  routingRequesting: false,
  activeObject: null,
  routeActiveTab: 'route-info',
  lineRouteItems: [],
  lriToDelete: null,
  fixedRouteItems: [],
  routeTransport: [],
  initialRouteTransport: [],
  waypoints: []
}

const state = {
  loadingLayers: [],
  panelActiveTab: 'routes',
  flyToGeom: null,
  lists: {
    stop_points: [],
    lines: []
  },
  // card
  cardId: null,
  cardType: null,
  cardGeom: null,
  vehicles: [],
  filteredVehicles: null,
  // line schedule
  lineRouteId: null,
  // router
  routeStops: null,
  userRoute: null,
  extremePoints: null,
  selectedStop: null,
  routeBbox: null,
  activeRoute: null,
  popup: {
    from: null,
    to: null
  },
  // --
  timeProfile: null,
  vehicleJourneys: null,
  vehicleVisibility: true,
  lineRouteItems: null,
  needToUpdateSchedule: false,
  needToUpdateLists: false,
  model: cloneDeep(model),
  cardInfo: cloneDeep(cardInfo),
  cardInfoCache: cloneDeep(cardInfo),
  editorState
}

const mutations = {
  SET_PT_FIELD(state, { field, value }) {
    state[field] = value
  },
  SET_PT_MODEL(state, { id, name, ids, children }) {
    state.model.id = id
    state.model.name = name
    state.model.ids = ids
    state.model.children = children
  },
  ADD_PT_LOADING_LAYER(state, id) {
    if (state.loadingLayers.indexOf(id) === -1) state.loadingLayers.push(id)
  },
  REMOVE_PT_LOADING_LAYER(state, id) {
    state.loadingLayers.splice(state.loadingLayers.indexOf(id), 1)
  },
  SET_PT_LIST(state, { type, list }) {
    state.lists[type] = list
  },
  SET_PT_CARD_INFO(state, info) {
    Object.keys(info).forEach(key => {
      state.cardInfo[key] = info[key]
    })
    state.cardInfoCache = cloneDeep(state.cardInfo)
  },
  CLEAR_PT_CARD_INFO(state) {
    state.cardId = null
    state.cardGeom = null
    state.lineRouteItems = null
    state.filteredVehicles = null

    Object.keys(cardInfo).forEach(key => {
      state.cardInfo[key] = cardInfo[key]
    })
    state.cardInfoCache = cloneDeep(state.cardInfo)
  },
  SET_PT_EDITOR_STATE(state, info) {
    Object.keys(info).forEach(key => {
      state.editorState[key] = info[key]
    })
  },
  SET_PT_EDITOR_PROP(state, { field, value }) {
    state.editorState[field] = value
  },
  SET_PT_CREATED_PROPERTY(state, { field, value }) {
    state.editorState.properties[field] = value
  },
  CLEAR_PT_EDITOR_STATE(state) {
    Object.keys(editorState).forEach(key => {
      state.editorState[key] = editorState[key]
    })

    state.editorState.lineRouteItems = []
    state.editorState.waypoints = []
    state.editorState.lineRouteId = null
    state.editorState.routeTransport = []
    state.editorState.routeActiveTab = 'route-info'
  },
  TOGGLE_PT_FIXED_ROUTE_ITEM(state, item) {
    const index = state.editorState.fixedRouteItems.findIndex(
      r => r.stop_point_id === item.stop_point_id
    )

    if (index > -1) {
      state.editorState.fixedRouteItems.splice(index, 1)
    } else {
      state.editorState.fixedRouteItems.push(item)
    }
  },
  CLEAR_PT_FIXED_ROUTE_ITEMS(state) {
    state.editorState.fixedRouteItems.splice(0)
  }
}

export default {
  state,
  mutations
}
