import { Constants, CommonSelectors } from '@/libs/map-draw/lib-utils'
import {
  getFeaturesByLayerId,
  getStopPointsData,
  createStopPointsHelpers,
  removeStopPointsHelpers,
  removeHoveredSPHelpers,
  nearestPointOnLine,
  isPointInLinkArea
} from '@/libs/map-draw/helpers'

export const createLinkSpliceMode = (MapboxDraw) => {
  const LinkSplice = {}
  const customState = {
    linkId: '',
    currentPoint: null,
    point: null
  }

  const LAYER_ID = 'gl-draw-line-inactive.cold'

  LinkSplice.onTap = LinkSplice.onClick = function(state) {
    const {
      linkId,
      currentPoint
    } = customState

    if (linkId && currentPoint) {
      const { coordinates } = currentPoint

      this.updateUIClasses({ mouse: Constants.cursors.MOVE })
      state.point.updateCoordinate('', coordinates[0], coordinates[1])

      // set properties
      state.point.setProperty('link_id', linkId)
      state.point.setProperty('type', 'splitLink')

      removeStopPointsHelpers(this.map)

      this.map.fire(Constants.events.CREATE, {
        features: [state.point.toGeoJSON()]
      })

      // change mode
      this.changeMode(Constants.modes.SIMPLE_SELECT, {
        featureIds: [state.point.id]
      })
    }
  }

  LinkSplice.onMouseMove = function(state, e) {
    const { lng, lat } = e.lngLat
    const linksFeatures = getFeaturesByLayerId(this.map, e, LAYER_ID, 50)

    customState.point = e.point

    if (linksFeatures.length) {
      this.map.getCanvas().style.cursor = ''

      const link = linksFeatures.find(l =>
        isPointInLinkArea([lng, lat], l.geometry.coordinates, 50)
      )

      if (link) {
        removeHoveredSPHelpers(this.map)
        const nearest = nearestPointOnLine(
          [lng, lat],
          link.geometry.coordinates
        )
        const current = {
          geometry: {
            type: 'Point',
            coordinates: [lng, lat]
          },
          properties: {
            projection: 'true'
          }
        }
        const connection = {
          geometry: {
            type: 'LineString',
            coordinates: [nearest.geometry.coordinates, [lng, lat]]
          }
        }

        const { id } = link.properties

        customState.linkId = id
        customState.currentPoint = nearest.geometry

        const data = getStopPointsData(nearest, current, connection)

        createStopPointsHelpers(this.map, data)
      } else {
        removeStopPointsHelpers(this.map)
        customState.linkId = ''
        customState.currentPoint = null
      }
    } else {
      this.map.getCanvas().style.cursor = ''
      removeStopPointsHelpers(this.map)
      customState.linkId = ''
      customState.currentPoint = null
    }
  }

  LinkSplice.onKeyUp = function(state, e) {
    if (CommonSelectors.isEscapeKey(e)) {
      this.stopDrawingAndRemove(state, e)

      this.changeMode('link_splice')
    }
  }

  return { ...MapboxDraw.modes.draw_point, ...LinkSplice }
}
