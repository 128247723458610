import mapboxgl from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'

import { mapToken } from '@/constants/tokens'
import { MapConfigEvents, getMapConfigs } from '@/utils'
import { ModesController } from '@/libs/map-draw/modes/modes-controller'
import { oddEditorStyles } from '@/libs/map-draw/config'
import {
  ModelController,
  BaseController,
  EditorController,
  RouterController
} from '../controllers'
import { loadIcons } from '../helpers'

export const initMap = component => {
  // map creating
  const { center, zoom, baselayer } = getMapConfigs(
    component,
    'vehicle_inspection_sheets'
  )

  mapboxgl.accessToken = mapToken

  component.mapgl = new mapboxgl.Map({
    container: 'odd-map',
    style: baselayer,
    preserveDrawingBuffer: true,
    center,
    zoom
  })

  const { controllers, mapgl } = component

  // view handlers
  mapgl.on('pitch', e => {
    component.is3d = e.target.getPitch() !== 0
  })
  mapgl.on('rotate', e => {
    component.mapBearing = e.target.getBearing()
  })

  // controllers
  controllers.events = new MapConfigEvents(
    component,
    'vehicle_inspection_sheets'
  )
  controllers.model = new ModelController(component)
  controllers.base = new BaseController(component)
  controllers.router = new RouterController(component)

  // map init
  mapgl.on('load', async() => {
    component.loading = true
    await controllers.model.loadModel()
    await loadIcons(component, component.$store.state.odd.books.signIcons)
    controllers.events.addEventHandler()
    controllers.base.addBaseLayers()
    controllers.router.addRoutesLayer()
    component.loading = false

    // map draw init
    const mc = new ModesController(component, MapboxDraw, 'editorState')
    component.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        line_string: true,
        point: true,
        trash: true
      },
      styles: oddEditorStyles,
      userProperties: true,
      modes: Object.assign(MapboxDraw.modes, {
        simple_select: mc.createODDSimpleSelectMode(),
        direct_select: mc.createODDDirectSelectMode(),
        draw_signs: mc.createODDDrawSignsMode(),
        draw_points: mc.createODDDrawPointsMode(),
        draw_lines: mc.createODDDrawLinesMode()
      })
    })

    controllers.editor = new EditorController(component)
    component.$emit('map-ready')
  })

  window.addEventListener('beforeunload', component.saveMapParams)
}
