export class StyleController {
  constructor(parent) {
    this.parent = parent
    this.$store = parent.$store
    this.mapgl = parent.mapgl
  }

  changePaintProperty(id, field) {
    const { stylesConfigs } = this.$store.state.modelling
    const config = stylesConfigs[id]
    const { paint } = config.style
    const value = paint[field]

    this.mapgl.setPaintProperty(id, field, value)
  }

  changePolygonOutlineProperty(id, field) {
    const layerId = `${id}_border`
    if (!this.mapgl.getLayer(layerId)) return

    const { stylesConfigs } = this.$store.state.modelling
    const config = stylesConfigs[id]
    const { paint } = config.borderStyle
    const value = paint[field]

    this.mapgl.setPaintProperty(layerId, field, value)
  }
}
