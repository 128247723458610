import vue from 'vue'

const state = {
  activeId: '',
  reportUrl: null,
  selectedItem: null,
  reportName: null,
  selectedDate: null,
  servicesData: null,
  modalTab: 'selection',
  report: null,
  workspaceType: 'table',
  showExport: false,
  data: null,
  dataLength: null,
  flyToGeom: null,
  activeObject: null
}

const mutations = {
  ANALYTICS_SET_FIELD(state, { field, value }) {
    vue.set(state, field, value)
  }
}

export default {
  state,
  mutations
}
