import vue from 'vue'

const state = {
  activeId: ''
}

const mutations = {
  REPORTS_SET_ACTIVE_ID(state, id) {
    vue.set(state, 'activeId', id)
  }
}

export default {
  state,
  mutations
}
