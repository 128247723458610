<template>
  <ul class="mt-card-attributes">
    <li class="mt-card-attributes__item">
      <div class="mt-card-attributes__cell mt-card-attributes__cell--label">
        <r-text type="caption">
          Проект
        </r-text>
      </div>
      <div class="mt-card-attributes__cell mt-card-attributes__cell--value">
        <r-text>
          {{ getProjectLabel() }}
        </r-text>
      </div>
    </li>
    <li
      v-for="item in attributesConfig"
      :key="item.prop"
      class="mt-card-attributes__item"
    >
      <div class="mt-card-attributes__cell mt-card-attributes__cell--label">
        <r-text type="caption">
          {{ item.title }}
        </r-text>
      </div>
      <div class="mt-card-attributes__cell mt-card-attributes__cell--value">
        <r-text>
          {{ getAttributeValue(item) }}
        </r-text>
      </div>
    </li>
  </ul>
</template>

<script>
import { etranAttributesConfig, vehicleAttributesConfig, bargesAttributesConfig } from './configs/'

export default {
  props: {
    info: {
      type: Object,
      required: true
    },
    fields: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'Легковой'
    }
  },
  data() {
    return {
      etranAttributesConfig,
      vehicleAttributesConfig,
      bargesAttributesConfig
    }
  },
  computed: {
    attributesConfig() {
      console.log(this.type)
      switch (this.type) {
        case 'ЖД':
          return this.etranAttributesConfig
        case 'Баржа':
          return this.bargesAttributesConfig
        case 'Грузовые':
          return this.vehicleAttributesConfig
        default:
          return null
      }
    }
  },
  methods: {
    getProjectLabel() {
      return 'Не указан'
    },
    getAttributeValue({ prop, mainProp, nestedProp, nesterPropName, type }) {
      const dateformat = 'DD.MM.YYYY'

      if (nesterPropName) {
        if (type === 'datetime') {
          if (nesterPropName === 'cargoes') {
            const actual = this.info?.[nesterPropName]?.reduce((a, c) => {
              if (c.receiving_date_fact) return a

              const value = mainProp && c?.[mainProp] ? c?.[mainProp] : c?.[prop]

              if (value) {
                if (!a) {
                  a = value
                } else if (this.$rDate.format(a, 'x') > this.$rDate.format(value, 'x')) {
                  a = value
                }
              }

              return a
            }, '')

            return this.$rDate.format(actual, dateformat)
          } else {
            return this.$rDate.format(this.info[nesterPropName]?.[0]?.[prop], dateformat)
          }
        } else {
          return this.info[nesterPropName]?.[0]?.[prop]
        }
      }

      if (nestedProp) {
        if (nestedProp === 'cargoes') {
          return this.info?.[nestedProp]?.reduce((a, c) => {
            if (c.receiving_date_fact) return a

            const value = c?.[prop]?.name

            if (value && !a.includes(value)) {
              a.push(value)
            }

            return a
          }, [])?.join(', ')
        } else {
          return this.info[nestedProp]?.[0]?.[prop]?.name
        }
      }

      const dates = ['arrival_forecast', 'departure_date', 'arrival_date', 'delivery_forecast']

      if (dates.includes(prop)) {
        return this.$rDate.format(this.info[prop], dateformat)
      }

      return this.info[prop]
    }
  }
}
</script>

<style lang="scss" scoped>
.mt-card-attributes {
  &__item {
    display: flex;
    align-items: center;
    border: 1px solid var(--dividers_low_contrast);
    border-bottom: none;
    min-height: 32px;

    &:last-child {
      border-bottom: 1px solid var(--dividers_low_contrast);
    }
  }

  &__cell {
    display: flex;
    align-items: center;
    min-height: 32px;
    height: 100%;
    width: 50%;
    padding: 4px 8px;

    &:last-child {
      border-left: 1px solid var(--dividers_low_contrast);
    }
  }
}
</style>
