<template>
  <ul
    v-if="type === `type-1`"
    class="sc-events-log__list"
  >
    <sc-events-log-list-item-type-1
      v-for="event in eventsData"
      :key="event.id"
      :event="event"
    />
  </ul>
  <ul
    v-else-if="type === `type-2`"
    class="sc-events-log__list"
  >
    <sc-events-log-list-item-type-2
      v-for="event in eventsData"
      :key="event.id"
      :event="event"
    />
  </ul>
</template>

<script>
import scEventsLogListItemType1 from './events-log-list-item-type-1'
import scEventsLogListItemType2 from './events-log-list-item-type-2'
import { widgetStyleConfig } from '../../../../configs'

export default {
  components: { scEventsLogListItemType1, scEventsLogListItemType2 },
  props: {
    eventsData: {
      type: Array,
      required: true,
      default: () => null
    },
    type: {
      type: String,
      default: 'type-1'
    }
  },
  data() {
    return {
      widgetStyleConfig
    }
  },
  methods: {
    getIconName(id) {
      return (
        this.widgetStyleConfig.events_log.icons[id] ||
        this.widgetStyleConfig.events_log.icons.default
      )
    },
    getColor(id) {
      return (
        this.widgetStyleConfig.events_log.colors[id] ||
        this.widgetStyleConfig.events_log.colors.default
      )
    }
  }
}
</script>

<style lang="scss">
.sc-events-log__list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 220px;
  overflow: hidden;
  overflow-y: auto;
}
</style>
