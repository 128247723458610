export const requestConfig = {
  vehicles: {
    only: [
      'id',
      'reg_number'
    ],
    where: [
      {
        field: 'categories.id',
        op: '=',
        value: 'fda33fb6-a377-42ae-a2f9-1091d56e25ce'
      }
    ]
  },
  railway: {
    only: [
      'id',
      'carriage_no'
    ],
    where: [
      {
        field: 'status_pre_id',
        op: '=',
        value: null
      }
    ]
  },
  barges: {
    only: [
      'id',
      'name'
    ]
  }
}
