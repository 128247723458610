import {
  getDiagramGeojson,
  createExtrusionRangeValue,
  createClassesValue
} from '../helpers'
import { updateObjectFieldsConfigs } from '@/utils'

export class DiagramController {
  constructor(map) {
    this.map = map
    this.mapgl = map.mapgl
    this.styleConfig = map.$store.state.map.styleConfig
    this.LayerController = map.controllers.layers
  }

  async toggleDiagram(id, { path, config }) {
    const layerId = `${id}_diagram`
    const extrusionLayerId = `${id}_diagram_extrusion`
    const { diagram } = this.styleConfig[id]

    if (path === diagram.field) {
      if (this.mapgl.getLayer(layerId)) this.mapgl.removeLayer(layerId)
      if (this.mapgl.getLayer(extrusionLayerId)) {
        this.mapgl.removeLayer(extrusionLayerId)
      }
      if (this.mapgl.getSource(layerId)) this.mapgl.removeSource(layerId)
      diagram.field = ''
      diagram.secondField = ''
      diagram.conditions = []
      diagram.extruded = false
      return
    }

    diagram.field = path

    updateObjectFieldsConfigs(this.map, id, path, config)
    await this.LayerController.updateLayerData(id, [path])
    const attribute = this.map.objectFields[id].find(f => f.title === path)
    diagram.maxValue = attribute.max
    diagram.minValue = attribute.min

    const layerSource = this.mapgl.getSource(id)
    const features = layerSource._data.features

    this.setDiagramLayer(id, features)
  }

  setDiagramLayer(id, features) {
    const { diagram } = this.styleConfig[id]
    const { color, opacity, conditions, secondField } = diagram
    const layerId = `${id}_diagram`
    const diagramGeojson = getDiagramGeojson(features, diagram)
    const mapSource = this.mapgl.getSource(layerId)
    const mapLayer = this.mapgl.getLayer(layerId)
    if (mapSource) mapSource.setData(diagramGeojson)
    else {
      this.mapgl.addSource(layerId, { type: 'geojson', data: diagramGeojson })
    }

    if (!mapLayer) {
      const fillColor =
        secondField && conditions.length
          ? createClassesValue(secondField, conditions)
          : color
      this.mapgl.addLayer({
        id: layerId,
        type: 'fill',
        source: layerId,
        paint: {
          'fill-opacity': opacity,
          'fill-color': fillColor
        }
      })
      this.mapgl.moveLayer(layerId, id)
    }

    if (diagram.extruded) {
      this.toggleDiagramExtrusion(id, true)
    }
  }

  toggleDiagramExtrusion(id, onlyAdd) {
    const layerId = `${id}_diagram`
    const extrusionLayerId = `${id}_diagram_extrusion`
    const { diagram } = this.styleConfig[id]
    const mapLayer = this.mapgl.getLayer(extrusionLayerId)
    const {
      field,
      minValue,
      maxValue,
      width,
      secondField,
      conditions,
      color,
      opacity
    } = diagram

    if (!mapLayer) {
      const fillColor =
        secondField && conditions.length
          ? createClassesValue(secondField, conditions)
          : color
      this.mapgl.addLayer({
        id: extrusionLayerId,
        type: 'fill-extrusion',
        source: layerId,
        paint: {
          'fill-extrusion-height': createExtrusionRangeValue(
            field,
            minValue,
            maxValue,
            width
          ),
          'fill-extrusion-color': fillColor,
          'fill-extrusion-opacity': opacity,
          'fill-extrusion-vertical-gradient': false
        }
      })
    } else if (!onlyAdd) this.mapgl.removeLayer(extrusionLayerId)
  }

  changeDiagramProperty(id, property) {
    const layerId = `${id}_diagram`
    const extrusionLayerId = `${id}_diagram_extrusion`
    const { diagram } = this.styleConfig[id]
    const mapSource = this.mapgl.getSource(id)
    const diagramLayer = this.mapgl.getLayer(layerId)
    const mapExtrusionLayer = this.mapgl.getLayer(extrusionLayerId)
    const { field, minValue, maxValue, width } = diagram

    if (!mapSource) return
    if (!diagramLayer) return

    const features = mapSource._data.features

    switch (property) {
      case 'color':
        this.mapgl.setPaintProperty(layerId, 'fill-color', diagram.color)
        if (mapExtrusionLayer) {
          this.mapgl.setPaintProperty(
            extrusionLayerId,
            'fill-extrusion-color',
            diagram.color
          )
        }
        break
      case 'opacity':
        this.mapgl.setPaintProperty(layerId, 'fill-opacity', diagram.opacity)
        if (mapExtrusionLayer) {
          this.mapgl.setPaintProperty(
            extrusionLayerId,
            'fill-extrusion-opacity',
            diagram.opacity
          )
        }
        break
      case 'width':
        this.setDiagramLayer(id, features)
        if (mapExtrusionLayer) {
          const value = createExtrusionRangeValue(
            field,
            minValue,
            maxValue,
            width
          )
          this.mapgl.setPaintProperty(
            extrusionLayerId,
            'fill-extrusion-height',
            value
          )
        }
        break
    }
  }

  changeDiagramClasses(id) {
    const layerId = `${id}_diagram`
    const extrusionLayerId = `${id}_diagram_extrusion`
    const { diagram } = this.styleConfig[id]
    const mapLayer = this.mapgl.getLayer(layerId)
    const mapExtrusionLayer = this.mapgl.getLayer(extrusionLayerId)
    if (!mapLayer) return

    const value = createClassesValue(diagram.secondField, diagram.conditions)

    this.mapgl.setPaintProperty(layerId, 'fill-color', value)
    if (mapExtrusionLayer) {
      this.mapgl.setPaintProperty(
        extrusionLayerId,
        'fill-extrusion-color',
        value
      )
    }
  }
}
