<template>
  <div :class="[`r-main-panel ${bgType}`]">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    bgType: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
.r-main-panel {
  height: 100%;
  width: 260px;
  box-shadow: 2px 0 10px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  background-color: var(--bg_panel_primary) !important;

  &.secondary {
    background-color: var(--bg_panel_secondary) !important;
  }
}
</style>
