<template>
  <r-tabs
    v-model="activeType"
    class="journal-main"
  >
    <r-tab
      v-for="type in types"
      :id="type.id"
      :key="type.id"
      :name="type.label"
    >
      <journal-list :events="events" />
    </r-tab>
  </r-tabs>
</template>

<script>
import JournalList from '../journal-list'
import { getEventsData } from '../helpers'

const types = [
  { id: 'car_event', label: 'Транспорт', uri: 'user?format=car_events_notifications' },
  { id: 'system_event', label: 'Системные', uri: 'user?format=system_notifications' },
  { id: 'cameras', label: 'Камеры', uri: 'user?format=traffic_notifications' }
]

export default {
  components: {
    JournalList
  },
  data() {
    return {
      loading: false,
      activeType: 'car_event',
      types
    }
  },
  computed: {
    events() {
      return this.$store.state.journal.lists[this.activeType]
    }
  },
  watch: {
    '$store.state.journal.updateList'(val) {
      if (!val) return
      this.loadEvents(val)
      this.$store.commit('SET_JOURNAL_FIELD', ['updateList', null])
    }
  },
  methods: {
    async loadEvents(type) {
      try {
        this.$emit('toggle-loading', true)

        const url = types?.find(t => t.id === type)?.uri || type
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        this.$store.commit('SET_JOURNAL_LIST', [
          type,
          getEventsData(Object.values(data), type)
        ])
      } catch (error) {
        console.log(error)
      } finally {
        this.$emit('toggle-loading', false)
      }
    }
  }
}
</script>

<style lang="scss">
.journal-main {
  &__header {
    display: grid;
    grid-gap: 0.5rem;
  }
}
</style>
