<template>
  <div :class="['r-list-item', { active: isActive }, { loading: isLoading }]">
    <div class="r-list-item__col icon-col">
      <r-icon
        v-if="item.icon && !isLoading"
        :name="item.icon"
        :size="24"
      />
      <r-text v-else-if="item.icon && isLoading">
        <span class="el-icon-loading" />
      </r-text>
    </div>
    <div class="r-list-item__col title-col">
      <div class="r-list-item__row title">
        <r-text
          type="caption"
          color-type="primary"
        >
          {{ item.title }}
        </r-text>
      </div>
    </div>
    <div
      v-if="tools && tools.length"
      class="r-list-item__controls"
    >
      <div class="r-list-item__controls-wrapper">
        <el-tooltip
          v-for="tool in tools"
          :key="tool.id"
          :open-delay="1000"
          :content="tool.title"
          placement="top"
        >
          <div
            class="r-list-item__controls-item"
            @click="e => clickHandler(e, tool.title, item)"
          >
            <r-icon
              :name="tool.icon"
              :size="24"
              color-type="white"
            />
          </div>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
      required: true
    },
    tools: {
      type: Array,
      default: null
    },
    activeList: {
      type: Array,
      default: () => []
    },
    loadingList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isActive() {
      return this.activeList.includes(this.item.id)
    },
    isLoading() {
      return this.loadingList.includes(this.item.id)
    }
  },
  methods: {
    clickHandler(e, tool, item) {
      e.stopPropagation()
      this.$emit('click-handler', { tool, item })
    }
  }
}
</script>

<style lang="scss">
.r-list-item {
  position: relative;
  display: flex;
  border-radius: var(--border-radius);
  overflow: hidden;
  padding: 0 0.5rem;
  cursor: pointer;
  min-height: 34px;

  &:hover {
    background-color: var(--bg_containers) !important;
  }
  &__controls {
    display: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 30px;
    right: 0;
    border-radius: var(--border-radius);
    overflow: hidden;

    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--modal_bg) !important;
      > div {
        margin-right: 4px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &:hover {
    .r-list-item {
      &__controls {
        display: flex;
      }
    }
  }
  &__col {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    &.icon-col {
      width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 4px;
      .el-icon-loading {
        width: 16px;
        height: 16px;
      }
    }
    &.title-col {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__row {
    width: 100%;
    .r-text {
      text-align: start;
    }
    &.title {
      .r-text {
        min-height: 16px;
        width: calc(100% - 8px);
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: auto;
      }
    }
    &.subtitle {
      margin-top: 2px;
    }
  }
}
div.r-list-item.active {
  background-color: var(--accent_selected) !important;

  .r-list-item__controls {
    &-wrapper {
      background-color: var(--accent_primary) !important;
    }
  }
}
</style>
