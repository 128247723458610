<template>
  <component
    :is="componentName"
    @close="close"
    @view="enableViewMode"
    @update="update"
  />
</template>

<script>
import appMainModal from './main/modal'
import appVehicleCreateModal from './app-vehicle-create-modal'
import appPreCreateModal from './app-pre-create-modal'

export default {
  components: {
    appMainModal,
    appVehicleCreateModal,
    appPreCreateModal
  },
  computed: {
    componentName() {
      return this.$store.state.bdd.modalName || 'appMainModal'
    }
  },
  methods: {
    close() {
      this.$store.commit('CLOSE_MODAL_WINDOW')
    },
    update() {
      this.$store.commit('BDD_SET_FIELD', {
        field: 'updateApplications',
        value: true
      })
    },
    enableViewMode() {
      this.$store.commit('BDD_SET_FIELD', {
        field: 'applicationModalMode',
        value: null
      })
    }
  }
}
</script>
