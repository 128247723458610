// Line Offset Turf lib, modified and saved locally
import { flattenEach } from '@turf/meta'
import { isObject, lineString, multiLineString } from '@turf/helpers'

function lineOffset(geojson, distance, options) {
  options = options || {}
  if (!isObject(options)) throw new Error('options is invalid')
  // const units = options.units

  // Valdiation
  if (!geojson) throw new Error('geojson is required')
  if (distance === undefined || distance === null || isNaN(distance)) { throw new Error('distance is required') }

  const type = getType(geojson, '')
  const properties = geojson.properties
  const coords = []

  switch (type) {
    case 'LineString':
      return lineOffsetFeature(geojson, distance)
    case 'MultiLineString':
      flattenEach(geojson, function(feature) {
        coords.push(
          lineOffsetFeature(feature, distance).geometry.coordinates
        )
      })
      return multiLineString(coords, properties)
    default:
      throw new Error('geometry ' + type + ' is not supported')
  }
}

function lineOffsetFeature(line, distance) {
  const segments = []
  // const offsetDegrees = lengthToDegrees(distance, units);
  const offsetDegrees = distance
  const coords = getCoords(line)
  const finalCoords = []
  coords.forEach(function(currentCoords, index) {
    if (index !== coords.length - 1) {
      const segment = processSegment(
        currentCoords,
        coords[index + 1],
        offsetDegrees
      )
      segments.push(segment)
      if (index > 0) {
        const seg2Coords = segments[index - 1]
        const intersects = intersection(segment, seg2Coords)

        // Handling for line segments that aren't straight
        if (intersects !== false) {
          seg2Coords[1] = intersects
          segment[0] = intersects
        }

        finalCoords.push(seg2Coords[0])
        if (index === coords.length - 2) {
          finalCoords.push(segment[0])
          finalCoords.push(segment[1])
        }
      }
      // Handling for lines that only have 1 segment
      if (coords.length === 2) {
        finalCoords.push(segment[0])
        finalCoords.push(segment[1])
      }
    }
  })

  let flag = false

  for (let i = 0; i < finalCoords.length; i++) {
    const coord = finalCoords[i]

    if (isNaN(coord[0]) || isNaN(coord[1])) {
      flag = true
      break
    }
  }

  return flag ? null : lineString(finalCoords, line.properties)
}

function processSegment(point1, point2, offset) {
  const L = Math.sqrt(
    (point1[0] - point2[0]) * (point1[0] - point2[0]) +
      (point1[1] - point2[1]) * (point1[1] - point2[1])
  )

  const out1x = point1[0] + (offset * (point2[1] - point1[1])) / L
  const out2x = point2[0] + (offset * (point2[1] - point1[1])) / L
  const out1y = point1[1] + (offset * (point1[0] - point2[0])) / L
  const out2y = point2[1] + (offset * (point1[0] - point2[0])) / L
  return [[out1x, out1y], [out2x, out2y]]
}

function intersection(a, b) {
  if (isParallel(a, b)) return false
  return intersectSegments(a, b)
}

function isParallel(a, b) {
  const r = ab(a)
  const s = ab(b)
  return crossProduct(r, s) === 0
}

function ab(segment) {
  const start = segment[0]
  const end = segment[1]
  return [end[0] - start[0], end[1] - start[1]]
}

function crossProduct(v1, v2) {
  return v1[0] * v2[1] - v2[0] * v1[1]
}

function intersectSegments(a, b) {
  const p = a[0]
  const r = ab(a)
  const q = b[0]
  const s = ab(b)

  const cross = crossProduct(r, s)
  const qmp = sub(q, p)
  const numerator = crossProduct(qmp, s)
  const t = numerator / cross
  const intersection = add(p, scalarMult(t, r))
  return intersection
}

function add(v1, v2) {
  return [v1[0] + v2[0], v1[1] + v2[1]]
}

function sub(v1, v2) {
  return [v1[0] - v2[0], v1[1] - v2[1]]
}

function scalarMult(s, v) {
  return [s * v[0], s * v[1]]
}

function getCoords(coords) {
  if (!coords) throw new Error('coords is required')
  // Feature
  if (coords.type === 'Feature' && coords.geometry !== null) { return coords.geometry.coordinates }
  // Geometry
  if (coords.coordinates) return coords.coordinates
  // Array of numbers
  if (Array.isArray(coords)) return coords
  throw new Error(
    'coords must be GeoJSON Feature, Geometry Object or an Array'
  )
}

export function getType(geojson, name) {
  if (!geojson) throw new Error((name || 'geojson') + ' is required')
  // GeoJSON Feature & GeometryCollection
  if (geojson.geometry && geojson.geometry.type) return geojson.geometry.type
  // GeoJSON Geometry & FeatureCollection
  if (geojson.type) return geojson.type
  throw new Error((name || 'geojson') + ' is invalid')
}

export default lineOffset
