export const fieldsSortBy = {
  forecast_delivery: { order: 1 },
  reg_number: { order: 2 },
  vrc_full_name: { order: 3 },
  driver_full_name: { order: 4 },
  driver_phone: { order: 5 },
  cargo_type: { order: 6 }
}

export const fieldsExplanation = {
  name: 'Наименование',
  number: 'Номер',
  forecast_delivery: 'Прогноз. дата прибытия',
  arrival_forecast: 'Прогнозная дата ЖД',
  delivery_forecast: 'Прогнозная дата Груз',
  reg_number: 'ГРЗ',
  driver_full_name: 'Фио водителя',
  driver_phone: 'Телефон водителя',
  contractor: 'Подрядчик',
  cargo_type: 'Тип груза',
  comment: 'Комментарий',
  pot_status_id: 'Статус',
  nomenclature_group: 'Группа номенклатуры',
  packing_list_no: '№ Упаковочного листа',
  subproject: 'Подпроект',
  comments: 'Комментарий',
  carriage_no: 'Номер вагона',
  current_station: 'Текущая станция',
  destination_station: 'Станция назначения',
  sender: 'Поставщик',
  waybill_no: 'Номер накладной'
}

export const columns = {
  vehicles: [
    'driver_full_name',
    'driver_phone',
    'reg_number',
    'forecast_delivery',
    'contractor',
    'nomenclature_group',
    'packing_list_no',
    'subproject',
    'comments'
  ],
  railway: [
    'carriage_no',
    'current_station',
    'destination_station',
    'waybill_no',
    'comments',
    'arrival_forecast',
    'delivery_forecast',
    'sender',
    'packing_list_no',
    'subproject'
  ],
  barges: [
    'name',
    'number',
    'packing_list_no',
    'sender',
    'subproject',
    'comments'
  ],
  default: [
    'no',
    'comments'
  ]
}

export const related = {
  vehicle_types: {
    id: 1,
    urlName: 'vehicleTypes',
    data: [],
    config: { only: ['name', 'id'] }
  },
  main_contractor_id: {
    id: 2,
    urlName: 'mainContractor',
    data: [],
    config: { only: ['name', 'id'] }
  },
  vehicle_models: {
    id: 3,
    urlName: 'vehicleModels',
    data: [],
    config: { only: ['name', 'id'] }
  },
  checkpoint_id: {
    id: 4,
    urlName: 'checkpoints',
    data: [],
    config: { only: ['name', 'id'] }
  },
  warehouse_id: {
    id: 5,
    urlName: 'warehouses',
    data: [],
    config: { only: ['name', 'id'] }
  },
  vehicle_categories: {
    id: 7,
    urlName: 'vehicleCategories',
    data: [],
    config: { only: ['name', 'id'] }
  },
  vehicles: {
    id: 6,
    urlName: 'vehicles',
    data: [],
    config: {
      only: [
        'id',
        'driver_full_name',
        'driver_phone',
        'reg_number',
        'pot_status_id',
        'vrc_full_name',
        'main_contractor_id',
        'warehouse_id',
        'checkpoint_id'
      ],
      include: {
        cargoes: {
          include: { sender: {}, subproject: { only: ['name'] } },
          where: [{
            field: 'receiving_date_fact',
            op: '=',
            value: null
          }]
        },
        vehicle_type: {
          only: ['name', 'id']
        },
        vehicle_categories: {}
      },
      where: []
    }
  },
  pot_status_id: {
    id: 8,
    urlName: 'potStatusList',
    data: [],
    config: { only: ['name', 'id', 'ordered'] }
  },
  subprojects: {
    id: 9,
    urlName: 'subprojects',
    data: [],
    config: { only: ['name', 'id'] }
  },
  subsubprojects: {
    id: 9,
    urlName: 'subsubprojects',
    data: [],
    config: { only: ['name', 'id'] }
  },
  sender: {
    id: 10,
    urlName: 'mainContractor',
    data: [],
    config: { only: ['name', 'id'] }
  },
  status_pre_id: {
    id: 11,
    urlName: 'statusPreId',
    data: [],
    config: { only: ['name', 'id'] }
  },
  mols: {
    id: 'mols',
    urlName: 'mols',
    data: [],
    config: { only: ['fio', 'id'] },
    keyField: 'fio'
  }
}

export const vehicleFilters =
[
  {
    id: 'cargoes.forecast_delivery',
    initType: 'date-filter',
    type: 'day-filter',
    title: 'Дата прибытия',
    prop: { interval: { from: '', to: '' } },
    active: false
  },
  {
    id: 'cargoes.sender_id',
    source: 'main_contractor_id',
    type: 'checkbox-filter',
    title: 'Подрядчик',
    prop: [],
    active: false
  },
  {
    id: 'pot_status_id',
    source: 'pot_status_id',
    type: 'checkbox-filter',
    title: 'Статус',
    prop: [],
    active: false
  },
  {
    id: 'warehouse_id',
    source: 'warehouse_id',
    type: 'checkbox-filter',
    title: 'Склад',
    prop: [],
    active: false
  },
  {
    id: 'checkpoint_id',
    source: 'checkpoint_id',
    type: 'checkbox-filter',
    title: 'КПП',
    prop: [],
    active: false
  },
  {
    id: 'driver_full_name',
    source: 'driver_full_name',
    type: 'string-filter',
    title: 'ФИО Водителя',
    prop: '',
    active: false
  },
  {
    id: 'driver_phone',
    source: 'driver_phone',
    type: 'string-filter',
    title: 'Телефон водителя',
    prop: '',
    active: false
  },
  {
    id: 'cargoes.packing_list_no',
    source: 'cargoes.packing_list_no',
    type: 'string-filter',
    title: '№ Упаковочного листа',
    prop: '',
    active: false
  },
  {
    id: 'cargoes.nomenclature_group',
    source: 'cargoes.nomenclature_group',
    type: 'string-filter',
    title: 'Группа номенклатуры',
    prop: '',
    active: false
  },
  {
    id: 'cargoes.subproject_id',
    source: 'subprojects',
    type: 'checkbox-filter',
    title: 'Подпроект',
    prop: [],
    active: false
  }
]

export const railwayFilters = [
  {
    id: 'current_station',
    source: 'current_station',
    type: 'string-filter',
    title: 'Текущая станция',
    prop: '',
    active: false
  },
  {
    id: 'destination_station',
    source: 'destination_station',
    type: 'string-filter',
    title: 'Станция назначения',
    prop: '',
    active: false
  },
  {
    id: 'arrival_forecast',
    initType: 'date-filter',
    type: 'day-filter',
    title: 'Прогнозная дата ЖД',
    prop: { interval: { from: '', to: '' } },
    active: false
  },
  {
    id: 'cargoes.forecast_delivery',
    initType: 'date-filter',
    type: 'day-filter',
    title: 'Прогнозная дата груза',
    prop: { interval: { from: '', to: '' } },
    active: false
  },
  {
    id: 'status_pre_id',
    source: 'status_pre_id',
    type: 'checkbox-filter',
    title: 'Статус',
    prop: [],
    active: false
  },
  {
    id: 'cargoes.sender_id',
    source: 'main_contractor_id',
    type: 'checkbox-filter',
    title: 'Поставщик',
    prop: [],
    active: false
  },
  {
    id: 'packing_list_no',
    source: 'packing_list_no',
    type: 'string-filter',
    title: '№ Упаковочного листа',
    prop: '',
    active: false
  },
  {
    id: 'cargoes.subproject_id',
    source: 'subprojects',
    type: 'checkbox-filter',
    title: 'Подпроект',
    prop: [],
    active: false
  }
]

export const bargesFilters = [
  {
    id: 'name',
    source: 'name',
    type: 'string-filter',
    title: 'Наименование',
    prop: '',
    active: false
  },
  {
    id: 'number',
    source: 'number',
    type: 'string-filter',
    title: 'Номер',
    prop: '',
    active: false
  },
  {
    id: 'cargoes.packing_list_no',
    source: 'cargoes.packing_list_no',
    type: 'string-filter',
    title: '№ Упаковочного листа',
    prop: '',
    active: false
  },
  {
    id: 'cargoes.forecast_delivery',
    initType: 'date-filter',
    type: 'day-filter',
    title: 'Прогнозная дата груза',
    prop: { interval: { from: '', to: '' } },
    active: false
  },
  {
    id: 'cargoes.sender_id',
    source: 'main_contractor_id',
    type: 'checkbox-filter',
    title: 'Поставщик',
    prop: [],
    active: false
  },
  {
    id: 'cargoes.subproject_id',
    source: 'subprojects',
    type: 'checkbox-filter',
    title: 'Подпроект',
    prop: [],
    active: false
  }
]
