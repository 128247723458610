<template>
  <div class="rp-users-datasources">
    <rp-users-tip :tip="tip" />
    <div class="rp-users-datasources-row">
      <div class="rp-users-datasources-col">
        <rp-users-datasources-list
          :list="allDatasources"
          :selected-items="selectedFromAll"
        />
      </div>
      <div class="rp-users-datasources-control">
        <r-button
          :class="{ active: selectedFromAll.length }"
          icon="chevron-right"
          @click="addDatasources"
        />
        <r-button
          :class="{ active: selectedFromRole.length }"
          icon="chevron-left"
          @click="removeDatasources"
        />
      </div>
      <div class="rp-users-datasources-col">
        <rp-users-datasources-list-role
          :list="userDatasources"
          :selected-items="selectedFromRole"
        />
      </div>
    </div>
  </div>
</template>

<script>
import rpUsersDatasourcesList from './rp-users-datasources-list'
import rpUsersDatasourcesListRole from './rp-users-datasources-list-role'
import rpUsersTip from '../rp-users-tip'
import { crudsTypes } from './configs'

export default {
  components: {
    rpUsersDatasourcesList,
    rpUsersDatasourcesListRole,
    rpUsersTip
  },
  data() {
    return {
      tip:
        'Источники данных, добавленные для роли, убирать нельзя. Пользователю вы можете только добавить новые. Полный контроль изменений доступен при редактировании роли.',
      selectedFromAll: [],
      selectedFromRole: [],
      crudsTypes
    }
  },
  computed: {
    allDatasources() {
      const datasources =
        this.$store.state.rolePermission.allPerms?.datasources || []

      return datasources
        .filter(e => {
          return !this.userDatasources.find(
            d => d.id === e.id && d._action !== 'deleted'
          )
        })
        .sort((a, b) => {
          if (a.name > b.name) return 1
          if (a.name < b.name) return -1

          return 0
        })
    },
    userDatasources() {
      const userDatasources =
        this.$store.state.rolePermission.userPerms?.datasources || []

      return userDatasources
        .map(e => e)
        .sort((a, b) => {
          if (a.name > b.name) return 1
          if (a.name < b.name) return -1

          return 0
        })
    },
    initialUserDatasources() {
      return (
        this.$store.state.rolePermission.initialUserPerms?.datasources || []
      )
    }
  },
  watch: {
    '$store.state.rolePermission.activeUserId': function() {
      this.selectedFromAll = []
      this.selectedFromRole = []
    }
  },
  methods: {
    addDatasources() {
      if (!this.selectedFromAll?.length) return

      const datasources = this.selectedFromAll.map(e => {
        e.cruds = []

        this.crudsTypes.forEach(c => {
          e.cruds.push(c.value)
        })

        if (e.children?.length) {
          e.children.forEach(c => {
            c.cruds = e.cruds
          })
        }

        return e
      })
      const initial = this.userDatasources.map(e => e)
      datasources.forEach(ds => {
        const index = initial.findIndex(e => {
          return e.id === ds.id
        })

        if (index > -1) {
          initial[index]._action = ds._action
        } else {
          initial.push(ds)
        }
      })

      this.$store.commit('ROLE_PERM_SET_USER_DATASOURCES', initial)
      this.selectedFromAll = []
      this.$forceUpdate()
    },
    removeDatasources() {
      if (!this.selectedFromRole?.length) return

      const dsToRemove = this.selectedFromRole.filter(e => {
        return !this.initialUserDatasources.find(ds => ds.id === e.id)
      })
      const dsToUpdate = this.selectedFromRole.filter(e => {
        return this.initialUserDatasources.find(ds => ds.id === e.id)
      })
      dsToUpdate.forEach(e => {
        const index = this.userDatasources.findIndex(d => d.id === e.id)
        if (index > -1) {
          this.userDatasources[index]._action = 'deleted'
        }
      })
      const datasources = this.userDatasources.filter(e => {
        return !dsToRemove.find(d => d.id === e.id)
      })

      this.$store.commit('ROLE_PERM_SET_USER_DATASOURCES', datasources)
      this.selectedFromRole = []
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss">
.rp-users-datasources {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1020px;
  max-width: 1080px;

  &-row {
    position: relative;
    display: flex;
    height: calc(100% - 74px);
    width: 100%;
    min-width: 1020px;
  }

  &-control {
    padding: 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .r-button {
      padding: 6px !important;
      margin-bottom: 20px;
      cursor: initial;

      &:last-child {
        margin-bottom: 0;
        margin-left: 0;
      }

      &.r-button {
        &.active {
          background-color: var(--accent_active) !important;
          cursor: pointer;
        }

        &:hover {
          background-color: var(--accent_hover) !important;
        }
      }
    }
  }

  &-col {
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--bg_containers);
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
</style>
