<template>
  <div
    class="railway-control__content-peron"
  >
    <div class="railway-control__content-peron__title">
      <r-text type="caption">
        {{ $t('way') }}
      </r-text>
      <r-title>
        {{ info.way_no }}
      </r-title>
    </div>
    <ul
      class="railway-control__content-peron-list"
      @dragstart="onDragStart($event)"
    >
      <li
        v-for="(item, i) in info.capacity"
        :key="i"
        :class="['railway-control__content-peron-list__item', { 'mouseover': isCarOver === item }, { 'available': isCarAvailable(item) }]"
        @dragleave="onCarDragLeave()"
        @dragover="onCarDragOver($event, item)"
        @drop="onCarDrop($event, item)"
      >
        <railway-control-content-peron-car
          v-if="getCar(item)"
          :info="getCar(item)"
          @click-handler="e => setDragId(e)"
        />
      </li>
    </ul>
    <r-icon
      name="arrow-back"
      :size="24"
    />
  </div>
</template>

<script>
import railwayControlContentPeronCar from './railway-control-content-peron-car'

export default {
  components: {
    railwayControlContentPeronCar
  },
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dragId: null,
      isCarOver: null
    }
  },
  computed: {
    carsList() {
      return this.$store.state.railwayControl.carsList || []
    },
    itemsList() {
      return this.carsList.filter(
        e => String(e.way_no) === String(this.info.way_no)
      ) || []
    },
    statusList() {
      const statusList = this.$store.state.railwayControl.statusList

      if (!statusList) return []
      return Object.values(statusList)
    },
    firstStatus() {
      if (!this.statusList?.length) return null
      return this.statusList.find(e => e.name === 'На сортировке') || null
    },
    secondStatus() {
      if (!this.statusList?.length) return null
      return this.statusList.find(e => e.name === 'Прибыл на завод') || null
    }
  },
  methods: {
    isCarAvailable(peron_no) {
      return !this.itemsList.find(e => e.peron_no === peron_no)
    },
    onCarDragOver(e, peron_no) {
      this.isCarOver = peron_no
      if (this.isCarAvailable(peron_no)) e.preventDefault()
    },
    findCarById(id) {
      return this.carsList.find(e => e.id === id)
    },
    changeStatus(id) {
      if (!this.firstStatus || !this.secondStatus) return
      const carInfo = this.findCarById(id)
      const carStatusId = carInfo.status_id || null
      if (carStatusId === this.firstStatus.id) {
        this.$store.commit('RAILWAY_CONTROL_UPDATE_CAR_BY_ID', {
          field: 'status_id',
          value: this.secondStatus.id,
          id
        })
      }
    },
    onCarDrop(e, peron_no) {
      if (this.isCarAvailable(peron_no)) e.preventDefault()
      const id = e.dataTransfer.getData('text')
      this.$store.commit('RAILWAY_CONTROL_UPDATE_CAR_BY_ID', {
        value: this.info.way_no,
        field: 'way_no',
        id
      })
      this.$store.commit('RAILWAY_CONTROL_UPDATE_CAR_BY_ID', {
        value: peron_no,
        field: 'peron_no',
        id
      })
      this.changeStatus(id)
      this.$store.commit('RAILWAY_CONTROL_OBSERV_CHANGES_BY_ID', {
        id
      })
      this.isCarOver = null
    },
    onCarDragLeave() {
      this.isCarOver = null
    },
    getCar(peron_no) {
      return this.itemsList.find(e => e.peron_no === peron_no)
    },
    setDragId(i) {
      this.dragId = i
    },
    onDragStart(e) {
      e.dataTransfer.setData('Text', this.dragId)
      e.dataTransfer.effectAllowed = 'move'
    }
  }
}
</script>

<style lang="scss">
.railway-control__content-peron {
  position: relative;
  display: flex;
  border-radius: 12px;
  padding: 12px 16px;
  background-color: var(--bg_containers);
  align-items: center;
  justify-content: space-between;
  margin-right: 36px;
  flex-grow: 1;
  transition: all 0.2s ease;
  min-width: 184px;
  &:last-child,
  &:nth-child(2) {
    margin-right: 0;
    flex-shrink: 0;
  }
  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    flex-shrink: 0;
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    margin: -8px 0;
    > * {
      margin: 8px 8px 8px 0;
      &:last-child {
        margin-right: 0;
      }
    }
    &__item {
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 46px;
      border-radius: var(--border-radius);
      background-color: var(--field_bg);
      &.mouseover {
        box-shadow: 0 0 0 2px var(--accent_error);
        cursor: not-allowed;
        &.available {
          cursor: copy;
          box-shadow: 0 0 0 2px var(--accent_success_bg);
        }
      }
    }
  }
  .r-icon {
    flex-shrink: 0;
    padding-left: 16px;
    margin-left: auto;
  }
}
</style>

<i18n>
{
  "ru": {
    "way": "Путь"
  },
  "en": {
    "way": "Way"
  }
}
</i18n>
