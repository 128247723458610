<template>
  <situation-center-component />
</template>

<script>
import SituationCenterComponent from '@/components/situation-center/situation-center'

export default {
  components: {
    SituationCenterComponent
  }

}
</script>

<style>

</style>
