<template>
  <div
    v-if="potItem"
    v-loading="loading"
    class="r-modal-card pot-modal pot-vehicle-view"
  >
    <r-title color-type="subhead">
      Карточка транспортного средства c ТМЦ
    </r-title>
    <controls
      :loading="loading"
      :is-editing="isEditing"
      @clickHandler="handlePotItem"
    />
    <r-tabs
      @input="changeTab"
    >
      <r-tab
        v-for="tab in tabs"
        :id="tab.label"
        :key="tab.label"
        :name="tab.title"
        :active="viewTab === tab"
      />
    </r-tabs>
    <div
      v-if="viewTab === 'main'"
      class="r-modal-card__main-layout"
    >
      <settings-block
        :pot-item="potItem"
        :checkpoint-list="checkpointList"
        :status-list="statusList"
        :warehouse-list="warehouseList"
      />
      <r-modal-card-block
        main
        :icon="iconName"
        title="Перевозчик"
      >
        <attributes-list
          :model="mainModel"
          :related="related"
          :source="potItem"
          :is-editing="isEditing"
          @hasChanges="hasVehicleChanges()"
        />
      </r-modal-card-block>
    </div>
    <cargo-block
      v-if="viewTab === 'cargoes'"
      :model="potItemModel"
      :is-editing="isEditing"
      :related="related"
      :pot-item="potItem"
      :clone="clone"
      :cargo-info="cargoInfo"
      @cargoesChanges="hasCargoesChanges"
    />
    <files-comments
      v-if="viewTab === 'filesComments'"
      :id="id"
      :source_id="source_id"
      column
      container
    />
  </div>
</template>

<script>
// helpers
import { tabs, potItemModel } from '../configs'
import { getPotItemById } from '../helpers'
import { notifyFactory } from '@/utils'

// components
import filesComments from '@/components/files-comments/files-comments'
import attributesList from '@/components/globals/r-modal-elements/attributes-list'
import rModalCardBlock from '@/components/globals/r-modal-elements/r-modal-card-block'
import controls from './controls'
import settingsBlock from './settings-block'
import cargoBlock from './cargo-block'

// Libs
import cloneDeep from 'lodash.clonedeep'
import debounce from 'lodash.debounce'

export default {
  components: {
    rModalCardBlock,
    attributesList,
    filesComments,
    controls,
    settingsBlock,
    cargoBlock
  },
  data() {
    return {
      potItemEditing: false,
      loading: false,
      vehicleChanges: false,
      cargoesChanges: false,
      viewTab: 'main',
      potItem: null,
      potItemClone: null,
      isEditing: false,
      potItemModel,
      clone: null,
      cargoInfo: {
        current: '',
        idArray: []
      },
      tabs
    }
  },
  computed: {
    activeModule() {
      return this.$store.state.pot1.activeModule
    },
    iconName() {
      switch (this.activeModule) {
        case 'railway':
          return 'train-hollow'
        case 'barges':
          return 'ship-filled'
        default:
          return 'truck-hollow'
      }
    },
    source_id() {
      switch (this.activeModule) {
        case 'railway':
          return this.$store.state.pot1.railway_id
        case 'barges':
          return this.$store.state.pot1.barges_id
        default:
          return this.$store.state.pot1.source_id
      }
    },
    isAdmin() {
      return !!this.$store.state.pot1.isAdmin
    },
    related() {
      return this.$store.state.pot1.related
    },
    statusList() {
      return this.$store.state.pot1.statusList
    },
    cargoSouceId() {
      return this.$store.state.pot1.cargoId
    },
    checkpointList() {
      return this.$store.state.pot1.related.checkpoint_id.data
    },
    warehouseList() {
      return this.$store.state.pot1.related.warehouse_id.data
    },
    mainModel() {
      return this.potItemModel.filter(m => {
        return m.block === this.activeModule
      })
    },
    id() {
      return this.$store.state.pot1.cardId
    }
  },
  watch: {
    vehicleChanges(val) {
      if (val) {
        this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', true)
      }
    },
    cargoesChanges(val) {
      if (val) {
        this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', true)
      }
    }
  },
  created() {
    this.loadPotItem()
  },
  methods: {
    hasVehicleChanges() {
      this.vehicleChanges = true
    },
    hasCargoesChanges(val) {
      this.cargoesChanges = val
    },
    loadPotItem() {
      switch (this.activeModule) {
        case 'railway':
          this.loadRailwayItem()
          break
        case 'barges':
          this.loadBargeItem()
          break
        default:
          this.loadVehicleItem()
          break
      }
    },
    async loadBargeItem() {
      if (!this.id) throw new Error('no ID')
      this.loading = true
      try {
        const config = {
          where: [{ field: 'id', value: this.id, op: '=' }],
          only: [
            'id',
            'name',
            'number'
          ],
          include: {
            cargoes: {
              include: { cargoes_assigment: {}, contractor: {}, sender: {}, mols: {}, cargo_mols: {} }
            }
          }
        }
        const url = `objectInfo/telemetry.barges?config=${JSON.stringify(config)}`
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })
        const potItemData = Object.values(data)[0]
        potItemData.cargoes = potItemData?.cargoes?.map(c => {
          if (c.mols?.length) {
            c.mols = c.mols.map(m => m.id)
          }

          return c
        })

        this.potItem = potItemData
        this.potItemClone = cloneDeep(this.potItem)
        this.cargoInfo = {
          current: potItemData.cargoes[0]?.id || '',
          idArray: potItemData.cargoes.map(({ id }) => id)
        }
        this.clone = cloneDeep(potItemData)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    async loadRailwayItem() {
      if (!this.id) throw new Error('no ID')
      this.loading = true
      try {
        const config = {
          where: [{ field: 'id', value: this.id, op: '=' }],
          only: [
            'id',
            'carriage_no',
            'waybill_no',
            'destination_station',
            'current_station',
            'arrival_forecast',
            'status_pre_id'
          ],
          include: {
            cargoes: {
              include: { cargoes_assigment: {}, contractor: {}, sender: {}, mols: {}, cargo_mols: {} }
            }
          }
        }
        const url = `objectInfo/42651eb5-0954-4687-a9d6-23872b98b009?config=${JSON.stringify(config)}`
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })
        const potItemData = Object.values(data)[0]
        const arrivalForecast = potItemData.arrival_forecast
          ? this.$rDate.zeroing(potItemData.arrival_forecast).format('iso')
          : null
        this.$set(potItemData, 'arrival_forecast', arrivalForecast)

        potItemData.cargoes = potItemData?.cargoes?.map(c => {
          if (c.mols?.length) {
            c.mols = c.mols.map(m => m.id)
          }

          return c
        })

        this.potItem = potItemData
        this.potItemClone = cloneDeep(this.potItem)
        this.cargoInfo = {
          current: potItemData.cargoes[0]?.id || '',
          idArray: potItemData.cargoes.map(({ id }) => id)
        }
        this.clone = cloneDeep(potItemData)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    async loadVehicleItem() {
      if (!this.id) throw new Error('no ID')
      this.loading = true
      try {
        const url = getPotItemById(this.id, this.source_id)
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })
        const potItemData = Object.values(data)[0]
        potItemData.cargoes = potItemData?.cargoes?.map(c => {
          if (c.mols?.length) {
            c.mols = c.mols.map(m => m.id)
          }

          return c
        })
        this.potItem = potItemData
        this.potItemClone = cloneDeep(this.potItem)
        this.cargoInfo = {
          current: potItemData.cargoes[0]?.id || '',
          idArray: potItemData.cargoes.map(({ id }) => id)
        }
        this.clone = cloneDeep(potItemData)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    async handlePotItem(cmd) {
      if (cmd === 'delete') {
        this.deletePotItem()
        this.isEditing = false
        return
      }
      if (!this.isEditing) {
        this.isEditing = true
        return
      }
      if (cmd === 'discard') {
        this.loadPotItem()
        this.isEditing = false
        this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', false)
        return
      }
      if (this.cargoesChanges) {
        await this.handleBelongingCargoes()
      }
      this.preparePotItemData()
      this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', false)
      this.isEditing = false
    },
    async deletePotItem() {
      const { id, source_id, $store } = this

      try {
        this.loading = true
        const data = { id }

        switch (this.activeModule) {
          case 'vehicles':
            data.pot_status_id = null
            break
          case 'railway':
            data.status_pre_id = null
            break
        }

        await $store.dispatch('POST_REQUEST', {
          url: `objectInfo/${source_id}`,
          data
        })
      } catch (e) {
        throw new Error(e)
      } finally {
        this.$store.commit('SET_POT_FIELD', {
          field: 'updateData',
          value: true
        })
        $store.commit('CLOSE_MODAL_WINDOW')
        this.loading = false
      }
    },
    async handleBelongingCargoes() {
      const currentIds = this.cargoInfo.idArray
      const savedCargoes = this.clone.cargoes
      const vehcileId = this.potItem.id
      const data = []
      const vehicleField = this.activeModule === 'vehicles' ? 'vehicle_id' : 'last_dislocation_data_id'
      // adding
      currentIds.forEach(id => {
        if (!savedCargoes.find(c => c.id === id)) {
          data.push({ id, cargoes_assigment: [{ [vehicleField]: vehcileId }] })
        }
      })
      // removing
      savedCargoes.forEach((sc) => {
        if (!currentIds.includes(sc.id)) {
          data.push({
            id: sc.id,
            cargoes_assigment: [
              {
                id: sc.cargoes_assigment.find(
                  ca => ca[vehicleField] === vehcileId
                )?.id,
                disabled: true
              }
            ]
          })
        }
      })

      if (data.length) {
        await this.putRequest(data, this.cargoSouceId)
      }
    },
    preparePotItemData() {
      const cargoes = this.potItem.cargoes
      const data = this.potItemModel.reduce(
        (acc, { block, model }) => {
          const vehicleBlocks = ['carrier', 'railway-carrier', 'railway', 'barges']
          if (vehicleBlocks.includes(block)) {
            acc.vehicle[model] = this.potItem[model]
          }
          if ((block === 'contacts' || block === 'cargo') && cargoes.length) {
            cargoes.forEach(cargo => {
              const { id } = cargo
              const hasRecord = acc.cargoes.find(c => c.id === id)

              const existed = this.potItem.cargoes.find(c => c.id === id)
              if (hasRecord) {
                hasRecord[model] = existed[model]
              } else {
                acc.cargoes.push({ id, [model]: existed[model] })
              }
            })
          }
          return acc
        },
        { vehicle: { id: this.id }, cargoes: [] }
      )
      if (this.vehicleChanges) this.putRequest(data.vehicle, this.source_id)
      if (cargoes.length && this.cargoesChanges) {
        const parsed = data.cargoes?.map(e => {
          const parsedDate = {}
          const dateFields = this.potItemModel.filter(m => m.block === 'cargo' && m.type === 'date')
          dateFields.forEach(f => {
            if (e[f.model]) {
              parsedDate[f.model] = this.$rDate.format(e[f.model], 'iso')
            }
          })
          const initialCargo = this.potItemClone?.cargoes?.find(c => c.id === e.id)
          const initialMols = initialCargo?.mols
          const initialCargoMols = initialCargo?.cargo_mols
          const newMols = e.mols.filter(m => !initialMols.includes(m))
          const deletedMols = initialMols.filter(m => !e.mols.includes(m))

          if (newMols?.length || deletedMols?.length) {
            e.cargo_mols = deletedMols.map(m => {
              return {
                id: initialCargoMols.find(icm => icm.mol_id === m)?.id,
                disabled: true
              }
            }) || []

            e.cargo_mols.push(...newMols.map(m => {
              return {
                mol_id: m,
                vehicle_id: this.potItem.id
              }
            }))
          }
          return {
            ...e,
            ...parsedDate
          }
        })
        this.putRequest(parsed, this.cargoSouceId)
      }
    },
    notify: debounce(function() {
      const title = 'Данные успешно сохранены!'
      this.$notify(notifyFactory('success', title))
    }, 999),
    async putRequest(data, source) {
      if (!data) return
      try {
        this.loading = true
        await this.$store.dispatch('PUT_REQUEST', {
          url: `objectInfo/${source}`,
          data
        })
        this.notify()
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
        this.$store.commit('SET_POT_FIELD', {
          field: 'updateData',
          value: true
        })
      }

      await this.loadPotItem()
    },
    changeTab(tab) {
      this.viewTab = tab
    }
  }
}
</script>
