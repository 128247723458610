<template>
  <div class="bdd-checklists">
    <router-link
      to="app/bdd/applications"
    >
      <r-button
        simple
        icon="chevron-left"
      >
        Вернуться назад
      </r-button>
    </router-link>
    <component :is="componentName" />
  </div>
</template>

<script>
import checklistList from './components/checklist-list/checklist-list'
import checklist from './components/checklist/checklist'

export default {
  components: {
    checklistList,
    checklist
  },
  computed: {
    componentName() {
      const { id } = this.$route.query

      return id ? 'checklist' : 'checklist-list'
    }
  }

}
</script>

<style lang="scss">
.bdd-checklists {
  height: 100%;
  display: grid;
  grid-gap: 1rem;
  padding: 2rem 0;
  align-content: start;
  justify-content: center;
  overflow-y: auto;
  background-color: var(--bg_surface) !important;
}
</style>
