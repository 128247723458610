<template>
  <div class="sc-object-card__list-cameras-item__wrapper">
    <div class="sc-object-card__list-cameras-item__wrapper-title">
      <r-text
        type="caption"
        :size="10"
      >
        {{ `ip: ${field.ip}` }}
      </r-text>
    </div>
    <el-tooltip
      :open-delay="500"
      :content="`${$t(`status:${field.status}`)}`"
      placement="top"
    >
      <span
        :class="[
          'sc-object-card__list-cameras-item__wrapper-status',
          { error: !field.status }
        ]"
      />
    </el-tooltip>
    <img
      class="sc-object-card__list-cameras-item__wrapper-image"
      :src="cameraUrl"
      alt="No-data"
      @click="dialogVisible = !dialogVisible"
    >
    <el-dialog
      class="r-dialog"
      :visible.sync="dialogVisible"
      width="50vw"
      :modal-append-to-body="false"
    >
      <img
        class="sc-object-card__list-cameras-item__wrapper-image--modal"
        :src="cameraUrl"
        alt="No-data"
      >
    </el-dialog>
  </div>
</template>

<script>
import constants from '@/constants/url'

export default {
  props: {
    field: {
      type: Object,
      required: true,
      default: () => null
    }
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  computed: {
    cameraUrl() {
      return `${constants.BASE_URL}/cam_proxy/${this.field.ip}`
    }
  }
}
</script>

<style lang="scss">
.sc-object-card__list-cameras-item__wrapper {
  position: relative;
  display: flex;
  &-title {
    border-radius: var(--border-radius);
    padding: 2px 6px;
    position: absolute;
    bottom: 4px;
    left: 4px;
    display: flex;
    justify-content: center;
    font-size: 12px;
    color: var(--accent_success);
    background-color: var(--black_bg);
    &.error {
      color: var(--accent_error);
    }
  }
  &-status {
    position: absolute;
    right: 6px;
    top: 6px;
    display: block;
    padding: 4px;
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--accent_success);
    &.error {
      background-color: var(--accent_error);
    }
  }
  &-image {
    min-width: 144px;
    min-height: 100px;
    width: 160px;
    height: auto;
    text-align: center;
    color: var(--text_secondary) !important;
    cursor: zoom-in;

    &--modal {
      width: calc(50vw - 50px);
      border-radius: var(--border-radius);
      overflow: hidden;
      cursor: zoom-out;
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "status:true": "Работает",
    "status:false": "Ошибка"
  },
  "en": {
    "status:true": "Working",
    "status:false": "Error"
  }
}
</i18n>
