<template>
  <div class="sc-object-card__camera">
    <img
      class="sc-object-card__camera-img"
      :src="src"
      alt="no-data"
      @click="dialogVisible = !dialogVisible"
    >
    <el-dialog
      class="r-dialog"
      :visible.sync="dialogVisible"
      title="Камера"
      width="50vw"
      :modal-append-to-body="false"
    >
      <img
        class="sc-object-card__camera-img sc-object-card__camera-img--dialog"
        :src="src"
        alt="No-data"
      >
    </el-dialog>
  </div>
</template>

<script>
import constants from '@/constants/url'

export default {
  props: {
    id: {
      type: String,
      required: true,
      default: null
    }
  },
  data() {
    return {
      dialogVisible: false,
      src: ''
    }
  },
  created() {
    this.src = `${constants.URL}/api/py/iss/video_frame?cam=1`
  }
}
</script>

<style lang="scss">
.sc-object-card__camera {
  position: relative;
  &-img {
    max-width: calc(100% - 4px);
    width: calc(100% - 4px);
    border-radius: var(--border-radius);
    overflow: hidden;
    cursor: zoom-in;

    &--modal {
      width: 100%;
      height: 100%;
      max-width: calc(50vw - 50px);
      max-height: 60vh;
      border-radius: var(--border-radius);
      overflow: hidden;
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "location": "Месторасположение",
    "no-cameras": "Камер нет",
    "status": "Статус"
  },
  "en": {
    "location": "Location",
    "no-cameras": "No cameras",
    "status": "Status"
  }
}
</i18n>
