<template>
  <div class="mt-card-actions">
    <r-block
      col
      start
      no-padding
    >
      <r-icon
        :name="icon"
        :size="24"
      />
      <r-text :size="16">
        {{ title }}
      </r-text>
    </r-block>
    <r-block
      no-padding
      col
    >
      <r-button
        icon="focus-zone"
        @click="flyTo"
      />
      <attributes-editor
        :id="id"
        :source_id="source_id"
        @updated="$emit('updated')"
      />
    </r-block>
  </div>
</template>

<script>
import attributesEditor from '@/components/attributes-editor/attributes-editor'

export default {
  components: { attributesEditor },
  props: {
    location: {
      type: Object,
      required: true
    },
    source_id: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: 'bus'
    },
    title: {
      type: String,
      default: 'Название'
    }
  },
  methods: {
    flyTo() {
      this.$store.commit('SET_MT_FIELD', {
        field: 'flyToGeom',
        value: this.location
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.mt-card-actions {
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-auto-flow: column;
  width: 100%;
  grid-gap: 0.5rem;
}
</style>
