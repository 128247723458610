<template>
  <div class="r-list">
    <div
      v-for="item in list"
      :key="item.id"
      class="r-list__item-wrapper"
      @click="nodeClickHandler(item)"
    >
      <r-list-item
        :item="item"
        :tools="toolsList"
        :active-list="activeList"
        :loading-list="loadingList"
        @click-handler="e => toolClickHandler(e)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => null,
      required: true
    },
    toolsList: {
      type: Array,
      default: () => null
    },
    activeList: {
      type: Array,
      default: () => []
    },
    loadingList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    nodeClickHandler(e) {
      this.$emit('node-click-handler', e)
    },
    toolClickHandler(e) {
      this.$emit('tool-click-handler', e)
    }
  }
}
</script>

<style lang="scss">
.r-list {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  height: 0px;
  &__item-wrapper {
    margin-bottom: 4px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
