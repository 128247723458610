import mapboxgl from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { mapToken } from '@/constants/tokens'
import { ModesController } from '@/libs/map-draw/modes/modes-controller'
import { BaseController, EditorController } from '../controllers'
import { eeEditorStyles } from '@/libs/map-draw/config'
import { getMapParams } from '@/utils'
import mapboxStyles from '@/constants/mapbox_styles'

export const initMap = component => {
  // map creating
  const { center, zoom } = getMapParams('ptMap')
  // const theme = component.$store.state.theme;
  // const disabledTiles = theme === "dark-theme" ? "light-theme" : "dark-theme";

  mapboxgl.accessToken = mapToken

  component.mapgl = new mapboxgl.Map({
    container: 'event-editor-map',
    style: mapboxStyles.dark,
    center,
    zoom
  })

  // component.mapgl.on("load", () =>
  //   component.mapgl.setLayoutProperty(disabledTiles, "visibility", "none")
  // );

  // view handlers
  component.mapgl.on('pitch', e => {
    component.is3d = e.target.getPitch() !== 0
  })
  component.mapgl.on('rotate', e => {
    component.mapBearing = e.target.getBearing()
  })

  // event editor controllers
  const mc = new ModesController(component, MapboxDraw, 'editorState')
  component.draw = new MapboxDraw({
    displayControlsDefault: false,
    controls: {
      line_string: true,
      point: true,
      polygon: true,
      trash: true
    },
    styles: eeEditorStyles,
    userProperties: true,
    modes: Object.assign(MapboxDraw.modes, {
      simple_select: mc.createEESimpleSelectMode(),
      direct_select: mc.createEEDirectSelectMode(),
      draw_points: mc.createEEDrawPointsMode(),
      draw_lines: mc.createEEDrawLinesMode(),
      draw_polygons: mc.createEEDrawPolygonsMode()
    })
  })
  component.BaseController = new BaseController(component)
  component.EditorController = new EditorController(component)
  component.EditorController.initDrawListeners()

  component.mapgl.addControl(component.draw)

  window.addEventListener('beforeunload', component.saveMapParams)
}
