<template>
  <div class="sc-object-card__actions">
    <div class="sc-object-card__actions-list">
      <div
        v-for="action in actions"
        :key="action.title"
        class="sc-object-card__actions-item"
        @click="callFunction(action.title)"
      >
        <r-icon
          :name="action.icon"
          :size="20"
        />
        <r-text>{{ $t(action.title) }}</r-text>
      </div>
    </div>
  </div>
</template>

<script>
const actions = [{ title: 'flyTo', icon: 'focus-zone' }]

export default {
  props: {
    info: {
      type: Object,
      default: () => null,
      required: true
    }
  },
  data() {
    return {
      actions
    }
  },
  methods: {
    callFunction(title) {
      switch (title) {
        case 'flyTo':
          this.flyTo()
          break
        default:
          console.log('Неопознанное действие: ', title)
          break
      }
    },
    flyTo() {
      this.$store.commit('SET_SI_CENTER_FLY_TO', {
        item: this.info
      })
    }
  }
}
</script>

<style lang="scss">
.sc-object-card__actions {
  flex-shrink: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  &-item {
    cursor: pointer;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .r-icon__svg,
    .r-text {
      transition: all 0.15s ease !important;
    }

    &:hover {
      .r-icon__svg,
      .r-text {
        color: var(--accent_primary) !important;
        fill: var(--accent_primary) !important;
      }
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "flyTo": "Найти на карте"
  },
  "en": {
    "flyTo": "Find on map"
  }
}
</i18n>
