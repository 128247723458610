<template>
  <div :class="`si-center-card ${type} ${size} ${bgColor}`">
    <div
      v-if="title"
      class="si-center-card__title"
    >
      <r-cblock
        v-if="titleIcon"
        :size="24"
        :color="titleIcon.color"
        :border-color="titleIcon.color"
      >
        <r-icon
          :name="titleIcon.name"
          :size="18"
          :color="titleIcon.color"
        />
      </r-cblock>
      <r-title type="subtitle-1">
        {{ $t(title) }}
      </r-title>
    </div>
    <el-divider v-if="title && !noDeviders" />
    <div class="si-center-card__content">
      <slot />
    </div>
    <el-divider v-if="footer && !noDeviders" />
    <div
      v-if="footer"
      class="si-center-card__footer"
    >
      <slot name="footer" />
    </div>
    <el-divider v-if="secondFooter && !noDeviders" />
    <div
      v-if="secondFooter"
      class="si-center-card__second-footer"
    >
      <slot name="second-footer" />
    </div>
    <div
      v-if="controls"
      class="si-center-card__controls"
    >
      <slot name="controls" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    footer: {
      type: Boolean,
      default: false
    },
    secondFooter: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'default'
    },
    controls: {
      type: Boolean,
      default: false
    },
    titleIcon: {
      type: Object,
      default: null
    },
    noDeviders: {
      type: Boolean,
      default: false
    },
    bgColor: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'small'
    }
  }
}
</script>

<style lang="scss">
.si-center-card {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  background-color: var(--modal_bg) !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  &.bg_panel_primary {
    background-color: var(--bg_panel_primary) !important;
  }
  .el-divider {
    margin: 0;
    background-color: var(--dividers_low_contrast) !important;
    min-height: 1px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    flex: 1;
  }

  &__title {
    flex-shrink: 0;
    padding: 8px 8px 10px;
    margin: 0;
    display: flex;
    align-items: center;
    .r-cblock {
      margin-right: 12px;
      width: 44px;
    }
    .r-title {
      width: calc(100% - 56px);
    }
  }
  &__content,
  &__footer,
  &__second-footer {
    padding: 8px;
    position: relative;
  }

  &__controls {
    position: absolute;
    padding: 2px 8px;
    top: 1px;
    right: 0;
  }
  &.small {
    max-width: 320px;
  }
  &.mid {
    max-width: 620px;
  }
}
</style>
