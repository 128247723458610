<template>
  <div class="map-editor-legend">
    <ul
      v-if="editorState.modelId"
      class="map-editor-legend__list"
    >
      <li class="map-editor-legend__item">
        <div class="map-editor-legend__item-key">
          <div class="map-editor-legend__item-symbol nodes" />
        </div>
        <div class="map-editor-legend__item-value">
          Узлы
        </div>
      </li>
      <li class="map-editor-legend__item">
        <div class="map-editor-legend__item-key">
          <div class="map-editor-legend__item-symbol links" />
        </div>
        <div class="map-editor-legend__item-value">
          Отрезки
        </div>
      </li>
      <li
        v-if="mapMode === 'editor'"
        class="map-editor-legend__item"
      >
        <div class="map-editor-legend__item-key">
          <div class="map-editor-legend__item-symbol connectors" />
        </div>
        <div class="map-editor-legend__item-value">
          Примыкания
        </div>
      </li>
      <li
        v-if="mapMode === 'editor'"
        class="map-editor-legend__item"
      >
        <div class="map-editor-legend__item-key">
          <div class="map-editor-legend__item-symbol zones" />
        </div>
        <div class="map-editor-legend__item-value">
          Районы
        </div>
      </li>
    </ul>
    <ul
      v-else
      class="map-editor-legend__list"
    >
      <li class="map-editor-legend__item">
        <div class="map-editor-legend__item-key">
          <div class="map-editor-legend__item-symbol nodes" />
        </div>
        <div class="map-editor-legend__item-value">
          Точки
        </div>
      </li>
      <li class="map-editor-legend__item">
        <div class="map-editor-legend__item-key">
          <div class="map-editor-legend__item-symbol links" />
        </div>
        <div class="map-editor-legend__item-value">
          Линии
        </div>
      </li>
      <li class="map-editor-legend__item">
        <div class="map-editor-legend__item-key">
          <div class="map-editor-legend__item-symbol zones" />
        </div>
        <div class="map-editor-legend__item-value">
          Полигоны
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    editorState: {
      type: Object,
      required: true
    },
    mapMode: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.map-editor-legend {
  padding: 10px;

  &__item {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-top: 5px;
    }

    &-key {
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-value {
      margin-left: 10px;
      color: var(--text_secondary) !important;
    }

    &-symbol {
      &.nodes {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #3a8fd2;
      }

      &.links {
        width: 100%;
        height: 2px;
        background: #3a8fd2;
      }

      &.stop_points {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #e56566;
        border: 1px solid var(--white_white);
      }

      &.connectors {
        width: 100%;
        height: 1px;
        background: linear-gradient(
          90deg,
          #3a8fd2 0%,
          #3a8fd2 20%,
          transparent 20%,
          transparent 40%,
          #3a8fd2 40%,
          #3a8fd2 60%,
          transparent 60%,
          transparent 80%,
          #3a8fd2 80%
        );
      }

      &.line_routes {
        width: 100%;
        height: 3px;
        background: #e56566;
      }

      &.zones {
        width: 100%;
        height: 15px;
        border: 2px solid #3a8fd2;
        background: rgba(58, 144, 210, 0.3);
      }
    }
  }
}
</style>
