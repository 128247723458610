<template>
  <div class="r-simple-calendar">
    <ul class="r-simple-calendar__list">
      <li
        v-for="i in 5"
        :key="i"
        :class="[
          'r-simple-calendar__list-item',
          { active: isActiveDate('subtract', i) }
        ]"
        @click="clickHandler('subtract', i)"
      >
        <railway-control-content-header-calendar-item
          :date="getDate('subtract', i)"
          :is-active="isActiveDate('subtract', i)"
        />
      </li>
      <li
        :class="[
          'r-simple-calendar__list-item current',
          { active: isActiveDate('today') }
        ]"
        @click="clickHandler('today')"
      >
        <railway-control-content-header-calendar-item
          :date="getCurrentDate()"
          :is-active="isActiveDate('today')"
        />
      </li>
    </ul>
    <div class="r-simple-calendar__button">
      <el-date-picker
        v-model="dateFilter"
        class="r-date-picker"
        type="date"
        size="mini"
        name="date"
        :clearable="false"
        format="dd.MM.yyyy"
        :picker-options="pickerOptions"
      />
    </div>
  </div>
</template>

<script>
import railwayControlContentHeaderCalendarItem from './renderers/railway-control-content-header-calendar-item'

export default {
  components: {
    railwayControlContentHeaderCalendarItem
  },
  data() {
    return {
      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      currentDate: new Date()
    }
  },
  computed: {
    locale() {
      return this.$store.state.locale
    },
    dateFilter: {
      get() {
        return this.$store.state.railwayControl.dateFilter || null
      },
      set(date) {
        this.$store.commit(
          'RAILWAY_CONTROL_SET_DATE_FILTER',
          this.$rDate.format(date, 'iso')
        )
      }
    }
  },
  methods: {
    isActiveDate(action, val) {
      let date = ''

      switch (action) {
        case 'today':
          date = this.$rDate.format(this.currentDate, 'iso')
          break
        case 'subtract':
          date = this.$rDate
            .calc(-(6 - val), 'day', this.currentDate)
            .format('iso')
          break
        default:
          date = this.$rDate.format(this.currentDate, 'iso')
          break
      }

      return (
        this.$rDate.format(this.dateFilter, 'l') ===
        this.$rDate.format(date, 'l')
      )
    },
    clickHandler(action, val) {
      let date = ''

      switch (action) {
        case 'today':
          date = this.$rDate.format(this.currentDate, 'iso')
          break
        case 'subtract':
          date = this.$rDate
            .calc(-(6 - val), 'day', this.currentDate)
            .format('iso')
          break
        default:
          date = this.$rDate.format(this.currentDate, 'iso')
          break
      }

      this.$store.commit('RAILWAY_CONTROL_SET_DATE_FILTER', date)
    },
    getDate(action, i) {
      return action === 'add'
        ? this.$rDate.calc(+i, 'day', this.currentDate).format('iso')
        : this.$rDate.calc(-(6 - i), 'day', this.currentDate).format('iso')
    },
    getCurrentDate() {
      return this.$rDate.format(this.currentDate, 'iso')
    }
  }
}
</script>

<style lang="scss">
.railway-control__content-header__calendar {
  position: relative;
}

.r-simple-calendar {
  position: relative;
  display: flex;

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 4px;
      min-width: 32px;
      border-radius: var(--border-radius);
      cursor: pointer;
      height: 100%;

      &:hover {
        background-color: var(--accent_hover);
      }

      &.active {
        padding: 0 12px;
        background-color: var(--button_secondary_bg);
      }

      &.current {
        background-color: var(--accent_hover);

        &.active {
          background-color: var(--accent_active);
        }
      }
    }
  }

  &__button {
    padding: 0 12px;
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: var(--dividers_low_contrast);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .r-date-picker {
    width: 24px !important;

    input {
      display: none;
    }

    .el-input__prefix {
      left: auto;
      font-size: 24px;
    }

    .el-input__suffix {
      display: none;
    }
  }
}
</style>
