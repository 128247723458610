<template>
  <div class="map-legend-container">
    <div class="map-legend-layer-header">
      <div class="map-legend-title">
        {{ item.name }}
      </div>
      <div class="map-legend-count">
        {{ item.count }}
      </div>
    </div>
    <div class="map-legend-subtitle">
      Тепловая карта
    </div>
    <div class="map-legend-row heatmap-colors">
      <div
        v-for="(color, i) in item.colors"
        :key="color + i"
        class="map-legend-color"
        :style="`background-color: ${color}`"
      />
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
