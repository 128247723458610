import axios from 'axios'

import { getCookie, setCookie } from '@/utils'
import constants from '@/constants/url'

const state = {
  token: getCookie('token'),
  user: {},
  modules: [],
  datasources: [],
  dsIds: {}
}

const getters = {
  hasModule: state => module => !!state.modules.find(m => m.name === module),
  hasInstrument: state => (module, instrument) => {
    const foundModule = state.modules.find(m => m.name === module)
    if (!foundModule) return false

    return !!foundModule.instruments.find(i => i.name === instrument)
  }
}

const actions = {
  LOG_IN({ commit }, { userName, userPass }) {
    const formData = new FormData()
    formData.append('user[username]', userName)
    formData.append('user[password]', userPass)

    return new Promise((resolve, reject) => {
      axios
        .post(`${constants.URL}/login`, formData)
        .then(response => {
          const { data } = response
          if (data.access_token) {
            setCookie('token', data.access_token, { expires: 24 * 3600 })
            console.log(data)
            commit('SET_USER_DATA', {
              token: data.access_token,
              user: { ...data, name: data.user }
            })
            resolve(data)
          } else {
            reject(data)
          }
        })
        .catch(error => reject(error))
    })
  }
}

const mutations = {
  SET_USER_DATA(state, payload) {
    state.token = payload.token
    state.user = payload.user
  },
  SET_USER_DATA_BY_TOKEN(state, payload) {
    state.user = payload.user
  },
  SET_USER_MODULES(state, modules) {
    state.modules = modules
  },
  SET_USER_DATASOURCES(state, datasources) {
    state.datasources = datasources

    if (datasources?.length) {
      const ids = {}

      datasources.forEach(e => {
        ids[e.id] = {}
      })

      state.dsIds = ids
    }
  },
  UPDATE_USER_DATA(state, user) {
    state.user.company = user.company
    state.user.email = user.email
    state.user.first_name = user.first_name
    state.user.last_name = user.last_name
    state.user.middle_name = user.middle_name
    state.user.avatar = user.avatar
    state.user.position = user.position
    state.user.role_name = user.role_name
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
