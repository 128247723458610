<template>
  <div
    class="railway-control__content-peron-car"
    :draggable="isDraggable()"
    @mousedown="setDragId(info.id)"
  >
    <div
      :class="[`railway-control__content-peron-car-wrapper ${getColorType()}`]"
    >
      <r-text no-wrap>
        {{ `№${info.number}` }}
      </r-text>
      <div @click="clickHandler()">
        <r-text
          type="caption"
          color-type="subhead"
        >
          {{ $t('more') }}
        </r-text>
      </div>
    </div>
  </div>
</template>

<script>
import { getStatusNameById, statusDecryption } from '../../configs'

export default {
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  computed: {
    dateFilter() {
      return this.$store.state.railwayControl.dateFilter || null
    }
  },
  methods: {
    isDraggable() {
      const dateFilter = this.$rDate.format(this.dateFilter, 'DD.MM.YYYY')
      const today = this.$rDate.format(new Date(), 'DD.MM.YYYY')

      return dateFilter === today
    },
    getColorType() {
      const carStatusName =
        getStatusNameById(this, this.info.status_id) || 'default'

      return statusDecryption[carStatusName]?.color
    },
    clickHandler() {
      this.$store.commit('RAILWAY_CONTROL_SET_CARD_ID', this.info.id)
    },
    setDragId(id) {
      this.$emit('click-handler', id)
    }
  }
}
</script>

<style lang="scss">
.railway-control__content-peron-car {
  background-color: var(--bg_panel_primary);
  width: 100px;
  height: 100%;
  position: relative;
  cursor: grab;
  overflow: hidden;

  &-wrapper {
    padding: 4px 4px 4px 16px;
    display: grid;
    align-items: flex-start;
    justify-content: center;
    background-color: var(--field_bg);
    overflow: hidden;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 4px;
      top: 50%;
      transform: translateY(-50%);
      height: 6px;
      width: 6px;
      border: 1px solid #fff;
      border-radius: 50%;
      background-color: var(--icons_low_contrast);
    }

    &.accent-primary {
      background-color: var(--accent_active);

      &:before {
        background-color: var(--accent_primary);
      }
    }

    &.accent-warning {
      background-color: var(--accent_warning_bg);

      &:before {
        background-color: var(--accent_warning);
      }
    }

    &.accent-success {
      background-color: var(--accent_success_bg);

      &:before {
        background-color: var(--accent_success);
      }
    }

    .r-text.caption {
      cursor: pointer;
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "more": "Подробнее"
  },
  "en": {
    "more": "More details"
  }
}
</i18n>
