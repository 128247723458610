<template>
  <div class="sc-status-widget type-2">
    <div class="sc-status-widget__list">
      <div
        v-for="(item, i) in filteredData"
        :key="item + i"
        class="sc-status-widget__list-item"
      >
        <r-text
          :size="28"
          style="font-weight:600"
          :color="colors[item.type] || colors.default"
        >
          {{ item.value }}
        </r-text>
        <r-text type="caption">
          {{ $t(item.type) }}
        </r-text>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    statusData: {
      type: Object,
      default: () => null,
      required: true
    },
    colors: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    filteredData() {
      return this.statusData.items
        ? this.statusData.items.filter(
          e => e.type === 'work' || e.type === 'error'
        )
        : []
    }
  }
}
</script>

<style lang="scss">
.sc-status-widget {
  &.type-2 {
    .sc-status-widget__list {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: space-between;
      &-item {
        width: 50%;
        min-width: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "work": "Работают",
    "error": "Ошибка"
  },
  "en": {
    "work": "Work",
    "error": "Error"
  }
}
</i18n>
