export const isInstrumentActive = function(components, component, instrument) {
  const roleComponent = components?.find(c => c.id === component)
  const roleInstrument = roleComponent?.instruments?.find(i => i.id === instrument)

  return !!(roleInstrument && roleInstrument._action !== 'deleted')
}

export const isComponentActive = function(components, component) {
  const roleComponent = components?.find(c => c.id === component)

  return !!(roleComponent && roleComponent._action !== 'deleted')
}

export const isModuleActive = function(module, components) {
  const moduleComponentsIds = module.components.map(c => c.id)

  return moduleComponentsIds.every(
    c => components.find(e => e.id === c && e._action !== 'deleted')
  )
}
