<template>
  <pot1 />
</template>

<script>
import Pot1 from '@/components/pot-1/pot-1'

export default {
  components: {
    Pot1
  }
}
</script>

<style lang="scss"></style>
