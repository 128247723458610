<template>
  <el-dialog
    :title="modalTitle"
    :visible.sync="newCardId"
    width="400px"
    append-to-body
    class="r-modal-window"
    destroy-on-close
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div
      v-loading="loading"
      class="odd-new-card"
    >
      <div
        v-if="object"
        class="odd-new-card__attributes"
      >
        <sign-attributes
          v-if="type === 'signs'"
          :object="object.properties"
          :signs="signs"
          @add-sign="addSign"
          @remove-sign="removeSign"
        />
        <event-create-attributes
          v-else-if="type === 'events'"
          :object="object.properties"
          :create-mode="true"
          :geometry-type="object.geometry.type"
        />
      </div>
      <r-button
        type="primary"
        :loading="saving"
        :disabled="isSaveDisabled"
        @click="saveObject"
      >
        Подтвердить
      </r-button>
    </div>
  </el-dialog>
</template>

<script>
import SignAttributes from './sign-attributes'
import EventCreateAttributes from './event-create-attributes'
import cloneDeep from 'lodash.clonedeep'
import { signTemplate } from './configs'

export default {
  components: {
    SignAttributes,
    EventCreateAttributes
  },
  data() {
    return {
      loading: false,
      saving: false,
      signs: [],
      signsClone: [],
      object: null
    }
  },
  computed: {
    modalTitle() {
      switch (this.type) {
        case 'events':
          return 'Новое событие'
        case 'signs':
          return this.object?.rack ? 'Карточка стойки' : 'Новая стойка'
        default:
          return 'Новый объект'
      }
    },
    eventClasses() {
      return this.$store.state.odd.books.eventClasses
    },
    newCardId() {
      return !!this.$store.state.odd.newCardId
    },
    type() {
      const { type } = this.$store.state.odd.editorState

      return type === 'signs' ? 'signs' : 'events'
    },
    isSaveDisabled() {
      if (!this.object) return true

      const { properties } = this.object

      if (this.type === 'signs') {
        const queryAttr = ['name', 'sign_icon_id']
        const signsDataFalse = this.signs?.some(e => {
          return queryAttr.some(q => !e[q])
        })
        return !!(!properties?.name || !this.signs?.length || signsDataFalse)
      }

      return !!(!properties?.name && !properties?.event_class_id)
    }
  },
  watch: {
    newCardId() {
      this.getCardData()
    }
  },
  created() {
    this.getCardData()
  },
  methods: {
    handleClose() {
      this.$store.commit('SET_ODD_FIELD', { field: 'newCardId', value: null })
    },
    async saveSigns(event_id) {
      const { ids } = this.$store.state.odd.model
      const url = `objectInfo/${ids.signs}`
      const { properties, geometry } = this.object
      const { name, sign_icon_id } = properties

      const startGeom = {
        type: 'Point',
        coordinates: geometry.coordinates[0]
      }
      const endGeom = {
        type: 'Point',
        coordinates: geometry.coordinates[geometry.coordinates.length - 1]
      }

      const data = [
        {
          name: `${name} (знак)`,
          geom: startGeom,
          projection: startGeom,
          event_id,
          sign_icon_id
        },
        {
          name: `${name} (знак)`,
          geom: endGeom,
          projection: endGeom,
          event_id,
          sign_icon_id
        }
      ]

      await this.$store.dispatch('POST_REQUEST', { url, data })
    },
    saveObject() {
      switch (this.type) {
        case 'events':
          this.saveEvent()
          break
        case 'signs':
          this.saveRack()
          break
      }
    },
    async saveEvent() {
      try {
        this.saving = true

        const { ids } = this.$store.state.odd.model
        const url = `objectInfo/${ids[this.type]}`
        const { properties } = this.object
        if (properties?.sign_icon_id) {
          const { end_time } = properties
          properties.end_time = end_time ? this.$rDate.format(end_time, 'iso') : null
        }
        const data = [
          {
            ...properties,
            geom: this.object.geometry
          }
        ]

        const response = await this.$store.dispatch('POST_REQUEST', {
          url,
          data
        })

        if (this.type === 'events') {
          await this.createAdditionalTable(response)
          this.openModal(response)

          if (this.object.properties.sign_icon_id) {
            await this.saveSigns(response.data?.[0]?.id)
          }
        }

        this.handleClose()
        this.$router.push('/app/odd/objects')

        this.$store.commit('SET_ODD_FIELD', {
          field: 'needToUpdate',
          value: true
        })
      } catch (e) {
        throw new Error(e)
      } finally {
        this.saving = false
      }
    },
    async saveRack() {
      try {
        this.saving = true

        const url = 'objectInfo/telemetry.racks'
        const { properties } = this.object
        const { id, link_id } = properties
        console.log(properties)
        if (properties?.sign_icon_id) {
          const { end_time } = properties
          properties.end_time = end_time ? this.$rDate.format(end_time, 'iso') : null
        }
        const data = !id ? [
          {
            ...properties,
            geom: this.object.geometry,
            signs: this.getSigns(link_id)
          }
        ] : [{ id, signs: this.getSigns(link_id) }]

        await this.$store.dispatch('POST_REQUEST', {
          url,
          data
        })

        this.handleClose()

        if (this.$route.name !== 'odd-objects') {
          this.$router.push('/app/odd/objects')
        }

        this.$store.commit('SET_ODD_FIELD', {
          field: 'needToUpdate',
          value: true
        })
      } catch (e) {
        throw new Error(e)
      } finally {
        this.saving = false
      }
    },
    getSigns(link_id) {
      const positions = [1, 2, 3, 4, 5]

      this.signs.forEach(({ rack_position }) => {
        const index = positions.findIndex(p => p === rack_position)

        if (index > -1) {
          positions.splice(index, 1)
        }
      })

      const parsed = this.signs.map(e => {
        e.geom = this.object.geometry

        if (!e.created_at) {
          delete e.id
          e.rack_position = positions[0]
          positions.splice(0, 1)
        }

        e.link_id = link_id

        return e
      })

      const deleted = this.signsClone
        .filter(sd => !this.signs.find(si => si.id === sd.id))
        .map(s => {
          s.disabled = true
          return s
        })

      return [...parsed, ...deleted]
    },
    openModal({ data }) {
      const [{ id, event_class_id }] = data
      this.$store.commit('SET_ODD_FIELD', {
        field: 'cardId',
        value: id
      })
      this.$store.commit('SET_ODD_FIELD', {
        field: 'cardType',
        value: this.type
      })
      this.$store.commit('SET_ODD_FIELD', {
        field: 'needToUpdate',
        value: true
      })
      const { modal } = this.getAdditionalInfo(event_class_id)
      if (modal) {
        this.$store.commit('SET_ODD_FIELD', {
          field: 'modalType',
          value: modal
        })
        this.$store.commit('OPEN_MODAL_WINDOW', 'odd-modal-card')
      }
    },
    getAdditionalInfo(event_class_id) {
      const eventClassName = this.eventClasses.find(
        ({ id }) => id === event_class_id
      )?.name
      const additionalEntities = {
        ДТП: { table: 'trafficaccident', modal: 'accident' },
        Недостатки: { table: 'road_block', modal: 'closure' },
        'Ремонтные работы': { table: 'road_block', modal: 'closure' }
      }
      return additionalEntities[eventClassName]
    },
    async createAdditionalTable({ data }) {
      const [{ id, event_class_id }] = data
      const { table } = this.getAdditionalInfo(event_class_id)
      if (!id || !table) return

      const source_id = this.$store.state.services[table]
      const url = `objectInfo/${source_id}`
      await this.$store.dispatch('POST_REQUEST', {
        url,
        data: { event_id: id }
      })
    },
    async getCardData() {
      const { createdObject } = this.$store.state.odd.editorState

      this.object = createdObject

      const rackId = this.object?.rack

      this.signs = []
      this.signsClone = cloneDeep(this.signs)

      if (!rackId) {
        this.addSign()
        return
      }

      try {
        this.loading = true

        const config = {
          where: [
            {
              field: 'id',
              op: '=',
              value: rackId
            }
          ],
          include: {
            signs: {}
          }
        }

        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/telemetry.racks?config=${JSON.stringify(config)}`
        })
        const rack = data[rackId]

        this.object.properties = rack
        this.signs = [...rack?.signs]
        this.signsClone = cloneDeep(this.signs)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    addSign() {
      this.loading = true

      setTimeout(() => {
        const sign = signTemplate()
        const date = new Date()
        sign.id = this.$rDate.format(date, 'x') + this.signs?.length
        sign.name = `Новый знак от ${this.$rDate.format(date, 'DD.MM.YY')}`

        this.signs.push(sign)

        this.loading = false
      }, 8)
    },
    removeSign(sign) {
      const index = this.signs.findIndex(s => s.id === sign.id)

      if (index > -1) {
        this.signs.splice(index, 1)
      }

      this.$emit('remove-sign', sign)
    }
  }
}
</script>

<style lang="scss">
.odd-new-card {
  display: grid;
  grid-gap: 1rem;

  .r-button {
    justify-self: start;
  }
}
</style>
