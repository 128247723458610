<template>
  <div class="inspection-list-item doc-item-info">
    <div class="inspection-list-item__label">
      <r-title
        type="title-2"
        color-type="subhead"
      >
        {{ item.label }}
      </r-title>
    </div>
    <div class="inspection-list-item__controls">
      <el-radio
        v-model="item.value"
        class="r-radio"
        :label="true"
      />
      <el-radio
        v-model="item.value"
        class="r-radio error"
        :label="false"
      />
    </div>
    <div class="doc-item-info__inputs">
      <div class="doc-item-info__inputs-item">
        <r-text color-type="secondary">
          № Документа
        </r-text>
        <el-input
          v-if="parent"
          v-model="source[parent][attr_name]"
          clearable
          class="r-input"
        />
        <el-input
          v-else
          v-model="source[attr_name]"
          clearable
          class="r-input"
        />
      </div>
      <div class="doc-item-info__inputs-item">
        <r-text color-type="secondary">
          Примечание
        </r-text>
        <el-input
          v-model="item.comment"
          class="r-input"
          placeholder="Введите текст"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    docs: {
      type: Array,
      required: true
    }
  },
  computed: {
    doc() {
      return this.docs.find(({ title }) => title === this.item.label)
    },
    label() {
      return this.item.label
    },
    parent() {
      return this.doc.parent
    },
    attr_name() {
      return this.doc.attr_name
    }
  }
}
</script>

<style lang="scss" scoped>
.doc-item-info {
  display: grid;
  border-bottom: none !important;

  &__inputs {
    &-item {
      display: grid;
      grid-gap: 0.5rem;
    }

    grid-column: 1 / 3;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    align-items: center;
  }
}
</style>
