<template>
  <div class="account-menu__button">
    <div :class="['account-menu__button-ava', !user.avatar ? 'border' : '']">
      <img
        v-if="user.avatar"
        :src="`${baseURL}/${downloadURL}/${user.avatar}`"
        :alt="$t('user-avatar')"
      >
      <r-icon
        v-else
        name="user-avatar"
        :size="22"
      />
    </div>
    <r-icon arrow-down />
  </div>
</template>

<script>
import constants from '@/constants/url'

export default {
  props: {
    user: {
      type: Object,
      default: () => null,
      required: true
    }
  },
  data() {
    return {
      baseURL: constants.URL,
      downloadURL: 'download_share_resource'
    }
  }
}
</script>

<style lang="scss" scoped>
.account-menu {
  &__button {
    display: flex;
    align-items: center;
    height: 36px;
    padding: 0 0.25rem;
    border-radius: var(--border-radius);
    cursor: pointer;

    &:hover {
      background-color: var(--accent_selected);
    }

    &-ava {
      width: 28px;
      height: 28px;
      position: relative;
      border-radius: 50%;
      overflow: hidden;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 120%;
        width: auto;
      }
    }

    & > i {
      color: var(--text_primary) !important;
      font-size: 14px;
      margin-left: 5px;
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "user-avatar": "Аватар пользователя"
  },
  "en": {
    "user-avatar": "User avatar"
  }
}
</i18n>
