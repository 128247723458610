<template>
  <div class="pt-editor-controls">
    <div class="pt-editor-controls__row">
      <r-button
        type="success"
        :loading="saving"
        :disabled="isSavingDisabled"
        @click="saveChanges"
      >
        Сохранить изменения
      </r-button>
      <!-- :loading="deleting" -->
      <r-delete-button
        v-if="editorState.id"
        simple
        mini
        @delete="handleDelete"
      />
    </div>
    <div class="pt-editor-controls__row">
      <r-button
        simple
        mini
        @click="back"
      >
        Отменить и выйти
      </r-button>
    </div>
  </div>
</template>

<script>
import { notifyFactory } from '@/utils'

export default {
  data() {
    return {
      saving: false,
      deleting: false
    }
  },
  computed: {
    editorState() {
      return this.$store.state.publicTransport.editorState
    },
    isSavingDisabled() {
      const { properties, geometry } = this.editorState

      if (!geometry) return true

      const requiredProps = ['name']

      return requiredProps.some(prop => !properties[prop])
    }
  },
  methods: {
    back() {
      this.$emit('back')
    },
    getData() {
      const { type } = this.editorState

      if (type === 'stop_points') {
        return this.getStopPointData()
      } else {
        return this.getRouteData()
      }
    },
    getStopPointData() {
      const { geometry, properties, stopGeom } = this.editorState

      const {
        name,
        id,
        address,
        is_active,
        on_demand,
        node_id,
        link_id,
        stop_geom
      } = properties

      return {
        geom: id ? stopGeom : stop_geom,
        stop_geom: geometry,
        address,
        is_active,
        on_demand,
        name,
        id: id || undefined,
        node_id: node_id || undefined,
        link_id: link_id || undefined
      }
    },
    getRouteData() {
      const {
        geometry,
        properties,
        lineRouteItems,
        waypoints
      } = this.editorState

      const {
        id,
        name,
        contractor_id,
        color,
        description,
        active,
        valid_from,
        valid_until,
        line_routes: lineRoutes
      } = properties
      const start_stop_point_id = lineRouteItems[0].stop_point_id
      const end_stop_point_id =
        lineRouteItems[lineRouteItems.length - 1].stop_point_id
      const lrId = lineRoutes?.[0]?.id || undefined

      const line_routes = [
        {
          id: lrId,
          line_route_items: lineRouteItems,
          geom: lrId ? undefined : geometry,
          waypoints: lrId ? undefined : waypoints
        }
      ]

      return {
        id: id || undefined,
        valid_from: valid_from ? this.$rDate.format(valid_from, 'iso') : null,
        valid_until: valid_until
          ? this.$rDate.format(valid_until, 'iso')
          : null,
        start_stop_point_id,
        end_stop_point_id,
        line_routes,
        active,
        name,
        description,
        contractor_id,
        color
      }
    },
    async saveRouteTransport(line_id) {
      const { routeTransport, initialRouteTransport } = this.editorState

      const sourceId = 'f09e59fb-ee58-482c-8b0f-9ac520cf2099'
      const url = `objectInfo/${sourceId}`

      const newTransport = routeTransport
        ?.filter(item => (item.line_vehicle?.every(lv => {
          if (!lv.id) return true
          if (lv.line_id !== line_id) return true
          return false
        })))
        ?.map(({ id }) => ({
          id,
          line_vehicle: [
            {
              line_id
            }
          ]
        }))
      const deletedTransport = initialRouteTransport
        ?.filter(({ id }) => routeTransport.findIndex(t => t.id === id) === -1)
        ?.map(item => {
          const lineVehicleId = item?.line_vehicle?.find(lv => lv.line_id === line_id)?.id
          return {
            id: item.id,
            line_vehicle: [
              {
                id: lineVehicleId,
                disabled: true
              }
            ]
          }
        })

      const data = [...newTransport, ...deletedTransport]

      await this.$store.dispatch('POST_REQUEST', { url, data })
    },
    async saveChanges() {
      const { layerId, type } = this.editorState

      try {
        this.saving = true

        const url = `objectInfo/${layerId}`
        const data = this.getData()

        if (!data) return

        const response = await this.$store.dispatch('POST_REQUEST', {
          url,
          data
        })

        if (type === 'lines') {
          await this.saveRouteTransport(response.data[0].id)
        }

        const title = 'Сохранение выполнено'
        const message = 'Объект успешно сохранен'
        this.$notify(notifyFactory('succcess', title, message))

        this.back()
      } catch (error) {
        console.log(error)

        const title = 'Сохранение не выполнено'
        const message = 'Не удалось сохранить объект'
        this.$notify(notifyFactory('error', title, message))
      } finally {
        this.saving = false
      }
    },
    handleDelete() {
      this.$confirm('Вы уверены, что хотите удалить данный объект?', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Отменить',
        type: 'warning'
      })
        .then(_ => {
          this.deleteObject()
        })
        .catch(_ => {})
    },
    async deleteObject() {
      const { id, layerId } = this.editorState

      try {
        this.deleting = true

        const url = `objectInfo/${layerId}?id=${id}`
        await this.$store.dispatch('DELETE_REQUEST', { url })

        const title = 'Удаление выполнено'
        const message = 'Объект успешно удален'
        this.$notify(notifyFactory('succcess', title, message))

        this.back()
      } catch (error) {
        console.log(error)

        const title = 'Удаление не выполнено'
        const message = 'Не удалось удалить объект'
        this.$notify(notifyFactory('error', title, message))
      } finally {
        this.deleting = false
      }
    }
  }
}
</script>

<style lang="scss">
.pt-editor-controls {
  display: grid;
  grid-gap: 0.5rem;

  &__row {
    display: grid;
    grid-gap: 0.5rem;
    grid-auto-flow: column;

    .r-button {
      width: 100% !important;
    }
  }
}
</style>
