export const tasksClassification = {
  GeojsonImporter: { description: 'Импорт GEOJSON' },
  DataBaseImporter: { description: 'Подключение PostgreSQL таблицы' },
  SkimMatrix: { description: null },
  TileLayerImporter: { description: 'Импорт TileLayer' },
  DemandMatrix: { description: null },
  StatisticsDataWriter: { description: null },
  CalculationRuner: { description: null },
  ChsmeoCamsDataWriter: { description: null },
  LTMProcess: { description: 'Расчет LTM' },
  DataImport: { description: 'Импорт данных' },
  TestWorker: { description: null },
  DueProcess: { description: 'Рачсет DUE' },
  OsrmProcess: { description: 'Подготовка данных для OSRM' },
  VisumProcess: { description: 'Тестовый расчет Visum' },
  STA: { description: null },
  GdbImporter: { description: 'Импорт GDB' },
  NetImporter: { description: 'Импорт NET' },
  DueImporter: { description: 'Импорт DUE' },
  CustomImporter: { description: 'Создание пользовательского слоя' },
  CsvImporter: { description: 'Импорт CSV' },
  DmdImporter: { description: 'Импорт DMD' },
  UserImporter: { description: 'Импорт пользовательских данных' },
  JsonImporter: { description: 'Импорт JSON' },
  GtfsImporter: { description: 'Импорт GTFS' },
  ShpImporter: { description: 'Импорт SHP' },
  OsmExport: { description: 'Экспорт в OSM' },
  LtmExport: { description: 'Экспорт данных для LTM' },
  DueExport: { description: 'Экспорт данных для DUE' },
  DorfondImporter: { description: 'Импорт данных Dorfond' },
  TracksImporter: { description: 'Импорт данных трэков' },
  DnlProcess: { description: 'Расчет DNL' }
}

export const getTaskImage = worker_class => {
  switch (worker_class) {
    case 'GeojsonImporter':
    case 'DataBaseImporter':
    case 'TileLayerImporter':
    case 'DataImport':
    case 'GdbImporter':
    case 'NetImporter':
    case 'DueImporter':
    case 'CustomImporter':
    case 'CsvImporter':
    case 'DmdImporter':
    case 'UserImporter':
    case 'JsonImporter':
    case 'GtfsImporter':
    case 'ShpImporter':
    case 'DorfondImporter':
    case 'TracksImporter':
      return 'tasks-list__item__image import'
    case 'SkimMatrix':
    case 'DemandMatrix':
    case 'CalculationRuner':
    case 'LTMProcess':
    case 'OsrmProcess':
    case 'VisumProcess':
    case 'STA':
    case 'DnlProcess':
      return 'tasks-list__item__image calc'
    default:
      return 'tasks-list__item__image other'
  }
}

export const getTaskHint = worker_class => {
  switch (worker_class) {
    case 'GeojsonImporter':
    case 'DataBaseImporter':
    case 'TileLayerImporter':
    case 'DataImport':
    case 'GdbImporter':
    case 'NetImporter':
    case 'DueImporter':
    case 'CustomImporter':
    case 'CsvImporter':
    case 'DmdImporter':
    case 'UserImporter':
    case 'JsonImporter':
    case 'GtfsImporter':
    case 'ShpImporter':
    case 'DorfondImporter':
    case 'TracksImporter':
      return 'Импорт данных'
    case 'SkimMatrix':
    case 'DemandMatrix':
    case 'CalculationRuner':
    case 'LTMProcess':
    case 'OsrmProcess':
    case 'VisumProcess':
    case 'STA':
    case 'DnlProcess':
      return 'Расчет'
    default:
      return 'Другое'
  }
}
