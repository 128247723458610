export const related = {
  employees: {
    id: 'a28c528a-64dd-41f9-94b9-aefd7485f55c',
    data: [],
    config: {
      include: { subcontractor: {} },
      only: ['name', 'id', 'position', 'is_prime']
    }
  },
  contract: {
    id: '8325e612-6821-4a40-a6b7-5722d8a1be9f',
    data: [],
    config: {}
  },
  status: {
    id: '02d13ee0-0ce0-4e2b-ab5e-f7fa8b6f69d0',
    data: [],
    config: { only: ['name', 'id'] }
  },
  type: {
    id: '06e0fb0f-6758-4b6a-923f-39a7c6f44c1b',
    data: [],
    config: { only: ['name', 'id'] }
  },
  contractor: {
    id: 'ef021b44-a650-478e-a25f-ace1e9b545ca',
    data: [],
    config: { only: ['name', 'id'] }
  },
  event: {
    id: '',
    data: [],
    config: {
      include: {
        event_class: {
          only: ['name']
        },
        road_block: {
          include: { recommendations: {} }
        },
        trafficaccident: {
          include: {
            injureds: {},
            intruders: {
              include: {
                vehicle: {
                  only: ['id', 'reg_number'],
                  include: {
                    vehicle_type: { only: ['id', 'name'] },
                    model: { only: ['id', 'name'] }
                  }
                }
              }
            },
            corrective_actions: {}
          }
        }
      }
    }
  },
  vehicle: {
    id: 'f09e59fb-ee58-482c-8b0f-9ac520cf2099',
    data: [],
    config: {
      only: ['reg_number', 'id', 'vehicle_type_id', 'model_id'],
      include: { vehicle_type: {}, model: {} }
    }
  },
  vehicleTypes: {
    id: 'aaf863b8-3df0-4264-9b65-773fee7aaeef',
    data: [],
    config: {
      only: ['name', 'id'],
      include: { vehicle_type_group: { only: ['id', 'name'] } }
    }
  },
  subproject: {
    id: 'fe1656be-32eb-40e4-97f4-835fcd6ded4a',
    data: [],
    config: { only: ['name', 'id'] }
  },
  guilty_three_vio: {
    id: '1f702ed3-b18d-4280-8867-2ac2addefe23',
    data: [],
    config: { only: ['name', 'id'] }
  },
  accident_category_custom: {
    id: null,
    data: [
      { id: 'light', name: 'Легкое' },
      { id: 'average', name: 'Среднее' },
      { id: 'serious', name: 'Серьезное' }
    ]
  },
  templates: {
    id: null,
    data: [
      { id: 'assignments', name: 'Акт-предписание' },
      { id: 'violations', name: 'Акт-нарушение' }
    ]
  }
}
