<template>
  <r-simple-card title="Цвет">
    <div class="map-color-picker">
      <div
        v-if="!field"
        class="map-color-picker__simple"
      >
        <r-color-picker
          :color="layerStyle.paint[`${property}-color`]"
          :color-opacity="layerStyle.paint[`${property}-opacity`]"
          @change="changeProperty('paint', property, $event)"
        />
      </div>
      <div
        v-else-if="type === 'classes'"
        class="map-color-picker__palette"
      >
        <el-select
          v-model="currentPalette"
          class="r-select dark palette"
          placeholder=""
          size="default"
          :popper-append-to-body="false"
          popper-class="map-color-picker__palette-popper"
          value-key="id"
          @change="changePalette"
        >
          <template slot="prefix">
            <palette-colors
              :colors="currentPalette.value || []"
              :is-current="true"
            />
          </template>
          <el-option
            v-for="item in palettes"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
            <palette-colors :colors="item.value" />
          </el-option>
        </el-select>
      </div>
      <div class="map-color-picker__more-block">
        <div class="map-color-picker__attributes">
          <r-text
            type="caption"
            style="margin-bottom: 4px;"
          >
            Зависит от атрибута
          </r-text>
          <source-attributes
            :single="true"
            :active-fields="field"
            :tree-data="getAttributes(objectFields, false, false, true)"
            @handleNodeClick="changeField($event)"
          />
        </div>
        <classifications
          v-if="type === 'classes' && field"
          :conditions="conditions"
          @apply-classes="applyClasses"
          @change-conditions="changeConditions"
        />
        <strings
          v-else-if="field"
          :id="id"
          :field="field"
          :default="defaultStringValue"
          :values="stringsValues"
          @apply-strings="applyStrings"
          @change-strings="changeStrings"
        />
      </div>
    </div>
  </r-simple-card>
</template>

<script>
import SourceAttributes from '@/components/map/components/source-attributes/source-attributes'
import Classifications from './classifications'
import Strings from './strings'
import PaletteColors from './palette-colors'
import { palettes } from '../config'
import {
  getAttributes,
  getConditions,
  updateObjectFieldsConfigs
} from '@/utils'

export default {
  components: {
    SourceAttributes,
    Classifications,
    Strings,
    PaletteColors
  },
  props: {
    id: {
      type: String,
      required: true
    },
    layerStyle: {
      type: Object,
      required: true
    },
    controllers: {
      type: Object,
      required: true
    },
    property: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      moreShown: false,
      field:
        this.$store.state.map.styleConfig[this.id].classesStyle[
          `${this.property}-color`
        ].field ||
        this.$store.state.map.styleConfig[this.id].stringsStyle[
          `${this.property}-color`
        ].field ||
        '',
      currentPalette:
        this.$store.state.map.styleConfig[this.id].classesStyle[
          `${this.property}-color`
        ].currentPalette || {},
      objectFields: this.$clientStore.getDataFields(
        this.$store.state.profiles.sourceIdById[this.id]
      ),
      conditions:
        this.$store.state.map.styleConfig[this.id].classesStyle[
          `${this.property}-color`
        ].conditions || [],
      defaultStringValue: this.$store.state.map.styleConfig[this.id]
        .stringsStyle[`${this.property}-color`].defaultValue,
      stringsValues: this.$store.state.map.styleConfig[this.id].stringsStyle[
        `${this.property}-color`
      ].values,
      type: this.$store.state.map.styleConfig[this.id].stringsStyle[
        `${this.property}-color`
      ].field
        ? 'strings'
        : 'classes',
      palettes
    }
  },
  watch: {
    id: function() {
      const { classesStyle, stringsStyle } = this.$store.state.map.styleConfig[
        this.id
      ]

      if (!classesStyle || !stringsStyle) return

      // classes
      const currentClasses = classesStyle[`${this.property}-color`]

      if (!currentClasses) return

      const currentStrings = stringsStyle[`${this.property}-color`]
      const path = currentClasses.field || currentStrings.field || ''

      this.currentPalette = currentClasses.currentPalette || {}
      this.conditions = currentClasses.conditions || []
      // strings
      this.defaultStringValue = currentStrings.defaultValue
      this.stringsValues = currentStrings.values
      this.objectFields = this.$clientStore.getDataFields(this.id)
      this.type = currentStrings.field ? 'strings' : 'classes'
      if (this.field) this.changeField({ path })
      else {
        this.field = ''
        this.moreShown = false
        this.changeField({ path })
      }
    }
  },
  methods: {
    changeProperty(type, property, value) {
      if (value) {
        const { color, opacity } = value

        this.layerStyle.paint[`${property}-color`] = color
        this.layerStyle.paint[`${property}-opacity`] = opacity
      }

      this.$emit('change-property', { type, field: `${property}-color` })
      this.$emit('change-property', { type, field: `${property}-opacity` })
    },
    async changeField(field) {
      const { path, config } = field
      const fields = this.controllers.layers.map.objectFields[this.id]
      const { type } = fields.find(f => f.title === path) || {}

      this.type = type === 'string' ? type : 'classes'

      if (path === this.field) {
        this.field = ''
        this.changeProperty('paint', this.property)
        this.clearStrings()
        this.clearClasses()
      } else {
        this.field = path
        updateObjectFieldsConfigs(
          this.controllers.layers.map,
          this.id,
          path,
          config
        )
        await this.controllers.layers.updateLayerData(this.id, [this.field])

        if (this.type === 'classes') {
          if (!this.currentPalette.id) this.currentPalette = palettes[0]
          this.clearStrings()
          this.setClassesPalette(this.id, this.currentPalette)
          this.applyClasses()
        } else {
          this.clearClasses()
        }
      }
    },
    changePalette() {
      this.setClassesPalette()
      this.applyClasses()
    },
    saveConfigToStore(type, isClear) {
      if (type === 'classes') {
        this.$store.commit('SET_FIELD_CLASSES_STYLE', {
          id: this.id,
          field: `${this.property}-color`,
          config: {
            field: isClear ? '' : this.field,
            conditions: this.conditions,
            currentPalette: this.currentPalette
          }
        })
      } else {
        this.$store.commit('SET_FIELD_STRINGS_STYLE', {
          id: this.id,
          field: `${this.property}-color`,
          config: {
            field: isClear ? '' : this.field,
            defaultValue: this.defaultStringValue,
            values: this.stringsValues
          }
        })
      }
    },
    applyClasses() {
      this.saveConfigToStore('classes')
      this.$emit('change-classes', { field: `${this.property}-color` })
    },
    changeConditions(conditions) {
      this.$set(this, 'conditions', conditions)
    },
    setClassesPalette() {
      this.conditions = this.getConditions(
        this.id,
        this.currentPalette,
        this.field,
        this
      )
    },
    clearClasses() {
      this.currentPalette = {}
      this.conditions = []
      this.saveConfigToStore('classes', true)
    },
    applyStrings() {
      this.saveConfigToStore('strings')
      this.$emit('change-strings', { field: `${this.property}-color` })
    },
    changeStrings({ type, value }) {
      const prop = type === 'default' ? 'defaultStringValue' : 'stringValues'
      this[prop] = value
    },
    clearStrings() {
      this.stringsValues = []
      this.defaultStringValue = 'rgba(255,255,255,1)'
      this.saveConfigToStore('strings', true)
    },
    getConditions,
    getAttributes
  }
}
</script>

<style lang="scss">
.map-color-picker {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 5px;
    line-height: 24px;

    i {
      font-size: 14px;
    }
  }

  &__container {
    padding-left: 10px;
  }

  &__opacity {
    margin-top: 10px;

    .el-input-number {
      width: 30% !important;
    }
  }

  &__simple {
    margin-bottom: 15px;
  }

  &__palette {
    margin-bottom: 15px;

    .el-select-dropdown__item {
      height: 36px !important;
      line-height: 36px !important;
    }

    .el-input__inner {
      min-height: 10px !important;
    }
  }
}
</style>
