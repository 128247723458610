export const related = {
  vehicle_types: {
    id: 1,
    urlName: 'vehicleTypes',
    data: [],
    config: { only: ['name', 'id'] }
  },
  main_contractor_id: {
    id: 2,
    urlName: 'mainContractor',
    data: [],
    config: { only: ['name', 'id'] }
  },
  vehicle_models: {
    id: 3,
    urlName: 'vehicleModels',
    data: [],
    config: { only: ['name', 'id'] }
  },
  vehicle_type_group: {
    id: 3,
    urlName: 'vehicleGroups',
    data: [],
    config: { only: ['name', 'id'] }
  },
  applications_status_list: {
    id: 4,
    urlName: 'applicationsStatusList',
    data: [],
    config: { only: ['name', 'id'], order: [{ name: 'desc' }] }
  },
  employees: {
    id: 5,
    urlName: 'employees',
    data: [],
    config: { only: ['name', 'position', 'id', 'is_prime'] }
  }
}
