<template>
  <div
    v-loading="loading"
    class="r-bottom-card"
  >
    <div class="r-bottom-card__main">
      <div class="r-bottom-card__header">
        <div
          v-if="title || icon"
          class="r-bottom-card__title"
        >
          <r-icon
            :name="icon"
            :size="24"
          />
          <r-text :size="16">
            {{ title }}
          </r-text>
        </div>

        <div class="r-bottom-card__actions">
          <slot name="actions" />
        </div>
      </div>

      <div class="r-bottom-card__attributes">
        <slot name="attributes" />
      </div>
    </div>

    <div class="r-bottom-card__tabs">
      <slot name="tabs" />
    </div>

    <div class="r-bottom-card__close">
      <r-button
        simple
        mini
        icon="close-delete"
        @click="closeCard"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    closeCard() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.r-bottom-card {
  width: 100%;
  height: 334px;
  background-color: var(--bg_panel_primary) !important;
  position: relative;
  display: flex;
  z-index: 1100;

  &__main {
    width: 300px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 8px;
    border-right: 1px solid transparent;
    border-color: var(--dividers_low_contrast) !important;
  }

  &__title {
    display: grid;
    align-items: center;
    padding: 0.5rem 0;
    grid-auto-flow: column;
    justify-content: start;
    grid-gap: 0.5rem;
  }

  &__attributes {
    flex: 1;
    overflow: auto;
    margin-top: 16px;
  }

  &__tabs {
    flex: 1;
    height: 100%;
    overflow: hidden;
  }

  &__close {
    position: absolute;
    right: 1rem;
    bottom: 100%;
    z-index: 1100;
    border-radius: 4px 4px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    background-color: var(--bg_panel_primary) !important;
  }
}
</style>
