<template>
  <div class="railway-control-card">
    <railway-control-card-header :info="currentCarInfo" />
    <div class="railway-control-card-content">
      <railway-control-card-status
        :disable-control="statusChangerDisabled"
        :info="currentCarInfo"
      />
      <railway-control-card-planned-way-control
        :disable-control="!!currentCarInfo.way_no"
        :info="currentCarInfo"
      />
      <railway-control-card-way-control :info="currentCarInfo" />
      <railway-control-card-info :info="currentCarInfo" />
      <r-button
        type="danger"
        @click="beforeDelete()"
      >
        Удалить вагон
      </r-button>
    </div>
  </div>
</template>

<script>
import railwayControlCardHeader from './railway-control-card-header'
import railwayControlCardStatus from './railway-control-card-status'
import railwayControlCardWayControl from './railway-control-card-way-control'
import railwayControlCardPlannedWayControl from './railway-control-card-planned-way-control'
import railwayControlCardInfo from './railway-control-card-info'
import { notifyFactory } from '@/utils'

export default {
  components: {
    railwayControlCardHeader,
    railwayControlCardStatus,
    railwayControlCardWayControl,
    railwayControlCardPlannedWayControl,
    railwayControlCardInfo
  },
  computed: {
    cardId() {
      return this.$store.state.railwayControl.cardId
    },
    allCars() {
      return this.$store.state.railwayControl.carsList
    },
    currentCarInfo() {
      return this.allCars.find(e => e.id === this.cardId)
    },
    statusChangerDisabled() {
      const statusList = this.$store.state.railwayControl.statusList
      const firstStatus = Object.values(statusList).find(
        e => e.name === 'На сортировке'
      )

      return this.currentCarInfo.status_id === firstStatus.id
    }
  },
  methods: {
    closeCard() {
      this.$store.commit('RAILWAY_CONTROL_SET_CARD_ID', '')
    },
    beforeDelete() {
      const message = 'Вагон будет удален, вы уверены?'
      const title = 'Удаление вагона'
      const confirm = 'Да'
      const cancel = 'Отмена'

      this.$confirm(message, title, {
        customClass: 'r-message-box',
        type: 'warning',
        closeOnPressEscape: false,
        closeOnClickModal: false,
        confirm,
        cancel
      })
        .then(() => {
          this.deleteCar()
        })
        .catch(() => {})
    },
    async deleteCar() {
      try {
        await this.$store.dispatch('DELETE_REQUEST', {
          url: `ZdCarriage?carriage_id=${this.cardId}`
        })

        const title = 'Удаление вагона'
        const message = 'Удаление вагона выполнено успешно'

        this.closeCard()
        this.$notify(notifyFactory('success', title, message))
        this.$store.commit('RAILWAY_CONTROL_SET_FIELD', {
          field: 'update',
          value: true
        })
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>

<style lang="scss">
.railway-control-card {
  position: absolute;
  top: 3.75rem;
  right: 0.5rem;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.08);
  background-color: var(--modal_bg);
  border-radius: var(--border-radius);
  width: 360px;
  min-height: 632px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  height: 0px;

  &-content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
    height: 0px;
    padding: 16px 8px;

    > div {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    // .r-button {
    //   margin-top: 8px;
    //   width: auto;
    //   margin-left: auto;
    // }
  }
}
</style>
