export default [
  {
    id: 1,
    label: 'Mapbox Basic',
    name: 'basic'
  },
  {
    id: 2,
    label: 'Mapbox Streets',
    name: 'streets'
  },
  {
    id: 3,
    label: 'Mapbox Bright',
    name: 'bright'
  },
  {
    id: 4,
    label: 'Mapbox Light',
    name: 'light'
  },
  {
    id: 5,
    label: 'Mapbox Dark',
    name: 'dark'
  },
  {
    id: 6,
    label: 'Mapbox Satellite',
    name: 'satellite'
  },
  {
    id: 7,
    label: 'Mapbox Satellite Streets',
    name: 'satellite-streets'
  },
  {
    id: 8,
    label: 'Local basic',
    name: 'maptiler_basic'
  },
  {
    id: 9,
    label: 'Local dark',
    name: 'maptiler_dark'
  }
]
