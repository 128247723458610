<template>
  <div class="information">
    <div class="information__wrapper">
      <div class="information__logo">
        <r-logo />
      </div>
      <div class="information__content">
        <div class="information__title">
          <r-title>
            ДМТЛП
          </r-title>
        </div>
        <div class="information__subtitle">
          <r-text>
            Система Диспетчеризации и моделирования транспортно-логистических потоков
          </r-text>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.information {
  &__wrapper,
  &__content,
  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__logo {
    height: 90px;
    width: 76px;
  }

  &__content {
    margin-top: 8px;
    max-width: 320px;
  }

  &__title {
    margin-bottom: 4px;
    text-align: center;
  }

  &__subtitle {
    text-align: center;
  }

  &__footer {
    .r-text {
      margin-top: 4px;
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "information:subtitle": "",
    "information:privacy": ""
  },
  "en": {
    "information:subtitle": "",
    "information:privacy": ""
  }
}
</i18n>
