var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map-editor-legend"},[(_vm.editorState.modelId)?_c('ul',{staticClass:"map-editor-legend__list"},[_vm._m(0),_vm._m(1),(_vm.mapMode === 'editor')?_c('li',{staticClass:"map-editor-legend__item"},[_vm._m(2),_c('div',{staticClass:"map-editor-legend__item-value"},[_vm._v(" Примыкания ")])]):_vm._e(),(_vm.mapMode === 'editor')?_c('li',{staticClass:"map-editor-legend__item"},[_vm._m(3),_c('div',{staticClass:"map-editor-legend__item-value"},[_vm._v(" Районы ")])]):_vm._e()]):_c('ul',{staticClass:"map-editor-legend__list"},[_vm._m(4),_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"map-editor-legend__item"},[_c('div',{staticClass:"map-editor-legend__item-key"},[_c('div',{staticClass:"map-editor-legend__item-symbol nodes"})]),_c('div',{staticClass:"map-editor-legend__item-value"},[_vm._v(" Узлы ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"map-editor-legend__item"},[_c('div',{staticClass:"map-editor-legend__item-key"},[_c('div',{staticClass:"map-editor-legend__item-symbol links"})]),_c('div',{staticClass:"map-editor-legend__item-value"},[_vm._v(" Отрезки ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map-editor-legend__item-key"},[_c('div',{staticClass:"map-editor-legend__item-symbol connectors"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map-editor-legend__item-key"},[_c('div',{staticClass:"map-editor-legend__item-symbol zones"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"map-editor-legend__item"},[_c('div',{staticClass:"map-editor-legend__item-key"},[_c('div',{staticClass:"map-editor-legend__item-symbol nodes"})]),_c('div',{staticClass:"map-editor-legend__item-value"},[_vm._v(" Точки ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"map-editor-legend__item"},[_c('div',{staticClass:"map-editor-legend__item-key"},[_c('div',{staticClass:"map-editor-legend__item-symbol links"})]),_c('div',{staticClass:"map-editor-legend__item-value"},[_vm._v(" Линии ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"map-editor-legend__item"},[_c('div',{staticClass:"map-editor-legend__item-key"},[_c('div',{staticClass:"map-editor-legend__item-symbol zones"})]),_c('div',{staticClass:"map-editor-legend__item-value"},[_vm._v(" Полигоны ")])])
}]

export { render, staticRenderFns }