import vue from 'vue'
import axios from 'axios'
import constants from '@/constants/url'
import cloneDeep from 'lodash.clonedeep'

const parseResponse = (resolve, reject) => data => {
  if (data.status === 211) reject(data.data)
  else resolve(data)
}

const state = {
  isLoading: false,
  usersUpdate: false,
  changeUserByName: '',
  isNewUserReady: false,
  isCreateUser: false,
  currentUser: null,
  initialCurrentUser: null,
  activeUser: null,
  initialUser: null,
  hasChanges: {
    user: false,
    modules: false,
    datasources: false,
    local_admin: false
  },

  activeUserModules: null,
  activeUserDS: null,

  initialUserModules: null,
  initialUserDS: null,

  allPerms: {
    datasources: [],
    modules: []
  }
}

const actions = {
  SAVE_USER_PERMS({ rootState }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${constants.URL}/user?user_id=${data.id}`, data, {})
        .then(parseResponse(resolve, reject))
        .catch(error => reject(error))
    })
  },
  CREATE_USER({ rootState }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${constants.URL}/user`, data, {})
        .then(parseResponse(resolve, reject))
        .catch(error => reject(error))
    })
  }
}

const mutations = {
  SET_USER_ROLES_ALL_PERMS(state, { datasources, modules }) {
    vue.set(state.allPerms, 'datasources', datasources)
    vue.set(state.allPerms, 'modules', modules)
  },
  SET_ACTIVE_USER_ROLES_USER(
    state,
    { user, modules, ds, datasources, isCreate = false }
  ) {
    state.activeUser = cloneDeep(user)
    state.activeUserModules = cloneDeep(modules)
    state.activeUserDS = cloneDeep(ds)
    state.activeUserDatasources = cloneDeep(datasources)
    vue.set(state, 'isCreateUser', isCreate)
  },
  SET_ACTIVE_USER_INFO(state, user) {
    vue.set(state, 'currentUser', user)
  },
  SET_INITIAL_USER_INFO(state, user) {
    state.initialCurrentUser = cloneDeep(user)
  },
  SET_INITIAL_USER_ROLES_USER(state, { user, modules, ds, datasources }) {
    state.initialUser = cloneDeep(user)
    state.initialUserModules = cloneDeep(modules)
    state.initialUserDS = cloneDeep(ds)
    state.activeUserDatasources = cloneDeep(datasources)
  },
  CHANGE_ACTIVEUSER_AVATAR(state, { avatar, module }) {
    switch (module) {
      case 'account':
        vue.set(state.currentUser, 'avatar', avatar)
        break
      default:
        vue.set(state.activeUser, 'avatar', avatar)
        break
    }
  },
  SET_ACTIVE_USER_CHANGES(state, { module, value }) {
    vue.set(state.hasChanges, module, value)
  },
  SET_USER_ROLES_LOADING(state, value) {
    vue.set(state, 'isLoading', value)
  },
  SET_USER_ROLES_USERS_UPDATE(state, value) {
    vue.set(state, 'usersUpdate', value)
  },
  SET_USER_ROLES_CHANGE_BY_NAME(state, name) {
    vue.set(state, 'changeUserByName', name)
  },
  SET_USER_ROLES_NEW_USER_READY(state, value) {
    vue.set(state, 'isNewUserReady', value)
  }
}

export default {
  state,
  actions,
  mutations
}
