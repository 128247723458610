<template>
  <div class="sc-chart__wrapper">
    <v-chart
      :option="chartOption"
      :autoresize="true"
      class="sc-charts"
    />
    <div class="sc-chart__legend">
      <div
        v-for="(item, i) in chartData"
        :key="item.name + i"
        class="sc-chart__legend-item"
      >
        <div class="sc-chart__legend-item-row">
          <r-text
            type="caption"
            style="margin: 0 !important; font-weight: 600;"
          >
            {{ item.name }}
          </r-text>
        </div>
        <div class="sc-chart__legend-item-row">
          <r-text>{{ item.value }}</r-text>
        </div>
      </div>
    </div>
    <div class="sc-chart__controllers">
      <el-dropdown
        class="r-dropdown"
        :hide-on-click="false"
        placement="bottom-start"
        @command="changeChartType"
      >
        <span class="el-dropdown-link">
          <r-icon
            name="settings"
            :size="14"
          />
        </span>
        <el-dropdown-menu
          slot="dropdown"
          class="r-dropdown-menu sc-chart__controllers-dropdown"
        >
          <el-dropdown-item
            v-for="item in chartsTypes"
            :key="item"
            :command="item"
          >
            <div class="r-dropdown-menu__item-wrapper">
              <span
                v-if="item === type"
                class="el-icon-check"
              />
              <r-text>{{ $t(`chart-type:${item}`) }}</r-text>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import 'echarts'
import VChart from 'vue-echarts'

import { pie, doughnut } from './configs'
import { setChartStyle, buildChart } from '../../../configs'

const chartsTypes = ['type-1', 'type-2']

export default {
  components: { VChart },
  props: {
    chartData: {
      type: Array,
      required: true,
      default: () => null
    },
    type: {
      type: String,
      validator: val => chartsTypes.indexOf(val) !== -1,
      default: 'type-1'
    },
    title: {
      type: String,
      default: ''
    },
    colors: {
      type: Array,
      default: () => null
    },
    changeChartType: {
      type: Function,
      default: () => null
    }
  },
  data() {
    return {
      isLoading: false,
      chartOption: null,

      chartsTypes,
      doughnut,
      pie
    }
  },
  computed: {
    theme() {
      return this.$store.state.theme
    },
    themeColors() {
      return this.$store.state.colors
    }
  },
  watch: {
    theme() {
      this.setChart()
    },
    type() {
      this.setChart()
    }
  },
  created() {
    this.setChart()
  },
  methods: {
    setChart() {
      this.buildChart(this)
      this.setColorTheme()
    },
    setColorTheme() {
      setChartStyle(this, this.themeColors)
    },
    buildChart
  }
}
</script>

<style lang="scss">
.sc-chart__wrapper {
  min-width: 264px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  .echarts {
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    margin-top: -72px;
    top: 0;
    left: 0;
  }
  .sc-chart {
    &__legend {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: space-between;
      width: 100%;
      overflow: auto;
      height: 44px;
      overflow-y: hidden;
      &-item {
        text-align: center;
        min-width: 70px;
        overflow: hidden;
        padding: 0 4px;
        .r-text,
        .r-text.caption-2 {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    &__controllers {
      position: absolute;
      top: 0;
      left: 0;
      .r-dropdown {
        cursor: pointer;
      }
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "chart-type:type-1": "Pie",
    "chart-type:type-2": "Doughnut"
  },
  "en": {
    "chart-type:type-1": "Pie",
    "chart-type:type-2": "Doughnut"
  }
}
</i18n>
