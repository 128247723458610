<template>
  <div :class="`sc-modal-window ${type}`">
    <div
      v-if="title"
      class="sc-modal-window__title"
    >
      <r-title>{{ $t(title) }}</r-title>
    </div>
    <div class="sc-modal-window__content">
      <slot />
    </div>
    <el-divider v-if="footer" />
    <div
      v-if="footer"
      class="sc-modal-window__footer"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    footer: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'default'
    }
  }
}
</script>

<style lang="scss">
.sc-modal-window {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  margin: -30px -16px -16px;
  .el-divider {
    margin: 0;
    background-color: var(--dividers_low_contrast) !important;
    min-height: 1px;
  }
  &__title {
    padding: 16px 40px;
    color: var(--text_primary) !important;
    margin: 0;
    text-transform: capitalize;
  }
  &__footer {
    padding: 20px 40px;
    position: relative;
  }
  &__content {
    padding: 0 40px;
    position: relative;
    overflow: hidden;
    overflow-y: auto;
  }
}
</style>

<i18n>
{
  "ru": {
    "traffic_lights": "Светофоры",
    "pdk": "Камеры ПДК",
    "road_engineering": "Дорожная техника",
    "public_transport": "Пассажирский транспорт",
    "events": "События"
  },
  "en": {
    "traffic_lights": "Traffic lights",
    "pdk": "PDK cameras",
    "road_engineering": "Road engineering",
    "public_transport": "Public transport",
    "events": "Events"
  }
}
</i18n>
