<template>
  <div class="rp-roles-datasources-list-role">
    <r-search-input
      :filter-text="filterText"
      placeholder="Поиск по названию"
      @change="e => (filterText = e)"
    />
    <ul
      v-if="filteredList && filteredList.length > 0"
      class="rp-roles-datasources-list-role-wrapper"
    >
      <li
        v-for="item in filteredList"
        :key="item.id"
        :class="[
          'rp-roles-datasources-list-role__item',
          { active: isItemActive(item) }
        ]"
        @click="selectItem(item)"
      >
        <div class="rp-roles-datasources-list-role__item-row">
          <r-icon
            :name="getIconName(item)"
            :size="24"
          />
          <div class="rp-roles-datasources-list-role__item__title">
            <r-text>{{ item.name }}</r-text>
          </div>
          <r-button
            v-if="hasAttrFilter(item)"
            type="primary"
            icon="filter"
            :no-gap="!isAttrFilterActive(item)"
            @click.native="openAttrFilter($event, item)"
          >
            {{ !isAttrFilterActive(item) ? '' : 'Изменить' }}
          </r-button>
        </div>
        <div class="rp-roles-datasources-list-role__item-row">
          <rp-roles-datasources-list-cruds :item="item" />
        </div>
      </li>
    </ul>
    <div
      v-else
      class="rp-roles-datasources-list-role__no-data"
    >
      <r-text>Нет данных</r-text>
    </div>
  </div>
</template>

<script>
import vue from 'vue'
import rpRolesDatasourcesListCruds from './rp-roles-datasources-list-cruds'
import { crudsTypes } from './configs'
import { hasCrudsChanges } from './helpers'
import cloneDeep from 'lodash.clonedeep'

export default {
  components: {
    rpRolesDatasourcesListCruds
  },
  props: {
    list: {
      type: Array,
      required: true
    },
    selectedItems: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      filterText: '',
      crudsTypes
    }
  },
  computed: {
    filteredList() {
      const list = this.list.filter(e => e._action !== 'deleted')
      if (!this.filterText?.trim()) return list || []

      return (
        list?.filter(e => {
          return e.name
            ?.trim()
            ?.toLowerCase()
            ?.includes(this.filterText?.trim()?.toLowerCase())
        }) || []
      )
    },
    roleAttrFilter() {
      return this.$store.state.rolePermission.roleAttrFilter || null
    },
    initialRoleDatasources() {
      return (
        this.$store.state.rolePermission.initialRolePerms?.datasources || []
      )
    },
    multiEdit() {
      return this.$store.state.rolePermission.multiEdit || null
    },
    vehiclesUrl() {
      return this.$store.state.services.vehicles || null
    },
    cargoesUrl() {
      return this.$store.state.services.cargoes || null
    }
  },
  methods: {
    hasAttrFilter(item) {
      const query = [this.vehiclesUrl, this.cargoesUrl]

      return query.includes(item.id)
    },
    getIconName(item) {
      if (item.datatype === 'model') return 'model-layer'

      switch (item.geom_type) {
        case 'line_string':
        case 'multi-line-string':
          return 'line-layer'
        case 'polygon':
        case 'multi_polygon':
          return 'polygon-layer'
        case 'point':
        case 'multi_point':
          return 'node-layer'
        default:
          return 'folder-layer'
      }
    },
    toggleCrud(item, crud, value) {
      if (!item.cruds) return
      if (crud === 1) {
        vue.set(item.cruds, crud, true)
        if (item.children?.length) {
          item.children.forEach(e => {
            vue.set(e.cruds, crud, true)
          })
        }
        return
      }

      vue.set(item.cruds, crud, value)
      if (item.children?.length) {
        item.children.forEach(e => {
          vue.set(e.cruds, crud, value)
        })
      }
      const hasChanges = hasCrudsChanges(this.initialRoleDatasources, item)

      if (hasChanges) {
        vue.set(item, '_action', 'updated')
      } else if (item._action === 'updated') {
        vue.set(item, '_action', undefined)
      }
    },
    selectItem(item) {
      if (!this.selectedItems) return
      if (this.multiEdit) {
        const crudsIndex = this.multiEdit.map(
          e => this.crudsTypes.find(c => c.title === e)?.index
        )
        const value = crudsIndex.some(c => !item.cruds[c])

        crudsIndex.forEach(i => {
          this.toggleCrud(item, i, value)
        })
        item = cloneDeep(item)
        return
      }

      const existIndex = this.selectedItems.findIndex(e => e.id === item.id)

      if (existIndex > -1) {
        this.selectedItems.splice(existIndex, 1)
      } else {
        this.selectedItems.push(item)
      }
    },
    isItemActive(item) {
      return !!this.selectedItems?.find(e => e.id === item.id)
    },
    isAttrFilterActive(item) {
      return item.restrictions && Object.values(item.restrictions)?.length > 0
    },
    openAttrFilter(e, item) {
      e.stopPropagation()

      if (item?.id) {
        this.$store.commit('ROLE_PERM_SET_FIELD', {
          field: 'roleAttrFilterFields',
          value: null
        })
        const roleAttrFilter =
          item.restrictions && Array.isArray(item.restrictions)
            ? item.restrictions?.filter(e => e.field !== 'id')
            : null
        this.$store.commit('ROLE_PERM_SET_FIELD', {
          field: 'roleAttrFilter',
          value: roleAttrFilter
        })
        const roleAttrFilterItemsConfig =
          item.restrictions && Array.isArray(item.restrictions)
            ? item.restrictions?.find(e => e.field === 'id')
            : null
        this.$store.commit('ROLE_PERM_SET_FIELD', {
          field: 'roleAttrFilterItemsConfig',
          value: roleAttrFilterItemsConfig
        })
        this.$store.commit('ROLE_PERM_SET_FIELD', {
          field: 'roleAttrFilterId',
          value: item.id
        })
        this.$store.commit('OPEN_MODAL_WINDOW', 'rp-attr-filter')
      }
    }
  }
}
</script>

<style lang="scss">
.rp-roles-datasources-list-role {
  padding: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0px;
  overflow: hidden;

  &-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 0;
    overflow: auto;
    margin-top: 8px;
  }

  &__item {
    position: relative;
    padding: 8px;
    border-radius: var(--border-radius);
    margin-bottom: 4px;
    cursor: pointer;
    border: 1px solid transparent;

    &__title {
      width: calc(100% - 144px) !important;
    }

    &-row {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex: 1;
      width: 100%;
      overflow: hidden;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      > * {
        flex-shrink: 0;
      }

      .r-icon {
        width: 24px;
      }

      .r-title {
        margin: 0 2px;
      }

      .r-button {
        margin-left: auto;
        padding: 4px !important;

        span {
          grid-gap: 0;
        }

        .r-icon {
          margin: 0;
        }
      }
    }

    &::after {
      content: '';
      height: 1px;
      width: 100%;
      background-color: var(--dividers_low_contrast);
      position: absolute;
      display: block;
      bottom: -2px;
      left: 0;
    }

    &:last-child {
      margin-bottom: 0;

      &::after {
        content: none;
      }
    }

    .r-icon {
      margin-right: 4px;
    }

    &:hover {
      border: 1px solid var(--accent_hover);
    }

    &.active {
      background-color: var(--accent_selected);
    }
  }

  &__no-data {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 80px;
  }

  .r-button.accent-selected {
    background-color: var(--accent_selected) !important;
  }
}
</style>
