<template>
  <div class="navbar__links">
    <ul class="navbar__links-list">
      <li
        v-for="link in filteredModuleLinks"
        :key="link.id"
        @click="setDefaultPath(link)"
      >
        <router-group :routes-group="link" />
      </li>
    </ul>
  </div>
</template>

<script>
import routerGroup from './navbar-router-group'
import { moduleLinks } from '../../config'

export default {
  components: {
    routerGroup
  },
  data() {
    return {
      moduleLinks
    }
  },
  computed: {
    filteredModuleLinks() {
      const systemModuleLinks =
        this.moduleLinks[this.instance] || this.moduleLinks.default

      return systemModuleLinks.filter(l => {
        if (!l.routesLinks) {
          return this.hasModule(l.name)
        } else {
          const hasAny = !!l.routesLinks.filter(k => this.hasModule(k.name))
            .length
          if (hasAny) return true
        }
      })
    }
  },
  methods: {
    hasModule(name) {
      return this.$store.getters.hasModule(name)
    },
    setDefaultPath(link) {
      if (link.routesLinks && link.routesLinks.length) return
      this.$store.commit('SET_DEFAULT_PATH', link.to)
      this.$store.dispatch('SAVE_MAIN_USER_CONFIG')
    }
  }
}
</script>

<style lang="scss">
.navbar__links {
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 8px;

  &-list {
    height: 100%;
    display: flex;
    align-items: center;

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 32px;
    }

    li {
      display: flex;
      height: 32px;
      box-sizing: border-box;
      min-width: 32px;
      margin-right: 4px;
      position: relative;
      align-items: center;
      &:last-child {
        margin-right: 0;
      }
    }

    .navbar__link {
      position: relative;
      display: block;
      height: 100%;
      width: 100%;
      border-radius: var(--border-radius);
      box-sizing: border-box;
      outline: none !important;
      transition: all 0.15s ease;
      border: 2px solid;
      border-color: transparent;

      &:hover {
        background-color: var(--accent_selected) !important;
      }

      &.active {
        background-color: var(--accent_selected) !important;
      }
    }
  }
}
</style>
