import vue from 'vue'

import { deleteCookie } from '@/utils'

export default {
  SET_THEME(state, value) {
    state.theme = value
  },
  SET_THEME_COLORS(state, colors) {
    vue.set(state, 'colors', colors)
  },
  SET_DEFAULT_PATH(state, value) {
    state.defaultPath = value
  },
  SET_MAIN_MODEL_ID(state, id) {
    vue.set(state, 'mainModelId', id)
  },
  CLEAR_USER_DATA(state) {
    state.datasources = {}
    // state.objectInfo = {}
    // state.objectFields = {}
    state.user_config = {
      datasources: {},
      objectInfoFields: {},
      timeFormat: 'DD-MM-YYYY HH:mm:ss',
      baselayerId: 5
    }

    state.auth.token = undefined
    state.auth.user = undefined
    // state.commonData.filteredFields = []
    deleteCookie('token')
  },
  SET_USER_CONFIG(state, { user_config, user }) {
    // console.log('user_config ---- ', user_config)
    if (user) {
      state.admin = user.admin
      state.local_admin = user.local_admin
    }
    state.instance = user_config.instance || 'dev'
    state.userId = user_config.user_id || ''
    state.locale = 'ru'
    state.theme = user_config.theme || 'dark-theme'
    state.defaultPath = user_config.default_path
      ? user_config.default_path.indexOf('pot-1-terminal') > -1
        ? '/app/map'
        : user_config.default_path
      : '/app/map'

    state.user_config.datasources = { ...user_config.datasources }
    state.user_config.objectInfoFields = { ...user_config.object_info_fields }

    if (localStorage.mapConfig) {
      const {
        bi_baselayer_id,
        bi_style_config,
        bi_requested_fields,
        bi_object_fields_configs,
        activeLayers,
        sourceIdById
      } = JSON.parse(localStorage.mapConfig)
      state.user_config.baselayerId = bi_baselayer_id
        ? Number(bi_baselayer_id)
        : 5

      if (bi_style_config) state.map.styleConfig = bi_style_config

      if (bi_requested_fields) state.map.requestedFields = bi_requested_fields
      if (bi_object_fields_configs) {
        state.map.objectFieldsConfigs = bi_object_fields_configs
      }
      if (sourceIdById) state.profiles.sourceIdById = sourceIdById
      if (activeLayers) {
        // state.mapConfigs.activeLayers.map = activeLayers
        vue.set(state.mapConfigs.activeLayers, 'map', activeLayers)
      }
    }

    if (user_config.book) {
      state.book.activeBook.source_id = user_config.book?.activeBook?.source_id
      state.book.activeBook.id = user_config.book?.activeBook?.id
      state.book.activeBook.name = user_config.book?.activeBook?.name
      state.book.activeBook.dataLength =
        user_config.book?.activeBook?.dataLength
      state.profiles.treeExpandedNodes = user_config.book.treeExpandedNodes
        ? user_config.book.treeExpandedNodes
        : []
      state.profiles.treeScrollNode = user_config.book.treeScrollNode
        ? user_config.book.treeScrollNode
        : ''
    }

    // example for

    // const moduleName = 'sit_center'

    // if (user_config[moduleName]) {
    //   const entities = ['disabledLayers', 'objectCard', 'layout']

    //   entities.forEach(e => {
    //     if (user_config[moduleName][e]) {
    //       vue.set(state, moduleName[e], user_config[moduleName][e])
    //     }
    //   })
    // }

    if (user_config.sit_center) {
      if (user_config.sit_center.disabledLayers) {
        state.situationCenter.disabledLayers =
          user_config.sit_center.disabledLayers
      }
      if (user_config.sit_center.objectCard) {
        state.situationCenter.objectCard = user_config.sit_center.objectCard
      }
      if (user_config.sit_center.layout) {
        state.situationCenter.layout = user_config.sit_center.layout
      }
      if (user_config.sit_center.activeLayers) {
        vue.set(
          state.mapConfigs.activeLayers,
          'sit_center',
          user_config.sit_center.activeLayers
        )
        state.mapConfigs.activeLayers.sit_center =
          user_config.sit_center.activeLayers
      }

      state.situationCenter.toggleVehicles = !!user_config.sit_center
        .toggleVehicles
    }

    if (user_config.external_monitoring) {
      const monitoringTmc = user_config.external_monitoring

      if (monitoringTmc.filterCategories) {
        // state.monitoringTmc.filterCategories = monitoringTmc.filterCategories
      }
      if (monitoringTmc.config) {
        state.mapConfigs.configs.monitoringTmc = monitoringTmc.config
      }
      if (monitoringTmc.baselayer) {
        vue.set(
          state.mapConfigs.baselayers,
          'monitoringTmc',
          monitoringTmc.baselayer
        )
      }
      if (monitoringTmc.activeLayers) {
        vue.set(
          state.mapConfigs.activeLayers,
          'monitoringTmc',
          monitoringTmc.activeLayers
        )
      }
    }

    if (user_config.internal_monitoring) {
      const monitoring = user_config.internal_monitoring

      // if (monitoring.statusFilter) {
      //   state.monitoring.statusFilter = monitoring.statusFilter
      // }
      // if (monitoring.filterCategories) {
      //   state.monitoring.filterCategories = monitoring.filterCategories
      // }
      if (monitoring.config) {
        state.mapConfigs.configs.monitoring = monitoring.config
      }
      if (monitoring.baselayer) {
        vue.set(state.mapConfigs.baselayers, 'monitoring', monitoring.baselayer)
      }
      if (monitoring.activeLayers) {
        vue.set(
          state.mapConfigs.activeLayers,
          'monitoring',
          monitoring.activeLayers
        )
      }
    }

    if (user_config.traffic_counts) {
      const traffic_counts = user_config.traffic_counts

      if (traffic_counts.config) {
        state.mapConfigs.configs.traffic_counts = traffic_counts.config
      }
      if (traffic_counts.baselayer) {
        vue.set(
          state.mapConfigs.baselayers,
          'traffic_counts',
          traffic_counts.baselayer
        )
      }
      if (traffic_counts.activeLayers) {
        vue.set(
          state.mapConfigs.activeLayers,
          'traffic_counts',
          traffic_counts.activeLayers
        )
      }
    }

    if (user_config.vehicle_inspection_sheets) {
      const vehicle_inspection_sheets = user_config.vehicle_inspection_sheets

      if (vehicle_inspection_sheets.config) {
        state.mapConfigs.configs.vehicle_inspection_sheets =
          vehicle_inspection_sheets.config
      }
      if (vehicle_inspection_sheets.baselayer) {
        vue.set(
          state.mapConfigs.baselayers,
          'vehicle_inspection_sheets',
          vehicle_inspection_sheets.baselayer
        )
      }
      if (vehicle_inspection_sheets.activeLayers) {
        vue.set(
          state.mapConfigs.activeLayers,
          'vehicle_inspection_sheets',
          vehicle_inspection_sheets.activeLayers
        )
      }
    }

    if (user_config.situation_center_display) {
      const situation_center_display = user_config.situation_center_display

      if (situation_center_display.config) {
        state.mapConfigs.configs.situation_center_display =
          situation_center_display.config
      }
    }

    if (user_config.modelling) {
      const modelling = user_config.modelling

      if (modelling.config) {
        state.mapConfigs.configs.modelling = modelling.config
      }
    }

    if (user_config.journal) {
      state.journal.activeType = user_config.journal.activeType
    }

    if (user_config.pot1_dispatcher) {
      state.pot1.filters = user_config.pot1_dispatcher
    }

    if (user_config.vehicle_inspection_applications) {
      state.bdd.filters = user_config.vehicle_inspection_applications
    }

    if (user_config.acts_register) {
      state.bdd.acts = user_config.acts_register
    }

    vue.set(state, 'isReadyToRender', true)
  },
  SET_OBJECT_FIELDS(state, { source_id, fields }) {
    vue.$clientStore.setDataFields(source_id, fields)
  },
  SET_BASELAYER_ID(state, id) {
    state.user_config.baselayerId = id
  },
  SET_DATASOURCE_FROM_TREE(state, { data, id }) {
    state.datasources[id] = data
  },
  ADD_LISTENER_ID(state, id) {
    state.listeners.push(id)
  }
}
