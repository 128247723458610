<template>
  <div class="pt-stops">
    <r-table-list
      :data="stops"
      :fields-names="fieldsExplanation"
      @click-handler="rowClickHandler"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      fieldsExplanation: {
        no: 'Номер',
        name: 'Название',
        address: 'Адрес',
        is_active: 'Работает',
        on_demand: 'По требованию'
      }
    }
  },
  computed: {
    stops() {
      const { properties } = this.$store.state.publicTransport.cardInfo

      if (!properties) return []
      if (!properties.line_routes) return []

      const { line_route_items } = properties.line_routes[0]

      return line_route_items
        .filter(item => !!item.stop_point)
        .map(({ stop_point }) => ({
          id: stop_point.id,
          no: stop_point.no,
          name: stop_point.name,
          is_active: stop_point.is_active ? 'Да' : 'Нет',
          on_demand: stop_point.on_demand ? 'Да' : 'Нет',
          address: stop_point.address || 'Не указан'
        }))
    }
  },
  methods: {
    rowClickHandler(object) {
      this.$store.commit('SET_PT_FIELD', {
        field: 'cardType',
        value: 'stop_points'
      })
      this.$store.commit('SET_PT_FIELD', {
        field: 'cardId',
        value: object.id
      })
    }
  }
}
</script>

<style lang="scss">
.pt-stops {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
