<template>
  <div class="doc-item-files">
    <el-upload
      ref="upload"
      drag
      :data="postFileData"
      :show-file-list="false"
      :on-progress="startLoading"
      :on-success="attach"
      :before-upload="beforeUpload"
      :action="`${url}/upload_resource`"
      :headers="headers"
    >
      <r-button
        :loading="loading"
        class="file-item-attribute__upload-button"
      >
        {{ loading ? 'Загрузка' : 'Выбрать файл' }}
      </r-button>
    </el-upload>

    <doc-item-img
      v-for="(file, i) in attached"
      :key="i"
      :source_id="source_id"
      :file="file"
      :url="url"
      @remove="$emit('remove', file.id)"
      @update="$emit('update')"
    />
  </div>
</template>

<script>
import docItemImg from './doc-item-img'
import { getHeaders } from '@/utils'

export default {
  components: {
    docItemImg
  },
  props: {
    source: {
      type: Object,
      required: true
    },
    docs: {
      type: Array,
      required: true
    },
    source_id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    attached: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      dialogVisible: false,
      loading: false
    }
  },
  computed: {
    item() {
      return this.docs.find(({ title }) => title === this.label)
    },
    attr_name() {
      return this.item?.attr_name
    },
    headers() {
      return getHeaders()
    },
    postFileData() {
      return {
        source_id: this.source_id,
        obj_id: this.source.id,
        attr_name: this.attr_name
      }
    }
  },
  methods: {
    openImg() {
      this.dialogVisible = true
    },
    attach() {
      this.$emit('update')
      this.loading = false
    },
    startLoading() {
      this.loading = true
    },
    beforeUpload({ type, size }) {
      const isImg = type === 'image/jpeg' || type === 'image/png'
      const isSuitableSize = size / 1024 / 1024 < 5

      if (!isSuitableSize) {
        this.$message.error('Файл не должен превышать 5 Мб')
      }
      if (!isImg) {
        this.$message.error('Файл должен быть в формате JPG или PNG')
      }
      return isSuitableSize && isImg
    }
  }
}
</script>

<style lang="scss">
.doc-item-files {
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
  flex-wrap: wrap;

  &-button.r-button {
    border-radius: var(--border-radius);
  }

  .el-upload-dragger {
    margin-right: 0.5rem;
    border-radius: var(--border-radius) !important;
    overflow: hidden;
    background-color: transparent;
    border: none;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1.5rem;
    align-items: center;
    height: auto;
    width: auto;

    &:hover {
      border: none;
    }
  }
}
</style>
