<template>
  <el-dialog
    width="360px"
    title="Удаление объектов"
    :visible="visible"
    class="map-editor-delete-modal"
    @close="cancel"
  >
    <div class="map-editor-delete-modal__container">
      {{ getText() }}
    </div>
    <div
      slot="footer"
      class="gcp-settings__actions"
    >
      <r-button
        simple
        @click="cancel"
      >
        Отменить
      </r-button>
      <r-delete-button
        simple
        @delete="deleteObjects"
      />
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    state: {
      type: Object,
      required: true
    },
    draw: {
      type: Object,
      required: true
    }
  },
  methods: {
    getText() {
      const { deletingIds, dependenciesCount } = this.state

      return `
        При удалении ${
          deletingIds.length === 1 ? 'данного объекта' : 'данных объектов'
        }
        также будут удалено ${dependenciesCount} зависимых объекта(-ов)
      `
    },
    cancel() {
      this.$emit('toggle-delete-modal', false)
      this.draw.changeMode('simple_select', {
        featureIds: []
      })
    },
    deleteObjects() {
      const { deletingCallback } = this.state

      deletingCallback()
      this.$emit('toggle-delete-modal', false)
    }
  }
}
</script>

<style lang="scss">
.map-editor-delete-modal {
  &__container {
    font-size: 14px;
    word-break: break-word;
    color: var(--text_secondary) !important;
  }
}
</style>
