<template>
  <div class="public-notifications">
    <r-title>{{ title }}</r-title>
    <component
      :is="`public-notifications-${mode}`"
      :source_id="source_id"
      :selected="selected"
      @mode="changeMode"
    />
  </div>
</template>

<script>
import publicNotificationsView from './public-notifications-view'
import publicNotificationsEdit from './public-notifications-edit'
import publicNotificationsCreate from './public-notifications-create'

export default {
  components: {
    publicNotificationsView,
    publicNotificationsEdit,
    publicNotificationsCreate
  },
  data() {
    return {
      mode: 'view',
      selected: null,
      source_id: 'cfbdc04f-1781-4d8f-b18d-f161e5b4331c'
    }
  },
  computed: {
    title() {
      switch (this.mode) {
        case 'edit':
          return 'Редактирование уведомления'
        case 'create':
          return 'Создание уведомления'
        default:
          return 'Публичные уведомления в приложении ПАТ'
      }
    }
  },
  watch: {
    mode(mode) {
      this.setHeight(mode === 'view' ? 'calc(90% - 50px)' : 'auto')
    }
  },
  mounted() {
    this.setHeight('calc(90% - 50px)')
  },
  methods: {
    setHeight(height) {
      document.querySelector('[aria-label="dialog"]').style.height = height
    },
    changeMode(prop) {
      this.mode = prop[0]
      this.selected = prop[1] || null
    }
  }
}
</script>

<style lang="scss">
.public-notifications {
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: row;
  align-items: start;
  align-content: start;
  height: 100%;
  overflow: hidden;
}
</style>
