<template>
  <div class="r-numeric-keyboard">
    <div class="r-numeric-keyboard__wrapper">
      <div
        v-for="row in keysMap"
        :key="row.id"
        class="r-numeric-keyboard__row"
      >
        <div
          v-for="item in row.items"
          :key="item.id"
          class="r-numeric-keyboard__item"
          @click="handleClick(item.value)"
        >
          <r-icon
            v-if="item.icon"
            :size="64"
            :name="item.icon"
            color-type="error"
          >
            {{ item.value }}
          </r-icon>
          <r-text
            v-else
            :size="64"
            :weight="600"
          >
            {{ item.value }}
          </r-text>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const keysMap = [
  {
    id: 1,
    items: [
      {
        id: 1,
        value: 1,
        lable: 1
      },
      {
        id: 2,
        value: 2,
        lable: 2
      },
      {
        id: 3,
        value: 3,
        lable: 3
      }
    ]
  },
  {
    id: 2,
    items: [
      {
        id: 4,
        value: 4,
        lable: 4
      },
      {
        id: 5,
        value: 5,
        lable: 5
      },
      {
        id: 6,
        value: 6,
        lable: 6
      }
    ]
  },
  {
    id: 3,
    items: [
      {
        id: 7,
        value: 7,
        lable: 7
      },
      {
        id: 8,
        value: 8,
        lable: 8
      },
      {
        id: 9,
        value: 9,
        lable: 9
      }
    ]
  },
  {
    id: 4,
    items: [
      {
        id: 10,
        value: 'delete',
        lable: 'delete',
        icon: 'arrow-back'
      },
      {
        id: 11,
        value: 0,
        lable: 0
      }
    ]
  }
]
export default {
  data() {
    return {
      keysMap
    }
  },
  methods: {
    handleClick(e) {
      this.$emit('handle-click', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.r-numeric-keyboard {
  position: relative;
  width: 100%;
  background-color: var(--bg_panel_secondary);
  display: flex;
  justify-content: center;

  &__wrapper {
    padding: 24px;
    display: flex;
    flex-direction: column;
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    width: 80px;
    height: 96px;
    border-radius: var(--border-radius);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.08);
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
