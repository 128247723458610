<template>
  <monitoring-tmc />
</template>

<script>
import monitoringTmc from '@/components/monitoring-tmc/monitoring-tmc'

export default {
  components: {
    monitoringTmc
  },
  created() {
    this.$socket.open()
  },
  beforeDestroy() {
    // this.$socket.close()
  }
}
</script>

<style lang="scss">
</style>
