import vue from 'vue'
import cloneDeep from 'lodash.clonedeep'

const state = {
  dsUrl: null,
  iconsSourceId: '3c11eb14-f35f-4bc7-b03f-997aaa477959',
  activeBook: {
    id: '',
    source_id: '',
    name: '',
    fields: [],
    data: [],
    dataLength: null,
    fullDataLength: null
  },
  reflections: {},
  activeFields: { fields: null, update: false },
  filters: {
    filtersConfigs: [],
    openFilter: ''
  },
  isLoading: false,
  update: false,
  updateFields: false,
  showIconsModal: false,
  fastFilter: null,
  showModal: false,
  modalName: null,
  modalActive: null,
  modalHasChanges: false,
  showExport: false
}

const mutations = {
  BOOK_TOGGLE_MODAL(state, value) {
    vue.set(state, 'showModal', value)
  },
  BOOK_SET_REFLECTION_ITEM(state, { name, value }) {
    vue.set(state.reflections, name, value)
  },
  BOOK_CLEAR_REFLECTIONS(state) {
    vue.set(state, 'reflections', {})
  },
  BOOK_SET_ACTIVE_BOOK_FIELD(state, { field, value }) {
    vue.set(state.activeBook, field, value)
  },
  BOOK_SET_FIELD(state, { field, value }) {
    vue.set(state, field, value)
  },
  BOOK_CLEAR_CONFIGS(state) {
    vue.set(state, 'activeFields', null)
    vue.set(state, 'filters', {
      filtersConfigs: [],
      openFilter: ''
    })
  },
  BOOK_SET_IS_LOADING(state, value) {
    vue.set(state, 'isLoading', value)
  },

  BOOK_SET_ACTIVE_BOOK(state, activeBook) {
    vue.set(state, 'activeBook', activeBook)
  },
  BOOK_TOGGLE_ACTIVE_FIELDS(state) {
    if (state.activeFields?.fields.length) {
      vue.set(state.activeFields, 'fields', [])
    } else {
      vue.set(state.activeFields, 'fields', cloneDeep(state.activeBook.fields))
    }
  },

  UPDATE_ACTIVE_TABLE(state, val = true) {
    vue.set(state, 'update', val)
  },
  SET_ACTIVE_FIELDS(state, { fields, update = false }) {
    vue.set(state, 'activeFields', { fields, update })
  },
  SET_TABLE_OPEN_FITLER(state, id) {
    vue.set(state.filters, 'openFilter', id)
  },
  SET_TABLE_FILTER(state, { name, id, field, op, type, value, active }) {
    const filterConfig = {
      name,
      id,
      field,
      op,
      type,
      value,
      active
    }
    let index

    state.filters.filtersConfigs.forEach((e, i) => {
      if (e.id === id) index = i
    })

    if (index || index === 0) {
      vue.set(state.filters.filtersConfigs, index, filterConfig)
    } else {
      state.filters.filtersConfigs.push(filterConfig)
    }
  },
  TOGGLE_TABLE_FILTERS(state, { id, value, all = false }) {
    if (!all) {
      let index

      state.filters.filtersConfigs.forEach((e, i) => {
        if (e.id === id) index = i
      })
      vue.set(state.filters.filtersConfigs[index], 'active', value)
    } else {
      state.filters.filtersConfigs.map(e => {
        e.active = !value
        return e
      })
    }
  },
  DELETE_TABLE_FILTER(state, id) {
    let index

    state.filters.filtersConfigs.forEach((e, i) => {
      if (e.id === id) index = i
    })
    state.filters.filtersConfigs.splice(index, 1)
  }
}

export default {
  state,
  mutations
}
