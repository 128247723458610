<template>
  <div class="pt-card-attributes">
    <ul
      v-if="cardInfo.properties"
      class="pt-card-attributes__list"
    >
      <li
        v-for="item in attributes"
        :key="item.prop"
        class="pt-card-attributes__item"
      >
        <div class="pt-card-attributes__cell">
          <r-text type="caption">
            {{ item.label }}
          </r-text>
        </div>
        <div class="pt-card-attributes__cell pt-card-attributes__cell--value">
          <r-text>
            {{ getValue(item) }}
          </r-text>
        </div>
      </li>
    </ul>
    <div
      v-else
      class="pt-card-attributes__nodata"
    >
      <r-text type="caption">
        Данные не загружены
      </r-text>
    </div>
  </div>
</template>

<script>
const attributesConfig = {
  stop_points: [
    {
      prop: 'no',
      label: 'Номер',
      type: 'string'
    },
    {
      prop: 'name',
      label: 'Название',
      type: 'string'
    },
    {
      prop: 'address',
      label: 'Адрес',
      type: 'string'
    },
    {
      prop: 'is_active',
      label: 'Работает',
      type: 'boolean'
    },
    {
      prop: 'on_demand',
      label: 'По требованию',
      type: 'boolean'
    }
  ],
  lines: [
    {
      prop: 'name',
      label: 'Название',
      type: 'string'
    },
    {
      prop: 'description',
      label: 'Описание',
      type: 'string'
    },
    {
      prop: 'active',
      label: 'Работает',
      type: 'boolean'
    },
    {
      prop: 'contractor_id',
      label: 'Перевозчик',
      type: 'nested',
      nestedProp: 'contractor'
    },
    {
      prop: 'valid_from',
      label: 'Начало работы',
      type: 'datetime'
    },
    {
      prop: 'valid_until',
      label: 'Окончание работы',
      type: 'datetime'
    }
  ]
}

export default {
  computed: {
    cardType() {
      return this.$store.state.publicTransport.cardType
    },
    cardInfo() {
      return this.$store.state.publicTransport.cardInfo
    },
    attributes() {
      return attributesConfig[this.cardType]
    }
  },
  methods: {
    getValue({ prop, type, nestedProp }) {
      switch (type) {
        case 'boolean':
          return this.cardInfo.properties[prop] ? 'Да' : 'Нет'
        case 'datetime': {
          const value = this.cardInfo.properties[prop]
          return this.$rDate.format(value, null, 'Не указано')
        }
        case 'nested': {
          return this.cardInfo.properties[nestedProp]
            ? this.cardInfo.properties[nestedProp].name
            : 'Не указано'
        }
        default:
          return this.cardInfo.properties[prop]
      }
    }
  }
}
</script>

<style lang="scss">
.pt-card-attributes {
  &__item {
    display: flex;
    align-items: stretch;
    border-top: 1px solid var(--dividers_low_contrast);

    &:last-child {
      border-bottom: 1px solid var(--dividers_low_contrast);
    }
  }

  &__cell {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-left: 1px solid var(--dividers_low_contrast);
    width: 40%;

    &--value {
      width: 60%;
    }

    &:last-child {
      border-right: 1px solid var(--dividers_low_contrast);
    }
  }
}
</style>
