<template>
  <div
    :class="{ vIndent, hIndent, 'vIndent hIndent': indent }"
    class="r-ritm-tabs"
  >
    <ul
      :class="{ metro }"
      class="r-ritm-tabs__list"
    >
      <li
        v-for="tab in tabs"
        :key="tab.id"
        class="list-item__header"
        :class="{ active: tab.isActive }"
        @click="setActive(tab.id)"
      >
        <r-icon
          v-if="tab.icon"
          :name="tab.icon"
          :size="18"
        />
        <span class="list-item__header-name">
          {{ tab.name || '' }}
        </span>
      </li>
    </ul>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    metro: {
      type: Boolean,
      default: false
    },
    indent: {
      type: Boolean,
      default: false
    },
    hIndent: {
      type: Boolean,
      default: false
    },
    vIndent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tabs: [],
      currentTab: ''
    }
  },
  watch: {
    value(v) {
      if ((v || v === 0) && v !== this.currentTab) this.setActive(v)
    }
  },
  mounted() {
    this.tabs = this.$children.filter(e => e.thisIsTab)
    const v = this.value

    if (v || v === 0) {
      this.setActive(v)
      this.currentTab = v
    } else {
      const tab = this.$children.find(tab => tab.thisIsTab)
      if (tab) {
        tab.setActive(true)
      }
    }
  },
  methods: {
    setActive(id) {
      if (this.currentTab === id) return

      this.$children.forEach(tab => {
        if (tab.thisIsTab) {
          tab.setActive(tab.id === id)
        }
      })
      this.currentTab = id
      this.$emit('input', id)
    }
  }
}
</script>

<style lang="scss">
.r-ritm-tabs {
  display: grid;
  grid-gap: 0.5rem;
  height: 100%;
  overflow: hidden;
  align-content: start;

  &.hIndent {
    .r-ritm-tabs__list-item {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  &.vIndent {
    .r-ritm-tabs__list-item {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }

  &__list {
    padding: 0.25rem;
    border-radius: var(--border-radius);
    display: grid;
    align-items: center;
    justify-items: stretch;
    grid-auto-flow: column;
    width: 100%;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(80px, auto));
    justify-content: stretch;
    background-color: var(--bg_containers);

    .list-item {
      &__header {
        height: 1.75rem;
        display: inline-grid;
        align-items: center;
        grid-auto-flow: column;
        grid-gap: 0.25rem;
        padding: 0 0.5rem;
        justify-content: center;
        border-radius: var(--border-radius);
        transition: ease 0.2s;

        &-name {
          color: var(--text_primary);
          font-size: 14px;
          user-select: none;
          line-height: 1.75rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &:hover {
          cursor: pointer;
          background-color: var(--field_hover_bg);
        }

        &.active {
          background-color: var(--accent_primary_1);
        }
      }
    }

    &.metro {
      padding: 0;
      grid-gap: 0;
      background-color: transparent;

      .list-item {
        &__header {
          height: 2.25rem;
          border-radius: 0;
          border-bottom: 2px solid transparent;

          &-name {
            font-size: 1rem;
          }

          &:hover {
            background-color: var(--field_hover_bg);
          }

          &.active {
            border-bottom-color: var(--accent_primary);
            background-color: var(--accent_primary_1);
          }
        }
      }
    }
  }
}
</style>
