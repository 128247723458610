<template>
  <div class="ee-geometry-mode">
    <r-text
      type="caption"
      style="margin-bottom: 4px;"
    >
      Тип геометрии
    </r-text>
    <el-radio-group
      v-model="geometryMode"
      :disabled="state.isDrawing"
      @change="changeHandler"
    >
      <el-radio-button
        v-for="item in modes"
        :key="item.value"
        :label="item.value"
      >
        {{ item.name }}
      </el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
const modes = [
  { value: 'points', name: 'Точка' },
  { value: 'lines', name: 'Отрезок' },
  { value: 'polygons', name: 'Район' }
]

export default {
  props: {
    state: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modes
    }
  },
  computed: {
    geometryMode: {
      get() {
        return this.$store.state.eventEditor.geometryMode
      },
      set(value) {
        this.$store.commit('SET_EE_STATE_PROP', {
          name: 'geometryMode',
          value
        })
      }
    }
  },
  methods: {
    changeHandler(mode) {
      this.$emit('toggle-geometry-mode', mode)
    }
  }
}
</script>

<style lang="scss">
.ee-geometry-mode {
  margin-top: 16px;

  .el-radio-group {
    display: flex;
    width: 100%;
  }

  .el-radio-button {
    flex: 1;

    &__orig-radio:checked + .el-radio-button__inner {
      color: var(--white_white) !important;
      background-color: var(--accent_primary) !important;
      border-color: var(--accent_primary) !important;
    }

    &__inner {
      width: 100%;
      color: var(--text_primary) !important;
      background-color: var(--field_bg) !important;
      border-color: var(--field_border) !important;
    }
  }
}
</style>
