<template>
  <div class="mt-card-actions">
    <r-button
      icon="focus-zone"
      @click="flyTo"
    >
      Найти на карте
    </r-button>
    <r-button
      icon="trash"
      :disabled="!info.carriage_no"
      type="danger"
      @click="beforeDelete()"
    />
  </div>
</template>

<script>
import { notifyFactory } from '@/utils'

export default {
  props: {
    location: {
      type: Object,
      required: true
    },
    id: {
      type: String,
      default: null
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      carsListId: this.$store.state.railwayControl.carsListId || null,
      etranSourceId: this.$store.state.monitoringTmc.etran_source_id || null
    }
  },
  methods: {
    flyTo() {
      this.$store.commit('MT_TMC_SET_FIELD', {
        field: 'flyToGeom',
        value: this.location
      })
    },
    beforeDelete() {
      if (!this.info?.carriage_no) return
      const message = 'Вагон будет удален, вы уверены?'
      const title = 'Удаление вагона'
      const confirm = 'Да'
      const cancel = 'Отмена'

      this.$confirm(message, title, {
        customClass: 'r-message-box',
        type: 'warning',
        closeOnPressEscape: false,
        closeOnClickModal: false,
        confirm,
        cancel
      })
        .then(() => {
          this.deleteCar()
        })
        .catch(() => {})
    },
    async deleteCar() {
      try {
        const options = {
          where: [
            {
              field: 'number',
              op: '=',
              value: this.info.carriage_no
            }
          ]
        }
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/${this.carsListId}?config=${JSON.stringify(options)}`
        })
        if (data && Object.keys(data)?.length) {
          const carId = Object.keys(data)[0]
          await this.$store.dispatch('DELETE_REQUEST', {
            url: `ZdCarriage?carriage_id=${carId}`
          })
        } else {
          await this.$store.dispatch('DELETE_REQUEST', {
            url: `objectInfo/${this.etranSourceId}?id=${this.info.id}`
          })
        }

        const title = 'Удаление вагона'
        const message = 'Удаление вагона выполнено успешно'

        this.$notify(notifyFactory('success', title, message))
        this.$store.commit('MT_TMC_SET_UPDATE_FIELD', {
          field: 'etran',
          value: true
        })
        this.closeCard()
      } catch (e) {
        console.log(e)
      }
    },
    closeCard() {
      this.$store.commit('MT_TMC_SET_FIELD', {
        field: 'cardId',
        value: null
      })
      this.$store.commit('MT_TMC_SET_FIELD', {
        field: 'trackDataId',
        value: null
      })
      this.$store.commit('MT_TMC_SET_FIELD', {
        field: 'cardTrack',
        value: null
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.mt-card-actions {
  display: flex;

  .r-button {
    &:first-child {
      flex: 1;
    }

    &:not(:first-child) {
      margin-left: 8px;
    }
  }
}
</style>
