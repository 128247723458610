export const getConfig = (id, fields = []) => {
  const config = {
    where: [{ field: 'id', value: id, op: '=' }],
    include: {}
  }

  fields.forEach(f => {
    const through = f?.through || null

    if (through) {
      config.include[through] = {}
    }
  })

  return config
}

export const getChangedData = (id, fields, changed) => {
  const item = { id }

  Object.keys(changed).forEach(cf => {
    const field = fields.find(f => f.source_name === cf)

    if (field.through) {
      const { value, valueCache } = field
      const added = value
        .filter(v => !valueCache.find(vc => vc.geozone_id === v))
        .map(v => ({ vehicle_id: id, [field.foreign_key]: v }))
      const removed = valueCache
        .filter(vc => !value.includes(vc.geozone_id))
        .map(vc => ({ id: vc.id, disabled: true }))

      item[field.through] = [...added, ...removed]
    } else {
      const key = field.foreign_key || field.source_name

      item[key] = field.value
    }
  })

  return item
}
