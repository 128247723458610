<template>
  <div class="rp-attr-filter-header">
    <div class="rp-attr-filter-header-row">
      <r-button
        type="success"
        :disabled="!roleAttrFilterHasChanges"
        @click="saveAttrFilter"
      >
        Сохранить
      </r-button>
      <r-button
        simple
        @click="close"
      >
        Выйти без сохранения
      </r-button>
    </div>
    <div class="rp-attr-filter-header-row rp-attr-filter-header__note">
      <r-icon
        name="alert"
        color-type="accent-warning"
      />
      <r-text>
        Все объекты источника данных наследуют права доступа на чтение, удаление
        и др. Вы не можете изменить их отдельно.
      </r-text>
    </div>
    <div class="rp-attr-filter-header-row rp-attr-filter-header__info">
      <r-icon
        :name="getIconName(item)"
        :size="24"
      />
      <r-title type="title-2">
        {{ item.name }}
      </r-title>
      <r-title
        type="title-2"
        color-type="secondary"
      >
        {{ `(${dsLength} объектов)` }}
      </r-title>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    dsLength: {
      type: Number,
      default: 0
    }
  },
  computed: {
    roleAttrFilterHasChanges: {
      get() {
        return this.$store.state.rolePermission.roleAttrFilterHasChanges
      },
      set(val) {
        this.$store.commit('ROLE_PERM_SET_FIELD', {
          field: 'roleAttrFilterHasChanges',
          value: val
        })
      }
    },
    roleAttrFilterId() {
      return this.$store.state.rolePermission.roleAttrFilterId || null
    },
    roleDatasource() {
      const datasources =
        this.$store.state.rolePermission.rolePerms?.datasources || null

      if (!datasources?.length) return

      return datasources.find(e => e.id === this.roleAttrFilterId) || null
    },
    roleAttrFilter() {
      return this.$store.state.rolePermission.roleAttrFilter || null
    },
    roleAttrFilterItemsConfig() {
      return this.$store.state.rolePermission.roleAttrFilterItemsConfig || null
    }
  },
  methods: {
    getIconName(item) {
      if (item.datatype === 'model') return 'model-layer'

      switch (item.geom_type) {
        case 'line_string':
        case 'multi-line-string':
          return 'line-layer'
        case 'polygon':
        case 'multi_polygon':
          return 'polygon-layer'
        case 'point':
        case 'multi_point':
          return 'node-layer'
        default:
          return 'folder-layer'
      }
    },
    saveAttrFilter() {
      if (!this.roleDatasource.restrictions) {
        this.roleDatasource.restrictions = {}
      }

      const restrictions = []

      if (this.roleAttrFilterItemsConfig?.value?.length) {
        restrictions.push(this.roleAttrFilterItemsConfig)
      }

      if (this.roleAttrFilter?.length) {
        restrictions.push(...this.roleAttrFilter)
      }
      this.roleDatasource.restrictions = restrictions

      const actionValue = !this.roleDatasource._action
        ? 'updated'
        : this.roleDatasource._action

      this.$set(this.roleDatasource, '_action', actionValue)
      this.roleAttrFilterHasChanges = false
      this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', false)
    },
    close() {
      this.$store.commit('CLOSE_MODAL_WINDOW')
      this.roleAttrFilterHasChanges = false
    }
  }
}
</script>

<style lang="scss">
.rp-attr-filter-header {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-shrink: 0;

  &-row {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    justify-content: start;
    align-items: center;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__note {
    width: 100%;
    padding: 10px 8px;
    border-radius: var(--border-radius);
    background-color: var(--bg_containers);

    .r-icon {
      margin-right: 8px;
      flex-shrink: 0;
      max-height: none;
      height: auto;
    }
  }

  &__info {
    > * {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
