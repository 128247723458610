import vue from 'vue'
import cloneDeep from 'lodash.clonedeep'

const state = {
  carsListId: 'd771ab92-6787-4aa6-ae65-e64d687f5234',
  statusListId: '78277cf8-1a02-4bc5-be8c-f8e1fc384a7c',
  historyCarsListId: '6fae4538-a367-4fce-bd33-fcb52b2b4fe0',
  carsList: null,
  initialCarsList: null,
  changedCars: [],
  statusList: null,
  dateFilter: null,
  cardId: '',
  update: false
}

const mutations = {
  RAILWAY_CONTROL_SET_FIELD(state, { field, value }) {
    vue.set(state, field, value)
  },
  RAILWAY_CONTROL_DELETE_CAR(state, id) {
    state.carsList.find((e, i) => {
      if (String(e.id) === String(id)) {
        vue.set(state.carsList[i], 'way_no', null)
        vue.set(state.carsList[i], 'peron_no', null)
        vue.set(state.carsList[i], 'status_id', null)
        return true
      }
    })
  },
  RAILWAY_CONTROL_SET_DATE_FILTER(state, date) {
    vue.set(state, 'dateFilter', date)
  },
  RAILWAY_CONTROL_CANCEL_CHANGES(state) {
    state.carsList = cloneDeep(state.initialCarsList)
    state.changedCars = cloneDeep([])
  },
  RAILWAY_CONTROL_SET_CARS_LIST(state, list) {
    vue.set(state, 'carsList', list)
  },
  RAILWAY_CONTROL_SET_INITIAL_CARS_LIST(state, list) {
    state.initialCarsList = cloneDeep(list)
  },
  RAILWAY_CONTROL_UPDATE_INITIAL_CARS_LIST(state) {
    state.initialCarsList = cloneDeep(state.carsList)
    state.changedCars = cloneDeep([])
  },
  RAILWAY_CONTROL_SET_STATUS_LIST(state, list) {
    vue.set(state, 'statusList', list)
  },
  RAILWAY_CONTROL_SET_CARD_ID(state, id) {
    vue.set(state, 'cardId', id)
  },
  RAILWAY_CONTROL_UPDATE_CAR_BY_ID(state, { id, field, value }) {
    state.carsList.find((e, i) => {
      if (String(e.id) === String(id)) {
        vue.set(state.carsList[i], field, value)
        return true
      }
    })
  },
  RAILWAY_CONTROL_OBSERV_CHANGES_BY_ID(state, { id }) {
    const currentCar = state.carsList.find(e => e.id === id)
    const initialCar = state.initialCarsList.find(e => e.id === id)
    const alreadyChanged = state.changedCars.find(e => e.id === id)
    const changedIndex = state.changedCars.findIndex(e => e.id === id)

    if (currentCar.way_no !== initialCar.way_no ||
      currentCar.status_id !== initialCar.status_id ||
      currentCar.planned_way_no !== initialCar.planned_way_no
    ) {
      if (alreadyChanged) {
        state.changedCars.map(e => {
          if (e.id === id) {
            e.way_no = currentCar.way_no
            e.status_id = currentCar.status_id
            e.planned_way_no = currentCar.planned_way_no
            return true
          }
        })
      } else {
        state.changedCars.push(currentCar)
      }
    } else {
      if (alreadyChanged) {
        state.changedCars.splice(changedIndex, 1)
      }
    }
  }
}

export default {
  state,
  mutations
}
