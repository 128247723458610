<template>
  <div class="ds-manager__component empty-model">
    <div class="ds-manager__component-title">
      <r-title type="title-2">
        {{ $t('ds-manager:creation:empty-model:title') }}
      </r-title>
    </div>
    <div class="ds-manager__component-content">
      <el-row class="ds-manager__component-row">
        <div class="ds-manager__component-subtitle">
          <r-text type="caption">
            {{ $t('ds-manager:creation:empty-model:subtitle') }}
          </r-text>
        </div>
        <el-input
          v-model="dsName"
          class="r-input"
          size="mini"
          :placeholder="`${$t(
            'ds-manager:creation:empty-model:subtitle-placeholder'
          )}`"
        />
      </el-row>
      <el-row class="ds-manager__component-row">
        <div class="ds-manager__component-subtitle">
          <r-text type="caption">
            {{ $t('ds-manager:creation:enter-profiles') }}
          </r-text>
        </div>
        <el-select
          v-model="selectedProfiles"
          class="r-select"
          size="mini"
          multiple
          collapse-tags
          :placeholder="`${$t(
            'ds-manager:creation:select-profiles-placeholder'
          )}`"
        >
          <el-option
            v-for="profile in profiles"
            :key="profile.id"
            :value="profile.id"
            :label="profile.name"
          />
        </el-select>
      </el-row>
      <el-row class="ds-manager__component-row text-center">
        <r-button
          type="success"
          :disabled="!dsName.trim()"
          @click="createEmptyDs"
        >
          {{ $t('ds-manager:creation:create-ds') }}
        </r-button>
      </el-row>
    </div>
  </div>
</template>

<script>
import { notifyFactory, errorParser } from '@/utils'

export default {
  data() {
    return {
      selectedProfiles: [],
      dsName: ''
    }
  },
  computed: {
    profiles() {
      return this.$store.state.profiles.profilesTree
    },
    currentProfile() {
      return this.$store.state.profiles.currentProfile
    }
  },
  watch: {
    dsName(val) {
      this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', !!val.trim())
    }
  },
  created() {
    this.selectedProfiles = this.profiles
      .filter(e => e.id === this.currentProfile.id)
      .map(e => e.id)
  },
  beforeDestroy() {
    this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', false)
  },
  methods: {
    async createEmptyDs() {
      try {
        const data = new FormData()

        data.append('data_source_name', this.dsName)
        data.append('type', 'net')
        data.append('user_profile_id', this.selectedProfiles.join())

        const response = await this.$store.dispatch('SEND_FORM_DATA', {
          url: 'import_data',
          data
        })
        const title = this.$t('ds-manager:creation:create-success-title')
        const message = `${this.$t(
          'ds-manager:creation:create-success-text'
        )} ${response.data.task_id}`

        this.$notify(notifyFactory('success', title, message))
        this.$store.commit('ADD_ACTIVE_TASK', {
          task_id: response.data.task_id,
          type: 'import',
          description: `Импорт ИД - ${this.layerName}`
        })
      } catch (e) {
        errorParser.call(this, e)
      }
    }
  }
}
</script>

<style lang="scss">
.empty-model {
  .ds-manager__component-content {
    width: 400px;
    height: 200px;
    .r-select {
      width: 100%;
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "ds-manager:creation:empty-model:title": "Создать пустую модель данных",
    "ds-manager:creation:empty-model:subtitle": "Укажите название",
    "ds-manager:creation:empty-model:subtitle-placeholder": "Название",
    "ds-manager:creation:enter-profiles": "Выберите профили",
    "ds-manager:creation:select-profiles-placeholder": "Выберите профили в которые добавить источник данных",

    "ds-manager:creation:create-ds": "Создать источник данных",

    "ds-manager:creation:create-success-title": "Запрос отправлен",
    "ds-manager:creation:create-success-text": "Запрос на создание источника данных отправлен. Номер задачи - "
  },
  "en": {
    "ds-manager:creation:empty-model:title": "Create an empty data model",
    "ds-manager:creation:empty-model:subtitle": "Enter name",
    "ds-manager:creation:empty-model:subtitle-placeholder": "Name",
    "ds-manager:creation:enter-profiles": "Select profiles",
    "ds-manager:creation:select-profiles-placeholder": "Select the profiles to add the source to",

    "ds-manager:creation:create-ds": "Create data source",

    "ds-manager:creation:create-success-title": "Request sent",
    "ds-manager:creation:create-success-text": "A request to create a data source has been sent. Task number -"
  }
}
</i18n>
