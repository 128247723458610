<template>
  <journal-component />
</template>

<script>
import JournalComponent from '@/components/journal/journal'

export default {
  components: {
    JournalComponent
  }
}
</script>

<style>
</style>
