<template>
  <div class="map-style point">
    <div class="map-style__block">
      <classes-color-picker
        :id="id"
        :layer-style="layerStyle.style"
        :controllers="controllers"
        property="fill"
        title="Цвет"
        @change-property="changeProperty"
        @change-classes="changeClasses"
        @change-strings="changeStrings"
      />
    </div>
    <div class="map-style__block">
      <polygon-outline-picker
        :id="id"
        :layer-style="layerStyle.borderStyle"
        :controllers="controllers"
        title="Обводка"
        @change-property="changeBorder"
      />
    </div>
    <div class="map-style__block">
      <fill-extrusion-picker
        :id="id"
        :layer-style="layerStyle"
        :controllers="controllers"
        title="Высота"
      />
    </div>
    <div class="map-style__block">
      <popup-fields-picker
        :id="id"
        :layer-style="layerStyle"
        :controllers="controllers"
        title="Информация"
      />
      <popup-click-fields-picker
        :id="id"
        :layer-style="layerStyle"
        :controllers="controllers"
      />
    </div>
  </div>
</template>

<script>
import ClassesColorPicker from '../input-sources/classes-color-picker'
import PolygonOutlinePicker from '../input-sources/polygon-outline-picker'
import FillExtrusionPicker from '../input-sources/fill-extrusion-picker'
import PopupFieldsPicker from '../input-sources/popup-fields-picker'
import PopupClickFieldsPicker from '../input-sources/popup-click-fields-picker'

export default {
  components: {
    ClassesColorPicker,
    PolygonOutlinePicker,
    FillExtrusionPicker,
    PopupFieldsPicker,
    PopupClickFieldsPicker
  },
  props: {
    id: {
      type: String,
      required: true
    },
    layerStyle: {
      type: Object,
      required: true
    },
    controllers: {
      type: Object,
      required: true
    }
  },
  methods: {
    changeProperty(payload) {
      this.$emit('change-property', payload)
    },
    changeClasses(payload) {
      this.$emit('change-classes', payload)
    },
    changeStrings(payload) {
      this.$emit('change-strings', payload)
    },
    changeBorder({ type, field }) {
      this.controllers.style.changePolygonBorder(this.id, type, field)
    }
  }
}
</script>

<style lang="scss"></style>
