<template>
  <div
    v-loading="loading"
    class="odd-router-view"
  >
    <r-panel-header title="Маршрутизатор" />
    <div class="odd-router-view__controls">
      <r-button
        type="default"
        @click="cancel"
      >
        Выйти из режима
      </r-button>
      <r-button
        type="primary"
        @click="goToCreate"
      >
        <r-text> Построить маршрут </r-text>
      </r-button>
    </div>
    <router-list
      :routes="routes"
      @update="getData"
    />
  </div>
</template>

<script>
import routerList from '@/components/odd/router/router-list'

export default {
  components: {
    routerList
  },
  data() {
    return {
      routes: [],
      loading: false
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        this.loading = true

        const url = 'objectInfo/telemetry.user_routes'

        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        this.routes = Object.values(data)
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    cancel() {
      this.$router.push('/app/odd/objects')
    },
    goToCreate() {
      this.$router.push('/app/odd/router/create')
    }
  }
}
</script>

<style lang="scss">
.odd-router-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &__controls {
    display: grid;
    grid-gap: 0.5rem;
    padding: 0.5rem;
    border-bottom: 1px solid var(--dividers_low_contrast) !important;
  }
}
</style>
