<template>
  <div class="rp-users-tip">
    <r-icon
      name="lock"
      :size="24"
    />
    <r-text>
      {{ tip }}
    </r-text>
  </div>
</template>

<script>
export default {
  props: {
    tip: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.rp-users-tip {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  background-color: var(--accent_selected);
  overflow: hidden;
  padding: 8px;
  width: 100%;
  margin-bottom: 16px;

  .r-icon {
    margin-right: 8px;
    flex-shrink: 0;
  }
}
</style>
