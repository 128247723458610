<template>
  <div
    v-loading="loading"
    class="violations-modal"
  >
    <r-title>{{ title }}</r-title>
    <component
      :is="`modal-${mode}`"
      v-if="ready"
      :source_id="sourceId"
      :source="violation"
      :no-violator="noViolator"
      :mark-items="markItems"
      :copy-source="copySource"
      @mode="changeMode"
    />
  </div>
</template>

<script>
import { titles, sources } from '../../configs'
import { getConfig } from './configs'
import { setMarkItems } from '../helpers'

export default {
  components: {
    modalView: () => import('./modal-view/modal-view'),
    modalEdit: () => import('./modal-edit/modal-edit'),
    modalCopy: () => import('./modal-copy/')
  },
  data: () => ({
    ready: false,
    loading: false,
    violation: {},
    mode: 'view',
    oddEvent: {},
    markItems: [],
    copySource: '',
    sources,
    titles
  }),
  computed: {
    title() {
      switch (this.mode) {
        case 'edit':
          return this.titles.edit
        default:
          return this.titles.view
      }
    },
    source() {
      return this.$store.state?.[this.sources.store]?.[this.sources.field]
    },
    related() {
      return this.source?.related
    },
    sourceId() {
      return this.source?.source_id || null
    },
    noViolator() {
      return this.oddEvent?.event_class?.name === 'Недостатки'
    }
  },
  mounted() {
    this.loadViolation()
  },
  methods: {
    changeMode(prop, value) {
      this.mode = prop

      if (prop === 'copy') {
        this.copySource = value
      } else {
        this.copySource = null
      }
    },
    async loadViolation() {
      this.loading = true

      const { id } = this.$store.state.bdd.violation
      const config = getConfig(id)
      const url = `objectInfo/${this.sourceId}?config=${JSON.stringify(config)}`

      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })
        this.violation = Object.values(data)?.[0]

        if (this.violation.vehicle_id) {
          const { vehicle } = this.violation

          this.violation.model = vehicle?.model?.name
          this.violation.vehicle_type = vehicle?.vehicle_type?.name
          this.violation.vehicle_class = vehicle?.vehicle_class?.name
        }
        this.violation.related = this.related

        if (this.violation.event_id) {
          this.oddEvent = this.related.event?.data?.find(
            e => e.id === this.violation.event_id
          )
          setMarkItems(this.markItems, this.oddEvent)
        }
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
        this.ready = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.violations-modal {
  display: grid;
  grid-gap: 1rem;
  align-content: start;
}
</style>
