<template>
  <div
    :class="{ active: isActive }"
    class="odd-object-item"
    @click="clickHandler"
  >
    <div class="odd-object-item__icon">
      <div v-if="type === 'signs'">
        <r-icon
          v-if="!object.sign_icon"
          :size="24"
          name="image"
        />
        <icon-image
          v-else
          :resource_id="resource_id"
        />
      </div>
      <r-icon
        v-else
        :size="24"
        :name="eventIcon"
      />
    </div>
    <r-text>
      {{ object.name }}
    </r-text>
  </div>
</template>

<script>
import iconImage from '@/components/odd/map/components/icon-image'

export default {
  components: { iconImage },
  props: {
    object: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    cardId() {
      return this.$store.state.odd.cardId
    },
    isActive() {
      return this.object.id === this.cardId
    },
    resource_id() {
      return this.object.sign_icon.resource_id
    },
    eventIcon() {
      switch (this.object.event_class?.name) {
        case 'Недостатки':
          return 'closed'
        case 'Ремонтные работы':
          return 'work'
        case 'ДТП':
          return 'car-accident'
        default:
          return 'closed'
      }
    }
  },
  methods: {
    clickHandler() {
      const { id } = this.object

      this.$store.commit('SET_ODD_FIELD', { field: 'cardId', value: id })
      this.$store.commit('SET_ODD_FIELD', {
        field: 'cardType',
        value: this.type
      })
    }
  }
}
</script>

<style lang="scss">
.odd-object-item {
  display: grid;
  grid-gap: 0.5rem;
  padding: 0.25rem 0.5rem;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  border-radius: var(--border-radius);
  width: 100%;
  transition: 0.15s ease;

  &.active {
    background-color: var(--accent_active) !important;
  }

  &:hover {
    background-color: var(--accent_hover) !important;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
