<template>
  <el-collapse
    v-model="show"
    accordion
    class="r-collapse simple map-source-attributes"
  >
    <el-collapse-item name="1">
      <template
        v-if="!single"
        slot="title"
      >
        <div
          v-if="activeFields.length"
          class="map-source-attributes-header"
        >
          <el-tag
            v-for="tag in activeFields"
            :key="tag"
            size="mini"
            closable
            :disable-transitions="true"
            @close="removeField(tag)"
          >
            {{ tag }}
          </el-tag>
        </div>
        <r-text
          v-else
          color-type="field-placeholder"
        >
          Не выбран
        </r-text>
      </template>
      <template
        v-else
        slot="title"
      >
        <div
          v-if="activeFields"
          class="map-source-attributes-header single"
        >
          <span class="label">{{ activeFields }}</span>
          <span
            class="map-source-attributes-close el-icon-close"
            @click="closeHandler"
          />
        </div>
        <r-text
          v-else
          color-type="field-placeholder"
        >
          Не выбран
        </r-text>
      </template>
      <tree-attributes
        :active-fields="activeFields"
        :is-not-editable="true"
        :is-only-number="true"
        :map-bi="true"
        :single="single"
        label="alias"
        children="children"
        :tree-data="treeData"
        @handleNodeClick="handleNodeClick"
      />
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import TreeAttributes from '@/components/tree-attributes/tree-attributes'

export default {
  components: {
    TreeAttributes
  },
  props: {
    activeFields: {
      type: [String, Array],
      required: true
    },
    single: {
      type: Boolean,
      default: false
    },
    treeData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      show: ''
    }
  },
  methods: {
    handleNodeClick(data) {
      if (this.single) this.show = ''
      this.$emit('handleNodeClick', data)
    },
    removeField(path) {
      this.$emit('handleNodeClick', { path, config: {} })
    },
    closeHandler(e) {
      e.stopPropagation()
      this.removeField(this.activeFields)
    }
  }
}
</script>

<style lang="scss">
.map-source-attributes {
  &-close {
    margin-left: 5px;
  }

  &-header {
    .el-tag:not(:first-child) {
      margin-left: 5px;
    }

    &.single {
      display: flex;
      align-items: center;

      .label {
        max-width: 140px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
      }
    }
  }

  .tree.attributes {
    .tree__container {
      height: 240px;
      background-color: transparent !important;
      overflow: auto;
    }

    .el-tree {
      color: var(--text_secondary) !important;
      font-weight: 500;
    }

    .el-tree-node {
      overflow: hidden;
      &:focus > .el-tree-node__content {
        background-color: transparent !important;
      }

      &__content {
        &:hover {
          background-color: var(--accent_primary);
          color: var(--white_white) !important;

          .tree__text--selected {
            color: var(--white_white) !important;
          }
        }
      }
    }

    .el-input__inner {
      background-color: var(--field_bg) !important;
      border-color: var(--field_border) !important;
      color: var(--text_secondary) !important;
    }
  }
  .el-tag {
    border-color: var(--dividers_low_contrast) !important;
    background-color: var(--field_bg) !important;
    color: var(--text_secondary) !important;

    .el-tag__close {
      background-color: transparent !important;
      color: var(--text_secondary) !important;
    }
  }
}
</style>
