<template>
  <div
    :style="`display: ${settings.display};top: ${settings.top}px; left: ${settings.left}px`"
    class="map-popup-list"
  >
    <div class="map-popup-list__title">
      <r-text style="display: inline">
        {{ $t('title') }}
      </r-text>
      <r-text type="caption">
        {{ `(${$t('amount')} ${list.length})` }}
      </r-text>
    </div>
    <ul class="map-popup-list__list">
      <li
        v-for="item in list"
        :key="item.id"
        :class="[
          'map-popup-list__item',
          { active: isActive(item.id) }
        ]"
        @click="clickHandler(item.id)"
      >
        <div class="map-popup-list__layer-name">
          <r-text
            type="caption"
            :size="14"
          >
            {{ getType(item) === 'train' ? getAttributeLabel('carriage_no') : 'ГРЗ' }}
          </r-text>
          <r-text>
            {{ item.number }}
          </r-text>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    settings: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    list() {
      if (!this.settings.values?.length) return []

      return this.settings.values.map(e => e.properties)
    },
    cardId() {
      return this.$store.state.monitoringTmc.cardId
    },
    etranObjectFields() {
      return this.$store.state.monitoringTmc.etranObjectFields || null
    }
  },
  methods: {
    clickHandler(id) {
      if (this.cardId === id) return
      this.$store.commit('MT_TMC_SET_FIELD', {
        field: 'cardId',
        value: id
      })
    },
    isActive(id) {
      return this.cardId === id
    },
    getAttributeLabel(prop) {
      return this.etranObjectFields[prop]?.alias || prop
    },
    getType(e) {
      switch (e.type_name) {
        case 'train':
          return 'ЖД'
        default:
          return 'vehicle'
      }
    }
  }
}
</script>

<style lang="scss">
.map-popup-list {
  position: absolute;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  background-color: var(--bg_surface) !important;
  border-color: var(--dividers_low_contrast) !important;
  min-width: 160px;
  padding: 8px;
  z-index: 10;
  &__title {
    margin-bottom: 8px;
  }
  &__list {
    max-height: 40vh;
    overflow: auto;
    max-width: 300px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 4px 8px;
    border-bottom: 1px solid;
    border-radius: var(--border-radius);
    border-color: var(--dividers_low_contrast) !important;
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
    &.active {
      background-color: var(--accent_hover) !important;
      * {
        color: var(--accent_primary) !important;
      }
    }
    &:hover {
      background-color: var(--accent_hover) !important;
    }
    &:active {
      background-color: var(--accent_active)!important;
    }
  }
  &__key {
    margin-right: 16px;
  }
  &__value {
    font-weight: bold;
  }
}
</style>

<i18n>
{
  "ru": {
    "title": "Доступные объекты",
    "amount": "кол-во"
  },
  "en": {
    "title": "Available objects",
    "amount": "amount"
  }
}
</i18n>
