<template>
  <div
    v-if="!task.done"
    class="tasks-list-item__loader"
  >
    <span class="el-icon-loading" />
  </div>
  <div
    v-else-if="task.success"
    class="tasks-list-item__complete-status success"
  >
    <span class="el-icon-success" />
  </div>
  <div
    v-else
    class="tasks-list-item__complete-status error"
  >
    <span class="el-icon-error" />
  </div>
</template>

<script>
export default {
  props: {
    task: {
      type: Object,
      default: null,
      required: true
    }
  }
}
</script>

<style lang="scss">
.tasks-list-item {
  &__loader {
    flex-shrink: 0;
    margin-left: auto;
    padding-left: 10px;
    i {
      font-size: 14px;
    }
  }
  &__complete-status {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 60px;
    i {
      font-size: 20px;
    }
    &.success {
      i {
        color: var(--accent_success);
      }
    }
    &.error {
      i {
        color: var(--accent_error);
      }
    }
  }
}
</style>
