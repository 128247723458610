const colors = [
  '#2E93FE',
  '#F4D340',
  '#F85C50',
  '#64C7FF',
  '#B33EA9',
  '#F48540',
  '#A771FE',
  '#0043A4',
  '#2E93FE',
  '#4E78D6',
  '#12E6D6',
  '#F85C50',
  '#F375F3',
  '#B40A1B',
  '#4A586E'
]
// const colorsGradient = [
//   '#2E93FE',
//   '#2E93FE85',
//   '#2E93FE70',
//   '#2E93FE55',
//   '#2E93FE40',
//   '#2E93FE25',
//   '#2E93FE10'
// ]

export const dataZoomConfig = {
  horizontal: [
    {
      type: 'slider',
      show: true,
      realtime: true,
      start: 0,
      end: 100
    }
  ],
  vertical: [
    {
      type: 'slider',
      show: true,
      realtime: true,
      orient: 'vertical',
      left: 20,
      start: 0,
      end: 100
    }
  ]
}

export const bar = {
  color: colors,
  tooltip: {
    trigger: 'axis'
  },
  legend: {
    top: 28,
    left: 'center',
    data: ['1', '2', '3', '4'],
    textStyle: {
      fontSize: 10
    },
    icon: 'line'
  },
  dataZoom: [],
  grid: {
    top: '15%',
    left: '5%',
    right: '5%',
    bottom: 48,
    containLabel: true
  },
  xAxis: {
    type: 'category',
    data: ['1', '2', '3', '4', '5', '6', '7'],
    splitLine: {
      show: false
    },
    axisLabel: {
      textStyle: {
        fontSize: 10
      }
    }
  },
  yAxis: {
    type: 'value',
    splitLine: {
      show: false
    },
    axisLabel: {
      textStyle: {
        fontSize: 10
      }
    }
  },
  series: [
    {
      name: '1',
      type: 'bar',
      stack: '1',
      data: [320, 302, 301, 334, 390, 330, 320]
    },
    {
      name: '2',
      type: 'bar',
      stack: '2',
      data: [120, 132, 101, 134, 90, 230, 210]
    },
    {
      name: '3',
      type: 'bar',
      stack: '3',
      data: [220, 182, 191, 234, 290, 330, 310]
    },
    {
      name: '4',
      type: 'bar',
      stack: '4',
      data: [150, 212, 201, 154, 190, 330, 410]
    }
  ]
}

export const barX = {
  color: colors,
  tooltip: {
    trigger: 'axis'
  },
  legend: {
    top: 28,
    left: 'center',
    data: ['1', '2', '3', '4'],
    textStyle: {
      fontSize: 10
    },
    icon: 'line'
  },
  grid: {
    top: '20%',
    left: '10%',
    right: '5%',
    bottom: 24,
    containLabel: true
  },
  dataZoom: [],
  xAxis: {
    type: 'value',
    splitLine: {
      show: false
    },
    axisLabel: {
      textStyle: {
        fontSize: 10
      }
    }
  },
  yAxis: {
    type: 'category',
    data: ['mo', 'th', 'we', 'th', 'fr', 'sa', 'su'],
    splitLine: {
      show: false
    },
    axisLabel: {
      textStyle: {
        fontSize: 10
      }
    }
  },
  series: [
    {
      name: '1',
      type: 'bar',
      stack: '1',
      data: [320, 302, 301, 334, 390, 330, 320]
    },
    {
      name: '2',
      type: 'bar',
      stack: '2',
      data: [120, 132, 101, 134, 90, 230, 210]
    },
    {
      name: '3',
      type: 'bar',
      stack: '3',
      data: [220, 182, 191, 234, 290, 330, 310]
    },
    {
      name: '4',
      type: 'bar',
      stack: '4',
      data: [150, 212, 201, 154, 190, 330, 410]
    }
  ],
  textStyle: {
    fontSize: 10
  }
}

export const basic = {
  color: colors,
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      label: {
        backgroundColor: '#2E93FE'
      }
    }
  },
  legend: {
    top: 15,
    left: 'center',
    data: ['m2'],
    textStyle: {
      fontSize: 10
    },
    icon: 'line'
  },
  dataZoom: [],
  grid: {
    top: '15%',
    left: '5%',
    right: '5%',
    bottom: 48,
    containLabel: true
  },
  xAxis: [
    {
      type: 'category',
      boundaryGap: false,
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      splitLine: {
        show: false
      },
      axisLabel: {
        textStyle: {
          fontSize: 10
        }
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      splitLine: {
        show: false
      },
      axisLabel: {
        textStyle: {
          fontSize: 10
        }
      }
    }
  ],
  series: [
    {
      name: 'm2',
      type: 'line',
      areaStyle: {
        opacity: 0.6
      },
      smooth: true,
      data: [82, 93, 90, 93, 129, 133, 132],
      lineStyle: {
        width: 1
      },
      itemStyle: {
      },
      symbol: 'circle'
    }
  ],
  textStyle: {
    fontSize: 10
  },
  animationDuration: 500
}

export const pie = {
  color: colors,
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b}: {c} ({d}%)',
    textStyle: {
      fontSize: 12
    }
  },
  legend: {
    top: 20
  },
  series: [
    {
      name: 'Donuts',
      type: 'pie',
      radius: ['50%', '80%'],
      center: ['50%', '55%'],
      avoidLabelOverlap: false,
      label: {
        show: false
      },
      labelLine: {
        normal: {
          show: false
        }
      },
      data: [
        { value: 335, name: 'One' },
        { value: 310, name: 'Two' },
        { value: 234, name: 'Three' },
        { value: 135, name: 'Four' },
        { value: 1548, name: 'Five' }
      ],
      itemStyle: {
        borderWidth: 2,
        borderColor: '#292B37'
      }
    }
  ],
  animationDuration: 500
}
