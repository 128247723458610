<template>
  <div class="map-history-list">
    <ul
      v-if="list.length"
      class="map-history-list__container"
    >
      <li
        v-for="item in list"
        :key="item.id"
        :class="getClassName(item.index)"
        @click="setFlyToGeom(item.geom)"
      >
        <div :class="`map-history-list__type ${getTypeClassname(item.type)}`">
          <div class="map-history-list__type-inner" />
        </div>
        <div class="map-history-list__info">
          <div class="map-history-list__date">
            <r-text>
              {{ item.date }}
            </r-text>
          </div>
          <div class="map-history-list__user">
            <r-text type="caption">
              Изменил: {{ item.user }}
            </r-text>
          </div>
        </div>
        <div
          v-if="item.geom"
          class="map-history-list__action"
        >
          <button>
            <r-icon
              name="focus-zone"
              :size="18"
            />
          </button>
        </div>
      </li>
    </ul>
    <div
      v-else
      class="map-history-list__nodata"
    >
      <r-text type="caption">
        Изменения за указанный период отсутствуют
      </r-text>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    historyHoveredIndex() {
      return this.$store.state.map.historyHoveredIndex
    }
  },
  methods: {
    getTypeClassname(type) {
      return `map-history-list__type--${type}`
    },
    getClassName(index) {
      if (index === this.historyHoveredIndex) {
        return 'map-history-list__item map-history-list__item--hovered'
      }

      return 'map-history-list__item'
    },
    setFlyToGeom(geometry) {
      if (geometry) {
        this.$store.commit('SET_FLY_TO_GEOM', geometry)
      }
    }
  }
}
</script>

<style lang="scss">
.map-history-list {
  flex: 1;
  padding: 8px 0;

  &__item {
    display: flex;
    align-items: center;
    background-color: var(--bg_containers);
    border-radius: var(--border-radius);
    padding: 4px 8px;
    cursor: pointer;

    &:not(:first-child) {
      margin-top: 8px;
    }

    &:hover {
      background-color: var(--accent_hover);
    }

    &--hovered {
      background-color: var(--accent_active);
    }
  }

  &__info {
    margin-right: 8px;
  }

  &__type {
    margin-right: 8px;

    &--insert {
      .map-history-list__type-inner {
        background-color: var(--accent_success);
      }
    }

    &--delete {
      .map-history-list__type-inner {
        background-color: var(--accent_error);
      }
    }

    &--update {
      .map-history-list__type-inner {
        background-color: var(--accent_warning);
      }
    }

    &-inner {
      width: 8px;
      height: 8px;
      border: 1px solid #fff;
      border-radius: 50%;
    }
  }

  &__date {
    margin-top: 4px;
  }

  &__action {
    margin-left: auto;

    button {
      background: none;
      border: none;
    }
  }

}
</style>
