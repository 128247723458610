export const fileTypes = [
  {
    id: 1,
    value: 'json',
    icon: 'json'
  },
  {
    id: 2,
    value: 'csv',
    icon: 'csv'
  },
  {
    id: 3,
    value: 'xlsx',
    icon: 'osm'
  }
]

export const typesList = [
  {
    id: 1,
    value: 'table'
  },
  {
    id: 2,
    value: 'table-chart'
  },
  {
    id: 3,
    value: 'table-map'
  },
  {
    id: 4,
    value: 'table-map-chart'
  }
]
