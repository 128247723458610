<template>
  <bdd-pass-register />
</template>

<script>
import bddPassRegister from '@/components/bdd/pass-register/bdd-pass-register'

export default {
  components: { bddPassRegister }
}
</script>
