<template>
  <r-bottom-card
    :loading="loading"
    @close="closeCard"
  >
    <div
      v-if="location"
      slot="actions"
    >
      <card-actions
        :id="cardId"
        icon="bus-hollow"
        :title="title"
        :source_id="source_id"
        :location="location"
        @updated="getData"
      />
      <r-filter-set
        class="m-tmc-card-filter-set"
        :filters="filters"
        no-reset-button
        no-reset
        @reset-all="setEventTime"
        @load="updateFilters"
      />
    </div>
    <div
      slot="attributes"
      class="pt-attributes"
    >
      <card-attributes
        v-if="info && objectFields"
        :info="info"
        :fields="objectFields"
      />
    </div>
    <card-tabs
      v-if="!loading"
      :id="cardId"
      slot="tabs"
      :info="info"
    />
  </r-bottom-card>
</template>

<script>
import CardAttributes from './card-attributes'
import CardTabs from './card-tabs'
import CardActions from './card-actions'

export default {
  components: {
    CardAttributes,
    CardTabs,
    CardActions
  },
  data() {
    return {
      loading: false,
      info: null,
      objectFields: null,
      title: null,
      source_id: 'f09e59fb-ee58-482c-8b0f-9ac520cf2099',
      filters: [
        {
          id: 'event_time',
          type: 'interval-filter',
          format: 'dd.MM.yyyy HH:mm',
          dateFormat: 'DD.MM.YY HH:mm',
          pickerType: 'datetime',
          title: '',
          noClearable: true,
          prop: this.$store.state.monitoring.eventTime,
          fastValues: [
            { name: 'За последние 30 мин', value: 'last_30_minutes' }
          ],
          active: true
        }
      ]
    }
  },
  computed: {
    cardId() {
      return this.$store.state.monitoring.cardId
    },
    location() {
      return this.$store.state.monitoring.cardLocation
    }
  },
  watch: {
    cardId() {
      this.getData()
    },
    '$store.state.monitoring.lastUpdatedVehicleId'(id) {
      if (id === this.cardId) {
        this.getData()

        this.$store.commit('SET_MT_FIELD', {
          field: 'lastUpdatedVehicleId',
          value: null
        })
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        this.loading = true

        const config = {
          include: {
            bsmts_status: { only: ['name'] },
            contractor: { only: ['name'] },
            colour: { only: ['name'] },
            protocol: { only: ['name'] },
            vehicle_type: {
              only: ['name'],
              include: { vehicle_type_group: { only: ['name'] } }
            },
            sub_contractor: { only: ['name'] }
          },
          where: [
            {
              field: 'id',
              op: '=',
              value: this.cardId
            }
          ]
        }
        const url = `vehicle/${this.source_id}?config=${JSON.stringify(
          config
        )}&id=${this.cardId}`
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        // get object fields
        const fields = await this.$store.dispatch('GET_REQUEST', {
          url: `objectFields/${this.source_id}`
        })

        this.info = Object.values(data)[0] || {}
        this.objectFields = fields.data

        // set card title
        const { reg_number, retranslation_status } = this.info
        this.title = `${reg_number} (${this.getVehicleStatus(
          retranslation_status
        )})`

        // set location
        this.$store.commit('SET_MT_FIELD', {
          field: 'cardLocation',
          value: this.info.last_location
        })

        this.loading = false
      } catch (error) {
        throw new Error(error)
      } finally {
        this.loading = false
      }
    },
    getVehicleStatus(status) {
      switch (status) {
        case 'online':
          return 'на связи'
        case 'without_coords_halfline':
        case 'with_coords_halfline':
        case 'with_coords_offline':
          return 'нет связи, отметка более 5 мин назад'
        case 'without_coords_offline':
          return 'на связи, нет координат более 30 мин'
        default:
          return 'нет данных'
      }
    },
    closeCard() {
      this.$store.commit('SET_MT_FIELD', {
        field: 'cardId',
        value: null
      })
      this.$store.commit('SET_MT_FIELD', {
        field: 'cardLocation',
        value: null
      })
      this.$store.commit('SET_MT_FIELD', {
        field: 'trackDataId',
        value: null
      })
      this.$store.commit('SET_MT_FIELD', {
        field: 'mainTrackGeom',
        value: null
      })
      this.$store.commit('SET_MT_FIELD', {
        field: 'secondTrackGeom',
        value: null
      })
    },
    updateFilters(filter) {
      const target = this.filters.find(f => f.id === filter.id)
      const item = { ...target, ...filter }

      this.filters[this.filters.indexOf(target)] = item

      this.setEventTime()
    },
    setEventTime() {
      this.$store.commit('SET_MT_FIELD', { field: 'eventTime', value: this.filters[0].prop })
    }
  }
}
</script>

<style lang="scss">
.m-tmc-card-filter-set {
  padding: 0.5rem 0 0 !important;

  .r-filter-set__item {
    margin: 0 !important;
    width: 100% !important;

    .r-button {
      width: 100% !important;
    }
  }
}
</style>
