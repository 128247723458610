<template>
  <el-dialog
    class="r-modal-window"
    title="Создать новое ТС"
    :visible="isVisible"
    :before-close="close"
    width="480px"
    top="50px"
    destroy-on-close
    :close-on-click-modal="false"
  >
    <r-block
      v-loading="loading"
      no-padding
    >
      <r-block
        no-padding
        col
      >
        <r-button
          type="primary"
          :disabled="saveDisabled"
          @click="create"
        >
          Сохранить ТС
        </r-button>
        <r-button
          simple
          @click="close"
        >
          Выйти без сохранения
        </r-button>
      </r-block>
      <attributes-list
        v-if="ready"
        :model="model"
        :related="related"
        :source="source"
        is-editing
      />
    </r-block>
  </el-dialog>
</template>

<script>
import attributesList from '@/components/globals/r-modal-elements/attributes-list'
import cloneDeep from 'lodash.clonedeep'
import { notifyFactory } from '@/utils'
import { vehicleModel, related } from '../config'

export default {
  components: { attributesList },
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    regNumber: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      ready: false,
      model: vehicleModel,
      source: {
        reg_number: this.regNumber,
        driver_full_name: null,
        driver_phone: null,
        model_id: null,
        vehicle_type_id: null,
        main_contractor_id: null
      }
    }
  },
  computed: {
    related() {
      return this.$store.state.journal.related
    },
    saveDisabled() {
      return !this.source?.reg_number
    }
  },
  created() {
    this.loadRelated()
  },
  methods: {
    async loadRelated() {
      if (this.related) {
        this.ready = true
        return
      }

      for (const r in related) {
        this.loading = true
        const urlName = related[r].urlName
        const source_id = this.$store.state.services[urlName]

        if (!source_id) return

        try {
          const config = cloneDeep(related[r].config)
          const { data } = await this.$store.dispatch('GET_REQUEST', {
            url: `objectInfo/${source_id}?config=${JSON.stringify(config)}`
          })

          related[r].data = Object.values(data)
          this.ready = true
        } catch (e) {
          throw new Error(e)
        } finally {
          this.loading = false
        }
      }
      this.$store.commit('SET_JOURNAL_FIELD', ['related', related])
    },
    async create() {
      this.loading = true

      try {
        const data = {}

        this.model.filter(m => this.source[m.model]).forEach(m => {
          data[m.model] = this.source[m.model]
        })

        await this.$store.dispatch('POST_REQUEST', {
          url: 'vehicle',
          data
        })

        this.$notify(
          notifyFactory(
            'success',
            'Новое транспортное средство успешно создано!'
          )
        )
        this.$emit('update-vehicles')
        this.close()
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
