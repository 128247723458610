import vue from 'vue'

const state = {
  name: '',
  isShow: false,
  isActive: false,
  attributesEditor: {
    id: null,
    source_id: null,
    hash: {}
  }
}

const mutations = {
  OPEN_ATTRIBUTES_EDITOR(state, { id, source_id }) {
    state.attributesEditor.id = id
    state.attributesEditor.source_id = source_id
    state.name = 'attributes-modal'
    state.isShow = true
  },
  SET_ATTRIBUTES_HASH(state, [id, data]) {
    state.attributesEditor.hash[id] = data
  },
  OPEN_MODAL_WINDOW(state, value) {
    vue.set(state, 'name', value)
    vue.set(state, 'isShow', true)
  },
  CLOSE_MODAL_WINDOW(state) {
    vue.set(state, 'name', '')
    vue.set(state, 'isShow', false)
    vue.set(state, 'isActive', false)
  },
  MODAL_WINDOW_ACTIVE_TOGGLER(state, value) {
    vue.set(state, 'isActive', value)
  }
}

export default {
  state,
  mutations
}
