import { reportOptions } from '../configs'
import { rDate } from '@/utils'

export class BsmtsActualMapping {
  constructor(state) {
    this.$store = state.$store
    this.state = state

    this.options = reportOptions.bsmts_actual_mapping
  }

  getTableData(data) {
    const { tableFields } = this.options

    return data.map(e => {
      const item = {}

      tableFields.forEach(l => {
        switch (l.type) {
          case 'date':
            item[l.title] = rDate.format(e[l.value], l.dateFormat || 'DD.MM.YYYY HH:mm:ss') || null
            break
          default:
            item[l.title] = e[l.value]
            break
        }
      })

      return item
    })
  }
}
