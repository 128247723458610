<template>
  <div
    :class="className"
    @click="toggleRouter"
  >
    <el-tooltip
      :open-delay="1500"
      content="Построение маршрута"
      placement="bottom"
    >
      <r-icon
        name="route"
        :size="24"
        :color-type="$route.name === 'odd-router' ? 'accent-primary' : ''"
      />
    </el-tooltip>
  </div>
</template>

<script>
export default {
  computed: {
    className() {
      return this.$route.name === 'odd-router'
        ? 'odd-router-button odd-router-button--active'
        : 'odd-router-button'
    }
  },
  methods: {
    toggleRouter() {
      const { name } = this.$route

      if (name === 'odd-router-view') {
        this.$router.push('/app/odd/objects')
      } else {
        this.$router.push('/app/odd/router/view')
      }
    }
  }
}
</script>

<style lang="scss">
.odd-router-button {
  width: 36px;
  height: 36px;
  border-radius: var(--border-radius);
  background-color: var(--modal_bg) !important;
  cursor: pointer;

  &--active {
    background-color: var(--accent_selected) !important;
  }

  &:hover {
    background-color: var(--accent_hover) !important;
  }
}
</style>
