import along from '@turf/along'
import bearing from '@turf/bearing'
import destination from '@turf/destination'
import { lineString } from '@turf/helpers'
import { Constants, CommonSelectors } from '@/libs/map-draw/lib-utils'
import {
  getFeaturesByLayerId,
  createHoveredSPHelper,
  getStopPointsData,
  createStopPointsHelpers,
  removeStopPointsHelpers,
  removeHoveredSPHelpers,
  nearestPointOnLine,
  isPointInLinkArea,
  getNearestFeature
} from '@/libs/map-draw/helpers'
import { rDate } from '@/utils'

export const createODDDrawSignsMode = (MapboxDraw, map) => {
  const DrawSigns = {}
  const customState = {
    hoveredFeatureId: '',
    no: '',
    fromnodeno: '',
    type: '',
    currentPoint: null,
    projectionPoint: null,
    point: null
  }

  const { ids } = map.$store.state.odd.model

  DrawSigns.onTap = DrawSigns.onClick = function(state) {
    const {
      hoveredFeatureId,
      currentPoint,
      projectionPoint,
      type,
      point
    } = customState

    if (hoveredFeatureId && currentPoint) {
      const { coordinates } = currentPoint

      this.updateUIClasses({ mouse: Constants.cursors.MOVE })
      state.point.updateCoordinate('', coordinates[0], coordinates[1])

      if (type === 'link') {
        state.point.setProperty('link_id', hoveredFeatureId)
      } else {
        state.point.setProperty('node_id', hoveredFeatureId)
      }

      // set properties
      state.point.setProperty(
        'name',
        `Новая стойка от ${rDate.format(new Date(), 'DD.MM.YY')}`
      )
      state.point.setProperty('projection', projectionPoint)
      state.point.setProperty('point', point)

      removeStopPointsHelpers(this.map)
      this.map.fire(Constants.events.CREATE, {
        features: [state.point.toGeoJSON()]
      })

      // change mode
      this.changeMode(Constants.modes.SIMPLE_SELECT, {
        featureIds: [state.point.id]
      })
      map.$store.commit('SET_ODD_EDITOR_PROP', {
        field: 'mode',
        value: 'select'
      })
    }
  }

  DrawSigns.onMouseMove = function(state, e) {
    const { lng, lat } = e.lngLat
    const nodesFeatures = getFeaturesByLayerId(this.map, e, ids.nodes, 50)
    const linksFeatures = getFeaturesByLayerId(this.map, e, ids.links, 100)
    const racksFeatures = getFeaturesByLayerId(this.map, e, 'racks', 10)

    customState.point = e.point
    if (racksFeatures.length || nodesFeatures.length) {
      this.map.getCanvas().style.cursor = 'pointer'
      const feature = getNearestFeature([...nodesFeatures, ...racksFeatures], [lng, lat])
      const { coordinates } = feature.geometry

      if (customState.hoveredFeatureId !== feature.properties.id) {
        removeStopPointsHelpers(this.map)
        customState.hoveredFeatureId = ''

        createHoveredSPHelper(this.map, coordinates)
        const { id, no } = feature.properties
        customState.hoveredFeatureId = id
        customState.currentPoint = {
          type: 'Point',
          coordinates
        }
        customState.projectionPoint = {
          type: 'Point',
          coordinates
        }
        customState.type = 'node'
        customState.no = no
      }
    } else if (linksFeatures.length) {
      this.map.getCanvas().style.cursor = ''

      const link = linksFeatures.find(l =>
        isPointInLinkArea([lng, lat], l.geometry.coordinates, 100)
      )

      if (link) {
        removeHoveredSPHelpers(this.map)
        const nearest = nearestPointOnLine(
          [lng, lat],
          link.geometry.coordinates
        )
        const current = {
          geometry: {
            type: 'Point',
            coordinates: [lng, lat]
          },
          properties: {
            projection: 'true'
          }
        }
        const connection = {
          geometry: {
            type: 'LineString',
            coordinates: [nearest.geometry.coordinates, [lng, lat]]
          }
        }

        const options = { units: 'kilometers' }
        const angle = bearing(nearest.geometry, current.geometry)
        const destinationPoint = destination(nearest, 0.02, angle, options)
        const destinationLine = [
          nearest.geometry.coordinates,
          destinationPoint.geometry.coordinates
        ]

        const point = along(lineString(destinationLine), 0.02, options)

        const { id, no, fromnodeno } = link.properties

        customState.hoveredFeatureId = id
        customState.type = 'link'
        customState.no = no
        customState.fromnodeno = fromnodeno
        customState.projectionPoint = nearest.geometry

        let data

        if (map.$store.state.odd.editorState.signsFixDistance) {
          customState.currentPoint = point.geometry

          data = getStopPointsData(nearest, point, {
            geometry: {
              type: 'LineString',
              coordinates: [
                nearest.geometry.coordinates,
                point.geometry.coordinates
              ]
            }
          })
        } else {
          customState.currentPoint = current.geometry

          data = getStopPointsData(nearest, current, connection)
        }

        createStopPointsHelpers(this.map, data)
      } else {
        removeStopPointsHelpers(this.map)
        customState.hoveredFeatureId = ''
        customState.type = ''
        customState.currentPoint = null
        customState.projectionPoint = null
      }
    } else {
      this.map.getCanvas().style.cursor = ''
      removeStopPointsHelpers(this.map)
      customState.hoveredFeatureId = ''
      customState.type = ''
      customState.currentPoint = null
      customState.projectionPoint = null
    }
  }

  DrawSigns.onKeyUp = function(state, e) {
    if (CommonSelectors.isEscapeKey(e)) {
      this.stopDrawingAndRemove(state, e)

      this.changeMode('draw_signs')
    }
  }

  return { ...MapboxDraw.modes.draw_point, ...DrawSigns }
}
