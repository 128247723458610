import { lineString, polygon } from '@turf/helpers'
import { toMercator, toWgs84 } from '@turf/projection'
import lineOffset from '@/libs/line-offset'

export const getBranchParam = id => (id ? `branch_id=${id}` : '')
export const getCalcParams = (scenarioId, time_value) =>
  `branch_id=${scenarioId || ''}&calculation_id=${scenarioId ||
    ''}&time_value=${time_value || ''}`

export const getDiagramGeojson = (features, config, id, store) => {
  const { field, maxValue, width } = config
  const diagramFeatures = []

  const getFeatureValue = (f, fieldName) => {
    // const { objectFields } = store.state.modelling
    // const fields = objectFields[id]
    // const fieldConfig = fields.find(f => f.title === fieldName)

    // if (fieldConfig && fieldConfig.calc_field) {
    //   return (
    //     Math.abs(f.properties[`link_calculation_results_${fieldName}`]) || 0
    //   )
    // }

    // console.log('properties --- ', f.properties)

    return Math.abs(f.properties[fieldName]) || 0
  }

  // console.log('field --- ', field)

  features.forEach(f => {
    const value = getFeatureValue(f, field)

    // console.log('value --- ', field, value)

    const weight = field ? (value / maxValue) * width : width

    // console.log('weight --- ', weight)

    if (isFinite(weight) && weight > 0) {
      const initial = f.geometry.coordinates
      const line = lineString(initial)
      const mercLine = toMercator(line)
      const lineCoords = mercLine.geometry.coordinates
      const offset = lineOffset(mercLine, weight, { units: 'metres' })

      if (offset) {
        const offsetCoords = offset.geometry.coordinates
        offsetCoords.reverse()
        const mercCoordinates = [
          [...lineCoords, ...offsetCoords, lineCoords[0]]
        ]
        const coordinates = toWgs84(polygon(mercCoordinates)).geometry
          .coordinates

        diagramFeatures.push({
          type: 'Feature',
          properties: { ...f.properties },
          geometry: {
            type: 'Polygon',
            coordinates
          }
        })
      }
    }
  })

  return { type: 'FeatureCollection', features: diagramFeatures }
}

export const createClassesValue = (field, conditions, store, id) => {
  const { objectFields, stylesConfigs } = store.state.modelling
  const { diagram } = stylesConfigs[id]
  const { secondField } = diagram
  const fields = objectFields[id]
  const { min, max } = fields.find(f => f.title === secondField && f.type === 'float')

  if (min === max) {
    return conditions[0].style.color
  }

  const value = ['interpolate', ['linear'], ['get', field]]

  for (let i = conditions.length - 1; i >= 0; i--) {
    if (i === conditions.length - 1) {
      value.push(0)
      value.push(conditions[i].style.color)
    } else {
      value.push(conditions[i + 1].value)
      value.push(conditions[i].style.color)
    }
  }

  return value
}
