<template>
  <div class="ee-editor-help">
    <div
      v-if="helpShow"
      class="ee-editor-help__container"
    >
      <div
        v-if="state.mode === 'create'"
        class="ee-editor-help__subcontainer"
      >
        <div class="ee-editor-help__subtitle">
          Создание
        </div>
        <div class="ee-editor-help__tips">
          <div class="ee-editor-help__block">
            <strong>ЛКМ</strong> - выбор местоположения и создание объекта
          </div>
          <div class="ee-editor-help__block">
            <strong>ДЛКМ</strong> - завершить создание объекта
          </div>
          <div class="ee-editor-help__block">
            <strong>Escape</strong> - отмена создания объекта
          </div>
        </div>
      </div>
      <div
        v-else
        class="ee-editor-help__subcontainer"
      >
        <div class="ee-editor-help__subtitle">
          Редактирование
        </div>
        <div class="ee-editor-help__tips">
          <div class="ee-editor-help__block">
            <strong>ЛКМ</strong> - выбор объекта, открытие карточки для
            редактирования параметров объекта
          </div>
          <div class="ee-editor-help__block">
            <strong>Backspace</strong> - удаление объекта
          </div>
        </div>
      </div>
    </div>
    <div
      class="ee-editor-help__title"
      @click="helpShow = !helpShow"
    >
      <span :class="helpShow ? 'el-icon-caret-top' : 'el-icon-caret-right'" />
      Помощь
    </div>
  </div>
</template>

<script>
export default {
  props: {
    state: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      helpShow: true
    }
  }
}
</script>

<style lang="scss">
.ee-editor-help {
  color: var(--text_secondary) !important;

  &__title {
    font-size: 12px;
    cursor: pointer;
    margin: 16px;
    margin-top: 5px;
  }

  &__container {
    padding: 16px;
    border-top: 1px solid transparent;
    border-color: var(--dividers_low_contrast) !important;
  }

  &__subtitle {
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 12px;
    border-radius: var(--border-radius);
    text-transform: uppercase;
    color: var(--text_primary) !important;
  }

  &__block {
    font-size: 11px;

    b {
      color: var(--text_primary) !important;
    }

    &:not(:first-child) {
      margin-top: 5px;
    }
  }

  &__image {
    margin-top: 16px;
    width: 100%;
    height: auto;
    border-radius: var(--border-radius);
    color: var(--text_secondary) !important;
    background-color: var(--bg_containers) !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
