<template>
  <r-bottom-card
    :loading="loading"
    :icon="getIcon()"
    :title="title"
    @close="closeCard"
  >
    <div
      v-if="location"
      slot="actions"
      class="mt-card__actions"
    >
      <card-actions
        :id="cardId"
        :info="info"
        :location="location"
      />
    </div>
    <div
      slot="attributes"
      class="mt-attributes"
    >
      <card-attributes
        v-if="info && objectFields"
        :info="info"
        :fields="objectFields"
        :type="vehicleType"
      />
    </div>

    <card-tabs
      :id="cardId"
      slot="tabs"
      class="mt-tabs"
      :info="info"
    />
  </r-bottom-card>
</template>

<script>
import CardAttributes from './card-attributes'
import CardTabs from './card-tabs'
import CardActions from './card-actions'

export default {
  components: {
    CardAttributes,
    CardTabs,
    CardActions
  },
  data() {
    return {
      location: null,
      loading: false,
      info: {},
      title: null,
      refreshData: null,
      cargoSourceId: this.$store.state.services.cargoes,
      etran_source_id: this.$store.state.monitoringTmc.etran_source_id,
      vehicles_source_id: this.$store.state.monitoringTmc.vehicles_source_id,
      fields: null
    }
  },
  computed: {
    cardId() {
      return this.$store.state.monitoringTmc.cardId
    },
    vehicles() {
      return this.$store.state.monitoringTmc.vehicles || []
    },
    vehicleType() {
      const vehicle = this.vehicles.find(e => e.id === this.cardId)

      return vehicle?.type_name
    },
    etranObjectFields() {
      return this.$store.state.monitoringTmc.etranObjectFields || null
    },
    vehiclesObjectFields() {
      return this.$store.state.monitoringTmc.vehiclesObjectFields || null
    },
    objectFields() {
      switch (this.vehicleType) {
        case 'ЖД':
          return this.etranObjectFields
        case 'Грузовые':
          return this.vehiclesObjectFields
        case 'Баржа':
          return {}
        default:
          return null
      }
    }
  },
  watch: {
    cardId() {
      this.getData(true)
    }
  },
  created() {
    this.getData(true)
    this.refreshData = setInterval(this.getData, 300000)
  },
  beforeDestroy() {
    clearInterval(this.refreshData)
  },
  methods: {
    async getData(loading = false) {
      try {
        this.loading = loading

        switch (this.vehicleType) {
          case 'Баржа':
            await this.getBarge()
            break
          case 'ЖД':
            await this.getRailway()
            break
          case 'Грузовые':
            await this.getVehicle()
            break
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    async getVehicle() {
      try {
        const config = {
          where: [
            {
              field: 'id',
              op: '=',
              value: this.cardId
            }
          ],
          include: {
            contractor: { only: ['name'] },
            cargoes: {
              include: {
                contractor: { only: ['name'] },
                sender: { only: ['name'] }
              }
            }
          }
        }
        const url = `vehicle/${this.vehicles_source_id}?config=${JSON.stringify(config)}`
        console.log(url)
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        this.info = Object.values(data)[0] || {}
        this.title = String(this.info.reg_number)
        this.location = this.info.last_location
      } catch (e) {
        console.error(e)
      }
    },
    async getRailway() {
      try {
        const config = {
          where: [
            {
              field: 'id',
              op: '=',
              value: this.cardId
            }
          ],
          include: {
            contractor: { only: ['name'] },
            cargoes: {
              include: {
                contractor: { only: ['name'] },
                sender: { only: ['name'] }
              }
            }
          }
        }
        const url = `ZdDislocationData?format=list&config=${JSON.stringify(config)}`
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        this.info = Object.values(data)[0] || {}
        this.title = String(this.info.carriage_no)
        this.location =
          this.info.last_location || this.info.current_point || this.info.geom
      } catch (e) {
        console.error(e)
      }
    },
    async getBarge() {
      try {
        const config = {
          where: [
            {
              field: 'id',
              op: '=',
              value: this.cardId
            }
          ],
          include: {
            contractor: { only: ['name'] },
            cargoes: {
              include: {
                contractor: { only: ['name'] },
                sender: { only: ['name'] }
              }
            }
          }
        }
        const url = `objectInfo/telemetry.barges?config=${JSON.stringify(config)}`
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        this.info = Object.values(data)[0] || {}
        this.title = String(this.info.number)
        this.location = this.info.geom
      } catch (e) {
        console.error(e)
      }
    },
    closeCard() {
      this.$store.commit('MT_TMC_SET_FIELD', {
        field: 'cardId',
        value: null
      })
      this.$store.commit('MT_TMC_SET_FIELD', {
        field: 'trackDataId',
        value: null
      })
      this.$store.commit('MT_TMC_SET_FIELD', {
        field: 'cardTrack',
        value: null
      })
    },
    getIcon() {
      switch (this.vehicleType) {
        case 'ЖД':
          return 'train-filled'
        case 'Автобус':
          return 'bus-filled'
        default:
          return 'car-filled'
      }
    }
  }
}
</script>

<style lang="scss"></style>
