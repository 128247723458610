import turfLength from '@turf/length'
import { lineString } from '@turf/helpers'
import { getFeaturesByLayerId } from '@/libs/map-draw/helpers'
import { modifyLinksGeometry } from '@/utils'
import { layersConfig } from '../configs'

const pointsId = 'odd-router-points'
const routeId = 'odd-router-result'

export class RouterController {
  constructor(parent) {
    this.parent = parent
    this.$store = parent.$store
    this.mapgl = parent.mapgl

    // set handlers
    this.mousemoveHandler = this.mousemoveHandler.bind(this)
    this.clickHandler = this.clickHandler.bind(this)
    this.keyHandler = this.keyHandler.bind(this)

    // variables
    this.points = []
    this.hoveredPoint = null
  }

  // handlers
  mousemoveHandler(e) {
    const { ids } = this.$store.state.odd.model
    const features = getFeaturesByLayerId(this.mapgl, e, ids.nodes, 50)

    if (features.length) {
      // find nearest feature
      const { lng, lat } = e.lngLat
      let length = Infinity
      let feature = features[0]

      for (let i = 0; i < features.length; i++) {
        const point = features[i]
        const line = lineString([
          [lng, lat],
          JSON.parse(point.properties.geom).coordinates
        ])
        const lineDistance = turfLength(line, { units: 'meters' })

        if (lineDistance < length) {
          length = lineDistance
          feature = point
        }
      }

      this.hoveredPoint = feature
    } else {
      this.hoveredPoint = null
    }

    this.updatePointsData()
  }

  clickHandler(e) {
    if (this.hoveredPoint) {
      this.points.push(this.hoveredPoint)

      if (this.points.length > 1) {
        this.showRoute()
      }
    }
  }

  keyHandler(e) {
    if (this.parent.makingRoutingRequest) return

    const { code, ctrlKey } = e

    // if (code === 'Escape' || code === 'Backspace') {
    if (code === 'Escape') {
      this.points = []
      this.updatePointsData()
      this.clearRoute()

      return
    }

    if (code === 'KeyZ' && ctrlKey) {
      this.points.pop()
      this.updatePointsData()

      if (this.points.length > 1) {
        this.showRoute()
      } else {
        this.clearRoute()
      }
    }
  }

  // enable / disable controls
  enableRouter() {
    // handlers
    this.mapgl.on('mousemove', this.mousemoveHandler)
    this.mapgl.on('click', this.clickHandler)
    window.addEventListener('keyup', this.keyHandler)

    // add route layer
    this.mapgl.addSource(routeId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })
    this.mapgl.addLayer({
      id: routeId,
      source: routeId,
      ...layersConfig.router_lines
    })
    this.mapgl.addLayer({
      id: `${routeId}_arrows`,
      source: routeId,
      ...layersConfig.router_arrows
    })

    // add hovered point layer
    this.mapgl.addSource(pointsId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })
    this.mapgl.addLayer({
      id: pointsId,
      source: pointsId,
      ...layersConfig.router_points
    })
  }

  disableRouter() {
    this.$store.commit('SET_ODD_ROUTER_PROP', {
      field: 'geometry',
      value: null
    })

    this.points = []
    this.hoveredPoint = null

    this.mapgl.off('mousemove', this.mousemoveHandler)
    this.mapgl.off('click', this.clickHandler)
    window.removeEventListener('keyup', this.keyHandler)

    if (this.mapgl.getSource(pointsId)) {
      this.mapgl.removeLayer(pointsId)
      this.mapgl.removeSource(pointsId)
    }

    if (this.mapgl.getSource(routeId)) {
      this.mapgl.removeLayer(routeId)
      this.mapgl.removeLayer(`${routeId}_arrows`)
      this.mapgl.removeSource(routeId)
    }
  }

  getLocString() {
    return `[${this.points
      .map(p => `[${JSON.parse(p.properties.geom).coordinates}]`)
      .join(',')}]`
  }

  getRouteGeometry(data) {
    const { links } = data

    // get modified links
    const modifiedLinks = modifyLinksGeometry(links)
    const resultCoordinates = modifiedLinks.reduce((prev, el, i) => {
      const { coordinates } = el.geom

      return [...prev, ...coordinates]
    }, [])
    const resultGeometry = {
      type: 'LineString',
      coordinates: resultCoordinates
    }

    return resultGeometry
  }

  async showRoute() {
    try {
      this.parent.makingRoutingRequest = true

      const source = this.mapgl.getSource(routeId)
      const { model } = this.$store.state.odd
      const locString = this.getLocString()

      const url = `route/${model.id}?coordinates=${locString}`
      const { data } = await this.$store.dispatch('GET_REQUEST', {
        url
      })
      const geometry = this.getRouteGeometry(data)

      source.setData({
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry
          }
        ]
      })

      this.$store.commit('SET_ODD_ROUTER_PROP', {
        field: 'geometry',
        value: geometry
      })

      this.parent.makingRoutingRequest = false
    } catch (error) {
      console.log(error)
      this.parent.makingRoutingRequest = false
    }
  }

  clearRoute() {
    const source = this.mapgl.getSource(routeId)

    this.points = []

    source.setData({
      type: 'FeatureCollection',
      features: []
    })

    this.$store.commit('SET_ODD_ROUTER_PROP', {
      field: 'geometry',
      value: null
    })
  }

  updatePointsData() {
    const source = this.mapgl.getSource(pointsId)
    const features = this.points.map(f => ({
      type: 'Feature',
      properties: {
        type: 'point'
      },
      geometry: f.geometry
    }))

    if (this.hoveredPoint) {
      features.push({
        properties: {
          type: 'hovered'
        },
        geometry: this.hoveredPoint.geometry
      })
    }

    if (source) {
      source.setData({
        type: 'FeatureCollection',
        features
      })
    }
  }

  addRoutesLayer() {
    const layerId = 'odd-router-routes'

    this.mapgl.addSource(layerId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })
    this.mapgl.addLayer({
      id: layerId,
      source: layerId,
      ...layersConfig.routes_lines
    })
    this.mapgl.addLayer({
      id: `${layerId}_arrows`,
      source: layerId,
      ...layersConfig.routes_arrows
    })
  }

  updateRoutesLayer(routes) {
    const source = this.mapgl.getSource('odd-router-routes')
    const data = {
      type: 'FeatureCollection',
      features: routes.map(r => ({
        type: 'Feature',
        properties: {
          color: r.color
        },
        geometry: r.geom
      }))
    }

    if (source) {
      source.setData(data)
    }
  }
}
