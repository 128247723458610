<template>
  <div class="m-editor">
    <div class="m-editor__left">
      <r-panel-header
        :title="scenarioId ? 'Редактирование сценария' : 'Создание сценария'"
        :with-back-button="true"
        @back="back"
      />
      <div class="m-editor__container">
        <editor-controls />
      </div>
    </div>
    <r-second-panel
      v-if="activeEventId"
      @close="closePanel"
    >
      <event-attributes />
    </r-second-panel>
  </div>
</template>

<script>
import EditorControls from '@/components/modelling/editor/editor-controls'
import EventAttributes from '@/components/modelling/editor/event-attributes'

export default {
  components: {
    EditorControls,
    EventAttributes
  },
  computed: {
    activeEventId() {
      return this.$store.state.modelling.activeEventId
    },
    scenarioId() {
      return this.$route.query.id
    }
  },
  methods: {
    back() {
      const { calculating, loadingLayers } = this.$store.state.modelling
      if (loadingLayers.length) return
      if (calculating) return

      if (this.activeEventId) this.closePanel()

      this.$router.push('/app/modelling/main/list')
    },
    closePanel() {
      this.$cControllers.drawContext.deselect(this.activeEventId)

      this.$store.commit('SET_CALC_FIELD', {
        field: 'activeEventId',
        value: null
      })
      this.$store.commit('SET_CALC_FIELD', {
        field: 'overlay',
        value: false
      })
    }
  }
}
</script>

<style lang="scss">
.m-editor {
  display: flex;
  height: calc(100vh - 48px);

  &__left {
    display: flex;
    flex-direction: column;
    width: 260px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 0px;
  }
}
</style>
