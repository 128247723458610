<template>
  <r-toggle-card
    title="Эпюры"
    :is-open="config.enabled"
    @toggle="toggleHandler"
  >
    <div class="m-diagram">
      <div class="m-diagram__color">
        <r-color-picker
          v-if="!config.secondField"
          :color="config.color"
          :color-opacity="config.opacity"
          @change="changeColorProperty"
        />
        <div class="m-diagram__field">
          <r-text
            type="caption"
            style="margin-bottom: 8px;"
          >
            Цвет по атрибуту
          </r-text>
          <c-attributes
            :id="id"
            :config="config"
            datatype="links"
            field="secondField"
            @update="updateSecondField"
          />
        </div>
        <div class="m-diagram__palette">
          <r-text
            type="caption"
            style="margin-bottom: 8px;"
          >
            Цветовая схема
          </r-text>
          <el-select
            v-if="config.secondField"
            v-model="config.currentPalette"
            class="r-select dark palette"
            placeholder=""
            size="mini"
            :popper-append-to-body="false"
            popper-class="map-color-picker__palette-popper"
            value-key="id"
            @change="changePalette"
          >
            <template slot="prefix">
              <palette-colors
                :colors="config.currentPalette.value"
                :is-current="true"
              />
            </template>
            <el-option
              v-for="item in palettes"
              :key="item.id"
              :label="item.name"
              :value="item"
            >
              <palette-colors :colors="item.value" />
            </el-option>
          </el-select>

          <classifications
            :conditions="config.conditions"
            @apply-classes="applyClasses"
            @change-conditions="changeConditions"
          />
        </div>
      </div>
      <div class="m-diagram__width">
        <r-slider
          style="margin-top: 8px;"
          title="Ширина"
          :number="config.width"
          :min="50"
          :max="1000"
          :step="10"
          @change="changeWidthProperty"
        />
        <div class="m-diagram__field">
          <r-text
            type="caption"
            style="margin-bottom: 8px;"
          >
            Ширина по атрибуту
          </r-text>
          <c-attributes
            :id="id"
            :config="config"
            datatype="links"
            field="field"
            @update="updateField"
          />
        </div>
      </div>
    </div>
  </r-toggle-card>
</template>

<script>
import { palettes } from '@/components/map/components/layer-settings/config'
import PaletteColors from '@/components/map/components/layer-settings/input-sources/palette-colors'
import Classifications from '@/components/map/components/layer-settings/input-sources/classifications'
import CAttributes from '../attributes/attributes'

export default {
  components: {
    CAttributes,
    PaletteColors,
    Classifications
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      palettes
    }
  },
  computed: {
    config() {
      return this.$store.state.modelling.stylesConfigs[this.id].diagram
    }
  },
  methods: {
    toggleHandler(value) {
      this.config.enabled = value

      this.$cControllers.diagram.toggleDiagram(this.id)
    },
    changeColorProperty({ color, opacity }) {
      this.config.color = color
      this.config.opacity = opacity

      this.$cControllers.diagram.changeDiagramProperty(this.id, 'color')
      this.$cControllers.diagram.changeDiagramProperty(this.id, 'opacity')
    },
    changeWidthProperty(value) {
      this.config.width = value

      this.$cControllers.diagram.addDiagramLayer(this.id)
    },
    updateField(value) {
      if (value) {
        const { objectFields } = this.$store.state.modelling
        const fields = objectFields[this.id]
        const { max, min } = fields.find(f => f.title === value && f.type === 'float')

        this.$set(this.config, 'minValue', min)
        this.$set(this.config, 'maxValue', max)
      }

      this.$cControllers.diagram.addDiagramLayer(this.id)
    },
    updateSecondField(value) {
      if (value) {
        this.setConditions()
        this.$cControllers.diagram.changeDiagramConditions(this.id)
      } else {
        this.$set(this.config, 'conditions', [])
        this.$cControllers.diagram.changeDiagramProperty(this.id, 'color')
      }
    },
    setConditions() {
      const { currentPalette, secondField } = this.config
      const colors = currentPalette.value
      const { objectFields } = this.$store.state.modelling
      const fields = objectFields[this.id]
      const { max, min } = fields.find(f => f.title === secondField && f.type === 'float')
      const step = Number(((max - min) / 5).toFixed(1))

      const conditions = colors.map((color, i) => {
        const id = Date.now() + i
        const index = i === 0 ? colors.length - i - 1 : colors.length - i
        const value = Number(((index + 1) * step).toFixed(1))
        const style = {
          color
        }
        const initial = i === 0 ? true : undefined

        return { id, value, style, initial }
      })

      this.$set(this.config, 'conditions', conditions)
    },
    changePalette() {
      this.setConditions()
      this.$cControllers.diagram.changeDiagramConditions(this.id)
    },
    changeConditions(conditions) {
      this.$set(this.config, 'conditions', conditions)
    },
    applyClasses() {
      this.$cControllers.diagram.changeDiagramConditions(this.id)
    }
  }
}
</script>

<style lang="scss">
.m-diagram {
  &__palette {
    margin-top: 8px;

    .map-palette-colors {
      width: 100%;
    }

    .el-select-dropdown__item {
      height: 36px !important;
      line-height: 36px !important;
    }
  }

  &__width {
    margin-top: 24px;
  }

  &__field {
    margin-top: 8px;

    .r-select {
      width: 100%;
    }
  }
}
</style>
