export const findLayerNameById = function(list, id) {
  const layer = findLayer(list, id)

  return layer ? layer.name : id
}

const findLayer = function(list, id) {
  let layer

  list.some(e => {
    if (e.children && e.children.length) {
      layer = findLayer(e.children, id)
      if (layer) return true
      return false
    }
    if (!layer && e.id === id) {
      layer = e
      return true
    }

    return false
  })

  return layer
}
