<template>
  <div
    v-loading="loading"
    class="pn-view"
  >
    <r-button
      type="primary"
      class="pn-view__add-button"
      @click="$emit('mode', ['create'])"
    >
      Создать новое уведомление
    </r-button>
    <el-radio-group
      v-model="notificationsTab"
      class="pn-view__relevance-toggler"
    >
      <el-radio-button label="active">
        Активные
      </el-radio-button>
      <el-radio-button label="history">
        История
      </el-radio-button>
    </el-radio-group>
    <r-search-input
      placeholder="Поиск по темам"
      :filter-text="filterText"
      @change="e => filterChange(e)"
    />
    <div
      v-if="notificationsTab === 'active'"
      class="pn-view__list pn-view__list--active"
    >
      <notification-item
        v-for="notification in filteredActiveNotifications"
        :key="notification.id"
        :notification="notification"
        :source_id="source_id"
        @update="loadNotifications"
        @edit="edit"
      />
      <r-text
        v-if="!activeNotifications.length && !loading"
        type="caption"
      >
        Активные уведомления отсутствуют
      </r-text>
    </div>
    <div
      v-else
      class="pn-view__list pn-view__list--history"
    >
      <notification-item
        v-for="notification in filteredHistoryNotifications"
        :key="notification.id"
        :notification="notification"
        :source_id="source_id"
        @update="loadNotifications"
        @edit="edit"
      />
      <r-text
        v-if="!historyNotifications.length && !loading"
        type="caption"
      >
        Неактивные уведомления отсутствуют
      </r-text>
    </div>
  </div>
</template>

<script>
import notificationItem from './notification-item'
import { hashToArray } from '@/utils'

export default {
  components: { notificationItem },
  props: {
    source_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      notificationsTab: 'active',
      filterText: '',
      loading: false,
      activeNotifications: [],
      historyNotifications: []
    }
  },
  computed: {
    filteredActiveNotifications() {
      return this.activeNotifications.filter(({ subject }) =>
        subject
          .toLowerCase()
          .trim()
          .includes(this.filterText.toLowerCase().trim())
      )
    },
    filteredHistoryNotifications() {
      return this.historyNotifications.filter(({ subject }) =>
        subject
          .toLowerCase()
          .trim()
          .includes(this.filterText.toLowerCase().trim())
      )
    }
  },
  mounted() {
    this.loadNotifications()
  },
  methods: {
    filterChange(e) {
      this.filterText = e
    },
    edit(item) {
      this.$emit('mode', ['edit', item])
    },
    async loadNotifications() {
      this.loading = true
      const config = {
        include: {
          created_user: { only: ['id', 'name'] },
          updated_user: { only: ['id', 'name'] }
        },
        order: ['updated_at']
      }

      const url = `objectInfo/${this.source_id}?config=${JSON.stringify(
        config
      )}`

      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })
        const arrayData = hashToArray(data).reverse()

        this.activeNotifications = arrayData.filter(n => n.status)
        this.historyNotifications = arrayData.filter(n => !n.status)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.pn-view {
  display: grid;
  grid-gap: 1rem;
  align-items: start;
  align-content: start;
  max-height: 70vh;
  height: 100%;
  overflow: hidden;

  &__add-button {
    font-weight: 400 !important;
    justify-self: start;
  }

  .el-radio-group.pn-view__relevance-toggler {
    display: flex;
    z-index: 2;
    height: 36px;

    .el-radio-button {
      flex: 1;

      .el-radio-button__inner {
        height: 36px !important;
        display: grid;
        align-items: center;
        justify-content: center;
        grid-auto-flow: column;
        grid-gap: 0.5rem;
        padding: 0;
        background-color: var(--bg_containers);
        border: 0;
        box-shadow: none !important;
        font-size: 14px;
        color: var(--text_primary);
        font-weight: 400;
      }

      &.is-active {
        .el-radio-button__inner {
          background-color: var(--accent_active) !important;
        }
      }
    }
  }

  &__list {
    border-radius: var(--border-radius);
    display: grid;
    grid-gap: 1rem;
    padding: 0 1rem;
    overflow-y: auto;
    align-content: start;
    height: 100%;
  }
}
</style>
