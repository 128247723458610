import vue from 'vue'

const state = {
  activeItem: null,
  related: null,
  updateList: false
}

const mutations = {
  UNLOAD_SET_FIELD(state, [field, value]) {
    vue.set(state, field, value)
  }
}

export default {
  state,
  mutations
}
