<template>
  <el-collapse-item :name="name">
    <template slot="title">
      <tasks-modal-list-item-title :task="task" />
    </template>
    <tasks-modal-list-item-content :task="task" />
  </el-collapse-item>
</template>

<script>
import { getTaskImage, getTaskHint } from '../../configs'

import tasksModalListItemTitle from './tasks-modal-list-item-title'
import tasksModalListItemContent from './tasks-modal-list-item-content'

export default {
  components: {
    tasksModalListItemTitle,
    tasksModalListItemContent
  },
  props: {
    task: {
      type: Object,
      default: () => null,
      required: true
    },
    name: {
      type: Number,
      default: null,
      required: true
    }
  },
  methods: {
    getDate(date) {
      return this.$rDate.format(date, 'HH:mm • DD.MM.YYYY')
    },
    getTaskImage,
    getTaskHint
  }
}
</script>
