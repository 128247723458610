<template>
  <simple-button
    :mapgl="mapgl"
    :module="module"
    :layer-id="id"
    :icon="icon"
    :title="title"
    :add-handler="addHandler"
    :layers-ids="layersIds"
  />
</template>

<script>
import mapboxgl from 'mapbox-gl'
import SimpleButton from '../buttons/simple-button'

import { jsonToGeojson } from '@/utils'

export default {
  components: {
    SimpleButton
  },
  props: {
    mapgl: {
      type: Object,
      required: true
    },
    module: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      id: 'shahmatka',
      gridId: 'grid',
      title: 'Шахматка',
      icon: 'folder-layer',
      url: 'objectInfo/3fc2ce59-6654-4e78-aa58-77583cfae23b',
      urlGrid: 'chess',
      config: {
        mapPolygon: {
          type: 'fill',
          layout: {},
          paint: {
            'fill-color': 'rgb(38, 190, 179)',
            'fill-opacity': 0.1
          }
        },
        mapBorder: {
          type: 'line',
          paint: {
            'line-color': 'rgb(38, 190, 179)',
            'line-width': 2
          }
        },
        grid: {
          type: 'symbol',
          layout: {
            'text-field': ['get', 'text']
          },
          paint: {
            'text-color': 'rgb(38, 190, 179)'
          }
        }
      },
      layersIds: []
    }
  },
  methods: {
    async addHandler() {
      const { id, url, gridId, urlGrid } = this
      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })
        const { data: grid } = await this.$store.dispatch('GET_REQUEST', {
          url: urlGrid
        })
        if (!data) return
        const features = jsonToGeojson(Object.values(data))
        const gridFeatures = jsonToGeojson(grid)
        if (!features) return
        const source = this.mapgl.getSource(id)
        const gridSource = this.mapgl.getSource(gridId)

        if (!source) {
          this.mapgl.addSource(id, {
            type: 'geojson',
            data: features
          })
          this.addLayers(id)
          this.mapgl.addSource(gridId, {
            type: 'geojson',
            data: gridFeatures
          })
          this.addGridLayers(gridId)
        } else {
          source.setData(features)
          gridSource.setData(gridFeatures)
        }

        this.layersIds = [id, `${id}_border`, gridId]
        this.addLayerHandlers(id)
      } catch (error) {
        console.log(error)
      }
    },
    addLayerHandlers(layer) {
      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        offset: 15,
        anchor: 'left',
        className: 'info-layer__custom-popup'
      })

      this.mapgl.on('mousemove', layer, e => {
        this.mapgl.getCanvas().style.cursor = ''
        popup.remove()
        this.addPopup(e, popup)
        this.mapgl.getCanvas().style.cursor = 'pointer'
      })

      this.mapgl.on('mouseleave', layer, _ => {
        this.mapgl.getCanvas().style.cursor = ''
        popup.remove()
      })
    },
    addPopup(e, popup) {
      const html = e.features?.map(f => {
        const data = f.properties
        const { name } = data

        const item = '<dl><dt>Слой: </dt><dd>' + this.title + '</dd><dt>Наименование: </dt><dd>' + (name || 'Не указано') + '</dd></dl>'

        return item
      })?.join('<hr size="1" noshade>')

      popup
        .setLngLat(e.lngLat)
        .setHTML(html)
        .addTo(this.mapgl)
    },
    addLayers(id) {
      const { mapPolygon, mapBorder } = this.config

      this.mapgl.addLayer({
        id: `${id}`,
        source: id,
        ...mapPolygon
      })

      this.mapgl.addLayer(
        {
          id: `${id}_border`,
          source: id,
          ...mapBorder
        }
      )
    },
    addGridLayers(id) {
      const { grid } = this.config

      this.mapgl.addLayer({
        id: `${id}`,
        source: id,
        ...grid
      })
    }
  }
}
</script>

<style lang="scss">
.info-layer__custom-popup {
  z-index: 99;

  .mapboxgl-popup {
    &-tip {
      border: none !important;
    }

    &-content {
      background-color: var(--bg_surface);
      display: grid;
      align-items: center;
      justify-content: start;
      grid-gap: 0.25rem;
      grid-auto-flow: row;
      padding: 0;
      border-radius: var(--border-radius);
      border: 1px solid transparent;
      border-color: var(--dividers_low_contrast) !important;

      dl {
        padding: 0.25rem 0.5rem;
        margin: 0;
      }

      dt {
        margin: 0;
        color: var(--text_secondary);
        font-size: 12px;
      }

      dd {
        font-weight: bold;
        margin: 0;
        color: var(--text_primary);
      }
    }
  }
}
</style>
