<template>
  <r-simple-card title="Обводка">
    <r-color-picker
      :color="config[colorField]"
      :color-opacity="config[opacityField]"
      @change="changeColorProperty"
    />
    <r-slider
      style="margin-top: 8px;"
      title="Ширина"
      :number="config[widthField]"
      :min="1"
      :max="10"
      :step="1"
      @change="changeWidthProperty"
    />
  </r-simple-card>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: 'Обводка'
    },
    config: {
      type: Object,
      required: true
    },
    colorField: {
      type: String,
      required: true
    },
    opacityField: {
      type: String,
      required: true
    },
    widthField: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      min: 1,
      max: 10
    }
  },
  methods: {
    changeColorProperty({ color, opacity }) {
      this.config[this.colorField] = color
      this.config[this.opacityField] = opacity

      if (this.type === 'nodes') {
        this.$cControllers.styles.changePaintProperty(this.id, this.colorField)
        this.$cControllers.styles.changePaintProperty(
          this.id,
          this.opacityField
        )
      } else {
        this.$cControllers.styles.changePolygonOutlineProperty(
          this.id,
          this.colorField
        )
        this.$cControllers.styles.changePolygonOutlineProperty(
          this.id,
          this.opacityField
        )
      }
    },
    changeWidthProperty(value) {
      this.config[this.widthField] = value

      if (this.type === 'nodes') {
        this.$cControllers.styles.changePaintProperty(this.id, this.widthField)
      } else {
        this.$cControllers.styles.changePolygonOutlineProperty(
          this.id,
          this.widthField
        )
      }
    }
  }
}
</script>

<style lang="scss">
.c-outline {
  &__input {
    display: flex;
    align-items: center;

    .r-slider {
      width: 75%;
      margin-right: 10px;
    }

    .r-input {
      width: 25% !important;
    }
  }
}
</style>
