
export const getConfig = (id) => {
  return {
    where: [{
      field: 'id',
      op: '=',
      value: id
    }],
    order: [{ created_at: 'desc' }],
    include: {
      violation_representatives_employees: {},
      violation_appointed_employees: {},
      detection_employee: { only: ['name'] },
      responsible: {},
      vehicle: {
        only: ['id', 'reg_number'],
        include: {
          model: {
            only: ['id', 'name']
          },
          vehicle_type: {
            only: ['id', 'name']
          },
          vehicle_class: {
            only: ['id', 'name']
          }
        }
      }
    }
  }
}
