<template>
  <ul
    v-if="fields && fields.length"
    class="sc-object-card__list-cameras"
  >
    <li
      v-for="(field, i) in fields"
      :key="field + i"
      class="sc-object-card__list-cameras-item"
    >
      <list-item-camera :field="field" />
    </li>
  </ul>
  <div
    v-else
    class="sc-object-card__list-cameras-no-data"
  >
    <r-text>{{ $t("no-cameras") }}</r-text>
  </div>
</template>

<script>
import listItemCamera from './renderer/list-item-camera'

export default {
  components: { listItemCamera },
  props: {
    fields: {
      type: Array,
      required: true,
      default: () => null
    }
  }
}
</script>

<style lang="scss">
.sc-object-card__list-cameras {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &-item {
    width: 48%;
    height: auto;
    margin-bottom: 16px;
    position: relative;
    border-radius: var(--border-radius);
    overflow: hidden;
    background-color: var(--bg_surface) !important;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &-no-data {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 40px;
  }
}
</style>

<i18n>
{
  "ru": {
    "location": "Месторасположение",
    "no-cameras": "Камер нет",
    "status": "Статус"
  },
  "en": {
    "location": "Location",
    "no-cameras": "No cameras",
    "status": "Status"
  }
}
</i18n>
