<template>
  <div class="pass-register-modal">
    <div
      v-if="appList.length"
      class="pass-register-modal__application-selector"
    >
      <r-text>
        {{ $t('register_modal::completed-app') }}
      </r-text>
      <el-select
        v-model="application"
        filterable
      >
        <el-option
          v-for="item in appList"
          :key="item.id"
          :value="item.id"
          :label="getVehicleLabel(item)"
        />
      </el-select>
    </div>
    <div
      v-else
      class="pass-register-modal__notice"
    >
      <r-icon
        :size="20"
        name="info"
        color="var(--accent_warning)"
      />
      <r-text> Нет подходящих заявок для создания пропуска </r-text>
    </div>
    <r-text>
      {{ $t('register_modal::app-not-found') }}
      <r-button
        simple
        active
        link
        @click="createApplication"
      >
        {{ $t('register_modal::create-app-button') }}
      </r-button>
    </r-text>
    <r-button
      type="success"
      class="pass-register-modal__create-button"
      :disabled="!canContinue"
      @click="continueCreating"
    >
      {{ $t('register_modal::create-button') }}
    </r-button>
  </div>
</template>

<script>
import { sources } from './config/fields'

export default {
  props: {
    source_id: {
      type: String,
      required: true
    },
    source: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      application: '',
      sources
    }
  },
  computed: {
    appList() {
      return Object.values(this.source.related.application.data)
    },
    canContinue() {
      return this.application
    },
    passBasedOnAppId() {
      return this.$store.state.bdd.passBasedOnAppId
    }
  },
  watch: {
    application() {
      this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', true)
    }
  },
  mounted() {
    if (this.passBasedOnAppId) {
      this.application = this.passBasedOnAppId
    }
    this.setWidth(400)
  },
  beforeDestroy() {
    this.setWidth(640)
    this.$store.commit('BDD_SET_FIELD', {
      field: 'passBasedOnAppId',
      value: null
    })
  },
  methods: {
    getVehicleLabel(item) {
      return (
        item.vehicle?.reg_number || `Заявка № ${item.app_id} недействительна!`
      )
    },
    setWidth(width) {
      const dialog = document.querySelector('[aria-label="dialog"]')
      if (dialog) {
        dialog.style.width = `${width}px`
      }
    },
    continueCreating() {
      this.$emit('application', this.application)
      this.$emit('mode', 'create')
    },
    createApplication() {
      this.$router.push({ path: '/app/bdd/applications' })
      this.$store.commit('CLOSE_MODAL_WINDOW')
    }
  }
}
</script>

<style lang="scss">
.pass-register-modal {
  &__notice {
    background-color: var(--bg_containers);
    border-radius: var(--border-radius);
    padding: 0.6rem;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 0.5rem;
  }

  &__application-selector {
    display: grid;
    grid-gap: 0.5rem;
  }
}
</style>

<i18n>
{
  "ru": {
    "register_modal::completed-app": "Завершённая заявка на осмотр",
    "register_modal::create-button": "Создать пропуск",
    "register_modal::app-not-found": "Если не нашли заявку, Вы можете",
    "register_modal::create-app-button": "создать новую"
  },
  "en": {
    "register_modal::completed-app": "Completed inspection application",
    "register_modal::create-button": "Create pass",
    "register_modal::app-not-found": "create new",
    "register_modal::create-app-button": "create new"
  }
}
</i18n>
